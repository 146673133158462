@import url(./style/base/config.css);
@import url(node_modules/@splidejs/splide/dist/css/splide-core.min.css);
@import url(nouislider/dist/nouislider.css);
@import url(../js/libs/@elements/alert-notification/alert-notification.css);
@import url(../js/libs/@elements/datepicker/datepicker.css);
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #DC3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #B5ADA6;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #B5ADA6;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #323232;
  --bs-primary: #313330;
  --bs-secondary: #E5E1DE;
  --bs-success: #65BA8C;
  --bs-info: #FFE569;
  --bs-warning: #FFE569;
  --bs-danger: #DC3545;
  --bs-light: #f8f9fa;
  --bs-dark: #323232;
  --bs-primary-rgb: 49, 51, 48;
  --bs-secondary-rgb: 229, 225, 222;
  --bs-success-rgb: 101, 186, 140;
  --bs-info-rgb: 255, 229, 105;
  --bs-warning-rgb: 255, 229, 105;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 50, 50, 50;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 50, 50, 50;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #323232;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.25rem;
  --bs-border-radius-sm: 0.2rem;
  --bs-border-radius-lg: 0.3rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #313330;
  --bs-link-hover-color: #272926;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fcf8e3; }

*,
*::before,
*::after {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: var(--bs-highlight-bg); }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: var(--bs-link-color);
  text-decoration: underline; }
  a:hover {
    color: var(--bs-link-hover-color); }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #323232;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #B5ADA6;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #B5ADA6; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #B5ADA6; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl,
.container-xxxl {
  --bs-gutter-x: 10px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

@media (min-width: 1921px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl, .container-xxxl {
    max-width: 1800px; } }

.row {
  --bs-gutter-x: 20px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.3125rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.3125rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.625rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.625rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 0.9375rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 0.9375rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.25rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.25rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 1.5625rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 1.5625rem; }

.g-6,
.gx-6 {
  --bs-gutter-x: 1.875rem; }

.g-6,
.gy-6 {
  --bs-gutter-y: 1.875rem; }

.g-7,
.gx-7 {
  --bs-gutter-x: 2.1875rem; }

.g-7,
.gy-7 {
  --bs-gutter-y: 2.1875rem; }

.g-8,
.gx-8 {
  --bs-gutter-x: 2.5rem; }

.g-8,
.gy-8 {
  --bs-gutter-y: 2.5rem; }

.g-9,
.gx-9 {
  --bs-gutter-x: 2.8125rem; }

.g-9,
.gy-9 {
  --bs-gutter-y: 2.8125rem; }

.g-10,
.gx-10 {
  --bs-gutter-x: 3.125rem; }

.g-10,
.gy-10 {
  --bs-gutter-y: 3.125rem; }

.g-11,
.gx-11 {
  --bs-gutter-x: 3.4375rem; }

.g-11,
.gy-11 {
  --bs-gutter-y: 3.4375rem; }

.g-12,
.gx-12 {
  --bs-gutter-x: 3.75rem; }

.g-12,
.gy-12 {
  --bs-gutter-y: 3.75rem; }

.g-13,
.gx-13 {
  --bs-gutter-x: 4.0625rem; }

.g-13,
.gy-13 {
  --bs-gutter-y: 4.0625rem; }

.g-14,
.gx-14 {
  --bs-gutter-x: 4.375rem; }

.g-14,
.gy-14 {
  --bs-gutter-y: 4.375rem; }

.g-15,
.gx-15 {
  --bs-gutter-x: 4.6875rem; }

.g-15,
.gy-15 {
  --bs-gutter-y: 4.6875rem; }

.g-16,
.gx-16 {
  --bs-gutter-x: 5rem; }

.g-16,
.gy-16 {
  --bs-gutter-y: 5rem; }

.g-17,
.gx-17 {
  --bs-gutter-x: 5.3125rem; }

.g-17,
.gy-17 {
  --bs-gutter-y: 5.3125rem; }

.g-18,
.gx-18 {
  --bs-gutter-x: 5.625rem; }

.g-18,
.gy-18 {
  --bs-gutter-y: 5.625rem; }

.g-19,
.gx-19 {
  --bs-gutter-x: 5.9375rem; }

.g-19,
.gy-19 {
  --bs-gutter-y: 5.9375rem; }

.g-20,
.gx-20 {
  --bs-gutter-x: 6.25rem; }

.g-20,
.gy-20 {
  --bs-gutter-y: 6.25rem; }

.g-21,
.gx-21 {
  --bs-gutter-x: 6.5625rem; }

.g-21,
.gy-21 {
  --bs-gutter-y: 6.5625rem; }

.g-22,
.gx-22 {
  --bs-gutter-x: 6.875rem; }

.g-22,
.gy-22 {
  --bs-gutter-y: 6.875rem; }

.g-23,
.gx-23 {
  --bs-gutter-x: 7.1875rem; }

.g-23,
.gy-23 {
  --bs-gutter-y: 7.1875rem; }

.g-24,
.gx-24 {
  --bs-gutter-x: 7.5rem; }

.g-24,
.gy-24 {
  --bs-gutter-y: 7.5rem; }

.g-25,
.gx-25 {
  --bs-gutter-x: 7.8125rem; }

.g-25,
.gy-25 {
  --bs-gutter-y: 7.8125rem; }

.g-26,
.gx-26 {
  --bs-gutter-x: 8.125rem; }

.g-26,
.gy-26 {
  --bs-gutter-y: 8.125rem; }

.g-27,
.gx-27 {
  --bs-gutter-x: 8.4375rem; }

.g-27,
.gy-27 {
  --bs-gutter-y: 8.4375rem; }

.g-28,
.gx-28 {
  --bs-gutter-x: 8.75rem; }

.g-28,
.gy-28 {
  --bs-gutter-y: 8.75rem; }

.g-29,
.gx-29 {
  --bs-gutter-x: 9.0625rem; }

.g-29,
.gy-29 {
  --bs-gutter-y: 9.0625rem; }

.g-30,
.gx-30 {
  --bs-gutter-x: 9.375rem; }

.g-30,
.gy-30 {
  --bs-gutter-y: 9.375rem; }

.g-31,
.gx-31 {
  --bs-gutter-x: 9.6875rem; }

.g-31,
.gy-31 {
  --bs-gutter-y: 9.6875rem; }

.g-32,
.gx-32 {
  --bs-gutter-x: 10rem; }

.g-32,
.gy-32 {
  --bs-gutter-y: 10rem; }

.g-33,
.gx-33 {
  --bs-gutter-x: 10.3125rem; }

.g-33,
.gy-33 {
  --bs-gutter-y: 10.3125rem; }

.g-34,
.gx-34 {
  --bs-gutter-x: 10.625rem; }

.g-34,
.gy-34 {
  --bs-gutter-y: 10.625rem; }

.g-35,
.gx-35 {
  --bs-gutter-x: 10.9375rem; }

.g-35,
.gy-35 {
  --bs-gutter-y: 10.9375rem; }

.g-36,
.gx-36 {
  --bs-gutter-x: 11.25rem; }

.g-36,
.gy-36 {
  --bs-gutter-y: 11.25rem; }

.g-37,
.gx-37 {
  --bs-gutter-x: 11.5625rem; }

.g-37,
.gy-37 {
  --bs-gutter-y: 11.5625rem; }

.g-38,
.gx-38 {
  --bs-gutter-x: 11.875rem; }

.g-38,
.gy-38 {
  --bs-gutter-y: 11.875rem; }

.g-39,
.gx-39 {
  --bs-gutter-x: 12.1875rem; }

.g-39,
.gy-39 {
  --bs-gutter-y: 12.1875rem; }

.g-40,
.gx-40 {
  --bs-gutter-x: 12.5rem; }

.g-40,
.gy-40 {
  --bs-gutter-y: 12.5rem; }

.g-base,
.gx-base {
  --bs-gutter-x: calc(20rem / 16); }

.g-base,
.gy-base {
  --bs-gutter-y: calc(20rem / 16); }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.3125rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.3125rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.625rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.625rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 0.9375rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 0.9375rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.25rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.25rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 1.5625rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 1.5625rem; }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 1.875rem; }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 1.875rem; }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 2.1875rem; }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 2.1875rem; }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 2.5rem; }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 2.5rem; }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 2.8125rem; }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 2.8125rem; }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 3.125rem; }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 3.125rem; }
  .g-sm-11,
  .gx-sm-11 {
    --bs-gutter-x: 3.4375rem; }
  .g-sm-11,
  .gy-sm-11 {
    --bs-gutter-y: 3.4375rem; }
  .g-sm-12,
  .gx-sm-12 {
    --bs-gutter-x: 3.75rem; }
  .g-sm-12,
  .gy-sm-12 {
    --bs-gutter-y: 3.75rem; }
  .g-sm-13,
  .gx-sm-13 {
    --bs-gutter-x: 4.0625rem; }
  .g-sm-13,
  .gy-sm-13 {
    --bs-gutter-y: 4.0625rem; }
  .g-sm-14,
  .gx-sm-14 {
    --bs-gutter-x: 4.375rem; }
  .g-sm-14,
  .gy-sm-14 {
    --bs-gutter-y: 4.375rem; }
  .g-sm-15,
  .gx-sm-15 {
    --bs-gutter-x: 4.6875rem; }
  .g-sm-15,
  .gy-sm-15 {
    --bs-gutter-y: 4.6875rem; }
  .g-sm-16,
  .gx-sm-16 {
    --bs-gutter-x: 5rem; }
  .g-sm-16,
  .gy-sm-16 {
    --bs-gutter-y: 5rem; }
  .g-sm-17,
  .gx-sm-17 {
    --bs-gutter-x: 5.3125rem; }
  .g-sm-17,
  .gy-sm-17 {
    --bs-gutter-y: 5.3125rem; }
  .g-sm-18,
  .gx-sm-18 {
    --bs-gutter-x: 5.625rem; }
  .g-sm-18,
  .gy-sm-18 {
    --bs-gutter-y: 5.625rem; }
  .g-sm-19,
  .gx-sm-19 {
    --bs-gutter-x: 5.9375rem; }
  .g-sm-19,
  .gy-sm-19 {
    --bs-gutter-y: 5.9375rem; }
  .g-sm-20,
  .gx-sm-20 {
    --bs-gutter-x: 6.25rem; }
  .g-sm-20,
  .gy-sm-20 {
    --bs-gutter-y: 6.25rem; }
  .g-sm-21,
  .gx-sm-21 {
    --bs-gutter-x: 6.5625rem; }
  .g-sm-21,
  .gy-sm-21 {
    --bs-gutter-y: 6.5625rem; }
  .g-sm-22,
  .gx-sm-22 {
    --bs-gutter-x: 6.875rem; }
  .g-sm-22,
  .gy-sm-22 {
    --bs-gutter-y: 6.875rem; }
  .g-sm-23,
  .gx-sm-23 {
    --bs-gutter-x: 7.1875rem; }
  .g-sm-23,
  .gy-sm-23 {
    --bs-gutter-y: 7.1875rem; }
  .g-sm-24,
  .gx-sm-24 {
    --bs-gutter-x: 7.5rem; }
  .g-sm-24,
  .gy-sm-24 {
    --bs-gutter-y: 7.5rem; }
  .g-sm-25,
  .gx-sm-25 {
    --bs-gutter-x: 7.8125rem; }
  .g-sm-25,
  .gy-sm-25 {
    --bs-gutter-y: 7.8125rem; }
  .g-sm-26,
  .gx-sm-26 {
    --bs-gutter-x: 8.125rem; }
  .g-sm-26,
  .gy-sm-26 {
    --bs-gutter-y: 8.125rem; }
  .g-sm-27,
  .gx-sm-27 {
    --bs-gutter-x: 8.4375rem; }
  .g-sm-27,
  .gy-sm-27 {
    --bs-gutter-y: 8.4375rem; }
  .g-sm-28,
  .gx-sm-28 {
    --bs-gutter-x: 8.75rem; }
  .g-sm-28,
  .gy-sm-28 {
    --bs-gutter-y: 8.75rem; }
  .g-sm-29,
  .gx-sm-29 {
    --bs-gutter-x: 9.0625rem; }
  .g-sm-29,
  .gy-sm-29 {
    --bs-gutter-y: 9.0625rem; }
  .g-sm-30,
  .gx-sm-30 {
    --bs-gutter-x: 9.375rem; }
  .g-sm-30,
  .gy-sm-30 {
    --bs-gutter-y: 9.375rem; }
  .g-sm-31,
  .gx-sm-31 {
    --bs-gutter-x: 9.6875rem; }
  .g-sm-31,
  .gy-sm-31 {
    --bs-gutter-y: 9.6875rem; }
  .g-sm-32,
  .gx-sm-32 {
    --bs-gutter-x: 10rem; }
  .g-sm-32,
  .gy-sm-32 {
    --bs-gutter-y: 10rem; }
  .g-sm-33,
  .gx-sm-33 {
    --bs-gutter-x: 10.3125rem; }
  .g-sm-33,
  .gy-sm-33 {
    --bs-gutter-y: 10.3125rem; }
  .g-sm-34,
  .gx-sm-34 {
    --bs-gutter-x: 10.625rem; }
  .g-sm-34,
  .gy-sm-34 {
    --bs-gutter-y: 10.625rem; }
  .g-sm-35,
  .gx-sm-35 {
    --bs-gutter-x: 10.9375rem; }
  .g-sm-35,
  .gy-sm-35 {
    --bs-gutter-y: 10.9375rem; }
  .g-sm-36,
  .gx-sm-36 {
    --bs-gutter-x: 11.25rem; }
  .g-sm-36,
  .gy-sm-36 {
    --bs-gutter-y: 11.25rem; }
  .g-sm-37,
  .gx-sm-37 {
    --bs-gutter-x: 11.5625rem; }
  .g-sm-37,
  .gy-sm-37 {
    --bs-gutter-y: 11.5625rem; }
  .g-sm-38,
  .gx-sm-38 {
    --bs-gutter-x: 11.875rem; }
  .g-sm-38,
  .gy-sm-38 {
    --bs-gutter-y: 11.875rem; }
  .g-sm-39,
  .gx-sm-39 {
    --bs-gutter-x: 12.1875rem; }
  .g-sm-39,
  .gy-sm-39 {
    --bs-gutter-y: 12.1875rem; }
  .g-sm-40,
  .gx-sm-40 {
    --bs-gutter-x: 12.5rem; }
  .g-sm-40,
  .gy-sm-40 {
    --bs-gutter-y: 12.5rem; }
  .g-sm-base,
  .gx-sm-base {
    --bs-gutter-x: calc(20rem / 16); }
  .g-sm-base,
  .gy-sm-base {
    --bs-gutter-y: calc(20rem / 16); } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.3125rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.3125rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.625rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.625rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 0.9375rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 0.9375rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.25rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.25rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 1.5625rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 1.5625rem; }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 1.875rem; }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 1.875rem; }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 2.1875rem; }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 2.1875rem; }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 2.5rem; }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 2.5rem; }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 2.8125rem; }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 2.8125rem; }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 3.125rem; }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 3.125rem; }
  .g-md-11,
  .gx-md-11 {
    --bs-gutter-x: 3.4375rem; }
  .g-md-11,
  .gy-md-11 {
    --bs-gutter-y: 3.4375rem; }
  .g-md-12,
  .gx-md-12 {
    --bs-gutter-x: 3.75rem; }
  .g-md-12,
  .gy-md-12 {
    --bs-gutter-y: 3.75rem; }
  .g-md-13,
  .gx-md-13 {
    --bs-gutter-x: 4.0625rem; }
  .g-md-13,
  .gy-md-13 {
    --bs-gutter-y: 4.0625rem; }
  .g-md-14,
  .gx-md-14 {
    --bs-gutter-x: 4.375rem; }
  .g-md-14,
  .gy-md-14 {
    --bs-gutter-y: 4.375rem; }
  .g-md-15,
  .gx-md-15 {
    --bs-gutter-x: 4.6875rem; }
  .g-md-15,
  .gy-md-15 {
    --bs-gutter-y: 4.6875rem; }
  .g-md-16,
  .gx-md-16 {
    --bs-gutter-x: 5rem; }
  .g-md-16,
  .gy-md-16 {
    --bs-gutter-y: 5rem; }
  .g-md-17,
  .gx-md-17 {
    --bs-gutter-x: 5.3125rem; }
  .g-md-17,
  .gy-md-17 {
    --bs-gutter-y: 5.3125rem; }
  .g-md-18,
  .gx-md-18 {
    --bs-gutter-x: 5.625rem; }
  .g-md-18,
  .gy-md-18 {
    --bs-gutter-y: 5.625rem; }
  .g-md-19,
  .gx-md-19 {
    --bs-gutter-x: 5.9375rem; }
  .g-md-19,
  .gy-md-19 {
    --bs-gutter-y: 5.9375rem; }
  .g-md-20,
  .gx-md-20 {
    --bs-gutter-x: 6.25rem; }
  .g-md-20,
  .gy-md-20 {
    --bs-gutter-y: 6.25rem; }
  .g-md-21,
  .gx-md-21 {
    --bs-gutter-x: 6.5625rem; }
  .g-md-21,
  .gy-md-21 {
    --bs-gutter-y: 6.5625rem; }
  .g-md-22,
  .gx-md-22 {
    --bs-gutter-x: 6.875rem; }
  .g-md-22,
  .gy-md-22 {
    --bs-gutter-y: 6.875rem; }
  .g-md-23,
  .gx-md-23 {
    --bs-gutter-x: 7.1875rem; }
  .g-md-23,
  .gy-md-23 {
    --bs-gutter-y: 7.1875rem; }
  .g-md-24,
  .gx-md-24 {
    --bs-gutter-x: 7.5rem; }
  .g-md-24,
  .gy-md-24 {
    --bs-gutter-y: 7.5rem; }
  .g-md-25,
  .gx-md-25 {
    --bs-gutter-x: 7.8125rem; }
  .g-md-25,
  .gy-md-25 {
    --bs-gutter-y: 7.8125rem; }
  .g-md-26,
  .gx-md-26 {
    --bs-gutter-x: 8.125rem; }
  .g-md-26,
  .gy-md-26 {
    --bs-gutter-y: 8.125rem; }
  .g-md-27,
  .gx-md-27 {
    --bs-gutter-x: 8.4375rem; }
  .g-md-27,
  .gy-md-27 {
    --bs-gutter-y: 8.4375rem; }
  .g-md-28,
  .gx-md-28 {
    --bs-gutter-x: 8.75rem; }
  .g-md-28,
  .gy-md-28 {
    --bs-gutter-y: 8.75rem; }
  .g-md-29,
  .gx-md-29 {
    --bs-gutter-x: 9.0625rem; }
  .g-md-29,
  .gy-md-29 {
    --bs-gutter-y: 9.0625rem; }
  .g-md-30,
  .gx-md-30 {
    --bs-gutter-x: 9.375rem; }
  .g-md-30,
  .gy-md-30 {
    --bs-gutter-y: 9.375rem; }
  .g-md-31,
  .gx-md-31 {
    --bs-gutter-x: 9.6875rem; }
  .g-md-31,
  .gy-md-31 {
    --bs-gutter-y: 9.6875rem; }
  .g-md-32,
  .gx-md-32 {
    --bs-gutter-x: 10rem; }
  .g-md-32,
  .gy-md-32 {
    --bs-gutter-y: 10rem; }
  .g-md-33,
  .gx-md-33 {
    --bs-gutter-x: 10.3125rem; }
  .g-md-33,
  .gy-md-33 {
    --bs-gutter-y: 10.3125rem; }
  .g-md-34,
  .gx-md-34 {
    --bs-gutter-x: 10.625rem; }
  .g-md-34,
  .gy-md-34 {
    --bs-gutter-y: 10.625rem; }
  .g-md-35,
  .gx-md-35 {
    --bs-gutter-x: 10.9375rem; }
  .g-md-35,
  .gy-md-35 {
    --bs-gutter-y: 10.9375rem; }
  .g-md-36,
  .gx-md-36 {
    --bs-gutter-x: 11.25rem; }
  .g-md-36,
  .gy-md-36 {
    --bs-gutter-y: 11.25rem; }
  .g-md-37,
  .gx-md-37 {
    --bs-gutter-x: 11.5625rem; }
  .g-md-37,
  .gy-md-37 {
    --bs-gutter-y: 11.5625rem; }
  .g-md-38,
  .gx-md-38 {
    --bs-gutter-x: 11.875rem; }
  .g-md-38,
  .gy-md-38 {
    --bs-gutter-y: 11.875rem; }
  .g-md-39,
  .gx-md-39 {
    --bs-gutter-x: 12.1875rem; }
  .g-md-39,
  .gy-md-39 {
    --bs-gutter-y: 12.1875rem; }
  .g-md-40,
  .gx-md-40 {
    --bs-gutter-x: 12.5rem; }
  .g-md-40,
  .gy-md-40 {
    --bs-gutter-y: 12.5rem; }
  .g-md-base,
  .gx-md-base {
    --bs-gutter-x: calc(20rem / 16); }
  .g-md-base,
  .gy-md-base {
    --bs-gutter-y: calc(20rem / 16); } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.3125rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.3125rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.625rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.625rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 0.9375rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 0.9375rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.25rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.25rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 1.5625rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 1.5625rem; }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 1.875rem; }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 1.875rem; }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 2.1875rem; }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 2.1875rem; }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 2.5rem; }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 2.5rem; }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 2.8125rem; }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 2.8125rem; }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 3.125rem; }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 3.125rem; }
  .g-lg-11,
  .gx-lg-11 {
    --bs-gutter-x: 3.4375rem; }
  .g-lg-11,
  .gy-lg-11 {
    --bs-gutter-y: 3.4375rem; }
  .g-lg-12,
  .gx-lg-12 {
    --bs-gutter-x: 3.75rem; }
  .g-lg-12,
  .gy-lg-12 {
    --bs-gutter-y: 3.75rem; }
  .g-lg-13,
  .gx-lg-13 {
    --bs-gutter-x: 4.0625rem; }
  .g-lg-13,
  .gy-lg-13 {
    --bs-gutter-y: 4.0625rem; }
  .g-lg-14,
  .gx-lg-14 {
    --bs-gutter-x: 4.375rem; }
  .g-lg-14,
  .gy-lg-14 {
    --bs-gutter-y: 4.375rem; }
  .g-lg-15,
  .gx-lg-15 {
    --bs-gutter-x: 4.6875rem; }
  .g-lg-15,
  .gy-lg-15 {
    --bs-gutter-y: 4.6875rem; }
  .g-lg-16,
  .gx-lg-16 {
    --bs-gutter-x: 5rem; }
  .g-lg-16,
  .gy-lg-16 {
    --bs-gutter-y: 5rem; }
  .g-lg-17,
  .gx-lg-17 {
    --bs-gutter-x: 5.3125rem; }
  .g-lg-17,
  .gy-lg-17 {
    --bs-gutter-y: 5.3125rem; }
  .g-lg-18,
  .gx-lg-18 {
    --bs-gutter-x: 5.625rem; }
  .g-lg-18,
  .gy-lg-18 {
    --bs-gutter-y: 5.625rem; }
  .g-lg-19,
  .gx-lg-19 {
    --bs-gutter-x: 5.9375rem; }
  .g-lg-19,
  .gy-lg-19 {
    --bs-gutter-y: 5.9375rem; }
  .g-lg-20,
  .gx-lg-20 {
    --bs-gutter-x: 6.25rem; }
  .g-lg-20,
  .gy-lg-20 {
    --bs-gutter-y: 6.25rem; }
  .g-lg-21,
  .gx-lg-21 {
    --bs-gutter-x: 6.5625rem; }
  .g-lg-21,
  .gy-lg-21 {
    --bs-gutter-y: 6.5625rem; }
  .g-lg-22,
  .gx-lg-22 {
    --bs-gutter-x: 6.875rem; }
  .g-lg-22,
  .gy-lg-22 {
    --bs-gutter-y: 6.875rem; }
  .g-lg-23,
  .gx-lg-23 {
    --bs-gutter-x: 7.1875rem; }
  .g-lg-23,
  .gy-lg-23 {
    --bs-gutter-y: 7.1875rem; }
  .g-lg-24,
  .gx-lg-24 {
    --bs-gutter-x: 7.5rem; }
  .g-lg-24,
  .gy-lg-24 {
    --bs-gutter-y: 7.5rem; }
  .g-lg-25,
  .gx-lg-25 {
    --bs-gutter-x: 7.8125rem; }
  .g-lg-25,
  .gy-lg-25 {
    --bs-gutter-y: 7.8125rem; }
  .g-lg-26,
  .gx-lg-26 {
    --bs-gutter-x: 8.125rem; }
  .g-lg-26,
  .gy-lg-26 {
    --bs-gutter-y: 8.125rem; }
  .g-lg-27,
  .gx-lg-27 {
    --bs-gutter-x: 8.4375rem; }
  .g-lg-27,
  .gy-lg-27 {
    --bs-gutter-y: 8.4375rem; }
  .g-lg-28,
  .gx-lg-28 {
    --bs-gutter-x: 8.75rem; }
  .g-lg-28,
  .gy-lg-28 {
    --bs-gutter-y: 8.75rem; }
  .g-lg-29,
  .gx-lg-29 {
    --bs-gutter-x: 9.0625rem; }
  .g-lg-29,
  .gy-lg-29 {
    --bs-gutter-y: 9.0625rem; }
  .g-lg-30,
  .gx-lg-30 {
    --bs-gutter-x: 9.375rem; }
  .g-lg-30,
  .gy-lg-30 {
    --bs-gutter-y: 9.375rem; }
  .g-lg-31,
  .gx-lg-31 {
    --bs-gutter-x: 9.6875rem; }
  .g-lg-31,
  .gy-lg-31 {
    --bs-gutter-y: 9.6875rem; }
  .g-lg-32,
  .gx-lg-32 {
    --bs-gutter-x: 10rem; }
  .g-lg-32,
  .gy-lg-32 {
    --bs-gutter-y: 10rem; }
  .g-lg-33,
  .gx-lg-33 {
    --bs-gutter-x: 10.3125rem; }
  .g-lg-33,
  .gy-lg-33 {
    --bs-gutter-y: 10.3125rem; }
  .g-lg-34,
  .gx-lg-34 {
    --bs-gutter-x: 10.625rem; }
  .g-lg-34,
  .gy-lg-34 {
    --bs-gutter-y: 10.625rem; }
  .g-lg-35,
  .gx-lg-35 {
    --bs-gutter-x: 10.9375rem; }
  .g-lg-35,
  .gy-lg-35 {
    --bs-gutter-y: 10.9375rem; }
  .g-lg-36,
  .gx-lg-36 {
    --bs-gutter-x: 11.25rem; }
  .g-lg-36,
  .gy-lg-36 {
    --bs-gutter-y: 11.25rem; }
  .g-lg-37,
  .gx-lg-37 {
    --bs-gutter-x: 11.5625rem; }
  .g-lg-37,
  .gy-lg-37 {
    --bs-gutter-y: 11.5625rem; }
  .g-lg-38,
  .gx-lg-38 {
    --bs-gutter-x: 11.875rem; }
  .g-lg-38,
  .gy-lg-38 {
    --bs-gutter-y: 11.875rem; }
  .g-lg-39,
  .gx-lg-39 {
    --bs-gutter-x: 12.1875rem; }
  .g-lg-39,
  .gy-lg-39 {
    --bs-gutter-y: 12.1875rem; }
  .g-lg-40,
  .gx-lg-40 {
    --bs-gutter-x: 12.5rem; }
  .g-lg-40,
  .gy-lg-40 {
    --bs-gutter-y: 12.5rem; }
  .g-lg-base,
  .gx-lg-base {
    --bs-gutter-x: calc(20rem / 16); }
  .g-lg-base,
  .gy-lg-base {
    --bs-gutter-y: calc(20rem / 16); } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.3125rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.3125rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.625rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.625rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 0.9375rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 0.9375rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.25rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.25rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 1.5625rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 1.5625rem; }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 1.875rem; }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 1.875rem; }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 2.1875rem; }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 2.1875rem; }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 2.5rem; }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 2.5rem; }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 2.8125rem; }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 2.8125rem; }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 3.125rem; }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 3.125rem; }
  .g-xl-11,
  .gx-xl-11 {
    --bs-gutter-x: 3.4375rem; }
  .g-xl-11,
  .gy-xl-11 {
    --bs-gutter-y: 3.4375rem; }
  .g-xl-12,
  .gx-xl-12 {
    --bs-gutter-x: 3.75rem; }
  .g-xl-12,
  .gy-xl-12 {
    --bs-gutter-y: 3.75rem; }
  .g-xl-13,
  .gx-xl-13 {
    --bs-gutter-x: 4.0625rem; }
  .g-xl-13,
  .gy-xl-13 {
    --bs-gutter-y: 4.0625rem; }
  .g-xl-14,
  .gx-xl-14 {
    --bs-gutter-x: 4.375rem; }
  .g-xl-14,
  .gy-xl-14 {
    --bs-gutter-y: 4.375rem; }
  .g-xl-15,
  .gx-xl-15 {
    --bs-gutter-x: 4.6875rem; }
  .g-xl-15,
  .gy-xl-15 {
    --bs-gutter-y: 4.6875rem; }
  .g-xl-16,
  .gx-xl-16 {
    --bs-gutter-x: 5rem; }
  .g-xl-16,
  .gy-xl-16 {
    --bs-gutter-y: 5rem; }
  .g-xl-17,
  .gx-xl-17 {
    --bs-gutter-x: 5.3125rem; }
  .g-xl-17,
  .gy-xl-17 {
    --bs-gutter-y: 5.3125rem; }
  .g-xl-18,
  .gx-xl-18 {
    --bs-gutter-x: 5.625rem; }
  .g-xl-18,
  .gy-xl-18 {
    --bs-gutter-y: 5.625rem; }
  .g-xl-19,
  .gx-xl-19 {
    --bs-gutter-x: 5.9375rem; }
  .g-xl-19,
  .gy-xl-19 {
    --bs-gutter-y: 5.9375rem; }
  .g-xl-20,
  .gx-xl-20 {
    --bs-gutter-x: 6.25rem; }
  .g-xl-20,
  .gy-xl-20 {
    --bs-gutter-y: 6.25rem; }
  .g-xl-21,
  .gx-xl-21 {
    --bs-gutter-x: 6.5625rem; }
  .g-xl-21,
  .gy-xl-21 {
    --bs-gutter-y: 6.5625rem; }
  .g-xl-22,
  .gx-xl-22 {
    --bs-gutter-x: 6.875rem; }
  .g-xl-22,
  .gy-xl-22 {
    --bs-gutter-y: 6.875rem; }
  .g-xl-23,
  .gx-xl-23 {
    --bs-gutter-x: 7.1875rem; }
  .g-xl-23,
  .gy-xl-23 {
    --bs-gutter-y: 7.1875rem; }
  .g-xl-24,
  .gx-xl-24 {
    --bs-gutter-x: 7.5rem; }
  .g-xl-24,
  .gy-xl-24 {
    --bs-gutter-y: 7.5rem; }
  .g-xl-25,
  .gx-xl-25 {
    --bs-gutter-x: 7.8125rem; }
  .g-xl-25,
  .gy-xl-25 {
    --bs-gutter-y: 7.8125rem; }
  .g-xl-26,
  .gx-xl-26 {
    --bs-gutter-x: 8.125rem; }
  .g-xl-26,
  .gy-xl-26 {
    --bs-gutter-y: 8.125rem; }
  .g-xl-27,
  .gx-xl-27 {
    --bs-gutter-x: 8.4375rem; }
  .g-xl-27,
  .gy-xl-27 {
    --bs-gutter-y: 8.4375rem; }
  .g-xl-28,
  .gx-xl-28 {
    --bs-gutter-x: 8.75rem; }
  .g-xl-28,
  .gy-xl-28 {
    --bs-gutter-y: 8.75rem; }
  .g-xl-29,
  .gx-xl-29 {
    --bs-gutter-x: 9.0625rem; }
  .g-xl-29,
  .gy-xl-29 {
    --bs-gutter-y: 9.0625rem; }
  .g-xl-30,
  .gx-xl-30 {
    --bs-gutter-x: 9.375rem; }
  .g-xl-30,
  .gy-xl-30 {
    --bs-gutter-y: 9.375rem; }
  .g-xl-31,
  .gx-xl-31 {
    --bs-gutter-x: 9.6875rem; }
  .g-xl-31,
  .gy-xl-31 {
    --bs-gutter-y: 9.6875rem; }
  .g-xl-32,
  .gx-xl-32 {
    --bs-gutter-x: 10rem; }
  .g-xl-32,
  .gy-xl-32 {
    --bs-gutter-y: 10rem; }
  .g-xl-33,
  .gx-xl-33 {
    --bs-gutter-x: 10.3125rem; }
  .g-xl-33,
  .gy-xl-33 {
    --bs-gutter-y: 10.3125rem; }
  .g-xl-34,
  .gx-xl-34 {
    --bs-gutter-x: 10.625rem; }
  .g-xl-34,
  .gy-xl-34 {
    --bs-gutter-y: 10.625rem; }
  .g-xl-35,
  .gx-xl-35 {
    --bs-gutter-x: 10.9375rem; }
  .g-xl-35,
  .gy-xl-35 {
    --bs-gutter-y: 10.9375rem; }
  .g-xl-36,
  .gx-xl-36 {
    --bs-gutter-x: 11.25rem; }
  .g-xl-36,
  .gy-xl-36 {
    --bs-gutter-y: 11.25rem; }
  .g-xl-37,
  .gx-xl-37 {
    --bs-gutter-x: 11.5625rem; }
  .g-xl-37,
  .gy-xl-37 {
    --bs-gutter-y: 11.5625rem; }
  .g-xl-38,
  .gx-xl-38 {
    --bs-gutter-x: 11.875rem; }
  .g-xl-38,
  .gy-xl-38 {
    --bs-gutter-y: 11.875rem; }
  .g-xl-39,
  .gx-xl-39 {
    --bs-gutter-x: 12.1875rem; }
  .g-xl-39,
  .gy-xl-39 {
    --bs-gutter-y: 12.1875rem; }
  .g-xl-40,
  .gx-xl-40 {
    --bs-gutter-x: 12.5rem; }
  .g-xl-40,
  .gy-xl-40 {
    --bs-gutter-y: 12.5rem; }
  .g-xl-base,
  .gx-xl-base {
    --bs-gutter-x: calc(20rem / 16); }
  .g-xl-base,
  .gy-xl-base {
    --bs-gutter-y: calc(20rem / 16); } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.3125rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.3125rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.625rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.625rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 0.9375rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 0.9375rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.25rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.25rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 1.5625rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 1.5625rem; }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 1.875rem; }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 1.875rem; }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 2.1875rem; }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 2.1875rem; }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 2.5rem; }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 2.5rem; }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 2.8125rem; }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 2.8125rem; }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 3.125rem; }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 3.125rem; }
  .g-xxl-11,
  .gx-xxl-11 {
    --bs-gutter-x: 3.4375rem; }
  .g-xxl-11,
  .gy-xxl-11 {
    --bs-gutter-y: 3.4375rem; }
  .g-xxl-12,
  .gx-xxl-12 {
    --bs-gutter-x: 3.75rem; }
  .g-xxl-12,
  .gy-xxl-12 {
    --bs-gutter-y: 3.75rem; }
  .g-xxl-13,
  .gx-xxl-13 {
    --bs-gutter-x: 4.0625rem; }
  .g-xxl-13,
  .gy-xxl-13 {
    --bs-gutter-y: 4.0625rem; }
  .g-xxl-14,
  .gx-xxl-14 {
    --bs-gutter-x: 4.375rem; }
  .g-xxl-14,
  .gy-xxl-14 {
    --bs-gutter-y: 4.375rem; }
  .g-xxl-15,
  .gx-xxl-15 {
    --bs-gutter-x: 4.6875rem; }
  .g-xxl-15,
  .gy-xxl-15 {
    --bs-gutter-y: 4.6875rem; }
  .g-xxl-16,
  .gx-xxl-16 {
    --bs-gutter-x: 5rem; }
  .g-xxl-16,
  .gy-xxl-16 {
    --bs-gutter-y: 5rem; }
  .g-xxl-17,
  .gx-xxl-17 {
    --bs-gutter-x: 5.3125rem; }
  .g-xxl-17,
  .gy-xxl-17 {
    --bs-gutter-y: 5.3125rem; }
  .g-xxl-18,
  .gx-xxl-18 {
    --bs-gutter-x: 5.625rem; }
  .g-xxl-18,
  .gy-xxl-18 {
    --bs-gutter-y: 5.625rem; }
  .g-xxl-19,
  .gx-xxl-19 {
    --bs-gutter-x: 5.9375rem; }
  .g-xxl-19,
  .gy-xxl-19 {
    --bs-gutter-y: 5.9375rem; }
  .g-xxl-20,
  .gx-xxl-20 {
    --bs-gutter-x: 6.25rem; }
  .g-xxl-20,
  .gy-xxl-20 {
    --bs-gutter-y: 6.25rem; }
  .g-xxl-21,
  .gx-xxl-21 {
    --bs-gutter-x: 6.5625rem; }
  .g-xxl-21,
  .gy-xxl-21 {
    --bs-gutter-y: 6.5625rem; }
  .g-xxl-22,
  .gx-xxl-22 {
    --bs-gutter-x: 6.875rem; }
  .g-xxl-22,
  .gy-xxl-22 {
    --bs-gutter-y: 6.875rem; }
  .g-xxl-23,
  .gx-xxl-23 {
    --bs-gutter-x: 7.1875rem; }
  .g-xxl-23,
  .gy-xxl-23 {
    --bs-gutter-y: 7.1875rem; }
  .g-xxl-24,
  .gx-xxl-24 {
    --bs-gutter-x: 7.5rem; }
  .g-xxl-24,
  .gy-xxl-24 {
    --bs-gutter-y: 7.5rem; }
  .g-xxl-25,
  .gx-xxl-25 {
    --bs-gutter-x: 7.8125rem; }
  .g-xxl-25,
  .gy-xxl-25 {
    --bs-gutter-y: 7.8125rem; }
  .g-xxl-26,
  .gx-xxl-26 {
    --bs-gutter-x: 8.125rem; }
  .g-xxl-26,
  .gy-xxl-26 {
    --bs-gutter-y: 8.125rem; }
  .g-xxl-27,
  .gx-xxl-27 {
    --bs-gutter-x: 8.4375rem; }
  .g-xxl-27,
  .gy-xxl-27 {
    --bs-gutter-y: 8.4375rem; }
  .g-xxl-28,
  .gx-xxl-28 {
    --bs-gutter-x: 8.75rem; }
  .g-xxl-28,
  .gy-xxl-28 {
    --bs-gutter-y: 8.75rem; }
  .g-xxl-29,
  .gx-xxl-29 {
    --bs-gutter-x: 9.0625rem; }
  .g-xxl-29,
  .gy-xxl-29 {
    --bs-gutter-y: 9.0625rem; }
  .g-xxl-30,
  .gx-xxl-30 {
    --bs-gutter-x: 9.375rem; }
  .g-xxl-30,
  .gy-xxl-30 {
    --bs-gutter-y: 9.375rem; }
  .g-xxl-31,
  .gx-xxl-31 {
    --bs-gutter-x: 9.6875rem; }
  .g-xxl-31,
  .gy-xxl-31 {
    --bs-gutter-y: 9.6875rem; }
  .g-xxl-32,
  .gx-xxl-32 {
    --bs-gutter-x: 10rem; }
  .g-xxl-32,
  .gy-xxl-32 {
    --bs-gutter-y: 10rem; }
  .g-xxl-33,
  .gx-xxl-33 {
    --bs-gutter-x: 10.3125rem; }
  .g-xxl-33,
  .gy-xxl-33 {
    --bs-gutter-y: 10.3125rem; }
  .g-xxl-34,
  .gx-xxl-34 {
    --bs-gutter-x: 10.625rem; }
  .g-xxl-34,
  .gy-xxl-34 {
    --bs-gutter-y: 10.625rem; }
  .g-xxl-35,
  .gx-xxl-35 {
    --bs-gutter-x: 10.9375rem; }
  .g-xxl-35,
  .gy-xxl-35 {
    --bs-gutter-y: 10.9375rem; }
  .g-xxl-36,
  .gx-xxl-36 {
    --bs-gutter-x: 11.25rem; }
  .g-xxl-36,
  .gy-xxl-36 {
    --bs-gutter-y: 11.25rem; }
  .g-xxl-37,
  .gx-xxl-37 {
    --bs-gutter-x: 11.5625rem; }
  .g-xxl-37,
  .gy-xxl-37 {
    --bs-gutter-y: 11.5625rem; }
  .g-xxl-38,
  .gx-xxl-38 {
    --bs-gutter-x: 11.875rem; }
  .g-xxl-38,
  .gy-xxl-38 {
    --bs-gutter-y: 11.875rem; }
  .g-xxl-39,
  .gx-xxl-39 {
    --bs-gutter-x: 12.1875rem; }
  .g-xxl-39,
  .gy-xxl-39 {
    --bs-gutter-y: 12.1875rem; }
  .g-xxl-40,
  .gx-xxl-40 {
    --bs-gutter-x: 12.5rem; }
  .g-xxl-40,
  .gy-xxl-40 {
    --bs-gutter-y: 12.5rem; }
  .g-xxl-base,
  .gx-xxl-base {
    --bs-gutter-x: calc(20rem / 16); }
  .g-xxl-base,
  .gy-xxl-base {
    --bs-gutter-y: calc(20rem / 16); } }

@media (min-width: 1921px) {
  .col-xxxl {
    flex: 1 0 0%; }
  .row-cols-xxxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxxl-0 {
    margin-left: 0; }
  .offset-xxxl-1 {
    margin-left: 8.33333%; }
  .offset-xxxl-2 {
    margin-left: 16.66667%; }
  .offset-xxxl-3 {
    margin-left: 25%; }
  .offset-xxxl-4 {
    margin-left: 33.33333%; }
  .offset-xxxl-5 {
    margin-left: 41.66667%; }
  .offset-xxxl-6 {
    margin-left: 50%; }
  .offset-xxxl-7 {
    margin-left: 58.33333%; }
  .offset-xxxl-8 {
    margin-left: 66.66667%; }
  .offset-xxxl-9 {
    margin-left: 75%; }
  .offset-xxxl-10 {
    margin-left: 83.33333%; }
  .offset-xxxl-11 {
    margin-left: 91.66667%; }
  .g-xxxl-0,
  .gx-xxxl-0 {
    --bs-gutter-x: 0; }
  .g-xxxl-0,
  .gy-xxxl-0 {
    --bs-gutter-y: 0; }
  .g-xxxl-1,
  .gx-xxxl-1 {
    --bs-gutter-x: 0.3125rem; }
  .g-xxxl-1,
  .gy-xxxl-1 {
    --bs-gutter-y: 0.3125rem; }
  .g-xxxl-2,
  .gx-xxxl-2 {
    --bs-gutter-x: 0.625rem; }
  .g-xxxl-2,
  .gy-xxxl-2 {
    --bs-gutter-y: 0.625rem; }
  .g-xxxl-3,
  .gx-xxxl-3 {
    --bs-gutter-x: 0.9375rem; }
  .g-xxxl-3,
  .gy-xxxl-3 {
    --bs-gutter-y: 0.9375rem; }
  .g-xxxl-4,
  .gx-xxxl-4 {
    --bs-gutter-x: 1.25rem; }
  .g-xxxl-4,
  .gy-xxxl-4 {
    --bs-gutter-y: 1.25rem; }
  .g-xxxl-5,
  .gx-xxxl-5 {
    --bs-gutter-x: 1.5625rem; }
  .g-xxxl-5,
  .gy-xxxl-5 {
    --bs-gutter-y: 1.5625rem; }
  .g-xxxl-6,
  .gx-xxxl-6 {
    --bs-gutter-x: 1.875rem; }
  .g-xxxl-6,
  .gy-xxxl-6 {
    --bs-gutter-y: 1.875rem; }
  .g-xxxl-7,
  .gx-xxxl-7 {
    --bs-gutter-x: 2.1875rem; }
  .g-xxxl-7,
  .gy-xxxl-7 {
    --bs-gutter-y: 2.1875rem; }
  .g-xxxl-8,
  .gx-xxxl-8 {
    --bs-gutter-x: 2.5rem; }
  .g-xxxl-8,
  .gy-xxxl-8 {
    --bs-gutter-y: 2.5rem; }
  .g-xxxl-9,
  .gx-xxxl-9 {
    --bs-gutter-x: 2.8125rem; }
  .g-xxxl-9,
  .gy-xxxl-9 {
    --bs-gutter-y: 2.8125rem; }
  .g-xxxl-10,
  .gx-xxxl-10 {
    --bs-gutter-x: 3.125rem; }
  .g-xxxl-10,
  .gy-xxxl-10 {
    --bs-gutter-y: 3.125rem; }
  .g-xxxl-11,
  .gx-xxxl-11 {
    --bs-gutter-x: 3.4375rem; }
  .g-xxxl-11,
  .gy-xxxl-11 {
    --bs-gutter-y: 3.4375rem; }
  .g-xxxl-12,
  .gx-xxxl-12 {
    --bs-gutter-x: 3.75rem; }
  .g-xxxl-12,
  .gy-xxxl-12 {
    --bs-gutter-y: 3.75rem; }
  .g-xxxl-13,
  .gx-xxxl-13 {
    --bs-gutter-x: 4.0625rem; }
  .g-xxxl-13,
  .gy-xxxl-13 {
    --bs-gutter-y: 4.0625rem; }
  .g-xxxl-14,
  .gx-xxxl-14 {
    --bs-gutter-x: 4.375rem; }
  .g-xxxl-14,
  .gy-xxxl-14 {
    --bs-gutter-y: 4.375rem; }
  .g-xxxl-15,
  .gx-xxxl-15 {
    --bs-gutter-x: 4.6875rem; }
  .g-xxxl-15,
  .gy-xxxl-15 {
    --bs-gutter-y: 4.6875rem; }
  .g-xxxl-16,
  .gx-xxxl-16 {
    --bs-gutter-x: 5rem; }
  .g-xxxl-16,
  .gy-xxxl-16 {
    --bs-gutter-y: 5rem; }
  .g-xxxl-17,
  .gx-xxxl-17 {
    --bs-gutter-x: 5.3125rem; }
  .g-xxxl-17,
  .gy-xxxl-17 {
    --bs-gutter-y: 5.3125rem; }
  .g-xxxl-18,
  .gx-xxxl-18 {
    --bs-gutter-x: 5.625rem; }
  .g-xxxl-18,
  .gy-xxxl-18 {
    --bs-gutter-y: 5.625rem; }
  .g-xxxl-19,
  .gx-xxxl-19 {
    --bs-gutter-x: 5.9375rem; }
  .g-xxxl-19,
  .gy-xxxl-19 {
    --bs-gutter-y: 5.9375rem; }
  .g-xxxl-20,
  .gx-xxxl-20 {
    --bs-gutter-x: 6.25rem; }
  .g-xxxl-20,
  .gy-xxxl-20 {
    --bs-gutter-y: 6.25rem; }
  .g-xxxl-21,
  .gx-xxxl-21 {
    --bs-gutter-x: 6.5625rem; }
  .g-xxxl-21,
  .gy-xxxl-21 {
    --bs-gutter-y: 6.5625rem; }
  .g-xxxl-22,
  .gx-xxxl-22 {
    --bs-gutter-x: 6.875rem; }
  .g-xxxl-22,
  .gy-xxxl-22 {
    --bs-gutter-y: 6.875rem; }
  .g-xxxl-23,
  .gx-xxxl-23 {
    --bs-gutter-x: 7.1875rem; }
  .g-xxxl-23,
  .gy-xxxl-23 {
    --bs-gutter-y: 7.1875rem; }
  .g-xxxl-24,
  .gx-xxxl-24 {
    --bs-gutter-x: 7.5rem; }
  .g-xxxl-24,
  .gy-xxxl-24 {
    --bs-gutter-y: 7.5rem; }
  .g-xxxl-25,
  .gx-xxxl-25 {
    --bs-gutter-x: 7.8125rem; }
  .g-xxxl-25,
  .gy-xxxl-25 {
    --bs-gutter-y: 7.8125rem; }
  .g-xxxl-26,
  .gx-xxxl-26 {
    --bs-gutter-x: 8.125rem; }
  .g-xxxl-26,
  .gy-xxxl-26 {
    --bs-gutter-y: 8.125rem; }
  .g-xxxl-27,
  .gx-xxxl-27 {
    --bs-gutter-x: 8.4375rem; }
  .g-xxxl-27,
  .gy-xxxl-27 {
    --bs-gutter-y: 8.4375rem; }
  .g-xxxl-28,
  .gx-xxxl-28 {
    --bs-gutter-x: 8.75rem; }
  .g-xxxl-28,
  .gy-xxxl-28 {
    --bs-gutter-y: 8.75rem; }
  .g-xxxl-29,
  .gx-xxxl-29 {
    --bs-gutter-x: 9.0625rem; }
  .g-xxxl-29,
  .gy-xxxl-29 {
    --bs-gutter-y: 9.0625rem; }
  .g-xxxl-30,
  .gx-xxxl-30 {
    --bs-gutter-x: 9.375rem; }
  .g-xxxl-30,
  .gy-xxxl-30 {
    --bs-gutter-y: 9.375rem; }
  .g-xxxl-31,
  .gx-xxxl-31 {
    --bs-gutter-x: 9.6875rem; }
  .g-xxxl-31,
  .gy-xxxl-31 {
    --bs-gutter-y: 9.6875rem; }
  .g-xxxl-32,
  .gx-xxxl-32 {
    --bs-gutter-x: 10rem; }
  .g-xxxl-32,
  .gy-xxxl-32 {
    --bs-gutter-y: 10rem; }
  .g-xxxl-33,
  .gx-xxxl-33 {
    --bs-gutter-x: 10.3125rem; }
  .g-xxxl-33,
  .gy-xxxl-33 {
    --bs-gutter-y: 10.3125rem; }
  .g-xxxl-34,
  .gx-xxxl-34 {
    --bs-gutter-x: 10.625rem; }
  .g-xxxl-34,
  .gy-xxxl-34 {
    --bs-gutter-y: 10.625rem; }
  .g-xxxl-35,
  .gx-xxxl-35 {
    --bs-gutter-x: 10.9375rem; }
  .g-xxxl-35,
  .gy-xxxl-35 {
    --bs-gutter-y: 10.9375rem; }
  .g-xxxl-36,
  .gx-xxxl-36 {
    --bs-gutter-x: 11.25rem; }
  .g-xxxl-36,
  .gy-xxxl-36 {
    --bs-gutter-y: 11.25rem; }
  .g-xxxl-37,
  .gx-xxxl-37 {
    --bs-gutter-x: 11.5625rem; }
  .g-xxxl-37,
  .gy-xxxl-37 {
    --bs-gutter-y: 11.5625rem; }
  .g-xxxl-38,
  .gx-xxxl-38 {
    --bs-gutter-x: 11.875rem; }
  .g-xxxl-38,
  .gy-xxxl-38 {
    --bs-gutter-y: 11.875rem; }
  .g-xxxl-39,
  .gx-xxxl-39 {
    --bs-gutter-x: 12.1875rem; }
  .g-xxxl-39,
  .gy-xxxl-39 {
    --bs-gutter-y: 12.1875rem; }
  .g-xxxl-40,
  .gx-xxxl-40 {
    --bs-gutter-x: 12.5rem; }
  .g-xxxl-40,
  .gy-xxxl-40 {
    --bs-gutter-y: 12.5rem; }
  .g-xxxl-base,
  .gx-xxxl-base {
    --bs-gutter-x: calc(20rem / 16); }
  .g-xxxl-base,
  .gy-xxxl-base {
    --bs-gutter-y: calc(20rem / 16); } }

.table {
  --bs-table-color: #323232;
  --bs-table-bg: transparent;
  --bs-table-border-color: #dee2e6;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #323232;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #323232;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #323232;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }

.table-group-divider {
  border-top: 2px solid currentColor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #d6d6d6;
  --bs-table-border-color: #c1c1c1;
  --bs-table-striped-bg: #cbcbcb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1c1c1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6c6c6;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #faf9f8;
  --bs-table-border-color: #e1e0df;
  --bs-table-striped-bg: #eeedec;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1e0df;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7e6e5;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #e0f1e8;
  --bs-table-border-color: #cad9d1;
  --bs-table-striped-bg: #d5e5dc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cad9d1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cfdfd7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #fffae1;
  --bs-table-border-color: #e6e1cb;
  --bs-table-striped-bg: #f2eed6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6e1cb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece7d0;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fffae1;
  --bs-table-border-color: #e6e1cb;
  --bs-table-striped-bg: #f2eed6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6e1cb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece7d0;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #323232;
  --bs-table-border-color: #474747;
  --bs-table-striped-bg: #3c3c3c;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #474747;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #414141;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1920.98px) {
  .table-responsive-xxxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #B5ADA6; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #323232;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #323232;
    background-color: #fff;
    border-color: #989998;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(49, 51, 48, 0.25); }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #B5ADA6;
    opacity: 1; }
  .form-control:disabled {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #323232;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #323232;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext:focus {
    outline: 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: 0.25rem; }
  .form-control-color::-webkit-color-swatch {
    border-radius: 0.25rem; }
  .form-control-color.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px); }
  .form-control-color.form-control-lg {
    height: calc(1.5em + 1rem + 2px); }

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 1rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #323232;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #989998;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(49, 51, 48, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    color: #B5ADA6;
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #323232; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-check-input {
      transition: none; } }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #989998;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(49, 51, 48, 0.25); }
  .form-check-input:checked {
    background-color: #313330;
    border-color: #313330; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #313330;
    border-color: #313330;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23989998'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input {
      margin-right: -2.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0rem rgba(49, 51, 48, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0rem rgba(49, 51, 48, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #313330;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #c1c2c1; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #313330;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #c1c2c1; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill,
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: 1px 0; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 5; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 5; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #323232;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 1.75rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #65BA8C; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(101, 186, 140, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #65BA8C;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2365BA8C' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #65BA8C;
    box-shadow: 0 0 0 0rem rgba(101, 186, 140, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #65BA8C; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: calc(0.75em + 2.3125rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2365BA8C' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 1.75rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #65BA8C;
    box-shadow: 0 0 0 0rem rgba(101, 186, 140, 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #65BA8C; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #65BA8C; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0rem rgba(101, 186, 140, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #65BA8C; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #DC3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #DC3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23DC3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23DC3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #DC3545;
    box-shadow: 0 0 0 0rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #DC3545; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: calc(0.75em + 2.3125rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23DC3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23DC3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 1.75rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #DC3545;
    box-shadow: 0 0 0 0rem rgba(220, 53, 69, 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #DC3545; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #DC3545; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #DC3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4; }

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #323232;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.25rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color); }
  .btn-check + .btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color); }
  .btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:focus-visible + .btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color); }
    .btn-check:checked + .btn:focus-visible,
    :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
      box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity); }

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #313330;
  --bs-btn-border-color: #313330;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2a2b29;
  --bs-btn-hover-border-color: #272926;
  --bs-btn-focus-shadow-rgb: 80, 82, 79;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #272926;
  --bs-btn-active-border-color: #252624;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #313330;
  --bs-btn-disabled-border-color: #313330; }

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #E5E1DE;
  --bs-btn-border-color: #E5E1DE;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e9e6e3;
  --bs-btn-hover-border-color: #e8e4e1;
  --bs-btn-focus-shadow-rgb: 195, 191, 189;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #eae7e5;
  --bs-btn-active-border-color: #e8e4e1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #E5E1DE;
  --bs-btn-disabled-border-color: #E5E1DE; }

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #65BA8C;
  --bs-btn-border-color: #65BA8C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #7cc49d;
  --bs-btn-hover-border-color: #74c198;
  --bs-btn-focus-shadow-rgb: 86, 158, 119;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #84c8a3;
  --bs-btn-active-border-color: #74c198;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #65BA8C;
  --bs-btn-disabled-border-color: #65BA8C; }

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #FFE569;
  --bs-btn-border-color: #FFE569;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffe980;
  --bs-btn-hover-border-color: #ffe878;
  --bs-btn-focus-shadow-rgb: 217, 195, 89;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffea87;
  --bs-btn-active-border-color: #ffe878;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FFE569;
  --bs-btn-disabled-border-color: #FFE569; }

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #FFE569;
  --bs-btn-border-color: #FFE569;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffe980;
  --bs-btn-hover-border-color: #ffe878;
  --bs-btn-focus-shadow-rgb: 217, 195, 89;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffea87;
  --bs-btn-active-border-color: #ffe878;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FFE569;
  --bs-btn-disabled-border-color: #FFE569; }

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #DC3545;
  --bs-btn-border-color: #DC3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #DC3545;
  --bs-btn-disabled-border-color: #DC3545; }

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa; }

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #323232;
  --bs-btn-border-color: #323232;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #515151;
  --bs-btn-hover-border-color: #474747;
  --bs-btn-focus-shadow-rgb: 81, 81, 81;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5b5b5b;
  --bs-btn-active-border-color: #474747;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #323232;
  --bs-btn-disabled-border-color: #323232; }

.btn-outline-primary {
  --bs-btn-color: #313330;
  --bs-btn-border-color: #313330;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #313330;
  --bs-btn-hover-border-color: #313330;
  --bs-btn-focus-shadow-rgb: 49, 51, 48;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #313330;
  --bs-btn-active-border-color: #313330;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #313330;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #313330;
  --bs-gradient: none; }

.btn-outline-secondary {
  --bs-btn-color: #E5E1DE;
  --bs-btn-border-color: #E5E1DE;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #E5E1DE;
  --bs-btn-hover-border-color: #E5E1DE;
  --bs-btn-focus-shadow-rgb: 229, 225, 222;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #E5E1DE;
  --bs-btn-active-border-color: #E5E1DE;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #E5E1DE;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #E5E1DE;
  --bs-gradient: none; }

.btn-outline-success {
  --bs-btn-color: #65BA8C;
  --bs-btn-border-color: #65BA8C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #65BA8C;
  --bs-btn-hover-border-color: #65BA8C;
  --bs-btn-focus-shadow-rgb: 101, 186, 140;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #65BA8C;
  --bs-btn-active-border-color: #65BA8C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #65BA8C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #65BA8C;
  --bs-gradient: none; }

.btn-outline-info {
  --bs-btn-color: #FFE569;
  --bs-btn-border-color: #FFE569;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FFE569;
  --bs-btn-hover-border-color: #FFE569;
  --bs-btn-focus-shadow-rgb: 255, 229, 105;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FFE569;
  --bs-btn-active-border-color: #FFE569;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFE569;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFE569;
  --bs-gradient: none; }

.btn-outline-warning {
  --bs-btn-color: #FFE569;
  --bs-btn-border-color: #FFE569;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FFE569;
  --bs-btn-hover-border-color: #FFE569;
  --bs-btn-focus-shadow-rgb: 255, 229, 105;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FFE569;
  --bs-btn-active-border-color: #FFE569;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFE569;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFE569;
  --bs-gradient: none; }

.btn-outline-danger {
  --bs-btn-color: #DC3545;
  --bs-btn-border-color: #DC3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #DC3545;
  --bs-btn-hover-border-color: #DC3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #DC3545;
  --bs-btn-active-border-color: #DC3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #DC3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #DC3545;
  --bs-gradient: none; }

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none; }

.btn-outline-dark {
  --bs-btn-color: #323232;
  --bs-btn-border-color: #323232;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #323232;
  --bs-btn-hover-border-color: #323232;
  --bs-btn-focus-shadow-rgb: 50, 50, 50;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #323232;
  --bs-btn-active-border-color: #323232;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #323232;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #323232;
  --bs-gradient: none; }

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #313330;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #272926;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #272926;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #B5ADA6;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 80, 82, 79;
  text-decoration: underline; }
  .btn-link:focus-visible {
    color: var(--bs-btn-color); }
  .btn-link:hover {
    color: var(--bs-btn-hover-color); }

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.2rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #323232;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: rgba(0, 0, 0, 0.15);
  --bs-dropdown-border-radius: 0.25rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.25rem - 1px);
  --bs-dropdown-divider-bg: rgba(0, 0, 0, 0.15);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #323232;
  --bs-dropdown-link-hover-color: #2d2d2d;
  --bs-dropdown-link-hover-bg: #f8f9fa;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #313330;
  --bs-dropdown-link-disabled-color: #B5ADA6;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #B5ADA6;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1921px) {
  .dropdown-menu-xxxl-start {
    --bs-position: start; }
    .dropdown-menu-xxxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxxl-end {
    --bs-position: end; }
    .dropdown-menu-xxxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer); }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer); }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer); }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color); }

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: rgba(0, 0, 0, 0.15);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #313330;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group {
  border-radius: 0.25rem; }
  .btn-group > :not(.btn-check:first-child) + .btn,
  .btn-group > .btn-group:not(:first-child) {
    margin-left: -1px; }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn.dropdown-toggle-split:first-child,
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn,
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #B5ADA6;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: var(--bs-nav-link-hover-color); }
  .nav-link.disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.25rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color); }
  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    background: none;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius); }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: var(--bs-nav-tabs-link-hover-border-color); }
    .nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color); }
  .nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills {
  --bs-nav-pills-border-radius: 0.25rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #313330; }
  .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius); }
    .nav-pills .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg); }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: rgba(0, 0, 0, 0.125);
  --bs-card-border-radius: 0.25rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.25rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: var(--bs-card-inner-border-radius);
      border-top-right-radius: var(--bs-card-inner-border-radius); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: var(--bs-card-inner-border-radius);
      border-bottom-left-radius: var(--bs-card-inner-border-radius); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color); }

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y); }

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x); }

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0; }

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius); }

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg); }

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x)); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius); }

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin); }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion {
  --bs-accordion-color: #323232;
  --bs-accordion-bg: transparent;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: rgba(0, 0, 0, 0.125);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: calc(4rem / 16);
  --bs-accordion-inner-border-radius: calc(calc(4rem / 16) - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #323232;
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23323232'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232c2e2b'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #989998;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0rem rgba(49, 51, 48, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #2c2e2b;
  --bs-accordion-active-bg: #eaebea; }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition); }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); }
    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform); }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition); }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); }
  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius); }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius); }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
      border-bottom-left-radius: var(--bs-accordion-inner-border-radius); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: var(--bs-accordion-border-radius);
      border-bottom-left-radius: var(--bs-accordion-border-radius); }

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
  font-size: calc(10rem / 16);
  color: #B5ADA6; }
  @media screen and (min-width: 768px) {
    .breadcrumb {
      font-size: calc(12rem / 16); } }

.breadcrumb-item + .breadcrumb-item {
  padding-left: calc(8rem / 16); }
  @media screen and (min-width: 768px) {
    .breadcrumb-item + .breadcrumb-item {
      padding-left: calc(20rem / 16); } }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: calc(8rem / 16);
    color: #B5ADA6;
    content: var(--icon-arrow-right);
    font-family: iconfont;
    font-size: calc(8rem / 16); }
    @media screen and (min-width: 768px) {
      .breadcrumb-item + .breadcrumb-item::before {
        margin-top: calc(2rem / 16);
        padding-right: calc(20rem / 16); } }

.breadcrumb-item.active {
  color: #323232; }

.breadcrumb-container {
  margin: calc(16rem / 16) 0 calc(24rem / 16); }
  @media screen and (min-width: 768px) {
    .breadcrumb-container {
      margin: calc(28rem / 16) 0; } }

.breadcrumb-container + .slide,
.breadcrumb-container + .slide-sm,
.breadcrumb-container + .pimcore_area_content > .slide,
.breadcrumb-container + .pimcore_area_content > .slide-sm {
  margin-top: 0; }

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: #313330;
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.25rem;
  --bs-pagination-hover-color: #272926;
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: #272926;
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0rem rgba(49, 51, 48, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #313330;
  --bs-pagination-active-border-color: #313330;
  --bs-pagination-disabled-color: #B5ADA6;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius); }

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius); }

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.3rem; }

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.2rem; }

.badge {
  display: inline-block;
  line-height: 1.2;
  font-size: calc(10rem / 16);
  color: #fff;
  padding: calc(5rem / 16) calc(3rem / 16) calc(3rem / 16) calc(5rem / 16);
  letter-spacing: calc(2rem / 16);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-family: var(--font-default-compact);
  font-weight: var(--font-default-compact-weight);
  font-style: var(--font-default-compact-style);
  text-transform: uppercase; }
  .badge:empty {
    display: none; }
  @media screen and (min-width: 768px) {
    .badge {
      font-size: calc(14rem / 16);
      letter-spacing: calc(3rem / 16);
      padding: calc(10rem / 16) calc(11rem / 16) calc(6rem / 16); } }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-sm {
  font-size: calc(8rem / 16);
  letter-spacing: calc(1rem / 16);
  padding: calc(5rem / 16) calc(4rem / 16) calc(3rem / 16) calc(5rem / 16); }

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.25rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius); }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  --bs-alert-color: #1d1f1d;
  --bs-alert-bg: #d6d6d6;
  --bs-alert-border-color: #c1c2c1; }
  .alert-primary .alert-link {
    color: #171917; }

.alert-secondary {
  --bs-alert-color: #5c5a59;
  --bs-alert-bg: #faf9f8;
  --bs-alert-border-color: #f7f6f5; }
  .alert-secondary .alert-link {
    color: #4a4847; }

.alert-success {
  --bs-alert-color: #3d7054;
  --bs-alert-bg: #e0f1e8;
  --bs-alert-border-color: #d1eadd; }
  .alert-success .alert-link {
    color: #315a43; }

.alert-info {
  --bs-alert-color: #665c2a;
  --bs-alert-bg: #fffae1;
  --bs-alert-border-color: #fff7d2; }
  .alert-info .alert-link {
    color: #524a22; }

.alert-warning {
  --bs-alert-color: #665c2a;
  --bs-alert-bg: #fffae1;
  --bs-alert-border-color: #fff7d2; }
  .alert-warning .alert-link {
    color: #524a22; }

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7; }
  .alert-danger .alert-link {
    color: #6a1a21; }

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe; }
  .alert-light .alert-link {
    color: #4f5050; }

.alert-dark {
  --bs-alert-color: #1e1e1e;
  --bs-alert-bg: #d6d6d6;
  --bs-alert-border-color: #c2c2c2; }
  .alert-dark .alert-link {
    color: #181818; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.25rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #313330;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition); }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height); }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  --bs-list-group-color: #323232;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.25rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #323232;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #B5ADA6;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #313330;
  --bs-list-group-active-border-color: #313330;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius); }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(-1 * var(--bs-list-group-border-width));
      border-top-width: var(--bs-list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--bs-list-group-border-width));
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1921px) {
  .list-group-horizontal-xxxl {
    flex-direction: row; }
    .list-group-horizontal-xxxl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxxl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width); }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #1d1f1d;
  background-color: #d6d6d6; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #1d1f1d;
    background-color: #c1c1c1; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #1d1f1d;
    border-color: #1d1f1d; }

.list-group-item-secondary {
  color: #5c5a59;
  background-color: #faf9f8; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #5c5a59;
    background-color: #e1e0df; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #5c5a59;
    border-color: #5c5a59; }

.list-group-item-success {
  color: #3d7054;
  background-color: #e0f1e8; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #3d7054;
    background-color: #cad9d1; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #3d7054;
    border-color: #3d7054; }

.list-group-item-info {
  color: #665c2a;
  background-color: #fffae1; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #665c2a;
    background-color: #e6e1cb; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #665c2a;
    border-color: #665c2a; }

.list-group-item-warning {
  color: #665c2a;
  background-color: #fffae1; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #665c2a;
    background-color: #e6e1cb; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #665c2a;
    border-color: #665c2a; }

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #842029;
    background-color: #dfc2c4; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029; }

.list-group-item-light {
  color: #636464;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #636464;
    background-color: #e5e5e5; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #636464;
    border-color: #636464; }

.list-group-item-dark {
  color: #1e1e1e;
  background-color: #d6d6d6; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1e1e1e;
    background-color: #c1c1c1; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1e1e1e;
    border-color: #1e1e1e; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(49, 51, 48, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 10px;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: rgba(0, 0, 0, 0.1);
  --bs-toast-border-radius: 0.25rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #B5ADA6;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius); }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: var(--bs-toast-spacing); }

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width)); }
  .toast-header .btn-close {
    margin-right: calc(-.5 * var(--bs-toast-padding-x));
    margin-left: var(--bs-toast-padding-x); }

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word; }

.modal {
  --bs-modal-zindex: 1050;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: rgba(0, 0, 0, 0.2);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.3rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.3rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: #dee2e6;
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: #dee2e6;
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0; }

.modal-backdrop {
  --bs-backdrop-zindex: 1040;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity); }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius); }
  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto; }

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height); }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding); }

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius); }
  .modal-footer > * {
    margin: calc(var(--bs-modal-footer-gap) * .5); }

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto; }
  .modal-sm {
    --bs-modal-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header,
  .modal-fullscreen .modal-footer {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header,
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header,
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header,
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header,
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header,
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1920.98px) {
  .modal-fullscreen-xxxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxxl-down .modal-header,
    .modal-fullscreen-xxxl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xxxl-down .modal-body {
      overflow-y: auto; } }

.tooltip {
  --bs-tooltip-zindex: 1070;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: 0;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.25rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: var(--bs-tooltip-opacity); }
  .tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height); }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0; }
  .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0; }
  .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius); }

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1060;
  display: block;
  max-width: 303px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-family: var(--font-default);
  font-size: calc(14rem / 16);
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.3rem;
  padding: calc(15rem / 16);
  letter-spacing: calc(.5rem / 16); }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }
  @media screen and (min-width: 768px) {
    .popover {
      font-size: calc(16rem / 16);
      padding: calc(20rem / 16); } }

.bs-popover-top, .bs-popover-auto[data-popper-placement^="top"] {
  margin-bottom: 0.5rem !important; }
  .bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-end, .bs-popover-auto[data-popper-placement^="right"] {
  margin-left: 0.5rem !important; }
  .bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[data-popper-placement^="bottom"] {
  margin-top: 0.5rem !important; }
  .bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid transparent; }

.bs-popover-start, .bs-popover-auto[data-popper-placement^="left"] {
  margin-right: 0.5rem !important; }
  .bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  font-size: 1rem;
  letter-spacing: calc(.5rem / 16);
  margin-bottom: 0; }
  .popover-header:empty {
    display: none; }

.popover-body {
  color: #323232; }

.popover-header + .popover-body {
  margin-top: calc(6rem / 16); }
  @media screen and (min-width: 768px) {
    .popover-header + .popover-body {
      margin-top: calc(10rem / 16); } }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-0 {
  --bs-border-width: 0; }

.border-1 {
  --bs-border-width: 1px; }

.border-2 {
  --bs-border-width: 2px; }

.border-3 {
  --bs-border-width: 3px; }

.border-4 {
  --bs-border-width: 4px; }

.border-5 {
  --bs-border-width: 5px; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.3125rem !important; }

.m-2 {
  margin: 0.625rem !important; }

.m-3 {
  margin: 0.9375rem !important; }

.m-4 {
  margin: 1.25rem !important; }

.m-5 {
  margin: 1.5625rem !important; }

.m-6 {
  margin: 1.875rem !important; }

.m-7 {
  margin: 2.1875rem !important; }

.m-8 {
  margin: 2.5rem !important; }

.m-9 {
  margin: 2.8125rem !important; }

.m-10 {
  margin: 3.125rem !important; }

.m-11 {
  margin: 3.4375rem !important; }

.m-12 {
  margin: 3.75rem !important; }

.m-13 {
  margin: 4.0625rem !important; }

.m-14 {
  margin: 4.375rem !important; }

.m-15 {
  margin: 4.6875rem !important; }

.m-16 {
  margin: 5rem !important; }

.m-17 {
  margin: 5.3125rem !important; }

.m-18 {
  margin: 5.625rem !important; }

.m-19 {
  margin: 5.9375rem !important; }

.m-20 {
  margin: 6.25rem !important; }

.m-21 {
  margin: 6.5625rem !important; }

.m-22 {
  margin: 6.875rem !important; }

.m-23 {
  margin: 7.1875rem !important; }

.m-24 {
  margin: 7.5rem !important; }

.m-25 {
  margin: 7.8125rem !important; }

.m-26 {
  margin: 8.125rem !important; }

.m-27 {
  margin: 8.4375rem !important; }

.m-28 {
  margin: 8.75rem !important; }

.m-29 {
  margin: 9.0625rem !important; }

.m-30 {
  margin: 9.375rem !important; }

.m-31 {
  margin: 9.6875rem !important; }

.m-32 {
  margin: 10rem !important; }

.m-33 {
  margin: 10.3125rem !important; }

.m-34 {
  margin: 10.625rem !important; }

.m-35 {
  margin: 10.9375rem !important; }

.m-36 {
  margin: 11.25rem !important; }

.m-37 {
  margin: 11.5625rem !important; }

.m-38 {
  margin: 11.875rem !important; }

.m-39 {
  margin: 12.1875rem !important; }

.m-40 {
  margin: 12.5rem !important; }

.m-base {
  margin: calc(20rem / 16) !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.3125rem !important;
  margin-left: 0.3125rem !important; }

.mx-2 {
  margin-right: 0.625rem !important;
  margin-left: 0.625rem !important; }

.mx-3 {
  margin-right: 0.9375rem !important;
  margin-left: 0.9375rem !important; }

.mx-4 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important; }

.mx-5 {
  margin-right: 1.5625rem !important;
  margin-left: 1.5625rem !important; }

.mx-6 {
  margin-right: 1.875rem !important;
  margin-left: 1.875rem !important; }

.mx-7 {
  margin-right: 2.1875rem !important;
  margin-left: 2.1875rem !important; }

.mx-8 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important; }

.mx-9 {
  margin-right: 2.8125rem !important;
  margin-left: 2.8125rem !important; }

.mx-10 {
  margin-right: 3.125rem !important;
  margin-left: 3.125rem !important; }

.mx-11 {
  margin-right: 3.4375rem !important;
  margin-left: 3.4375rem !important; }

.mx-12 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important; }

.mx-13 {
  margin-right: 4.0625rem !important;
  margin-left: 4.0625rem !important; }

.mx-14 {
  margin-right: 4.375rem !important;
  margin-left: 4.375rem !important; }

.mx-15 {
  margin-right: 4.6875rem !important;
  margin-left: 4.6875rem !important; }

.mx-16 {
  margin-right: 5rem !important;
  margin-left: 5rem !important; }

.mx-17 {
  margin-right: 5.3125rem !important;
  margin-left: 5.3125rem !important; }

.mx-18 {
  margin-right: 5.625rem !important;
  margin-left: 5.625rem !important; }

.mx-19 {
  margin-right: 5.9375rem !important;
  margin-left: 5.9375rem !important; }

.mx-20 {
  margin-right: 6.25rem !important;
  margin-left: 6.25rem !important; }

.mx-21 {
  margin-right: 6.5625rem !important;
  margin-left: 6.5625rem !important; }

.mx-22 {
  margin-right: 6.875rem !important;
  margin-left: 6.875rem !important; }

.mx-23 {
  margin-right: 7.1875rem !important;
  margin-left: 7.1875rem !important; }

.mx-24 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important; }

.mx-25 {
  margin-right: 7.8125rem !important;
  margin-left: 7.8125rem !important; }

.mx-26 {
  margin-right: 8.125rem !important;
  margin-left: 8.125rem !important; }

.mx-27 {
  margin-right: 8.4375rem !important;
  margin-left: 8.4375rem !important; }

.mx-28 {
  margin-right: 8.75rem !important;
  margin-left: 8.75rem !important; }

.mx-29 {
  margin-right: 9.0625rem !important;
  margin-left: 9.0625rem !important; }

.mx-30 {
  margin-right: 9.375rem !important;
  margin-left: 9.375rem !important; }

.mx-31 {
  margin-right: 9.6875rem !important;
  margin-left: 9.6875rem !important; }

.mx-32 {
  margin-right: 10rem !important;
  margin-left: 10rem !important; }

.mx-33 {
  margin-right: 10.3125rem !important;
  margin-left: 10.3125rem !important; }

.mx-34 {
  margin-right: 10.625rem !important;
  margin-left: 10.625rem !important; }

.mx-35 {
  margin-right: 10.9375rem !important;
  margin-left: 10.9375rem !important; }

.mx-36 {
  margin-right: 11.25rem !important;
  margin-left: 11.25rem !important; }

.mx-37 {
  margin-right: 11.5625rem !important;
  margin-left: 11.5625rem !important; }

.mx-38 {
  margin-right: 11.875rem !important;
  margin-left: 11.875rem !important; }

.mx-39 {
  margin-right: 12.1875rem !important;
  margin-left: 12.1875rem !important; }

.mx-40 {
  margin-right: 12.5rem !important;
  margin-left: 12.5rem !important; }

.mx-base {
  margin-right: calc(20rem / 16) !important;
  margin-left: calc(20rem / 16) !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.3125rem !important;
  margin-bottom: 0.3125rem !important; }

.my-2 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important; }

.my-3 {
  margin-top: 0.9375rem !important;
  margin-bottom: 0.9375rem !important; }

.my-4 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important; }

.my-5 {
  margin-top: 1.5625rem !important;
  margin-bottom: 1.5625rem !important; }

.my-6 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important; }

.my-7 {
  margin-top: 2.1875rem !important;
  margin-bottom: 2.1875rem !important; }

.my-8 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important; }

.my-9 {
  margin-top: 2.8125rem !important;
  margin-bottom: 2.8125rem !important; }

.my-10 {
  margin-top: 3.125rem !important;
  margin-bottom: 3.125rem !important; }

.my-11 {
  margin-top: 3.4375rem !important;
  margin-bottom: 3.4375rem !important; }

.my-12 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important; }

.my-13 {
  margin-top: 4.0625rem !important;
  margin-bottom: 4.0625rem !important; }

.my-14 {
  margin-top: 4.375rem !important;
  margin-bottom: 4.375rem !important; }

.my-15 {
  margin-top: 4.6875rem !important;
  margin-bottom: 4.6875rem !important; }

.my-16 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important; }

.my-17 {
  margin-top: 5.3125rem !important;
  margin-bottom: 5.3125rem !important; }

.my-18 {
  margin-top: 5.625rem !important;
  margin-bottom: 5.625rem !important; }

.my-19 {
  margin-top: 5.9375rem !important;
  margin-bottom: 5.9375rem !important; }

.my-20 {
  margin-top: 6.25rem !important;
  margin-bottom: 6.25rem !important; }

.my-21 {
  margin-top: 6.5625rem !important;
  margin-bottom: 6.5625rem !important; }

.my-22 {
  margin-top: 6.875rem !important;
  margin-bottom: 6.875rem !important; }

.my-23 {
  margin-top: 7.1875rem !important;
  margin-bottom: 7.1875rem !important; }

.my-24 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important; }

.my-25 {
  margin-top: 7.8125rem !important;
  margin-bottom: 7.8125rem !important; }

.my-26 {
  margin-top: 8.125rem !important;
  margin-bottom: 8.125rem !important; }

.my-27 {
  margin-top: 8.4375rem !important;
  margin-bottom: 8.4375rem !important; }

.my-28 {
  margin-top: 8.75rem !important;
  margin-bottom: 8.75rem !important; }

.my-29 {
  margin-top: 9.0625rem !important;
  margin-bottom: 9.0625rem !important; }

.my-30 {
  margin-top: 9.375rem !important;
  margin-bottom: 9.375rem !important; }

.my-31 {
  margin-top: 9.6875rem !important;
  margin-bottom: 9.6875rem !important; }

.my-32 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important; }

.my-33 {
  margin-top: 10.3125rem !important;
  margin-bottom: 10.3125rem !important; }

.my-34 {
  margin-top: 10.625rem !important;
  margin-bottom: 10.625rem !important; }

.my-35 {
  margin-top: 10.9375rem !important;
  margin-bottom: 10.9375rem !important; }

.my-36 {
  margin-top: 11.25rem !important;
  margin-bottom: 11.25rem !important; }

.my-37 {
  margin-top: 11.5625rem !important;
  margin-bottom: 11.5625rem !important; }

.my-38 {
  margin-top: 11.875rem !important;
  margin-bottom: 11.875rem !important; }

.my-39 {
  margin-top: 12.1875rem !important;
  margin-bottom: 12.1875rem !important; }

.my-40 {
  margin-top: 12.5rem !important;
  margin-bottom: 12.5rem !important; }

.my-base {
  margin-top: calc(20rem / 16) !important;
  margin-bottom: calc(20rem / 16) !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.3125rem !important; }

.mt-2 {
  margin-top: 0.625rem !important; }

.mt-3 {
  margin-top: 0.9375rem !important; }

.mt-4 {
  margin-top: 1.25rem !important; }

.mt-5 {
  margin-top: 1.5625rem !important; }

.mt-6 {
  margin-top: 1.875rem !important; }

.mt-7 {
  margin-top: 2.1875rem !important; }

.mt-8 {
  margin-top: 2.5rem !important; }

.mt-9 {
  margin-top: 2.8125rem !important; }

.mt-10 {
  margin-top: 3.125rem !important; }

.mt-11 {
  margin-top: 3.4375rem !important; }

.mt-12 {
  margin-top: 3.75rem !important; }

.mt-13 {
  margin-top: 4.0625rem !important; }

.mt-14 {
  margin-top: 4.375rem !important; }

.mt-15 {
  margin-top: 4.6875rem !important; }

.mt-16 {
  margin-top: 5rem !important; }

.mt-17 {
  margin-top: 5.3125rem !important; }

.mt-18 {
  margin-top: 5.625rem !important; }

.mt-19 {
  margin-top: 5.9375rem !important; }

.mt-20 {
  margin-top: 6.25rem !important; }

.mt-21 {
  margin-top: 6.5625rem !important; }

.mt-22 {
  margin-top: 6.875rem !important; }

.mt-23 {
  margin-top: 7.1875rem !important; }

.mt-24 {
  margin-top: 7.5rem !important; }

.mt-25 {
  margin-top: 7.8125rem !important; }

.mt-26 {
  margin-top: 8.125rem !important; }

.mt-27 {
  margin-top: 8.4375rem !important; }

.mt-28 {
  margin-top: 8.75rem !important; }

.mt-29 {
  margin-top: 9.0625rem !important; }

.mt-30 {
  margin-top: 9.375rem !important; }

.mt-31 {
  margin-top: 9.6875rem !important; }

.mt-32 {
  margin-top: 10rem !important; }

.mt-33 {
  margin-top: 10.3125rem !important; }

.mt-34 {
  margin-top: 10.625rem !important; }

.mt-35 {
  margin-top: 10.9375rem !important; }

.mt-36 {
  margin-top: 11.25rem !important; }

.mt-37 {
  margin-top: 11.5625rem !important; }

.mt-38 {
  margin-top: 11.875rem !important; }

.mt-39 {
  margin-top: 12.1875rem !important; }

.mt-40 {
  margin-top: 12.5rem !important; }

.mt-base {
  margin-top: calc(20rem / 16) !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.3125rem !important; }

.me-2 {
  margin-right: 0.625rem !important; }

.me-3 {
  margin-right: 0.9375rem !important; }

.me-4 {
  margin-right: 1.25rem !important; }

.me-5 {
  margin-right: 1.5625rem !important; }

.me-6 {
  margin-right: 1.875rem !important; }

.me-7 {
  margin-right: 2.1875rem !important; }

.me-8 {
  margin-right: 2.5rem !important; }

.me-9 {
  margin-right: 2.8125rem !important; }

.me-10 {
  margin-right: 3.125rem !important; }

.me-11 {
  margin-right: 3.4375rem !important; }

.me-12 {
  margin-right: 3.75rem !important; }

.me-13 {
  margin-right: 4.0625rem !important; }

.me-14 {
  margin-right: 4.375rem !important; }

.me-15 {
  margin-right: 4.6875rem !important; }

.me-16 {
  margin-right: 5rem !important; }

.me-17 {
  margin-right: 5.3125rem !important; }

.me-18 {
  margin-right: 5.625rem !important; }

.me-19 {
  margin-right: 5.9375rem !important; }

.me-20 {
  margin-right: 6.25rem !important; }

.me-21 {
  margin-right: 6.5625rem !important; }

.me-22 {
  margin-right: 6.875rem !important; }

.me-23 {
  margin-right: 7.1875rem !important; }

.me-24 {
  margin-right: 7.5rem !important; }

.me-25 {
  margin-right: 7.8125rem !important; }

.me-26 {
  margin-right: 8.125rem !important; }

.me-27 {
  margin-right: 8.4375rem !important; }

.me-28 {
  margin-right: 8.75rem !important; }

.me-29 {
  margin-right: 9.0625rem !important; }

.me-30 {
  margin-right: 9.375rem !important; }

.me-31 {
  margin-right: 9.6875rem !important; }

.me-32 {
  margin-right: 10rem !important; }

.me-33 {
  margin-right: 10.3125rem !important; }

.me-34 {
  margin-right: 10.625rem !important; }

.me-35 {
  margin-right: 10.9375rem !important; }

.me-36 {
  margin-right: 11.25rem !important; }

.me-37 {
  margin-right: 11.5625rem !important; }

.me-38 {
  margin-right: 11.875rem !important; }

.me-39 {
  margin-right: 12.1875rem !important; }

.me-40 {
  margin-right: 12.5rem !important; }

.me-base {
  margin-right: calc(20rem / 16) !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.3125rem !important; }

.mb-2 {
  margin-bottom: 0.625rem !important; }

.mb-3 {
  margin-bottom: 0.9375rem !important; }

.mb-4 {
  margin-bottom: 1.25rem !important; }

.mb-5 {
  margin-bottom: 1.5625rem !important; }

.mb-6 {
  margin-bottom: 1.875rem !important; }

.mb-7 {
  margin-bottom: 2.1875rem !important; }

.mb-8 {
  margin-bottom: 2.5rem !important; }

.mb-9 {
  margin-bottom: 2.8125rem !important; }

.mb-10 {
  margin-bottom: 3.125rem !important; }

.mb-11 {
  margin-bottom: 3.4375rem !important; }

.mb-12 {
  margin-bottom: 3.75rem !important; }

.mb-13 {
  margin-bottom: 4.0625rem !important; }

.mb-14 {
  margin-bottom: 4.375rem !important; }

.mb-15 {
  margin-bottom: 4.6875rem !important; }

.mb-16 {
  margin-bottom: 5rem !important; }

.mb-17 {
  margin-bottom: 5.3125rem !important; }

.mb-18 {
  margin-bottom: 5.625rem !important; }

.mb-19 {
  margin-bottom: 5.9375rem !important; }

.mb-20 {
  margin-bottom: 6.25rem !important; }

.mb-21 {
  margin-bottom: 6.5625rem !important; }

.mb-22 {
  margin-bottom: 6.875rem !important; }

.mb-23 {
  margin-bottom: 7.1875rem !important; }

.mb-24 {
  margin-bottom: 7.5rem !important; }

.mb-25 {
  margin-bottom: 7.8125rem !important; }

.mb-26 {
  margin-bottom: 8.125rem !important; }

.mb-27 {
  margin-bottom: 8.4375rem !important; }

.mb-28 {
  margin-bottom: 8.75rem !important; }

.mb-29 {
  margin-bottom: 9.0625rem !important; }

.mb-30 {
  margin-bottom: 9.375rem !important; }

.mb-31 {
  margin-bottom: 9.6875rem !important; }

.mb-32 {
  margin-bottom: 10rem !important; }

.mb-33 {
  margin-bottom: 10.3125rem !important; }

.mb-34 {
  margin-bottom: 10.625rem !important; }

.mb-35 {
  margin-bottom: 10.9375rem !important; }

.mb-36 {
  margin-bottom: 11.25rem !important; }

.mb-37 {
  margin-bottom: 11.5625rem !important; }

.mb-38 {
  margin-bottom: 11.875rem !important; }

.mb-39 {
  margin-bottom: 12.1875rem !important; }

.mb-40 {
  margin-bottom: 12.5rem !important; }

.mb-base {
  margin-bottom: calc(20rem / 16) !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.3125rem !important; }

.ms-2 {
  margin-left: 0.625rem !important; }

.ms-3 {
  margin-left: 0.9375rem !important; }

.ms-4 {
  margin-left: 1.25rem !important; }

.ms-5 {
  margin-left: 1.5625rem !important; }

.ms-6 {
  margin-left: 1.875rem !important; }

.ms-7 {
  margin-left: 2.1875rem !important; }

.ms-8 {
  margin-left: 2.5rem !important; }

.ms-9 {
  margin-left: 2.8125rem !important; }

.ms-10 {
  margin-left: 3.125rem !important; }

.ms-11 {
  margin-left: 3.4375rem !important; }

.ms-12 {
  margin-left: 3.75rem !important; }

.ms-13 {
  margin-left: 4.0625rem !important; }

.ms-14 {
  margin-left: 4.375rem !important; }

.ms-15 {
  margin-left: 4.6875rem !important; }

.ms-16 {
  margin-left: 5rem !important; }

.ms-17 {
  margin-left: 5.3125rem !important; }

.ms-18 {
  margin-left: 5.625rem !important; }

.ms-19 {
  margin-left: 5.9375rem !important; }

.ms-20 {
  margin-left: 6.25rem !important; }

.ms-21 {
  margin-left: 6.5625rem !important; }

.ms-22 {
  margin-left: 6.875rem !important; }

.ms-23 {
  margin-left: 7.1875rem !important; }

.ms-24 {
  margin-left: 7.5rem !important; }

.ms-25 {
  margin-left: 7.8125rem !important; }

.ms-26 {
  margin-left: 8.125rem !important; }

.ms-27 {
  margin-left: 8.4375rem !important; }

.ms-28 {
  margin-left: 8.75rem !important; }

.ms-29 {
  margin-left: 9.0625rem !important; }

.ms-30 {
  margin-left: 9.375rem !important; }

.ms-31 {
  margin-left: 9.6875rem !important; }

.ms-32 {
  margin-left: 10rem !important; }

.ms-33 {
  margin-left: 10.3125rem !important; }

.ms-34 {
  margin-left: 10.625rem !important; }

.ms-35 {
  margin-left: 10.9375rem !important; }

.ms-36 {
  margin-left: 11.25rem !important; }

.ms-37 {
  margin-left: 11.5625rem !important; }

.ms-38 {
  margin-left: 11.875rem !important; }

.ms-39 {
  margin-left: 12.1875rem !important; }

.ms-40 {
  margin-left: 12.5rem !important; }

.ms-base {
  margin-left: calc(20rem / 16) !important; }

.ms-auto {
  margin-left: auto !important; }

.m-n1 {
  margin: -0.3125rem !important; }

.m-n2 {
  margin: -0.625rem !important; }

.m-n3 {
  margin: -0.9375rem !important; }

.m-n4 {
  margin: -1.25rem !important; }

.m-n5 {
  margin: -1.5625rem !important; }

.m-n6 {
  margin: -1.875rem !important; }

.m-n7 {
  margin: -2.1875rem !important; }

.m-n8 {
  margin: -2.5rem !important; }

.m-n9 {
  margin: -2.8125rem !important; }

.m-n10 {
  margin: -3.125rem !important; }

.m-n11 {
  margin: -3.4375rem !important; }

.m-n12 {
  margin: -3.75rem !important; }

.m-n13 {
  margin: -4.0625rem !important; }

.m-n14 {
  margin: -4.375rem !important; }

.m-n15 {
  margin: -4.6875rem !important; }

.m-n16 {
  margin: -5rem !important; }

.m-n17 {
  margin: -5.3125rem !important; }

.m-n18 {
  margin: -5.625rem !important; }

.m-n19 {
  margin: -5.9375rem !important; }

.m-n20 {
  margin: -6.25rem !important; }

.m-n21 {
  margin: -6.5625rem !important; }

.m-n22 {
  margin: -6.875rem !important; }

.m-n23 {
  margin: -7.1875rem !important; }

.m-n24 {
  margin: -7.5rem !important; }

.m-n25 {
  margin: -7.8125rem !important; }

.m-n26 {
  margin: -8.125rem !important; }

.m-n27 {
  margin: -8.4375rem !important; }

.m-n28 {
  margin: -8.75rem !important; }

.m-n29 {
  margin: -9.0625rem !important; }

.m-n30 {
  margin: -9.375rem !important; }

.m-n31 {
  margin: -9.6875rem !important; }

.m-n32 {
  margin: -10rem !important; }

.m-n33 {
  margin: -10.3125rem !important; }

.m-n34 {
  margin: -10.625rem !important; }

.m-n35 {
  margin: -10.9375rem !important; }

.m-n36 {
  margin: -11.25rem !important; }

.m-n37 {
  margin: -11.5625rem !important; }

.m-n38 {
  margin: -11.875rem !important; }

.m-n39 {
  margin: -12.1875rem !important; }

.m-n40 {
  margin: -12.5rem !important; }

.m-nbase {
  margin: -calc(20rem / 16) !important; }

.mx-n1 {
  margin-right: -0.3125rem !important;
  margin-left: -0.3125rem !important; }

.mx-n2 {
  margin-right: -0.625rem !important;
  margin-left: -0.625rem !important; }

.mx-n3 {
  margin-right: -0.9375rem !important;
  margin-left: -0.9375rem !important; }

.mx-n4 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important; }

.mx-n5 {
  margin-right: -1.5625rem !important;
  margin-left: -1.5625rem !important; }

.mx-n6 {
  margin-right: -1.875rem !important;
  margin-left: -1.875rem !important; }

.mx-n7 {
  margin-right: -2.1875rem !important;
  margin-left: -2.1875rem !important; }

.mx-n8 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important; }

.mx-n9 {
  margin-right: -2.8125rem !important;
  margin-left: -2.8125rem !important; }

.mx-n10 {
  margin-right: -3.125rem !important;
  margin-left: -3.125rem !important; }

.mx-n11 {
  margin-right: -3.4375rem !important;
  margin-left: -3.4375rem !important; }

.mx-n12 {
  margin-right: -3.75rem !important;
  margin-left: -3.75rem !important; }

.mx-n13 {
  margin-right: -4.0625rem !important;
  margin-left: -4.0625rem !important; }

.mx-n14 {
  margin-right: -4.375rem !important;
  margin-left: -4.375rem !important; }

.mx-n15 {
  margin-right: -4.6875rem !important;
  margin-left: -4.6875rem !important; }

.mx-n16 {
  margin-right: -5rem !important;
  margin-left: -5rem !important; }

.mx-n17 {
  margin-right: -5.3125rem !important;
  margin-left: -5.3125rem !important; }

.mx-n18 {
  margin-right: -5.625rem !important;
  margin-left: -5.625rem !important; }

.mx-n19 {
  margin-right: -5.9375rem !important;
  margin-left: -5.9375rem !important; }

.mx-n20 {
  margin-right: -6.25rem !important;
  margin-left: -6.25rem !important; }

.mx-n21 {
  margin-right: -6.5625rem !important;
  margin-left: -6.5625rem !important; }

.mx-n22 {
  margin-right: -6.875rem !important;
  margin-left: -6.875rem !important; }

.mx-n23 {
  margin-right: -7.1875rem !important;
  margin-left: -7.1875rem !important; }

.mx-n24 {
  margin-right: -7.5rem !important;
  margin-left: -7.5rem !important; }

.mx-n25 {
  margin-right: -7.8125rem !important;
  margin-left: -7.8125rem !important; }

.mx-n26 {
  margin-right: -8.125rem !important;
  margin-left: -8.125rem !important; }

.mx-n27 {
  margin-right: -8.4375rem !important;
  margin-left: -8.4375rem !important; }

.mx-n28 {
  margin-right: -8.75rem !important;
  margin-left: -8.75rem !important; }

.mx-n29 {
  margin-right: -9.0625rem !important;
  margin-left: -9.0625rem !important; }

.mx-n30 {
  margin-right: -9.375rem !important;
  margin-left: -9.375rem !important; }

.mx-n31 {
  margin-right: -9.6875rem !important;
  margin-left: -9.6875rem !important; }

.mx-n32 {
  margin-right: -10rem !important;
  margin-left: -10rem !important; }

.mx-n33 {
  margin-right: -10.3125rem !important;
  margin-left: -10.3125rem !important; }

.mx-n34 {
  margin-right: -10.625rem !important;
  margin-left: -10.625rem !important; }

.mx-n35 {
  margin-right: -10.9375rem !important;
  margin-left: -10.9375rem !important; }

.mx-n36 {
  margin-right: -11.25rem !important;
  margin-left: -11.25rem !important; }

.mx-n37 {
  margin-right: -11.5625rem !important;
  margin-left: -11.5625rem !important; }

.mx-n38 {
  margin-right: -11.875rem !important;
  margin-left: -11.875rem !important; }

.mx-n39 {
  margin-right: -12.1875rem !important;
  margin-left: -12.1875rem !important; }

.mx-n40 {
  margin-right: -12.5rem !important;
  margin-left: -12.5rem !important; }

.mx-nbase {
  margin-right: -calc(20rem / 16) !important;
  margin-left: -calc(20rem / 16) !important; }

.my-n1 {
  margin-top: -0.3125rem !important;
  margin-bottom: -0.3125rem !important; }

.my-n2 {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important; }

.my-n3 {
  margin-top: -0.9375rem !important;
  margin-bottom: -0.9375rem !important; }

.my-n4 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important; }

.my-n5 {
  margin-top: -1.5625rem !important;
  margin-bottom: -1.5625rem !important; }

.my-n6 {
  margin-top: -1.875rem !important;
  margin-bottom: -1.875rem !important; }

.my-n7 {
  margin-top: -2.1875rem !important;
  margin-bottom: -2.1875rem !important; }

.my-n8 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important; }

.my-n9 {
  margin-top: -2.8125rem !important;
  margin-bottom: -2.8125rem !important; }

.my-n10 {
  margin-top: -3.125rem !important;
  margin-bottom: -3.125rem !important; }

.my-n11 {
  margin-top: -3.4375rem !important;
  margin-bottom: -3.4375rem !important; }

.my-n12 {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important; }

.my-n13 {
  margin-top: -4.0625rem !important;
  margin-bottom: -4.0625rem !important; }

.my-n14 {
  margin-top: -4.375rem !important;
  margin-bottom: -4.375rem !important; }

.my-n15 {
  margin-top: -4.6875rem !important;
  margin-bottom: -4.6875rem !important; }

.my-n16 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important; }

.my-n17 {
  margin-top: -5.3125rem !important;
  margin-bottom: -5.3125rem !important; }

.my-n18 {
  margin-top: -5.625rem !important;
  margin-bottom: -5.625rem !important; }

.my-n19 {
  margin-top: -5.9375rem !important;
  margin-bottom: -5.9375rem !important; }

.my-n20 {
  margin-top: -6.25rem !important;
  margin-bottom: -6.25rem !important; }

.my-n21 {
  margin-top: -6.5625rem !important;
  margin-bottom: -6.5625rem !important; }

.my-n22 {
  margin-top: -6.875rem !important;
  margin-bottom: -6.875rem !important; }

.my-n23 {
  margin-top: -7.1875rem !important;
  margin-bottom: -7.1875rem !important; }

.my-n24 {
  margin-top: -7.5rem !important;
  margin-bottom: -7.5rem !important; }

.my-n25 {
  margin-top: -7.8125rem !important;
  margin-bottom: -7.8125rem !important; }

.my-n26 {
  margin-top: -8.125rem !important;
  margin-bottom: -8.125rem !important; }

.my-n27 {
  margin-top: -8.4375rem !important;
  margin-bottom: -8.4375rem !important; }

.my-n28 {
  margin-top: -8.75rem !important;
  margin-bottom: -8.75rem !important; }

.my-n29 {
  margin-top: -9.0625rem !important;
  margin-bottom: -9.0625rem !important; }

.my-n30 {
  margin-top: -9.375rem !important;
  margin-bottom: -9.375rem !important; }

.my-n31 {
  margin-top: -9.6875rem !important;
  margin-bottom: -9.6875rem !important; }

.my-n32 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important; }

.my-n33 {
  margin-top: -10.3125rem !important;
  margin-bottom: -10.3125rem !important; }

.my-n34 {
  margin-top: -10.625rem !important;
  margin-bottom: -10.625rem !important; }

.my-n35 {
  margin-top: -10.9375rem !important;
  margin-bottom: -10.9375rem !important; }

.my-n36 {
  margin-top: -11.25rem !important;
  margin-bottom: -11.25rem !important; }

.my-n37 {
  margin-top: -11.5625rem !important;
  margin-bottom: -11.5625rem !important; }

.my-n38 {
  margin-top: -11.875rem !important;
  margin-bottom: -11.875rem !important; }

.my-n39 {
  margin-top: -12.1875rem !important;
  margin-bottom: -12.1875rem !important; }

.my-n40 {
  margin-top: -12.5rem !important;
  margin-bottom: -12.5rem !important; }

.my-nbase {
  margin-top: -calc(20rem / 16) !important;
  margin-bottom: -calc(20rem / 16) !important; }

.mt-n1 {
  margin-top: -0.3125rem !important; }

.mt-n2 {
  margin-top: -0.625rem !important; }

.mt-n3 {
  margin-top: -0.9375rem !important; }

.mt-n4 {
  margin-top: -1.25rem !important; }

.mt-n5 {
  margin-top: -1.5625rem !important; }

.mt-n6 {
  margin-top: -1.875rem !important; }

.mt-n7 {
  margin-top: -2.1875rem !important; }

.mt-n8 {
  margin-top: -2.5rem !important; }

.mt-n9 {
  margin-top: -2.8125rem !important; }

.mt-n10 {
  margin-top: -3.125rem !important; }

.mt-n11 {
  margin-top: -3.4375rem !important; }

.mt-n12 {
  margin-top: -3.75rem !important; }

.mt-n13 {
  margin-top: -4.0625rem !important; }

.mt-n14 {
  margin-top: -4.375rem !important; }

.mt-n15 {
  margin-top: -4.6875rem !important; }

.mt-n16 {
  margin-top: -5rem !important; }

.mt-n17 {
  margin-top: -5.3125rem !important; }

.mt-n18 {
  margin-top: -5.625rem !important; }

.mt-n19 {
  margin-top: -5.9375rem !important; }

.mt-n20 {
  margin-top: -6.25rem !important; }

.mt-n21 {
  margin-top: -6.5625rem !important; }

.mt-n22 {
  margin-top: -6.875rem !important; }

.mt-n23 {
  margin-top: -7.1875rem !important; }

.mt-n24 {
  margin-top: -7.5rem !important; }

.mt-n25 {
  margin-top: -7.8125rem !important; }

.mt-n26 {
  margin-top: -8.125rem !important; }

.mt-n27 {
  margin-top: -8.4375rem !important; }

.mt-n28 {
  margin-top: -8.75rem !important; }

.mt-n29 {
  margin-top: -9.0625rem !important; }

.mt-n30 {
  margin-top: -9.375rem !important; }

.mt-n31 {
  margin-top: -9.6875rem !important; }

.mt-n32 {
  margin-top: -10rem !important; }

.mt-n33 {
  margin-top: -10.3125rem !important; }

.mt-n34 {
  margin-top: -10.625rem !important; }

.mt-n35 {
  margin-top: -10.9375rem !important; }

.mt-n36 {
  margin-top: -11.25rem !important; }

.mt-n37 {
  margin-top: -11.5625rem !important; }

.mt-n38 {
  margin-top: -11.875rem !important; }

.mt-n39 {
  margin-top: -12.1875rem !important; }

.mt-n40 {
  margin-top: -12.5rem !important; }

.mt-nbase {
  margin-top: -calc(20rem / 16) !important; }

.me-n1 {
  margin-right: -0.3125rem !important; }

.me-n2 {
  margin-right: -0.625rem !important; }

.me-n3 {
  margin-right: -0.9375rem !important; }

.me-n4 {
  margin-right: -1.25rem !important; }

.me-n5 {
  margin-right: -1.5625rem !important; }

.me-n6 {
  margin-right: -1.875rem !important; }

.me-n7 {
  margin-right: -2.1875rem !important; }

.me-n8 {
  margin-right: -2.5rem !important; }

.me-n9 {
  margin-right: -2.8125rem !important; }

.me-n10 {
  margin-right: -3.125rem !important; }

.me-n11 {
  margin-right: -3.4375rem !important; }

.me-n12 {
  margin-right: -3.75rem !important; }

.me-n13 {
  margin-right: -4.0625rem !important; }

.me-n14 {
  margin-right: -4.375rem !important; }

.me-n15 {
  margin-right: -4.6875rem !important; }

.me-n16 {
  margin-right: -5rem !important; }

.me-n17 {
  margin-right: -5.3125rem !important; }

.me-n18 {
  margin-right: -5.625rem !important; }

.me-n19 {
  margin-right: -5.9375rem !important; }

.me-n20 {
  margin-right: -6.25rem !important; }

.me-n21 {
  margin-right: -6.5625rem !important; }

.me-n22 {
  margin-right: -6.875rem !important; }

.me-n23 {
  margin-right: -7.1875rem !important; }

.me-n24 {
  margin-right: -7.5rem !important; }

.me-n25 {
  margin-right: -7.8125rem !important; }

.me-n26 {
  margin-right: -8.125rem !important; }

.me-n27 {
  margin-right: -8.4375rem !important; }

.me-n28 {
  margin-right: -8.75rem !important; }

.me-n29 {
  margin-right: -9.0625rem !important; }

.me-n30 {
  margin-right: -9.375rem !important; }

.me-n31 {
  margin-right: -9.6875rem !important; }

.me-n32 {
  margin-right: -10rem !important; }

.me-n33 {
  margin-right: -10.3125rem !important; }

.me-n34 {
  margin-right: -10.625rem !important; }

.me-n35 {
  margin-right: -10.9375rem !important; }

.me-n36 {
  margin-right: -11.25rem !important; }

.me-n37 {
  margin-right: -11.5625rem !important; }

.me-n38 {
  margin-right: -11.875rem !important; }

.me-n39 {
  margin-right: -12.1875rem !important; }

.me-n40 {
  margin-right: -12.5rem !important; }

.me-nbase {
  margin-right: -calc(20rem / 16) !important; }

.mb-n1 {
  margin-bottom: -0.3125rem !important; }

.mb-n2 {
  margin-bottom: -0.625rem !important; }

.mb-n3 {
  margin-bottom: -0.9375rem !important; }

.mb-n4 {
  margin-bottom: -1.25rem !important; }

.mb-n5 {
  margin-bottom: -1.5625rem !important; }

.mb-n6 {
  margin-bottom: -1.875rem !important; }

.mb-n7 {
  margin-bottom: -2.1875rem !important; }

.mb-n8 {
  margin-bottom: -2.5rem !important; }

.mb-n9 {
  margin-bottom: -2.8125rem !important; }

.mb-n10 {
  margin-bottom: -3.125rem !important; }

.mb-n11 {
  margin-bottom: -3.4375rem !important; }

.mb-n12 {
  margin-bottom: -3.75rem !important; }

.mb-n13 {
  margin-bottom: -4.0625rem !important; }

.mb-n14 {
  margin-bottom: -4.375rem !important; }

.mb-n15 {
  margin-bottom: -4.6875rem !important; }

.mb-n16 {
  margin-bottom: -5rem !important; }

.mb-n17 {
  margin-bottom: -5.3125rem !important; }

.mb-n18 {
  margin-bottom: -5.625rem !important; }

.mb-n19 {
  margin-bottom: -5.9375rem !important; }

.mb-n20 {
  margin-bottom: -6.25rem !important; }

.mb-n21 {
  margin-bottom: -6.5625rem !important; }

.mb-n22 {
  margin-bottom: -6.875rem !important; }

.mb-n23 {
  margin-bottom: -7.1875rem !important; }

.mb-n24 {
  margin-bottom: -7.5rem !important; }

.mb-n25 {
  margin-bottom: -7.8125rem !important; }

.mb-n26 {
  margin-bottom: -8.125rem !important; }

.mb-n27 {
  margin-bottom: -8.4375rem !important; }

.mb-n28 {
  margin-bottom: -8.75rem !important; }

.mb-n29 {
  margin-bottom: -9.0625rem !important; }

.mb-n30 {
  margin-bottom: -9.375rem !important; }

.mb-n31 {
  margin-bottom: -9.6875rem !important; }

.mb-n32 {
  margin-bottom: -10rem !important; }

.mb-n33 {
  margin-bottom: -10.3125rem !important; }

.mb-n34 {
  margin-bottom: -10.625rem !important; }

.mb-n35 {
  margin-bottom: -10.9375rem !important; }

.mb-n36 {
  margin-bottom: -11.25rem !important; }

.mb-n37 {
  margin-bottom: -11.5625rem !important; }

.mb-n38 {
  margin-bottom: -11.875rem !important; }

.mb-n39 {
  margin-bottom: -12.1875rem !important; }

.mb-n40 {
  margin-bottom: -12.5rem !important; }

.mb-nbase {
  margin-bottom: -calc(20rem / 16) !important; }

.ms-n1 {
  margin-left: -0.3125rem !important; }

.ms-n2 {
  margin-left: -0.625rem !important; }

.ms-n3 {
  margin-left: -0.9375rem !important; }

.ms-n4 {
  margin-left: -1.25rem !important; }

.ms-n5 {
  margin-left: -1.5625rem !important; }

.ms-n6 {
  margin-left: -1.875rem !important; }

.ms-n7 {
  margin-left: -2.1875rem !important; }

.ms-n8 {
  margin-left: -2.5rem !important; }

.ms-n9 {
  margin-left: -2.8125rem !important; }

.ms-n10 {
  margin-left: -3.125rem !important; }

.ms-n11 {
  margin-left: -3.4375rem !important; }

.ms-n12 {
  margin-left: -3.75rem !important; }

.ms-n13 {
  margin-left: -4.0625rem !important; }

.ms-n14 {
  margin-left: -4.375rem !important; }

.ms-n15 {
  margin-left: -4.6875rem !important; }

.ms-n16 {
  margin-left: -5rem !important; }

.ms-n17 {
  margin-left: -5.3125rem !important; }

.ms-n18 {
  margin-left: -5.625rem !important; }

.ms-n19 {
  margin-left: -5.9375rem !important; }

.ms-n20 {
  margin-left: -6.25rem !important; }

.ms-n21 {
  margin-left: -6.5625rem !important; }

.ms-n22 {
  margin-left: -6.875rem !important; }

.ms-n23 {
  margin-left: -7.1875rem !important; }

.ms-n24 {
  margin-left: -7.5rem !important; }

.ms-n25 {
  margin-left: -7.8125rem !important; }

.ms-n26 {
  margin-left: -8.125rem !important; }

.ms-n27 {
  margin-left: -8.4375rem !important; }

.ms-n28 {
  margin-left: -8.75rem !important; }

.ms-n29 {
  margin-left: -9.0625rem !important; }

.ms-n30 {
  margin-left: -9.375rem !important; }

.ms-n31 {
  margin-left: -9.6875rem !important; }

.ms-n32 {
  margin-left: -10rem !important; }

.ms-n33 {
  margin-left: -10.3125rem !important; }

.ms-n34 {
  margin-left: -10.625rem !important; }

.ms-n35 {
  margin-left: -10.9375rem !important; }

.ms-n36 {
  margin-left: -11.25rem !important; }

.ms-n37 {
  margin-left: -11.5625rem !important; }

.ms-n38 {
  margin-left: -11.875rem !important; }

.ms-n39 {
  margin-left: -12.1875rem !important; }

.ms-n40 {
  margin-left: -12.5rem !important; }

.ms-nbase {
  margin-left: -calc(20rem / 16) !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.3125rem !important; }

.p-2 {
  padding: 0.625rem !important; }

.p-3 {
  padding: 0.9375rem !important; }

.p-4 {
  padding: 1.25rem !important; }

.p-5 {
  padding: 1.5625rem !important; }

.p-6 {
  padding: 1.875rem !important; }

.p-7 {
  padding: 2.1875rem !important; }

.p-8 {
  padding: 2.5rem !important; }

.p-9 {
  padding: 2.8125rem !important; }

.p-10 {
  padding: 3.125rem !important; }

.p-11 {
  padding: 3.4375rem !important; }

.p-12 {
  padding: 3.75rem !important; }

.p-13 {
  padding: 4.0625rem !important; }

.p-14 {
  padding: 4.375rem !important; }

.p-15 {
  padding: 4.6875rem !important; }

.p-16 {
  padding: 5rem !important; }

.p-17 {
  padding: 5.3125rem !important; }

.p-18 {
  padding: 5.625rem !important; }

.p-19 {
  padding: 5.9375rem !important; }

.p-20 {
  padding: 6.25rem !important; }

.p-21 {
  padding: 6.5625rem !important; }

.p-22 {
  padding: 6.875rem !important; }

.p-23 {
  padding: 7.1875rem !important; }

.p-24 {
  padding: 7.5rem !important; }

.p-25 {
  padding: 7.8125rem !important; }

.p-26 {
  padding: 8.125rem !important; }

.p-27 {
  padding: 8.4375rem !important; }

.p-28 {
  padding: 8.75rem !important; }

.p-29 {
  padding: 9.0625rem !important; }

.p-30 {
  padding: 9.375rem !important; }

.p-31 {
  padding: 9.6875rem !important; }

.p-32 {
  padding: 10rem !important; }

.p-33 {
  padding: 10.3125rem !important; }

.p-34 {
  padding: 10.625rem !important; }

.p-35 {
  padding: 10.9375rem !important; }

.p-36 {
  padding: 11.25rem !important; }

.p-37 {
  padding: 11.5625rem !important; }

.p-38 {
  padding: 11.875rem !important; }

.p-39 {
  padding: 12.1875rem !important; }

.p-40 {
  padding: 12.5rem !important; }

.p-base {
  padding: calc(20rem / 16) !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.3125rem !important;
  padding-left: 0.3125rem !important; }

.px-2 {
  padding-right: 0.625rem !important;
  padding-left: 0.625rem !important; }

.px-3 {
  padding-right: 0.9375rem !important;
  padding-left: 0.9375rem !important; }

.px-4 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important; }

.px-5 {
  padding-right: 1.5625rem !important;
  padding-left: 1.5625rem !important; }

.px-6 {
  padding-right: 1.875rem !important;
  padding-left: 1.875rem !important; }

.px-7 {
  padding-right: 2.1875rem !important;
  padding-left: 2.1875rem !important; }

.px-8 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important; }

.px-9 {
  padding-right: 2.8125rem !important;
  padding-left: 2.8125rem !important; }

.px-10 {
  padding-right: 3.125rem !important;
  padding-left: 3.125rem !important; }

.px-11 {
  padding-right: 3.4375rem !important;
  padding-left: 3.4375rem !important; }

.px-12 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important; }

.px-13 {
  padding-right: 4.0625rem !important;
  padding-left: 4.0625rem !important; }

.px-14 {
  padding-right: 4.375rem !important;
  padding-left: 4.375rem !important; }

.px-15 {
  padding-right: 4.6875rem !important;
  padding-left: 4.6875rem !important; }

.px-16 {
  padding-right: 5rem !important;
  padding-left: 5rem !important; }

.px-17 {
  padding-right: 5.3125rem !important;
  padding-left: 5.3125rem !important; }

.px-18 {
  padding-right: 5.625rem !important;
  padding-left: 5.625rem !important; }

.px-19 {
  padding-right: 5.9375rem !important;
  padding-left: 5.9375rem !important; }

.px-20 {
  padding-right: 6.25rem !important;
  padding-left: 6.25rem !important; }

.px-21 {
  padding-right: 6.5625rem !important;
  padding-left: 6.5625rem !important; }

.px-22 {
  padding-right: 6.875rem !important;
  padding-left: 6.875rem !important; }

.px-23 {
  padding-right: 7.1875rem !important;
  padding-left: 7.1875rem !important; }

.px-24 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important; }

.px-25 {
  padding-right: 7.8125rem !important;
  padding-left: 7.8125rem !important; }

.px-26 {
  padding-right: 8.125rem !important;
  padding-left: 8.125rem !important; }

.px-27 {
  padding-right: 8.4375rem !important;
  padding-left: 8.4375rem !important; }

.px-28 {
  padding-right: 8.75rem !important;
  padding-left: 8.75rem !important; }

.px-29 {
  padding-right: 9.0625rem !important;
  padding-left: 9.0625rem !important; }

.px-30 {
  padding-right: 9.375rem !important;
  padding-left: 9.375rem !important; }

.px-31 {
  padding-right: 9.6875rem !important;
  padding-left: 9.6875rem !important; }

.px-32 {
  padding-right: 10rem !important;
  padding-left: 10rem !important; }

.px-33 {
  padding-right: 10.3125rem !important;
  padding-left: 10.3125rem !important; }

.px-34 {
  padding-right: 10.625rem !important;
  padding-left: 10.625rem !important; }

.px-35 {
  padding-right: 10.9375rem !important;
  padding-left: 10.9375rem !important; }

.px-36 {
  padding-right: 11.25rem !important;
  padding-left: 11.25rem !important; }

.px-37 {
  padding-right: 11.5625rem !important;
  padding-left: 11.5625rem !important; }

.px-38 {
  padding-right: 11.875rem !important;
  padding-left: 11.875rem !important; }

.px-39 {
  padding-right: 12.1875rem !important;
  padding-left: 12.1875rem !important; }

.px-40 {
  padding-right: 12.5rem !important;
  padding-left: 12.5rem !important; }

.px-base {
  padding-right: calc(20rem / 16) !important;
  padding-left: calc(20rem / 16) !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important; }

.py-2 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important; }

.py-3 {
  padding-top: 0.9375rem !important;
  padding-bottom: 0.9375rem !important; }

.py-4 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important; }

.py-5 {
  padding-top: 1.5625rem !important;
  padding-bottom: 1.5625rem !important; }

.py-6 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important; }

.py-7 {
  padding-top: 2.1875rem !important;
  padding-bottom: 2.1875rem !important; }

.py-8 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important; }

.py-9 {
  padding-top: 2.8125rem !important;
  padding-bottom: 2.8125rem !important; }

.py-10 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important; }

.py-11 {
  padding-top: 3.4375rem !important;
  padding-bottom: 3.4375rem !important; }

.py-12 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important; }

.py-13 {
  padding-top: 4.0625rem !important;
  padding-bottom: 4.0625rem !important; }

.py-14 {
  padding-top: 4.375rem !important;
  padding-bottom: 4.375rem !important; }

.py-15 {
  padding-top: 4.6875rem !important;
  padding-bottom: 4.6875rem !important; }

.py-16 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important; }

.py-17 {
  padding-top: 5.3125rem !important;
  padding-bottom: 5.3125rem !important; }

.py-18 {
  padding-top: 5.625rem !important;
  padding-bottom: 5.625rem !important; }

.py-19 {
  padding-top: 5.9375rem !important;
  padding-bottom: 5.9375rem !important; }

.py-20 {
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important; }

.py-21 {
  padding-top: 6.5625rem !important;
  padding-bottom: 6.5625rem !important; }

.py-22 {
  padding-top: 6.875rem !important;
  padding-bottom: 6.875rem !important; }

.py-23 {
  padding-top: 7.1875rem !important;
  padding-bottom: 7.1875rem !important; }

.py-24 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important; }

.py-25 {
  padding-top: 7.8125rem !important;
  padding-bottom: 7.8125rem !important; }

.py-26 {
  padding-top: 8.125rem !important;
  padding-bottom: 8.125rem !important; }

.py-27 {
  padding-top: 8.4375rem !important;
  padding-bottom: 8.4375rem !important; }

.py-28 {
  padding-top: 8.75rem !important;
  padding-bottom: 8.75rem !important; }

.py-29 {
  padding-top: 9.0625rem !important;
  padding-bottom: 9.0625rem !important; }

.py-30 {
  padding-top: 9.375rem !important;
  padding-bottom: 9.375rem !important; }

.py-31 {
  padding-top: 9.6875rem !important;
  padding-bottom: 9.6875rem !important; }

.py-32 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important; }

.py-33 {
  padding-top: 10.3125rem !important;
  padding-bottom: 10.3125rem !important; }

.py-34 {
  padding-top: 10.625rem !important;
  padding-bottom: 10.625rem !important; }

.py-35 {
  padding-top: 10.9375rem !important;
  padding-bottom: 10.9375rem !important; }

.py-36 {
  padding-top: 11.25rem !important;
  padding-bottom: 11.25rem !important; }

.py-37 {
  padding-top: 11.5625rem !important;
  padding-bottom: 11.5625rem !important; }

.py-38 {
  padding-top: 11.875rem !important;
  padding-bottom: 11.875rem !important; }

.py-39 {
  padding-top: 12.1875rem !important;
  padding-bottom: 12.1875rem !important; }

.py-40 {
  padding-top: 12.5rem !important;
  padding-bottom: 12.5rem !important; }

.py-base {
  padding-top: calc(20rem / 16) !important;
  padding-bottom: calc(20rem / 16) !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.3125rem !important; }

.pt-2 {
  padding-top: 0.625rem !important; }

.pt-3 {
  padding-top: 0.9375rem !important; }

.pt-4 {
  padding-top: 1.25rem !important; }

.pt-5 {
  padding-top: 1.5625rem !important; }

.pt-6 {
  padding-top: 1.875rem !important; }

.pt-7 {
  padding-top: 2.1875rem !important; }

.pt-8 {
  padding-top: 2.5rem !important; }

.pt-9 {
  padding-top: 2.8125rem !important; }

.pt-10 {
  padding-top: 3.125rem !important; }

.pt-11 {
  padding-top: 3.4375rem !important; }

.pt-12 {
  padding-top: 3.75rem !important; }

.pt-13 {
  padding-top: 4.0625rem !important; }

.pt-14 {
  padding-top: 4.375rem !important; }

.pt-15 {
  padding-top: 4.6875rem !important; }

.pt-16 {
  padding-top: 5rem !important; }

.pt-17 {
  padding-top: 5.3125rem !important; }

.pt-18 {
  padding-top: 5.625rem !important; }

.pt-19 {
  padding-top: 5.9375rem !important; }

.pt-20 {
  padding-top: 6.25rem !important; }

.pt-21 {
  padding-top: 6.5625rem !important; }

.pt-22 {
  padding-top: 6.875rem !important; }

.pt-23 {
  padding-top: 7.1875rem !important; }

.pt-24 {
  padding-top: 7.5rem !important; }

.pt-25 {
  padding-top: 7.8125rem !important; }

.pt-26 {
  padding-top: 8.125rem !important; }

.pt-27 {
  padding-top: 8.4375rem !important; }

.pt-28 {
  padding-top: 8.75rem !important; }

.pt-29 {
  padding-top: 9.0625rem !important; }

.pt-30 {
  padding-top: 9.375rem !important; }

.pt-31 {
  padding-top: 9.6875rem !important; }

.pt-32 {
  padding-top: 10rem !important; }

.pt-33 {
  padding-top: 10.3125rem !important; }

.pt-34 {
  padding-top: 10.625rem !important; }

.pt-35 {
  padding-top: 10.9375rem !important; }

.pt-36 {
  padding-top: 11.25rem !important; }

.pt-37 {
  padding-top: 11.5625rem !important; }

.pt-38 {
  padding-top: 11.875rem !important; }

.pt-39 {
  padding-top: 12.1875rem !important; }

.pt-40 {
  padding-top: 12.5rem !important; }

.pt-base {
  padding-top: calc(20rem / 16) !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.3125rem !important; }

.pe-2 {
  padding-right: 0.625rem !important; }

.pe-3 {
  padding-right: 0.9375rem !important; }

.pe-4 {
  padding-right: 1.25rem !important; }

.pe-5 {
  padding-right: 1.5625rem !important; }

.pe-6 {
  padding-right: 1.875rem !important; }

.pe-7 {
  padding-right: 2.1875rem !important; }

.pe-8 {
  padding-right: 2.5rem !important; }

.pe-9 {
  padding-right: 2.8125rem !important; }

.pe-10 {
  padding-right: 3.125rem !important; }

.pe-11 {
  padding-right: 3.4375rem !important; }

.pe-12 {
  padding-right: 3.75rem !important; }

.pe-13 {
  padding-right: 4.0625rem !important; }

.pe-14 {
  padding-right: 4.375rem !important; }

.pe-15 {
  padding-right: 4.6875rem !important; }

.pe-16 {
  padding-right: 5rem !important; }

.pe-17 {
  padding-right: 5.3125rem !important; }

.pe-18 {
  padding-right: 5.625rem !important; }

.pe-19 {
  padding-right: 5.9375rem !important; }

.pe-20 {
  padding-right: 6.25rem !important; }

.pe-21 {
  padding-right: 6.5625rem !important; }

.pe-22 {
  padding-right: 6.875rem !important; }

.pe-23 {
  padding-right: 7.1875rem !important; }

.pe-24 {
  padding-right: 7.5rem !important; }

.pe-25 {
  padding-right: 7.8125rem !important; }

.pe-26 {
  padding-right: 8.125rem !important; }

.pe-27 {
  padding-right: 8.4375rem !important; }

.pe-28 {
  padding-right: 8.75rem !important; }

.pe-29 {
  padding-right: 9.0625rem !important; }

.pe-30 {
  padding-right: 9.375rem !important; }

.pe-31 {
  padding-right: 9.6875rem !important; }

.pe-32 {
  padding-right: 10rem !important; }

.pe-33 {
  padding-right: 10.3125rem !important; }

.pe-34 {
  padding-right: 10.625rem !important; }

.pe-35 {
  padding-right: 10.9375rem !important; }

.pe-36 {
  padding-right: 11.25rem !important; }

.pe-37 {
  padding-right: 11.5625rem !important; }

.pe-38 {
  padding-right: 11.875rem !important; }

.pe-39 {
  padding-right: 12.1875rem !important; }

.pe-40 {
  padding-right: 12.5rem !important; }

.pe-base {
  padding-right: calc(20rem / 16) !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.3125rem !important; }

.pb-2 {
  padding-bottom: 0.625rem !important; }

.pb-3 {
  padding-bottom: 0.9375rem !important; }

.pb-4 {
  padding-bottom: 1.25rem !important; }

.pb-5 {
  padding-bottom: 1.5625rem !important; }

.pb-6 {
  padding-bottom: 1.875rem !important; }

.pb-7 {
  padding-bottom: 2.1875rem !important; }

.pb-8 {
  padding-bottom: 2.5rem !important; }

.pb-9 {
  padding-bottom: 2.8125rem !important; }

.pb-10 {
  padding-bottom: 3.125rem !important; }

.pb-11 {
  padding-bottom: 3.4375rem !important; }

.pb-12 {
  padding-bottom: 3.75rem !important; }

.pb-13 {
  padding-bottom: 4.0625rem !important; }

.pb-14 {
  padding-bottom: 4.375rem !important; }

.pb-15 {
  padding-bottom: 4.6875rem !important; }

.pb-16 {
  padding-bottom: 5rem !important; }

.pb-17 {
  padding-bottom: 5.3125rem !important; }

.pb-18 {
  padding-bottom: 5.625rem !important; }

.pb-19 {
  padding-bottom: 5.9375rem !important; }

.pb-20 {
  padding-bottom: 6.25rem !important; }

.pb-21 {
  padding-bottom: 6.5625rem !important; }

.pb-22 {
  padding-bottom: 6.875rem !important; }

.pb-23 {
  padding-bottom: 7.1875rem !important; }

.pb-24 {
  padding-bottom: 7.5rem !important; }

.pb-25 {
  padding-bottom: 7.8125rem !important; }

.pb-26 {
  padding-bottom: 8.125rem !important; }

.pb-27 {
  padding-bottom: 8.4375rem !important; }

.pb-28 {
  padding-bottom: 8.75rem !important; }

.pb-29 {
  padding-bottom: 9.0625rem !important; }

.pb-30 {
  padding-bottom: 9.375rem !important; }

.pb-31 {
  padding-bottom: 9.6875rem !important; }

.pb-32 {
  padding-bottom: 10rem !important; }

.pb-33 {
  padding-bottom: 10.3125rem !important; }

.pb-34 {
  padding-bottom: 10.625rem !important; }

.pb-35 {
  padding-bottom: 10.9375rem !important; }

.pb-36 {
  padding-bottom: 11.25rem !important; }

.pb-37 {
  padding-bottom: 11.5625rem !important; }

.pb-38 {
  padding-bottom: 11.875rem !important; }

.pb-39 {
  padding-bottom: 12.1875rem !important; }

.pb-40 {
  padding-bottom: 12.5rem !important; }

.pb-base {
  padding-bottom: calc(20rem / 16) !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.3125rem !important; }

.ps-2 {
  padding-left: 0.625rem !important; }

.ps-3 {
  padding-left: 0.9375rem !important; }

.ps-4 {
  padding-left: 1.25rem !important; }

.ps-5 {
  padding-left: 1.5625rem !important; }

.ps-6 {
  padding-left: 1.875rem !important; }

.ps-7 {
  padding-left: 2.1875rem !important; }

.ps-8 {
  padding-left: 2.5rem !important; }

.ps-9 {
  padding-left: 2.8125rem !important; }

.ps-10 {
  padding-left: 3.125rem !important; }

.ps-11 {
  padding-left: 3.4375rem !important; }

.ps-12 {
  padding-left: 3.75rem !important; }

.ps-13 {
  padding-left: 4.0625rem !important; }

.ps-14 {
  padding-left: 4.375rem !important; }

.ps-15 {
  padding-left: 4.6875rem !important; }

.ps-16 {
  padding-left: 5rem !important; }

.ps-17 {
  padding-left: 5.3125rem !important; }

.ps-18 {
  padding-left: 5.625rem !important; }

.ps-19 {
  padding-left: 5.9375rem !important; }

.ps-20 {
  padding-left: 6.25rem !important; }

.ps-21 {
  padding-left: 6.5625rem !important; }

.ps-22 {
  padding-left: 6.875rem !important; }

.ps-23 {
  padding-left: 7.1875rem !important; }

.ps-24 {
  padding-left: 7.5rem !important; }

.ps-25 {
  padding-left: 7.8125rem !important; }

.ps-26 {
  padding-left: 8.125rem !important; }

.ps-27 {
  padding-left: 8.4375rem !important; }

.ps-28 {
  padding-left: 8.75rem !important; }

.ps-29 {
  padding-left: 9.0625rem !important; }

.ps-30 {
  padding-left: 9.375rem !important; }

.ps-31 {
  padding-left: 9.6875rem !important; }

.ps-32 {
  padding-left: 10rem !important; }

.ps-33 {
  padding-left: 10.3125rem !important; }

.ps-34 {
  padding-left: 10.625rem !important; }

.ps-35 {
  padding-left: 10.9375rem !important; }

.ps-36 {
  padding-left: 11.25rem !important; }

.ps-37 {
  padding-left: 11.5625rem !important; }

.ps-38 {
  padding-left: 11.875rem !important; }

.ps-39 {
  padding-left: 12.1875rem !important; }

.ps-40 {
  padding-left: 12.5rem !important; }

.ps-base {
  padding-left: calc(20rem / 16) !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.3125rem !important; }

.gap-2 {
  gap: 0.625rem !important; }

.gap-3 {
  gap: 0.9375rem !important; }

.gap-4 {
  gap: 1.25rem !important; }

.gap-5 {
  gap: 1.5625rem !important; }

.gap-6 {
  gap: 1.875rem !important; }

.gap-7 {
  gap: 2.1875rem !important; }

.gap-8 {
  gap: 2.5rem !important; }

.gap-9 {
  gap: 2.8125rem !important; }

.gap-10 {
  gap: 3.125rem !important; }

.gap-11 {
  gap: 3.4375rem !important; }

.gap-12 {
  gap: 3.75rem !important; }

.gap-13 {
  gap: 4.0625rem !important; }

.gap-14 {
  gap: 4.375rem !important; }

.gap-15 {
  gap: 4.6875rem !important; }

.gap-16 {
  gap: 5rem !important; }

.gap-17 {
  gap: 5.3125rem !important; }

.gap-18 {
  gap: 5.625rem !important; }

.gap-19 {
  gap: 5.9375rem !important; }

.gap-20 {
  gap: 6.25rem !important; }

.gap-21 {
  gap: 6.5625rem !important; }

.gap-22 {
  gap: 6.875rem !important; }

.gap-23 {
  gap: 7.1875rem !important; }

.gap-24 {
  gap: 7.5rem !important; }

.gap-25 {
  gap: 7.8125rem !important; }

.gap-26 {
  gap: 8.125rem !important; }

.gap-27 {
  gap: 8.4375rem !important; }

.gap-28 {
  gap: 8.75rem !important; }

.gap-29 {
  gap: 9.0625rem !important; }

.gap-30 {
  gap: 9.375rem !important; }

.gap-31 {
  gap: 9.6875rem !important; }

.gap-32 {
  gap: 10rem !important; }

.gap-33 {
  gap: 10.3125rem !important; }

.gap-34 {
  gap: 10.625rem !important; }

.gap-35 {
  gap: 10.9375rem !important; }

.gap-36 {
  gap: 11.25rem !important; }

.gap-37 {
  gap: 11.5625rem !important; }

.gap-38 {
  gap: 11.875rem !important; }

.gap-39 {
  gap: 12.1875rem !important; }

.gap-40 {
  gap: 12.5rem !important; }

.gap-base {
  gap: calc(20rem / 16) !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #B5ADA6 !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: var(--bs-border-radius) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important; }

.rounded-2 {
  border-radius: var(--bs-border-radius) !important; }

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important; }

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important; }

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important; }

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.3125rem !important; }
  .m-sm-2 {
    margin: 0.625rem !important; }
  .m-sm-3 {
    margin: 0.9375rem !important; }
  .m-sm-4 {
    margin: 1.25rem !important; }
  .m-sm-5 {
    margin: 1.5625rem !important; }
  .m-sm-6 {
    margin: 1.875rem !important; }
  .m-sm-7 {
    margin: 2.1875rem !important; }
  .m-sm-8 {
    margin: 2.5rem !important; }
  .m-sm-9 {
    margin: 2.8125rem !important; }
  .m-sm-10 {
    margin: 3.125rem !important; }
  .m-sm-11 {
    margin: 3.4375rem !important; }
  .m-sm-12 {
    margin: 3.75rem !important; }
  .m-sm-13 {
    margin: 4.0625rem !important; }
  .m-sm-14 {
    margin: 4.375rem !important; }
  .m-sm-15 {
    margin: 4.6875rem !important; }
  .m-sm-16 {
    margin: 5rem !important; }
  .m-sm-17 {
    margin: 5.3125rem !important; }
  .m-sm-18 {
    margin: 5.625rem !important; }
  .m-sm-19 {
    margin: 5.9375rem !important; }
  .m-sm-20 {
    margin: 6.25rem !important; }
  .m-sm-21 {
    margin: 6.5625rem !important; }
  .m-sm-22 {
    margin: 6.875rem !important; }
  .m-sm-23 {
    margin: 7.1875rem !important; }
  .m-sm-24 {
    margin: 7.5rem !important; }
  .m-sm-25 {
    margin: 7.8125rem !important; }
  .m-sm-26 {
    margin: 8.125rem !important; }
  .m-sm-27 {
    margin: 8.4375rem !important; }
  .m-sm-28 {
    margin: 8.75rem !important; }
  .m-sm-29 {
    margin: 9.0625rem !important; }
  .m-sm-30 {
    margin: 9.375rem !important; }
  .m-sm-31 {
    margin: 9.6875rem !important; }
  .m-sm-32 {
    margin: 10rem !important; }
  .m-sm-33 {
    margin: 10.3125rem !important; }
  .m-sm-34 {
    margin: 10.625rem !important; }
  .m-sm-35 {
    margin: 10.9375rem !important; }
  .m-sm-36 {
    margin: 11.25rem !important; }
  .m-sm-37 {
    margin: 11.5625rem !important; }
  .m-sm-38 {
    margin: 11.875rem !important; }
  .m-sm-39 {
    margin: 12.1875rem !important; }
  .m-sm-40 {
    margin: 12.5rem !important; }
  .m-sm-base {
    margin: calc(20rem / 16) !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important; }
  .mx-sm-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important; }
  .mx-sm-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important; }
  .mx-sm-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important; }
  .mx-sm-5 {
    margin-right: 1.5625rem !important;
    margin-left: 1.5625rem !important; }
  .mx-sm-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important; }
  .mx-sm-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important; }
  .mx-sm-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-sm-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important; }
  .mx-sm-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important; }
  .mx-sm-11 {
    margin-right: 3.4375rem !important;
    margin-left: 3.4375rem !important; }
  .mx-sm-12 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important; }
  .mx-sm-13 {
    margin-right: 4.0625rem !important;
    margin-left: 4.0625rem !important; }
  .mx-sm-14 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important; }
  .mx-sm-15 {
    margin-right: 4.6875rem !important;
    margin-left: 4.6875rem !important; }
  .mx-sm-16 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-sm-17 {
    margin-right: 5.3125rem !important;
    margin-left: 5.3125rem !important; }
  .mx-sm-18 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important; }
  .mx-sm-19 {
    margin-right: 5.9375rem !important;
    margin-left: 5.9375rem !important; }
  .mx-sm-20 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important; }
  .mx-sm-21 {
    margin-right: 6.5625rem !important;
    margin-left: 6.5625rem !important; }
  .mx-sm-22 {
    margin-right: 6.875rem !important;
    margin-left: 6.875rem !important; }
  .mx-sm-23 {
    margin-right: 7.1875rem !important;
    margin-left: 7.1875rem !important; }
  .mx-sm-24 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-sm-25 {
    margin-right: 7.8125rem !important;
    margin-left: 7.8125rem !important; }
  .mx-sm-26 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important; }
  .mx-sm-27 {
    margin-right: 8.4375rem !important;
    margin-left: 8.4375rem !important; }
  .mx-sm-28 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important; }
  .mx-sm-29 {
    margin-right: 9.0625rem !important;
    margin-left: 9.0625rem !important; }
  .mx-sm-30 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important; }
  .mx-sm-31 {
    margin-right: 9.6875rem !important;
    margin-left: 9.6875rem !important; }
  .mx-sm-32 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-sm-33 {
    margin-right: 10.3125rem !important;
    margin-left: 10.3125rem !important; }
  .mx-sm-34 {
    margin-right: 10.625rem !important;
    margin-left: 10.625rem !important; }
  .mx-sm-35 {
    margin-right: 10.9375rem !important;
    margin-left: 10.9375rem !important; }
  .mx-sm-36 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important; }
  .mx-sm-37 {
    margin-right: 11.5625rem !important;
    margin-left: 11.5625rem !important; }
  .mx-sm-38 {
    margin-right: 11.875rem !important;
    margin-left: 11.875rem !important; }
  .mx-sm-39 {
    margin-right: 12.1875rem !important;
    margin-left: 12.1875rem !important; }
  .mx-sm-40 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-sm-base {
    margin-right: calc(20rem / 16) !important;
    margin-left: calc(20rem / 16) !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important; }
  .my-sm-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important; }
  .my-sm-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important; }
  .my-sm-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .my-sm-5 {
    margin-top: 1.5625rem !important;
    margin-bottom: 1.5625rem !important; }
  .my-sm-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important; }
  .my-sm-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important; }
  .my-sm-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-sm-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important; }
  .my-sm-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important; }
  .my-sm-11 {
    margin-top: 3.4375rem !important;
    margin-bottom: 3.4375rem !important; }
  .my-sm-12 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important; }
  .my-sm-13 {
    margin-top: 4.0625rem !important;
    margin-bottom: 4.0625rem !important; }
  .my-sm-14 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important; }
  .my-sm-15 {
    margin-top: 4.6875rem !important;
    margin-bottom: 4.6875rem !important; }
  .my-sm-16 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-sm-17 {
    margin-top: 5.3125rem !important;
    margin-bottom: 5.3125rem !important; }
  .my-sm-18 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important; }
  .my-sm-19 {
    margin-top: 5.9375rem !important;
    margin-bottom: 5.9375rem !important; }
  .my-sm-20 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important; }
  .my-sm-21 {
    margin-top: 6.5625rem !important;
    margin-bottom: 6.5625rem !important; }
  .my-sm-22 {
    margin-top: 6.875rem !important;
    margin-bottom: 6.875rem !important; }
  .my-sm-23 {
    margin-top: 7.1875rem !important;
    margin-bottom: 7.1875rem !important; }
  .my-sm-24 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-sm-25 {
    margin-top: 7.8125rem !important;
    margin-bottom: 7.8125rem !important; }
  .my-sm-26 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important; }
  .my-sm-27 {
    margin-top: 8.4375rem !important;
    margin-bottom: 8.4375rem !important; }
  .my-sm-28 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important; }
  .my-sm-29 {
    margin-top: 9.0625rem !important;
    margin-bottom: 9.0625rem !important; }
  .my-sm-30 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important; }
  .my-sm-31 {
    margin-top: 9.6875rem !important;
    margin-bottom: 9.6875rem !important; }
  .my-sm-32 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-sm-33 {
    margin-top: 10.3125rem !important;
    margin-bottom: 10.3125rem !important; }
  .my-sm-34 {
    margin-top: 10.625rem !important;
    margin-bottom: 10.625rem !important; }
  .my-sm-35 {
    margin-top: 10.9375rem !important;
    margin-bottom: 10.9375rem !important; }
  .my-sm-36 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important; }
  .my-sm-37 {
    margin-top: 11.5625rem !important;
    margin-bottom: 11.5625rem !important; }
  .my-sm-38 {
    margin-top: 11.875rem !important;
    margin-bottom: 11.875rem !important; }
  .my-sm-39 {
    margin-top: 12.1875rem !important;
    margin-bottom: 12.1875rem !important; }
  .my-sm-40 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-sm-base {
    margin-top: calc(20rem / 16) !important;
    margin-bottom: calc(20rem / 16) !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.3125rem !important; }
  .mt-sm-2 {
    margin-top: 0.625rem !important; }
  .mt-sm-3 {
    margin-top: 0.9375rem !important; }
  .mt-sm-4 {
    margin-top: 1.25rem !important; }
  .mt-sm-5 {
    margin-top: 1.5625rem !important; }
  .mt-sm-6 {
    margin-top: 1.875rem !important; }
  .mt-sm-7 {
    margin-top: 2.1875rem !important; }
  .mt-sm-8 {
    margin-top: 2.5rem !important; }
  .mt-sm-9 {
    margin-top: 2.8125rem !important; }
  .mt-sm-10 {
    margin-top: 3.125rem !important; }
  .mt-sm-11 {
    margin-top: 3.4375rem !important; }
  .mt-sm-12 {
    margin-top: 3.75rem !important; }
  .mt-sm-13 {
    margin-top: 4.0625rem !important; }
  .mt-sm-14 {
    margin-top: 4.375rem !important; }
  .mt-sm-15 {
    margin-top: 4.6875rem !important; }
  .mt-sm-16 {
    margin-top: 5rem !important; }
  .mt-sm-17 {
    margin-top: 5.3125rem !important; }
  .mt-sm-18 {
    margin-top: 5.625rem !important; }
  .mt-sm-19 {
    margin-top: 5.9375rem !important; }
  .mt-sm-20 {
    margin-top: 6.25rem !important; }
  .mt-sm-21 {
    margin-top: 6.5625rem !important; }
  .mt-sm-22 {
    margin-top: 6.875rem !important; }
  .mt-sm-23 {
    margin-top: 7.1875rem !important; }
  .mt-sm-24 {
    margin-top: 7.5rem !important; }
  .mt-sm-25 {
    margin-top: 7.8125rem !important; }
  .mt-sm-26 {
    margin-top: 8.125rem !important; }
  .mt-sm-27 {
    margin-top: 8.4375rem !important; }
  .mt-sm-28 {
    margin-top: 8.75rem !important; }
  .mt-sm-29 {
    margin-top: 9.0625rem !important; }
  .mt-sm-30 {
    margin-top: 9.375rem !important; }
  .mt-sm-31 {
    margin-top: 9.6875rem !important; }
  .mt-sm-32 {
    margin-top: 10rem !important; }
  .mt-sm-33 {
    margin-top: 10.3125rem !important; }
  .mt-sm-34 {
    margin-top: 10.625rem !important; }
  .mt-sm-35 {
    margin-top: 10.9375rem !important; }
  .mt-sm-36 {
    margin-top: 11.25rem !important; }
  .mt-sm-37 {
    margin-top: 11.5625rem !important; }
  .mt-sm-38 {
    margin-top: 11.875rem !important; }
  .mt-sm-39 {
    margin-top: 12.1875rem !important; }
  .mt-sm-40 {
    margin-top: 12.5rem !important; }
  .mt-sm-base {
    margin-top: calc(20rem / 16) !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.3125rem !important; }
  .me-sm-2 {
    margin-right: 0.625rem !important; }
  .me-sm-3 {
    margin-right: 0.9375rem !important; }
  .me-sm-4 {
    margin-right: 1.25rem !important; }
  .me-sm-5 {
    margin-right: 1.5625rem !important; }
  .me-sm-6 {
    margin-right: 1.875rem !important; }
  .me-sm-7 {
    margin-right: 2.1875rem !important; }
  .me-sm-8 {
    margin-right: 2.5rem !important; }
  .me-sm-9 {
    margin-right: 2.8125rem !important; }
  .me-sm-10 {
    margin-right: 3.125rem !important; }
  .me-sm-11 {
    margin-right: 3.4375rem !important; }
  .me-sm-12 {
    margin-right: 3.75rem !important; }
  .me-sm-13 {
    margin-right: 4.0625rem !important; }
  .me-sm-14 {
    margin-right: 4.375rem !important; }
  .me-sm-15 {
    margin-right: 4.6875rem !important; }
  .me-sm-16 {
    margin-right: 5rem !important; }
  .me-sm-17 {
    margin-right: 5.3125rem !important; }
  .me-sm-18 {
    margin-right: 5.625rem !important; }
  .me-sm-19 {
    margin-right: 5.9375rem !important; }
  .me-sm-20 {
    margin-right: 6.25rem !important; }
  .me-sm-21 {
    margin-right: 6.5625rem !important; }
  .me-sm-22 {
    margin-right: 6.875rem !important; }
  .me-sm-23 {
    margin-right: 7.1875rem !important; }
  .me-sm-24 {
    margin-right: 7.5rem !important; }
  .me-sm-25 {
    margin-right: 7.8125rem !important; }
  .me-sm-26 {
    margin-right: 8.125rem !important; }
  .me-sm-27 {
    margin-right: 8.4375rem !important; }
  .me-sm-28 {
    margin-right: 8.75rem !important; }
  .me-sm-29 {
    margin-right: 9.0625rem !important; }
  .me-sm-30 {
    margin-right: 9.375rem !important; }
  .me-sm-31 {
    margin-right: 9.6875rem !important; }
  .me-sm-32 {
    margin-right: 10rem !important; }
  .me-sm-33 {
    margin-right: 10.3125rem !important; }
  .me-sm-34 {
    margin-right: 10.625rem !important; }
  .me-sm-35 {
    margin-right: 10.9375rem !important; }
  .me-sm-36 {
    margin-right: 11.25rem !important; }
  .me-sm-37 {
    margin-right: 11.5625rem !important; }
  .me-sm-38 {
    margin-right: 11.875rem !important; }
  .me-sm-39 {
    margin-right: 12.1875rem !important; }
  .me-sm-40 {
    margin-right: 12.5rem !important; }
  .me-sm-base {
    margin-right: calc(20rem / 16) !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.3125rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.625rem !important; }
  .mb-sm-3 {
    margin-bottom: 0.9375rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.25rem !important; }
  .mb-sm-5 {
    margin-bottom: 1.5625rem !important; }
  .mb-sm-6 {
    margin-bottom: 1.875rem !important; }
  .mb-sm-7 {
    margin-bottom: 2.1875rem !important; }
  .mb-sm-8 {
    margin-bottom: 2.5rem !important; }
  .mb-sm-9 {
    margin-bottom: 2.8125rem !important; }
  .mb-sm-10 {
    margin-bottom: 3.125rem !important; }
  .mb-sm-11 {
    margin-bottom: 3.4375rem !important; }
  .mb-sm-12 {
    margin-bottom: 3.75rem !important; }
  .mb-sm-13 {
    margin-bottom: 4.0625rem !important; }
  .mb-sm-14 {
    margin-bottom: 4.375rem !important; }
  .mb-sm-15 {
    margin-bottom: 4.6875rem !important; }
  .mb-sm-16 {
    margin-bottom: 5rem !important; }
  .mb-sm-17 {
    margin-bottom: 5.3125rem !important; }
  .mb-sm-18 {
    margin-bottom: 5.625rem !important; }
  .mb-sm-19 {
    margin-bottom: 5.9375rem !important; }
  .mb-sm-20 {
    margin-bottom: 6.25rem !important; }
  .mb-sm-21 {
    margin-bottom: 6.5625rem !important; }
  .mb-sm-22 {
    margin-bottom: 6.875rem !important; }
  .mb-sm-23 {
    margin-bottom: 7.1875rem !important; }
  .mb-sm-24 {
    margin-bottom: 7.5rem !important; }
  .mb-sm-25 {
    margin-bottom: 7.8125rem !important; }
  .mb-sm-26 {
    margin-bottom: 8.125rem !important; }
  .mb-sm-27 {
    margin-bottom: 8.4375rem !important; }
  .mb-sm-28 {
    margin-bottom: 8.75rem !important; }
  .mb-sm-29 {
    margin-bottom: 9.0625rem !important; }
  .mb-sm-30 {
    margin-bottom: 9.375rem !important; }
  .mb-sm-31 {
    margin-bottom: 9.6875rem !important; }
  .mb-sm-32 {
    margin-bottom: 10rem !important; }
  .mb-sm-33 {
    margin-bottom: 10.3125rem !important; }
  .mb-sm-34 {
    margin-bottom: 10.625rem !important; }
  .mb-sm-35 {
    margin-bottom: 10.9375rem !important; }
  .mb-sm-36 {
    margin-bottom: 11.25rem !important; }
  .mb-sm-37 {
    margin-bottom: 11.5625rem !important; }
  .mb-sm-38 {
    margin-bottom: 11.875rem !important; }
  .mb-sm-39 {
    margin-bottom: 12.1875rem !important; }
  .mb-sm-40 {
    margin-bottom: 12.5rem !important; }
  .mb-sm-base {
    margin-bottom: calc(20rem / 16) !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.3125rem !important; }
  .ms-sm-2 {
    margin-left: 0.625rem !important; }
  .ms-sm-3 {
    margin-left: 0.9375rem !important; }
  .ms-sm-4 {
    margin-left: 1.25rem !important; }
  .ms-sm-5 {
    margin-left: 1.5625rem !important; }
  .ms-sm-6 {
    margin-left: 1.875rem !important; }
  .ms-sm-7 {
    margin-left: 2.1875rem !important; }
  .ms-sm-8 {
    margin-left: 2.5rem !important; }
  .ms-sm-9 {
    margin-left: 2.8125rem !important; }
  .ms-sm-10 {
    margin-left: 3.125rem !important; }
  .ms-sm-11 {
    margin-left: 3.4375rem !important; }
  .ms-sm-12 {
    margin-left: 3.75rem !important; }
  .ms-sm-13 {
    margin-left: 4.0625rem !important; }
  .ms-sm-14 {
    margin-left: 4.375rem !important; }
  .ms-sm-15 {
    margin-left: 4.6875rem !important; }
  .ms-sm-16 {
    margin-left: 5rem !important; }
  .ms-sm-17 {
    margin-left: 5.3125rem !important; }
  .ms-sm-18 {
    margin-left: 5.625rem !important; }
  .ms-sm-19 {
    margin-left: 5.9375rem !important; }
  .ms-sm-20 {
    margin-left: 6.25rem !important; }
  .ms-sm-21 {
    margin-left: 6.5625rem !important; }
  .ms-sm-22 {
    margin-left: 6.875rem !important; }
  .ms-sm-23 {
    margin-left: 7.1875rem !important; }
  .ms-sm-24 {
    margin-left: 7.5rem !important; }
  .ms-sm-25 {
    margin-left: 7.8125rem !important; }
  .ms-sm-26 {
    margin-left: 8.125rem !important; }
  .ms-sm-27 {
    margin-left: 8.4375rem !important; }
  .ms-sm-28 {
    margin-left: 8.75rem !important; }
  .ms-sm-29 {
    margin-left: 9.0625rem !important; }
  .ms-sm-30 {
    margin-left: 9.375rem !important; }
  .ms-sm-31 {
    margin-left: 9.6875rem !important; }
  .ms-sm-32 {
    margin-left: 10rem !important; }
  .ms-sm-33 {
    margin-left: 10.3125rem !important; }
  .ms-sm-34 {
    margin-left: 10.625rem !important; }
  .ms-sm-35 {
    margin-left: 10.9375rem !important; }
  .ms-sm-36 {
    margin-left: 11.25rem !important; }
  .ms-sm-37 {
    margin-left: 11.5625rem !important; }
  .ms-sm-38 {
    margin-left: 11.875rem !important; }
  .ms-sm-39 {
    margin-left: 12.1875rem !important; }
  .ms-sm-40 {
    margin-left: 12.5rem !important; }
  .ms-sm-base {
    margin-left: calc(20rem / 16) !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .m-sm-n1 {
    margin: -0.3125rem !important; }
  .m-sm-n2 {
    margin: -0.625rem !important; }
  .m-sm-n3 {
    margin: -0.9375rem !important; }
  .m-sm-n4 {
    margin: -1.25rem !important; }
  .m-sm-n5 {
    margin: -1.5625rem !important; }
  .m-sm-n6 {
    margin: -1.875rem !important; }
  .m-sm-n7 {
    margin: -2.1875rem !important; }
  .m-sm-n8 {
    margin: -2.5rem !important; }
  .m-sm-n9 {
    margin: -2.8125rem !important; }
  .m-sm-n10 {
    margin: -3.125rem !important; }
  .m-sm-n11 {
    margin: -3.4375rem !important; }
  .m-sm-n12 {
    margin: -3.75rem !important; }
  .m-sm-n13 {
    margin: -4.0625rem !important; }
  .m-sm-n14 {
    margin: -4.375rem !important; }
  .m-sm-n15 {
    margin: -4.6875rem !important; }
  .m-sm-n16 {
    margin: -5rem !important; }
  .m-sm-n17 {
    margin: -5.3125rem !important; }
  .m-sm-n18 {
    margin: -5.625rem !important; }
  .m-sm-n19 {
    margin: -5.9375rem !important; }
  .m-sm-n20 {
    margin: -6.25rem !important; }
  .m-sm-n21 {
    margin: -6.5625rem !important; }
  .m-sm-n22 {
    margin: -6.875rem !important; }
  .m-sm-n23 {
    margin: -7.1875rem !important; }
  .m-sm-n24 {
    margin: -7.5rem !important; }
  .m-sm-n25 {
    margin: -7.8125rem !important; }
  .m-sm-n26 {
    margin: -8.125rem !important; }
  .m-sm-n27 {
    margin: -8.4375rem !important; }
  .m-sm-n28 {
    margin: -8.75rem !important; }
  .m-sm-n29 {
    margin: -9.0625rem !important; }
  .m-sm-n30 {
    margin: -9.375rem !important; }
  .m-sm-n31 {
    margin: -9.6875rem !important; }
  .m-sm-n32 {
    margin: -10rem !important; }
  .m-sm-n33 {
    margin: -10.3125rem !important; }
  .m-sm-n34 {
    margin: -10.625rem !important; }
  .m-sm-n35 {
    margin: -10.9375rem !important; }
  .m-sm-n36 {
    margin: -11.25rem !important; }
  .m-sm-n37 {
    margin: -11.5625rem !important; }
  .m-sm-n38 {
    margin: -11.875rem !important; }
  .m-sm-n39 {
    margin: -12.1875rem !important; }
  .m-sm-n40 {
    margin: -12.5rem !important; }
  .m-sm-nbase {
    margin: -calc(20rem / 16) !important; }
  .mx-sm-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important; }
  .mx-sm-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important; }
  .mx-sm-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important; }
  .mx-sm-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important; }
  .mx-sm-n5 {
    margin-right: -1.5625rem !important;
    margin-left: -1.5625rem !important; }
  .mx-sm-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important; }
  .mx-sm-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important; }
  .mx-sm-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-sm-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important; }
  .mx-sm-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important; }
  .mx-sm-n11 {
    margin-right: -3.4375rem !important;
    margin-left: -3.4375rem !important; }
  .mx-sm-n12 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important; }
  .mx-sm-n13 {
    margin-right: -4.0625rem !important;
    margin-left: -4.0625rem !important; }
  .mx-sm-n14 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important; }
  .mx-sm-n15 {
    margin-right: -4.6875rem !important;
    margin-left: -4.6875rem !important; }
  .mx-sm-n16 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-sm-n17 {
    margin-right: -5.3125rem !important;
    margin-left: -5.3125rem !important; }
  .mx-sm-n18 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important; }
  .mx-sm-n19 {
    margin-right: -5.9375rem !important;
    margin-left: -5.9375rem !important; }
  .mx-sm-n20 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important; }
  .mx-sm-n21 {
    margin-right: -6.5625rem !important;
    margin-left: -6.5625rem !important; }
  .mx-sm-n22 {
    margin-right: -6.875rem !important;
    margin-left: -6.875rem !important; }
  .mx-sm-n23 {
    margin-right: -7.1875rem !important;
    margin-left: -7.1875rem !important; }
  .mx-sm-n24 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-sm-n25 {
    margin-right: -7.8125rem !important;
    margin-left: -7.8125rem !important; }
  .mx-sm-n26 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important; }
  .mx-sm-n27 {
    margin-right: -8.4375rem !important;
    margin-left: -8.4375rem !important; }
  .mx-sm-n28 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important; }
  .mx-sm-n29 {
    margin-right: -9.0625rem !important;
    margin-left: -9.0625rem !important; }
  .mx-sm-n30 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important; }
  .mx-sm-n31 {
    margin-right: -9.6875rem !important;
    margin-left: -9.6875rem !important; }
  .mx-sm-n32 {
    margin-right: -10rem !important;
    margin-left: -10rem !important; }
  .mx-sm-n33 {
    margin-right: -10.3125rem !important;
    margin-left: -10.3125rem !important; }
  .mx-sm-n34 {
    margin-right: -10.625rem !important;
    margin-left: -10.625rem !important; }
  .mx-sm-n35 {
    margin-right: -10.9375rem !important;
    margin-left: -10.9375rem !important; }
  .mx-sm-n36 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important; }
  .mx-sm-n37 {
    margin-right: -11.5625rem !important;
    margin-left: -11.5625rem !important; }
  .mx-sm-n38 {
    margin-right: -11.875rem !important;
    margin-left: -11.875rem !important; }
  .mx-sm-n39 {
    margin-right: -12.1875rem !important;
    margin-left: -12.1875rem !important; }
  .mx-sm-n40 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important; }
  .mx-sm-nbase {
    margin-right: -calc(20rem / 16) !important;
    margin-left: -calc(20rem / 16) !important; }
  .my-sm-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important; }
  .my-sm-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important; }
  .my-sm-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important; }
  .my-sm-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .my-sm-n5 {
    margin-top: -1.5625rem !important;
    margin-bottom: -1.5625rem !important; }
  .my-sm-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important; }
  .my-sm-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important; }
  .my-sm-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-sm-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important; }
  .my-sm-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important; }
  .my-sm-n11 {
    margin-top: -3.4375rem !important;
    margin-bottom: -3.4375rem !important; }
  .my-sm-n12 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important; }
  .my-sm-n13 {
    margin-top: -4.0625rem !important;
    margin-bottom: -4.0625rem !important; }
  .my-sm-n14 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important; }
  .my-sm-n15 {
    margin-top: -4.6875rem !important;
    margin-bottom: -4.6875rem !important; }
  .my-sm-n16 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-sm-n17 {
    margin-top: -5.3125rem !important;
    margin-bottom: -5.3125rem !important; }
  .my-sm-n18 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important; }
  .my-sm-n19 {
    margin-top: -5.9375rem !important;
    margin-bottom: -5.9375rem !important; }
  .my-sm-n20 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important; }
  .my-sm-n21 {
    margin-top: -6.5625rem !important;
    margin-bottom: -6.5625rem !important; }
  .my-sm-n22 {
    margin-top: -6.875rem !important;
    margin-bottom: -6.875rem !important; }
  .my-sm-n23 {
    margin-top: -7.1875rem !important;
    margin-bottom: -7.1875rem !important; }
  .my-sm-n24 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-sm-n25 {
    margin-top: -7.8125rem !important;
    margin-bottom: -7.8125rem !important; }
  .my-sm-n26 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important; }
  .my-sm-n27 {
    margin-top: -8.4375rem !important;
    margin-bottom: -8.4375rem !important; }
  .my-sm-n28 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important; }
  .my-sm-n29 {
    margin-top: -9.0625rem !important;
    margin-bottom: -9.0625rem !important; }
  .my-sm-n30 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important; }
  .my-sm-n31 {
    margin-top: -9.6875rem !important;
    margin-bottom: -9.6875rem !important; }
  .my-sm-n32 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .my-sm-n33 {
    margin-top: -10.3125rem !important;
    margin-bottom: -10.3125rem !important; }
  .my-sm-n34 {
    margin-top: -10.625rem !important;
    margin-bottom: -10.625rem !important; }
  .my-sm-n35 {
    margin-top: -10.9375rem !important;
    margin-bottom: -10.9375rem !important; }
  .my-sm-n36 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important; }
  .my-sm-n37 {
    margin-top: -11.5625rem !important;
    margin-bottom: -11.5625rem !important; }
  .my-sm-n38 {
    margin-top: -11.875rem !important;
    margin-bottom: -11.875rem !important; }
  .my-sm-n39 {
    margin-top: -12.1875rem !important;
    margin-bottom: -12.1875rem !important; }
  .my-sm-n40 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important; }
  .my-sm-nbase {
    margin-top: -calc(20rem / 16) !important;
    margin-bottom: -calc(20rem / 16) !important; }
  .mt-sm-n1 {
    margin-top: -0.3125rem !important; }
  .mt-sm-n2 {
    margin-top: -0.625rem !important; }
  .mt-sm-n3 {
    margin-top: -0.9375rem !important; }
  .mt-sm-n4 {
    margin-top: -1.25rem !important; }
  .mt-sm-n5 {
    margin-top: -1.5625rem !important; }
  .mt-sm-n6 {
    margin-top: -1.875rem !important; }
  .mt-sm-n7 {
    margin-top: -2.1875rem !important; }
  .mt-sm-n8 {
    margin-top: -2.5rem !important; }
  .mt-sm-n9 {
    margin-top: -2.8125rem !important; }
  .mt-sm-n10 {
    margin-top: -3.125rem !important; }
  .mt-sm-n11 {
    margin-top: -3.4375rem !important; }
  .mt-sm-n12 {
    margin-top: -3.75rem !important; }
  .mt-sm-n13 {
    margin-top: -4.0625rem !important; }
  .mt-sm-n14 {
    margin-top: -4.375rem !important; }
  .mt-sm-n15 {
    margin-top: -4.6875rem !important; }
  .mt-sm-n16 {
    margin-top: -5rem !important; }
  .mt-sm-n17 {
    margin-top: -5.3125rem !important; }
  .mt-sm-n18 {
    margin-top: -5.625rem !important; }
  .mt-sm-n19 {
    margin-top: -5.9375rem !important; }
  .mt-sm-n20 {
    margin-top: -6.25rem !important; }
  .mt-sm-n21 {
    margin-top: -6.5625rem !important; }
  .mt-sm-n22 {
    margin-top: -6.875rem !important; }
  .mt-sm-n23 {
    margin-top: -7.1875rem !important; }
  .mt-sm-n24 {
    margin-top: -7.5rem !important; }
  .mt-sm-n25 {
    margin-top: -7.8125rem !important; }
  .mt-sm-n26 {
    margin-top: -8.125rem !important; }
  .mt-sm-n27 {
    margin-top: -8.4375rem !important; }
  .mt-sm-n28 {
    margin-top: -8.75rem !important; }
  .mt-sm-n29 {
    margin-top: -9.0625rem !important; }
  .mt-sm-n30 {
    margin-top: -9.375rem !important; }
  .mt-sm-n31 {
    margin-top: -9.6875rem !important; }
  .mt-sm-n32 {
    margin-top: -10rem !important; }
  .mt-sm-n33 {
    margin-top: -10.3125rem !important; }
  .mt-sm-n34 {
    margin-top: -10.625rem !important; }
  .mt-sm-n35 {
    margin-top: -10.9375rem !important; }
  .mt-sm-n36 {
    margin-top: -11.25rem !important; }
  .mt-sm-n37 {
    margin-top: -11.5625rem !important; }
  .mt-sm-n38 {
    margin-top: -11.875rem !important; }
  .mt-sm-n39 {
    margin-top: -12.1875rem !important; }
  .mt-sm-n40 {
    margin-top: -12.5rem !important; }
  .mt-sm-nbase {
    margin-top: -calc(20rem / 16) !important; }
  .me-sm-n1 {
    margin-right: -0.3125rem !important; }
  .me-sm-n2 {
    margin-right: -0.625rem !important; }
  .me-sm-n3 {
    margin-right: -0.9375rem !important; }
  .me-sm-n4 {
    margin-right: -1.25rem !important; }
  .me-sm-n5 {
    margin-right: -1.5625rem !important; }
  .me-sm-n6 {
    margin-right: -1.875rem !important; }
  .me-sm-n7 {
    margin-right: -2.1875rem !important; }
  .me-sm-n8 {
    margin-right: -2.5rem !important; }
  .me-sm-n9 {
    margin-right: -2.8125rem !important; }
  .me-sm-n10 {
    margin-right: -3.125rem !important; }
  .me-sm-n11 {
    margin-right: -3.4375rem !important; }
  .me-sm-n12 {
    margin-right: -3.75rem !important; }
  .me-sm-n13 {
    margin-right: -4.0625rem !important; }
  .me-sm-n14 {
    margin-right: -4.375rem !important; }
  .me-sm-n15 {
    margin-right: -4.6875rem !important; }
  .me-sm-n16 {
    margin-right: -5rem !important; }
  .me-sm-n17 {
    margin-right: -5.3125rem !important; }
  .me-sm-n18 {
    margin-right: -5.625rem !important; }
  .me-sm-n19 {
    margin-right: -5.9375rem !important; }
  .me-sm-n20 {
    margin-right: -6.25rem !important; }
  .me-sm-n21 {
    margin-right: -6.5625rem !important; }
  .me-sm-n22 {
    margin-right: -6.875rem !important; }
  .me-sm-n23 {
    margin-right: -7.1875rem !important; }
  .me-sm-n24 {
    margin-right: -7.5rem !important; }
  .me-sm-n25 {
    margin-right: -7.8125rem !important; }
  .me-sm-n26 {
    margin-right: -8.125rem !important; }
  .me-sm-n27 {
    margin-right: -8.4375rem !important; }
  .me-sm-n28 {
    margin-right: -8.75rem !important; }
  .me-sm-n29 {
    margin-right: -9.0625rem !important; }
  .me-sm-n30 {
    margin-right: -9.375rem !important; }
  .me-sm-n31 {
    margin-right: -9.6875rem !important; }
  .me-sm-n32 {
    margin-right: -10rem !important; }
  .me-sm-n33 {
    margin-right: -10.3125rem !important; }
  .me-sm-n34 {
    margin-right: -10.625rem !important; }
  .me-sm-n35 {
    margin-right: -10.9375rem !important; }
  .me-sm-n36 {
    margin-right: -11.25rem !important; }
  .me-sm-n37 {
    margin-right: -11.5625rem !important; }
  .me-sm-n38 {
    margin-right: -11.875rem !important; }
  .me-sm-n39 {
    margin-right: -12.1875rem !important; }
  .me-sm-n40 {
    margin-right: -12.5rem !important; }
  .me-sm-nbase {
    margin-right: -calc(20rem / 16) !important; }
  .mb-sm-n1 {
    margin-bottom: -0.3125rem !important; }
  .mb-sm-n2 {
    margin-bottom: -0.625rem !important; }
  .mb-sm-n3 {
    margin-bottom: -0.9375rem !important; }
  .mb-sm-n4 {
    margin-bottom: -1.25rem !important; }
  .mb-sm-n5 {
    margin-bottom: -1.5625rem !important; }
  .mb-sm-n6 {
    margin-bottom: -1.875rem !important; }
  .mb-sm-n7 {
    margin-bottom: -2.1875rem !important; }
  .mb-sm-n8 {
    margin-bottom: -2.5rem !important; }
  .mb-sm-n9 {
    margin-bottom: -2.8125rem !important; }
  .mb-sm-n10 {
    margin-bottom: -3.125rem !important; }
  .mb-sm-n11 {
    margin-bottom: -3.4375rem !important; }
  .mb-sm-n12 {
    margin-bottom: -3.75rem !important; }
  .mb-sm-n13 {
    margin-bottom: -4.0625rem !important; }
  .mb-sm-n14 {
    margin-bottom: -4.375rem !important; }
  .mb-sm-n15 {
    margin-bottom: -4.6875rem !important; }
  .mb-sm-n16 {
    margin-bottom: -5rem !important; }
  .mb-sm-n17 {
    margin-bottom: -5.3125rem !important; }
  .mb-sm-n18 {
    margin-bottom: -5.625rem !important; }
  .mb-sm-n19 {
    margin-bottom: -5.9375rem !important; }
  .mb-sm-n20 {
    margin-bottom: -6.25rem !important; }
  .mb-sm-n21 {
    margin-bottom: -6.5625rem !important; }
  .mb-sm-n22 {
    margin-bottom: -6.875rem !important; }
  .mb-sm-n23 {
    margin-bottom: -7.1875rem !important; }
  .mb-sm-n24 {
    margin-bottom: -7.5rem !important; }
  .mb-sm-n25 {
    margin-bottom: -7.8125rem !important; }
  .mb-sm-n26 {
    margin-bottom: -8.125rem !important; }
  .mb-sm-n27 {
    margin-bottom: -8.4375rem !important; }
  .mb-sm-n28 {
    margin-bottom: -8.75rem !important; }
  .mb-sm-n29 {
    margin-bottom: -9.0625rem !important; }
  .mb-sm-n30 {
    margin-bottom: -9.375rem !important; }
  .mb-sm-n31 {
    margin-bottom: -9.6875rem !important; }
  .mb-sm-n32 {
    margin-bottom: -10rem !important; }
  .mb-sm-n33 {
    margin-bottom: -10.3125rem !important; }
  .mb-sm-n34 {
    margin-bottom: -10.625rem !important; }
  .mb-sm-n35 {
    margin-bottom: -10.9375rem !important; }
  .mb-sm-n36 {
    margin-bottom: -11.25rem !important; }
  .mb-sm-n37 {
    margin-bottom: -11.5625rem !important; }
  .mb-sm-n38 {
    margin-bottom: -11.875rem !important; }
  .mb-sm-n39 {
    margin-bottom: -12.1875rem !important; }
  .mb-sm-n40 {
    margin-bottom: -12.5rem !important; }
  .mb-sm-nbase {
    margin-bottom: -calc(20rem / 16) !important; }
  .ms-sm-n1 {
    margin-left: -0.3125rem !important; }
  .ms-sm-n2 {
    margin-left: -0.625rem !important; }
  .ms-sm-n3 {
    margin-left: -0.9375rem !important; }
  .ms-sm-n4 {
    margin-left: -1.25rem !important; }
  .ms-sm-n5 {
    margin-left: -1.5625rem !important; }
  .ms-sm-n6 {
    margin-left: -1.875rem !important; }
  .ms-sm-n7 {
    margin-left: -2.1875rem !important; }
  .ms-sm-n8 {
    margin-left: -2.5rem !important; }
  .ms-sm-n9 {
    margin-left: -2.8125rem !important; }
  .ms-sm-n10 {
    margin-left: -3.125rem !important; }
  .ms-sm-n11 {
    margin-left: -3.4375rem !important; }
  .ms-sm-n12 {
    margin-left: -3.75rem !important; }
  .ms-sm-n13 {
    margin-left: -4.0625rem !important; }
  .ms-sm-n14 {
    margin-left: -4.375rem !important; }
  .ms-sm-n15 {
    margin-left: -4.6875rem !important; }
  .ms-sm-n16 {
    margin-left: -5rem !important; }
  .ms-sm-n17 {
    margin-left: -5.3125rem !important; }
  .ms-sm-n18 {
    margin-left: -5.625rem !important; }
  .ms-sm-n19 {
    margin-left: -5.9375rem !important; }
  .ms-sm-n20 {
    margin-left: -6.25rem !important; }
  .ms-sm-n21 {
    margin-left: -6.5625rem !important; }
  .ms-sm-n22 {
    margin-left: -6.875rem !important; }
  .ms-sm-n23 {
    margin-left: -7.1875rem !important; }
  .ms-sm-n24 {
    margin-left: -7.5rem !important; }
  .ms-sm-n25 {
    margin-left: -7.8125rem !important; }
  .ms-sm-n26 {
    margin-left: -8.125rem !important; }
  .ms-sm-n27 {
    margin-left: -8.4375rem !important; }
  .ms-sm-n28 {
    margin-left: -8.75rem !important; }
  .ms-sm-n29 {
    margin-left: -9.0625rem !important; }
  .ms-sm-n30 {
    margin-left: -9.375rem !important; }
  .ms-sm-n31 {
    margin-left: -9.6875rem !important; }
  .ms-sm-n32 {
    margin-left: -10rem !important; }
  .ms-sm-n33 {
    margin-left: -10.3125rem !important; }
  .ms-sm-n34 {
    margin-left: -10.625rem !important; }
  .ms-sm-n35 {
    margin-left: -10.9375rem !important; }
  .ms-sm-n36 {
    margin-left: -11.25rem !important; }
  .ms-sm-n37 {
    margin-left: -11.5625rem !important; }
  .ms-sm-n38 {
    margin-left: -11.875rem !important; }
  .ms-sm-n39 {
    margin-left: -12.1875rem !important; }
  .ms-sm-n40 {
    margin-left: -12.5rem !important; }
  .ms-sm-nbase {
    margin-left: -calc(20rem / 16) !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.3125rem !important; }
  .p-sm-2 {
    padding: 0.625rem !important; }
  .p-sm-3 {
    padding: 0.9375rem !important; }
  .p-sm-4 {
    padding: 1.25rem !important; }
  .p-sm-5 {
    padding: 1.5625rem !important; }
  .p-sm-6 {
    padding: 1.875rem !important; }
  .p-sm-7 {
    padding: 2.1875rem !important; }
  .p-sm-8 {
    padding: 2.5rem !important; }
  .p-sm-9 {
    padding: 2.8125rem !important; }
  .p-sm-10 {
    padding: 3.125rem !important; }
  .p-sm-11 {
    padding: 3.4375rem !important; }
  .p-sm-12 {
    padding: 3.75rem !important; }
  .p-sm-13 {
    padding: 4.0625rem !important; }
  .p-sm-14 {
    padding: 4.375rem !important; }
  .p-sm-15 {
    padding: 4.6875rem !important; }
  .p-sm-16 {
    padding: 5rem !important; }
  .p-sm-17 {
    padding: 5.3125rem !important; }
  .p-sm-18 {
    padding: 5.625rem !important; }
  .p-sm-19 {
    padding: 5.9375rem !important; }
  .p-sm-20 {
    padding: 6.25rem !important; }
  .p-sm-21 {
    padding: 6.5625rem !important; }
  .p-sm-22 {
    padding: 6.875rem !important; }
  .p-sm-23 {
    padding: 7.1875rem !important; }
  .p-sm-24 {
    padding: 7.5rem !important; }
  .p-sm-25 {
    padding: 7.8125rem !important; }
  .p-sm-26 {
    padding: 8.125rem !important; }
  .p-sm-27 {
    padding: 8.4375rem !important; }
  .p-sm-28 {
    padding: 8.75rem !important; }
  .p-sm-29 {
    padding: 9.0625rem !important; }
  .p-sm-30 {
    padding: 9.375rem !important; }
  .p-sm-31 {
    padding: 9.6875rem !important; }
  .p-sm-32 {
    padding: 10rem !important; }
  .p-sm-33 {
    padding: 10.3125rem !important; }
  .p-sm-34 {
    padding: 10.625rem !important; }
  .p-sm-35 {
    padding: 10.9375rem !important; }
  .p-sm-36 {
    padding: 11.25rem !important; }
  .p-sm-37 {
    padding: 11.5625rem !important; }
  .p-sm-38 {
    padding: 11.875rem !important; }
  .p-sm-39 {
    padding: 12.1875rem !important; }
  .p-sm-40 {
    padding: 12.5rem !important; }
  .p-sm-base {
    padding: calc(20rem / 16) !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important; }
  .px-sm-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important; }
  .px-sm-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important; }
  .px-sm-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important; }
  .px-sm-5 {
    padding-right: 1.5625rem !important;
    padding-left: 1.5625rem !important; }
  .px-sm-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important; }
  .px-sm-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important; }
  .px-sm-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-sm-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important; }
  .px-sm-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important; }
  .px-sm-11 {
    padding-right: 3.4375rem !important;
    padding-left: 3.4375rem !important; }
  .px-sm-12 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important; }
  .px-sm-13 {
    padding-right: 4.0625rem !important;
    padding-left: 4.0625rem !important; }
  .px-sm-14 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important; }
  .px-sm-15 {
    padding-right: 4.6875rem !important;
    padding-left: 4.6875rem !important; }
  .px-sm-16 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-sm-17 {
    padding-right: 5.3125rem !important;
    padding-left: 5.3125rem !important; }
  .px-sm-18 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important; }
  .px-sm-19 {
    padding-right: 5.9375rem !important;
    padding-left: 5.9375rem !important; }
  .px-sm-20 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important; }
  .px-sm-21 {
    padding-right: 6.5625rem !important;
    padding-left: 6.5625rem !important; }
  .px-sm-22 {
    padding-right: 6.875rem !important;
    padding-left: 6.875rem !important; }
  .px-sm-23 {
    padding-right: 7.1875rem !important;
    padding-left: 7.1875rem !important; }
  .px-sm-24 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-sm-25 {
    padding-right: 7.8125rem !important;
    padding-left: 7.8125rem !important; }
  .px-sm-26 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important; }
  .px-sm-27 {
    padding-right: 8.4375rem !important;
    padding-left: 8.4375rem !important; }
  .px-sm-28 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important; }
  .px-sm-29 {
    padding-right: 9.0625rem !important;
    padding-left: 9.0625rem !important; }
  .px-sm-30 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important; }
  .px-sm-31 {
    padding-right: 9.6875rem !important;
    padding-left: 9.6875rem !important; }
  .px-sm-32 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-sm-33 {
    padding-right: 10.3125rem !important;
    padding-left: 10.3125rem !important; }
  .px-sm-34 {
    padding-right: 10.625rem !important;
    padding-left: 10.625rem !important; }
  .px-sm-35 {
    padding-right: 10.9375rem !important;
    padding-left: 10.9375rem !important; }
  .px-sm-36 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important; }
  .px-sm-37 {
    padding-right: 11.5625rem !important;
    padding-left: 11.5625rem !important; }
  .px-sm-38 {
    padding-right: 11.875rem !important;
    padding-left: 11.875rem !important; }
  .px-sm-39 {
    padding-right: 12.1875rem !important;
    padding-left: 12.1875rem !important; }
  .px-sm-40 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-sm-base {
    padding-right: calc(20rem / 16) !important;
    padding-left: calc(20rem / 16) !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important; }
  .py-sm-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important; }
  .py-sm-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important; }
  .py-sm-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .py-sm-5 {
    padding-top: 1.5625rem !important;
    padding-bottom: 1.5625rem !important; }
  .py-sm-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important; }
  .py-sm-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important; }
  .py-sm-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-sm-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important; }
  .py-sm-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important; }
  .py-sm-11 {
    padding-top: 3.4375rem !important;
    padding-bottom: 3.4375rem !important; }
  .py-sm-12 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important; }
  .py-sm-13 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important; }
  .py-sm-14 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important; }
  .py-sm-15 {
    padding-top: 4.6875rem !important;
    padding-bottom: 4.6875rem !important; }
  .py-sm-16 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-sm-17 {
    padding-top: 5.3125rem !important;
    padding-bottom: 5.3125rem !important; }
  .py-sm-18 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important; }
  .py-sm-19 {
    padding-top: 5.9375rem !important;
    padding-bottom: 5.9375rem !important; }
  .py-sm-20 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important; }
  .py-sm-21 {
    padding-top: 6.5625rem !important;
    padding-bottom: 6.5625rem !important; }
  .py-sm-22 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important; }
  .py-sm-23 {
    padding-top: 7.1875rem !important;
    padding-bottom: 7.1875rem !important; }
  .py-sm-24 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-sm-25 {
    padding-top: 7.8125rem !important;
    padding-bottom: 7.8125rem !important; }
  .py-sm-26 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important; }
  .py-sm-27 {
    padding-top: 8.4375rem !important;
    padding-bottom: 8.4375rem !important; }
  .py-sm-28 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important; }
  .py-sm-29 {
    padding-top: 9.0625rem !important;
    padding-bottom: 9.0625rem !important; }
  .py-sm-30 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important; }
  .py-sm-31 {
    padding-top: 9.6875rem !important;
    padding-bottom: 9.6875rem !important; }
  .py-sm-32 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-sm-33 {
    padding-top: 10.3125rem !important;
    padding-bottom: 10.3125rem !important; }
  .py-sm-34 {
    padding-top: 10.625rem !important;
    padding-bottom: 10.625rem !important; }
  .py-sm-35 {
    padding-top: 10.9375rem !important;
    padding-bottom: 10.9375rem !important; }
  .py-sm-36 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important; }
  .py-sm-37 {
    padding-top: 11.5625rem !important;
    padding-bottom: 11.5625rem !important; }
  .py-sm-38 {
    padding-top: 11.875rem !important;
    padding-bottom: 11.875rem !important; }
  .py-sm-39 {
    padding-top: 12.1875rem !important;
    padding-bottom: 12.1875rem !important; }
  .py-sm-40 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-sm-base {
    padding-top: calc(20rem / 16) !important;
    padding-bottom: calc(20rem / 16) !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.3125rem !important; }
  .pt-sm-2 {
    padding-top: 0.625rem !important; }
  .pt-sm-3 {
    padding-top: 0.9375rem !important; }
  .pt-sm-4 {
    padding-top: 1.25rem !important; }
  .pt-sm-5 {
    padding-top: 1.5625rem !important; }
  .pt-sm-6 {
    padding-top: 1.875rem !important; }
  .pt-sm-7 {
    padding-top: 2.1875rem !important; }
  .pt-sm-8 {
    padding-top: 2.5rem !important; }
  .pt-sm-9 {
    padding-top: 2.8125rem !important; }
  .pt-sm-10 {
    padding-top: 3.125rem !important; }
  .pt-sm-11 {
    padding-top: 3.4375rem !important; }
  .pt-sm-12 {
    padding-top: 3.75rem !important; }
  .pt-sm-13 {
    padding-top: 4.0625rem !important; }
  .pt-sm-14 {
    padding-top: 4.375rem !important; }
  .pt-sm-15 {
    padding-top: 4.6875rem !important; }
  .pt-sm-16 {
    padding-top: 5rem !important; }
  .pt-sm-17 {
    padding-top: 5.3125rem !important; }
  .pt-sm-18 {
    padding-top: 5.625rem !important; }
  .pt-sm-19 {
    padding-top: 5.9375rem !important; }
  .pt-sm-20 {
    padding-top: 6.25rem !important; }
  .pt-sm-21 {
    padding-top: 6.5625rem !important; }
  .pt-sm-22 {
    padding-top: 6.875rem !important; }
  .pt-sm-23 {
    padding-top: 7.1875rem !important; }
  .pt-sm-24 {
    padding-top: 7.5rem !important; }
  .pt-sm-25 {
    padding-top: 7.8125rem !important; }
  .pt-sm-26 {
    padding-top: 8.125rem !important; }
  .pt-sm-27 {
    padding-top: 8.4375rem !important; }
  .pt-sm-28 {
    padding-top: 8.75rem !important; }
  .pt-sm-29 {
    padding-top: 9.0625rem !important; }
  .pt-sm-30 {
    padding-top: 9.375rem !important; }
  .pt-sm-31 {
    padding-top: 9.6875rem !important; }
  .pt-sm-32 {
    padding-top: 10rem !important; }
  .pt-sm-33 {
    padding-top: 10.3125rem !important; }
  .pt-sm-34 {
    padding-top: 10.625rem !important; }
  .pt-sm-35 {
    padding-top: 10.9375rem !important; }
  .pt-sm-36 {
    padding-top: 11.25rem !important; }
  .pt-sm-37 {
    padding-top: 11.5625rem !important; }
  .pt-sm-38 {
    padding-top: 11.875rem !important; }
  .pt-sm-39 {
    padding-top: 12.1875rem !important; }
  .pt-sm-40 {
    padding-top: 12.5rem !important; }
  .pt-sm-base {
    padding-top: calc(20rem / 16) !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.3125rem !important; }
  .pe-sm-2 {
    padding-right: 0.625rem !important; }
  .pe-sm-3 {
    padding-right: 0.9375rem !important; }
  .pe-sm-4 {
    padding-right: 1.25rem !important; }
  .pe-sm-5 {
    padding-right: 1.5625rem !important; }
  .pe-sm-6 {
    padding-right: 1.875rem !important; }
  .pe-sm-7 {
    padding-right: 2.1875rem !important; }
  .pe-sm-8 {
    padding-right: 2.5rem !important; }
  .pe-sm-9 {
    padding-right: 2.8125rem !important; }
  .pe-sm-10 {
    padding-right: 3.125rem !important; }
  .pe-sm-11 {
    padding-right: 3.4375rem !important; }
  .pe-sm-12 {
    padding-right: 3.75rem !important; }
  .pe-sm-13 {
    padding-right: 4.0625rem !important; }
  .pe-sm-14 {
    padding-right: 4.375rem !important; }
  .pe-sm-15 {
    padding-right: 4.6875rem !important; }
  .pe-sm-16 {
    padding-right: 5rem !important; }
  .pe-sm-17 {
    padding-right: 5.3125rem !important; }
  .pe-sm-18 {
    padding-right: 5.625rem !important; }
  .pe-sm-19 {
    padding-right: 5.9375rem !important; }
  .pe-sm-20 {
    padding-right: 6.25rem !important; }
  .pe-sm-21 {
    padding-right: 6.5625rem !important; }
  .pe-sm-22 {
    padding-right: 6.875rem !important; }
  .pe-sm-23 {
    padding-right: 7.1875rem !important; }
  .pe-sm-24 {
    padding-right: 7.5rem !important; }
  .pe-sm-25 {
    padding-right: 7.8125rem !important; }
  .pe-sm-26 {
    padding-right: 8.125rem !important; }
  .pe-sm-27 {
    padding-right: 8.4375rem !important; }
  .pe-sm-28 {
    padding-right: 8.75rem !important; }
  .pe-sm-29 {
    padding-right: 9.0625rem !important; }
  .pe-sm-30 {
    padding-right: 9.375rem !important; }
  .pe-sm-31 {
    padding-right: 9.6875rem !important; }
  .pe-sm-32 {
    padding-right: 10rem !important; }
  .pe-sm-33 {
    padding-right: 10.3125rem !important; }
  .pe-sm-34 {
    padding-right: 10.625rem !important; }
  .pe-sm-35 {
    padding-right: 10.9375rem !important; }
  .pe-sm-36 {
    padding-right: 11.25rem !important; }
  .pe-sm-37 {
    padding-right: 11.5625rem !important; }
  .pe-sm-38 {
    padding-right: 11.875rem !important; }
  .pe-sm-39 {
    padding-right: 12.1875rem !important; }
  .pe-sm-40 {
    padding-right: 12.5rem !important; }
  .pe-sm-base {
    padding-right: calc(20rem / 16) !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.3125rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.625rem !important; }
  .pb-sm-3 {
    padding-bottom: 0.9375rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.25rem !important; }
  .pb-sm-5 {
    padding-bottom: 1.5625rem !important; }
  .pb-sm-6 {
    padding-bottom: 1.875rem !important; }
  .pb-sm-7 {
    padding-bottom: 2.1875rem !important; }
  .pb-sm-8 {
    padding-bottom: 2.5rem !important; }
  .pb-sm-9 {
    padding-bottom: 2.8125rem !important; }
  .pb-sm-10 {
    padding-bottom: 3.125rem !important; }
  .pb-sm-11 {
    padding-bottom: 3.4375rem !important; }
  .pb-sm-12 {
    padding-bottom: 3.75rem !important; }
  .pb-sm-13 {
    padding-bottom: 4.0625rem !important; }
  .pb-sm-14 {
    padding-bottom: 4.375rem !important; }
  .pb-sm-15 {
    padding-bottom: 4.6875rem !important; }
  .pb-sm-16 {
    padding-bottom: 5rem !important; }
  .pb-sm-17 {
    padding-bottom: 5.3125rem !important; }
  .pb-sm-18 {
    padding-bottom: 5.625rem !important; }
  .pb-sm-19 {
    padding-bottom: 5.9375rem !important; }
  .pb-sm-20 {
    padding-bottom: 6.25rem !important; }
  .pb-sm-21 {
    padding-bottom: 6.5625rem !important; }
  .pb-sm-22 {
    padding-bottom: 6.875rem !important; }
  .pb-sm-23 {
    padding-bottom: 7.1875rem !important; }
  .pb-sm-24 {
    padding-bottom: 7.5rem !important; }
  .pb-sm-25 {
    padding-bottom: 7.8125rem !important; }
  .pb-sm-26 {
    padding-bottom: 8.125rem !important; }
  .pb-sm-27 {
    padding-bottom: 8.4375rem !important; }
  .pb-sm-28 {
    padding-bottom: 8.75rem !important; }
  .pb-sm-29 {
    padding-bottom: 9.0625rem !important; }
  .pb-sm-30 {
    padding-bottom: 9.375rem !important; }
  .pb-sm-31 {
    padding-bottom: 9.6875rem !important; }
  .pb-sm-32 {
    padding-bottom: 10rem !important; }
  .pb-sm-33 {
    padding-bottom: 10.3125rem !important; }
  .pb-sm-34 {
    padding-bottom: 10.625rem !important; }
  .pb-sm-35 {
    padding-bottom: 10.9375rem !important; }
  .pb-sm-36 {
    padding-bottom: 11.25rem !important; }
  .pb-sm-37 {
    padding-bottom: 11.5625rem !important; }
  .pb-sm-38 {
    padding-bottom: 11.875rem !important; }
  .pb-sm-39 {
    padding-bottom: 12.1875rem !important; }
  .pb-sm-40 {
    padding-bottom: 12.5rem !important; }
  .pb-sm-base {
    padding-bottom: calc(20rem / 16) !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.3125rem !important; }
  .ps-sm-2 {
    padding-left: 0.625rem !important; }
  .ps-sm-3 {
    padding-left: 0.9375rem !important; }
  .ps-sm-4 {
    padding-left: 1.25rem !important; }
  .ps-sm-5 {
    padding-left: 1.5625rem !important; }
  .ps-sm-6 {
    padding-left: 1.875rem !important; }
  .ps-sm-7 {
    padding-left: 2.1875rem !important; }
  .ps-sm-8 {
    padding-left: 2.5rem !important; }
  .ps-sm-9 {
    padding-left: 2.8125rem !important; }
  .ps-sm-10 {
    padding-left: 3.125rem !important; }
  .ps-sm-11 {
    padding-left: 3.4375rem !important; }
  .ps-sm-12 {
    padding-left: 3.75rem !important; }
  .ps-sm-13 {
    padding-left: 4.0625rem !important; }
  .ps-sm-14 {
    padding-left: 4.375rem !important; }
  .ps-sm-15 {
    padding-left: 4.6875rem !important; }
  .ps-sm-16 {
    padding-left: 5rem !important; }
  .ps-sm-17 {
    padding-left: 5.3125rem !important; }
  .ps-sm-18 {
    padding-left: 5.625rem !important; }
  .ps-sm-19 {
    padding-left: 5.9375rem !important; }
  .ps-sm-20 {
    padding-left: 6.25rem !important; }
  .ps-sm-21 {
    padding-left: 6.5625rem !important; }
  .ps-sm-22 {
    padding-left: 6.875rem !important; }
  .ps-sm-23 {
    padding-left: 7.1875rem !important; }
  .ps-sm-24 {
    padding-left: 7.5rem !important; }
  .ps-sm-25 {
    padding-left: 7.8125rem !important; }
  .ps-sm-26 {
    padding-left: 8.125rem !important; }
  .ps-sm-27 {
    padding-left: 8.4375rem !important; }
  .ps-sm-28 {
    padding-left: 8.75rem !important; }
  .ps-sm-29 {
    padding-left: 9.0625rem !important; }
  .ps-sm-30 {
    padding-left: 9.375rem !important; }
  .ps-sm-31 {
    padding-left: 9.6875rem !important; }
  .ps-sm-32 {
    padding-left: 10rem !important; }
  .ps-sm-33 {
    padding-left: 10.3125rem !important; }
  .ps-sm-34 {
    padding-left: 10.625rem !important; }
  .ps-sm-35 {
    padding-left: 10.9375rem !important; }
  .ps-sm-36 {
    padding-left: 11.25rem !important; }
  .ps-sm-37 {
    padding-left: 11.5625rem !important; }
  .ps-sm-38 {
    padding-left: 11.875rem !important; }
  .ps-sm-39 {
    padding-left: 12.1875rem !important; }
  .ps-sm-40 {
    padding-left: 12.5rem !important; }
  .ps-sm-base {
    padding-left: calc(20rem / 16) !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.3125rem !important; }
  .gap-sm-2 {
    gap: 0.625rem !important; }
  .gap-sm-3 {
    gap: 0.9375rem !important; }
  .gap-sm-4 {
    gap: 1.25rem !important; }
  .gap-sm-5 {
    gap: 1.5625rem !important; }
  .gap-sm-6 {
    gap: 1.875rem !important; }
  .gap-sm-7 {
    gap: 2.1875rem !important; }
  .gap-sm-8 {
    gap: 2.5rem !important; }
  .gap-sm-9 {
    gap: 2.8125rem !important; }
  .gap-sm-10 {
    gap: 3.125rem !important; }
  .gap-sm-11 {
    gap: 3.4375rem !important; }
  .gap-sm-12 {
    gap: 3.75rem !important; }
  .gap-sm-13 {
    gap: 4.0625rem !important; }
  .gap-sm-14 {
    gap: 4.375rem !important; }
  .gap-sm-15 {
    gap: 4.6875rem !important; }
  .gap-sm-16 {
    gap: 5rem !important; }
  .gap-sm-17 {
    gap: 5.3125rem !important; }
  .gap-sm-18 {
    gap: 5.625rem !important; }
  .gap-sm-19 {
    gap: 5.9375rem !important; }
  .gap-sm-20 {
    gap: 6.25rem !important; }
  .gap-sm-21 {
    gap: 6.5625rem !important; }
  .gap-sm-22 {
    gap: 6.875rem !important; }
  .gap-sm-23 {
    gap: 7.1875rem !important; }
  .gap-sm-24 {
    gap: 7.5rem !important; }
  .gap-sm-25 {
    gap: 7.8125rem !important; }
  .gap-sm-26 {
    gap: 8.125rem !important; }
  .gap-sm-27 {
    gap: 8.4375rem !important; }
  .gap-sm-28 {
    gap: 8.75rem !important; }
  .gap-sm-29 {
    gap: 9.0625rem !important; }
  .gap-sm-30 {
    gap: 9.375rem !important; }
  .gap-sm-31 {
    gap: 9.6875rem !important; }
  .gap-sm-32 {
    gap: 10rem !important; }
  .gap-sm-33 {
    gap: 10.3125rem !important; }
  .gap-sm-34 {
    gap: 10.625rem !important; }
  .gap-sm-35 {
    gap: 10.9375rem !important; }
  .gap-sm-36 {
    gap: 11.25rem !important; }
  .gap-sm-37 {
    gap: 11.5625rem !important; }
  .gap-sm-38 {
    gap: 11.875rem !important; }
  .gap-sm-39 {
    gap: 12.1875rem !important; }
  .gap-sm-40 {
    gap: 12.5rem !important; }
  .gap-sm-base {
    gap: calc(20rem / 16) !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.3125rem !important; }
  .m-md-2 {
    margin: 0.625rem !important; }
  .m-md-3 {
    margin: 0.9375rem !important; }
  .m-md-4 {
    margin: 1.25rem !important; }
  .m-md-5 {
    margin: 1.5625rem !important; }
  .m-md-6 {
    margin: 1.875rem !important; }
  .m-md-7 {
    margin: 2.1875rem !important; }
  .m-md-8 {
    margin: 2.5rem !important; }
  .m-md-9 {
    margin: 2.8125rem !important; }
  .m-md-10 {
    margin: 3.125rem !important; }
  .m-md-11 {
    margin: 3.4375rem !important; }
  .m-md-12 {
    margin: 3.75rem !important; }
  .m-md-13 {
    margin: 4.0625rem !important; }
  .m-md-14 {
    margin: 4.375rem !important; }
  .m-md-15 {
    margin: 4.6875rem !important; }
  .m-md-16 {
    margin: 5rem !important; }
  .m-md-17 {
    margin: 5.3125rem !important; }
  .m-md-18 {
    margin: 5.625rem !important; }
  .m-md-19 {
    margin: 5.9375rem !important; }
  .m-md-20 {
    margin: 6.25rem !important; }
  .m-md-21 {
    margin: 6.5625rem !important; }
  .m-md-22 {
    margin: 6.875rem !important; }
  .m-md-23 {
    margin: 7.1875rem !important; }
  .m-md-24 {
    margin: 7.5rem !important; }
  .m-md-25 {
    margin: 7.8125rem !important; }
  .m-md-26 {
    margin: 8.125rem !important; }
  .m-md-27 {
    margin: 8.4375rem !important; }
  .m-md-28 {
    margin: 8.75rem !important; }
  .m-md-29 {
    margin: 9.0625rem !important; }
  .m-md-30 {
    margin: 9.375rem !important; }
  .m-md-31 {
    margin: 9.6875rem !important; }
  .m-md-32 {
    margin: 10rem !important; }
  .m-md-33 {
    margin: 10.3125rem !important; }
  .m-md-34 {
    margin: 10.625rem !important; }
  .m-md-35 {
    margin: 10.9375rem !important; }
  .m-md-36 {
    margin: 11.25rem !important; }
  .m-md-37 {
    margin: 11.5625rem !important; }
  .m-md-38 {
    margin: 11.875rem !important; }
  .m-md-39 {
    margin: 12.1875rem !important; }
  .m-md-40 {
    margin: 12.5rem !important; }
  .m-md-base {
    margin: calc(20rem / 16) !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important; }
  .mx-md-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important; }
  .mx-md-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important; }
  .mx-md-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important; }
  .mx-md-5 {
    margin-right: 1.5625rem !important;
    margin-left: 1.5625rem !important; }
  .mx-md-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important; }
  .mx-md-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important; }
  .mx-md-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-md-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important; }
  .mx-md-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important; }
  .mx-md-11 {
    margin-right: 3.4375rem !important;
    margin-left: 3.4375rem !important; }
  .mx-md-12 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important; }
  .mx-md-13 {
    margin-right: 4.0625rem !important;
    margin-left: 4.0625rem !important; }
  .mx-md-14 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important; }
  .mx-md-15 {
    margin-right: 4.6875rem !important;
    margin-left: 4.6875rem !important; }
  .mx-md-16 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-md-17 {
    margin-right: 5.3125rem !important;
    margin-left: 5.3125rem !important; }
  .mx-md-18 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important; }
  .mx-md-19 {
    margin-right: 5.9375rem !important;
    margin-left: 5.9375rem !important; }
  .mx-md-20 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important; }
  .mx-md-21 {
    margin-right: 6.5625rem !important;
    margin-left: 6.5625rem !important; }
  .mx-md-22 {
    margin-right: 6.875rem !important;
    margin-left: 6.875rem !important; }
  .mx-md-23 {
    margin-right: 7.1875rem !important;
    margin-left: 7.1875rem !important; }
  .mx-md-24 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-md-25 {
    margin-right: 7.8125rem !important;
    margin-left: 7.8125rem !important; }
  .mx-md-26 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important; }
  .mx-md-27 {
    margin-right: 8.4375rem !important;
    margin-left: 8.4375rem !important; }
  .mx-md-28 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important; }
  .mx-md-29 {
    margin-right: 9.0625rem !important;
    margin-left: 9.0625rem !important; }
  .mx-md-30 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important; }
  .mx-md-31 {
    margin-right: 9.6875rem !important;
    margin-left: 9.6875rem !important; }
  .mx-md-32 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-md-33 {
    margin-right: 10.3125rem !important;
    margin-left: 10.3125rem !important; }
  .mx-md-34 {
    margin-right: 10.625rem !important;
    margin-left: 10.625rem !important; }
  .mx-md-35 {
    margin-right: 10.9375rem !important;
    margin-left: 10.9375rem !important; }
  .mx-md-36 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important; }
  .mx-md-37 {
    margin-right: 11.5625rem !important;
    margin-left: 11.5625rem !important; }
  .mx-md-38 {
    margin-right: 11.875rem !important;
    margin-left: 11.875rem !important; }
  .mx-md-39 {
    margin-right: 12.1875rem !important;
    margin-left: 12.1875rem !important; }
  .mx-md-40 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-md-base {
    margin-right: calc(20rem / 16) !important;
    margin-left: calc(20rem / 16) !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important; }
  .my-md-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important; }
  .my-md-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important; }
  .my-md-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .my-md-5 {
    margin-top: 1.5625rem !important;
    margin-bottom: 1.5625rem !important; }
  .my-md-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important; }
  .my-md-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important; }
  .my-md-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-md-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important; }
  .my-md-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important; }
  .my-md-11 {
    margin-top: 3.4375rem !important;
    margin-bottom: 3.4375rem !important; }
  .my-md-12 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important; }
  .my-md-13 {
    margin-top: 4.0625rem !important;
    margin-bottom: 4.0625rem !important; }
  .my-md-14 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important; }
  .my-md-15 {
    margin-top: 4.6875rem !important;
    margin-bottom: 4.6875rem !important; }
  .my-md-16 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-md-17 {
    margin-top: 5.3125rem !important;
    margin-bottom: 5.3125rem !important; }
  .my-md-18 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important; }
  .my-md-19 {
    margin-top: 5.9375rem !important;
    margin-bottom: 5.9375rem !important; }
  .my-md-20 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important; }
  .my-md-21 {
    margin-top: 6.5625rem !important;
    margin-bottom: 6.5625rem !important; }
  .my-md-22 {
    margin-top: 6.875rem !important;
    margin-bottom: 6.875rem !important; }
  .my-md-23 {
    margin-top: 7.1875rem !important;
    margin-bottom: 7.1875rem !important; }
  .my-md-24 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-md-25 {
    margin-top: 7.8125rem !important;
    margin-bottom: 7.8125rem !important; }
  .my-md-26 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important; }
  .my-md-27 {
    margin-top: 8.4375rem !important;
    margin-bottom: 8.4375rem !important; }
  .my-md-28 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important; }
  .my-md-29 {
    margin-top: 9.0625rem !important;
    margin-bottom: 9.0625rem !important; }
  .my-md-30 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important; }
  .my-md-31 {
    margin-top: 9.6875rem !important;
    margin-bottom: 9.6875rem !important; }
  .my-md-32 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-md-33 {
    margin-top: 10.3125rem !important;
    margin-bottom: 10.3125rem !important; }
  .my-md-34 {
    margin-top: 10.625rem !important;
    margin-bottom: 10.625rem !important; }
  .my-md-35 {
    margin-top: 10.9375rem !important;
    margin-bottom: 10.9375rem !important; }
  .my-md-36 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important; }
  .my-md-37 {
    margin-top: 11.5625rem !important;
    margin-bottom: 11.5625rem !important; }
  .my-md-38 {
    margin-top: 11.875rem !important;
    margin-bottom: 11.875rem !important; }
  .my-md-39 {
    margin-top: 12.1875rem !important;
    margin-bottom: 12.1875rem !important; }
  .my-md-40 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-md-base {
    margin-top: calc(20rem / 16) !important;
    margin-bottom: calc(20rem / 16) !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.3125rem !important; }
  .mt-md-2 {
    margin-top: 0.625rem !important; }
  .mt-md-3 {
    margin-top: 0.9375rem !important; }
  .mt-md-4 {
    margin-top: 1.25rem !important; }
  .mt-md-5 {
    margin-top: 1.5625rem !important; }
  .mt-md-6 {
    margin-top: 1.875rem !important; }
  .mt-md-7 {
    margin-top: 2.1875rem !important; }
  .mt-md-8 {
    margin-top: 2.5rem !important; }
  .mt-md-9 {
    margin-top: 2.8125rem !important; }
  .mt-md-10 {
    margin-top: 3.125rem !important; }
  .mt-md-11 {
    margin-top: 3.4375rem !important; }
  .mt-md-12 {
    margin-top: 3.75rem !important; }
  .mt-md-13 {
    margin-top: 4.0625rem !important; }
  .mt-md-14 {
    margin-top: 4.375rem !important; }
  .mt-md-15 {
    margin-top: 4.6875rem !important; }
  .mt-md-16 {
    margin-top: 5rem !important; }
  .mt-md-17 {
    margin-top: 5.3125rem !important; }
  .mt-md-18 {
    margin-top: 5.625rem !important; }
  .mt-md-19 {
    margin-top: 5.9375rem !important; }
  .mt-md-20 {
    margin-top: 6.25rem !important; }
  .mt-md-21 {
    margin-top: 6.5625rem !important; }
  .mt-md-22 {
    margin-top: 6.875rem !important; }
  .mt-md-23 {
    margin-top: 7.1875rem !important; }
  .mt-md-24 {
    margin-top: 7.5rem !important; }
  .mt-md-25 {
    margin-top: 7.8125rem !important; }
  .mt-md-26 {
    margin-top: 8.125rem !important; }
  .mt-md-27 {
    margin-top: 8.4375rem !important; }
  .mt-md-28 {
    margin-top: 8.75rem !important; }
  .mt-md-29 {
    margin-top: 9.0625rem !important; }
  .mt-md-30 {
    margin-top: 9.375rem !important; }
  .mt-md-31 {
    margin-top: 9.6875rem !important; }
  .mt-md-32 {
    margin-top: 10rem !important; }
  .mt-md-33 {
    margin-top: 10.3125rem !important; }
  .mt-md-34 {
    margin-top: 10.625rem !important; }
  .mt-md-35 {
    margin-top: 10.9375rem !important; }
  .mt-md-36 {
    margin-top: 11.25rem !important; }
  .mt-md-37 {
    margin-top: 11.5625rem !important; }
  .mt-md-38 {
    margin-top: 11.875rem !important; }
  .mt-md-39 {
    margin-top: 12.1875rem !important; }
  .mt-md-40 {
    margin-top: 12.5rem !important; }
  .mt-md-base {
    margin-top: calc(20rem / 16) !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.3125rem !important; }
  .me-md-2 {
    margin-right: 0.625rem !important; }
  .me-md-3 {
    margin-right: 0.9375rem !important; }
  .me-md-4 {
    margin-right: 1.25rem !important; }
  .me-md-5 {
    margin-right: 1.5625rem !important; }
  .me-md-6 {
    margin-right: 1.875rem !important; }
  .me-md-7 {
    margin-right: 2.1875rem !important; }
  .me-md-8 {
    margin-right: 2.5rem !important; }
  .me-md-9 {
    margin-right: 2.8125rem !important; }
  .me-md-10 {
    margin-right: 3.125rem !important; }
  .me-md-11 {
    margin-right: 3.4375rem !important; }
  .me-md-12 {
    margin-right: 3.75rem !important; }
  .me-md-13 {
    margin-right: 4.0625rem !important; }
  .me-md-14 {
    margin-right: 4.375rem !important; }
  .me-md-15 {
    margin-right: 4.6875rem !important; }
  .me-md-16 {
    margin-right: 5rem !important; }
  .me-md-17 {
    margin-right: 5.3125rem !important; }
  .me-md-18 {
    margin-right: 5.625rem !important; }
  .me-md-19 {
    margin-right: 5.9375rem !important; }
  .me-md-20 {
    margin-right: 6.25rem !important; }
  .me-md-21 {
    margin-right: 6.5625rem !important; }
  .me-md-22 {
    margin-right: 6.875rem !important; }
  .me-md-23 {
    margin-right: 7.1875rem !important; }
  .me-md-24 {
    margin-right: 7.5rem !important; }
  .me-md-25 {
    margin-right: 7.8125rem !important; }
  .me-md-26 {
    margin-right: 8.125rem !important; }
  .me-md-27 {
    margin-right: 8.4375rem !important; }
  .me-md-28 {
    margin-right: 8.75rem !important; }
  .me-md-29 {
    margin-right: 9.0625rem !important; }
  .me-md-30 {
    margin-right: 9.375rem !important; }
  .me-md-31 {
    margin-right: 9.6875rem !important; }
  .me-md-32 {
    margin-right: 10rem !important; }
  .me-md-33 {
    margin-right: 10.3125rem !important; }
  .me-md-34 {
    margin-right: 10.625rem !important; }
  .me-md-35 {
    margin-right: 10.9375rem !important; }
  .me-md-36 {
    margin-right: 11.25rem !important; }
  .me-md-37 {
    margin-right: 11.5625rem !important; }
  .me-md-38 {
    margin-right: 11.875rem !important; }
  .me-md-39 {
    margin-right: 12.1875rem !important; }
  .me-md-40 {
    margin-right: 12.5rem !important; }
  .me-md-base {
    margin-right: calc(20rem / 16) !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.3125rem !important; }
  .mb-md-2 {
    margin-bottom: 0.625rem !important; }
  .mb-md-3 {
    margin-bottom: 0.9375rem !important; }
  .mb-md-4 {
    margin-bottom: 1.25rem !important; }
  .mb-md-5 {
    margin-bottom: 1.5625rem !important; }
  .mb-md-6 {
    margin-bottom: 1.875rem !important; }
  .mb-md-7 {
    margin-bottom: 2.1875rem !important; }
  .mb-md-8 {
    margin-bottom: 2.5rem !important; }
  .mb-md-9 {
    margin-bottom: 2.8125rem !important; }
  .mb-md-10 {
    margin-bottom: 3.125rem !important; }
  .mb-md-11 {
    margin-bottom: 3.4375rem !important; }
  .mb-md-12 {
    margin-bottom: 3.75rem !important; }
  .mb-md-13 {
    margin-bottom: 4.0625rem !important; }
  .mb-md-14 {
    margin-bottom: 4.375rem !important; }
  .mb-md-15 {
    margin-bottom: 4.6875rem !important; }
  .mb-md-16 {
    margin-bottom: 5rem !important; }
  .mb-md-17 {
    margin-bottom: 5.3125rem !important; }
  .mb-md-18 {
    margin-bottom: 5.625rem !important; }
  .mb-md-19 {
    margin-bottom: 5.9375rem !important; }
  .mb-md-20 {
    margin-bottom: 6.25rem !important; }
  .mb-md-21 {
    margin-bottom: 6.5625rem !important; }
  .mb-md-22 {
    margin-bottom: 6.875rem !important; }
  .mb-md-23 {
    margin-bottom: 7.1875rem !important; }
  .mb-md-24 {
    margin-bottom: 7.5rem !important; }
  .mb-md-25 {
    margin-bottom: 7.8125rem !important; }
  .mb-md-26 {
    margin-bottom: 8.125rem !important; }
  .mb-md-27 {
    margin-bottom: 8.4375rem !important; }
  .mb-md-28 {
    margin-bottom: 8.75rem !important; }
  .mb-md-29 {
    margin-bottom: 9.0625rem !important; }
  .mb-md-30 {
    margin-bottom: 9.375rem !important; }
  .mb-md-31 {
    margin-bottom: 9.6875rem !important; }
  .mb-md-32 {
    margin-bottom: 10rem !important; }
  .mb-md-33 {
    margin-bottom: 10.3125rem !important; }
  .mb-md-34 {
    margin-bottom: 10.625rem !important; }
  .mb-md-35 {
    margin-bottom: 10.9375rem !important; }
  .mb-md-36 {
    margin-bottom: 11.25rem !important; }
  .mb-md-37 {
    margin-bottom: 11.5625rem !important; }
  .mb-md-38 {
    margin-bottom: 11.875rem !important; }
  .mb-md-39 {
    margin-bottom: 12.1875rem !important; }
  .mb-md-40 {
    margin-bottom: 12.5rem !important; }
  .mb-md-base {
    margin-bottom: calc(20rem / 16) !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.3125rem !important; }
  .ms-md-2 {
    margin-left: 0.625rem !important; }
  .ms-md-3 {
    margin-left: 0.9375rem !important; }
  .ms-md-4 {
    margin-left: 1.25rem !important; }
  .ms-md-5 {
    margin-left: 1.5625rem !important; }
  .ms-md-6 {
    margin-left: 1.875rem !important; }
  .ms-md-7 {
    margin-left: 2.1875rem !important; }
  .ms-md-8 {
    margin-left: 2.5rem !important; }
  .ms-md-9 {
    margin-left: 2.8125rem !important; }
  .ms-md-10 {
    margin-left: 3.125rem !important; }
  .ms-md-11 {
    margin-left: 3.4375rem !important; }
  .ms-md-12 {
    margin-left: 3.75rem !important; }
  .ms-md-13 {
    margin-left: 4.0625rem !important; }
  .ms-md-14 {
    margin-left: 4.375rem !important; }
  .ms-md-15 {
    margin-left: 4.6875rem !important; }
  .ms-md-16 {
    margin-left: 5rem !important; }
  .ms-md-17 {
    margin-left: 5.3125rem !important; }
  .ms-md-18 {
    margin-left: 5.625rem !important; }
  .ms-md-19 {
    margin-left: 5.9375rem !important; }
  .ms-md-20 {
    margin-left: 6.25rem !important; }
  .ms-md-21 {
    margin-left: 6.5625rem !important; }
  .ms-md-22 {
    margin-left: 6.875rem !important; }
  .ms-md-23 {
    margin-left: 7.1875rem !important; }
  .ms-md-24 {
    margin-left: 7.5rem !important; }
  .ms-md-25 {
    margin-left: 7.8125rem !important; }
  .ms-md-26 {
    margin-left: 8.125rem !important; }
  .ms-md-27 {
    margin-left: 8.4375rem !important; }
  .ms-md-28 {
    margin-left: 8.75rem !important; }
  .ms-md-29 {
    margin-left: 9.0625rem !important; }
  .ms-md-30 {
    margin-left: 9.375rem !important; }
  .ms-md-31 {
    margin-left: 9.6875rem !important; }
  .ms-md-32 {
    margin-left: 10rem !important; }
  .ms-md-33 {
    margin-left: 10.3125rem !important; }
  .ms-md-34 {
    margin-left: 10.625rem !important; }
  .ms-md-35 {
    margin-left: 10.9375rem !important; }
  .ms-md-36 {
    margin-left: 11.25rem !important; }
  .ms-md-37 {
    margin-left: 11.5625rem !important; }
  .ms-md-38 {
    margin-left: 11.875rem !important; }
  .ms-md-39 {
    margin-left: 12.1875rem !important; }
  .ms-md-40 {
    margin-left: 12.5rem !important; }
  .ms-md-base {
    margin-left: calc(20rem / 16) !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .m-md-n1 {
    margin: -0.3125rem !important; }
  .m-md-n2 {
    margin: -0.625rem !important; }
  .m-md-n3 {
    margin: -0.9375rem !important; }
  .m-md-n4 {
    margin: -1.25rem !important; }
  .m-md-n5 {
    margin: -1.5625rem !important; }
  .m-md-n6 {
    margin: -1.875rem !important; }
  .m-md-n7 {
    margin: -2.1875rem !important; }
  .m-md-n8 {
    margin: -2.5rem !important; }
  .m-md-n9 {
    margin: -2.8125rem !important; }
  .m-md-n10 {
    margin: -3.125rem !important; }
  .m-md-n11 {
    margin: -3.4375rem !important; }
  .m-md-n12 {
    margin: -3.75rem !important; }
  .m-md-n13 {
    margin: -4.0625rem !important; }
  .m-md-n14 {
    margin: -4.375rem !important; }
  .m-md-n15 {
    margin: -4.6875rem !important; }
  .m-md-n16 {
    margin: -5rem !important; }
  .m-md-n17 {
    margin: -5.3125rem !important; }
  .m-md-n18 {
    margin: -5.625rem !important; }
  .m-md-n19 {
    margin: -5.9375rem !important; }
  .m-md-n20 {
    margin: -6.25rem !important; }
  .m-md-n21 {
    margin: -6.5625rem !important; }
  .m-md-n22 {
    margin: -6.875rem !important; }
  .m-md-n23 {
    margin: -7.1875rem !important; }
  .m-md-n24 {
    margin: -7.5rem !important; }
  .m-md-n25 {
    margin: -7.8125rem !important; }
  .m-md-n26 {
    margin: -8.125rem !important; }
  .m-md-n27 {
    margin: -8.4375rem !important; }
  .m-md-n28 {
    margin: -8.75rem !important; }
  .m-md-n29 {
    margin: -9.0625rem !important; }
  .m-md-n30 {
    margin: -9.375rem !important; }
  .m-md-n31 {
    margin: -9.6875rem !important; }
  .m-md-n32 {
    margin: -10rem !important; }
  .m-md-n33 {
    margin: -10.3125rem !important; }
  .m-md-n34 {
    margin: -10.625rem !important; }
  .m-md-n35 {
    margin: -10.9375rem !important; }
  .m-md-n36 {
    margin: -11.25rem !important; }
  .m-md-n37 {
    margin: -11.5625rem !important; }
  .m-md-n38 {
    margin: -11.875rem !important; }
  .m-md-n39 {
    margin: -12.1875rem !important; }
  .m-md-n40 {
    margin: -12.5rem !important; }
  .m-md-nbase {
    margin: -calc(20rem / 16) !important; }
  .mx-md-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important; }
  .mx-md-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important; }
  .mx-md-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important; }
  .mx-md-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important; }
  .mx-md-n5 {
    margin-right: -1.5625rem !important;
    margin-left: -1.5625rem !important; }
  .mx-md-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important; }
  .mx-md-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important; }
  .mx-md-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-md-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important; }
  .mx-md-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important; }
  .mx-md-n11 {
    margin-right: -3.4375rem !important;
    margin-left: -3.4375rem !important; }
  .mx-md-n12 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important; }
  .mx-md-n13 {
    margin-right: -4.0625rem !important;
    margin-left: -4.0625rem !important; }
  .mx-md-n14 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important; }
  .mx-md-n15 {
    margin-right: -4.6875rem !important;
    margin-left: -4.6875rem !important; }
  .mx-md-n16 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-md-n17 {
    margin-right: -5.3125rem !important;
    margin-left: -5.3125rem !important; }
  .mx-md-n18 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important; }
  .mx-md-n19 {
    margin-right: -5.9375rem !important;
    margin-left: -5.9375rem !important; }
  .mx-md-n20 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important; }
  .mx-md-n21 {
    margin-right: -6.5625rem !important;
    margin-left: -6.5625rem !important; }
  .mx-md-n22 {
    margin-right: -6.875rem !important;
    margin-left: -6.875rem !important; }
  .mx-md-n23 {
    margin-right: -7.1875rem !important;
    margin-left: -7.1875rem !important; }
  .mx-md-n24 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-md-n25 {
    margin-right: -7.8125rem !important;
    margin-left: -7.8125rem !important; }
  .mx-md-n26 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important; }
  .mx-md-n27 {
    margin-right: -8.4375rem !important;
    margin-left: -8.4375rem !important; }
  .mx-md-n28 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important; }
  .mx-md-n29 {
    margin-right: -9.0625rem !important;
    margin-left: -9.0625rem !important; }
  .mx-md-n30 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important; }
  .mx-md-n31 {
    margin-right: -9.6875rem !important;
    margin-left: -9.6875rem !important; }
  .mx-md-n32 {
    margin-right: -10rem !important;
    margin-left: -10rem !important; }
  .mx-md-n33 {
    margin-right: -10.3125rem !important;
    margin-left: -10.3125rem !important; }
  .mx-md-n34 {
    margin-right: -10.625rem !important;
    margin-left: -10.625rem !important; }
  .mx-md-n35 {
    margin-right: -10.9375rem !important;
    margin-left: -10.9375rem !important; }
  .mx-md-n36 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important; }
  .mx-md-n37 {
    margin-right: -11.5625rem !important;
    margin-left: -11.5625rem !important; }
  .mx-md-n38 {
    margin-right: -11.875rem !important;
    margin-left: -11.875rem !important; }
  .mx-md-n39 {
    margin-right: -12.1875rem !important;
    margin-left: -12.1875rem !important; }
  .mx-md-n40 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important; }
  .mx-md-nbase {
    margin-right: -calc(20rem / 16) !important;
    margin-left: -calc(20rem / 16) !important; }
  .my-md-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important; }
  .my-md-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important; }
  .my-md-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important; }
  .my-md-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .my-md-n5 {
    margin-top: -1.5625rem !important;
    margin-bottom: -1.5625rem !important; }
  .my-md-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important; }
  .my-md-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important; }
  .my-md-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-md-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important; }
  .my-md-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important; }
  .my-md-n11 {
    margin-top: -3.4375rem !important;
    margin-bottom: -3.4375rem !important; }
  .my-md-n12 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important; }
  .my-md-n13 {
    margin-top: -4.0625rem !important;
    margin-bottom: -4.0625rem !important; }
  .my-md-n14 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important; }
  .my-md-n15 {
    margin-top: -4.6875rem !important;
    margin-bottom: -4.6875rem !important; }
  .my-md-n16 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-md-n17 {
    margin-top: -5.3125rem !important;
    margin-bottom: -5.3125rem !important; }
  .my-md-n18 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important; }
  .my-md-n19 {
    margin-top: -5.9375rem !important;
    margin-bottom: -5.9375rem !important; }
  .my-md-n20 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important; }
  .my-md-n21 {
    margin-top: -6.5625rem !important;
    margin-bottom: -6.5625rem !important; }
  .my-md-n22 {
    margin-top: -6.875rem !important;
    margin-bottom: -6.875rem !important; }
  .my-md-n23 {
    margin-top: -7.1875rem !important;
    margin-bottom: -7.1875rem !important; }
  .my-md-n24 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-md-n25 {
    margin-top: -7.8125rem !important;
    margin-bottom: -7.8125rem !important; }
  .my-md-n26 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important; }
  .my-md-n27 {
    margin-top: -8.4375rem !important;
    margin-bottom: -8.4375rem !important; }
  .my-md-n28 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important; }
  .my-md-n29 {
    margin-top: -9.0625rem !important;
    margin-bottom: -9.0625rem !important; }
  .my-md-n30 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important; }
  .my-md-n31 {
    margin-top: -9.6875rem !important;
    margin-bottom: -9.6875rem !important; }
  .my-md-n32 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .my-md-n33 {
    margin-top: -10.3125rem !important;
    margin-bottom: -10.3125rem !important; }
  .my-md-n34 {
    margin-top: -10.625rem !important;
    margin-bottom: -10.625rem !important; }
  .my-md-n35 {
    margin-top: -10.9375rem !important;
    margin-bottom: -10.9375rem !important; }
  .my-md-n36 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important; }
  .my-md-n37 {
    margin-top: -11.5625rem !important;
    margin-bottom: -11.5625rem !important; }
  .my-md-n38 {
    margin-top: -11.875rem !important;
    margin-bottom: -11.875rem !important; }
  .my-md-n39 {
    margin-top: -12.1875rem !important;
    margin-bottom: -12.1875rem !important; }
  .my-md-n40 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important; }
  .my-md-nbase {
    margin-top: -calc(20rem / 16) !important;
    margin-bottom: -calc(20rem / 16) !important; }
  .mt-md-n1 {
    margin-top: -0.3125rem !important; }
  .mt-md-n2 {
    margin-top: -0.625rem !important; }
  .mt-md-n3 {
    margin-top: -0.9375rem !important; }
  .mt-md-n4 {
    margin-top: -1.25rem !important; }
  .mt-md-n5 {
    margin-top: -1.5625rem !important; }
  .mt-md-n6 {
    margin-top: -1.875rem !important; }
  .mt-md-n7 {
    margin-top: -2.1875rem !important; }
  .mt-md-n8 {
    margin-top: -2.5rem !important; }
  .mt-md-n9 {
    margin-top: -2.8125rem !important; }
  .mt-md-n10 {
    margin-top: -3.125rem !important; }
  .mt-md-n11 {
    margin-top: -3.4375rem !important; }
  .mt-md-n12 {
    margin-top: -3.75rem !important; }
  .mt-md-n13 {
    margin-top: -4.0625rem !important; }
  .mt-md-n14 {
    margin-top: -4.375rem !important; }
  .mt-md-n15 {
    margin-top: -4.6875rem !important; }
  .mt-md-n16 {
    margin-top: -5rem !important; }
  .mt-md-n17 {
    margin-top: -5.3125rem !important; }
  .mt-md-n18 {
    margin-top: -5.625rem !important; }
  .mt-md-n19 {
    margin-top: -5.9375rem !important; }
  .mt-md-n20 {
    margin-top: -6.25rem !important; }
  .mt-md-n21 {
    margin-top: -6.5625rem !important; }
  .mt-md-n22 {
    margin-top: -6.875rem !important; }
  .mt-md-n23 {
    margin-top: -7.1875rem !important; }
  .mt-md-n24 {
    margin-top: -7.5rem !important; }
  .mt-md-n25 {
    margin-top: -7.8125rem !important; }
  .mt-md-n26 {
    margin-top: -8.125rem !important; }
  .mt-md-n27 {
    margin-top: -8.4375rem !important; }
  .mt-md-n28 {
    margin-top: -8.75rem !important; }
  .mt-md-n29 {
    margin-top: -9.0625rem !important; }
  .mt-md-n30 {
    margin-top: -9.375rem !important; }
  .mt-md-n31 {
    margin-top: -9.6875rem !important; }
  .mt-md-n32 {
    margin-top: -10rem !important; }
  .mt-md-n33 {
    margin-top: -10.3125rem !important; }
  .mt-md-n34 {
    margin-top: -10.625rem !important; }
  .mt-md-n35 {
    margin-top: -10.9375rem !important; }
  .mt-md-n36 {
    margin-top: -11.25rem !important; }
  .mt-md-n37 {
    margin-top: -11.5625rem !important; }
  .mt-md-n38 {
    margin-top: -11.875rem !important; }
  .mt-md-n39 {
    margin-top: -12.1875rem !important; }
  .mt-md-n40 {
    margin-top: -12.5rem !important; }
  .mt-md-nbase {
    margin-top: -calc(20rem / 16) !important; }
  .me-md-n1 {
    margin-right: -0.3125rem !important; }
  .me-md-n2 {
    margin-right: -0.625rem !important; }
  .me-md-n3 {
    margin-right: -0.9375rem !important; }
  .me-md-n4 {
    margin-right: -1.25rem !important; }
  .me-md-n5 {
    margin-right: -1.5625rem !important; }
  .me-md-n6 {
    margin-right: -1.875rem !important; }
  .me-md-n7 {
    margin-right: -2.1875rem !important; }
  .me-md-n8 {
    margin-right: -2.5rem !important; }
  .me-md-n9 {
    margin-right: -2.8125rem !important; }
  .me-md-n10 {
    margin-right: -3.125rem !important; }
  .me-md-n11 {
    margin-right: -3.4375rem !important; }
  .me-md-n12 {
    margin-right: -3.75rem !important; }
  .me-md-n13 {
    margin-right: -4.0625rem !important; }
  .me-md-n14 {
    margin-right: -4.375rem !important; }
  .me-md-n15 {
    margin-right: -4.6875rem !important; }
  .me-md-n16 {
    margin-right: -5rem !important; }
  .me-md-n17 {
    margin-right: -5.3125rem !important; }
  .me-md-n18 {
    margin-right: -5.625rem !important; }
  .me-md-n19 {
    margin-right: -5.9375rem !important; }
  .me-md-n20 {
    margin-right: -6.25rem !important; }
  .me-md-n21 {
    margin-right: -6.5625rem !important; }
  .me-md-n22 {
    margin-right: -6.875rem !important; }
  .me-md-n23 {
    margin-right: -7.1875rem !important; }
  .me-md-n24 {
    margin-right: -7.5rem !important; }
  .me-md-n25 {
    margin-right: -7.8125rem !important; }
  .me-md-n26 {
    margin-right: -8.125rem !important; }
  .me-md-n27 {
    margin-right: -8.4375rem !important; }
  .me-md-n28 {
    margin-right: -8.75rem !important; }
  .me-md-n29 {
    margin-right: -9.0625rem !important; }
  .me-md-n30 {
    margin-right: -9.375rem !important; }
  .me-md-n31 {
    margin-right: -9.6875rem !important; }
  .me-md-n32 {
    margin-right: -10rem !important; }
  .me-md-n33 {
    margin-right: -10.3125rem !important; }
  .me-md-n34 {
    margin-right: -10.625rem !important; }
  .me-md-n35 {
    margin-right: -10.9375rem !important; }
  .me-md-n36 {
    margin-right: -11.25rem !important; }
  .me-md-n37 {
    margin-right: -11.5625rem !important; }
  .me-md-n38 {
    margin-right: -11.875rem !important; }
  .me-md-n39 {
    margin-right: -12.1875rem !important; }
  .me-md-n40 {
    margin-right: -12.5rem !important; }
  .me-md-nbase {
    margin-right: -calc(20rem / 16) !important; }
  .mb-md-n1 {
    margin-bottom: -0.3125rem !important; }
  .mb-md-n2 {
    margin-bottom: -0.625rem !important; }
  .mb-md-n3 {
    margin-bottom: -0.9375rem !important; }
  .mb-md-n4 {
    margin-bottom: -1.25rem !important; }
  .mb-md-n5 {
    margin-bottom: -1.5625rem !important; }
  .mb-md-n6 {
    margin-bottom: -1.875rem !important; }
  .mb-md-n7 {
    margin-bottom: -2.1875rem !important; }
  .mb-md-n8 {
    margin-bottom: -2.5rem !important; }
  .mb-md-n9 {
    margin-bottom: -2.8125rem !important; }
  .mb-md-n10 {
    margin-bottom: -3.125rem !important; }
  .mb-md-n11 {
    margin-bottom: -3.4375rem !important; }
  .mb-md-n12 {
    margin-bottom: -3.75rem !important; }
  .mb-md-n13 {
    margin-bottom: -4.0625rem !important; }
  .mb-md-n14 {
    margin-bottom: -4.375rem !important; }
  .mb-md-n15 {
    margin-bottom: -4.6875rem !important; }
  .mb-md-n16 {
    margin-bottom: -5rem !important; }
  .mb-md-n17 {
    margin-bottom: -5.3125rem !important; }
  .mb-md-n18 {
    margin-bottom: -5.625rem !important; }
  .mb-md-n19 {
    margin-bottom: -5.9375rem !important; }
  .mb-md-n20 {
    margin-bottom: -6.25rem !important; }
  .mb-md-n21 {
    margin-bottom: -6.5625rem !important; }
  .mb-md-n22 {
    margin-bottom: -6.875rem !important; }
  .mb-md-n23 {
    margin-bottom: -7.1875rem !important; }
  .mb-md-n24 {
    margin-bottom: -7.5rem !important; }
  .mb-md-n25 {
    margin-bottom: -7.8125rem !important; }
  .mb-md-n26 {
    margin-bottom: -8.125rem !important; }
  .mb-md-n27 {
    margin-bottom: -8.4375rem !important; }
  .mb-md-n28 {
    margin-bottom: -8.75rem !important; }
  .mb-md-n29 {
    margin-bottom: -9.0625rem !important; }
  .mb-md-n30 {
    margin-bottom: -9.375rem !important; }
  .mb-md-n31 {
    margin-bottom: -9.6875rem !important; }
  .mb-md-n32 {
    margin-bottom: -10rem !important; }
  .mb-md-n33 {
    margin-bottom: -10.3125rem !important; }
  .mb-md-n34 {
    margin-bottom: -10.625rem !important; }
  .mb-md-n35 {
    margin-bottom: -10.9375rem !important; }
  .mb-md-n36 {
    margin-bottom: -11.25rem !important; }
  .mb-md-n37 {
    margin-bottom: -11.5625rem !important; }
  .mb-md-n38 {
    margin-bottom: -11.875rem !important; }
  .mb-md-n39 {
    margin-bottom: -12.1875rem !important; }
  .mb-md-n40 {
    margin-bottom: -12.5rem !important; }
  .mb-md-nbase {
    margin-bottom: -calc(20rem / 16) !important; }
  .ms-md-n1 {
    margin-left: -0.3125rem !important; }
  .ms-md-n2 {
    margin-left: -0.625rem !important; }
  .ms-md-n3 {
    margin-left: -0.9375rem !important; }
  .ms-md-n4 {
    margin-left: -1.25rem !important; }
  .ms-md-n5 {
    margin-left: -1.5625rem !important; }
  .ms-md-n6 {
    margin-left: -1.875rem !important; }
  .ms-md-n7 {
    margin-left: -2.1875rem !important; }
  .ms-md-n8 {
    margin-left: -2.5rem !important; }
  .ms-md-n9 {
    margin-left: -2.8125rem !important; }
  .ms-md-n10 {
    margin-left: -3.125rem !important; }
  .ms-md-n11 {
    margin-left: -3.4375rem !important; }
  .ms-md-n12 {
    margin-left: -3.75rem !important; }
  .ms-md-n13 {
    margin-left: -4.0625rem !important; }
  .ms-md-n14 {
    margin-left: -4.375rem !important; }
  .ms-md-n15 {
    margin-left: -4.6875rem !important; }
  .ms-md-n16 {
    margin-left: -5rem !important; }
  .ms-md-n17 {
    margin-left: -5.3125rem !important; }
  .ms-md-n18 {
    margin-left: -5.625rem !important; }
  .ms-md-n19 {
    margin-left: -5.9375rem !important; }
  .ms-md-n20 {
    margin-left: -6.25rem !important; }
  .ms-md-n21 {
    margin-left: -6.5625rem !important; }
  .ms-md-n22 {
    margin-left: -6.875rem !important; }
  .ms-md-n23 {
    margin-left: -7.1875rem !important; }
  .ms-md-n24 {
    margin-left: -7.5rem !important; }
  .ms-md-n25 {
    margin-left: -7.8125rem !important; }
  .ms-md-n26 {
    margin-left: -8.125rem !important; }
  .ms-md-n27 {
    margin-left: -8.4375rem !important; }
  .ms-md-n28 {
    margin-left: -8.75rem !important; }
  .ms-md-n29 {
    margin-left: -9.0625rem !important; }
  .ms-md-n30 {
    margin-left: -9.375rem !important; }
  .ms-md-n31 {
    margin-left: -9.6875rem !important; }
  .ms-md-n32 {
    margin-left: -10rem !important; }
  .ms-md-n33 {
    margin-left: -10.3125rem !important; }
  .ms-md-n34 {
    margin-left: -10.625rem !important; }
  .ms-md-n35 {
    margin-left: -10.9375rem !important; }
  .ms-md-n36 {
    margin-left: -11.25rem !important; }
  .ms-md-n37 {
    margin-left: -11.5625rem !important; }
  .ms-md-n38 {
    margin-left: -11.875rem !important; }
  .ms-md-n39 {
    margin-left: -12.1875rem !important; }
  .ms-md-n40 {
    margin-left: -12.5rem !important; }
  .ms-md-nbase {
    margin-left: -calc(20rem / 16) !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.3125rem !important; }
  .p-md-2 {
    padding: 0.625rem !important; }
  .p-md-3 {
    padding: 0.9375rem !important; }
  .p-md-4 {
    padding: 1.25rem !important; }
  .p-md-5 {
    padding: 1.5625rem !important; }
  .p-md-6 {
    padding: 1.875rem !important; }
  .p-md-7 {
    padding: 2.1875rem !important; }
  .p-md-8 {
    padding: 2.5rem !important; }
  .p-md-9 {
    padding: 2.8125rem !important; }
  .p-md-10 {
    padding: 3.125rem !important; }
  .p-md-11 {
    padding: 3.4375rem !important; }
  .p-md-12 {
    padding: 3.75rem !important; }
  .p-md-13 {
    padding: 4.0625rem !important; }
  .p-md-14 {
    padding: 4.375rem !important; }
  .p-md-15 {
    padding: 4.6875rem !important; }
  .p-md-16 {
    padding: 5rem !important; }
  .p-md-17 {
    padding: 5.3125rem !important; }
  .p-md-18 {
    padding: 5.625rem !important; }
  .p-md-19 {
    padding: 5.9375rem !important; }
  .p-md-20 {
    padding: 6.25rem !important; }
  .p-md-21 {
    padding: 6.5625rem !important; }
  .p-md-22 {
    padding: 6.875rem !important; }
  .p-md-23 {
    padding: 7.1875rem !important; }
  .p-md-24 {
    padding: 7.5rem !important; }
  .p-md-25 {
    padding: 7.8125rem !important; }
  .p-md-26 {
    padding: 8.125rem !important; }
  .p-md-27 {
    padding: 8.4375rem !important; }
  .p-md-28 {
    padding: 8.75rem !important; }
  .p-md-29 {
    padding: 9.0625rem !important; }
  .p-md-30 {
    padding: 9.375rem !important; }
  .p-md-31 {
    padding: 9.6875rem !important; }
  .p-md-32 {
    padding: 10rem !important; }
  .p-md-33 {
    padding: 10.3125rem !important; }
  .p-md-34 {
    padding: 10.625rem !important; }
  .p-md-35 {
    padding: 10.9375rem !important; }
  .p-md-36 {
    padding: 11.25rem !important; }
  .p-md-37 {
    padding: 11.5625rem !important; }
  .p-md-38 {
    padding: 11.875rem !important; }
  .p-md-39 {
    padding: 12.1875rem !important; }
  .p-md-40 {
    padding: 12.5rem !important; }
  .p-md-base {
    padding: calc(20rem / 16) !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important; }
  .px-md-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important; }
  .px-md-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important; }
  .px-md-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important; }
  .px-md-5 {
    padding-right: 1.5625rem !important;
    padding-left: 1.5625rem !important; }
  .px-md-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important; }
  .px-md-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important; }
  .px-md-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-md-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important; }
  .px-md-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important; }
  .px-md-11 {
    padding-right: 3.4375rem !important;
    padding-left: 3.4375rem !important; }
  .px-md-12 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important; }
  .px-md-13 {
    padding-right: 4.0625rem !important;
    padding-left: 4.0625rem !important; }
  .px-md-14 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important; }
  .px-md-15 {
    padding-right: 4.6875rem !important;
    padding-left: 4.6875rem !important; }
  .px-md-16 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-md-17 {
    padding-right: 5.3125rem !important;
    padding-left: 5.3125rem !important; }
  .px-md-18 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important; }
  .px-md-19 {
    padding-right: 5.9375rem !important;
    padding-left: 5.9375rem !important; }
  .px-md-20 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important; }
  .px-md-21 {
    padding-right: 6.5625rem !important;
    padding-left: 6.5625rem !important; }
  .px-md-22 {
    padding-right: 6.875rem !important;
    padding-left: 6.875rem !important; }
  .px-md-23 {
    padding-right: 7.1875rem !important;
    padding-left: 7.1875rem !important; }
  .px-md-24 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-md-25 {
    padding-right: 7.8125rem !important;
    padding-left: 7.8125rem !important; }
  .px-md-26 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important; }
  .px-md-27 {
    padding-right: 8.4375rem !important;
    padding-left: 8.4375rem !important; }
  .px-md-28 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important; }
  .px-md-29 {
    padding-right: 9.0625rem !important;
    padding-left: 9.0625rem !important; }
  .px-md-30 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important; }
  .px-md-31 {
    padding-right: 9.6875rem !important;
    padding-left: 9.6875rem !important; }
  .px-md-32 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-md-33 {
    padding-right: 10.3125rem !important;
    padding-left: 10.3125rem !important; }
  .px-md-34 {
    padding-right: 10.625rem !important;
    padding-left: 10.625rem !important; }
  .px-md-35 {
    padding-right: 10.9375rem !important;
    padding-left: 10.9375rem !important; }
  .px-md-36 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important; }
  .px-md-37 {
    padding-right: 11.5625rem !important;
    padding-left: 11.5625rem !important; }
  .px-md-38 {
    padding-right: 11.875rem !important;
    padding-left: 11.875rem !important; }
  .px-md-39 {
    padding-right: 12.1875rem !important;
    padding-left: 12.1875rem !important; }
  .px-md-40 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-md-base {
    padding-right: calc(20rem / 16) !important;
    padding-left: calc(20rem / 16) !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important; }
  .py-md-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important; }
  .py-md-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important; }
  .py-md-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .py-md-5 {
    padding-top: 1.5625rem !important;
    padding-bottom: 1.5625rem !important; }
  .py-md-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important; }
  .py-md-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important; }
  .py-md-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-md-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important; }
  .py-md-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important; }
  .py-md-11 {
    padding-top: 3.4375rem !important;
    padding-bottom: 3.4375rem !important; }
  .py-md-12 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important; }
  .py-md-13 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important; }
  .py-md-14 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important; }
  .py-md-15 {
    padding-top: 4.6875rem !important;
    padding-bottom: 4.6875rem !important; }
  .py-md-16 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-md-17 {
    padding-top: 5.3125rem !important;
    padding-bottom: 5.3125rem !important; }
  .py-md-18 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important; }
  .py-md-19 {
    padding-top: 5.9375rem !important;
    padding-bottom: 5.9375rem !important; }
  .py-md-20 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important; }
  .py-md-21 {
    padding-top: 6.5625rem !important;
    padding-bottom: 6.5625rem !important; }
  .py-md-22 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important; }
  .py-md-23 {
    padding-top: 7.1875rem !important;
    padding-bottom: 7.1875rem !important; }
  .py-md-24 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-md-25 {
    padding-top: 7.8125rem !important;
    padding-bottom: 7.8125rem !important; }
  .py-md-26 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important; }
  .py-md-27 {
    padding-top: 8.4375rem !important;
    padding-bottom: 8.4375rem !important; }
  .py-md-28 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important; }
  .py-md-29 {
    padding-top: 9.0625rem !important;
    padding-bottom: 9.0625rem !important; }
  .py-md-30 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important; }
  .py-md-31 {
    padding-top: 9.6875rem !important;
    padding-bottom: 9.6875rem !important; }
  .py-md-32 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-md-33 {
    padding-top: 10.3125rem !important;
    padding-bottom: 10.3125rem !important; }
  .py-md-34 {
    padding-top: 10.625rem !important;
    padding-bottom: 10.625rem !important; }
  .py-md-35 {
    padding-top: 10.9375rem !important;
    padding-bottom: 10.9375rem !important; }
  .py-md-36 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important; }
  .py-md-37 {
    padding-top: 11.5625rem !important;
    padding-bottom: 11.5625rem !important; }
  .py-md-38 {
    padding-top: 11.875rem !important;
    padding-bottom: 11.875rem !important; }
  .py-md-39 {
    padding-top: 12.1875rem !important;
    padding-bottom: 12.1875rem !important; }
  .py-md-40 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-md-base {
    padding-top: calc(20rem / 16) !important;
    padding-bottom: calc(20rem / 16) !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.3125rem !important; }
  .pt-md-2 {
    padding-top: 0.625rem !important; }
  .pt-md-3 {
    padding-top: 0.9375rem !important; }
  .pt-md-4 {
    padding-top: 1.25rem !important; }
  .pt-md-5 {
    padding-top: 1.5625rem !important; }
  .pt-md-6 {
    padding-top: 1.875rem !important; }
  .pt-md-7 {
    padding-top: 2.1875rem !important; }
  .pt-md-8 {
    padding-top: 2.5rem !important; }
  .pt-md-9 {
    padding-top: 2.8125rem !important; }
  .pt-md-10 {
    padding-top: 3.125rem !important; }
  .pt-md-11 {
    padding-top: 3.4375rem !important; }
  .pt-md-12 {
    padding-top: 3.75rem !important; }
  .pt-md-13 {
    padding-top: 4.0625rem !important; }
  .pt-md-14 {
    padding-top: 4.375rem !important; }
  .pt-md-15 {
    padding-top: 4.6875rem !important; }
  .pt-md-16 {
    padding-top: 5rem !important; }
  .pt-md-17 {
    padding-top: 5.3125rem !important; }
  .pt-md-18 {
    padding-top: 5.625rem !important; }
  .pt-md-19 {
    padding-top: 5.9375rem !important; }
  .pt-md-20 {
    padding-top: 6.25rem !important; }
  .pt-md-21 {
    padding-top: 6.5625rem !important; }
  .pt-md-22 {
    padding-top: 6.875rem !important; }
  .pt-md-23 {
    padding-top: 7.1875rem !important; }
  .pt-md-24 {
    padding-top: 7.5rem !important; }
  .pt-md-25 {
    padding-top: 7.8125rem !important; }
  .pt-md-26 {
    padding-top: 8.125rem !important; }
  .pt-md-27 {
    padding-top: 8.4375rem !important; }
  .pt-md-28 {
    padding-top: 8.75rem !important; }
  .pt-md-29 {
    padding-top: 9.0625rem !important; }
  .pt-md-30 {
    padding-top: 9.375rem !important; }
  .pt-md-31 {
    padding-top: 9.6875rem !important; }
  .pt-md-32 {
    padding-top: 10rem !important; }
  .pt-md-33 {
    padding-top: 10.3125rem !important; }
  .pt-md-34 {
    padding-top: 10.625rem !important; }
  .pt-md-35 {
    padding-top: 10.9375rem !important; }
  .pt-md-36 {
    padding-top: 11.25rem !important; }
  .pt-md-37 {
    padding-top: 11.5625rem !important; }
  .pt-md-38 {
    padding-top: 11.875rem !important; }
  .pt-md-39 {
    padding-top: 12.1875rem !important; }
  .pt-md-40 {
    padding-top: 12.5rem !important; }
  .pt-md-base {
    padding-top: calc(20rem / 16) !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.3125rem !important; }
  .pe-md-2 {
    padding-right: 0.625rem !important; }
  .pe-md-3 {
    padding-right: 0.9375rem !important; }
  .pe-md-4 {
    padding-right: 1.25rem !important; }
  .pe-md-5 {
    padding-right: 1.5625rem !important; }
  .pe-md-6 {
    padding-right: 1.875rem !important; }
  .pe-md-7 {
    padding-right: 2.1875rem !important; }
  .pe-md-8 {
    padding-right: 2.5rem !important; }
  .pe-md-9 {
    padding-right: 2.8125rem !important; }
  .pe-md-10 {
    padding-right: 3.125rem !important; }
  .pe-md-11 {
    padding-right: 3.4375rem !important; }
  .pe-md-12 {
    padding-right: 3.75rem !important; }
  .pe-md-13 {
    padding-right: 4.0625rem !important; }
  .pe-md-14 {
    padding-right: 4.375rem !important; }
  .pe-md-15 {
    padding-right: 4.6875rem !important; }
  .pe-md-16 {
    padding-right: 5rem !important; }
  .pe-md-17 {
    padding-right: 5.3125rem !important; }
  .pe-md-18 {
    padding-right: 5.625rem !important; }
  .pe-md-19 {
    padding-right: 5.9375rem !important; }
  .pe-md-20 {
    padding-right: 6.25rem !important; }
  .pe-md-21 {
    padding-right: 6.5625rem !important; }
  .pe-md-22 {
    padding-right: 6.875rem !important; }
  .pe-md-23 {
    padding-right: 7.1875rem !important; }
  .pe-md-24 {
    padding-right: 7.5rem !important; }
  .pe-md-25 {
    padding-right: 7.8125rem !important; }
  .pe-md-26 {
    padding-right: 8.125rem !important; }
  .pe-md-27 {
    padding-right: 8.4375rem !important; }
  .pe-md-28 {
    padding-right: 8.75rem !important; }
  .pe-md-29 {
    padding-right: 9.0625rem !important; }
  .pe-md-30 {
    padding-right: 9.375rem !important; }
  .pe-md-31 {
    padding-right: 9.6875rem !important; }
  .pe-md-32 {
    padding-right: 10rem !important; }
  .pe-md-33 {
    padding-right: 10.3125rem !important; }
  .pe-md-34 {
    padding-right: 10.625rem !important; }
  .pe-md-35 {
    padding-right: 10.9375rem !important; }
  .pe-md-36 {
    padding-right: 11.25rem !important; }
  .pe-md-37 {
    padding-right: 11.5625rem !important; }
  .pe-md-38 {
    padding-right: 11.875rem !important; }
  .pe-md-39 {
    padding-right: 12.1875rem !important; }
  .pe-md-40 {
    padding-right: 12.5rem !important; }
  .pe-md-base {
    padding-right: calc(20rem / 16) !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.3125rem !important; }
  .pb-md-2 {
    padding-bottom: 0.625rem !important; }
  .pb-md-3 {
    padding-bottom: 0.9375rem !important; }
  .pb-md-4 {
    padding-bottom: 1.25rem !important; }
  .pb-md-5 {
    padding-bottom: 1.5625rem !important; }
  .pb-md-6 {
    padding-bottom: 1.875rem !important; }
  .pb-md-7 {
    padding-bottom: 2.1875rem !important; }
  .pb-md-8 {
    padding-bottom: 2.5rem !important; }
  .pb-md-9 {
    padding-bottom: 2.8125rem !important; }
  .pb-md-10 {
    padding-bottom: 3.125rem !important; }
  .pb-md-11 {
    padding-bottom: 3.4375rem !important; }
  .pb-md-12 {
    padding-bottom: 3.75rem !important; }
  .pb-md-13 {
    padding-bottom: 4.0625rem !important; }
  .pb-md-14 {
    padding-bottom: 4.375rem !important; }
  .pb-md-15 {
    padding-bottom: 4.6875rem !important; }
  .pb-md-16 {
    padding-bottom: 5rem !important; }
  .pb-md-17 {
    padding-bottom: 5.3125rem !important; }
  .pb-md-18 {
    padding-bottom: 5.625rem !important; }
  .pb-md-19 {
    padding-bottom: 5.9375rem !important; }
  .pb-md-20 {
    padding-bottom: 6.25rem !important; }
  .pb-md-21 {
    padding-bottom: 6.5625rem !important; }
  .pb-md-22 {
    padding-bottom: 6.875rem !important; }
  .pb-md-23 {
    padding-bottom: 7.1875rem !important; }
  .pb-md-24 {
    padding-bottom: 7.5rem !important; }
  .pb-md-25 {
    padding-bottom: 7.8125rem !important; }
  .pb-md-26 {
    padding-bottom: 8.125rem !important; }
  .pb-md-27 {
    padding-bottom: 8.4375rem !important; }
  .pb-md-28 {
    padding-bottom: 8.75rem !important; }
  .pb-md-29 {
    padding-bottom: 9.0625rem !important; }
  .pb-md-30 {
    padding-bottom: 9.375rem !important; }
  .pb-md-31 {
    padding-bottom: 9.6875rem !important; }
  .pb-md-32 {
    padding-bottom: 10rem !important; }
  .pb-md-33 {
    padding-bottom: 10.3125rem !important; }
  .pb-md-34 {
    padding-bottom: 10.625rem !important; }
  .pb-md-35 {
    padding-bottom: 10.9375rem !important; }
  .pb-md-36 {
    padding-bottom: 11.25rem !important; }
  .pb-md-37 {
    padding-bottom: 11.5625rem !important; }
  .pb-md-38 {
    padding-bottom: 11.875rem !important; }
  .pb-md-39 {
    padding-bottom: 12.1875rem !important; }
  .pb-md-40 {
    padding-bottom: 12.5rem !important; }
  .pb-md-base {
    padding-bottom: calc(20rem / 16) !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.3125rem !important; }
  .ps-md-2 {
    padding-left: 0.625rem !important; }
  .ps-md-3 {
    padding-left: 0.9375rem !important; }
  .ps-md-4 {
    padding-left: 1.25rem !important; }
  .ps-md-5 {
    padding-left: 1.5625rem !important; }
  .ps-md-6 {
    padding-left: 1.875rem !important; }
  .ps-md-7 {
    padding-left: 2.1875rem !important; }
  .ps-md-8 {
    padding-left: 2.5rem !important; }
  .ps-md-9 {
    padding-left: 2.8125rem !important; }
  .ps-md-10 {
    padding-left: 3.125rem !important; }
  .ps-md-11 {
    padding-left: 3.4375rem !important; }
  .ps-md-12 {
    padding-left: 3.75rem !important; }
  .ps-md-13 {
    padding-left: 4.0625rem !important; }
  .ps-md-14 {
    padding-left: 4.375rem !important; }
  .ps-md-15 {
    padding-left: 4.6875rem !important; }
  .ps-md-16 {
    padding-left: 5rem !important; }
  .ps-md-17 {
    padding-left: 5.3125rem !important; }
  .ps-md-18 {
    padding-left: 5.625rem !important; }
  .ps-md-19 {
    padding-left: 5.9375rem !important; }
  .ps-md-20 {
    padding-left: 6.25rem !important; }
  .ps-md-21 {
    padding-left: 6.5625rem !important; }
  .ps-md-22 {
    padding-left: 6.875rem !important; }
  .ps-md-23 {
    padding-left: 7.1875rem !important; }
  .ps-md-24 {
    padding-left: 7.5rem !important; }
  .ps-md-25 {
    padding-left: 7.8125rem !important; }
  .ps-md-26 {
    padding-left: 8.125rem !important; }
  .ps-md-27 {
    padding-left: 8.4375rem !important; }
  .ps-md-28 {
    padding-left: 8.75rem !important; }
  .ps-md-29 {
    padding-left: 9.0625rem !important; }
  .ps-md-30 {
    padding-left: 9.375rem !important; }
  .ps-md-31 {
    padding-left: 9.6875rem !important; }
  .ps-md-32 {
    padding-left: 10rem !important; }
  .ps-md-33 {
    padding-left: 10.3125rem !important; }
  .ps-md-34 {
    padding-left: 10.625rem !important; }
  .ps-md-35 {
    padding-left: 10.9375rem !important; }
  .ps-md-36 {
    padding-left: 11.25rem !important; }
  .ps-md-37 {
    padding-left: 11.5625rem !important; }
  .ps-md-38 {
    padding-left: 11.875rem !important; }
  .ps-md-39 {
    padding-left: 12.1875rem !important; }
  .ps-md-40 {
    padding-left: 12.5rem !important; }
  .ps-md-base {
    padding-left: calc(20rem / 16) !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.3125rem !important; }
  .gap-md-2 {
    gap: 0.625rem !important; }
  .gap-md-3 {
    gap: 0.9375rem !important; }
  .gap-md-4 {
    gap: 1.25rem !important; }
  .gap-md-5 {
    gap: 1.5625rem !important; }
  .gap-md-6 {
    gap: 1.875rem !important; }
  .gap-md-7 {
    gap: 2.1875rem !important; }
  .gap-md-8 {
    gap: 2.5rem !important; }
  .gap-md-9 {
    gap: 2.8125rem !important; }
  .gap-md-10 {
    gap: 3.125rem !important; }
  .gap-md-11 {
    gap: 3.4375rem !important; }
  .gap-md-12 {
    gap: 3.75rem !important; }
  .gap-md-13 {
    gap: 4.0625rem !important; }
  .gap-md-14 {
    gap: 4.375rem !important; }
  .gap-md-15 {
    gap: 4.6875rem !important; }
  .gap-md-16 {
    gap: 5rem !important; }
  .gap-md-17 {
    gap: 5.3125rem !important; }
  .gap-md-18 {
    gap: 5.625rem !important; }
  .gap-md-19 {
    gap: 5.9375rem !important; }
  .gap-md-20 {
    gap: 6.25rem !important; }
  .gap-md-21 {
    gap: 6.5625rem !important; }
  .gap-md-22 {
    gap: 6.875rem !important; }
  .gap-md-23 {
    gap: 7.1875rem !important; }
  .gap-md-24 {
    gap: 7.5rem !important; }
  .gap-md-25 {
    gap: 7.8125rem !important; }
  .gap-md-26 {
    gap: 8.125rem !important; }
  .gap-md-27 {
    gap: 8.4375rem !important; }
  .gap-md-28 {
    gap: 8.75rem !important; }
  .gap-md-29 {
    gap: 9.0625rem !important; }
  .gap-md-30 {
    gap: 9.375rem !important; }
  .gap-md-31 {
    gap: 9.6875rem !important; }
  .gap-md-32 {
    gap: 10rem !important; }
  .gap-md-33 {
    gap: 10.3125rem !important; }
  .gap-md-34 {
    gap: 10.625rem !important; }
  .gap-md-35 {
    gap: 10.9375rem !important; }
  .gap-md-36 {
    gap: 11.25rem !important; }
  .gap-md-37 {
    gap: 11.5625rem !important; }
  .gap-md-38 {
    gap: 11.875rem !important; }
  .gap-md-39 {
    gap: 12.1875rem !important; }
  .gap-md-40 {
    gap: 12.5rem !important; }
  .gap-md-base {
    gap: calc(20rem / 16) !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.3125rem !important; }
  .m-lg-2 {
    margin: 0.625rem !important; }
  .m-lg-3 {
    margin: 0.9375rem !important; }
  .m-lg-4 {
    margin: 1.25rem !important; }
  .m-lg-5 {
    margin: 1.5625rem !important; }
  .m-lg-6 {
    margin: 1.875rem !important; }
  .m-lg-7 {
    margin: 2.1875rem !important; }
  .m-lg-8 {
    margin: 2.5rem !important; }
  .m-lg-9 {
    margin: 2.8125rem !important; }
  .m-lg-10 {
    margin: 3.125rem !important; }
  .m-lg-11 {
    margin: 3.4375rem !important; }
  .m-lg-12 {
    margin: 3.75rem !important; }
  .m-lg-13 {
    margin: 4.0625rem !important; }
  .m-lg-14 {
    margin: 4.375rem !important; }
  .m-lg-15 {
    margin: 4.6875rem !important; }
  .m-lg-16 {
    margin: 5rem !important; }
  .m-lg-17 {
    margin: 5.3125rem !important; }
  .m-lg-18 {
    margin: 5.625rem !important; }
  .m-lg-19 {
    margin: 5.9375rem !important; }
  .m-lg-20 {
    margin: 6.25rem !important; }
  .m-lg-21 {
    margin: 6.5625rem !important; }
  .m-lg-22 {
    margin: 6.875rem !important; }
  .m-lg-23 {
    margin: 7.1875rem !important; }
  .m-lg-24 {
    margin: 7.5rem !important; }
  .m-lg-25 {
    margin: 7.8125rem !important; }
  .m-lg-26 {
    margin: 8.125rem !important; }
  .m-lg-27 {
    margin: 8.4375rem !important; }
  .m-lg-28 {
    margin: 8.75rem !important; }
  .m-lg-29 {
    margin: 9.0625rem !important; }
  .m-lg-30 {
    margin: 9.375rem !important; }
  .m-lg-31 {
    margin: 9.6875rem !important; }
  .m-lg-32 {
    margin: 10rem !important; }
  .m-lg-33 {
    margin: 10.3125rem !important; }
  .m-lg-34 {
    margin: 10.625rem !important; }
  .m-lg-35 {
    margin: 10.9375rem !important; }
  .m-lg-36 {
    margin: 11.25rem !important; }
  .m-lg-37 {
    margin: 11.5625rem !important; }
  .m-lg-38 {
    margin: 11.875rem !important; }
  .m-lg-39 {
    margin: 12.1875rem !important; }
  .m-lg-40 {
    margin: 12.5rem !important; }
  .m-lg-base {
    margin: calc(20rem / 16) !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important; }
  .mx-lg-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important; }
  .mx-lg-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important; }
  .mx-lg-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important; }
  .mx-lg-5 {
    margin-right: 1.5625rem !important;
    margin-left: 1.5625rem !important; }
  .mx-lg-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important; }
  .mx-lg-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important; }
  .mx-lg-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-lg-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important; }
  .mx-lg-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important; }
  .mx-lg-11 {
    margin-right: 3.4375rem !important;
    margin-left: 3.4375rem !important; }
  .mx-lg-12 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important; }
  .mx-lg-13 {
    margin-right: 4.0625rem !important;
    margin-left: 4.0625rem !important; }
  .mx-lg-14 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important; }
  .mx-lg-15 {
    margin-right: 4.6875rem !important;
    margin-left: 4.6875rem !important; }
  .mx-lg-16 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-lg-17 {
    margin-right: 5.3125rem !important;
    margin-left: 5.3125rem !important; }
  .mx-lg-18 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important; }
  .mx-lg-19 {
    margin-right: 5.9375rem !important;
    margin-left: 5.9375rem !important; }
  .mx-lg-20 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important; }
  .mx-lg-21 {
    margin-right: 6.5625rem !important;
    margin-left: 6.5625rem !important; }
  .mx-lg-22 {
    margin-right: 6.875rem !important;
    margin-left: 6.875rem !important; }
  .mx-lg-23 {
    margin-right: 7.1875rem !important;
    margin-left: 7.1875rem !important; }
  .mx-lg-24 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-lg-25 {
    margin-right: 7.8125rem !important;
    margin-left: 7.8125rem !important; }
  .mx-lg-26 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important; }
  .mx-lg-27 {
    margin-right: 8.4375rem !important;
    margin-left: 8.4375rem !important; }
  .mx-lg-28 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important; }
  .mx-lg-29 {
    margin-right: 9.0625rem !important;
    margin-left: 9.0625rem !important; }
  .mx-lg-30 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important; }
  .mx-lg-31 {
    margin-right: 9.6875rem !important;
    margin-left: 9.6875rem !important; }
  .mx-lg-32 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-lg-33 {
    margin-right: 10.3125rem !important;
    margin-left: 10.3125rem !important; }
  .mx-lg-34 {
    margin-right: 10.625rem !important;
    margin-left: 10.625rem !important; }
  .mx-lg-35 {
    margin-right: 10.9375rem !important;
    margin-left: 10.9375rem !important; }
  .mx-lg-36 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important; }
  .mx-lg-37 {
    margin-right: 11.5625rem !important;
    margin-left: 11.5625rem !important; }
  .mx-lg-38 {
    margin-right: 11.875rem !important;
    margin-left: 11.875rem !important; }
  .mx-lg-39 {
    margin-right: 12.1875rem !important;
    margin-left: 12.1875rem !important; }
  .mx-lg-40 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-lg-base {
    margin-right: calc(20rem / 16) !important;
    margin-left: calc(20rem / 16) !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important; }
  .my-lg-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important; }
  .my-lg-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important; }
  .my-lg-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .my-lg-5 {
    margin-top: 1.5625rem !important;
    margin-bottom: 1.5625rem !important; }
  .my-lg-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important; }
  .my-lg-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important; }
  .my-lg-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-lg-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important; }
  .my-lg-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important; }
  .my-lg-11 {
    margin-top: 3.4375rem !important;
    margin-bottom: 3.4375rem !important; }
  .my-lg-12 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important; }
  .my-lg-13 {
    margin-top: 4.0625rem !important;
    margin-bottom: 4.0625rem !important; }
  .my-lg-14 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important; }
  .my-lg-15 {
    margin-top: 4.6875rem !important;
    margin-bottom: 4.6875rem !important; }
  .my-lg-16 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-lg-17 {
    margin-top: 5.3125rem !important;
    margin-bottom: 5.3125rem !important; }
  .my-lg-18 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important; }
  .my-lg-19 {
    margin-top: 5.9375rem !important;
    margin-bottom: 5.9375rem !important; }
  .my-lg-20 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important; }
  .my-lg-21 {
    margin-top: 6.5625rem !important;
    margin-bottom: 6.5625rem !important; }
  .my-lg-22 {
    margin-top: 6.875rem !important;
    margin-bottom: 6.875rem !important; }
  .my-lg-23 {
    margin-top: 7.1875rem !important;
    margin-bottom: 7.1875rem !important; }
  .my-lg-24 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-lg-25 {
    margin-top: 7.8125rem !important;
    margin-bottom: 7.8125rem !important; }
  .my-lg-26 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important; }
  .my-lg-27 {
    margin-top: 8.4375rem !important;
    margin-bottom: 8.4375rem !important; }
  .my-lg-28 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important; }
  .my-lg-29 {
    margin-top: 9.0625rem !important;
    margin-bottom: 9.0625rem !important; }
  .my-lg-30 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important; }
  .my-lg-31 {
    margin-top: 9.6875rem !important;
    margin-bottom: 9.6875rem !important; }
  .my-lg-32 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-lg-33 {
    margin-top: 10.3125rem !important;
    margin-bottom: 10.3125rem !important; }
  .my-lg-34 {
    margin-top: 10.625rem !important;
    margin-bottom: 10.625rem !important; }
  .my-lg-35 {
    margin-top: 10.9375rem !important;
    margin-bottom: 10.9375rem !important; }
  .my-lg-36 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important; }
  .my-lg-37 {
    margin-top: 11.5625rem !important;
    margin-bottom: 11.5625rem !important; }
  .my-lg-38 {
    margin-top: 11.875rem !important;
    margin-bottom: 11.875rem !important; }
  .my-lg-39 {
    margin-top: 12.1875rem !important;
    margin-bottom: 12.1875rem !important; }
  .my-lg-40 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-lg-base {
    margin-top: calc(20rem / 16) !important;
    margin-bottom: calc(20rem / 16) !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.3125rem !important; }
  .mt-lg-2 {
    margin-top: 0.625rem !important; }
  .mt-lg-3 {
    margin-top: 0.9375rem !important; }
  .mt-lg-4 {
    margin-top: 1.25rem !important; }
  .mt-lg-5 {
    margin-top: 1.5625rem !important; }
  .mt-lg-6 {
    margin-top: 1.875rem !important; }
  .mt-lg-7 {
    margin-top: 2.1875rem !important; }
  .mt-lg-8 {
    margin-top: 2.5rem !important; }
  .mt-lg-9 {
    margin-top: 2.8125rem !important; }
  .mt-lg-10 {
    margin-top: 3.125rem !important; }
  .mt-lg-11 {
    margin-top: 3.4375rem !important; }
  .mt-lg-12 {
    margin-top: 3.75rem !important; }
  .mt-lg-13 {
    margin-top: 4.0625rem !important; }
  .mt-lg-14 {
    margin-top: 4.375rem !important; }
  .mt-lg-15 {
    margin-top: 4.6875rem !important; }
  .mt-lg-16 {
    margin-top: 5rem !important; }
  .mt-lg-17 {
    margin-top: 5.3125rem !important; }
  .mt-lg-18 {
    margin-top: 5.625rem !important; }
  .mt-lg-19 {
    margin-top: 5.9375rem !important; }
  .mt-lg-20 {
    margin-top: 6.25rem !important; }
  .mt-lg-21 {
    margin-top: 6.5625rem !important; }
  .mt-lg-22 {
    margin-top: 6.875rem !important; }
  .mt-lg-23 {
    margin-top: 7.1875rem !important; }
  .mt-lg-24 {
    margin-top: 7.5rem !important; }
  .mt-lg-25 {
    margin-top: 7.8125rem !important; }
  .mt-lg-26 {
    margin-top: 8.125rem !important; }
  .mt-lg-27 {
    margin-top: 8.4375rem !important; }
  .mt-lg-28 {
    margin-top: 8.75rem !important; }
  .mt-lg-29 {
    margin-top: 9.0625rem !important; }
  .mt-lg-30 {
    margin-top: 9.375rem !important; }
  .mt-lg-31 {
    margin-top: 9.6875rem !important; }
  .mt-lg-32 {
    margin-top: 10rem !important; }
  .mt-lg-33 {
    margin-top: 10.3125rem !important; }
  .mt-lg-34 {
    margin-top: 10.625rem !important; }
  .mt-lg-35 {
    margin-top: 10.9375rem !important; }
  .mt-lg-36 {
    margin-top: 11.25rem !important; }
  .mt-lg-37 {
    margin-top: 11.5625rem !important; }
  .mt-lg-38 {
    margin-top: 11.875rem !important; }
  .mt-lg-39 {
    margin-top: 12.1875rem !important; }
  .mt-lg-40 {
    margin-top: 12.5rem !important; }
  .mt-lg-base {
    margin-top: calc(20rem / 16) !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.3125rem !important; }
  .me-lg-2 {
    margin-right: 0.625rem !important; }
  .me-lg-3 {
    margin-right: 0.9375rem !important; }
  .me-lg-4 {
    margin-right: 1.25rem !important; }
  .me-lg-5 {
    margin-right: 1.5625rem !important; }
  .me-lg-6 {
    margin-right: 1.875rem !important; }
  .me-lg-7 {
    margin-right: 2.1875rem !important; }
  .me-lg-8 {
    margin-right: 2.5rem !important; }
  .me-lg-9 {
    margin-right: 2.8125rem !important; }
  .me-lg-10 {
    margin-right: 3.125rem !important; }
  .me-lg-11 {
    margin-right: 3.4375rem !important; }
  .me-lg-12 {
    margin-right: 3.75rem !important; }
  .me-lg-13 {
    margin-right: 4.0625rem !important; }
  .me-lg-14 {
    margin-right: 4.375rem !important; }
  .me-lg-15 {
    margin-right: 4.6875rem !important; }
  .me-lg-16 {
    margin-right: 5rem !important; }
  .me-lg-17 {
    margin-right: 5.3125rem !important; }
  .me-lg-18 {
    margin-right: 5.625rem !important; }
  .me-lg-19 {
    margin-right: 5.9375rem !important; }
  .me-lg-20 {
    margin-right: 6.25rem !important; }
  .me-lg-21 {
    margin-right: 6.5625rem !important; }
  .me-lg-22 {
    margin-right: 6.875rem !important; }
  .me-lg-23 {
    margin-right: 7.1875rem !important; }
  .me-lg-24 {
    margin-right: 7.5rem !important; }
  .me-lg-25 {
    margin-right: 7.8125rem !important; }
  .me-lg-26 {
    margin-right: 8.125rem !important; }
  .me-lg-27 {
    margin-right: 8.4375rem !important; }
  .me-lg-28 {
    margin-right: 8.75rem !important; }
  .me-lg-29 {
    margin-right: 9.0625rem !important; }
  .me-lg-30 {
    margin-right: 9.375rem !important; }
  .me-lg-31 {
    margin-right: 9.6875rem !important; }
  .me-lg-32 {
    margin-right: 10rem !important; }
  .me-lg-33 {
    margin-right: 10.3125rem !important; }
  .me-lg-34 {
    margin-right: 10.625rem !important; }
  .me-lg-35 {
    margin-right: 10.9375rem !important; }
  .me-lg-36 {
    margin-right: 11.25rem !important; }
  .me-lg-37 {
    margin-right: 11.5625rem !important; }
  .me-lg-38 {
    margin-right: 11.875rem !important; }
  .me-lg-39 {
    margin-right: 12.1875rem !important; }
  .me-lg-40 {
    margin-right: 12.5rem !important; }
  .me-lg-base {
    margin-right: calc(20rem / 16) !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.3125rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.625rem !important; }
  .mb-lg-3 {
    margin-bottom: 0.9375rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.25rem !important; }
  .mb-lg-5 {
    margin-bottom: 1.5625rem !important; }
  .mb-lg-6 {
    margin-bottom: 1.875rem !important; }
  .mb-lg-7 {
    margin-bottom: 2.1875rem !important; }
  .mb-lg-8 {
    margin-bottom: 2.5rem !important; }
  .mb-lg-9 {
    margin-bottom: 2.8125rem !important; }
  .mb-lg-10 {
    margin-bottom: 3.125rem !important; }
  .mb-lg-11 {
    margin-bottom: 3.4375rem !important; }
  .mb-lg-12 {
    margin-bottom: 3.75rem !important; }
  .mb-lg-13 {
    margin-bottom: 4.0625rem !important; }
  .mb-lg-14 {
    margin-bottom: 4.375rem !important; }
  .mb-lg-15 {
    margin-bottom: 4.6875rem !important; }
  .mb-lg-16 {
    margin-bottom: 5rem !important; }
  .mb-lg-17 {
    margin-bottom: 5.3125rem !important; }
  .mb-lg-18 {
    margin-bottom: 5.625rem !important; }
  .mb-lg-19 {
    margin-bottom: 5.9375rem !important; }
  .mb-lg-20 {
    margin-bottom: 6.25rem !important; }
  .mb-lg-21 {
    margin-bottom: 6.5625rem !important; }
  .mb-lg-22 {
    margin-bottom: 6.875rem !important; }
  .mb-lg-23 {
    margin-bottom: 7.1875rem !important; }
  .mb-lg-24 {
    margin-bottom: 7.5rem !important; }
  .mb-lg-25 {
    margin-bottom: 7.8125rem !important; }
  .mb-lg-26 {
    margin-bottom: 8.125rem !important; }
  .mb-lg-27 {
    margin-bottom: 8.4375rem !important; }
  .mb-lg-28 {
    margin-bottom: 8.75rem !important; }
  .mb-lg-29 {
    margin-bottom: 9.0625rem !important; }
  .mb-lg-30 {
    margin-bottom: 9.375rem !important; }
  .mb-lg-31 {
    margin-bottom: 9.6875rem !important; }
  .mb-lg-32 {
    margin-bottom: 10rem !important; }
  .mb-lg-33 {
    margin-bottom: 10.3125rem !important; }
  .mb-lg-34 {
    margin-bottom: 10.625rem !important; }
  .mb-lg-35 {
    margin-bottom: 10.9375rem !important; }
  .mb-lg-36 {
    margin-bottom: 11.25rem !important; }
  .mb-lg-37 {
    margin-bottom: 11.5625rem !important; }
  .mb-lg-38 {
    margin-bottom: 11.875rem !important; }
  .mb-lg-39 {
    margin-bottom: 12.1875rem !important; }
  .mb-lg-40 {
    margin-bottom: 12.5rem !important; }
  .mb-lg-base {
    margin-bottom: calc(20rem / 16) !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.3125rem !important; }
  .ms-lg-2 {
    margin-left: 0.625rem !important; }
  .ms-lg-3 {
    margin-left: 0.9375rem !important; }
  .ms-lg-4 {
    margin-left: 1.25rem !important; }
  .ms-lg-5 {
    margin-left: 1.5625rem !important; }
  .ms-lg-6 {
    margin-left: 1.875rem !important; }
  .ms-lg-7 {
    margin-left: 2.1875rem !important; }
  .ms-lg-8 {
    margin-left: 2.5rem !important; }
  .ms-lg-9 {
    margin-left: 2.8125rem !important; }
  .ms-lg-10 {
    margin-left: 3.125rem !important; }
  .ms-lg-11 {
    margin-left: 3.4375rem !important; }
  .ms-lg-12 {
    margin-left: 3.75rem !important; }
  .ms-lg-13 {
    margin-left: 4.0625rem !important; }
  .ms-lg-14 {
    margin-left: 4.375rem !important; }
  .ms-lg-15 {
    margin-left: 4.6875rem !important; }
  .ms-lg-16 {
    margin-left: 5rem !important; }
  .ms-lg-17 {
    margin-left: 5.3125rem !important; }
  .ms-lg-18 {
    margin-left: 5.625rem !important; }
  .ms-lg-19 {
    margin-left: 5.9375rem !important; }
  .ms-lg-20 {
    margin-left: 6.25rem !important; }
  .ms-lg-21 {
    margin-left: 6.5625rem !important; }
  .ms-lg-22 {
    margin-left: 6.875rem !important; }
  .ms-lg-23 {
    margin-left: 7.1875rem !important; }
  .ms-lg-24 {
    margin-left: 7.5rem !important; }
  .ms-lg-25 {
    margin-left: 7.8125rem !important; }
  .ms-lg-26 {
    margin-left: 8.125rem !important; }
  .ms-lg-27 {
    margin-left: 8.4375rem !important; }
  .ms-lg-28 {
    margin-left: 8.75rem !important; }
  .ms-lg-29 {
    margin-left: 9.0625rem !important; }
  .ms-lg-30 {
    margin-left: 9.375rem !important; }
  .ms-lg-31 {
    margin-left: 9.6875rem !important; }
  .ms-lg-32 {
    margin-left: 10rem !important; }
  .ms-lg-33 {
    margin-left: 10.3125rem !important; }
  .ms-lg-34 {
    margin-left: 10.625rem !important; }
  .ms-lg-35 {
    margin-left: 10.9375rem !important; }
  .ms-lg-36 {
    margin-left: 11.25rem !important; }
  .ms-lg-37 {
    margin-left: 11.5625rem !important; }
  .ms-lg-38 {
    margin-left: 11.875rem !important; }
  .ms-lg-39 {
    margin-left: 12.1875rem !important; }
  .ms-lg-40 {
    margin-left: 12.5rem !important; }
  .ms-lg-base {
    margin-left: calc(20rem / 16) !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .m-lg-n1 {
    margin: -0.3125rem !important; }
  .m-lg-n2 {
    margin: -0.625rem !important; }
  .m-lg-n3 {
    margin: -0.9375rem !important; }
  .m-lg-n4 {
    margin: -1.25rem !important; }
  .m-lg-n5 {
    margin: -1.5625rem !important; }
  .m-lg-n6 {
    margin: -1.875rem !important; }
  .m-lg-n7 {
    margin: -2.1875rem !important; }
  .m-lg-n8 {
    margin: -2.5rem !important; }
  .m-lg-n9 {
    margin: -2.8125rem !important; }
  .m-lg-n10 {
    margin: -3.125rem !important; }
  .m-lg-n11 {
    margin: -3.4375rem !important; }
  .m-lg-n12 {
    margin: -3.75rem !important; }
  .m-lg-n13 {
    margin: -4.0625rem !important; }
  .m-lg-n14 {
    margin: -4.375rem !important; }
  .m-lg-n15 {
    margin: -4.6875rem !important; }
  .m-lg-n16 {
    margin: -5rem !important; }
  .m-lg-n17 {
    margin: -5.3125rem !important; }
  .m-lg-n18 {
    margin: -5.625rem !important; }
  .m-lg-n19 {
    margin: -5.9375rem !important; }
  .m-lg-n20 {
    margin: -6.25rem !important; }
  .m-lg-n21 {
    margin: -6.5625rem !important; }
  .m-lg-n22 {
    margin: -6.875rem !important; }
  .m-lg-n23 {
    margin: -7.1875rem !important; }
  .m-lg-n24 {
    margin: -7.5rem !important; }
  .m-lg-n25 {
    margin: -7.8125rem !important; }
  .m-lg-n26 {
    margin: -8.125rem !important; }
  .m-lg-n27 {
    margin: -8.4375rem !important; }
  .m-lg-n28 {
    margin: -8.75rem !important; }
  .m-lg-n29 {
    margin: -9.0625rem !important; }
  .m-lg-n30 {
    margin: -9.375rem !important; }
  .m-lg-n31 {
    margin: -9.6875rem !important; }
  .m-lg-n32 {
    margin: -10rem !important; }
  .m-lg-n33 {
    margin: -10.3125rem !important; }
  .m-lg-n34 {
    margin: -10.625rem !important; }
  .m-lg-n35 {
    margin: -10.9375rem !important; }
  .m-lg-n36 {
    margin: -11.25rem !important; }
  .m-lg-n37 {
    margin: -11.5625rem !important; }
  .m-lg-n38 {
    margin: -11.875rem !important; }
  .m-lg-n39 {
    margin: -12.1875rem !important; }
  .m-lg-n40 {
    margin: -12.5rem !important; }
  .m-lg-nbase {
    margin: -calc(20rem / 16) !important; }
  .mx-lg-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important; }
  .mx-lg-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important; }
  .mx-lg-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important; }
  .mx-lg-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important; }
  .mx-lg-n5 {
    margin-right: -1.5625rem !important;
    margin-left: -1.5625rem !important; }
  .mx-lg-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important; }
  .mx-lg-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important; }
  .mx-lg-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-lg-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important; }
  .mx-lg-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important; }
  .mx-lg-n11 {
    margin-right: -3.4375rem !important;
    margin-left: -3.4375rem !important; }
  .mx-lg-n12 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important; }
  .mx-lg-n13 {
    margin-right: -4.0625rem !important;
    margin-left: -4.0625rem !important; }
  .mx-lg-n14 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important; }
  .mx-lg-n15 {
    margin-right: -4.6875rem !important;
    margin-left: -4.6875rem !important; }
  .mx-lg-n16 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-lg-n17 {
    margin-right: -5.3125rem !important;
    margin-left: -5.3125rem !important; }
  .mx-lg-n18 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important; }
  .mx-lg-n19 {
    margin-right: -5.9375rem !important;
    margin-left: -5.9375rem !important; }
  .mx-lg-n20 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important; }
  .mx-lg-n21 {
    margin-right: -6.5625rem !important;
    margin-left: -6.5625rem !important; }
  .mx-lg-n22 {
    margin-right: -6.875rem !important;
    margin-left: -6.875rem !important; }
  .mx-lg-n23 {
    margin-right: -7.1875rem !important;
    margin-left: -7.1875rem !important; }
  .mx-lg-n24 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-lg-n25 {
    margin-right: -7.8125rem !important;
    margin-left: -7.8125rem !important; }
  .mx-lg-n26 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important; }
  .mx-lg-n27 {
    margin-right: -8.4375rem !important;
    margin-left: -8.4375rem !important; }
  .mx-lg-n28 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important; }
  .mx-lg-n29 {
    margin-right: -9.0625rem !important;
    margin-left: -9.0625rem !important; }
  .mx-lg-n30 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important; }
  .mx-lg-n31 {
    margin-right: -9.6875rem !important;
    margin-left: -9.6875rem !important; }
  .mx-lg-n32 {
    margin-right: -10rem !important;
    margin-left: -10rem !important; }
  .mx-lg-n33 {
    margin-right: -10.3125rem !important;
    margin-left: -10.3125rem !important; }
  .mx-lg-n34 {
    margin-right: -10.625rem !important;
    margin-left: -10.625rem !important; }
  .mx-lg-n35 {
    margin-right: -10.9375rem !important;
    margin-left: -10.9375rem !important; }
  .mx-lg-n36 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important; }
  .mx-lg-n37 {
    margin-right: -11.5625rem !important;
    margin-left: -11.5625rem !important; }
  .mx-lg-n38 {
    margin-right: -11.875rem !important;
    margin-left: -11.875rem !important; }
  .mx-lg-n39 {
    margin-right: -12.1875rem !important;
    margin-left: -12.1875rem !important; }
  .mx-lg-n40 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important; }
  .mx-lg-nbase {
    margin-right: -calc(20rem / 16) !important;
    margin-left: -calc(20rem / 16) !important; }
  .my-lg-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important; }
  .my-lg-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important; }
  .my-lg-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important; }
  .my-lg-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .my-lg-n5 {
    margin-top: -1.5625rem !important;
    margin-bottom: -1.5625rem !important; }
  .my-lg-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important; }
  .my-lg-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important; }
  .my-lg-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-lg-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important; }
  .my-lg-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important; }
  .my-lg-n11 {
    margin-top: -3.4375rem !important;
    margin-bottom: -3.4375rem !important; }
  .my-lg-n12 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important; }
  .my-lg-n13 {
    margin-top: -4.0625rem !important;
    margin-bottom: -4.0625rem !important; }
  .my-lg-n14 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important; }
  .my-lg-n15 {
    margin-top: -4.6875rem !important;
    margin-bottom: -4.6875rem !important; }
  .my-lg-n16 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-lg-n17 {
    margin-top: -5.3125rem !important;
    margin-bottom: -5.3125rem !important; }
  .my-lg-n18 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important; }
  .my-lg-n19 {
    margin-top: -5.9375rem !important;
    margin-bottom: -5.9375rem !important; }
  .my-lg-n20 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important; }
  .my-lg-n21 {
    margin-top: -6.5625rem !important;
    margin-bottom: -6.5625rem !important; }
  .my-lg-n22 {
    margin-top: -6.875rem !important;
    margin-bottom: -6.875rem !important; }
  .my-lg-n23 {
    margin-top: -7.1875rem !important;
    margin-bottom: -7.1875rem !important; }
  .my-lg-n24 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-lg-n25 {
    margin-top: -7.8125rem !important;
    margin-bottom: -7.8125rem !important; }
  .my-lg-n26 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important; }
  .my-lg-n27 {
    margin-top: -8.4375rem !important;
    margin-bottom: -8.4375rem !important; }
  .my-lg-n28 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important; }
  .my-lg-n29 {
    margin-top: -9.0625rem !important;
    margin-bottom: -9.0625rem !important; }
  .my-lg-n30 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important; }
  .my-lg-n31 {
    margin-top: -9.6875rem !important;
    margin-bottom: -9.6875rem !important; }
  .my-lg-n32 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .my-lg-n33 {
    margin-top: -10.3125rem !important;
    margin-bottom: -10.3125rem !important; }
  .my-lg-n34 {
    margin-top: -10.625rem !important;
    margin-bottom: -10.625rem !important; }
  .my-lg-n35 {
    margin-top: -10.9375rem !important;
    margin-bottom: -10.9375rem !important; }
  .my-lg-n36 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important; }
  .my-lg-n37 {
    margin-top: -11.5625rem !important;
    margin-bottom: -11.5625rem !important; }
  .my-lg-n38 {
    margin-top: -11.875rem !important;
    margin-bottom: -11.875rem !important; }
  .my-lg-n39 {
    margin-top: -12.1875rem !important;
    margin-bottom: -12.1875rem !important; }
  .my-lg-n40 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important; }
  .my-lg-nbase {
    margin-top: -calc(20rem / 16) !important;
    margin-bottom: -calc(20rem / 16) !important; }
  .mt-lg-n1 {
    margin-top: -0.3125rem !important; }
  .mt-lg-n2 {
    margin-top: -0.625rem !important; }
  .mt-lg-n3 {
    margin-top: -0.9375rem !important; }
  .mt-lg-n4 {
    margin-top: -1.25rem !important; }
  .mt-lg-n5 {
    margin-top: -1.5625rem !important; }
  .mt-lg-n6 {
    margin-top: -1.875rem !important; }
  .mt-lg-n7 {
    margin-top: -2.1875rem !important; }
  .mt-lg-n8 {
    margin-top: -2.5rem !important; }
  .mt-lg-n9 {
    margin-top: -2.8125rem !important; }
  .mt-lg-n10 {
    margin-top: -3.125rem !important; }
  .mt-lg-n11 {
    margin-top: -3.4375rem !important; }
  .mt-lg-n12 {
    margin-top: -3.75rem !important; }
  .mt-lg-n13 {
    margin-top: -4.0625rem !important; }
  .mt-lg-n14 {
    margin-top: -4.375rem !important; }
  .mt-lg-n15 {
    margin-top: -4.6875rem !important; }
  .mt-lg-n16 {
    margin-top: -5rem !important; }
  .mt-lg-n17 {
    margin-top: -5.3125rem !important; }
  .mt-lg-n18 {
    margin-top: -5.625rem !important; }
  .mt-lg-n19 {
    margin-top: -5.9375rem !important; }
  .mt-lg-n20 {
    margin-top: -6.25rem !important; }
  .mt-lg-n21 {
    margin-top: -6.5625rem !important; }
  .mt-lg-n22 {
    margin-top: -6.875rem !important; }
  .mt-lg-n23 {
    margin-top: -7.1875rem !important; }
  .mt-lg-n24 {
    margin-top: -7.5rem !important; }
  .mt-lg-n25 {
    margin-top: -7.8125rem !important; }
  .mt-lg-n26 {
    margin-top: -8.125rem !important; }
  .mt-lg-n27 {
    margin-top: -8.4375rem !important; }
  .mt-lg-n28 {
    margin-top: -8.75rem !important; }
  .mt-lg-n29 {
    margin-top: -9.0625rem !important; }
  .mt-lg-n30 {
    margin-top: -9.375rem !important; }
  .mt-lg-n31 {
    margin-top: -9.6875rem !important; }
  .mt-lg-n32 {
    margin-top: -10rem !important; }
  .mt-lg-n33 {
    margin-top: -10.3125rem !important; }
  .mt-lg-n34 {
    margin-top: -10.625rem !important; }
  .mt-lg-n35 {
    margin-top: -10.9375rem !important; }
  .mt-lg-n36 {
    margin-top: -11.25rem !important; }
  .mt-lg-n37 {
    margin-top: -11.5625rem !important; }
  .mt-lg-n38 {
    margin-top: -11.875rem !important; }
  .mt-lg-n39 {
    margin-top: -12.1875rem !important; }
  .mt-lg-n40 {
    margin-top: -12.5rem !important; }
  .mt-lg-nbase {
    margin-top: -calc(20rem / 16) !important; }
  .me-lg-n1 {
    margin-right: -0.3125rem !important; }
  .me-lg-n2 {
    margin-right: -0.625rem !important; }
  .me-lg-n3 {
    margin-right: -0.9375rem !important; }
  .me-lg-n4 {
    margin-right: -1.25rem !important; }
  .me-lg-n5 {
    margin-right: -1.5625rem !important; }
  .me-lg-n6 {
    margin-right: -1.875rem !important; }
  .me-lg-n7 {
    margin-right: -2.1875rem !important; }
  .me-lg-n8 {
    margin-right: -2.5rem !important; }
  .me-lg-n9 {
    margin-right: -2.8125rem !important; }
  .me-lg-n10 {
    margin-right: -3.125rem !important; }
  .me-lg-n11 {
    margin-right: -3.4375rem !important; }
  .me-lg-n12 {
    margin-right: -3.75rem !important; }
  .me-lg-n13 {
    margin-right: -4.0625rem !important; }
  .me-lg-n14 {
    margin-right: -4.375rem !important; }
  .me-lg-n15 {
    margin-right: -4.6875rem !important; }
  .me-lg-n16 {
    margin-right: -5rem !important; }
  .me-lg-n17 {
    margin-right: -5.3125rem !important; }
  .me-lg-n18 {
    margin-right: -5.625rem !important; }
  .me-lg-n19 {
    margin-right: -5.9375rem !important; }
  .me-lg-n20 {
    margin-right: -6.25rem !important; }
  .me-lg-n21 {
    margin-right: -6.5625rem !important; }
  .me-lg-n22 {
    margin-right: -6.875rem !important; }
  .me-lg-n23 {
    margin-right: -7.1875rem !important; }
  .me-lg-n24 {
    margin-right: -7.5rem !important; }
  .me-lg-n25 {
    margin-right: -7.8125rem !important; }
  .me-lg-n26 {
    margin-right: -8.125rem !important; }
  .me-lg-n27 {
    margin-right: -8.4375rem !important; }
  .me-lg-n28 {
    margin-right: -8.75rem !important; }
  .me-lg-n29 {
    margin-right: -9.0625rem !important; }
  .me-lg-n30 {
    margin-right: -9.375rem !important; }
  .me-lg-n31 {
    margin-right: -9.6875rem !important; }
  .me-lg-n32 {
    margin-right: -10rem !important; }
  .me-lg-n33 {
    margin-right: -10.3125rem !important; }
  .me-lg-n34 {
    margin-right: -10.625rem !important; }
  .me-lg-n35 {
    margin-right: -10.9375rem !important; }
  .me-lg-n36 {
    margin-right: -11.25rem !important; }
  .me-lg-n37 {
    margin-right: -11.5625rem !important; }
  .me-lg-n38 {
    margin-right: -11.875rem !important; }
  .me-lg-n39 {
    margin-right: -12.1875rem !important; }
  .me-lg-n40 {
    margin-right: -12.5rem !important; }
  .me-lg-nbase {
    margin-right: -calc(20rem / 16) !important; }
  .mb-lg-n1 {
    margin-bottom: -0.3125rem !important; }
  .mb-lg-n2 {
    margin-bottom: -0.625rem !important; }
  .mb-lg-n3 {
    margin-bottom: -0.9375rem !important; }
  .mb-lg-n4 {
    margin-bottom: -1.25rem !important; }
  .mb-lg-n5 {
    margin-bottom: -1.5625rem !important; }
  .mb-lg-n6 {
    margin-bottom: -1.875rem !important; }
  .mb-lg-n7 {
    margin-bottom: -2.1875rem !important; }
  .mb-lg-n8 {
    margin-bottom: -2.5rem !important; }
  .mb-lg-n9 {
    margin-bottom: -2.8125rem !important; }
  .mb-lg-n10 {
    margin-bottom: -3.125rem !important; }
  .mb-lg-n11 {
    margin-bottom: -3.4375rem !important; }
  .mb-lg-n12 {
    margin-bottom: -3.75rem !important; }
  .mb-lg-n13 {
    margin-bottom: -4.0625rem !important; }
  .mb-lg-n14 {
    margin-bottom: -4.375rem !important; }
  .mb-lg-n15 {
    margin-bottom: -4.6875rem !important; }
  .mb-lg-n16 {
    margin-bottom: -5rem !important; }
  .mb-lg-n17 {
    margin-bottom: -5.3125rem !important; }
  .mb-lg-n18 {
    margin-bottom: -5.625rem !important; }
  .mb-lg-n19 {
    margin-bottom: -5.9375rem !important; }
  .mb-lg-n20 {
    margin-bottom: -6.25rem !important; }
  .mb-lg-n21 {
    margin-bottom: -6.5625rem !important; }
  .mb-lg-n22 {
    margin-bottom: -6.875rem !important; }
  .mb-lg-n23 {
    margin-bottom: -7.1875rem !important; }
  .mb-lg-n24 {
    margin-bottom: -7.5rem !important; }
  .mb-lg-n25 {
    margin-bottom: -7.8125rem !important; }
  .mb-lg-n26 {
    margin-bottom: -8.125rem !important; }
  .mb-lg-n27 {
    margin-bottom: -8.4375rem !important; }
  .mb-lg-n28 {
    margin-bottom: -8.75rem !important; }
  .mb-lg-n29 {
    margin-bottom: -9.0625rem !important; }
  .mb-lg-n30 {
    margin-bottom: -9.375rem !important; }
  .mb-lg-n31 {
    margin-bottom: -9.6875rem !important; }
  .mb-lg-n32 {
    margin-bottom: -10rem !important; }
  .mb-lg-n33 {
    margin-bottom: -10.3125rem !important; }
  .mb-lg-n34 {
    margin-bottom: -10.625rem !important; }
  .mb-lg-n35 {
    margin-bottom: -10.9375rem !important; }
  .mb-lg-n36 {
    margin-bottom: -11.25rem !important; }
  .mb-lg-n37 {
    margin-bottom: -11.5625rem !important; }
  .mb-lg-n38 {
    margin-bottom: -11.875rem !important; }
  .mb-lg-n39 {
    margin-bottom: -12.1875rem !important; }
  .mb-lg-n40 {
    margin-bottom: -12.5rem !important; }
  .mb-lg-nbase {
    margin-bottom: -calc(20rem / 16) !important; }
  .ms-lg-n1 {
    margin-left: -0.3125rem !important; }
  .ms-lg-n2 {
    margin-left: -0.625rem !important; }
  .ms-lg-n3 {
    margin-left: -0.9375rem !important; }
  .ms-lg-n4 {
    margin-left: -1.25rem !important; }
  .ms-lg-n5 {
    margin-left: -1.5625rem !important; }
  .ms-lg-n6 {
    margin-left: -1.875rem !important; }
  .ms-lg-n7 {
    margin-left: -2.1875rem !important; }
  .ms-lg-n8 {
    margin-left: -2.5rem !important; }
  .ms-lg-n9 {
    margin-left: -2.8125rem !important; }
  .ms-lg-n10 {
    margin-left: -3.125rem !important; }
  .ms-lg-n11 {
    margin-left: -3.4375rem !important; }
  .ms-lg-n12 {
    margin-left: -3.75rem !important; }
  .ms-lg-n13 {
    margin-left: -4.0625rem !important; }
  .ms-lg-n14 {
    margin-left: -4.375rem !important; }
  .ms-lg-n15 {
    margin-left: -4.6875rem !important; }
  .ms-lg-n16 {
    margin-left: -5rem !important; }
  .ms-lg-n17 {
    margin-left: -5.3125rem !important; }
  .ms-lg-n18 {
    margin-left: -5.625rem !important; }
  .ms-lg-n19 {
    margin-left: -5.9375rem !important; }
  .ms-lg-n20 {
    margin-left: -6.25rem !important; }
  .ms-lg-n21 {
    margin-left: -6.5625rem !important; }
  .ms-lg-n22 {
    margin-left: -6.875rem !important; }
  .ms-lg-n23 {
    margin-left: -7.1875rem !important; }
  .ms-lg-n24 {
    margin-left: -7.5rem !important; }
  .ms-lg-n25 {
    margin-left: -7.8125rem !important; }
  .ms-lg-n26 {
    margin-left: -8.125rem !important; }
  .ms-lg-n27 {
    margin-left: -8.4375rem !important; }
  .ms-lg-n28 {
    margin-left: -8.75rem !important; }
  .ms-lg-n29 {
    margin-left: -9.0625rem !important; }
  .ms-lg-n30 {
    margin-left: -9.375rem !important; }
  .ms-lg-n31 {
    margin-left: -9.6875rem !important; }
  .ms-lg-n32 {
    margin-left: -10rem !important; }
  .ms-lg-n33 {
    margin-left: -10.3125rem !important; }
  .ms-lg-n34 {
    margin-left: -10.625rem !important; }
  .ms-lg-n35 {
    margin-left: -10.9375rem !important; }
  .ms-lg-n36 {
    margin-left: -11.25rem !important; }
  .ms-lg-n37 {
    margin-left: -11.5625rem !important; }
  .ms-lg-n38 {
    margin-left: -11.875rem !important; }
  .ms-lg-n39 {
    margin-left: -12.1875rem !important; }
  .ms-lg-n40 {
    margin-left: -12.5rem !important; }
  .ms-lg-nbase {
    margin-left: -calc(20rem / 16) !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.3125rem !important; }
  .p-lg-2 {
    padding: 0.625rem !important; }
  .p-lg-3 {
    padding: 0.9375rem !important; }
  .p-lg-4 {
    padding: 1.25rem !important; }
  .p-lg-5 {
    padding: 1.5625rem !important; }
  .p-lg-6 {
    padding: 1.875rem !important; }
  .p-lg-7 {
    padding: 2.1875rem !important; }
  .p-lg-8 {
    padding: 2.5rem !important; }
  .p-lg-9 {
    padding: 2.8125rem !important; }
  .p-lg-10 {
    padding: 3.125rem !important; }
  .p-lg-11 {
    padding: 3.4375rem !important; }
  .p-lg-12 {
    padding: 3.75rem !important; }
  .p-lg-13 {
    padding: 4.0625rem !important; }
  .p-lg-14 {
    padding: 4.375rem !important; }
  .p-lg-15 {
    padding: 4.6875rem !important; }
  .p-lg-16 {
    padding: 5rem !important; }
  .p-lg-17 {
    padding: 5.3125rem !important; }
  .p-lg-18 {
    padding: 5.625rem !important; }
  .p-lg-19 {
    padding: 5.9375rem !important; }
  .p-lg-20 {
    padding: 6.25rem !important; }
  .p-lg-21 {
    padding: 6.5625rem !important; }
  .p-lg-22 {
    padding: 6.875rem !important; }
  .p-lg-23 {
    padding: 7.1875rem !important; }
  .p-lg-24 {
    padding: 7.5rem !important; }
  .p-lg-25 {
    padding: 7.8125rem !important; }
  .p-lg-26 {
    padding: 8.125rem !important; }
  .p-lg-27 {
    padding: 8.4375rem !important; }
  .p-lg-28 {
    padding: 8.75rem !important; }
  .p-lg-29 {
    padding: 9.0625rem !important; }
  .p-lg-30 {
    padding: 9.375rem !important; }
  .p-lg-31 {
    padding: 9.6875rem !important; }
  .p-lg-32 {
    padding: 10rem !important; }
  .p-lg-33 {
    padding: 10.3125rem !important; }
  .p-lg-34 {
    padding: 10.625rem !important; }
  .p-lg-35 {
    padding: 10.9375rem !important; }
  .p-lg-36 {
    padding: 11.25rem !important; }
  .p-lg-37 {
    padding: 11.5625rem !important; }
  .p-lg-38 {
    padding: 11.875rem !important; }
  .p-lg-39 {
    padding: 12.1875rem !important; }
  .p-lg-40 {
    padding: 12.5rem !important; }
  .p-lg-base {
    padding: calc(20rem / 16) !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important; }
  .px-lg-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important; }
  .px-lg-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important; }
  .px-lg-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important; }
  .px-lg-5 {
    padding-right: 1.5625rem !important;
    padding-left: 1.5625rem !important; }
  .px-lg-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important; }
  .px-lg-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important; }
  .px-lg-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-lg-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important; }
  .px-lg-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important; }
  .px-lg-11 {
    padding-right: 3.4375rem !important;
    padding-left: 3.4375rem !important; }
  .px-lg-12 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important; }
  .px-lg-13 {
    padding-right: 4.0625rem !important;
    padding-left: 4.0625rem !important; }
  .px-lg-14 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important; }
  .px-lg-15 {
    padding-right: 4.6875rem !important;
    padding-left: 4.6875rem !important; }
  .px-lg-16 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-lg-17 {
    padding-right: 5.3125rem !important;
    padding-left: 5.3125rem !important; }
  .px-lg-18 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important; }
  .px-lg-19 {
    padding-right: 5.9375rem !important;
    padding-left: 5.9375rem !important; }
  .px-lg-20 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important; }
  .px-lg-21 {
    padding-right: 6.5625rem !important;
    padding-left: 6.5625rem !important; }
  .px-lg-22 {
    padding-right: 6.875rem !important;
    padding-left: 6.875rem !important; }
  .px-lg-23 {
    padding-right: 7.1875rem !important;
    padding-left: 7.1875rem !important; }
  .px-lg-24 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-lg-25 {
    padding-right: 7.8125rem !important;
    padding-left: 7.8125rem !important; }
  .px-lg-26 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important; }
  .px-lg-27 {
    padding-right: 8.4375rem !important;
    padding-left: 8.4375rem !important; }
  .px-lg-28 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important; }
  .px-lg-29 {
    padding-right: 9.0625rem !important;
    padding-left: 9.0625rem !important; }
  .px-lg-30 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important; }
  .px-lg-31 {
    padding-right: 9.6875rem !important;
    padding-left: 9.6875rem !important; }
  .px-lg-32 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-lg-33 {
    padding-right: 10.3125rem !important;
    padding-left: 10.3125rem !important; }
  .px-lg-34 {
    padding-right: 10.625rem !important;
    padding-left: 10.625rem !important; }
  .px-lg-35 {
    padding-right: 10.9375rem !important;
    padding-left: 10.9375rem !important; }
  .px-lg-36 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important; }
  .px-lg-37 {
    padding-right: 11.5625rem !important;
    padding-left: 11.5625rem !important; }
  .px-lg-38 {
    padding-right: 11.875rem !important;
    padding-left: 11.875rem !important; }
  .px-lg-39 {
    padding-right: 12.1875rem !important;
    padding-left: 12.1875rem !important; }
  .px-lg-40 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-lg-base {
    padding-right: calc(20rem / 16) !important;
    padding-left: calc(20rem / 16) !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important; }
  .py-lg-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important; }
  .py-lg-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important; }
  .py-lg-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .py-lg-5 {
    padding-top: 1.5625rem !important;
    padding-bottom: 1.5625rem !important; }
  .py-lg-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important; }
  .py-lg-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important; }
  .py-lg-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-lg-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important; }
  .py-lg-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important; }
  .py-lg-11 {
    padding-top: 3.4375rem !important;
    padding-bottom: 3.4375rem !important; }
  .py-lg-12 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important; }
  .py-lg-13 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important; }
  .py-lg-14 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important; }
  .py-lg-15 {
    padding-top: 4.6875rem !important;
    padding-bottom: 4.6875rem !important; }
  .py-lg-16 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-lg-17 {
    padding-top: 5.3125rem !important;
    padding-bottom: 5.3125rem !important; }
  .py-lg-18 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important; }
  .py-lg-19 {
    padding-top: 5.9375rem !important;
    padding-bottom: 5.9375rem !important; }
  .py-lg-20 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important; }
  .py-lg-21 {
    padding-top: 6.5625rem !important;
    padding-bottom: 6.5625rem !important; }
  .py-lg-22 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important; }
  .py-lg-23 {
    padding-top: 7.1875rem !important;
    padding-bottom: 7.1875rem !important; }
  .py-lg-24 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-lg-25 {
    padding-top: 7.8125rem !important;
    padding-bottom: 7.8125rem !important; }
  .py-lg-26 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important; }
  .py-lg-27 {
    padding-top: 8.4375rem !important;
    padding-bottom: 8.4375rem !important; }
  .py-lg-28 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important; }
  .py-lg-29 {
    padding-top: 9.0625rem !important;
    padding-bottom: 9.0625rem !important; }
  .py-lg-30 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important; }
  .py-lg-31 {
    padding-top: 9.6875rem !important;
    padding-bottom: 9.6875rem !important; }
  .py-lg-32 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-lg-33 {
    padding-top: 10.3125rem !important;
    padding-bottom: 10.3125rem !important; }
  .py-lg-34 {
    padding-top: 10.625rem !important;
    padding-bottom: 10.625rem !important; }
  .py-lg-35 {
    padding-top: 10.9375rem !important;
    padding-bottom: 10.9375rem !important; }
  .py-lg-36 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important; }
  .py-lg-37 {
    padding-top: 11.5625rem !important;
    padding-bottom: 11.5625rem !important; }
  .py-lg-38 {
    padding-top: 11.875rem !important;
    padding-bottom: 11.875rem !important; }
  .py-lg-39 {
    padding-top: 12.1875rem !important;
    padding-bottom: 12.1875rem !important; }
  .py-lg-40 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-lg-base {
    padding-top: calc(20rem / 16) !important;
    padding-bottom: calc(20rem / 16) !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.3125rem !important; }
  .pt-lg-2 {
    padding-top: 0.625rem !important; }
  .pt-lg-3 {
    padding-top: 0.9375rem !important; }
  .pt-lg-4 {
    padding-top: 1.25rem !important; }
  .pt-lg-5 {
    padding-top: 1.5625rem !important; }
  .pt-lg-6 {
    padding-top: 1.875rem !important; }
  .pt-lg-7 {
    padding-top: 2.1875rem !important; }
  .pt-lg-8 {
    padding-top: 2.5rem !important; }
  .pt-lg-9 {
    padding-top: 2.8125rem !important; }
  .pt-lg-10 {
    padding-top: 3.125rem !important; }
  .pt-lg-11 {
    padding-top: 3.4375rem !important; }
  .pt-lg-12 {
    padding-top: 3.75rem !important; }
  .pt-lg-13 {
    padding-top: 4.0625rem !important; }
  .pt-lg-14 {
    padding-top: 4.375rem !important; }
  .pt-lg-15 {
    padding-top: 4.6875rem !important; }
  .pt-lg-16 {
    padding-top: 5rem !important; }
  .pt-lg-17 {
    padding-top: 5.3125rem !important; }
  .pt-lg-18 {
    padding-top: 5.625rem !important; }
  .pt-lg-19 {
    padding-top: 5.9375rem !important; }
  .pt-lg-20 {
    padding-top: 6.25rem !important; }
  .pt-lg-21 {
    padding-top: 6.5625rem !important; }
  .pt-lg-22 {
    padding-top: 6.875rem !important; }
  .pt-lg-23 {
    padding-top: 7.1875rem !important; }
  .pt-lg-24 {
    padding-top: 7.5rem !important; }
  .pt-lg-25 {
    padding-top: 7.8125rem !important; }
  .pt-lg-26 {
    padding-top: 8.125rem !important; }
  .pt-lg-27 {
    padding-top: 8.4375rem !important; }
  .pt-lg-28 {
    padding-top: 8.75rem !important; }
  .pt-lg-29 {
    padding-top: 9.0625rem !important; }
  .pt-lg-30 {
    padding-top: 9.375rem !important; }
  .pt-lg-31 {
    padding-top: 9.6875rem !important; }
  .pt-lg-32 {
    padding-top: 10rem !important; }
  .pt-lg-33 {
    padding-top: 10.3125rem !important; }
  .pt-lg-34 {
    padding-top: 10.625rem !important; }
  .pt-lg-35 {
    padding-top: 10.9375rem !important; }
  .pt-lg-36 {
    padding-top: 11.25rem !important; }
  .pt-lg-37 {
    padding-top: 11.5625rem !important; }
  .pt-lg-38 {
    padding-top: 11.875rem !important; }
  .pt-lg-39 {
    padding-top: 12.1875rem !important; }
  .pt-lg-40 {
    padding-top: 12.5rem !important; }
  .pt-lg-base {
    padding-top: calc(20rem / 16) !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.3125rem !important; }
  .pe-lg-2 {
    padding-right: 0.625rem !important; }
  .pe-lg-3 {
    padding-right: 0.9375rem !important; }
  .pe-lg-4 {
    padding-right: 1.25rem !important; }
  .pe-lg-5 {
    padding-right: 1.5625rem !important; }
  .pe-lg-6 {
    padding-right: 1.875rem !important; }
  .pe-lg-7 {
    padding-right: 2.1875rem !important; }
  .pe-lg-8 {
    padding-right: 2.5rem !important; }
  .pe-lg-9 {
    padding-right: 2.8125rem !important; }
  .pe-lg-10 {
    padding-right: 3.125rem !important; }
  .pe-lg-11 {
    padding-right: 3.4375rem !important; }
  .pe-lg-12 {
    padding-right: 3.75rem !important; }
  .pe-lg-13 {
    padding-right: 4.0625rem !important; }
  .pe-lg-14 {
    padding-right: 4.375rem !important; }
  .pe-lg-15 {
    padding-right: 4.6875rem !important; }
  .pe-lg-16 {
    padding-right: 5rem !important; }
  .pe-lg-17 {
    padding-right: 5.3125rem !important; }
  .pe-lg-18 {
    padding-right: 5.625rem !important; }
  .pe-lg-19 {
    padding-right: 5.9375rem !important; }
  .pe-lg-20 {
    padding-right: 6.25rem !important; }
  .pe-lg-21 {
    padding-right: 6.5625rem !important; }
  .pe-lg-22 {
    padding-right: 6.875rem !important; }
  .pe-lg-23 {
    padding-right: 7.1875rem !important; }
  .pe-lg-24 {
    padding-right: 7.5rem !important; }
  .pe-lg-25 {
    padding-right: 7.8125rem !important; }
  .pe-lg-26 {
    padding-right: 8.125rem !important; }
  .pe-lg-27 {
    padding-right: 8.4375rem !important; }
  .pe-lg-28 {
    padding-right: 8.75rem !important; }
  .pe-lg-29 {
    padding-right: 9.0625rem !important; }
  .pe-lg-30 {
    padding-right: 9.375rem !important; }
  .pe-lg-31 {
    padding-right: 9.6875rem !important; }
  .pe-lg-32 {
    padding-right: 10rem !important; }
  .pe-lg-33 {
    padding-right: 10.3125rem !important; }
  .pe-lg-34 {
    padding-right: 10.625rem !important; }
  .pe-lg-35 {
    padding-right: 10.9375rem !important; }
  .pe-lg-36 {
    padding-right: 11.25rem !important; }
  .pe-lg-37 {
    padding-right: 11.5625rem !important; }
  .pe-lg-38 {
    padding-right: 11.875rem !important; }
  .pe-lg-39 {
    padding-right: 12.1875rem !important; }
  .pe-lg-40 {
    padding-right: 12.5rem !important; }
  .pe-lg-base {
    padding-right: calc(20rem / 16) !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.3125rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.625rem !important; }
  .pb-lg-3 {
    padding-bottom: 0.9375rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.25rem !important; }
  .pb-lg-5 {
    padding-bottom: 1.5625rem !important; }
  .pb-lg-6 {
    padding-bottom: 1.875rem !important; }
  .pb-lg-7 {
    padding-bottom: 2.1875rem !important; }
  .pb-lg-8 {
    padding-bottom: 2.5rem !important; }
  .pb-lg-9 {
    padding-bottom: 2.8125rem !important; }
  .pb-lg-10 {
    padding-bottom: 3.125rem !important; }
  .pb-lg-11 {
    padding-bottom: 3.4375rem !important; }
  .pb-lg-12 {
    padding-bottom: 3.75rem !important; }
  .pb-lg-13 {
    padding-bottom: 4.0625rem !important; }
  .pb-lg-14 {
    padding-bottom: 4.375rem !important; }
  .pb-lg-15 {
    padding-bottom: 4.6875rem !important; }
  .pb-lg-16 {
    padding-bottom: 5rem !important; }
  .pb-lg-17 {
    padding-bottom: 5.3125rem !important; }
  .pb-lg-18 {
    padding-bottom: 5.625rem !important; }
  .pb-lg-19 {
    padding-bottom: 5.9375rem !important; }
  .pb-lg-20 {
    padding-bottom: 6.25rem !important; }
  .pb-lg-21 {
    padding-bottom: 6.5625rem !important; }
  .pb-lg-22 {
    padding-bottom: 6.875rem !important; }
  .pb-lg-23 {
    padding-bottom: 7.1875rem !important; }
  .pb-lg-24 {
    padding-bottom: 7.5rem !important; }
  .pb-lg-25 {
    padding-bottom: 7.8125rem !important; }
  .pb-lg-26 {
    padding-bottom: 8.125rem !important; }
  .pb-lg-27 {
    padding-bottom: 8.4375rem !important; }
  .pb-lg-28 {
    padding-bottom: 8.75rem !important; }
  .pb-lg-29 {
    padding-bottom: 9.0625rem !important; }
  .pb-lg-30 {
    padding-bottom: 9.375rem !important; }
  .pb-lg-31 {
    padding-bottom: 9.6875rem !important; }
  .pb-lg-32 {
    padding-bottom: 10rem !important; }
  .pb-lg-33 {
    padding-bottom: 10.3125rem !important; }
  .pb-lg-34 {
    padding-bottom: 10.625rem !important; }
  .pb-lg-35 {
    padding-bottom: 10.9375rem !important; }
  .pb-lg-36 {
    padding-bottom: 11.25rem !important; }
  .pb-lg-37 {
    padding-bottom: 11.5625rem !important; }
  .pb-lg-38 {
    padding-bottom: 11.875rem !important; }
  .pb-lg-39 {
    padding-bottom: 12.1875rem !important; }
  .pb-lg-40 {
    padding-bottom: 12.5rem !important; }
  .pb-lg-base {
    padding-bottom: calc(20rem / 16) !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.3125rem !important; }
  .ps-lg-2 {
    padding-left: 0.625rem !important; }
  .ps-lg-3 {
    padding-left: 0.9375rem !important; }
  .ps-lg-4 {
    padding-left: 1.25rem !important; }
  .ps-lg-5 {
    padding-left: 1.5625rem !important; }
  .ps-lg-6 {
    padding-left: 1.875rem !important; }
  .ps-lg-7 {
    padding-left: 2.1875rem !important; }
  .ps-lg-8 {
    padding-left: 2.5rem !important; }
  .ps-lg-9 {
    padding-left: 2.8125rem !important; }
  .ps-lg-10 {
    padding-left: 3.125rem !important; }
  .ps-lg-11 {
    padding-left: 3.4375rem !important; }
  .ps-lg-12 {
    padding-left: 3.75rem !important; }
  .ps-lg-13 {
    padding-left: 4.0625rem !important; }
  .ps-lg-14 {
    padding-left: 4.375rem !important; }
  .ps-lg-15 {
    padding-left: 4.6875rem !important; }
  .ps-lg-16 {
    padding-left: 5rem !important; }
  .ps-lg-17 {
    padding-left: 5.3125rem !important; }
  .ps-lg-18 {
    padding-left: 5.625rem !important; }
  .ps-lg-19 {
    padding-left: 5.9375rem !important; }
  .ps-lg-20 {
    padding-left: 6.25rem !important; }
  .ps-lg-21 {
    padding-left: 6.5625rem !important; }
  .ps-lg-22 {
    padding-left: 6.875rem !important; }
  .ps-lg-23 {
    padding-left: 7.1875rem !important; }
  .ps-lg-24 {
    padding-left: 7.5rem !important; }
  .ps-lg-25 {
    padding-left: 7.8125rem !important; }
  .ps-lg-26 {
    padding-left: 8.125rem !important; }
  .ps-lg-27 {
    padding-left: 8.4375rem !important; }
  .ps-lg-28 {
    padding-left: 8.75rem !important; }
  .ps-lg-29 {
    padding-left: 9.0625rem !important; }
  .ps-lg-30 {
    padding-left: 9.375rem !important; }
  .ps-lg-31 {
    padding-left: 9.6875rem !important; }
  .ps-lg-32 {
    padding-left: 10rem !important; }
  .ps-lg-33 {
    padding-left: 10.3125rem !important; }
  .ps-lg-34 {
    padding-left: 10.625rem !important; }
  .ps-lg-35 {
    padding-left: 10.9375rem !important; }
  .ps-lg-36 {
    padding-left: 11.25rem !important; }
  .ps-lg-37 {
    padding-left: 11.5625rem !important; }
  .ps-lg-38 {
    padding-left: 11.875rem !important; }
  .ps-lg-39 {
    padding-left: 12.1875rem !important; }
  .ps-lg-40 {
    padding-left: 12.5rem !important; }
  .ps-lg-base {
    padding-left: calc(20rem / 16) !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.3125rem !important; }
  .gap-lg-2 {
    gap: 0.625rem !important; }
  .gap-lg-3 {
    gap: 0.9375rem !important; }
  .gap-lg-4 {
    gap: 1.25rem !important; }
  .gap-lg-5 {
    gap: 1.5625rem !important; }
  .gap-lg-6 {
    gap: 1.875rem !important; }
  .gap-lg-7 {
    gap: 2.1875rem !important; }
  .gap-lg-8 {
    gap: 2.5rem !important; }
  .gap-lg-9 {
    gap: 2.8125rem !important; }
  .gap-lg-10 {
    gap: 3.125rem !important; }
  .gap-lg-11 {
    gap: 3.4375rem !important; }
  .gap-lg-12 {
    gap: 3.75rem !important; }
  .gap-lg-13 {
    gap: 4.0625rem !important; }
  .gap-lg-14 {
    gap: 4.375rem !important; }
  .gap-lg-15 {
    gap: 4.6875rem !important; }
  .gap-lg-16 {
    gap: 5rem !important; }
  .gap-lg-17 {
    gap: 5.3125rem !important; }
  .gap-lg-18 {
    gap: 5.625rem !important; }
  .gap-lg-19 {
    gap: 5.9375rem !important; }
  .gap-lg-20 {
    gap: 6.25rem !important; }
  .gap-lg-21 {
    gap: 6.5625rem !important; }
  .gap-lg-22 {
    gap: 6.875rem !important; }
  .gap-lg-23 {
    gap: 7.1875rem !important; }
  .gap-lg-24 {
    gap: 7.5rem !important; }
  .gap-lg-25 {
    gap: 7.8125rem !important; }
  .gap-lg-26 {
    gap: 8.125rem !important; }
  .gap-lg-27 {
    gap: 8.4375rem !important; }
  .gap-lg-28 {
    gap: 8.75rem !important; }
  .gap-lg-29 {
    gap: 9.0625rem !important; }
  .gap-lg-30 {
    gap: 9.375rem !important; }
  .gap-lg-31 {
    gap: 9.6875rem !important; }
  .gap-lg-32 {
    gap: 10rem !important; }
  .gap-lg-33 {
    gap: 10.3125rem !important; }
  .gap-lg-34 {
    gap: 10.625rem !important; }
  .gap-lg-35 {
    gap: 10.9375rem !important; }
  .gap-lg-36 {
    gap: 11.25rem !important; }
  .gap-lg-37 {
    gap: 11.5625rem !important; }
  .gap-lg-38 {
    gap: 11.875rem !important; }
  .gap-lg-39 {
    gap: 12.1875rem !important; }
  .gap-lg-40 {
    gap: 12.5rem !important; }
  .gap-lg-base {
    gap: calc(20rem / 16) !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.3125rem !important; }
  .m-xl-2 {
    margin: 0.625rem !important; }
  .m-xl-3 {
    margin: 0.9375rem !important; }
  .m-xl-4 {
    margin: 1.25rem !important; }
  .m-xl-5 {
    margin: 1.5625rem !important; }
  .m-xl-6 {
    margin: 1.875rem !important; }
  .m-xl-7 {
    margin: 2.1875rem !important; }
  .m-xl-8 {
    margin: 2.5rem !important; }
  .m-xl-9 {
    margin: 2.8125rem !important; }
  .m-xl-10 {
    margin: 3.125rem !important; }
  .m-xl-11 {
    margin: 3.4375rem !important; }
  .m-xl-12 {
    margin: 3.75rem !important; }
  .m-xl-13 {
    margin: 4.0625rem !important; }
  .m-xl-14 {
    margin: 4.375rem !important; }
  .m-xl-15 {
    margin: 4.6875rem !important; }
  .m-xl-16 {
    margin: 5rem !important; }
  .m-xl-17 {
    margin: 5.3125rem !important; }
  .m-xl-18 {
    margin: 5.625rem !important; }
  .m-xl-19 {
    margin: 5.9375rem !important; }
  .m-xl-20 {
    margin: 6.25rem !important; }
  .m-xl-21 {
    margin: 6.5625rem !important; }
  .m-xl-22 {
    margin: 6.875rem !important; }
  .m-xl-23 {
    margin: 7.1875rem !important; }
  .m-xl-24 {
    margin: 7.5rem !important; }
  .m-xl-25 {
    margin: 7.8125rem !important; }
  .m-xl-26 {
    margin: 8.125rem !important; }
  .m-xl-27 {
    margin: 8.4375rem !important; }
  .m-xl-28 {
    margin: 8.75rem !important; }
  .m-xl-29 {
    margin: 9.0625rem !important; }
  .m-xl-30 {
    margin: 9.375rem !important; }
  .m-xl-31 {
    margin: 9.6875rem !important; }
  .m-xl-32 {
    margin: 10rem !important; }
  .m-xl-33 {
    margin: 10.3125rem !important; }
  .m-xl-34 {
    margin: 10.625rem !important; }
  .m-xl-35 {
    margin: 10.9375rem !important; }
  .m-xl-36 {
    margin: 11.25rem !important; }
  .m-xl-37 {
    margin: 11.5625rem !important; }
  .m-xl-38 {
    margin: 11.875rem !important; }
  .m-xl-39 {
    margin: 12.1875rem !important; }
  .m-xl-40 {
    margin: 12.5rem !important; }
  .m-xl-base {
    margin: calc(20rem / 16) !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important; }
  .mx-xl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important; }
  .mx-xl-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important; }
  .mx-xl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important; }
  .mx-xl-5 {
    margin-right: 1.5625rem !important;
    margin-left: 1.5625rem !important; }
  .mx-xl-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important; }
  .mx-xl-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important; }
  .mx-xl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-xl-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important; }
  .mx-xl-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important; }
  .mx-xl-11 {
    margin-right: 3.4375rem !important;
    margin-left: 3.4375rem !important; }
  .mx-xl-12 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important; }
  .mx-xl-13 {
    margin-right: 4.0625rem !important;
    margin-left: 4.0625rem !important; }
  .mx-xl-14 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important; }
  .mx-xl-15 {
    margin-right: 4.6875rem !important;
    margin-left: 4.6875rem !important; }
  .mx-xl-16 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-xl-17 {
    margin-right: 5.3125rem !important;
    margin-left: 5.3125rem !important; }
  .mx-xl-18 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important; }
  .mx-xl-19 {
    margin-right: 5.9375rem !important;
    margin-left: 5.9375rem !important; }
  .mx-xl-20 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important; }
  .mx-xl-21 {
    margin-right: 6.5625rem !important;
    margin-left: 6.5625rem !important; }
  .mx-xl-22 {
    margin-right: 6.875rem !important;
    margin-left: 6.875rem !important; }
  .mx-xl-23 {
    margin-right: 7.1875rem !important;
    margin-left: 7.1875rem !important; }
  .mx-xl-24 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-xl-25 {
    margin-right: 7.8125rem !important;
    margin-left: 7.8125rem !important; }
  .mx-xl-26 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important; }
  .mx-xl-27 {
    margin-right: 8.4375rem !important;
    margin-left: 8.4375rem !important; }
  .mx-xl-28 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important; }
  .mx-xl-29 {
    margin-right: 9.0625rem !important;
    margin-left: 9.0625rem !important; }
  .mx-xl-30 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important; }
  .mx-xl-31 {
    margin-right: 9.6875rem !important;
    margin-left: 9.6875rem !important; }
  .mx-xl-32 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-xl-33 {
    margin-right: 10.3125rem !important;
    margin-left: 10.3125rem !important; }
  .mx-xl-34 {
    margin-right: 10.625rem !important;
    margin-left: 10.625rem !important; }
  .mx-xl-35 {
    margin-right: 10.9375rem !important;
    margin-left: 10.9375rem !important; }
  .mx-xl-36 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important; }
  .mx-xl-37 {
    margin-right: 11.5625rem !important;
    margin-left: 11.5625rem !important; }
  .mx-xl-38 {
    margin-right: 11.875rem !important;
    margin-left: 11.875rem !important; }
  .mx-xl-39 {
    margin-right: 12.1875rem !important;
    margin-left: 12.1875rem !important; }
  .mx-xl-40 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-xl-base {
    margin-right: calc(20rem / 16) !important;
    margin-left: calc(20rem / 16) !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important; }
  .my-xl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important; }
  .my-xl-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important; }
  .my-xl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .my-xl-5 {
    margin-top: 1.5625rem !important;
    margin-bottom: 1.5625rem !important; }
  .my-xl-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important; }
  .my-xl-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important; }
  .my-xl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-xl-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important; }
  .my-xl-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important; }
  .my-xl-11 {
    margin-top: 3.4375rem !important;
    margin-bottom: 3.4375rem !important; }
  .my-xl-12 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important; }
  .my-xl-13 {
    margin-top: 4.0625rem !important;
    margin-bottom: 4.0625rem !important; }
  .my-xl-14 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important; }
  .my-xl-15 {
    margin-top: 4.6875rem !important;
    margin-bottom: 4.6875rem !important; }
  .my-xl-16 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-xl-17 {
    margin-top: 5.3125rem !important;
    margin-bottom: 5.3125rem !important; }
  .my-xl-18 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important; }
  .my-xl-19 {
    margin-top: 5.9375rem !important;
    margin-bottom: 5.9375rem !important; }
  .my-xl-20 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important; }
  .my-xl-21 {
    margin-top: 6.5625rem !important;
    margin-bottom: 6.5625rem !important; }
  .my-xl-22 {
    margin-top: 6.875rem !important;
    margin-bottom: 6.875rem !important; }
  .my-xl-23 {
    margin-top: 7.1875rem !important;
    margin-bottom: 7.1875rem !important; }
  .my-xl-24 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-xl-25 {
    margin-top: 7.8125rem !important;
    margin-bottom: 7.8125rem !important; }
  .my-xl-26 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important; }
  .my-xl-27 {
    margin-top: 8.4375rem !important;
    margin-bottom: 8.4375rem !important; }
  .my-xl-28 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important; }
  .my-xl-29 {
    margin-top: 9.0625rem !important;
    margin-bottom: 9.0625rem !important; }
  .my-xl-30 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important; }
  .my-xl-31 {
    margin-top: 9.6875rem !important;
    margin-bottom: 9.6875rem !important; }
  .my-xl-32 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-xl-33 {
    margin-top: 10.3125rem !important;
    margin-bottom: 10.3125rem !important; }
  .my-xl-34 {
    margin-top: 10.625rem !important;
    margin-bottom: 10.625rem !important; }
  .my-xl-35 {
    margin-top: 10.9375rem !important;
    margin-bottom: 10.9375rem !important; }
  .my-xl-36 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important; }
  .my-xl-37 {
    margin-top: 11.5625rem !important;
    margin-bottom: 11.5625rem !important; }
  .my-xl-38 {
    margin-top: 11.875rem !important;
    margin-bottom: 11.875rem !important; }
  .my-xl-39 {
    margin-top: 12.1875rem !important;
    margin-bottom: 12.1875rem !important; }
  .my-xl-40 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-xl-base {
    margin-top: calc(20rem / 16) !important;
    margin-bottom: calc(20rem / 16) !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.3125rem !important; }
  .mt-xl-2 {
    margin-top: 0.625rem !important; }
  .mt-xl-3 {
    margin-top: 0.9375rem !important; }
  .mt-xl-4 {
    margin-top: 1.25rem !important; }
  .mt-xl-5 {
    margin-top: 1.5625rem !important; }
  .mt-xl-6 {
    margin-top: 1.875rem !important; }
  .mt-xl-7 {
    margin-top: 2.1875rem !important; }
  .mt-xl-8 {
    margin-top: 2.5rem !important; }
  .mt-xl-9 {
    margin-top: 2.8125rem !important; }
  .mt-xl-10 {
    margin-top: 3.125rem !important; }
  .mt-xl-11 {
    margin-top: 3.4375rem !important; }
  .mt-xl-12 {
    margin-top: 3.75rem !important; }
  .mt-xl-13 {
    margin-top: 4.0625rem !important; }
  .mt-xl-14 {
    margin-top: 4.375rem !important; }
  .mt-xl-15 {
    margin-top: 4.6875rem !important; }
  .mt-xl-16 {
    margin-top: 5rem !important; }
  .mt-xl-17 {
    margin-top: 5.3125rem !important; }
  .mt-xl-18 {
    margin-top: 5.625rem !important; }
  .mt-xl-19 {
    margin-top: 5.9375rem !important; }
  .mt-xl-20 {
    margin-top: 6.25rem !important; }
  .mt-xl-21 {
    margin-top: 6.5625rem !important; }
  .mt-xl-22 {
    margin-top: 6.875rem !important; }
  .mt-xl-23 {
    margin-top: 7.1875rem !important; }
  .mt-xl-24 {
    margin-top: 7.5rem !important; }
  .mt-xl-25 {
    margin-top: 7.8125rem !important; }
  .mt-xl-26 {
    margin-top: 8.125rem !important; }
  .mt-xl-27 {
    margin-top: 8.4375rem !important; }
  .mt-xl-28 {
    margin-top: 8.75rem !important; }
  .mt-xl-29 {
    margin-top: 9.0625rem !important; }
  .mt-xl-30 {
    margin-top: 9.375rem !important; }
  .mt-xl-31 {
    margin-top: 9.6875rem !important; }
  .mt-xl-32 {
    margin-top: 10rem !important; }
  .mt-xl-33 {
    margin-top: 10.3125rem !important; }
  .mt-xl-34 {
    margin-top: 10.625rem !important; }
  .mt-xl-35 {
    margin-top: 10.9375rem !important; }
  .mt-xl-36 {
    margin-top: 11.25rem !important; }
  .mt-xl-37 {
    margin-top: 11.5625rem !important; }
  .mt-xl-38 {
    margin-top: 11.875rem !important; }
  .mt-xl-39 {
    margin-top: 12.1875rem !important; }
  .mt-xl-40 {
    margin-top: 12.5rem !important; }
  .mt-xl-base {
    margin-top: calc(20rem / 16) !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.3125rem !important; }
  .me-xl-2 {
    margin-right: 0.625rem !important; }
  .me-xl-3 {
    margin-right: 0.9375rem !important; }
  .me-xl-4 {
    margin-right: 1.25rem !important; }
  .me-xl-5 {
    margin-right: 1.5625rem !important; }
  .me-xl-6 {
    margin-right: 1.875rem !important; }
  .me-xl-7 {
    margin-right: 2.1875rem !important; }
  .me-xl-8 {
    margin-right: 2.5rem !important; }
  .me-xl-9 {
    margin-right: 2.8125rem !important; }
  .me-xl-10 {
    margin-right: 3.125rem !important; }
  .me-xl-11 {
    margin-right: 3.4375rem !important; }
  .me-xl-12 {
    margin-right: 3.75rem !important; }
  .me-xl-13 {
    margin-right: 4.0625rem !important; }
  .me-xl-14 {
    margin-right: 4.375rem !important; }
  .me-xl-15 {
    margin-right: 4.6875rem !important; }
  .me-xl-16 {
    margin-right: 5rem !important; }
  .me-xl-17 {
    margin-right: 5.3125rem !important; }
  .me-xl-18 {
    margin-right: 5.625rem !important; }
  .me-xl-19 {
    margin-right: 5.9375rem !important; }
  .me-xl-20 {
    margin-right: 6.25rem !important; }
  .me-xl-21 {
    margin-right: 6.5625rem !important; }
  .me-xl-22 {
    margin-right: 6.875rem !important; }
  .me-xl-23 {
    margin-right: 7.1875rem !important; }
  .me-xl-24 {
    margin-right: 7.5rem !important; }
  .me-xl-25 {
    margin-right: 7.8125rem !important; }
  .me-xl-26 {
    margin-right: 8.125rem !important; }
  .me-xl-27 {
    margin-right: 8.4375rem !important; }
  .me-xl-28 {
    margin-right: 8.75rem !important; }
  .me-xl-29 {
    margin-right: 9.0625rem !important; }
  .me-xl-30 {
    margin-right: 9.375rem !important; }
  .me-xl-31 {
    margin-right: 9.6875rem !important; }
  .me-xl-32 {
    margin-right: 10rem !important; }
  .me-xl-33 {
    margin-right: 10.3125rem !important; }
  .me-xl-34 {
    margin-right: 10.625rem !important; }
  .me-xl-35 {
    margin-right: 10.9375rem !important; }
  .me-xl-36 {
    margin-right: 11.25rem !important; }
  .me-xl-37 {
    margin-right: 11.5625rem !important; }
  .me-xl-38 {
    margin-right: 11.875rem !important; }
  .me-xl-39 {
    margin-right: 12.1875rem !important; }
  .me-xl-40 {
    margin-right: 12.5rem !important; }
  .me-xl-base {
    margin-right: calc(20rem / 16) !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.3125rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.625rem !important; }
  .mb-xl-3 {
    margin-bottom: 0.9375rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.25rem !important; }
  .mb-xl-5 {
    margin-bottom: 1.5625rem !important; }
  .mb-xl-6 {
    margin-bottom: 1.875rem !important; }
  .mb-xl-7 {
    margin-bottom: 2.1875rem !important; }
  .mb-xl-8 {
    margin-bottom: 2.5rem !important; }
  .mb-xl-9 {
    margin-bottom: 2.8125rem !important; }
  .mb-xl-10 {
    margin-bottom: 3.125rem !important; }
  .mb-xl-11 {
    margin-bottom: 3.4375rem !important; }
  .mb-xl-12 {
    margin-bottom: 3.75rem !important; }
  .mb-xl-13 {
    margin-bottom: 4.0625rem !important; }
  .mb-xl-14 {
    margin-bottom: 4.375rem !important; }
  .mb-xl-15 {
    margin-bottom: 4.6875rem !important; }
  .mb-xl-16 {
    margin-bottom: 5rem !important; }
  .mb-xl-17 {
    margin-bottom: 5.3125rem !important; }
  .mb-xl-18 {
    margin-bottom: 5.625rem !important; }
  .mb-xl-19 {
    margin-bottom: 5.9375rem !important; }
  .mb-xl-20 {
    margin-bottom: 6.25rem !important; }
  .mb-xl-21 {
    margin-bottom: 6.5625rem !important; }
  .mb-xl-22 {
    margin-bottom: 6.875rem !important; }
  .mb-xl-23 {
    margin-bottom: 7.1875rem !important; }
  .mb-xl-24 {
    margin-bottom: 7.5rem !important; }
  .mb-xl-25 {
    margin-bottom: 7.8125rem !important; }
  .mb-xl-26 {
    margin-bottom: 8.125rem !important; }
  .mb-xl-27 {
    margin-bottom: 8.4375rem !important; }
  .mb-xl-28 {
    margin-bottom: 8.75rem !important; }
  .mb-xl-29 {
    margin-bottom: 9.0625rem !important; }
  .mb-xl-30 {
    margin-bottom: 9.375rem !important; }
  .mb-xl-31 {
    margin-bottom: 9.6875rem !important; }
  .mb-xl-32 {
    margin-bottom: 10rem !important; }
  .mb-xl-33 {
    margin-bottom: 10.3125rem !important; }
  .mb-xl-34 {
    margin-bottom: 10.625rem !important; }
  .mb-xl-35 {
    margin-bottom: 10.9375rem !important; }
  .mb-xl-36 {
    margin-bottom: 11.25rem !important; }
  .mb-xl-37 {
    margin-bottom: 11.5625rem !important; }
  .mb-xl-38 {
    margin-bottom: 11.875rem !important; }
  .mb-xl-39 {
    margin-bottom: 12.1875rem !important; }
  .mb-xl-40 {
    margin-bottom: 12.5rem !important; }
  .mb-xl-base {
    margin-bottom: calc(20rem / 16) !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.3125rem !important; }
  .ms-xl-2 {
    margin-left: 0.625rem !important; }
  .ms-xl-3 {
    margin-left: 0.9375rem !important; }
  .ms-xl-4 {
    margin-left: 1.25rem !important; }
  .ms-xl-5 {
    margin-left: 1.5625rem !important; }
  .ms-xl-6 {
    margin-left: 1.875rem !important; }
  .ms-xl-7 {
    margin-left: 2.1875rem !important; }
  .ms-xl-8 {
    margin-left: 2.5rem !important; }
  .ms-xl-9 {
    margin-left: 2.8125rem !important; }
  .ms-xl-10 {
    margin-left: 3.125rem !important; }
  .ms-xl-11 {
    margin-left: 3.4375rem !important; }
  .ms-xl-12 {
    margin-left: 3.75rem !important; }
  .ms-xl-13 {
    margin-left: 4.0625rem !important; }
  .ms-xl-14 {
    margin-left: 4.375rem !important; }
  .ms-xl-15 {
    margin-left: 4.6875rem !important; }
  .ms-xl-16 {
    margin-left: 5rem !important; }
  .ms-xl-17 {
    margin-left: 5.3125rem !important; }
  .ms-xl-18 {
    margin-left: 5.625rem !important; }
  .ms-xl-19 {
    margin-left: 5.9375rem !important; }
  .ms-xl-20 {
    margin-left: 6.25rem !important; }
  .ms-xl-21 {
    margin-left: 6.5625rem !important; }
  .ms-xl-22 {
    margin-left: 6.875rem !important; }
  .ms-xl-23 {
    margin-left: 7.1875rem !important; }
  .ms-xl-24 {
    margin-left: 7.5rem !important; }
  .ms-xl-25 {
    margin-left: 7.8125rem !important; }
  .ms-xl-26 {
    margin-left: 8.125rem !important; }
  .ms-xl-27 {
    margin-left: 8.4375rem !important; }
  .ms-xl-28 {
    margin-left: 8.75rem !important; }
  .ms-xl-29 {
    margin-left: 9.0625rem !important; }
  .ms-xl-30 {
    margin-left: 9.375rem !important; }
  .ms-xl-31 {
    margin-left: 9.6875rem !important; }
  .ms-xl-32 {
    margin-left: 10rem !important; }
  .ms-xl-33 {
    margin-left: 10.3125rem !important; }
  .ms-xl-34 {
    margin-left: 10.625rem !important; }
  .ms-xl-35 {
    margin-left: 10.9375rem !important; }
  .ms-xl-36 {
    margin-left: 11.25rem !important; }
  .ms-xl-37 {
    margin-left: 11.5625rem !important; }
  .ms-xl-38 {
    margin-left: 11.875rem !important; }
  .ms-xl-39 {
    margin-left: 12.1875rem !important; }
  .ms-xl-40 {
    margin-left: 12.5rem !important; }
  .ms-xl-base {
    margin-left: calc(20rem / 16) !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .m-xl-n1 {
    margin: -0.3125rem !important; }
  .m-xl-n2 {
    margin: -0.625rem !important; }
  .m-xl-n3 {
    margin: -0.9375rem !important; }
  .m-xl-n4 {
    margin: -1.25rem !important; }
  .m-xl-n5 {
    margin: -1.5625rem !important; }
  .m-xl-n6 {
    margin: -1.875rem !important; }
  .m-xl-n7 {
    margin: -2.1875rem !important; }
  .m-xl-n8 {
    margin: -2.5rem !important; }
  .m-xl-n9 {
    margin: -2.8125rem !important; }
  .m-xl-n10 {
    margin: -3.125rem !important; }
  .m-xl-n11 {
    margin: -3.4375rem !important; }
  .m-xl-n12 {
    margin: -3.75rem !important; }
  .m-xl-n13 {
    margin: -4.0625rem !important; }
  .m-xl-n14 {
    margin: -4.375rem !important; }
  .m-xl-n15 {
    margin: -4.6875rem !important; }
  .m-xl-n16 {
    margin: -5rem !important; }
  .m-xl-n17 {
    margin: -5.3125rem !important; }
  .m-xl-n18 {
    margin: -5.625rem !important; }
  .m-xl-n19 {
    margin: -5.9375rem !important; }
  .m-xl-n20 {
    margin: -6.25rem !important; }
  .m-xl-n21 {
    margin: -6.5625rem !important; }
  .m-xl-n22 {
    margin: -6.875rem !important; }
  .m-xl-n23 {
    margin: -7.1875rem !important; }
  .m-xl-n24 {
    margin: -7.5rem !important; }
  .m-xl-n25 {
    margin: -7.8125rem !important; }
  .m-xl-n26 {
    margin: -8.125rem !important; }
  .m-xl-n27 {
    margin: -8.4375rem !important; }
  .m-xl-n28 {
    margin: -8.75rem !important; }
  .m-xl-n29 {
    margin: -9.0625rem !important; }
  .m-xl-n30 {
    margin: -9.375rem !important; }
  .m-xl-n31 {
    margin: -9.6875rem !important; }
  .m-xl-n32 {
    margin: -10rem !important; }
  .m-xl-n33 {
    margin: -10.3125rem !important; }
  .m-xl-n34 {
    margin: -10.625rem !important; }
  .m-xl-n35 {
    margin: -10.9375rem !important; }
  .m-xl-n36 {
    margin: -11.25rem !important; }
  .m-xl-n37 {
    margin: -11.5625rem !important; }
  .m-xl-n38 {
    margin: -11.875rem !important; }
  .m-xl-n39 {
    margin: -12.1875rem !important; }
  .m-xl-n40 {
    margin: -12.5rem !important; }
  .m-xl-nbase {
    margin: -calc(20rem / 16) !important; }
  .mx-xl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important; }
  .mx-xl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important; }
  .mx-xl-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important; }
  .mx-xl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important; }
  .mx-xl-n5 {
    margin-right: -1.5625rem !important;
    margin-left: -1.5625rem !important; }
  .mx-xl-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important; }
  .mx-xl-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important; }
  .mx-xl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-xl-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important; }
  .mx-xl-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important; }
  .mx-xl-n11 {
    margin-right: -3.4375rem !important;
    margin-left: -3.4375rem !important; }
  .mx-xl-n12 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important; }
  .mx-xl-n13 {
    margin-right: -4.0625rem !important;
    margin-left: -4.0625rem !important; }
  .mx-xl-n14 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important; }
  .mx-xl-n15 {
    margin-right: -4.6875rem !important;
    margin-left: -4.6875rem !important; }
  .mx-xl-n16 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-xl-n17 {
    margin-right: -5.3125rem !important;
    margin-left: -5.3125rem !important; }
  .mx-xl-n18 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important; }
  .mx-xl-n19 {
    margin-right: -5.9375rem !important;
    margin-left: -5.9375rem !important; }
  .mx-xl-n20 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important; }
  .mx-xl-n21 {
    margin-right: -6.5625rem !important;
    margin-left: -6.5625rem !important; }
  .mx-xl-n22 {
    margin-right: -6.875rem !important;
    margin-left: -6.875rem !important; }
  .mx-xl-n23 {
    margin-right: -7.1875rem !important;
    margin-left: -7.1875rem !important; }
  .mx-xl-n24 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-xl-n25 {
    margin-right: -7.8125rem !important;
    margin-left: -7.8125rem !important; }
  .mx-xl-n26 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important; }
  .mx-xl-n27 {
    margin-right: -8.4375rem !important;
    margin-left: -8.4375rem !important; }
  .mx-xl-n28 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important; }
  .mx-xl-n29 {
    margin-right: -9.0625rem !important;
    margin-left: -9.0625rem !important; }
  .mx-xl-n30 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important; }
  .mx-xl-n31 {
    margin-right: -9.6875rem !important;
    margin-left: -9.6875rem !important; }
  .mx-xl-n32 {
    margin-right: -10rem !important;
    margin-left: -10rem !important; }
  .mx-xl-n33 {
    margin-right: -10.3125rem !important;
    margin-left: -10.3125rem !important; }
  .mx-xl-n34 {
    margin-right: -10.625rem !important;
    margin-left: -10.625rem !important; }
  .mx-xl-n35 {
    margin-right: -10.9375rem !important;
    margin-left: -10.9375rem !important; }
  .mx-xl-n36 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important; }
  .mx-xl-n37 {
    margin-right: -11.5625rem !important;
    margin-left: -11.5625rem !important; }
  .mx-xl-n38 {
    margin-right: -11.875rem !important;
    margin-left: -11.875rem !important; }
  .mx-xl-n39 {
    margin-right: -12.1875rem !important;
    margin-left: -12.1875rem !important; }
  .mx-xl-n40 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important; }
  .mx-xl-nbase {
    margin-right: -calc(20rem / 16) !important;
    margin-left: -calc(20rem / 16) !important; }
  .my-xl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important; }
  .my-xl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important; }
  .my-xl-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important; }
  .my-xl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .my-xl-n5 {
    margin-top: -1.5625rem !important;
    margin-bottom: -1.5625rem !important; }
  .my-xl-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important; }
  .my-xl-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important; }
  .my-xl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-xl-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important; }
  .my-xl-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important; }
  .my-xl-n11 {
    margin-top: -3.4375rem !important;
    margin-bottom: -3.4375rem !important; }
  .my-xl-n12 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important; }
  .my-xl-n13 {
    margin-top: -4.0625rem !important;
    margin-bottom: -4.0625rem !important; }
  .my-xl-n14 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important; }
  .my-xl-n15 {
    margin-top: -4.6875rem !important;
    margin-bottom: -4.6875rem !important; }
  .my-xl-n16 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-xl-n17 {
    margin-top: -5.3125rem !important;
    margin-bottom: -5.3125rem !important; }
  .my-xl-n18 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important; }
  .my-xl-n19 {
    margin-top: -5.9375rem !important;
    margin-bottom: -5.9375rem !important; }
  .my-xl-n20 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important; }
  .my-xl-n21 {
    margin-top: -6.5625rem !important;
    margin-bottom: -6.5625rem !important; }
  .my-xl-n22 {
    margin-top: -6.875rem !important;
    margin-bottom: -6.875rem !important; }
  .my-xl-n23 {
    margin-top: -7.1875rem !important;
    margin-bottom: -7.1875rem !important; }
  .my-xl-n24 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-xl-n25 {
    margin-top: -7.8125rem !important;
    margin-bottom: -7.8125rem !important; }
  .my-xl-n26 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important; }
  .my-xl-n27 {
    margin-top: -8.4375rem !important;
    margin-bottom: -8.4375rem !important; }
  .my-xl-n28 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important; }
  .my-xl-n29 {
    margin-top: -9.0625rem !important;
    margin-bottom: -9.0625rem !important; }
  .my-xl-n30 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important; }
  .my-xl-n31 {
    margin-top: -9.6875rem !important;
    margin-bottom: -9.6875rem !important; }
  .my-xl-n32 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .my-xl-n33 {
    margin-top: -10.3125rem !important;
    margin-bottom: -10.3125rem !important; }
  .my-xl-n34 {
    margin-top: -10.625rem !important;
    margin-bottom: -10.625rem !important; }
  .my-xl-n35 {
    margin-top: -10.9375rem !important;
    margin-bottom: -10.9375rem !important; }
  .my-xl-n36 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important; }
  .my-xl-n37 {
    margin-top: -11.5625rem !important;
    margin-bottom: -11.5625rem !important; }
  .my-xl-n38 {
    margin-top: -11.875rem !important;
    margin-bottom: -11.875rem !important; }
  .my-xl-n39 {
    margin-top: -12.1875rem !important;
    margin-bottom: -12.1875rem !important; }
  .my-xl-n40 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important; }
  .my-xl-nbase {
    margin-top: -calc(20rem / 16) !important;
    margin-bottom: -calc(20rem / 16) !important; }
  .mt-xl-n1 {
    margin-top: -0.3125rem !important; }
  .mt-xl-n2 {
    margin-top: -0.625rem !important; }
  .mt-xl-n3 {
    margin-top: -0.9375rem !important; }
  .mt-xl-n4 {
    margin-top: -1.25rem !important; }
  .mt-xl-n5 {
    margin-top: -1.5625rem !important; }
  .mt-xl-n6 {
    margin-top: -1.875rem !important; }
  .mt-xl-n7 {
    margin-top: -2.1875rem !important; }
  .mt-xl-n8 {
    margin-top: -2.5rem !important; }
  .mt-xl-n9 {
    margin-top: -2.8125rem !important; }
  .mt-xl-n10 {
    margin-top: -3.125rem !important; }
  .mt-xl-n11 {
    margin-top: -3.4375rem !important; }
  .mt-xl-n12 {
    margin-top: -3.75rem !important; }
  .mt-xl-n13 {
    margin-top: -4.0625rem !important; }
  .mt-xl-n14 {
    margin-top: -4.375rem !important; }
  .mt-xl-n15 {
    margin-top: -4.6875rem !important; }
  .mt-xl-n16 {
    margin-top: -5rem !important; }
  .mt-xl-n17 {
    margin-top: -5.3125rem !important; }
  .mt-xl-n18 {
    margin-top: -5.625rem !important; }
  .mt-xl-n19 {
    margin-top: -5.9375rem !important; }
  .mt-xl-n20 {
    margin-top: -6.25rem !important; }
  .mt-xl-n21 {
    margin-top: -6.5625rem !important; }
  .mt-xl-n22 {
    margin-top: -6.875rem !important; }
  .mt-xl-n23 {
    margin-top: -7.1875rem !important; }
  .mt-xl-n24 {
    margin-top: -7.5rem !important; }
  .mt-xl-n25 {
    margin-top: -7.8125rem !important; }
  .mt-xl-n26 {
    margin-top: -8.125rem !important; }
  .mt-xl-n27 {
    margin-top: -8.4375rem !important; }
  .mt-xl-n28 {
    margin-top: -8.75rem !important; }
  .mt-xl-n29 {
    margin-top: -9.0625rem !important; }
  .mt-xl-n30 {
    margin-top: -9.375rem !important; }
  .mt-xl-n31 {
    margin-top: -9.6875rem !important; }
  .mt-xl-n32 {
    margin-top: -10rem !important; }
  .mt-xl-n33 {
    margin-top: -10.3125rem !important; }
  .mt-xl-n34 {
    margin-top: -10.625rem !important; }
  .mt-xl-n35 {
    margin-top: -10.9375rem !important; }
  .mt-xl-n36 {
    margin-top: -11.25rem !important; }
  .mt-xl-n37 {
    margin-top: -11.5625rem !important; }
  .mt-xl-n38 {
    margin-top: -11.875rem !important; }
  .mt-xl-n39 {
    margin-top: -12.1875rem !important; }
  .mt-xl-n40 {
    margin-top: -12.5rem !important; }
  .mt-xl-nbase {
    margin-top: -calc(20rem / 16) !important; }
  .me-xl-n1 {
    margin-right: -0.3125rem !important; }
  .me-xl-n2 {
    margin-right: -0.625rem !important; }
  .me-xl-n3 {
    margin-right: -0.9375rem !important; }
  .me-xl-n4 {
    margin-right: -1.25rem !important; }
  .me-xl-n5 {
    margin-right: -1.5625rem !important; }
  .me-xl-n6 {
    margin-right: -1.875rem !important; }
  .me-xl-n7 {
    margin-right: -2.1875rem !important; }
  .me-xl-n8 {
    margin-right: -2.5rem !important; }
  .me-xl-n9 {
    margin-right: -2.8125rem !important; }
  .me-xl-n10 {
    margin-right: -3.125rem !important; }
  .me-xl-n11 {
    margin-right: -3.4375rem !important; }
  .me-xl-n12 {
    margin-right: -3.75rem !important; }
  .me-xl-n13 {
    margin-right: -4.0625rem !important; }
  .me-xl-n14 {
    margin-right: -4.375rem !important; }
  .me-xl-n15 {
    margin-right: -4.6875rem !important; }
  .me-xl-n16 {
    margin-right: -5rem !important; }
  .me-xl-n17 {
    margin-right: -5.3125rem !important; }
  .me-xl-n18 {
    margin-right: -5.625rem !important; }
  .me-xl-n19 {
    margin-right: -5.9375rem !important; }
  .me-xl-n20 {
    margin-right: -6.25rem !important; }
  .me-xl-n21 {
    margin-right: -6.5625rem !important; }
  .me-xl-n22 {
    margin-right: -6.875rem !important; }
  .me-xl-n23 {
    margin-right: -7.1875rem !important; }
  .me-xl-n24 {
    margin-right: -7.5rem !important; }
  .me-xl-n25 {
    margin-right: -7.8125rem !important; }
  .me-xl-n26 {
    margin-right: -8.125rem !important; }
  .me-xl-n27 {
    margin-right: -8.4375rem !important; }
  .me-xl-n28 {
    margin-right: -8.75rem !important; }
  .me-xl-n29 {
    margin-right: -9.0625rem !important; }
  .me-xl-n30 {
    margin-right: -9.375rem !important; }
  .me-xl-n31 {
    margin-right: -9.6875rem !important; }
  .me-xl-n32 {
    margin-right: -10rem !important; }
  .me-xl-n33 {
    margin-right: -10.3125rem !important; }
  .me-xl-n34 {
    margin-right: -10.625rem !important; }
  .me-xl-n35 {
    margin-right: -10.9375rem !important; }
  .me-xl-n36 {
    margin-right: -11.25rem !important; }
  .me-xl-n37 {
    margin-right: -11.5625rem !important; }
  .me-xl-n38 {
    margin-right: -11.875rem !important; }
  .me-xl-n39 {
    margin-right: -12.1875rem !important; }
  .me-xl-n40 {
    margin-right: -12.5rem !important; }
  .me-xl-nbase {
    margin-right: -calc(20rem / 16) !important; }
  .mb-xl-n1 {
    margin-bottom: -0.3125rem !important; }
  .mb-xl-n2 {
    margin-bottom: -0.625rem !important; }
  .mb-xl-n3 {
    margin-bottom: -0.9375rem !important; }
  .mb-xl-n4 {
    margin-bottom: -1.25rem !important; }
  .mb-xl-n5 {
    margin-bottom: -1.5625rem !important; }
  .mb-xl-n6 {
    margin-bottom: -1.875rem !important; }
  .mb-xl-n7 {
    margin-bottom: -2.1875rem !important; }
  .mb-xl-n8 {
    margin-bottom: -2.5rem !important; }
  .mb-xl-n9 {
    margin-bottom: -2.8125rem !important; }
  .mb-xl-n10 {
    margin-bottom: -3.125rem !important; }
  .mb-xl-n11 {
    margin-bottom: -3.4375rem !important; }
  .mb-xl-n12 {
    margin-bottom: -3.75rem !important; }
  .mb-xl-n13 {
    margin-bottom: -4.0625rem !important; }
  .mb-xl-n14 {
    margin-bottom: -4.375rem !important; }
  .mb-xl-n15 {
    margin-bottom: -4.6875rem !important; }
  .mb-xl-n16 {
    margin-bottom: -5rem !important; }
  .mb-xl-n17 {
    margin-bottom: -5.3125rem !important; }
  .mb-xl-n18 {
    margin-bottom: -5.625rem !important; }
  .mb-xl-n19 {
    margin-bottom: -5.9375rem !important; }
  .mb-xl-n20 {
    margin-bottom: -6.25rem !important; }
  .mb-xl-n21 {
    margin-bottom: -6.5625rem !important; }
  .mb-xl-n22 {
    margin-bottom: -6.875rem !important; }
  .mb-xl-n23 {
    margin-bottom: -7.1875rem !important; }
  .mb-xl-n24 {
    margin-bottom: -7.5rem !important; }
  .mb-xl-n25 {
    margin-bottom: -7.8125rem !important; }
  .mb-xl-n26 {
    margin-bottom: -8.125rem !important; }
  .mb-xl-n27 {
    margin-bottom: -8.4375rem !important; }
  .mb-xl-n28 {
    margin-bottom: -8.75rem !important; }
  .mb-xl-n29 {
    margin-bottom: -9.0625rem !important; }
  .mb-xl-n30 {
    margin-bottom: -9.375rem !important; }
  .mb-xl-n31 {
    margin-bottom: -9.6875rem !important; }
  .mb-xl-n32 {
    margin-bottom: -10rem !important; }
  .mb-xl-n33 {
    margin-bottom: -10.3125rem !important; }
  .mb-xl-n34 {
    margin-bottom: -10.625rem !important; }
  .mb-xl-n35 {
    margin-bottom: -10.9375rem !important; }
  .mb-xl-n36 {
    margin-bottom: -11.25rem !important; }
  .mb-xl-n37 {
    margin-bottom: -11.5625rem !important; }
  .mb-xl-n38 {
    margin-bottom: -11.875rem !important; }
  .mb-xl-n39 {
    margin-bottom: -12.1875rem !important; }
  .mb-xl-n40 {
    margin-bottom: -12.5rem !important; }
  .mb-xl-nbase {
    margin-bottom: -calc(20rem / 16) !important; }
  .ms-xl-n1 {
    margin-left: -0.3125rem !important; }
  .ms-xl-n2 {
    margin-left: -0.625rem !important; }
  .ms-xl-n3 {
    margin-left: -0.9375rem !important; }
  .ms-xl-n4 {
    margin-left: -1.25rem !important; }
  .ms-xl-n5 {
    margin-left: -1.5625rem !important; }
  .ms-xl-n6 {
    margin-left: -1.875rem !important; }
  .ms-xl-n7 {
    margin-left: -2.1875rem !important; }
  .ms-xl-n8 {
    margin-left: -2.5rem !important; }
  .ms-xl-n9 {
    margin-left: -2.8125rem !important; }
  .ms-xl-n10 {
    margin-left: -3.125rem !important; }
  .ms-xl-n11 {
    margin-left: -3.4375rem !important; }
  .ms-xl-n12 {
    margin-left: -3.75rem !important; }
  .ms-xl-n13 {
    margin-left: -4.0625rem !important; }
  .ms-xl-n14 {
    margin-left: -4.375rem !important; }
  .ms-xl-n15 {
    margin-left: -4.6875rem !important; }
  .ms-xl-n16 {
    margin-left: -5rem !important; }
  .ms-xl-n17 {
    margin-left: -5.3125rem !important; }
  .ms-xl-n18 {
    margin-left: -5.625rem !important; }
  .ms-xl-n19 {
    margin-left: -5.9375rem !important; }
  .ms-xl-n20 {
    margin-left: -6.25rem !important; }
  .ms-xl-n21 {
    margin-left: -6.5625rem !important; }
  .ms-xl-n22 {
    margin-left: -6.875rem !important; }
  .ms-xl-n23 {
    margin-left: -7.1875rem !important; }
  .ms-xl-n24 {
    margin-left: -7.5rem !important; }
  .ms-xl-n25 {
    margin-left: -7.8125rem !important; }
  .ms-xl-n26 {
    margin-left: -8.125rem !important; }
  .ms-xl-n27 {
    margin-left: -8.4375rem !important; }
  .ms-xl-n28 {
    margin-left: -8.75rem !important; }
  .ms-xl-n29 {
    margin-left: -9.0625rem !important; }
  .ms-xl-n30 {
    margin-left: -9.375rem !important; }
  .ms-xl-n31 {
    margin-left: -9.6875rem !important; }
  .ms-xl-n32 {
    margin-left: -10rem !important; }
  .ms-xl-n33 {
    margin-left: -10.3125rem !important; }
  .ms-xl-n34 {
    margin-left: -10.625rem !important; }
  .ms-xl-n35 {
    margin-left: -10.9375rem !important; }
  .ms-xl-n36 {
    margin-left: -11.25rem !important; }
  .ms-xl-n37 {
    margin-left: -11.5625rem !important; }
  .ms-xl-n38 {
    margin-left: -11.875rem !important; }
  .ms-xl-n39 {
    margin-left: -12.1875rem !important; }
  .ms-xl-n40 {
    margin-left: -12.5rem !important; }
  .ms-xl-nbase {
    margin-left: -calc(20rem / 16) !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.3125rem !important; }
  .p-xl-2 {
    padding: 0.625rem !important; }
  .p-xl-3 {
    padding: 0.9375rem !important; }
  .p-xl-4 {
    padding: 1.25rem !important; }
  .p-xl-5 {
    padding: 1.5625rem !important; }
  .p-xl-6 {
    padding: 1.875rem !important; }
  .p-xl-7 {
    padding: 2.1875rem !important; }
  .p-xl-8 {
    padding: 2.5rem !important; }
  .p-xl-9 {
    padding: 2.8125rem !important; }
  .p-xl-10 {
    padding: 3.125rem !important; }
  .p-xl-11 {
    padding: 3.4375rem !important; }
  .p-xl-12 {
    padding: 3.75rem !important; }
  .p-xl-13 {
    padding: 4.0625rem !important; }
  .p-xl-14 {
    padding: 4.375rem !important; }
  .p-xl-15 {
    padding: 4.6875rem !important; }
  .p-xl-16 {
    padding: 5rem !important; }
  .p-xl-17 {
    padding: 5.3125rem !important; }
  .p-xl-18 {
    padding: 5.625rem !important; }
  .p-xl-19 {
    padding: 5.9375rem !important; }
  .p-xl-20 {
    padding: 6.25rem !important; }
  .p-xl-21 {
    padding: 6.5625rem !important; }
  .p-xl-22 {
    padding: 6.875rem !important; }
  .p-xl-23 {
    padding: 7.1875rem !important; }
  .p-xl-24 {
    padding: 7.5rem !important; }
  .p-xl-25 {
    padding: 7.8125rem !important; }
  .p-xl-26 {
    padding: 8.125rem !important; }
  .p-xl-27 {
    padding: 8.4375rem !important; }
  .p-xl-28 {
    padding: 8.75rem !important; }
  .p-xl-29 {
    padding: 9.0625rem !important; }
  .p-xl-30 {
    padding: 9.375rem !important; }
  .p-xl-31 {
    padding: 9.6875rem !important; }
  .p-xl-32 {
    padding: 10rem !important; }
  .p-xl-33 {
    padding: 10.3125rem !important; }
  .p-xl-34 {
    padding: 10.625rem !important; }
  .p-xl-35 {
    padding: 10.9375rem !important; }
  .p-xl-36 {
    padding: 11.25rem !important; }
  .p-xl-37 {
    padding: 11.5625rem !important; }
  .p-xl-38 {
    padding: 11.875rem !important; }
  .p-xl-39 {
    padding: 12.1875rem !important; }
  .p-xl-40 {
    padding: 12.5rem !important; }
  .p-xl-base {
    padding: calc(20rem / 16) !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important; }
  .px-xl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important; }
  .px-xl-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important; }
  .px-xl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important; }
  .px-xl-5 {
    padding-right: 1.5625rem !important;
    padding-left: 1.5625rem !important; }
  .px-xl-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important; }
  .px-xl-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important; }
  .px-xl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-xl-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important; }
  .px-xl-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important; }
  .px-xl-11 {
    padding-right: 3.4375rem !important;
    padding-left: 3.4375rem !important; }
  .px-xl-12 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important; }
  .px-xl-13 {
    padding-right: 4.0625rem !important;
    padding-left: 4.0625rem !important; }
  .px-xl-14 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important; }
  .px-xl-15 {
    padding-right: 4.6875rem !important;
    padding-left: 4.6875rem !important; }
  .px-xl-16 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-xl-17 {
    padding-right: 5.3125rem !important;
    padding-left: 5.3125rem !important; }
  .px-xl-18 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important; }
  .px-xl-19 {
    padding-right: 5.9375rem !important;
    padding-left: 5.9375rem !important; }
  .px-xl-20 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important; }
  .px-xl-21 {
    padding-right: 6.5625rem !important;
    padding-left: 6.5625rem !important; }
  .px-xl-22 {
    padding-right: 6.875rem !important;
    padding-left: 6.875rem !important; }
  .px-xl-23 {
    padding-right: 7.1875rem !important;
    padding-left: 7.1875rem !important; }
  .px-xl-24 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-xl-25 {
    padding-right: 7.8125rem !important;
    padding-left: 7.8125rem !important; }
  .px-xl-26 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important; }
  .px-xl-27 {
    padding-right: 8.4375rem !important;
    padding-left: 8.4375rem !important; }
  .px-xl-28 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important; }
  .px-xl-29 {
    padding-right: 9.0625rem !important;
    padding-left: 9.0625rem !important; }
  .px-xl-30 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important; }
  .px-xl-31 {
    padding-right: 9.6875rem !important;
    padding-left: 9.6875rem !important; }
  .px-xl-32 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-xl-33 {
    padding-right: 10.3125rem !important;
    padding-left: 10.3125rem !important; }
  .px-xl-34 {
    padding-right: 10.625rem !important;
    padding-left: 10.625rem !important; }
  .px-xl-35 {
    padding-right: 10.9375rem !important;
    padding-left: 10.9375rem !important; }
  .px-xl-36 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important; }
  .px-xl-37 {
    padding-right: 11.5625rem !important;
    padding-left: 11.5625rem !important; }
  .px-xl-38 {
    padding-right: 11.875rem !important;
    padding-left: 11.875rem !important; }
  .px-xl-39 {
    padding-right: 12.1875rem !important;
    padding-left: 12.1875rem !important; }
  .px-xl-40 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-xl-base {
    padding-right: calc(20rem / 16) !important;
    padding-left: calc(20rem / 16) !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important; }
  .py-xl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important; }
  .py-xl-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important; }
  .py-xl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .py-xl-5 {
    padding-top: 1.5625rem !important;
    padding-bottom: 1.5625rem !important; }
  .py-xl-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important; }
  .py-xl-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important; }
  .py-xl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-xl-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important; }
  .py-xl-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important; }
  .py-xl-11 {
    padding-top: 3.4375rem !important;
    padding-bottom: 3.4375rem !important; }
  .py-xl-12 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important; }
  .py-xl-13 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important; }
  .py-xl-14 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important; }
  .py-xl-15 {
    padding-top: 4.6875rem !important;
    padding-bottom: 4.6875rem !important; }
  .py-xl-16 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-xl-17 {
    padding-top: 5.3125rem !important;
    padding-bottom: 5.3125rem !important; }
  .py-xl-18 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important; }
  .py-xl-19 {
    padding-top: 5.9375rem !important;
    padding-bottom: 5.9375rem !important; }
  .py-xl-20 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important; }
  .py-xl-21 {
    padding-top: 6.5625rem !important;
    padding-bottom: 6.5625rem !important; }
  .py-xl-22 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important; }
  .py-xl-23 {
    padding-top: 7.1875rem !important;
    padding-bottom: 7.1875rem !important; }
  .py-xl-24 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-xl-25 {
    padding-top: 7.8125rem !important;
    padding-bottom: 7.8125rem !important; }
  .py-xl-26 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important; }
  .py-xl-27 {
    padding-top: 8.4375rem !important;
    padding-bottom: 8.4375rem !important; }
  .py-xl-28 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important; }
  .py-xl-29 {
    padding-top: 9.0625rem !important;
    padding-bottom: 9.0625rem !important; }
  .py-xl-30 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important; }
  .py-xl-31 {
    padding-top: 9.6875rem !important;
    padding-bottom: 9.6875rem !important; }
  .py-xl-32 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-xl-33 {
    padding-top: 10.3125rem !important;
    padding-bottom: 10.3125rem !important; }
  .py-xl-34 {
    padding-top: 10.625rem !important;
    padding-bottom: 10.625rem !important; }
  .py-xl-35 {
    padding-top: 10.9375rem !important;
    padding-bottom: 10.9375rem !important; }
  .py-xl-36 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important; }
  .py-xl-37 {
    padding-top: 11.5625rem !important;
    padding-bottom: 11.5625rem !important; }
  .py-xl-38 {
    padding-top: 11.875rem !important;
    padding-bottom: 11.875rem !important; }
  .py-xl-39 {
    padding-top: 12.1875rem !important;
    padding-bottom: 12.1875rem !important; }
  .py-xl-40 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-xl-base {
    padding-top: calc(20rem / 16) !important;
    padding-bottom: calc(20rem / 16) !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.3125rem !important; }
  .pt-xl-2 {
    padding-top: 0.625rem !important; }
  .pt-xl-3 {
    padding-top: 0.9375rem !important; }
  .pt-xl-4 {
    padding-top: 1.25rem !important; }
  .pt-xl-5 {
    padding-top: 1.5625rem !important; }
  .pt-xl-6 {
    padding-top: 1.875rem !important; }
  .pt-xl-7 {
    padding-top: 2.1875rem !important; }
  .pt-xl-8 {
    padding-top: 2.5rem !important; }
  .pt-xl-9 {
    padding-top: 2.8125rem !important; }
  .pt-xl-10 {
    padding-top: 3.125rem !important; }
  .pt-xl-11 {
    padding-top: 3.4375rem !important; }
  .pt-xl-12 {
    padding-top: 3.75rem !important; }
  .pt-xl-13 {
    padding-top: 4.0625rem !important; }
  .pt-xl-14 {
    padding-top: 4.375rem !important; }
  .pt-xl-15 {
    padding-top: 4.6875rem !important; }
  .pt-xl-16 {
    padding-top: 5rem !important; }
  .pt-xl-17 {
    padding-top: 5.3125rem !important; }
  .pt-xl-18 {
    padding-top: 5.625rem !important; }
  .pt-xl-19 {
    padding-top: 5.9375rem !important; }
  .pt-xl-20 {
    padding-top: 6.25rem !important; }
  .pt-xl-21 {
    padding-top: 6.5625rem !important; }
  .pt-xl-22 {
    padding-top: 6.875rem !important; }
  .pt-xl-23 {
    padding-top: 7.1875rem !important; }
  .pt-xl-24 {
    padding-top: 7.5rem !important; }
  .pt-xl-25 {
    padding-top: 7.8125rem !important; }
  .pt-xl-26 {
    padding-top: 8.125rem !important; }
  .pt-xl-27 {
    padding-top: 8.4375rem !important; }
  .pt-xl-28 {
    padding-top: 8.75rem !important; }
  .pt-xl-29 {
    padding-top: 9.0625rem !important; }
  .pt-xl-30 {
    padding-top: 9.375rem !important; }
  .pt-xl-31 {
    padding-top: 9.6875rem !important; }
  .pt-xl-32 {
    padding-top: 10rem !important; }
  .pt-xl-33 {
    padding-top: 10.3125rem !important; }
  .pt-xl-34 {
    padding-top: 10.625rem !important; }
  .pt-xl-35 {
    padding-top: 10.9375rem !important; }
  .pt-xl-36 {
    padding-top: 11.25rem !important; }
  .pt-xl-37 {
    padding-top: 11.5625rem !important; }
  .pt-xl-38 {
    padding-top: 11.875rem !important; }
  .pt-xl-39 {
    padding-top: 12.1875rem !important; }
  .pt-xl-40 {
    padding-top: 12.5rem !important; }
  .pt-xl-base {
    padding-top: calc(20rem / 16) !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.3125rem !important; }
  .pe-xl-2 {
    padding-right: 0.625rem !important; }
  .pe-xl-3 {
    padding-right: 0.9375rem !important; }
  .pe-xl-4 {
    padding-right: 1.25rem !important; }
  .pe-xl-5 {
    padding-right: 1.5625rem !important; }
  .pe-xl-6 {
    padding-right: 1.875rem !important; }
  .pe-xl-7 {
    padding-right: 2.1875rem !important; }
  .pe-xl-8 {
    padding-right: 2.5rem !important; }
  .pe-xl-9 {
    padding-right: 2.8125rem !important; }
  .pe-xl-10 {
    padding-right: 3.125rem !important; }
  .pe-xl-11 {
    padding-right: 3.4375rem !important; }
  .pe-xl-12 {
    padding-right: 3.75rem !important; }
  .pe-xl-13 {
    padding-right: 4.0625rem !important; }
  .pe-xl-14 {
    padding-right: 4.375rem !important; }
  .pe-xl-15 {
    padding-right: 4.6875rem !important; }
  .pe-xl-16 {
    padding-right: 5rem !important; }
  .pe-xl-17 {
    padding-right: 5.3125rem !important; }
  .pe-xl-18 {
    padding-right: 5.625rem !important; }
  .pe-xl-19 {
    padding-right: 5.9375rem !important; }
  .pe-xl-20 {
    padding-right: 6.25rem !important; }
  .pe-xl-21 {
    padding-right: 6.5625rem !important; }
  .pe-xl-22 {
    padding-right: 6.875rem !important; }
  .pe-xl-23 {
    padding-right: 7.1875rem !important; }
  .pe-xl-24 {
    padding-right: 7.5rem !important; }
  .pe-xl-25 {
    padding-right: 7.8125rem !important; }
  .pe-xl-26 {
    padding-right: 8.125rem !important; }
  .pe-xl-27 {
    padding-right: 8.4375rem !important; }
  .pe-xl-28 {
    padding-right: 8.75rem !important; }
  .pe-xl-29 {
    padding-right: 9.0625rem !important; }
  .pe-xl-30 {
    padding-right: 9.375rem !important; }
  .pe-xl-31 {
    padding-right: 9.6875rem !important; }
  .pe-xl-32 {
    padding-right: 10rem !important; }
  .pe-xl-33 {
    padding-right: 10.3125rem !important; }
  .pe-xl-34 {
    padding-right: 10.625rem !important; }
  .pe-xl-35 {
    padding-right: 10.9375rem !important; }
  .pe-xl-36 {
    padding-right: 11.25rem !important; }
  .pe-xl-37 {
    padding-right: 11.5625rem !important; }
  .pe-xl-38 {
    padding-right: 11.875rem !important; }
  .pe-xl-39 {
    padding-right: 12.1875rem !important; }
  .pe-xl-40 {
    padding-right: 12.5rem !important; }
  .pe-xl-base {
    padding-right: calc(20rem / 16) !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.3125rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.625rem !important; }
  .pb-xl-3 {
    padding-bottom: 0.9375rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.25rem !important; }
  .pb-xl-5 {
    padding-bottom: 1.5625rem !important; }
  .pb-xl-6 {
    padding-bottom: 1.875rem !important; }
  .pb-xl-7 {
    padding-bottom: 2.1875rem !important; }
  .pb-xl-8 {
    padding-bottom: 2.5rem !important; }
  .pb-xl-9 {
    padding-bottom: 2.8125rem !important; }
  .pb-xl-10 {
    padding-bottom: 3.125rem !important; }
  .pb-xl-11 {
    padding-bottom: 3.4375rem !important; }
  .pb-xl-12 {
    padding-bottom: 3.75rem !important; }
  .pb-xl-13 {
    padding-bottom: 4.0625rem !important; }
  .pb-xl-14 {
    padding-bottom: 4.375rem !important; }
  .pb-xl-15 {
    padding-bottom: 4.6875rem !important; }
  .pb-xl-16 {
    padding-bottom: 5rem !important; }
  .pb-xl-17 {
    padding-bottom: 5.3125rem !important; }
  .pb-xl-18 {
    padding-bottom: 5.625rem !important; }
  .pb-xl-19 {
    padding-bottom: 5.9375rem !important; }
  .pb-xl-20 {
    padding-bottom: 6.25rem !important; }
  .pb-xl-21 {
    padding-bottom: 6.5625rem !important; }
  .pb-xl-22 {
    padding-bottom: 6.875rem !important; }
  .pb-xl-23 {
    padding-bottom: 7.1875rem !important; }
  .pb-xl-24 {
    padding-bottom: 7.5rem !important; }
  .pb-xl-25 {
    padding-bottom: 7.8125rem !important; }
  .pb-xl-26 {
    padding-bottom: 8.125rem !important; }
  .pb-xl-27 {
    padding-bottom: 8.4375rem !important; }
  .pb-xl-28 {
    padding-bottom: 8.75rem !important; }
  .pb-xl-29 {
    padding-bottom: 9.0625rem !important; }
  .pb-xl-30 {
    padding-bottom: 9.375rem !important; }
  .pb-xl-31 {
    padding-bottom: 9.6875rem !important; }
  .pb-xl-32 {
    padding-bottom: 10rem !important; }
  .pb-xl-33 {
    padding-bottom: 10.3125rem !important; }
  .pb-xl-34 {
    padding-bottom: 10.625rem !important; }
  .pb-xl-35 {
    padding-bottom: 10.9375rem !important; }
  .pb-xl-36 {
    padding-bottom: 11.25rem !important; }
  .pb-xl-37 {
    padding-bottom: 11.5625rem !important; }
  .pb-xl-38 {
    padding-bottom: 11.875rem !important; }
  .pb-xl-39 {
    padding-bottom: 12.1875rem !important; }
  .pb-xl-40 {
    padding-bottom: 12.5rem !important; }
  .pb-xl-base {
    padding-bottom: calc(20rem / 16) !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.3125rem !important; }
  .ps-xl-2 {
    padding-left: 0.625rem !important; }
  .ps-xl-3 {
    padding-left: 0.9375rem !important; }
  .ps-xl-4 {
    padding-left: 1.25rem !important; }
  .ps-xl-5 {
    padding-left: 1.5625rem !important; }
  .ps-xl-6 {
    padding-left: 1.875rem !important; }
  .ps-xl-7 {
    padding-left: 2.1875rem !important; }
  .ps-xl-8 {
    padding-left: 2.5rem !important; }
  .ps-xl-9 {
    padding-left: 2.8125rem !important; }
  .ps-xl-10 {
    padding-left: 3.125rem !important; }
  .ps-xl-11 {
    padding-left: 3.4375rem !important; }
  .ps-xl-12 {
    padding-left: 3.75rem !important; }
  .ps-xl-13 {
    padding-left: 4.0625rem !important; }
  .ps-xl-14 {
    padding-left: 4.375rem !important; }
  .ps-xl-15 {
    padding-left: 4.6875rem !important; }
  .ps-xl-16 {
    padding-left: 5rem !important; }
  .ps-xl-17 {
    padding-left: 5.3125rem !important; }
  .ps-xl-18 {
    padding-left: 5.625rem !important; }
  .ps-xl-19 {
    padding-left: 5.9375rem !important; }
  .ps-xl-20 {
    padding-left: 6.25rem !important; }
  .ps-xl-21 {
    padding-left: 6.5625rem !important; }
  .ps-xl-22 {
    padding-left: 6.875rem !important; }
  .ps-xl-23 {
    padding-left: 7.1875rem !important; }
  .ps-xl-24 {
    padding-left: 7.5rem !important; }
  .ps-xl-25 {
    padding-left: 7.8125rem !important; }
  .ps-xl-26 {
    padding-left: 8.125rem !important; }
  .ps-xl-27 {
    padding-left: 8.4375rem !important; }
  .ps-xl-28 {
    padding-left: 8.75rem !important; }
  .ps-xl-29 {
    padding-left: 9.0625rem !important; }
  .ps-xl-30 {
    padding-left: 9.375rem !important; }
  .ps-xl-31 {
    padding-left: 9.6875rem !important; }
  .ps-xl-32 {
    padding-left: 10rem !important; }
  .ps-xl-33 {
    padding-left: 10.3125rem !important; }
  .ps-xl-34 {
    padding-left: 10.625rem !important; }
  .ps-xl-35 {
    padding-left: 10.9375rem !important; }
  .ps-xl-36 {
    padding-left: 11.25rem !important; }
  .ps-xl-37 {
    padding-left: 11.5625rem !important; }
  .ps-xl-38 {
    padding-left: 11.875rem !important; }
  .ps-xl-39 {
    padding-left: 12.1875rem !important; }
  .ps-xl-40 {
    padding-left: 12.5rem !important; }
  .ps-xl-base {
    padding-left: calc(20rem / 16) !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.3125rem !important; }
  .gap-xl-2 {
    gap: 0.625rem !important; }
  .gap-xl-3 {
    gap: 0.9375rem !important; }
  .gap-xl-4 {
    gap: 1.25rem !important; }
  .gap-xl-5 {
    gap: 1.5625rem !important; }
  .gap-xl-6 {
    gap: 1.875rem !important; }
  .gap-xl-7 {
    gap: 2.1875rem !important; }
  .gap-xl-8 {
    gap: 2.5rem !important; }
  .gap-xl-9 {
    gap: 2.8125rem !important; }
  .gap-xl-10 {
    gap: 3.125rem !important; }
  .gap-xl-11 {
    gap: 3.4375rem !important; }
  .gap-xl-12 {
    gap: 3.75rem !important; }
  .gap-xl-13 {
    gap: 4.0625rem !important; }
  .gap-xl-14 {
    gap: 4.375rem !important; }
  .gap-xl-15 {
    gap: 4.6875rem !important; }
  .gap-xl-16 {
    gap: 5rem !important; }
  .gap-xl-17 {
    gap: 5.3125rem !important; }
  .gap-xl-18 {
    gap: 5.625rem !important; }
  .gap-xl-19 {
    gap: 5.9375rem !important; }
  .gap-xl-20 {
    gap: 6.25rem !important; }
  .gap-xl-21 {
    gap: 6.5625rem !important; }
  .gap-xl-22 {
    gap: 6.875rem !important; }
  .gap-xl-23 {
    gap: 7.1875rem !important; }
  .gap-xl-24 {
    gap: 7.5rem !important; }
  .gap-xl-25 {
    gap: 7.8125rem !important; }
  .gap-xl-26 {
    gap: 8.125rem !important; }
  .gap-xl-27 {
    gap: 8.4375rem !important; }
  .gap-xl-28 {
    gap: 8.75rem !important; }
  .gap-xl-29 {
    gap: 9.0625rem !important; }
  .gap-xl-30 {
    gap: 9.375rem !important; }
  .gap-xl-31 {
    gap: 9.6875rem !important; }
  .gap-xl-32 {
    gap: 10rem !important; }
  .gap-xl-33 {
    gap: 10.3125rem !important; }
  .gap-xl-34 {
    gap: 10.625rem !important; }
  .gap-xl-35 {
    gap: 10.9375rem !important; }
  .gap-xl-36 {
    gap: 11.25rem !important; }
  .gap-xl-37 {
    gap: 11.5625rem !important; }
  .gap-xl-38 {
    gap: 11.875rem !important; }
  .gap-xl-39 {
    gap: 12.1875rem !important; }
  .gap-xl-40 {
    gap: 12.5rem !important; }
  .gap-xl-base {
    gap: calc(20rem / 16) !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.3125rem !important; }
  .m-xxl-2 {
    margin: 0.625rem !important; }
  .m-xxl-3 {
    margin: 0.9375rem !important; }
  .m-xxl-4 {
    margin: 1.25rem !important; }
  .m-xxl-5 {
    margin: 1.5625rem !important; }
  .m-xxl-6 {
    margin: 1.875rem !important; }
  .m-xxl-7 {
    margin: 2.1875rem !important; }
  .m-xxl-8 {
    margin: 2.5rem !important; }
  .m-xxl-9 {
    margin: 2.8125rem !important; }
  .m-xxl-10 {
    margin: 3.125rem !important; }
  .m-xxl-11 {
    margin: 3.4375rem !important; }
  .m-xxl-12 {
    margin: 3.75rem !important; }
  .m-xxl-13 {
    margin: 4.0625rem !important; }
  .m-xxl-14 {
    margin: 4.375rem !important; }
  .m-xxl-15 {
    margin: 4.6875rem !important; }
  .m-xxl-16 {
    margin: 5rem !important; }
  .m-xxl-17 {
    margin: 5.3125rem !important; }
  .m-xxl-18 {
    margin: 5.625rem !important; }
  .m-xxl-19 {
    margin: 5.9375rem !important; }
  .m-xxl-20 {
    margin: 6.25rem !important; }
  .m-xxl-21 {
    margin: 6.5625rem !important; }
  .m-xxl-22 {
    margin: 6.875rem !important; }
  .m-xxl-23 {
    margin: 7.1875rem !important; }
  .m-xxl-24 {
    margin: 7.5rem !important; }
  .m-xxl-25 {
    margin: 7.8125rem !important; }
  .m-xxl-26 {
    margin: 8.125rem !important; }
  .m-xxl-27 {
    margin: 8.4375rem !important; }
  .m-xxl-28 {
    margin: 8.75rem !important; }
  .m-xxl-29 {
    margin: 9.0625rem !important; }
  .m-xxl-30 {
    margin: 9.375rem !important; }
  .m-xxl-31 {
    margin: 9.6875rem !important; }
  .m-xxl-32 {
    margin: 10rem !important; }
  .m-xxl-33 {
    margin: 10.3125rem !important; }
  .m-xxl-34 {
    margin: 10.625rem !important; }
  .m-xxl-35 {
    margin: 10.9375rem !important; }
  .m-xxl-36 {
    margin: 11.25rem !important; }
  .m-xxl-37 {
    margin: 11.5625rem !important; }
  .m-xxl-38 {
    margin: 11.875rem !important; }
  .m-xxl-39 {
    margin: 12.1875rem !important; }
  .m-xxl-40 {
    margin: 12.5rem !important; }
  .m-xxl-base {
    margin: calc(20rem / 16) !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important; }
  .mx-xxl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important; }
  .mx-xxl-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important; }
  .mx-xxl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important; }
  .mx-xxl-5 {
    margin-right: 1.5625rem !important;
    margin-left: 1.5625rem !important; }
  .mx-xxl-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important; }
  .mx-xxl-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important; }
  .mx-xxl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-xxl-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important; }
  .mx-xxl-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important; }
  .mx-xxl-11 {
    margin-right: 3.4375rem !important;
    margin-left: 3.4375rem !important; }
  .mx-xxl-12 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important; }
  .mx-xxl-13 {
    margin-right: 4.0625rem !important;
    margin-left: 4.0625rem !important; }
  .mx-xxl-14 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important; }
  .mx-xxl-15 {
    margin-right: 4.6875rem !important;
    margin-left: 4.6875rem !important; }
  .mx-xxl-16 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-xxl-17 {
    margin-right: 5.3125rem !important;
    margin-left: 5.3125rem !important; }
  .mx-xxl-18 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important; }
  .mx-xxl-19 {
    margin-right: 5.9375rem !important;
    margin-left: 5.9375rem !important; }
  .mx-xxl-20 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important; }
  .mx-xxl-21 {
    margin-right: 6.5625rem !important;
    margin-left: 6.5625rem !important; }
  .mx-xxl-22 {
    margin-right: 6.875rem !important;
    margin-left: 6.875rem !important; }
  .mx-xxl-23 {
    margin-right: 7.1875rem !important;
    margin-left: 7.1875rem !important; }
  .mx-xxl-24 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-xxl-25 {
    margin-right: 7.8125rem !important;
    margin-left: 7.8125rem !important; }
  .mx-xxl-26 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important; }
  .mx-xxl-27 {
    margin-right: 8.4375rem !important;
    margin-left: 8.4375rem !important; }
  .mx-xxl-28 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important; }
  .mx-xxl-29 {
    margin-right: 9.0625rem !important;
    margin-left: 9.0625rem !important; }
  .mx-xxl-30 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important; }
  .mx-xxl-31 {
    margin-right: 9.6875rem !important;
    margin-left: 9.6875rem !important; }
  .mx-xxl-32 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-xxl-33 {
    margin-right: 10.3125rem !important;
    margin-left: 10.3125rem !important; }
  .mx-xxl-34 {
    margin-right: 10.625rem !important;
    margin-left: 10.625rem !important; }
  .mx-xxl-35 {
    margin-right: 10.9375rem !important;
    margin-left: 10.9375rem !important; }
  .mx-xxl-36 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important; }
  .mx-xxl-37 {
    margin-right: 11.5625rem !important;
    margin-left: 11.5625rem !important; }
  .mx-xxl-38 {
    margin-right: 11.875rem !important;
    margin-left: 11.875rem !important; }
  .mx-xxl-39 {
    margin-right: 12.1875rem !important;
    margin-left: 12.1875rem !important; }
  .mx-xxl-40 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-xxl-base {
    margin-right: calc(20rem / 16) !important;
    margin-left: calc(20rem / 16) !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important; }
  .my-xxl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important; }
  .my-xxl-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important; }
  .my-xxl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .my-xxl-5 {
    margin-top: 1.5625rem !important;
    margin-bottom: 1.5625rem !important; }
  .my-xxl-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important; }
  .my-xxl-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important; }
  .my-xxl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-xxl-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important; }
  .my-xxl-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important; }
  .my-xxl-11 {
    margin-top: 3.4375rem !important;
    margin-bottom: 3.4375rem !important; }
  .my-xxl-12 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important; }
  .my-xxl-13 {
    margin-top: 4.0625rem !important;
    margin-bottom: 4.0625rem !important; }
  .my-xxl-14 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important; }
  .my-xxl-15 {
    margin-top: 4.6875rem !important;
    margin-bottom: 4.6875rem !important; }
  .my-xxl-16 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-xxl-17 {
    margin-top: 5.3125rem !important;
    margin-bottom: 5.3125rem !important; }
  .my-xxl-18 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important; }
  .my-xxl-19 {
    margin-top: 5.9375rem !important;
    margin-bottom: 5.9375rem !important; }
  .my-xxl-20 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important; }
  .my-xxl-21 {
    margin-top: 6.5625rem !important;
    margin-bottom: 6.5625rem !important; }
  .my-xxl-22 {
    margin-top: 6.875rem !important;
    margin-bottom: 6.875rem !important; }
  .my-xxl-23 {
    margin-top: 7.1875rem !important;
    margin-bottom: 7.1875rem !important; }
  .my-xxl-24 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-xxl-25 {
    margin-top: 7.8125rem !important;
    margin-bottom: 7.8125rem !important; }
  .my-xxl-26 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important; }
  .my-xxl-27 {
    margin-top: 8.4375rem !important;
    margin-bottom: 8.4375rem !important; }
  .my-xxl-28 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important; }
  .my-xxl-29 {
    margin-top: 9.0625rem !important;
    margin-bottom: 9.0625rem !important; }
  .my-xxl-30 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important; }
  .my-xxl-31 {
    margin-top: 9.6875rem !important;
    margin-bottom: 9.6875rem !important; }
  .my-xxl-32 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-xxl-33 {
    margin-top: 10.3125rem !important;
    margin-bottom: 10.3125rem !important; }
  .my-xxl-34 {
    margin-top: 10.625rem !important;
    margin-bottom: 10.625rem !important; }
  .my-xxl-35 {
    margin-top: 10.9375rem !important;
    margin-bottom: 10.9375rem !important; }
  .my-xxl-36 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important; }
  .my-xxl-37 {
    margin-top: 11.5625rem !important;
    margin-bottom: 11.5625rem !important; }
  .my-xxl-38 {
    margin-top: 11.875rem !important;
    margin-bottom: 11.875rem !important; }
  .my-xxl-39 {
    margin-top: 12.1875rem !important;
    margin-bottom: 12.1875rem !important; }
  .my-xxl-40 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-xxl-base {
    margin-top: calc(20rem / 16) !important;
    margin-bottom: calc(20rem / 16) !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.3125rem !important; }
  .mt-xxl-2 {
    margin-top: 0.625rem !important; }
  .mt-xxl-3 {
    margin-top: 0.9375rem !important; }
  .mt-xxl-4 {
    margin-top: 1.25rem !important; }
  .mt-xxl-5 {
    margin-top: 1.5625rem !important; }
  .mt-xxl-6 {
    margin-top: 1.875rem !important; }
  .mt-xxl-7 {
    margin-top: 2.1875rem !important; }
  .mt-xxl-8 {
    margin-top: 2.5rem !important; }
  .mt-xxl-9 {
    margin-top: 2.8125rem !important; }
  .mt-xxl-10 {
    margin-top: 3.125rem !important; }
  .mt-xxl-11 {
    margin-top: 3.4375rem !important; }
  .mt-xxl-12 {
    margin-top: 3.75rem !important; }
  .mt-xxl-13 {
    margin-top: 4.0625rem !important; }
  .mt-xxl-14 {
    margin-top: 4.375rem !important; }
  .mt-xxl-15 {
    margin-top: 4.6875rem !important; }
  .mt-xxl-16 {
    margin-top: 5rem !important; }
  .mt-xxl-17 {
    margin-top: 5.3125rem !important; }
  .mt-xxl-18 {
    margin-top: 5.625rem !important; }
  .mt-xxl-19 {
    margin-top: 5.9375rem !important; }
  .mt-xxl-20 {
    margin-top: 6.25rem !important; }
  .mt-xxl-21 {
    margin-top: 6.5625rem !important; }
  .mt-xxl-22 {
    margin-top: 6.875rem !important; }
  .mt-xxl-23 {
    margin-top: 7.1875rem !important; }
  .mt-xxl-24 {
    margin-top: 7.5rem !important; }
  .mt-xxl-25 {
    margin-top: 7.8125rem !important; }
  .mt-xxl-26 {
    margin-top: 8.125rem !important; }
  .mt-xxl-27 {
    margin-top: 8.4375rem !important; }
  .mt-xxl-28 {
    margin-top: 8.75rem !important; }
  .mt-xxl-29 {
    margin-top: 9.0625rem !important; }
  .mt-xxl-30 {
    margin-top: 9.375rem !important; }
  .mt-xxl-31 {
    margin-top: 9.6875rem !important; }
  .mt-xxl-32 {
    margin-top: 10rem !important; }
  .mt-xxl-33 {
    margin-top: 10.3125rem !important; }
  .mt-xxl-34 {
    margin-top: 10.625rem !important; }
  .mt-xxl-35 {
    margin-top: 10.9375rem !important; }
  .mt-xxl-36 {
    margin-top: 11.25rem !important; }
  .mt-xxl-37 {
    margin-top: 11.5625rem !important; }
  .mt-xxl-38 {
    margin-top: 11.875rem !important; }
  .mt-xxl-39 {
    margin-top: 12.1875rem !important; }
  .mt-xxl-40 {
    margin-top: 12.5rem !important; }
  .mt-xxl-base {
    margin-top: calc(20rem / 16) !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.3125rem !important; }
  .me-xxl-2 {
    margin-right: 0.625rem !important; }
  .me-xxl-3 {
    margin-right: 0.9375rem !important; }
  .me-xxl-4 {
    margin-right: 1.25rem !important; }
  .me-xxl-5 {
    margin-right: 1.5625rem !important; }
  .me-xxl-6 {
    margin-right: 1.875rem !important; }
  .me-xxl-7 {
    margin-right: 2.1875rem !important; }
  .me-xxl-8 {
    margin-right: 2.5rem !important; }
  .me-xxl-9 {
    margin-right: 2.8125rem !important; }
  .me-xxl-10 {
    margin-right: 3.125rem !important; }
  .me-xxl-11 {
    margin-right: 3.4375rem !important; }
  .me-xxl-12 {
    margin-right: 3.75rem !important; }
  .me-xxl-13 {
    margin-right: 4.0625rem !important; }
  .me-xxl-14 {
    margin-right: 4.375rem !important; }
  .me-xxl-15 {
    margin-right: 4.6875rem !important; }
  .me-xxl-16 {
    margin-right: 5rem !important; }
  .me-xxl-17 {
    margin-right: 5.3125rem !important; }
  .me-xxl-18 {
    margin-right: 5.625rem !important; }
  .me-xxl-19 {
    margin-right: 5.9375rem !important; }
  .me-xxl-20 {
    margin-right: 6.25rem !important; }
  .me-xxl-21 {
    margin-right: 6.5625rem !important; }
  .me-xxl-22 {
    margin-right: 6.875rem !important; }
  .me-xxl-23 {
    margin-right: 7.1875rem !important; }
  .me-xxl-24 {
    margin-right: 7.5rem !important; }
  .me-xxl-25 {
    margin-right: 7.8125rem !important; }
  .me-xxl-26 {
    margin-right: 8.125rem !important; }
  .me-xxl-27 {
    margin-right: 8.4375rem !important; }
  .me-xxl-28 {
    margin-right: 8.75rem !important; }
  .me-xxl-29 {
    margin-right: 9.0625rem !important; }
  .me-xxl-30 {
    margin-right: 9.375rem !important; }
  .me-xxl-31 {
    margin-right: 9.6875rem !important; }
  .me-xxl-32 {
    margin-right: 10rem !important; }
  .me-xxl-33 {
    margin-right: 10.3125rem !important; }
  .me-xxl-34 {
    margin-right: 10.625rem !important; }
  .me-xxl-35 {
    margin-right: 10.9375rem !important; }
  .me-xxl-36 {
    margin-right: 11.25rem !important; }
  .me-xxl-37 {
    margin-right: 11.5625rem !important; }
  .me-xxl-38 {
    margin-right: 11.875rem !important; }
  .me-xxl-39 {
    margin-right: 12.1875rem !important; }
  .me-xxl-40 {
    margin-right: 12.5rem !important; }
  .me-xxl-base {
    margin-right: calc(20rem / 16) !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.3125rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.625rem !important; }
  .mb-xxl-3 {
    margin-bottom: 0.9375rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.25rem !important; }
  .mb-xxl-5 {
    margin-bottom: 1.5625rem !important; }
  .mb-xxl-6 {
    margin-bottom: 1.875rem !important; }
  .mb-xxl-7 {
    margin-bottom: 2.1875rem !important; }
  .mb-xxl-8 {
    margin-bottom: 2.5rem !important; }
  .mb-xxl-9 {
    margin-bottom: 2.8125rem !important; }
  .mb-xxl-10 {
    margin-bottom: 3.125rem !important; }
  .mb-xxl-11 {
    margin-bottom: 3.4375rem !important; }
  .mb-xxl-12 {
    margin-bottom: 3.75rem !important; }
  .mb-xxl-13 {
    margin-bottom: 4.0625rem !important; }
  .mb-xxl-14 {
    margin-bottom: 4.375rem !important; }
  .mb-xxl-15 {
    margin-bottom: 4.6875rem !important; }
  .mb-xxl-16 {
    margin-bottom: 5rem !important; }
  .mb-xxl-17 {
    margin-bottom: 5.3125rem !important; }
  .mb-xxl-18 {
    margin-bottom: 5.625rem !important; }
  .mb-xxl-19 {
    margin-bottom: 5.9375rem !important; }
  .mb-xxl-20 {
    margin-bottom: 6.25rem !important; }
  .mb-xxl-21 {
    margin-bottom: 6.5625rem !important; }
  .mb-xxl-22 {
    margin-bottom: 6.875rem !important; }
  .mb-xxl-23 {
    margin-bottom: 7.1875rem !important; }
  .mb-xxl-24 {
    margin-bottom: 7.5rem !important; }
  .mb-xxl-25 {
    margin-bottom: 7.8125rem !important; }
  .mb-xxl-26 {
    margin-bottom: 8.125rem !important; }
  .mb-xxl-27 {
    margin-bottom: 8.4375rem !important; }
  .mb-xxl-28 {
    margin-bottom: 8.75rem !important; }
  .mb-xxl-29 {
    margin-bottom: 9.0625rem !important; }
  .mb-xxl-30 {
    margin-bottom: 9.375rem !important; }
  .mb-xxl-31 {
    margin-bottom: 9.6875rem !important; }
  .mb-xxl-32 {
    margin-bottom: 10rem !important; }
  .mb-xxl-33 {
    margin-bottom: 10.3125rem !important; }
  .mb-xxl-34 {
    margin-bottom: 10.625rem !important; }
  .mb-xxl-35 {
    margin-bottom: 10.9375rem !important; }
  .mb-xxl-36 {
    margin-bottom: 11.25rem !important; }
  .mb-xxl-37 {
    margin-bottom: 11.5625rem !important; }
  .mb-xxl-38 {
    margin-bottom: 11.875rem !important; }
  .mb-xxl-39 {
    margin-bottom: 12.1875rem !important; }
  .mb-xxl-40 {
    margin-bottom: 12.5rem !important; }
  .mb-xxl-base {
    margin-bottom: calc(20rem / 16) !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.3125rem !important; }
  .ms-xxl-2 {
    margin-left: 0.625rem !important; }
  .ms-xxl-3 {
    margin-left: 0.9375rem !important; }
  .ms-xxl-4 {
    margin-left: 1.25rem !important; }
  .ms-xxl-5 {
    margin-left: 1.5625rem !important; }
  .ms-xxl-6 {
    margin-left: 1.875rem !important; }
  .ms-xxl-7 {
    margin-left: 2.1875rem !important; }
  .ms-xxl-8 {
    margin-left: 2.5rem !important; }
  .ms-xxl-9 {
    margin-left: 2.8125rem !important; }
  .ms-xxl-10 {
    margin-left: 3.125rem !important; }
  .ms-xxl-11 {
    margin-left: 3.4375rem !important; }
  .ms-xxl-12 {
    margin-left: 3.75rem !important; }
  .ms-xxl-13 {
    margin-left: 4.0625rem !important; }
  .ms-xxl-14 {
    margin-left: 4.375rem !important; }
  .ms-xxl-15 {
    margin-left: 4.6875rem !important; }
  .ms-xxl-16 {
    margin-left: 5rem !important; }
  .ms-xxl-17 {
    margin-left: 5.3125rem !important; }
  .ms-xxl-18 {
    margin-left: 5.625rem !important; }
  .ms-xxl-19 {
    margin-left: 5.9375rem !important; }
  .ms-xxl-20 {
    margin-left: 6.25rem !important; }
  .ms-xxl-21 {
    margin-left: 6.5625rem !important; }
  .ms-xxl-22 {
    margin-left: 6.875rem !important; }
  .ms-xxl-23 {
    margin-left: 7.1875rem !important; }
  .ms-xxl-24 {
    margin-left: 7.5rem !important; }
  .ms-xxl-25 {
    margin-left: 7.8125rem !important; }
  .ms-xxl-26 {
    margin-left: 8.125rem !important; }
  .ms-xxl-27 {
    margin-left: 8.4375rem !important; }
  .ms-xxl-28 {
    margin-left: 8.75rem !important; }
  .ms-xxl-29 {
    margin-left: 9.0625rem !important; }
  .ms-xxl-30 {
    margin-left: 9.375rem !important; }
  .ms-xxl-31 {
    margin-left: 9.6875rem !important; }
  .ms-xxl-32 {
    margin-left: 10rem !important; }
  .ms-xxl-33 {
    margin-left: 10.3125rem !important; }
  .ms-xxl-34 {
    margin-left: 10.625rem !important; }
  .ms-xxl-35 {
    margin-left: 10.9375rem !important; }
  .ms-xxl-36 {
    margin-left: 11.25rem !important; }
  .ms-xxl-37 {
    margin-left: 11.5625rem !important; }
  .ms-xxl-38 {
    margin-left: 11.875rem !important; }
  .ms-xxl-39 {
    margin-left: 12.1875rem !important; }
  .ms-xxl-40 {
    margin-left: 12.5rem !important; }
  .ms-xxl-base {
    margin-left: calc(20rem / 16) !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .m-xxl-n1 {
    margin: -0.3125rem !important; }
  .m-xxl-n2 {
    margin: -0.625rem !important; }
  .m-xxl-n3 {
    margin: -0.9375rem !important; }
  .m-xxl-n4 {
    margin: -1.25rem !important; }
  .m-xxl-n5 {
    margin: -1.5625rem !important; }
  .m-xxl-n6 {
    margin: -1.875rem !important; }
  .m-xxl-n7 {
    margin: -2.1875rem !important; }
  .m-xxl-n8 {
    margin: -2.5rem !important; }
  .m-xxl-n9 {
    margin: -2.8125rem !important; }
  .m-xxl-n10 {
    margin: -3.125rem !important; }
  .m-xxl-n11 {
    margin: -3.4375rem !important; }
  .m-xxl-n12 {
    margin: -3.75rem !important; }
  .m-xxl-n13 {
    margin: -4.0625rem !important; }
  .m-xxl-n14 {
    margin: -4.375rem !important; }
  .m-xxl-n15 {
    margin: -4.6875rem !important; }
  .m-xxl-n16 {
    margin: -5rem !important; }
  .m-xxl-n17 {
    margin: -5.3125rem !important; }
  .m-xxl-n18 {
    margin: -5.625rem !important; }
  .m-xxl-n19 {
    margin: -5.9375rem !important; }
  .m-xxl-n20 {
    margin: -6.25rem !important; }
  .m-xxl-n21 {
    margin: -6.5625rem !important; }
  .m-xxl-n22 {
    margin: -6.875rem !important; }
  .m-xxl-n23 {
    margin: -7.1875rem !important; }
  .m-xxl-n24 {
    margin: -7.5rem !important; }
  .m-xxl-n25 {
    margin: -7.8125rem !important; }
  .m-xxl-n26 {
    margin: -8.125rem !important; }
  .m-xxl-n27 {
    margin: -8.4375rem !important; }
  .m-xxl-n28 {
    margin: -8.75rem !important; }
  .m-xxl-n29 {
    margin: -9.0625rem !important; }
  .m-xxl-n30 {
    margin: -9.375rem !important; }
  .m-xxl-n31 {
    margin: -9.6875rem !important; }
  .m-xxl-n32 {
    margin: -10rem !important; }
  .m-xxl-n33 {
    margin: -10.3125rem !important; }
  .m-xxl-n34 {
    margin: -10.625rem !important; }
  .m-xxl-n35 {
    margin: -10.9375rem !important; }
  .m-xxl-n36 {
    margin: -11.25rem !important; }
  .m-xxl-n37 {
    margin: -11.5625rem !important; }
  .m-xxl-n38 {
    margin: -11.875rem !important; }
  .m-xxl-n39 {
    margin: -12.1875rem !important; }
  .m-xxl-n40 {
    margin: -12.5rem !important; }
  .m-xxl-nbase {
    margin: -calc(20rem / 16) !important; }
  .mx-xxl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important; }
  .mx-xxl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important; }
  .mx-xxl-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important; }
  .mx-xxl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important; }
  .mx-xxl-n5 {
    margin-right: -1.5625rem !important;
    margin-left: -1.5625rem !important; }
  .mx-xxl-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important; }
  .mx-xxl-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important; }
  .mx-xxl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-xxl-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important; }
  .mx-xxl-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important; }
  .mx-xxl-n11 {
    margin-right: -3.4375rem !important;
    margin-left: -3.4375rem !important; }
  .mx-xxl-n12 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important; }
  .mx-xxl-n13 {
    margin-right: -4.0625rem !important;
    margin-left: -4.0625rem !important; }
  .mx-xxl-n14 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important; }
  .mx-xxl-n15 {
    margin-right: -4.6875rem !important;
    margin-left: -4.6875rem !important; }
  .mx-xxl-n16 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-xxl-n17 {
    margin-right: -5.3125rem !important;
    margin-left: -5.3125rem !important; }
  .mx-xxl-n18 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important; }
  .mx-xxl-n19 {
    margin-right: -5.9375rem !important;
    margin-left: -5.9375rem !important; }
  .mx-xxl-n20 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important; }
  .mx-xxl-n21 {
    margin-right: -6.5625rem !important;
    margin-left: -6.5625rem !important; }
  .mx-xxl-n22 {
    margin-right: -6.875rem !important;
    margin-left: -6.875rem !important; }
  .mx-xxl-n23 {
    margin-right: -7.1875rem !important;
    margin-left: -7.1875rem !important; }
  .mx-xxl-n24 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-xxl-n25 {
    margin-right: -7.8125rem !important;
    margin-left: -7.8125rem !important; }
  .mx-xxl-n26 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important; }
  .mx-xxl-n27 {
    margin-right: -8.4375rem !important;
    margin-left: -8.4375rem !important; }
  .mx-xxl-n28 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important; }
  .mx-xxl-n29 {
    margin-right: -9.0625rem !important;
    margin-left: -9.0625rem !important; }
  .mx-xxl-n30 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important; }
  .mx-xxl-n31 {
    margin-right: -9.6875rem !important;
    margin-left: -9.6875rem !important; }
  .mx-xxl-n32 {
    margin-right: -10rem !important;
    margin-left: -10rem !important; }
  .mx-xxl-n33 {
    margin-right: -10.3125rem !important;
    margin-left: -10.3125rem !important; }
  .mx-xxl-n34 {
    margin-right: -10.625rem !important;
    margin-left: -10.625rem !important; }
  .mx-xxl-n35 {
    margin-right: -10.9375rem !important;
    margin-left: -10.9375rem !important; }
  .mx-xxl-n36 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important; }
  .mx-xxl-n37 {
    margin-right: -11.5625rem !important;
    margin-left: -11.5625rem !important; }
  .mx-xxl-n38 {
    margin-right: -11.875rem !important;
    margin-left: -11.875rem !important; }
  .mx-xxl-n39 {
    margin-right: -12.1875rem !important;
    margin-left: -12.1875rem !important; }
  .mx-xxl-n40 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important; }
  .mx-xxl-nbase {
    margin-right: -calc(20rem / 16) !important;
    margin-left: -calc(20rem / 16) !important; }
  .my-xxl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important; }
  .my-xxl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important; }
  .my-xxl-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important; }
  .my-xxl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .my-xxl-n5 {
    margin-top: -1.5625rem !important;
    margin-bottom: -1.5625rem !important; }
  .my-xxl-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important; }
  .my-xxl-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important; }
  .my-xxl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-xxl-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important; }
  .my-xxl-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important; }
  .my-xxl-n11 {
    margin-top: -3.4375rem !important;
    margin-bottom: -3.4375rem !important; }
  .my-xxl-n12 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important; }
  .my-xxl-n13 {
    margin-top: -4.0625rem !important;
    margin-bottom: -4.0625rem !important; }
  .my-xxl-n14 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important; }
  .my-xxl-n15 {
    margin-top: -4.6875rem !important;
    margin-bottom: -4.6875rem !important; }
  .my-xxl-n16 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-xxl-n17 {
    margin-top: -5.3125rem !important;
    margin-bottom: -5.3125rem !important; }
  .my-xxl-n18 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important; }
  .my-xxl-n19 {
    margin-top: -5.9375rem !important;
    margin-bottom: -5.9375rem !important; }
  .my-xxl-n20 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important; }
  .my-xxl-n21 {
    margin-top: -6.5625rem !important;
    margin-bottom: -6.5625rem !important; }
  .my-xxl-n22 {
    margin-top: -6.875rem !important;
    margin-bottom: -6.875rem !important; }
  .my-xxl-n23 {
    margin-top: -7.1875rem !important;
    margin-bottom: -7.1875rem !important; }
  .my-xxl-n24 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-xxl-n25 {
    margin-top: -7.8125rem !important;
    margin-bottom: -7.8125rem !important; }
  .my-xxl-n26 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important; }
  .my-xxl-n27 {
    margin-top: -8.4375rem !important;
    margin-bottom: -8.4375rem !important; }
  .my-xxl-n28 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important; }
  .my-xxl-n29 {
    margin-top: -9.0625rem !important;
    margin-bottom: -9.0625rem !important; }
  .my-xxl-n30 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important; }
  .my-xxl-n31 {
    margin-top: -9.6875rem !important;
    margin-bottom: -9.6875rem !important; }
  .my-xxl-n32 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .my-xxl-n33 {
    margin-top: -10.3125rem !important;
    margin-bottom: -10.3125rem !important; }
  .my-xxl-n34 {
    margin-top: -10.625rem !important;
    margin-bottom: -10.625rem !important; }
  .my-xxl-n35 {
    margin-top: -10.9375rem !important;
    margin-bottom: -10.9375rem !important; }
  .my-xxl-n36 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important; }
  .my-xxl-n37 {
    margin-top: -11.5625rem !important;
    margin-bottom: -11.5625rem !important; }
  .my-xxl-n38 {
    margin-top: -11.875rem !important;
    margin-bottom: -11.875rem !important; }
  .my-xxl-n39 {
    margin-top: -12.1875rem !important;
    margin-bottom: -12.1875rem !important; }
  .my-xxl-n40 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important; }
  .my-xxl-nbase {
    margin-top: -calc(20rem / 16) !important;
    margin-bottom: -calc(20rem / 16) !important; }
  .mt-xxl-n1 {
    margin-top: -0.3125rem !important; }
  .mt-xxl-n2 {
    margin-top: -0.625rem !important; }
  .mt-xxl-n3 {
    margin-top: -0.9375rem !important; }
  .mt-xxl-n4 {
    margin-top: -1.25rem !important; }
  .mt-xxl-n5 {
    margin-top: -1.5625rem !important; }
  .mt-xxl-n6 {
    margin-top: -1.875rem !important; }
  .mt-xxl-n7 {
    margin-top: -2.1875rem !important; }
  .mt-xxl-n8 {
    margin-top: -2.5rem !important; }
  .mt-xxl-n9 {
    margin-top: -2.8125rem !important; }
  .mt-xxl-n10 {
    margin-top: -3.125rem !important; }
  .mt-xxl-n11 {
    margin-top: -3.4375rem !important; }
  .mt-xxl-n12 {
    margin-top: -3.75rem !important; }
  .mt-xxl-n13 {
    margin-top: -4.0625rem !important; }
  .mt-xxl-n14 {
    margin-top: -4.375rem !important; }
  .mt-xxl-n15 {
    margin-top: -4.6875rem !important; }
  .mt-xxl-n16 {
    margin-top: -5rem !important; }
  .mt-xxl-n17 {
    margin-top: -5.3125rem !important; }
  .mt-xxl-n18 {
    margin-top: -5.625rem !important; }
  .mt-xxl-n19 {
    margin-top: -5.9375rem !important; }
  .mt-xxl-n20 {
    margin-top: -6.25rem !important; }
  .mt-xxl-n21 {
    margin-top: -6.5625rem !important; }
  .mt-xxl-n22 {
    margin-top: -6.875rem !important; }
  .mt-xxl-n23 {
    margin-top: -7.1875rem !important; }
  .mt-xxl-n24 {
    margin-top: -7.5rem !important; }
  .mt-xxl-n25 {
    margin-top: -7.8125rem !important; }
  .mt-xxl-n26 {
    margin-top: -8.125rem !important; }
  .mt-xxl-n27 {
    margin-top: -8.4375rem !important; }
  .mt-xxl-n28 {
    margin-top: -8.75rem !important; }
  .mt-xxl-n29 {
    margin-top: -9.0625rem !important; }
  .mt-xxl-n30 {
    margin-top: -9.375rem !important; }
  .mt-xxl-n31 {
    margin-top: -9.6875rem !important; }
  .mt-xxl-n32 {
    margin-top: -10rem !important; }
  .mt-xxl-n33 {
    margin-top: -10.3125rem !important; }
  .mt-xxl-n34 {
    margin-top: -10.625rem !important; }
  .mt-xxl-n35 {
    margin-top: -10.9375rem !important; }
  .mt-xxl-n36 {
    margin-top: -11.25rem !important; }
  .mt-xxl-n37 {
    margin-top: -11.5625rem !important; }
  .mt-xxl-n38 {
    margin-top: -11.875rem !important; }
  .mt-xxl-n39 {
    margin-top: -12.1875rem !important; }
  .mt-xxl-n40 {
    margin-top: -12.5rem !important; }
  .mt-xxl-nbase {
    margin-top: -calc(20rem / 16) !important; }
  .me-xxl-n1 {
    margin-right: -0.3125rem !important; }
  .me-xxl-n2 {
    margin-right: -0.625rem !important; }
  .me-xxl-n3 {
    margin-right: -0.9375rem !important; }
  .me-xxl-n4 {
    margin-right: -1.25rem !important; }
  .me-xxl-n5 {
    margin-right: -1.5625rem !important; }
  .me-xxl-n6 {
    margin-right: -1.875rem !important; }
  .me-xxl-n7 {
    margin-right: -2.1875rem !important; }
  .me-xxl-n8 {
    margin-right: -2.5rem !important; }
  .me-xxl-n9 {
    margin-right: -2.8125rem !important; }
  .me-xxl-n10 {
    margin-right: -3.125rem !important; }
  .me-xxl-n11 {
    margin-right: -3.4375rem !important; }
  .me-xxl-n12 {
    margin-right: -3.75rem !important; }
  .me-xxl-n13 {
    margin-right: -4.0625rem !important; }
  .me-xxl-n14 {
    margin-right: -4.375rem !important; }
  .me-xxl-n15 {
    margin-right: -4.6875rem !important; }
  .me-xxl-n16 {
    margin-right: -5rem !important; }
  .me-xxl-n17 {
    margin-right: -5.3125rem !important; }
  .me-xxl-n18 {
    margin-right: -5.625rem !important; }
  .me-xxl-n19 {
    margin-right: -5.9375rem !important; }
  .me-xxl-n20 {
    margin-right: -6.25rem !important; }
  .me-xxl-n21 {
    margin-right: -6.5625rem !important; }
  .me-xxl-n22 {
    margin-right: -6.875rem !important; }
  .me-xxl-n23 {
    margin-right: -7.1875rem !important; }
  .me-xxl-n24 {
    margin-right: -7.5rem !important; }
  .me-xxl-n25 {
    margin-right: -7.8125rem !important; }
  .me-xxl-n26 {
    margin-right: -8.125rem !important; }
  .me-xxl-n27 {
    margin-right: -8.4375rem !important; }
  .me-xxl-n28 {
    margin-right: -8.75rem !important; }
  .me-xxl-n29 {
    margin-right: -9.0625rem !important; }
  .me-xxl-n30 {
    margin-right: -9.375rem !important; }
  .me-xxl-n31 {
    margin-right: -9.6875rem !important; }
  .me-xxl-n32 {
    margin-right: -10rem !important; }
  .me-xxl-n33 {
    margin-right: -10.3125rem !important; }
  .me-xxl-n34 {
    margin-right: -10.625rem !important; }
  .me-xxl-n35 {
    margin-right: -10.9375rem !important; }
  .me-xxl-n36 {
    margin-right: -11.25rem !important; }
  .me-xxl-n37 {
    margin-right: -11.5625rem !important; }
  .me-xxl-n38 {
    margin-right: -11.875rem !important; }
  .me-xxl-n39 {
    margin-right: -12.1875rem !important; }
  .me-xxl-n40 {
    margin-right: -12.5rem !important; }
  .me-xxl-nbase {
    margin-right: -calc(20rem / 16) !important; }
  .mb-xxl-n1 {
    margin-bottom: -0.3125rem !important; }
  .mb-xxl-n2 {
    margin-bottom: -0.625rem !important; }
  .mb-xxl-n3 {
    margin-bottom: -0.9375rem !important; }
  .mb-xxl-n4 {
    margin-bottom: -1.25rem !important; }
  .mb-xxl-n5 {
    margin-bottom: -1.5625rem !important; }
  .mb-xxl-n6 {
    margin-bottom: -1.875rem !important; }
  .mb-xxl-n7 {
    margin-bottom: -2.1875rem !important; }
  .mb-xxl-n8 {
    margin-bottom: -2.5rem !important; }
  .mb-xxl-n9 {
    margin-bottom: -2.8125rem !important; }
  .mb-xxl-n10 {
    margin-bottom: -3.125rem !important; }
  .mb-xxl-n11 {
    margin-bottom: -3.4375rem !important; }
  .mb-xxl-n12 {
    margin-bottom: -3.75rem !important; }
  .mb-xxl-n13 {
    margin-bottom: -4.0625rem !important; }
  .mb-xxl-n14 {
    margin-bottom: -4.375rem !important; }
  .mb-xxl-n15 {
    margin-bottom: -4.6875rem !important; }
  .mb-xxl-n16 {
    margin-bottom: -5rem !important; }
  .mb-xxl-n17 {
    margin-bottom: -5.3125rem !important; }
  .mb-xxl-n18 {
    margin-bottom: -5.625rem !important; }
  .mb-xxl-n19 {
    margin-bottom: -5.9375rem !important; }
  .mb-xxl-n20 {
    margin-bottom: -6.25rem !important; }
  .mb-xxl-n21 {
    margin-bottom: -6.5625rem !important; }
  .mb-xxl-n22 {
    margin-bottom: -6.875rem !important; }
  .mb-xxl-n23 {
    margin-bottom: -7.1875rem !important; }
  .mb-xxl-n24 {
    margin-bottom: -7.5rem !important; }
  .mb-xxl-n25 {
    margin-bottom: -7.8125rem !important; }
  .mb-xxl-n26 {
    margin-bottom: -8.125rem !important; }
  .mb-xxl-n27 {
    margin-bottom: -8.4375rem !important; }
  .mb-xxl-n28 {
    margin-bottom: -8.75rem !important; }
  .mb-xxl-n29 {
    margin-bottom: -9.0625rem !important; }
  .mb-xxl-n30 {
    margin-bottom: -9.375rem !important; }
  .mb-xxl-n31 {
    margin-bottom: -9.6875rem !important; }
  .mb-xxl-n32 {
    margin-bottom: -10rem !important; }
  .mb-xxl-n33 {
    margin-bottom: -10.3125rem !important; }
  .mb-xxl-n34 {
    margin-bottom: -10.625rem !important; }
  .mb-xxl-n35 {
    margin-bottom: -10.9375rem !important; }
  .mb-xxl-n36 {
    margin-bottom: -11.25rem !important; }
  .mb-xxl-n37 {
    margin-bottom: -11.5625rem !important; }
  .mb-xxl-n38 {
    margin-bottom: -11.875rem !important; }
  .mb-xxl-n39 {
    margin-bottom: -12.1875rem !important; }
  .mb-xxl-n40 {
    margin-bottom: -12.5rem !important; }
  .mb-xxl-nbase {
    margin-bottom: -calc(20rem / 16) !important; }
  .ms-xxl-n1 {
    margin-left: -0.3125rem !important; }
  .ms-xxl-n2 {
    margin-left: -0.625rem !important; }
  .ms-xxl-n3 {
    margin-left: -0.9375rem !important; }
  .ms-xxl-n4 {
    margin-left: -1.25rem !important; }
  .ms-xxl-n5 {
    margin-left: -1.5625rem !important; }
  .ms-xxl-n6 {
    margin-left: -1.875rem !important; }
  .ms-xxl-n7 {
    margin-left: -2.1875rem !important; }
  .ms-xxl-n8 {
    margin-left: -2.5rem !important; }
  .ms-xxl-n9 {
    margin-left: -2.8125rem !important; }
  .ms-xxl-n10 {
    margin-left: -3.125rem !important; }
  .ms-xxl-n11 {
    margin-left: -3.4375rem !important; }
  .ms-xxl-n12 {
    margin-left: -3.75rem !important; }
  .ms-xxl-n13 {
    margin-left: -4.0625rem !important; }
  .ms-xxl-n14 {
    margin-left: -4.375rem !important; }
  .ms-xxl-n15 {
    margin-left: -4.6875rem !important; }
  .ms-xxl-n16 {
    margin-left: -5rem !important; }
  .ms-xxl-n17 {
    margin-left: -5.3125rem !important; }
  .ms-xxl-n18 {
    margin-left: -5.625rem !important; }
  .ms-xxl-n19 {
    margin-left: -5.9375rem !important; }
  .ms-xxl-n20 {
    margin-left: -6.25rem !important; }
  .ms-xxl-n21 {
    margin-left: -6.5625rem !important; }
  .ms-xxl-n22 {
    margin-left: -6.875rem !important; }
  .ms-xxl-n23 {
    margin-left: -7.1875rem !important; }
  .ms-xxl-n24 {
    margin-left: -7.5rem !important; }
  .ms-xxl-n25 {
    margin-left: -7.8125rem !important; }
  .ms-xxl-n26 {
    margin-left: -8.125rem !important; }
  .ms-xxl-n27 {
    margin-left: -8.4375rem !important; }
  .ms-xxl-n28 {
    margin-left: -8.75rem !important; }
  .ms-xxl-n29 {
    margin-left: -9.0625rem !important; }
  .ms-xxl-n30 {
    margin-left: -9.375rem !important; }
  .ms-xxl-n31 {
    margin-left: -9.6875rem !important; }
  .ms-xxl-n32 {
    margin-left: -10rem !important; }
  .ms-xxl-n33 {
    margin-left: -10.3125rem !important; }
  .ms-xxl-n34 {
    margin-left: -10.625rem !important; }
  .ms-xxl-n35 {
    margin-left: -10.9375rem !important; }
  .ms-xxl-n36 {
    margin-left: -11.25rem !important; }
  .ms-xxl-n37 {
    margin-left: -11.5625rem !important; }
  .ms-xxl-n38 {
    margin-left: -11.875rem !important; }
  .ms-xxl-n39 {
    margin-left: -12.1875rem !important; }
  .ms-xxl-n40 {
    margin-left: -12.5rem !important; }
  .ms-xxl-nbase {
    margin-left: -calc(20rem / 16) !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.3125rem !important; }
  .p-xxl-2 {
    padding: 0.625rem !important; }
  .p-xxl-3 {
    padding: 0.9375rem !important; }
  .p-xxl-4 {
    padding: 1.25rem !important; }
  .p-xxl-5 {
    padding: 1.5625rem !important; }
  .p-xxl-6 {
    padding: 1.875rem !important; }
  .p-xxl-7 {
    padding: 2.1875rem !important; }
  .p-xxl-8 {
    padding: 2.5rem !important; }
  .p-xxl-9 {
    padding: 2.8125rem !important; }
  .p-xxl-10 {
    padding: 3.125rem !important; }
  .p-xxl-11 {
    padding: 3.4375rem !important; }
  .p-xxl-12 {
    padding: 3.75rem !important; }
  .p-xxl-13 {
    padding: 4.0625rem !important; }
  .p-xxl-14 {
    padding: 4.375rem !important; }
  .p-xxl-15 {
    padding: 4.6875rem !important; }
  .p-xxl-16 {
    padding: 5rem !important; }
  .p-xxl-17 {
    padding: 5.3125rem !important; }
  .p-xxl-18 {
    padding: 5.625rem !important; }
  .p-xxl-19 {
    padding: 5.9375rem !important; }
  .p-xxl-20 {
    padding: 6.25rem !important; }
  .p-xxl-21 {
    padding: 6.5625rem !important; }
  .p-xxl-22 {
    padding: 6.875rem !important; }
  .p-xxl-23 {
    padding: 7.1875rem !important; }
  .p-xxl-24 {
    padding: 7.5rem !important; }
  .p-xxl-25 {
    padding: 7.8125rem !important; }
  .p-xxl-26 {
    padding: 8.125rem !important; }
  .p-xxl-27 {
    padding: 8.4375rem !important; }
  .p-xxl-28 {
    padding: 8.75rem !important; }
  .p-xxl-29 {
    padding: 9.0625rem !important; }
  .p-xxl-30 {
    padding: 9.375rem !important; }
  .p-xxl-31 {
    padding: 9.6875rem !important; }
  .p-xxl-32 {
    padding: 10rem !important; }
  .p-xxl-33 {
    padding: 10.3125rem !important; }
  .p-xxl-34 {
    padding: 10.625rem !important; }
  .p-xxl-35 {
    padding: 10.9375rem !important; }
  .p-xxl-36 {
    padding: 11.25rem !important; }
  .p-xxl-37 {
    padding: 11.5625rem !important; }
  .p-xxl-38 {
    padding: 11.875rem !important; }
  .p-xxl-39 {
    padding: 12.1875rem !important; }
  .p-xxl-40 {
    padding: 12.5rem !important; }
  .p-xxl-base {
    padding: calc(20rem / 16) !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important; }
  .px-xxl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important; }
  .px-xxl-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important; }
  .px-xxl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important; }
  .px-xxl-5 {
    padding-right: 1.5625rem !important;
    padding-left: 1.5625rem !important; }
  .px-xxl-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important; }
  .px-xxl-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important; }
  .px-xxl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-xxl-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important; }
  .px-xxl-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important; }
  .px-xxl-11 {
    padding-right: 3.4375rem !important;
    padding-left: 3.4375rem !important; }
  .px-xxl-12 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important; }
  .px-xxl-13 {
    padding-right: 4.0625rem !important;
    padding-left: 4.0625rem !important; }
  .px-xxl-14 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important; }
  .px-xxl-15 {
    padding-right: 4.6875rem !important;
    padding-left: 4.6875rem !important; }
  .px-xxl-16 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-xxl-17 {
    padding-right: 5.3125rem !important;
    padding-left: 5.3125rem !important; }
  .px-xxl-18 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important; }
  .px-xxl-19 {
    padding-right: 5.9375rem !important;
    padding-left: 5.9375rem !important; }
  .px-xxl-20 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important; }
  .px-xxl-21 {
    padding-right: 6.5625rem !important;
    padding-left: 6.5625rem !important; }
  .px-xxl-22 {
    padding-right: 6.875rem !important;
    padding-left: 6.875rem !important; }
  .px-xxl-23 {
    padding-right: 7.1875rem !important;
    padding-left: 7.1875rem !important; }
  .px-xxl-24 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-xxl-25 {
    padding-right: 7.8125rem !important;
    padding-left: 7.8125rem !important; }
  .px-xxl-26 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important; }
  .px-xxl-27 {
    padding-right: 8.4375rem !important;
    padding-left: 8.4375rem !important; }
  .px-xxl-28 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important; }
  .px-xxl-29 {
    padding-right: 9.0625rem !important;
    padding-left: 9.0625rem !important; }
  .px-xxl-30 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important; }
  .px-xxl-31 {
    padding-right: 9.6875rem !important;
    padding-left: 9.6875rem !important; }
  .px-xxl-32 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-xxl-33 {
    padding-right: 10.3125rem !important;
    padding-left: 10.3125rem !important; }
  .px-xxl-34 {
    padding-right: 10.625rem !important;
    padding-left: 10.625rem !important; }
  .px-xxl-35 {
    padding-right: 10.9375rem !important;
    padding-left: 10.9375rem !important; }
  .px-xxl-36 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important; }
  .px-xxl-37 {
    padding-right: 11.5625rem !important;
    padding-left: 11.5625rem !important; }
  .px-xxl-38 {
    padding-right: 11.875rem !important;
    padding-left: 11.875rem !important; }
  .px-xxl-39 {
    padding-right: 12.1875rem !important;
    padding-left: 12.1875rem !important; }
  .px-xxl-40 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-xxl-base {
    padding-right: calc(20rem / 16) !important;
    padding-left: calc(20rem / 16) !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important; }
  .py-xxl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important; }
  .py-xxl-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important; }
  .py-xxl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .py-xxl-5 {
    padding-top: 1.5625rem !important;
    padding-bottom: 1.5625rem !important; }
  .py-xxl-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important; }
  .py-xxl-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important; }
  .py-xxl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-xxl-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important; }
  .py-xxl-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important; }
  .py-xxl-11 {
    padding-top: 3.4375rem !important;
    padding-bottom: 3.4375rem !important; }
  .py-xxl-12 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important; }
  .py-xxl-13 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important; }
  .py-xxl-14 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important; }
  .py-xxl-15 {
    padding-top: 4.6875rem !important;
    padding-bottom: 4.6875rem !important; }
  .py-xxl-16 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-xxl-17 {
    padding-top: 5.3125rem !important;
    padding-bottom: 5.3125rem !important; }
  .py-xxl-18 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important; }
  .py-xxl-19 {
    padding-top: 5.9375rem !important;
    padding-bottom: 5.9375rem !important; }
  .py-xxl-20 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important; }
  .py-xxl-21 {
    padding-top: 6.5625rem !important;
    padding-bottom: 6.5625rem !important; }
  .py-xxl-22 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important; }
  .py-xxl-23 {
    padding-top: 7.1875rem !important;
    padding-bottom: 7.1875rem !important; }
  .py-xxl-24 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-xxl-25 {
    padding-top: 7.8125rem !important;
    padding-bottom: 7.8125rem !important; }
  .py-xxl-26 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important; }
  .py-xxl-27 {
    padding-top: 8.4375rem !important;
    padding-bottom: 8.4375rem !important; }
  .py-xxl-28 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important; }
  .py-xxl-29 {
    padding-top: 9.0625rem !important;
    padding-bottom: 9.0625rem !important; }
  .py-xxl-30 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important; }
  .py-xxl-31 {
    padding-top: 9.6875rem !important;
    padding-bottom: 9.6875rem !important; }
  .py-xxl-32 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-xxl-33 {
    padding-top: 10.3125rem !important;
    padding-bottom: 10.3125rem !important; }
  .py-xxl-34 {
    padding-top: 10.625rem !important;
    padding-bottom: 10.625rem !important; }
  .py-xxl-35 {
    padding-top: 10.9375rem !important;
    padding-bottom: 10.9375rem !important; }
  .py-xxl-36 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important; }
  .py-xxl-37 {
    padding-top: 11.5625rem !important;
    padding-bottom: 11.5625rem !important; }
  .py-xxl-38 {
    padding-top: 11.875rem !important;
    padding-bottom: 11.875rem !important; }
  .py-xxl-39 {
    padding-top: 12.1875rem !important;
    padding-bottom: 12.1875rem !important; }
  .py-xxl-40 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-xxl-base {
    padding-top: calc(20rem / 16) !important;
    padding-bottom: calc(20rem / 16) !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.3125rem !important; }
  .pt-xxl-2 {
    padding-top: 0.625rem !important; }
  .pt-xxl-3 {
    padding-top: 0.9375rem !important; }
  .pt-xxl-4 {
    padding-top: 1.25rem !important; }
  .pt-xxl-5 {
    padding-top: 1.5625rem !important; }
  .pt-xxl-6 {
    padding-top: 1.875rem !important; }
  .pt-xxl-7 {
    padding-top: 2.1875rem !important; }
  .pt-xxl-8 {
    padding-top: 2.5rem !important; }
  .pt-xxl-9 {
    padding-top: 2.8125rem !important; }
  .pt-xxl-10 {
    padding-top: 3.125rem !important; }
  .pt-xxl-11 {
    padding-top: 3.4375rem !important; }
  .pt-xxl-12 {
    padding-top: 3.75rem !important; }
  .pt-xxl-13 {
    padding-top: 4.0625rem !important; }
  .pt-xxl-14 {
    padding-top: 4.375rem !important; }
  .pt-xxl-15 {
    padding-top: 4.6875rem !important; }
  .pt-xxl-16 {
    padding-top: 5rem !important; }
  .pt-xxl-17 {
    padding-top: 5.3125rem !important; }
  .pt-xxl-18 {
    padding-top: 5.625rem !important; }
  .pt-xxl-19 {
    padding-top: 5.9375rem !important; }
  .pt-xxl-20 {
    padding-top: 6.25rem !important; }
  .pt-xxl-21 {
    padding-top: 6.5625rem !important; }
  .pt-xxl-22 {
    padding-top: 6.875rem !important; }
  .pt-xxl-23 {
    padding-top: 7.1875rem !important; }
  .pt-xxl-24 {
    padding-top: 7.5rem !important; }
  .pt-xxl-25 {
    padding-top: 7.8125rem !important; }
  .pt-xxl-26 {
    padding-top: 8.125rem !important; }
  .pt-xxl-27 {
    padding-top: 8.4375rem !important; }
  .pt-xxl-28 {
    padding-top: 8.75rem !important; }
  .pt-xxl-29 {
    padding-top: 9.0625rem !important; }
  .pt-xxl-30 {
    padding-top: 9.375rem !important; }
  .pt-xxl-31 {
    padding-top: 9.6875rem !important; }
  .pt-xxl-32 {
    padding-top: 10rem !important; }
  .pt-xxl-33 {
    padding-top: 10.3125rem !important; }
  .pt-xxl-34 {
    padding-top: 10.625rem !important; }
  .pt-xxl-35 {
    padding-top: 10.9375rem !important; }
  .pt-xxl-36 {
    padding-top: 11.25rem !important; }
  .pt-xxl-37 {
    padding-top: 11.5625rem !important; }
  .pt-xxl-38 {
    padding-top: 11.875rem !important; }
  .pt-xxl-39 {
    padding-top: 12.1875rem !important; }
  .pt-xxl-40 {
    padding-top: 12.5rem !important; }
  .pt-xxl-base {
    padding-top: calc(20rem / 16) !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.3125rem !important; }
  .pe-xxl-2 {
    padding-right: 0.625rem !important; }
  .pe-xxl-3 {
    padding-right: 0.9375rem !important; }
  .pe-xxl-4 {
    padding-right: 1.25rem !important; }
  .pe-xxl-5 {
    padding-right: 1.5625rem !important; }
  .pe-xxl-6 {
    padding-right: 1.875rem !important; }
  .pe-xxl-7 {
    padding-right: 2.1875rem !important; }
  .pe-xxl-8 {
    padding-right: 2.5rem !important; }
  .pe-xxl-9 {
    padding-right: 2.8125rem !important; }
  .pe-xxl-10 {
    padding-right: 3.125rem !important; }
  .pe-xxl-11 {
    padding-right: 3.4375rem !important; }
  .pe-xxl-12 {
    padding-right: 3.75rem !important; }
  .pe-xxl-13 {
    padding-right: 4.0625rem !important; }
  .pe-xxl-14 {
    padding-right: 4.375rem !important; }
  .pe-xxl-15 {
    padding-right: 4.6875rem !important; }
  .pe-xxl-16 {
    padding-right: 5rem !important; }
  .pe-xxl-17 {
    padding-right: 5.3125rem !important; }
  .pe-xxl-18 {
    padding-right: 5.625rem !important; }
  .pe-xxl-19 {
    padding-right: 5.9375rem !important; }
  .pe-xxl-20 {
    padding-right: 6.25rem !important; }
  .pe-xxl-21 {
    padding-right: 6.5625rem !important; }
  .pe-xxl-22 {
    padding-right: 6.875rem !important; }
  .pe-xxl-23 {
    padding-right: 7.1875rem !important; }
  .pe-xxl-24 {
    padding-right: 7.5rem !important; }
  .pe-xxl-25 {
    padding-right: 7.8125rem !important; }
  .pe-xxl-26 {
    padding-right: 8.125rem !important; }
  .pe-xxl-27 {
    padding-right: 8.4375rem !important; }
  .pe-xxl-28 {
    padding-right: 8.75rem !important; }
  .pe-xxl-29 {
    padding-right: 9.0625rem !important; }
  .pe-xxl-30 {
    padding-right: 9.375rem !important; }
  .pe-xxl-31 {
    padding-right: 9.6875rem !important; }
  .pe-xxl-32 {
    padding-right: 10rem !important; }
  .pe-xxl-33 {
    padding-right: 10.3125rem !important; }
  .pe-xxl-34 {
    padding-right: 10.625rem !important; }
  .pe-xxl-35 {
    padding-right: 10.9375rem !important; }
  .pe-xxl-36 {
    padding-right: 11.25rem !important; }
  .pe-xxl-37 {
    padding-right: 11.5625rem !important; }
  .pe-xxl-38 {
    padding-right: 11.875rem !important; }
  .pe-xxl-39 {
    padding-right: 12.1875rem !important; }
  .pe-xxl-40 {
    padding-right: 12.5rem !important; }
  .pe-xxl-base {
    padding-right: calc(20rem / 16) !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.3125rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.625rem !important; }
  .pb-xxl-3 {
    padding-bottom: 0.9375rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.25rem !important; }
  .pb-xxl-5 {
    padding-bottom: 1.5625rem !important; }
  .pb-xxl-6 {
    padding-bottom: 1.875rem !important; }
  .pb-xxl-7 {
    padding-bottom: 2.1875rem !important; }
  .pb-xxl-8 {
    padding-bottom: 2.5rem !important; }
  .pb-xxl-9 {
    padding-bottom: 2.8125rem !important; }
  .pb-xxl-10 {
    padding-bottom: 3.125rem !important; }
  .pb-xxl-11 {
    padding-bottom: 3.4375rem !important; }
  .pb-xxl-12 {
    padding-bottom: 3.75rem !important; }
  .pb-xxl-13 {
    padding-bottom: 4.0625rem !important; }
  .pb-xxl-14 {
    padding-bottom: 4.375rem !important; }
  .pb-xxl-15 {
    padding-bottom: 4.6875rem !important; }
  .pb-xxl-16 {
    padding-bottom: 5rem !important; }
  .pb-xxl-17 {
    padding-bottom: 5.3125rem !important; }
  .pb-xxl-18 {
    padding-bottom: 5.625rem !important; }
  .pb-xxl-19 {
    padding-bottom: 5.9375rem !important; }
  .pb-xxl-20 {
    padding-bottom: 6.25rem !important; }
  .pb-xxl-21 {
    padding-bottom: 6.5625rem !important; }
  .pb-xxl-22 {
    padding-bottom: 6.875rem !important; }
  .pb-xxl-23 {
    padding-bottom: 7.1875rem !important; }
  .pb-xxl-24 {
    padding-bottom: 7.5rem !important; }
  .pb-xxl-25 {
    padding-bottom: 7.8125rem !important; }
  .pb-xxl-26 {
    padding-bottom: 8.125rem !important; }
  .pb-xxl-27 {
    padding-bottom: 8.4375rem !important; }
  .pb-xxl-28 {
    padding-bottom: 8.75rem !important; }
  .pb-xxl-29 {
    padding-bottom: 9.0625rem !important; }
  .pb-xxl-30 {
    padding-bottom: 9.375rem !important; }
  .pb-xxl-31 {
    padding-bottom: 9.6875rem !important; }
  .pb-xxl-32 {
    padding-bottom: 10rem !important; }
  .pb-xxl-33 {
    padding-bottom: 10.3125rem !important; }
  .pb-xxl-34 {
    padding-bottom: 10.625rem !important; }
  .pb-xxl-35 {
    padding-bottom: 10.9375rem !important; }
  .pb-xxl-36 {
    padding-bottom: 11.25rem !important; }
  .pb-xxl-37 {
    padding-bottom: 11.5625rem !important; }
  .pb-xxl-38 {
    padding-bottom: 11.875rem !important; }
  .pb-xxl-39 {
    padding-bottom: 12.1875rem !important; }
  .pb-xxl-40 {
    padding-bottom: 12.5rem !important; }
  .pb-xxl-base {
    padding-bottom: calc(20rem / 16) !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.3125rem !important; }
  .ps-xxl-2 {
    padding-left: 0.625rem !important; }
  .ps-xxl-3 {
    padding-left: 0.9375rem !important; }
  .ps-xxl-4 {
    padding-left: 1.25rem !important; }
  .ps-xxl-5 {
    padding-left: 1.5625rem !important; }
  .ps-xxl-6 {
    padding-left: 1.875rem !important; }
  .ps-xxl-7 {
    padding-left: 2.1875rem !important; }
  .ps-xxl-8 {
    padding-left: 2.5rem !important; }
  .ps-xxl-9 {
    padding-left: 2.8125rem !important; }
  .ps-xxl-10 {
    padding-left: 3.125rem !important; }
  .ps-xxl-11 {
    padding-left: 3.4375rem !important; }
  .ps-xxl-12 {
    padding-left: 3.75rem !important; }
  .ps-xxl-13 {
    padding-left: 4.0625rem !important; }
  .ps-xxl-14 {
    padding-left: 4.375rem !important; }
  .ps-xxl-15 {
    padding-left: 4.6875rem !important; }
  .ps-xxl-16 {
    padding-left: 5rem !important; }
  .ps-xxl-17 {
    padding-left: 5.3125rem !important; }
  .ps-xxl-18 {
    padding-left: 5.625rem !important; }
  .ps-xxl-19 {
    padding-left: 5.9375rem !important; }
  .ps-xxl-20 {
    padding-left: 6.25rem !important; }
  .ps-xxl-21 {
    padding-left: 6.5625rem !important; }
  .ps-xxl-22 {
    padding-left: 6.875rem !important; }
  .ps-xxl-23 {
    padding-left: 7.1875rem !important; }
  .ps-xxl-24 {
    padding-left: 7.5rem !important; }
  .ps-xxl-25 {
    padding-left: 7.8125rem !important; }
  .ps-xxl-26 {
    padding-left: 8.125rem !important; }
  .ps-xxl-27 {
    padding-left: 8.4375rem !important; }
  .ps-xxl-28 {
    padding-left: 8.75rem !important; }
  .ps-xxl-29 {
    padding-left: 9.0625rem !important; }
  .ps-xxl-30 {
    padding-left: 9.375rem !important; }
  .ps-xxl-31 {
    padding-left: 9.6875rem !important; }
  .ps-xxl-32 {
    padding-left: 10rem !important; }
  .ps-xxl-33 {
    padding-left: 10.3125rem !important; }
  .ps-xxl-34 {
    padding-left: 10.625rem !important; }
  .ps-xxl-35 {
    padding-left: 10.9375rem !important; }
  .ps-xxl-36 {
    padding-left: 11.25rem !important; }
  .ps-xxl-37 {
    padding-left: 11.5625rem !important; }
  .ps-xxl-38 {
    padding-left: 11.875rem !important; }
  .ps-xxl-39 {
    padding-left: 12.1875rem !important; }
  .ps-xxl-40 {
    padding-left: 12.5rem !important; }
  .ps-xxl-base {
    padding-left: calc(20rem / 16) !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.3125rem !important; }
  .gap-xxl-2 {
    gap: 0.625rem !important; }
  .gap-xxl-3 {
    gap: 0.9375rem !important; }
  .gap-xxl-4 {
    gap: 1.25rem !important; }
  .gap-xxl-5 {
    gap: 1.5625rem !important; }
  .gap-xxl-6 {
    gap: 1.875rem !important; }
  .gap-xxl-7 {
    gap: 2.1875rem !important; }
  .gap-xxl-8 {
    gap: 2.5rem !important; }
  .gap-xxl-9 {
    gap: 2.8125rem !important; }
  .gap-xxl-10 {
    gap: 3.125rem !important; }
  .gap-xxl-11 {
    gap: 3.4375rem !important; }
  .gap-xxl-12 {
    gap: 3.75rem !important; }
  .gap-xxl-13 {
    gap: 4.0625rem !important; }
  .gap-xxl-14 {
    gap: 4.375rem !important; }
  .gap-xxl-15 {
    gap: 4.6875rem !important; }
  .gap-xxl-16 {
    gap: 5rem !important; }
  .gap-xxl-17 {
    gap: 5.3125rem !important; }
  .gap-xxl-18 {
    gap: 5.625rem !important; }
  .gap-xxl-19 {
    gap: 5.9375rem !important; }
  .gap-xxl-20 {
    gap: 6.25rem !important; }
  .gap-xxl-21 {
    gap: 6.5625rem !important; }
  .gap-xxl-22 {
    gap: 6.875rem !important; }
  .gap-xxl-23 {
    gap: 7.1875rem !important; }
  .gap-xxl-24 {
    gap: 7.5rem !important; }
  .gap-xxl-25 {
    gap: 7.8125rem !important; }
  .gap-xxl-26 {
    gap: 8.125rem !important; }
  .gap-xxl-27 {
    gap: 8.4375rem !important; }
  .gap-xxl-28 {
    gap: 8.75rem !important; }
  .gap-xxl-29 {
    gap: 9.0625rem !important; }
  .gap-xxl-30 {
    gap: 9.375rem !important; }
  .gap-xxl-31 {
    gap: 9.6875rem !important; }
  .gap-xxl-32 {
    gap: 10rem !important; }
  .gap-xxl-33 {
    gap: 10.3125rem !important; }
  .gap-xxl-34 {
    gap: 10.625rem !important; }
  .gap-xxl-35 {
    gap: 10.9375rem !important; }
  .gap-xxl-36 {
    gap: 11.25rem !important; }
  .gap-xxl-37 {
    gap: 11.5625rem !important; }
  .gap-xxl-38 {
    gap: 11.875rem !important; }
  .gap-xxl-39 {
    gap: 12.1875rem !important; }
  .gap-xxl-40 {
    gap: 12.5rem !important; }
  .gap-xxl-base {
    gap: calc(20rem / 16) !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1921px) {
  .float-xxxl-start {
    float: left !important; }
  .float-xxxl-end {
    float: right !important; }
  .float-xxxl-none {
    float: none !important; }
  .d-xxxl-inline {
    display: inline !important; }
  .d-xxxl-inline-block {
    display: inline-block !important; }
  .d-xxxl-block {
    display: block !important; }
  .d-xxxl-grid {
    display: grid !important; }
  .d-xxxl-table {
    display: table !important; }
  .d-xxxl-table-row {
    display: table-row !important; }
  .d-xxxl-table-cell {
    display: table-cell !important; }
  .d-xxxl-flex {
    display: flex !important; }
  .d-xxxl-inline-flex {
    display: inline-flex !important; }
  .d-xxxl-none {
    display: none !important; }
  .flex-xxxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxxl-row {
    flex-direction: row !important; }
  .flex-xxxl-column {
    flex-direction: column !important; }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxxl-center {
    justify-content: center !important; }
  .justify-content-xxxl-between {
    justify-content: space-between !important; }
  .justify-content-xxxl-around {
    justify-content: space-around !important; }
  .justify-content-xxxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxxl-start {
    align-items: flex-start !important; }
  .align-items-xxxl-end {
    align-items: flex-end !important; }
  .align-items-xxxl-center {
    align-items: center !important; }
  .align-items-xxxl-baseline {
    align-items: baseline !important; }
  .align-items-xxxl-stretch {
    align-items: stretch !important; }
  .align-content-xxxl-start {
    align-content: flex-start !important; }
  .align-content-xxxl-end {
    align-content: flex-end !important; }
  .align-content-xxxl-center {
    align-content: center !important; }
  .align-content-xxxl-between {
    align-content: space-between !important; }
  .align-content-xxxl-around {
    align-content: space-around !important; }
  .align-content-xxxl-stretch {
    align-content: stretch !important; }
  .align-self-xxxl-auto {
    align-self: auto !important; }
  .align-self-xxxl-start {
    align-self: flex-start !important; }
  .align-self-xxxl-end {
    align-self: flex-end !important; }
  .align-self-xxxl-center {
    align-self: center !important; }
  .align-self-xxxl-baseline {
    align-self: baseline !important; }
  .align-self-xxxl-stretch {
    align-self: stretch !important; }
  .order-xxxl-first {
    order: -1 !important; }
  .order-xxxl-0 {
    order: 0 !important; }
  .order-xxxl-1 {
    order: 1 !important; }
  .order-xxxl-2 {
    order: 2 !important; }
  .order-xxxl-3 {
    order: 3 !important; }
  .order-xxxl-4 {
    order: 4 !important; }
  .order-xxxl-5 {
    order: 5 !important; }
  .order-xxxl-last {
    order: 6 !important; }
  .m-xxxl-0 {
    margin: 0 !important; }
  .m-xxxl-1 {
    margin: 0.3125rem !important; }
  .m-xxxl-2 {
    margin: 0.625rem !important; }
  .m-xxxl-3 {
    margin: 0.9375rem !important; }
  .m-xxxl-4 {
    margin: 1.25rem !important; }
  .m-xxxl-5 {
    margin: 1.5625rem !important; }
  .m-xxxl-6 {
    margin: 1.875rem !important; }
  .m-xxxl-7 {
    margin: 2.1875rem !important; }
  .m-xxxl-8 {
    margin: 2.5rem !important; }
  .m-xxxl-9 {
    margin: 2.8125rem !important; }
  .m-xxxl-10 {
    margin: 3.125rem !important; }
  .m-xxxl-11 {
    margin: 3.4375rem !important; }
  .m-xxxl-12 {
    margin: 3.75rem !important; }
  .m-xxxl-13 {
    margin: 4.0625rem !important; }
  .m-xxxl-14 {
    margin: 4.375rem !important; }
  .m-xxxl-15 {
    margin: 4.6875rem !important; }
  .m-xxxl-16 {
    margin: 5rem !important; }
  .m-xxxl-17 {
    margin: 5.3125rem !important; }
  .m-xxxl-18 {
    margin: 5.625rem !important; }
  .m-xxxl-19 {
    margin: 5.9375rem !important; }
  .m-xxxl-20 {
    margin: 6.25rem !important; }
  .m-xxxl-21 {
    margin: 6.5625rem !important; }
  .m-xxxl-22 {
    margin: 6.875rem !important; }
  .m-xxxl-23 {
    margin: 7.1875rem !important; }
  .m-xxxl-24 {
    margin: 7.5rem !important; }
  .m-xxxl-25 {
    margin: 7.8125rem !important; }
  .m-xxxl-26 {
    margin: 8.125rem !important; }
  .m-xxxl-27 {
    margin: 8.4375rem !important; }
  .m-xxxl-28 {
    margin: 8.75rem !important; }
  .m-xxxl-29 {
    margin: 9.0625rem !important; }
  .m-xxxl-30 {
    margin: 9.375rem !important; }
  .m-xxxl-31 {
    margin: 9.6875rem !important; }
  .m-xxxl-32 {
    margin: 10rem !important; }
  .m-xxxl-33 {
    margin: 10.3125rem !important; }
  .m-xxxl-34 {
    margin: 10.625rem !important; }
  .m-xxxl-35 {
    margin: 10.9375rem !important; }
  .m-xxxl-36 {
    margin: 11.25rem !important; }
  .m-xxxl-37 {
    margin: 11.5625rem !important; }
  .m-xxxl-38 {
    margin: 11.875rem !important; }
  .m-xxxl-39 {
    margin: 12.1875rem !important; }
  .m-xxxl-40 {
    margin: 12.5rem !important; }
  .m-xxxl-base {
    margin: calc(20rem / 16) !important; }
  .m-xxxl-auto {
    margin: auto !important; }
  .mx-xxxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxxl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important; }
  .mx-xxxl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important; }
  .mx-xxxl-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important; }
  .mx-xxxl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important; }
  .mx-xxxl-5 {
    margin-right: 1.5625rem !important;
    margin-left: 1.5625rem !important; }
  .mx-xxxl-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important; }
  .mx-xxxl-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important; }
  .mx-xxxl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-xxxl-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important; }
  .mx-xxxl-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important; }
  .mx-xxxl-11 {
    margin-right: 3.4375rem !important;
    margin-left: 3.4375rem !important; }
  .mx-xxxl-12 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important; }
  .mx-xxxl-13 {
    margin-right: 4.0625rem !important;
    margin-left: 4.0625rem !important; }
  .mx-xxxl-14 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important; }
  .mx-xxxl-15 {
    margin-right: 4.6875rem !important;
    margin-left: 4.6875rem !important; }
  .mx-xxxl-16 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-xxxl-17 {
    margin-right: 5.3125rem !important;
    margin-left: 5.3125rem !important; }
  .mx-xxxl-18 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important; }
  .mx-xxxl-19 {
    margin-right: 5.9375rem !important;
    margin-left: 5.9375rem !important; }
  .mx-xxxl-20 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important; }
  .mx-xxxl-21 {
    margin-right: 6.5625rem !important;
    margin-left: 6.5625rem !important; }
  .mx-xxxl-22 {
    margin-right: 6.875rem !important;
    margin-left: 6.875rem !important; }
  .mx-xxxl-23 {
    margin-right: 7.1875rem !important;
    margin-left: 7.1875rem !important; }
  .mx-xxxl-24 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-xxxl-25 {
    margin-right: 7.8125rem !important;
    margin-left: 7.8125rem !important; }
  .mx-xxxl-26 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important; }
  .mx-xxxl-27 {
    margin-right: 8.4375rem !important;
    margin-left: 8.4375rem !important; }
  .mx-xxxl-28 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important; }
  .mx-xxxl-29 {
    margin-right: 9.0625rem !important;
    margin-left: 9.0625rem !important; }
  .mx-xxxl-30 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important; }
  .mx-xxxl-31 {
    margin-right: 9.6875rem !important;
    margin-left: 9.6875rem !important; }
  .mx-xxxl-32 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-xxxl-33 {
    margin-right: 10.3125rem !important;
    margin-left: 10.3125rem !important; }
  .mx-xxxl-34 {
    margin-right: 10.625rem !important;
    margin-left: 10.625rem !important; }
  .mx-xxxl-35 {
    margin-right: 10.9375rem !important;
    margin-left: 10.9375rem !important; }
  .mx-xxxl-36 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important; }
  .mx-xxxl-37 {
    margin-right: 11.5625rem !important;
    margin-left: 11.5625rem !important; }
  .mx-xxxl-38 {
    margin-right: 11.875rem !important;
    margin-left: 11.875rem !important; }
  .mx-xxxl-39 {
    margin-right: 12.1875rem !important;
    margin-left: 12.1875rem !important; }
  .mx-xxxl-40 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-xxxl-base {
    margin-right: calc(20rem / 16) !important;
    margin-left: calc(20rem / 16) !important; }
  .mx-xxxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxxl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important; }
  .my-xxxl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important; }
  .my-xxxl-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important; }
  .my-xxxl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .my-xxxl-5 {
    margin-top: 1.5625rem !important;
    margin-bottom: 1.5625rem !important; }
  .my-xxxl-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important; }
  .my-xxxl-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important; }
  .my-xxxl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-xxxl-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important; }
  .my-xxxl-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important; }
  .my-xxxl-11 {
    margin-top: 3.4375rem !important;
    margin-bottom: 3.4375rem !important; }
  .my-xxxl-12 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important; }
  .my-xxxl-13 {
    margin-top: 4.0625rem !important;
    margin-bottom: 4.0625rem !important; }
  .my-xxxl-14 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important; }
  .my-xxxl-15 {
    margin-top: 4.6875rem !important;
    margin-bottom: 4.6875rem !important; }
  .my-xxxl-16 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-xxxl-17 {
    margin-top: 5.3125rem !important;
    margin-bottom: 5.3125rem !important; }
  .my-xxxl-18 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important; }
  .my-xxxl-19 {
    margin-top: 5.9375rem !important;
    margin-bottom: 5.9375rem !important; }
  .my-xxxl-20 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important; }
  .my-xxxl-21 {
    margin-top: 6.5625rem !important;
    margin-bottom: 6.5625rem !important; }
  .my-xxxl-22 {
    margin-top: 6.875rem !important;
    margin-bottom: 6.875rem !important; }
  .my-xxxl-23 {
    margin-top: 7.1875rem !important;
    margin-bottom: 7.1875rem !important; }
  .my-xxxl-24 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-xxxl-25 {
    margin-top: 7.8125rem !important;
    margin-bottom: 7.8125rem !important; }
  .my-xxxl-26 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important; }
  .my-xxxl-27 {
    margin-top: 8.4375rem !important;
    margin-bottom: 8.4375rem !important; }
  .my-xxxl-28 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important; }
  .my-xxxl-29 {
    margin-top: 9.0625rem !important;
    margin-bottom: 9.0625rem !important; }
  .my-xxxl-30 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important; }
  .my-xxxl-31 {
    margin-top: 9.6875rem !important;
    margin-bottom: 9.6875rem !important; }
  .my-xxxl-32 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-xxxl-33 {
    margin-top: 10.3125rem !important;
    margin-bottom: 10.3125rem !important; }
  .my-xxxl-34 {
    margin-top: 10.625rem !important;
    margin-bottom: 10.625rem !important; }
  .my-xxxl-35 {
    margin-top: 10.9375rem !important;
    margin-bottom: 10.9375rem !important; }
  .my-xxxl-36 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important; }
  .my-xxxl-37 {
    margin-top: 11.5625rem !important;
    margin-bottom: 11.5625rem !important; }
  .my-xxxl-38 {
    margin-top: 11.875rem !important;
    margin-bottom: 11.875rem !important; }
  .my-xxxl-39 {
    margin-top: 12.1875rem !important;
    margin-bottom: 12.1875rem !important; }
  .my-xxxl-40 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-xxxl-base {
    margin-top: calc(20rem / 16) !important;
    margin-bottom: calc(20rem / 16) !important; }
  .my-xxxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxxl-0 {
    margin-top: 0 !important; }
  .mt-xxxl-1 {
    margin-top: 0.3125rem !important; }
  .mt-xxxl-2 {
    margin-top: 0.625rem !important; }
  .mt-xxxl-3 {
    margin-top: 0.9375rem !important; }
  .mt-xxxl-4 {
    margin-top: 1.25rem !important; }
  .mt-xxxl-5 {
    margin-top: 1.5625rem !important; }
  .mt-xxxl-6 {
    margin-top: 1.875rem !important; }
  .mt-xxxl-7 {
    margin-top: 2.1875rem !important; }
  .mt-xxxl-8 {
    margin-top: 2.5rem !important; }
  .mt-xxxl-9 {
    margin-top: 2.8125rem !important; }
  .mt-xxxl-10 {
    margin-top: 3.125rem !important; }
  .mt-xxxl-11 {
    margin-top: 3.4375rem !important; }
  .mt-xxxl-12 {
    margin-top: 3.75rem !important; }
  .mt-xxxl-13 {
    margin-top: 4.0625rem !important; }
  .mt-xxxl-14 {
    margin-top: 4.375rem !important; }
  .mt-xxxl-15 {
    margin-top: 4.6875rem !important; }
  .mt-xxxl-16 {
    margin-top: 5rem !important; }
  .mt-xxxl-17 {
    margin-top: 5.3125rem !important; }
  .mt-xxxl-18 {
    margin-top: 5.625rem !important; }
  .mt-xxxl-19 {
    margin-top: 5.9375rem !important; }
  .mt-xxxl-20 {
    margin-top: 6.25rem !important; }
  .mt-xxxl-21 {
    margin-top: 6.5625rem !important; }
  .mt-xxxl-22 {
    margin-top: 6.875rem !important; }
  .mt-xxxl-23 {
    margin-top: 7.1875rem !important; }
  .mt-xxxl-24 {
    margin-top: 7.5rem !important; }
  .mt-xxxl-25 {
    margin-top: 7.8125rem !important; }
  .mt-xxxl-26 {
    margin-top: 8.125rem !important; }
  .mt-xxxl-27 {
    margin-top: 8.4375rem !important; }
  .mt-xxxl-28 {
    margin-top: 8.75rem !important; }
  .mt-xxxl-29 {
    margin-top: 9.0625rem !important; }
  .mt-xxxl-30 {
    margin-top: 9.375rem !important; }
  .mt-xxxl-31 {
    margin-top: 9.6875rem !important; }
  .mt-xxxl-32 {
    margin-top: 10rem !important; }
  .mt-xxxl-33 {
    margin-top: 10.3125rem !important; }
  .mt-xxxl-34 {
    margin-top: 10.625rem !important; }
  .mt-xxxl-35 {
    margin-top: 10.9375rem !important; }
  .mt-xxxl-36 {
    margin-top: 11.25rem !important; }
  .mt-xxxl-37 {
    margin-top: 11.5625rem !important; }
  .mt-xxxl-38 {
    margin-top: 11.875rem !important; }
  .mt-xxxl-39 {
    margin-top: 12.1875rem !important; }
  .mt-xxxl-40 {
    margin-top: 12.5rem !important; }
  .mt-xxxl-base {
    margin-top: calc(20rem / 16) !important; }
  .mt-xxxl-auto {
    margin-top: auto !important; }
  .me-xxxl-0 {
    margin-right: 0 !important; }
  .me-xxxl-1 {
    margin-right: 0.3125rem !important; }
  .me-xxxl-2 {
    margin-right: 0.625rem !important; }
  .me-xxxl-3 {
    margin-right: 0.9375rem !important; }
  .me-xxxl-4 {
    margin-right: 1.25rem !important; }
  .me-xxxl-5 {
    margin-right: 1.5625rem !important; }
  .me-xxxl-6 {
    margin-right: 1.875rem !important; }
  .me-xxxl-7 {
    margin-right: 2.1875rem !important; }
  .me-xxxl-8 {
    margin-right: 2.5rem !important; }
  .me-xxxl-9 {
    margin-right: 2.8125rem !important; }
  .me-xxxl-10 {
    margin-right: 3.125rem !important; }
  .me-xxxl-11 {
    margin-right: 3.4375rem !important; }
  .me-xxxl-12 {
    margin-right: 3.75rem !important; }
  .me-xxxl-13 {
    margin-right: 4.0625rem !important; }
  .me-xxxl-14 {
    margin-right: 4.375rem !important; }
  .me-xxxl-15 {
    margin-right: 4.6875rem !important; }
  .me-xxxl-16 {
    margin-right: 5rem !important; }
  .me-xxxl-17 {
    margin-right: 5.3125rem !important; }
  .me-xxxl-18 {
    margin-right: 5.625rem !important; }
  .me-xxxl-19 {
    margin-right: 5.9375rem !important; }
  .me-xxxl-20 {
    margin-right: 6.25rem !important; }
  .me-xxxl-21 {
    margin-right: 6.5625rem !important; }
  .me-xxxl-22 {
    margin-right: 6.875rem !important; }
  .me-xxxl-23 {
    margin-right: 7.1875rem !important; }
  .me-xxxl-24 {
    margin-right: 7.5rem !important; }
  .me-xxxl-25 {
    margin-right: 7.8125rem !important; }
  .me-xxxl-26 {
    margin-right: 8.125rem !important; }
  .me-xxxl-27 {
    margin-right: 8.4375rem !important; }
  .me-xxxl-28 {
    margin-right: 8.75rem !important; }
  .me-xxxl-29 {
    margin-right: 9.0625rem !important; }
  .me-xxxl-30 {
    margin-right: 9.375rem !important; }
  .me-xxxl-31 {
    margin-right: 9.6875rem !important; }
  .me-xxxl-32 {
    margin-right: 10rem !important; }
  .me-xxxl-33 {
    margin-right: 10.3125rem !important; }
  .me-xxxl-34 {
    margin-right: 10.625rem !important; }
  .me-xxxl-35 {
    margin-right: 10.9375rem !important; }
  .me-xxxl-36 {
    margin-right: 11.25rem !important; }
  .me-xxxl-37 {
    margin-right: 11.5625rem !important; }
  .me-xxxl-38 {
    margin-right: 11.875rem !important; }
  .me-xxxl-39 {
    margin-right: 12.1875rem !important; }
  .me-xxxl-40 {
    margin-right: 12.5rem !important; }
  .me-xxxl-base {
    margin-right: calc(20rem / 16) !important; }
  .me-xxxl-auto {
    margin-right: auto !important; }
  .mb-xxxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxxl-1 {
    margin-bottom: 0.3125rem !important; }
  .mb-xxxl-2 {
    margin-bottom: 0.625rem !important; }
  .mb-xxxl-3 {
    margin-bottom: 0.9375rem !important; }
  .mb-xxxl-4 {
    margin-bottom: 1.25rem !important; }
  .mb-xxxl-5 {
    margin-bottom: 1.5625rem !important; }
  .mb-xxxl-6 {
    margin-bottom: 1.875rem !important; }
  .mb-xxxl-7 {
    margin-bottom: 2.1875rem !important; }
  .mb-xxxl-8 {
    margin-bottom: 2.5rem !important; }
  .mb-xxxl-9 {
    margin-bottom: 2.8125rem !important; }
  .mb-xxxl-10 {
    margin-bottom: 3.125rem !important; }
  .mb-xxxl-11 {
    margin-bottom: 3.4375rem !important; }
  .mb-xxxl-12 {
    margin-bottom: 3.75rem !important; }
  .mb-xxxl-13 {
    margin-bottom: 4.0625rem !important; }
  .mb-xxxl-14 {
    margin-bottom: 4.375rem !important; }
  .mb-xxxl-15 {
    margin-bottom: 4.6875rem !important; }
  .mb-xxxl-16 {
    margin-bottom: 5rem !important; }
  .mb-xxxl-17 {
    margin-bottom: 5.3125rem !important; }
  .mb-xxxl-18 {
    margin-bottom: 5.625rem !important; }
  .mb-xxxl-19 {
    margin-bottom: 5.9375rem !important; }
  .mb-xxxl-20 {
    margin-bottom: 6.25rem !important; }
  .mb-xxxl-21 {
    margin-bottom: 6.5625rem !important; }
  .mb-xxxl-22 {
    margin-bottom: 6.875rem !important; }
  .mb-xxxl-23 {
    margin-bottom: 7.1875rem !important; }
  .mb-xxxl-24 {
    margin-bottom: 7.5rem !important; }
  .mb-xxxl-25 {
    margin-bottom: 7.8125rem !important; }
  .mb-xxxl-26 {
    margin-bottom: 8.125rem !important; }
  .mb-xxxl-27 {
    margin-bottom: 8.4375rem !important; }
  .mb-xxxl-28 {
    margin-bottom: 8.75rem !important; }
  .mb-xxxl-29 {
    margin-bottom: 9.0625rem !important; }
  .mb-xxxl-30 {
    margin-bottom: 9.375rem !important; }
  .mb-xxxl-31 {
    margin-bottom: 9.6875rem !important; }
  .mb-xxxl-32 {
    margin-bottom: 10rem !important; }
  .mb-xxxl-33 {
    margin-bottom: 10.3125rem !important; }
  .mb-xxxl-34 {
    margin-bottom: 10.625rem !important; }
  .mb-xxxl-35 {
    margin-bottom: 10.9375rem !important; }
  .mb-xxxl-36 {
    margin-bottom: 11.25rem !important; }
  .mb-xxxl-37 {
    margin-bottom: 11.5625rem !important; }
  .mb-xxxl-38 {
    margin-bottom: 11.875rem !important; }
  .mb-xxxl-39 {
    margin-bottom: 12.1875rem !important; }
  .mb-xxxl-40 {
    margin-bottom: 12.5rem !important; }
  .mb-xxxl-base {
    margin-bottom: calc(20rem / 16) !important; }
  .mb-xxxl-auto {
    margin-bottom: auto !important; }
  .ms-xxxl-0 {
    margin-left: 0 !important; }
  .ms-xxxl-1 {
    margin-left: 0.3125rem !important; }
  .ms-xxxl-2 {
    margin-left: 0.625rem !important; }
  .ms-xxxl-3 {
    margin-left: 0.9375rem !important; }
  .ms-xxxl-4 {
    margin-left: 1.25rem !important; }
  .ms-xxxl-5 {
    margin-left: 1.5625rem !important; }
  .ms-xxxl-6 {
    margin-left: 1.875rem !important; }
  .ms-xxxl-7 {
    margin-left: 2.1875rem !important; }
  .ms-xxxl-8 {
    margin-left: 2.5rem !important; }
  .ms-xxxl-9 {
    margin-left: 2.8125rem !important; }
  .ms-xxxl-10 {
    margin-left: 3.125rem !important; }
  .ms-xxxl-11 {
    margin-left: 3.4375rem !important; }
  .ms-xxxl-12 {
    margin-left: 3.75rem !important; }
  .ms-xxxl-13 {
    margin-left: 4.0625rem !important; }
  .ms-xxxl-14 {
    margin-left: 4.375rem !important; }
  .ms-xxxl-15 {
    margin-left: 4.6875rem !important; }
  .ms-xxxl-16 {
    margin-left: 5rem !important; }
  .ms-xxxl-17 {
    margin-left: 5.3125rem !important; }
  .ms-xxxl-18 {
    margin-left: 5.625rem !important; }
  .ms-xxxl-19 {
    margin-left: 5.9375rem !important; }
  .ms-xxxl-20 {
    margin-left: 6.25rem !important; }
  .ms-xxxl-21 {
    margin-left: 6.5625rem !important; }
  .ms-xxxl-22 {
    margin-left: 6.875rem !important; }
  .ms-xxxl-23 {
    margin-left: 7.1875rem !important; }
  .ms-xxxl-24 {
    margin-left: 7.5rem !important; }
  .ms-xxxl-25 {
    margin-left: 7.8125rem !important; }
  .ms-xxxl-26 {
    margin-left: 8.125rem !important; }
  .ms-xxxl-27 {
    margin-left: 8.4375rem !important; }
  .ms-xxxl-28 {
    margin-left: 8.75rem !important; }
  .ms-xxxl-29 {
    margin-left: 9.0625rem !important; }
  .ms-xxxl-30 {
    margin-left: 9.375rem !important; }
  .ms-xxxl-31 {
    margin-left: 9.6875rem !important; }
  .ms-xxxl-32 {
    margin-left: 10rem !important; }
  .ms-xxxl-33 {
    margin-left: 10.3125rem !important; }
  .ms-xxxl-34 {
    margin-left: 10.625rem !important; }
  .ms-xxxl-35 {
    margin-left: 10.9375rem !important; }
  .ms-xxxl-36 {
    margin-left: 11.25rem !important; }
  .ms-xxxl-37 {
    margin-left: 11.5625rem !important; }
  .ms-xxxl-38 {
    margin-left: 11.875rem !important; }
  .ms-xxxl-39 {
    margin-left: 12.1875rem !important; }
  .ms-xxxl-40 {
    margin-left: 12.5rem !important; }
  .ms-xxxl-base {
    margin-left: calc(20rem / 16) !important; }
  .ms-xxxl-auto {
    margin-left: auto !important; }
  .m-xxxl-n1 {
    margin: -0.3125rem !important; }
  .m-xxxl-n2 {
    margin: -0.625rem !important; }
  .m-xxxl-n3 {
    margin: -0.9375rem !important; }
  .m-xxxl-n4 {
    margin: -1.25rem !important; }
  .m-xxxl-n5 {
    margin: -1.5625rem !important; }
  .m-xxxl-n6 {
    margin: -1.875rem !important; }
  .m-xxxl-n7 {
    margin: -2.1875rem !important; }
  .m-xxxl-n8 {
    margin: -2.5rem !important; }
  .m-xxxl-n9 {
    margin: -2.8125rem !important; }
  .m-xxxl-n10 {
    margin: -3.125rem !important; }
  .m-xxxl-n11 {
    margin: -3.4375rem !important; }
  .m-xxxl-n12 {
    margin: -3.75rem !important; }
  .m-xxxl-n13 {
    margin: -4.0625rem !important; }
  .m-xxxl-n14 {
    margin: -4.375rem !important; }
  .m-xxxl-n15 {
    margin: -4.6875rem !important; }
  .m-xxxl-n16 {
    margin: -5rem !important; }
  .m-xxxl-n17 {
    margin: -5.3125rem !important; }
  .m-xxxl-n18 {
    margin: -5.625rem !important; }
  .m-xxxl-n19 {
    margin: -5.9375rem !important; }
  .m-xxxl-n20 {
    margin: -6.25rem !important; }
  .m-xxxl-n21 {
    margin: -6.5625rem !important; }
  .m-xxxl-n22 {
    margin: -6.875rem !important; }
  .m-xxxl-n23 {
    margin: -7.1875rem !important; }
  .m-xxxl-n24 {
    margin: -7.5rem !important; }
  .m-xxxl-n25 {
    margin: -7.8125rem !important; }
  .m-xxxl-n26 {
    margin: -8.125rem !important; }
  .m-xxxl-n27 {
    margin: -8.4375rem !important; }
  .m-xxxl-n28 {
    margin: -8.75rem !important; }
  .m-xxxl-n29 {
    margin: -9.0625rem !important; }
  .m-xxxl-n30 {
    margin: -9.375rem !important; }
  .m-xxxl-n31 {
    margin: -9.6875rem !important; }
  .m-xxxl-n32 {
    margin: -10rem !important; }
  .m-xxxl-n33 {
    margin: -10.3125rem !important; }
  .m-xxxl-n34 {
    margin: -10.625rem !important; }
  .m-xxxl-n35 {
    margin: -10.9375rem !important; }
  .m-xxxl-n36 {
    margin: -11.25rem !important; }
  .m-xxxl-n37 {
    margin: -11.5625rem !important; }
  .m-xxxl-n38 {
    margin: -11.875rem !important; }
  .m-xxxl-n39 {
    margin: -12.1875rem !important; }
  .m-xxxl-n40 {
    margin: -12.5rem !important; }
  .m-xxxl-nbase {
    margin: -calc(20rem / 16) !important; }
  .mx-xxxl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important; }
  .mx-xxxl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important; }
  .mx-xxxl-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important; }
  .mx-xxxl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important; }
  .mx-xxxl-n5 {
    margin-right: -1.5625rem !important;
    margin-left: -1.5625rem !important; }
  .mx-xxxl-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important; }
  .mx-xxxl-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important; }
  .mx-xxxl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-xxxl-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important; }
  .mx-xxxl-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important; }
  .mx-xxxl-n11 {
    margin-right: -3.4375rem !important;
    margin-left: -3.4375rem !important; }
  .mx-xxxl-n12 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important; }
  .mx-xxxl-n13 {
    margin-right: -4.0625rem !important;
    margin-left: -4.0625rem !important; }
  .mx-xxxl-n14 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important; }
  .mx-xxxl-n15 {
    margin-right: -4.6875rem !important;
    margin-left: -4.6875rem !important; }
  .mx-xxxl-n16 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-xxxl-n17 {
    margin-right: -5.3125rem !important;
    margin-left: -5.3125rem !important; }
  .mx-xxxl-n18 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important; }
  .mx-xxxl-n19 {
    margin-right: -5.9375rem !important;
    margin-left: -5.9375rem !important; }
  .mx-xxxl-n20 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important; }
  .mx-xxxl-n21 {
    margin-right: -6.5625rem !important;
    margin-left: -6.5625rem !important; }
  .mx-xxxl-n22 {
    margin-right: -6.875rem !important;
    margin-left: -6.875rem !important; }
  .mx-xxxl-n23 {
    margin-right: -7.1875rem !important;
    margin-left: -7.1875rem !important; }
  .mx-xxxl-n24 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-xxxl-n25 {
    margin-right: -7.8125rem !important;
    margin-left: -7.8125rem !important; }
  .mx-xxxl-n26 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important; }
  .mx-xxxl-n27 {
    margin-right: -8.4375rem !important;
    margin-left: -8.4375rem !important; }
  .mx-xxxl-n28 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important; }
  .mx-xxxl-n29 {
    margin-right: -9.0625rem !important;
    margin-left: -9.0625rem !important; }
  .mx-xxxl-n30 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important; }
  .mx-xxxl-n31 {
    margin-right: -9.6875rem !important;
    margin-left: -9.6875rem !important; }
  .mx-xxxl-n32 {
    margin-right: -10rem !important;
    margin-left: -10rem !important; }
  .mx-xxxl-n33 {
    margin-right: -10.3125rem !important;
    margin-left: -10.3125rem !important; }
  .mx-xxxl-n34 {
    margin-right: -10.625rem !important;
    margin-left: -10.625rem !important; }
  .mx-xxxl-n35 {
    margin-right: -10.9375rem !important;
    margin-left: -10.9375rem !important; }
  .mx-xxxl-n36 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important; }
  .mx-xxxl-n37 {
    margin-right: -11.5625rem !important;
    margin-left: -11.5625rem !important; }
  .mx-xxxl-n38 {
    margin-right: -11.875rem !important;
    margin-left: -11.875rem !important; }
  .mx-xxxl-n39 {
    margin-right: -12.1875rem !important;
    margin-left: -12.1875rem !important; }
  .mx-xxxl-n40 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important; }
  .mx-xxxl-nbase {
    margin-right: -calc(20rem / 16) !important;
    margin-left: -calc(20rem / 16) !important; }
  .my-xxxl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important; }
  .my-xxxl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important; }
  .my-xxxl-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important; }
  .my-xxxl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .my-xxxl-n5 {
    margin-top: -1.5625rem !important;
    margin-bottom: -1.5625rem !important; }
  .my-xxxl-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important; }
  .my-xxxl-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important; }
  .my-xxxl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-xxxl-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important; }
  .my-xxxl-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important; }
  .my-xxxl-n11 {
    margin-top: -3.4375rem !important;
    margin-bottom: -3.4375rem !important; }
  .my-xxxl-n12 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important; }
  .my-xxxl-n13 {
    margin-top: -4.0625rem !important;
    margin-bottom: -4.0625rem !important; }
  .my-xxxl-n14 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important; }
  .my-xxxl-n15 {
    margin-top: -4.6875rem !important;
    margin-bottom: -4.6875rem !important; }
  .my-xxxl-n16 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-xxxl-n17 {
    margin-top: -5.3125rem !important;
    margin-bottom: -5.3125rem !important; }
  .my-xxxl-n18 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important; }
  .my-xxxl-n19 {
    margin-top: -5.9375rem !important;
    margin-bottom: -5.9375rem !important; }
  .my-xxxl-n20 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important; }
  .my-xxxl-n21 {
    margin-top: -6.5625rem !important;
    margin-bottom: -6.5625rem !important; }
  .my-xxxl-n22 {
    margin-top: -6.875rem !important;
    margin-bottom: -6.875rem !important; }
  .my-xxxl-n23 {
    margin-top: -7.1875rem !important;
    margin-bottom: -7.1875rem !important; }
  .my-xxxl-n24 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-xxxl-n25 {
    margin-top: -7.8125rem !important;
    margin-bottom: -7.8125rem !important; }
  .my-xxxl-n26 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important; }
  .my-xxxl-n27 {
    margin-top: -8.4375rem !important;
    margin-bottom: -8.4375rem !important; }
  .my-xxxl-n28 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important; }
  .my-xxxl-n29 {
    margin-top: -9.0625rem !important;
    margin-bottom: -9.0625rem !important; }
  .my-xxxl-n30 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important; }
  .my-xxxl-n31 {
    margin-top: -9.6875rem !important;
    margin-bottom: -9.6875rem !important; }
  .my-xxxl-n32 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .my-xxxl-n33 {
    margin-top: -10.3125rem !important;
    margin-bottom: -10.3125rem !important; }
  .my-xxxl-n34 {
    margin-top: -10.625rem !important;
    margin-bottom: -10.625rem !important; }
  .my-xxxl-n35 {
    margin-top: -10.9375rem !important;
    margin-bottom: -10.9375rem !important; }
  .my-xxxl-n36 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important; }
  .my-xxxl-n37 {
    margin-top: -11.5625rem !important;
    margin-bottom: -11.5625rem !important; }
  .my-xxxl-n38 {
    margin-top: -11.875rem !important;
    margin-bottom: -11.875rem !important; }
  .my-xxxl-n39 {
    margin-top: -12.1875rem !important;
    margin-bottom: -12.1875rem !important; }
  .my-xxxl-n40 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important; }
  .my-xxxl-nbase {
    margin-top: -calc(20rem / 16) !important;
    margin-bottom: -calc(20rem / 16) !important; }
  .mt-xxxl-n1 {
    margin-top: -0.3125rem !important; }
  .mt-xxxl-n2 {
    margin-top: -0.625rem !important; }
  .mt-xxxl-n3 {
    margin-top: -0.9375rem !important; }
  .mt-xxxl-n4 {
    margin-top: -1.25rem !important; }
  .mt-xxxl-n5 {
    margin-top: -1.5625rem !important; }
  .mt-xxxl-n6 {
    margin-top: -1.875rem !important; }
  .mt-xxxl-n7 {
    margin-top: -2.1875rem !important; }
  .mt-xxxl-n8 {
    margin-top: -2.5rem !important; }
  .mt-xxxl-n9 {
    margin-top: -2.8125rem !important; }
  .mt-xxxl-n10 {
    margin-top: -3.125rem !important; }
  .mt-xxxl-n11 {
    margin-top: -3.4375rem !important; }
  .mt-xxxl-n12 {
    margin-top: -3.75rem !important; }
  .mt-xxxl-n13 {
    margin-top: -4.0625rem !important; }
  .mt-xxxl-n14 {
    margin-top: -4.375rem !important; }
  .mt-xxxl-n15 {
    margin-top: -4.6875rem !important; }
  .mt-xxxl-n16 {
    margin-top: -5rem !important; }
  .mt-xxxl-n17 {
    margin-top: -5.3125rem !important; }
  .mt-xxxl-n18 {
    margin-top: -5.625rem !important; }
  .mt-xxxl-n19 {
    margin-top: -5.9375rem !important; }
  .mt-xxxl-n20 {
    margin-top: -6.25rem !important; }
  .mt-xxxl-n21 {
    margin-top: -6.5625rem !important; }
  .mt-xxxl-n22 {
    margin-top: -6.875rem !important; }
  .mt-xxxl-n23 {
    margin-top: -7.1875rem !important; }
  .mt-xxxl-n24 {
    margin-top: -7.5rem !important; }
  .mt-xxxl-n25 {
    margin-top: -7.8125rem !important; }
  .mt-xxxl-n26 {
    margin-top: -8.125rem !important; }
  .mt-xxxl-n27 {
    margin-top: -8.4375rem !important; }
  .mt-xxxl-n28 {
    margin-top: -8.75rem !important; }
  .mt-xxxl-n29 {
    margin-top: -9.0625rem !important; }
  .mt-xxxl-n30 {
    margin-top: -9.375rem !important; }
  .mt-xxxl-n31 {
    margin-top: -9.6875rem !important; }
  .mt-xxxl-n32 {
    margin-top: -10rem !important; }
  .mt-xxxl-n33 {
    margin-top: -10.3125rem !important; }
  .mt-xxxl-n34 {
    margin-top: -10.625rem !important; }
  .mt-xxxl-n35 {
    margin-top: -10.9375rem !important; }
  .mt-xxxl-n36 {
    margin-top: -11.25rem !important; }
  .mt-xxxl-n37 {
    margin-top: -11.5625rem !important; }
  .mt-xxxl-n38 {
    margin-top: -11.875rem !important; }
  .mt-xxxl-n39 {
    margin-top: -12.1875rem !important; }
  .mt-xxxl-n40 {
    margin-top: -12.5rem !important; }
  .mt-xxxl-nbase {
    margin-top: -calc(20rem / 16) !important; }
  .me-xxxl-n1 {
    margin-right: -0.3125rem !important; }
  .me-xxxl-n2 {
    margin-right: -0.625rem !important; }
  .me-xxxl-n3 {
    margin-right: -0.9375rem !important; }
  .me-xxxl-n4 {
    margin-right: -1.25rem !important; }
  .me-xxxl-n5 {
    margin-right: -1.5625rem !important; }
  .me-xxxl-n6 {
    margin-right: -1.875rem !important; }
  .me-xxxl-n7 {
    margin-right: -2.1875rem !important; }
  .me-xxxl-n8 {
    margin-right: -2.5rem !important; }
  .me-xxxl-n9 {
    margin-right: -2.8125rem !important; }
  .me-xxxl-n10 {
    margin-right: -3.125rem !important; }
  .me-xxxl-n11 {
    margin-right: -3.4375rem !important; }
  .me-xxxl-n12 {
    margin-right: -3.75rem !important; }
  .me-xxxl-n13 {
    margin-right: -4.0625rem !important; }
  .me-xxxl-n14 {
    margin-right: -4.375rem !important; }
  .me-xxxl-n15 {
    margin-right: -4.6875rem !important; }
  .me-xxxl-n16 {
    margin-right: -5rem !important; }
  .me-xxxl-n17 {
    margin-right: -5.3125rem !important; }
  .me-xxxl-n18 {
    margin-right: -5.625rem !important; }
  .me-xxxl-n19 {
    margin-right: -5.9375rem !important; }
  .me-xxxl-n20 {
    margin-right: -6.25rem !important; }
  .me-xxxl-n21 {
    margin-right: -6.5625rem !important; }
  .me-xxxl-n22 {
    margin-right: -6.875rem !important; }
  .me-xxxl-n23 {
    margin-right: -7.1875rem !important; }
  .me-xxxl-n24 {
    margin-right: -7.5rem !important; }
  .me-xxxl-n25 {
    margin-right: -7.8125rem !important; }
  .me-xxxl-n26 {
    margin-right: -8.125rem !important; }
  .me-xxxl-n27 {
    margin-right: -8.4375rem !important; }
  .me-xxxl-n28 {
    margin-right: -8.75rem !important; }
  .me-xxxl-n29 {
    margin-right: -9.0625rem !important; }
  .me-xxxl-n30 {
    margin-right: -9.375rem !important; }
  .me-xxxl-n31 {
    margin-right: -9.6875rem !important; }
  .me-xxxl-n32 {
    margin-right: -10rem !important; }
  .me-xxxl-n33 {
    margin-right: -10.3125rem !important; }
  .me-xxxl-n34 {
    margin-right: -10.625rem !important; }
  .me-xxxl-n35 {
    margin-right: -10.9375rem !important; }
  .me-xxxl-n36 {
    margin-right: -11.25rem !important; }
  .me-xxxl-n37 {
    margin-right: -11.5625rem !important; }
  .me-xxxl-n38 {
    margin-right: -11.875rem !important; }
  .me-xxxl-n39 {
    margin-right: -12.1875rem !important; }
  .me-xxxl-n40 {
    margin-right: -12.5rem !important; }
  .me-xxxl-nbase {
    margin-right: -calc(20rem / 16) !important; }
  .mb-xxxl-n1 {
    margin-bottom: -0.3125rem !important; }
  .mb-xxxl-n2 {
    margin-bottom: -0.625rem !important; }
  .mb-xxxl-n3 {
    margin-bottom: -0.9375rem !important; }
  .mb-xxxl-n4 {
    margin-bottom: -1.25rem !important; }
  .mb-xxxl-n5 {
    margin-bottom: -1.5625rem !important; }
  .mb-xxxl-n6 {
    margin-bottom: -1.875rem !important; }
  .mb-xxxl-n7 {
    margin-bottom: -2.1875rem !important; }
  .mb-xxxl-n8 {
    margin-bottom: -2.5rem !important; }
  .mb-xxxl-n9 {
    margin-bottom: -2.8125rem !important; }
  .mb-xxxl-n10 {
    margin-bottom: -3.125rem !important; }
  .mb-xxxl-n11 {
    margin-bottom: -3.4375rem !important; }
  .mb-xxxl-n12 {
    margin-bottom: -3.75rem !important; }
  .mb-xxxl-n13 {
    margin-bottom: -4.0625rem !important; }
  .mb-xxxl-n14 {
    margin-bottom: -4.375rem !important; }
  .mb-xxxl-n15 {
    margin-bottom: -4.6875rem !important; }
  .mb-xxxl-n16 {
    margin-bottom: -5rem !important; }
  .mb-xxxl-n17 {
    margin-bottom: -5.3125rem !important; }
  .mb-xxxl-n18 {
    margin-bottom: -5.625rem !important; }
  .mb-xxxl-n19 {
    margin-bottom: -5.9375rem !important; }
  .mb-xxxl-n20 {
    margin-bottom: -6.25rem !important; }
  .mb-xxxl-n21 {
    margin-bottom: -6.5625rem !important; }
  .mb-xxxl-n22 {
    margin-bottom: -6.875rem !important; }
  .mb-xxxl-n23 {
    margin-bottom: -7.1875rem !important; }
  .mb-xxxl-n24 {
    margin-bottom: -7.5rem !important; }
  .mb-xxxl-n25 {
    margin-bottom: -7.8125rem !important; }
  .mb-xxxl-n26 {
    margin-bottom: -8.125rem !important; }
  .mb-xxxl-n27 {
    margin-bottom: -8.4375rem !important; }
  .mb-xxxl-n28 {
    margin-bottom: -8.75rem !important; }
  .mb-xxxl-n29 {
    margin-bottom: -9.0625rem !important; }
  .mb-xxxl-n30 {
    margin-bottom: -9.375rem !important; }
  .mb-xxxl-n31 {
    margin-bottom: -9.6875rem !important; }
  .mb-xxxl-n32 {
    margin-bottom: -10rem !important; }
  .mb-xxxl-n33 {
    margin-bottom: -10.3125rem !important; }
  .mb-xxxl-n34 {
    margin-bottom: -10.625rem !important; }
  .mb-xxxl-n35 {
    margin-bottom: -10.9375rem !important; }
  .mb-xxxl-n36 {
    margin-bottom: -11.25rem !important; }
  .mb-xxxl-n37 {
    margin-bottom: -11.5625rem !important; }
  .mb-xxxl-n38 {
    margin-bottom: -11.875rem !important; }
  .mb-xxxl-n39 {
    margin-bottom: -12.1875rem !important; }
  .mb-xxxl-n40 {
    margin-bottom: -12.5rem !important; }
  .mb-xxxl-nbase {
    margin-bottom: -calc(20rem / 16) !important; }
  .ms-xxxl-n1 {
    margin-left: -0.3125rem !important; }
  .ms-xxxl-n2 {
    margin-left: -0.625rem !important; }
  .ms-xxxl-n3 {
    margin-left: -0.9375rem !important; }
  .ms-xxxl-n4 {
    margin-left: -1.25rem !important; }
  .ms-xxxl-n5 {
    margin-left: -1.5625rem !important; }
  .ms-xxxl-n6 {
    margin-left: -1.875rem !important; }
  .ms-xxxl-n7 {
    margin-left: -2.1875rem !important; }
  .ms-xxxl-n8 {
    margin-left: -2.5rem !important; }
  .ms-xxxl-n9 {
    margin-left: -2.8125rem !important; }
  .ms-xxxl-n10 {
    margin-left: -3.125rem !important; }
  .ms-xxxl-n11 {
    margin-left: -3.4375rem !important; }
  .ms-xxxl-n12 {
    margin-left: -3.75rem !important; }
  .ms-xxxl-n13 {
    margin-left: -4.0625rem !important; }
  .ms-xxxl-n14 {
    margin-left: -4.375rem !important; }
  .ms-xxxl-n15 {
    margin-left: -4.6875rem !important; }
  .ms-xxxl-n16 {
    margin-left: -5rem !important; }
  .ms-xxxl-n17 {
    margin-left: -5.3125rem !important; }
  .ms-xxxl-n18 {
    margin-left: -5.625rem !important; }
  .ms-xxxl-n19 {
    margin-left: -5.9375rem !important; }
  .ms-xxxl-n20 {
    margin-left: -6.25rem !important; }
  .ms-xxxl-n21 {
    margin-left: -6.5625rem !important; }
  .ms-xxxl-n22 {
    margin-left: -6.875rem !important; }
  .ms-xxxl-n23 {
    margin-left: -7.1875rem !important; }
  .ms-xxxl-n24 {
    margin-left: -7.5rem !important; }
  .ms-xxxl-n25 {
    margin-left: -7.8125rem !important; }
  .ms-xxxl-n26 {
    margin-left: -8.125rem !important; }
  .ms-xxxl-n27 {
    margin-left: -8.4375rem !important; }
  .ms-xxxl-n28 {
    margin-left: -8.75rem !important; }
  .ms-xxxl-n29 {
    margin-left: -9.0625rem !important; }
  .ms-xxxl-n30 {
    margin-left: -9.375rem !important; }
  .ms-xxxl-n31 {
    margin-left: -9.6875rem !important; }
  .ms-xxxl-n32 {
    margin-left: -10rem !important; }
  .ms-xxxl-n33 {
    margin-left: -10.3125rem !important; }
  .ms-xxxl-n34 {
    margin-left: -10.625rem !important; }
  .ms-xxxl-n35 {
    margin-left: -10.9375rem !important; }
  .ms-xxxl-n36 {
    margin-left: -11.25rem !important; }
  .ms-xxxl-n37 {
    margin-left: -11.5625rem !important; }
  .ms-xxxl-n38 {
    margin-left: -11.875rem !important; }
  .ms-xxxl-n39 {
    margin-left: -12.1875rem !important; }
  .ms-xxxl-n40 {
    margin-left: -12.5rem !important; }
  .ms-xxxl-nbase {
    margin-left: -calc(20rem / 16) !important; }
  .p-xxxl-0 {
    padding: 0 !important; }
  .p-xxxl-1 {
    padding: 0.3125rem !important; }
  .p-xxxl-2 {
    padding: 0.625rem !important; }
  .p-xxxl-3 {
    padding: 0.9375rem !important; }
  .p-xxxl-4 {
    padding: 1.25rem !important; }
  .p-xxxl-5 {
    padding: 1.5625rem !important; }
  .p-xxxl-6 {
    padding: 1.875rem !important; }
  .p-xxxl-7 {
    padding: 2.1875rem !important; }
  .p-xxxl-8 {
    padding: 2.5rem !important; }
  .p-xxxl-9 {
    padding: 2.8125rem !important; }
  .p-xxxl-10 {
    padding: 3.125rem !important; }
  .p-xxxl-11 {
    padding: 3.4375rem !important; }
  .p-xxxl-12 {
    padding: 3.75rem !important; }
  .p-xxxl-13 {
    padding: 4.0625rem !important; }
  .p-xxxl-14 {
    padding: 4.375rem !important; }
  .p-xxxl-15 {
    padding: 4.6875rem !important; }
  .p-xxxl-16 {
    padding: 5rem !important; }
  .p-xxxl-17 {
    padding: 5.3125rem !important; }
  .p-xxxl-18 {
    padding: 5.625rem !important; }
  .p-xxxl-19 {
    padding: 5.9375rem !important; }
  .p-xxxl-20 {
    padding: 6.25rem !important; }
  .p-xxxl-21 {
    padding: 6.5625rem !important; }
  .p-xxxl-22 {
    padding: 6.875rem !important; }
  .p-xxxl-23 {
    padding: 7.1875rem !important; }
  .p-xxxl-24 {
    padding: 7.5rem !important; }
  .p-xxxl-25 {
    padding: 7.8125rem !important; }
  .p-xxxl-26 {
    padding: 8.125rem !important; }
  .p-xxxl-27 {
    padding: 8.4375rem !important; }
  .p-xxxl-28 {
    padding: 8.75rem !important; }
  .p-xxxl-29 {
    padding: 9.0625rem !important; }
  .p-xxxl-30 {
    padding: 9.375rem !important; }
  .p-xxxl-31 {
    padding: 9.6875rem !important; }
  .p-xxxl-32 {
    padding: 10rem !important; }
  .p-xxxl-33 {
    padding: 10.3125rem !important; }
  .p-xxxl-34 {
    padding: 10.625rem !important; }
  .p-xxxl-35 {
    padding: 10.9375rem !important; }
  .p-xxxl-36 {
    padding: 11.25rem !important; }
  .p-xxxl-37 {
    padding: 11.5625rem !important; }
  .p-xxxl-38 {
    padding: 11.875rem !important; }
  .p-xxxl-39 {
    padding: 12.1875rem !important; }
  .p-xxxl-40 {
    padding: 12.5rem !important; }
  .p-xxxl-base {
    padding: calc(20rem / 16) !important; }
  .px-xxxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxxl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important; }
  .px-xxxl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important; }
  .px-xxxl-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important; }
  .px-xxxl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important; }
  .px-xxxl-5 {
    padding-right: 1.5625rem !important;
    padding-left: 1.5625rem !important; }
  .px-xxxl-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important; }
  .px-xxxl-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important; }
  .px-xxxl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-xxxl-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important; }
  .px-xxxl-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important; }
  .px-xxxl-11 {
    padding-right: 3.4375rem !important;
    padding-left: 3.4375rem !important; }
  .px-xxxl-12 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important; }
  .px-xxxl-13 {
    padding-right: 4.0625rem !important;
    padding-left: 4.0625rem !important; }
  .px-xxxl-14 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important; }
  .px-xxxl-15 {
    padding-right: 4.6875rem !important;
    padding-left: 4.6875rem !important; }
  .px-xxxl-16 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-xxxl-17 {
    padding-right: 5.3125rem !important;
    padding-left: 5.3125rem !important; }
  .px-xxxl-18 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important; }
  .px-xxxl-19 {
    padding-right: 5.9375rem !important;
    padding-left: 5.9375rem !important; }
  .px-xxxl-20 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important; }
  .px-xxxl-21 {
    padding-right: 6.5625rem !important;
    padding-left: 6.5625rem !important; }
  .px-xxxl-22 {
    padding-right: 6.875rem !important;
    padding-left: 6.875rem !important; }
  .px-xxxl-23 {
    padding-right: 7.1875rem !important;
    padding-left: 7.1875rem !important; }
  .px-xxxl-24 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-xxxl-25 {
    padding-right: 7.8125rem !important;
    padding-left: 7.8125rem !important; }
  .px-xxxl-26 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important; }
  .px-xxxl-27 {
    padding-right: 8.4375rem !important;
    padding-left: 8.4375rem !important; }
  .px-xxxl-28 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important; }
  .px-xxxl-29 {
    padding-right: 9.0625rem !important;
    padding-left: 9.0625rem !important; }
  .px-xxxl-30 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important; }
  .px-xxxl-31 {
    padding-right: 9.6875rem !important;
    padding-left: 9.6875rem !important; }
  .px-xxxl-32 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-xxxl-33 {
    padding-right: 10.3125rem !important;
    padding-left: 10.3125rem !important; }
  .px-xxxl-34 {
    padding-right: 10.625rem !important;
    padding-left: 10.625rem !important; }
  .px-xxxl-35 {
    padding-right: 10.9375rem !important;
    padding-left: 10.9375rem !important; }
  .px-xxxl-36 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important; }
  .px-xxxl-37 {
    padding-right: 11.5625rem !important;
    padding-left: 11.5625rem !important; }
  .px-xxxl-38 {
    padding-right: 11.875rem !important;
    padding-left: 11.875rem !important; }
  .px-xxxl-39 {
    padding-right: 12.1875rem !important;
    padding-left: 12.1875rem !important; }
  .px-xxxl-40 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-xxxl-base {
    padding-right: calc(20rem / 16) !important;
    padding-left: calc(20rem / 16) !important; }
  .py-xxxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxxl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important; }
  .py-xxxl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important; }
  .py-xxxl-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important; }
  .py-xxxl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .py-xxxl-5 {
    padding-top: 1.5625rem !important;
    padding-bottom: 1.5625rem !important; }
  .py-xxxl-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important; }
  .py-xxxl-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important; }
  .py-xxxl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-xxxl-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important; }
  .py-xxxl-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important; }
  .py-xxxl-11 {
    padding-top: 3.4375rem !important;
    padding-bottom: 3.4375rem !important; }
  .py-xxxl-12 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important; }
  .py-xxxl-13 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important; }
  .py-xxxl-14 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important; }
  .py-xxxl-15 {
    padding-top: 4.6875rem !important;
    padding-bottom: 4.6875rem !important; }
  .py-xxxl-16 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-xxxl-17 {
    padding-top: 5.3125rem !important;
    padding-bottom: 5.3125rem !important; }
  .py-xxxl-18 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important; }
  .py-xxxl-19 {
    padding-top: 5.9375rem !important;
    padding-bottom: 5.9375rem !important; }
  .py-xxxl-20 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important; }
  .py-xxxl-21 {
    padding-top: 6.5625rem !important;
    padding-bottom: 6.5625rem !important; }
  .py-xxxl-22 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important; }
  .py-xxxl-23 {
    padding-top: 7.1875rem !important;
    padding-bottom: 7.1875rem !important; }
  .py-xxxl-24 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-xxxl-25 {
    padding-top: 7.8125rem !important;
    padding-bottom: 7.8125rem !important; }
  .py-xxxl-26 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important; }
  .py-xxxl-27 {
    padding-top: 8.4375rem !important;
    padding-bottom: 8.4375rem !important; }
  .py-xxxl-28 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important; }
  .py-xxxl-29 {
    padding-top: 9.0625rem !important;
    padding-bottom: 9.0625rem !important; }
  .py-xxxl-30 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important; }
  .py-xxxl-31 {
    padding-top: 9.6875rem !important;
    padding-bottom: 9.6875rem !important; }
  .py-xxxl-32 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-xxxl-33 {
    padding-top: 10.3125rem !important;
    padding-bottom: 10.3125rem !important; }
  .py-xxxl-34 {
    padding-top: 10.625rem !important;
    padding-bottom: 10.625rem !important; }
  .py-xxxl-35 {
    padding-top: 10.9375rem !important;
    padding-bottom: 10.9375rem !important; }
  .py-xxxl-36 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important; }
  .py-xxxl-37 {
    padding-top: 11.5625rem !important;
    padding-bottom: 11.5625rem !important; }
  .py-xxxl-38 {
    padding-top: 11.875rem !important;
    padding-bottom: 11.875rem !important; }
  .py-xxxl-39 {
    padding-top: 12.1875rem !important;
    padding-bottom: 12.1875rem !important; }
  .py-xxxl-40 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-xxxl-base {
    padding-top: calc(20rem / 16) !important;
    padding-bottom: calc(20rem / 16) !important; }
  .pt-xxxl-0 {
    padding-top: 0 !important; }
  .pt-xxxl-1 {
    padding-top: 0.3125rem !important; }
  .pt-xxxl-2 {
    padding-top: 0.625rem !important; }
  .pt-xxxl-3 {
    padding-top: 0.9375rem !important; }
  .pt-xxxl-4 {
    padding-top: 1.25rem !important; }
  .pt-xxxl-5 {
    padding-top: 1.5625rem !important; }
  .pt-xxxl-6 {
    padding-top: 1.875rem !important; }
  .pt-xxxl-7 {
    padding-top: 2.1875rem !important; }
  .pt-xxxl-8 {
    padding-top: 2.5rem !important; }
  .pt-xxxl-9 {
    padding-top: 2.8125rem !important; }
  .pt-xxxl-10 {
    padding-top: 3.125rem !important; }
  .pt-xxxl-11 {
    padding-top: 3.4375rem !important; }
  .pt-xxxl-12 {
    padding-top: 3.75rem !important; }
  .pt-xxxl-13 {
    padding-top: 4.0625rem !important; }
  .pt-xxxl-14 {
    padding-top: 4.375rem !important; }
  .pt-xxxl-15 {
    padding-top: 4.6875rem !important; }
  .pt-xxxl-16 {
    padding-top: 5rem !important; }
  .pt-xxxl-17 {
    padding-top: 5.3125rem !important; }
  .pt-xxxl-18 {
    padding-top: 5.625rem !important; }
  .pt-xxxl-19 {
    padding-top: 5.9375rem !important; }
  .pt-xxxl-20 {
    padding-top: 6.25rem !important; }
  .pt-xxxl-21 {
    padding-top: 6.5625rem !important; }
  .pt-xxxl-22 {
    padding-top: 6.875rem !important; }
  .pt-xxxl-23 {
    padding-top: 7.1875rem !important; }
  .pt-xxxl-24 {
    padding-top: 7.5rem !important; }
  .pt-xxxl-25 {
    padding-top: 7.8125rem !important; }
  .pt-xxxl-26 {
    padding-top: 8.125rem !important; }
  .pt-xxxl-27 {
    padding-top: 8.4375rem !important; }
  .pt-xxxl-28 {
    padding-top: 8.75rem !important; }
  .pt-xxxl-29 {
    padding-top: 9.0625rem !important; }
  .pt-xxxl-30 {
    padding-top: 9.375rem !important; }
  .pt-xxxl-31 {
    padding-top: 9.6875rem !important; }
  .pt-xxxl-32 {
    padding-top: 10rem !important; }
  .pt-xxxl-33 {
    padding-top: 10.3125rem !important; }
  .pt-xxxl-34 {
    padding-top: 10.625rem !important; }
  .pt-xxxl-35 {
    padding-top: 10.9375rem !important; }
  .pt-xxxl-36 {
    padding-top: 11.25rem !important; }
  .pt-xxxl-37 {
    padding-top: 11.5625rem !important; }
  .pt-xxxl-38 {
    padding-top: 11.875rem !important; }
  .pt-xxxl-39 {
    padding-top: 12.1875rem !important; }
  .pt-xxxl-40 {
    padding-top: 12.5rem !important; }
  .pt-xxxl-base {
    padding-top: calc(20rem / 16) !important; }
  .pe-xxxl-0 {
    padding-right: 0 !important; }
  .pe-xxxl-1 {
    padding-right: 0.3125rem !important; }
  .pe-xxxl-2 {
    padding-right: 0.625rem !important; }
  .pe-xxxl-3 {
    padding-right: 0.9375rem !important; }
  .pe-xxxl-4 {
    padding-right: 1.25rem !important; }
  .pe-xxxl-5 {
    padding-right: 1.5625rem !important; }
  .pe-xxxl-6 {
    padding-right: 1.875rem !important; }
  .pe-xxxl-7 {
    padding-right: 2.1875rem !important; }
  .pe-xxxl-8 {
    padding-right: 2.5rem !important; }
  .pe-xxxl-9 {
    padding-right: 2.8125rem !important; }
  .pe-xxxl-10 {
    padding-right: 3.125rem !important; }
  .pe-xxxl-11 {
    padding-right: 3.4375rem !important; }
  .pe-xxxl-12 {
    padding-right: 3.75rem !important; }
  .pe-xxxl-13 {
    padding-right: 4.0625rem !important; }
  .pe-xxxl-14 {
    padding-right: 4.375rem !important; }
  .pe-xxxl-15 {
    padding-right: 4.6875rem !important; }
  .pe-xxxl-16 {
    padding-right: 5rem !important; }
  .pe-xxxl-17 {
    padding-right: 5.3125rem !important; }
  .pe-xxxl-18 {
    padding-right: 5.625rem !important; }
  .pe-xxxl-19 {
    padding-right: 5.9375rem !important; }
  .pe-xxxl-20 {
    padding-right: 6.25rem !important; }
  .pe-xxxl-21 {
    padding-right: 6.5625rem !important; }
  .pe-xxxl-22 {
    padding-right: 6.875rem !important; }
  .pe-xxxl-23 {
    padding-right: 7.1875rem !important; }
  .pe-xxxl-24 {
    padding-right: 7.5rem !important; }
  .pe-xxxl-25 {
    padding-right: 7.8125rem !important; }
  .pe-xxxl-26 {
    padding-right: 8.125rem !important; }
  .pe-xxxl-27 {
    padding-right: 8.4375rem !important; }
  .pe-xxxl-28 {
    padding-right: 8.75rem !important; }
  .pe-xxxl-29 {
    padding-right: 9.0625rem !important; }
  .pe-xxxl-30 {
    padding-right: 9.375rem !important; }
  .pe-xxxl-31 {
    padding-right: 9.6875rem !important; }
  .pe-xxxl-32 {
    padding-right: 10rem !important; }
  .pe-xxxl-33 {
    padding-right: 10.3125rem !important; }
  .pe-xxxl-34 {
    padding-right: 10.625rem !important; }
  .pe-xxxl-35 {
    padding-right: 10.9375rem !important; }
  .pe-xxxl-36 {
    padding-right: 11.25rem !important; }
  .pe-xxxl-37 {
    padding-right: 11.5625rem !important; }
  .pe-xxxl-38 {
    padding-right: 11.875rem !important; }
  .pe-xxxl-39 {
    padding-right: 12.1875rem !important; }
  .pe-xxxl-40 {
    padding-right: 12.5rem !important; }
  .pe-xxxl-base {
    padding-right: calc(20rem / 16) !important; }
  .pb-xxxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxxl-1 {
    padding-bottom: 0.3125rem !important; }
  .pb-xxxl-2 {
    padding-bottom: 0.625rem !important; }
  .pb-xxxl-3 {
    padding-bottom: 0.9375rem !important; }
  .pb-xxxl-4 {
    padding-bottom: 1.25rem !important; }
  .pb-xxxl-5 {
    padding-bottom: 1.5625rem !important; }
  .pb-xxxl-6 {
    padding-bottom: 1.875rem !important; }
  .pb-xxxl-7 {
    padding-bottom: 2.1875rem !important; }
  .pb-xxxl-8 {
    padding-bottom: 2.5rem !important; }
  .pb-xxxl-9 {
    padding-bottom: 2.8125rem !important; }
  .pb-xxxl-10 {
    padding-bottom: 3.125rem !important; }
  .pb-xxxl-11 {
    padding-bottom: 3.4375rem !important; }
  .pb-xxxl-12 {
    padding-bottom: 3.75rem !important; }
  .pb-xxxl-13 {
    padding-bottom: 4.0625rem !important; }
  .pb-xxxl-14 {
    padding-bottom: 4.375rem !important; }
  .pb-xxxl-15 {
    padding-bottom: 4.6875rem !important; }
  .pb-xxxl-16 {
    padding-bottom: 5rem !important; }
  .pb-xxxl-17 {
    padding-bottom: 5.3125rem !important; }
  .pb-xxxl-18 {
    padding-bottom: 5.625rem !important; }
  .pb-xxxl-19 {
    padding-bottom: 5.9375rem !important; }
  .pb-xxxl-20 {
    padding-bottom: 6.25rem !important; }
  .pb-xxxl-21 {
    padding-bottom: 6.5625rem !important; }
  .pb-xxxl-22 {
    padding-bottom: 6.875rem !important; }
  .pb-xxxl-23 {
    padding-bottom: 7.1875rem !important; }
  .pb-xxxl-24 {
    padding-bottom: 7.5rem !important; }
  .pb-xxxl-25 {
    padding-bottom: 7.8125rem !important; }
  .pb-xxxl-26 {
    padding-bottom: 8.125rem !important; }
  .pb-xxxl-27 {
    padding-bottom: 8.4375rem !important; }
  .pb-xxxl-28 {
    padding-bottom: 8.75rem !important; }
  .pb-xxxl-29 {
    padding-bottom: 9.0625rem !important; }
  .pb-xxxl-30 {
    padding-bottom: 9.375rem !important; }
  .pb-xxxl-31 {
    padding-bottom: 9.6875rem !important; }
  .pb-xxxl-32 {
    padding-bottom: 10rem !important; }
  .pb-xxxl-33 {
    padding-bottom: 10.3125rem !important; }
  .pb-xxxl-34 {
    padding-bottom: 10.625rem !important; }
  .pb-xxxl-35 {
    padding-bottom: 10.9375rem !important; }
  .pb-xxxl-36 {
    padding-bottom: 11.25rem !important; }
  .pb-xxxl-37 {
    padding-bottom: 11.5625rem !important; }
  .pb-xxxl-38 {
    padding-bottom: 11.875rem !important; }
  .pb-xxxl-39 {
    padding-bottom: 12.1875rem !important; }
  .pb-xxxl-40 {
    padding-bottom: 12.5rem !important; }
  .pb-xxxl-base {
    padding-bottom: calc(20rem / 16) !important; }
  .ps-xxxl-0 {
    padding-left: 0 !important; }
  .ps-xxxl-1 {
    padding-left: 0.3125rem !important; }
  .ps-xxxl-2 {
    padding-left: 0.625rem !important; }
  .ps-xxxl-3 {
    padding-left: 0.9375rem !important; }
  .ps-xxxl-4 {
    padding-left: 1.25rem !important; }
  .ps-xxxl-5 {
    padding-left: 1.5625rem !important; }
  .ps-xxxl-6 {
    padding-left: 1.875rem !important; }
  .ps-xxxl-7 {
    padding-left: 2.1875rem !important; }
  .ps-xxxl-8 {
    padding-left: 2.5rem !important; }
  .ps-xxxl-9 {
    padding-left: 2.8125rem !important; }
  .ps-xxxl-10 {
    padding-left: 3.125rem !important; }
  .ps-xxxl-11 {
    padding-left: 3.4375rem !important; }
  .ps-xxxl-12 {
    padding-left: 3.75rem !important; }
  .ps-xxxl-13 {
    padding-left: 4.0625rem !important; }
  .ps-xxxl-14 {
    padding-left: 4.375rem !important; }
  .ps-xxxl-15 {
    padding-left: 4.6875rem !important; }
  .ps-xxxl-16 {
    padding-left: 5rem !important; }
  .ps-xxxl-17 {
    padding-left: 5.3125rem !important; }
  .ps-xxxl-18 {
    padding-left: 5.625rem !important; }
  .ps-xxxl-19 {
    padding-left: 5.9375rem !important; }
  .ps-xxxl-20 {
    padding-left: 6.25rem !important; }
  .ps-xxxl-21 {
    padding-left: 6.5625rem !important; }
  .ps-xxxl-22 {
    padding-left: 6.875rem !important; }
  .ps-xxxl-23 {
    padding-left: 7.1875rem !important; }
  .ps-xxxl-24 {
    padding-left: 7.5rem !important; }
  .ps-xxxl-25 {
    padding-left: 7.8125rem !important; }
  .ps-xxxl-26 {
    padding-left: 8.125rem !important; }
  .ps-xxxl-27 {
    padding-left: 8.4375rem !important; }
  .ps-xxxl-28 {
    padding-left: 8.75rem !important; }
  .ps-xxxl-29 {
    padding-left: 9.0625rem !important; }
  .ps-xxxl-30 {
    padding-left: 9.375rem !important; }
  .ps-xxxl-31 {
    padding-left: 9.6875rem !important; }
  .ps-xxxl-32 {
    padding-left: 10rem !important; }
  .ps-xxxl-33 {
    padding-left: 10.3125rem !important; }
  .ps-xxxl-34 {
    padding-left: 10.625rem !important; }
  .ps-xxxl-35 {
    padding-left: 10.9375rem !important; }
  .ps-xxxl-36 {
    padding-left: 11.25rem !important; }
  .ps-xxxl-37 {
    padding-left: 11.5625rem !important; }
  .ps-xxxl-38 {
    padding-left: 11.875rem !important; }
  .ps-xxxl-39 {
    padding-left: 12.1875rem !important; }
  .ps-xxxl-40 {
    padding-left: 12.5rem !important; }
  .ps-xxxl-base {
    padding-left: calc(20rem / 16) !important; }
  .gap-xxxl-0 {
    gap: 0 !important; }
  .gap-xxxl-1 {
    gap: 0.3125rem !important; }
  .gap-xxxl-2 {
    gap: 0.625rem !important; }
  .gap-xxxl-3 {
    gap: 0.9375rem !important; }
  .gap-xxxl-4 {
    gap: 1.25rem !important; }
  .gap-xxxl-5 {
    gap: 1.5625rem !important; }
  .gap-xxxl-6 {
    gap: 1.875rem !important; }
  .gap-xxxl-7 {
    gap: 2.1875rem !important; }
  .gap-xxxl-8 {
    gap: 2.5rem !important; }
  .gap-xxxl-9 {
    gap: 2.8125rem !important; }
  .gap-xxxl-10 {
    gap: 3.125rem !important; }
  .gap-xxxl-11 {
    gap: 3.4375rem !important; }
  .gap-xxxl-12 {
    gap: 3.75rem !important; }
  .gap-xxxl-13 {
    gap: 4.0625rem !important; }
  .gap-xxxl-14 {
    gap: 4.375rem !important; }
  .gap-xxxl-15 {
    gap: 4.6875rem !important; }
  .gap-xxxl-16 {
    gap: 5rem !important; }
  .gap-xxxl-17 {
    gap: 5.3125rem !important; }
  .gap-xxxl-18 {
    gap: 5.625rem !important; }
  .gap-xxxl-19 {
    gap: 5.9375rem !important; }
  .gap-xxxl-20 {
    gap: 6.25rem !important; }
  .gap-xxxl-21 {
    gap: 6.5625rem !important; }
  .gap-xxxl-22 {
    gap: 6.875rem !important; }
  .gap-xxxl-23 {
    gap: 7.1875rem !important; }
  .gap-xxxl-24 {
    gap: 7.5rem !important; }
  .gap-xxxl-25 {
    gap: 7.8125rem !important; }
  .gap-xxxl-26 {
    gap: 8.125rem !important; }
  .gap-xxxl-27 {
    gap: 8.4375rem !important; }
  .gap-xxxl-28 {
    gap: 8.75rem !important; }
  .gap-xxxl-29 {
    gap: 9.0625rem !important; }
  .gap-xxxl-30 {
    gap: 9.375rem !important; }
  .gap-xxxl-31 {
    gap: 9.6875rem !important; }
  .gap-xxxl-32 {
    gap: 10rem !important; }
  .gap-xxxl-33 {
    gap: 10.3125rem !important; }
  .gap-xxxl-34 {
    gap: 10.625rem !important; }
  .gap-xxxl-35 {
    gap: 10.9375rem !important; }
  .gap-xxxl-36 {
    gap: 11.25rem !important; }
  .gap-xxxl-37 {
    gap: 11.5625rem !important; }
  .gap-xxxl-38 {
    gap: 11.875rem !important; }
  .gap-xxxl-39 {
    gap: 12.1875rem !important; }
  .gap-xxxl-40 {
    gap: 12.5rem !important; }
  .gap-xxxl-base {
    gap: calc(20rem / 16) !important; }
  .text-xxxl-start {
    text-align: left !important; }
  .text-xxxl-end {
    text-align: right !important; }
  .text-xxxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #313330 !important; }
  .link-primary:hover, .link-primary:focus {
    color: #272926 !important; }

.link-secondary {
  color: #E5E1DE !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: #eae7e5 !important; }

.link-success {
  color: #65BA8C !important; }
  .link-success:hover, .link-success:focus {
    color: #84c8a3 !important; }

.link-info {
  color: #FFE569 !important; }
  .link-info:hover, .link-info:focus {
    color: #ffea87 !important; }

.link-warning {
  color: #FFE569 !important; }
  .link-warning:hover, .link-warning:focus {
    color: #ffea87 !important; }

.link-danger {
  color: #DC3545 !important; }
  .link-danger:hover, .link-danger:focus {
    color: #b02a37 !important; }

.link-light {
  color: #f8f9fa !important; }
  .link-light:hover, .link-light:focus {
    color: #f9fafb !important; }

.link-dark {
  color: #323232 !important; }
  .link-dark:hover, .link-dark:focus {
    color: #282828 !important; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--aspect-ratio);
    content: ""; }
  .ratio .ratio-item,
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --aspect-ratio: 100%; }

.ratio-4x3 {
  --aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --aspect-ratio: calc(9 / 21 * 100%); }

.ratio-9x6 {
  --aspect-ratio: calc(6 / 9 * 100%); }

.ratio-3x2 {
  --aspect-ratio: calc(2 / 3 * 100%); }

.ratio-3x4 {
  --aspect-ratio: calc(4 / 3 * 100%); }

.ratio-4x5 {
  --aspect-ratio: calc(5 / 4 * 100%); }

.ratio-15x4 {
  --aspect-ratio: calc(4 / 15 * 100%); }

.ratio-2x3 {
  --aspect-ratio: calc(3 / 2 * 100%); }

.ratio-7x3 {
  --aspect-ratio: calc(3 / 7 * 100%); }

.ratio-16x7 {
  --aspect-ratio: calc(7 / 16 * 100%); }

.ratio-2x1 {
  --aspect-ratio: calc(1 / 2 * 100%); }

@media screen and (max-width: 767px) {
  .ratio-15x8-xs {
    --aspect-ratio: calc(8 / 15 * 100%); }
  .ratio-1x1-xs {
    --aspect-ratio: 100%; } }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1921px) {
  .sticky-xxxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-0 {
  --bs-border-width: 0; }

.border-1 {
  --bs-border-width: 1px; }

.border-2 {
  --bs-border-width: 2px; }

.border-3 {
  --bs-border-width: 3px; }

.border-4 {
  --bs-border-width: 4px; }

.border-5 {
  --bs-border-width: 5px; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.3125rem !important; }

.m-2 {
  margin: 0.625rem !important; }

.m-3 {
  margin: 0.9375rem !important; }

.m-4 {
  margin: 1.25rem !important; }

.m-5 {
  margin: 1.5625rem !important; }

.m-6 {
  margin: 1.875rem !important; }

.m-7 {
  margin: 2.1875rem !important; }

.m-8 {
  margin: 2.5rem !important; }

.m-9 {
  margin: 2.8125rem !important; }

.m-10 {
  margin: 3.125rem !important; }

.m-11 {
  margin: 3.4375rem !important; }

.m-12 {
  margin: 3.75rem !important; }

.m-13 {
  margin: 4.0625rem !important; }

.m-14 {
  margin: 4.375rem !important; }

.m-15 {
  margin: 4.6875rem !important; }

.m-16 {
  margin: 5rem !important; }

.m-17 {
  margin: 5.3125rem !important; }

.m-18 {
  margin: 5.625rem !important; }

.m-19 {
  margin: 5.9375rem !important; }

.m-20 {
  margin: 6.25rem !important; }

.m-21 {
  margin: 6.5625rem !important; }

.m-22 {
  margin: 6.875rem !important; }

.m-23 {
  margin: 7.1875rem !important; }

.m-24 {
  margin: 7.5rem !important; }

.m-25 {
  margin: 7.8125rem !important; }

.m-26 {
  margin: 8.125rem !important; }

.m-27 {
  margin: 8.4375rem !important; }

.m-28 {
  margin: 8.75rem !important; }

.m-29 {
  margin: 9.0625rem !important; }

.m-30 {
  margin: 9.375rem !important; }

.m-31 {
  margin: 9.6875rem !important; }

.m-32 {
  margin: 10rem !important; }

.m-33 {
  margin: 10.3125rem !important; }

.m-34 {
  margin: 10.625rem !important; }

.m-35 {
  margin: 10.9375rem !important; }

.m-36 {
  margin: 11.25rem !important; }

.m-37 {
  margin: 11.5625rem !important; }

.m-38 {
  margin: 11.875rem !important; }

.m-39 {
  margin: 12.1875rem !important; }

.m-40 {
  margin: 12.5rem !important; }

.m-base {
  margin: calc(20rem / 16) !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.3125rem !important;
  margin-left: 0.3125rem !important; }

.mx-2 {
  margin-right: 0.625rem !important;
  margin-left: 0.625rem !important; }

.mx-3 {
  margin-right: 0.9375rem !important;
  margin-left: 0.9375rem !important; }

.mx-4 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important; }

.mx-5 {
  margin-right: 1.5625rem !important;
  margin-left: 1.5625rem !important; }

.mx-6 {
  margin-right: 1.875rem !important;
  margin-left: 1.875rem !important; }

.mx-7 {
  margin-right: 2.1875rem !important;
  margin-left: 2.1875rem !important; }

.mx-8 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important; }

.mx-9 {
  margin-right: 2.8125rem !important;
  margin-left: 2.8125rem !important; }

.mx-10 {
  margin-right: 3.125rem !important;
  margin-left: 3.125rem !important; }

.mx-11 {
  margin-right: 3.4375rem !important;
  margin-left: 3.4375rem !important; }

.mx-12 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important; }

.mx-13 {
  margin-right: 4.0625rem !important;
  margin-left: 4.0625rem !important; }

.mx-14 {
  margin-right: 4.375rem !important;
  margin-left: 4.375rem !important; }

.mx-15 {
  margin-right: 4.6875rem !important;
  margin-left: 4.6875rem !important; }

.mx-16 {
  margin-right: 5rem !important;
  margin-left: 5rem !important; }

.mx-17 {
  margin-right: 5.3125rem !important;
  margin-left: 5.3125rem !important; }

.mx-18 {
  margin-right: 5.625rem !important;
  margin-left: 5.625rem !important; }

.mx-19 {
  margin-right: 5.9375rem !important;
  margin-left: 5.9375rem !important; }

.mx-20 {
  margin-right: 6.25rem !important;
  margin-left: 6.25rem !important; }

.mx-21 {
  margin-right: 6.5625rem !important;
  margin-left: 6.5625rem !important; }

.mx-22 {
  margin-right: 6.875rem !important;
  margin-left: 6.875rem !important; }

.mx-23 {
  margin-right: 7.1875rem !important;
  margin-left: 7.1875rem !important; }

.mx-24 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important; }

.mx-25 {
  margin-right: 7.8125rem !important;
  margin-left: 7.8125rem !important; }

.mx-26 {
  margin-right: 8.125rem !important;
  margin-left: 8.125rem !important; }

.mx-27 {
  margin-right: 8.4375rem !important;
  margin-left: 8.4375rem !important; }

.mx-28 {
  margin-right: 8.75rem !important;
  margin-left: 8.75rem !important; }

.mx-29 {
  margin-right: 9.0625rem !important;
  margin-left: 9.0625rem !important; }

.mx-30 {
  margin-right: 9.375rem !important;
  margin-left: 9.375rem !important; }

.mx-31 {
  margin-right: 9.6875rem !important;
  margin-left: 9.6875rem !important; }

.mx-32 {
  margin-right: 10rem !important;
  margin-left: 10rem !important; }

.mx-33 {
  margin-right: 10.3125rem !important;
  margin-left: 10.3125rem !important; }

.mx-34 {
  margin-right: 10.625rem !important;
  margin-left: 10.625rem !important; }

.mx-35 {
  margin-right: 10.9375rem !important;
  margin-left: 10.9375rem !important; }

.mx-36 {
  margin-right: 11.25rem !important;
  margin-left: 11.25rem !important; }

.mx-37 {
  margin-right: 11.5625rem !important;
  margin-left: 11.5625rem !important; }

.mx-38 {
  margin-right: 11.875rem !important;
  margin-left: 11.875rem !important; }

.mx-39 {
  margin-right: 12.1875rem !important;
  margin-left: 12.1875rem !important; }

.mx-40 {
  margin-right: 12.5rem !important;
  margin-left: 12.5rem !important; }

.mx-base {
  margin-right: calc(20rem / 16) !important;
  margin-left: calc(20rem / 16) !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.3125rem !important;
  margin-bottom: 0.3125rem !important; }

.my-2 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important; }

.my-3 {
  margin-top: 0.9375rem !important;
  margin-bottom: 0.9375rem !important; }

.my-4 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important; }

.my-5 {
  margin-top: 1.5625rem !important;
  margin-bottom: 1.5625rem !important; }

.my-6 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important; }

.my-7 {
  margin-top: 2.1875rem !important;
  margin-bottom: 2.1875rem !important; }

.my-8 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important; }

.my-9 {
  margin-top: 2.8125rem !important;
  margin-bottom: 2.8125rem !important; }

.my-10 {
  margin-top: 3.125rem !important;
  margin-bottom: 3.125rem !important; }

.my-11 {
  margin-top: 3.4375rem !important;
  margin-bottom: 3.4375rem !important; }

.my-12 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important; }

.my-13 {
  margin-top: 4.0625rem !important;
  margin-bottom: 4.0625rem !important; }

.my-14 {
  margin-top: 4.375rem !important;
  margin-bottom: 4.375rem !important; }

.my-15 {
  margin-top: 4.6875rem !important;
  margin-bottom: 4.6875rem !important; }

.my-16 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important; }

.my-17 {
  margin-top: 5.3125rem !important;
  margin-bottom: 5.3125rem !important; }

.my-18 {
  margin-top: 5.625rem !important;
  margin-bottom: 5.625rem !important; }

.my-19 {
  margin-top: 5.9375rem !important;
  margin-bottom: 5.9375rem !important; }

.my-20 {
  margin-top: 6.25rem !important;
  margin-bottom: 6.25rem !important; }

.my-21 {
  margin-top: 6.5625rem !important;
  margin-bottom: 6.5625rem !important; }

.my-22 {
  margin-top: 6.875rem !important;
  margin-bottom: 6.875rem !important; }

.my-23 {
  margin-top: 7.1875rem !important;
  margin-bottom: 7.1875rem !important; }

.my-24 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important; }

.my-25 {
  margin-top: 7.8125rem !important;
  margin-bottom: 7.8125rem !important; }

.my-26 {
  margin-top: 8.125rem !important;
  margin-bottom: 8.125rem !important; }

.my-27 {
  margin-top: 8.4375rem !important;
  margin-bottom: 8.4375rem !important; }

.my-28 {
  margin-top: 8.75rem !important;
  margin-bottom: 8.75rem !important; }

.my-29 {
  margin-top: 9.0625rem !important;
  margin-bottom: 9.0625rem !important; }

.my-30 {
  margin-top: 9.375rem !important;
  margin-bottom: 9.375rem !important; }

.my-31 {
  margin-top: 9.6875rem !important;
  margin-bottom: 9.6875rem !important; }

.my-32 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important; }

.my-33 {
  margin-top: 10.3125rem !important;
  margin-bottom: 10.3125rem !important; }

.my-34 {
  margin-top: 10.625rem !important;
  margin-bottom: 10.625rem !important; }

.my-35 {
  margin-top: 10.9375rem !important;
  margin-bottom: 10.9375rem !important; }

.my-36 {
  margin-top: 11.25rem !important;
  margin-bottom: 11.25rem !important; }

.my-37 {
  margin-top: 11.5625rem !important;
  margin-bottom: 11.5625rem !important; }

.my-38 {
  margin-top: 11.875rem !important;
  margin-bottom: 11.875rem !important; }

.my-39 {
  margin-top: 12.1875rem !important;
  margin-bottom: 12.1875rem !important; }

.my-40 {
  margin-top: 12.5rem !important;
  margin-bottom: 12.5rem !important; }

.my-base {
  margin-top: calc(20rem / 16) !important;
  margin-bottom: calc(20rem / 16) !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.3125rem !important; }

.mt-2 {
  margin-top: 0.625rem !important; }

.mt-3 {
  margin-top: 0.9375rem !important; }

.mt-4 {
  margin-top: 1.25rem !important; }

.mt-5 {
  margin-top: 1.5625rem !important; }

.mt-6 {
  margin-top: 1.875rem !important; }

.mt-7 {
  margin-top: 2.1875rem !important; }

.mt-8 {
  margin-top: 2.5rem !important; }

.mt-9 {
  margin-top: 2.8125rem !important; }

.mt-10 {
  margin-top: 3.125rem !important; }

.mt-11 {
  margin-top: 3.4375rem !important; }

.mt-12 {
  margin-top: 3.75rem !important; }

.mt-13 {
  margin-top: 4.0625rem !important; }

.mt-14 {
  margin-top: 4.375rem !important; }

.mt-15 {
  margin-top: 4.6875rem !important; }

.mt-16 {
  margin-top: 5rem !important; }

.mt-17 {
  margin-top: 5.3125rem !important; }

.mt-18 {
  margin-top: 5.625rem !important; }

.mt-19 {
  margin-top: 5.9375rem !important; }

.mt-20 {
  margin-top: 6.25rem !important; }

.mt-21 {
  margin-top: 6.5625rem !important; }

.mt-22 {
  margin-top: 6.875rem !important; }

.mt-23 {
  margin-top: 7.1875rem !important; }

.mt-24 {
  margin-top: 7.5rem !important; }

.mt-25 {
  margin-top: 7.8125rem !important; }

.mt-26 {
  margin-top: 8.125rem !important; }

.mt-27 {
  margin-top: 8.4375rem !important; }

.mt-28 {
  margin-top: 8.75rem !important; }

.mt-29 {
  margin-top: 9.0625rem !important; }

.mt-30 {
  margin-top: 9.375rem !important; }

.mt-31 {
  margin-top: 9.6875rem !important; }

.mt-32 {
  margin-top: 10rem !important; }

.mt-33 {
  margin-top: 10.3125rem !important; }

.mt-34 {
  margin-top: 10.625rem !important; }

.mt-35 {
  margin-top: 10.9375rem !important; }

.mt-36 {
  margin-top: 11.25rem !important; }

.mt-37 {
  margin-top: 11.5625rem !important; }

.mt-38 {
  margin-top: 11.875rem !important; }

.mt-39 {
  margin-top: 12.1875rem !important; }

.mt-40 {
  margin-top: 12.5rem !important; }

.mt-base {
  margin-top: calc(20rem / 16) !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.3125rem !important; }

.me-2 {
  margin-right: 0.625rem !important; }

.me-3 {
  margin-right: 0.9375rem !important; }

.me-4 {
  margin-right: 1.25rem !important; }

.me-5 {
  margin-right: 1.5625rem !important; }

.me-6 {
  margin-right: 1.875rem !important; }

.me-7 {
  margin-right: 2.1875rem !important; }

.me-8 {
  margin-right: 2.5rem !important; }

.me-9 {
  margin-right: 2.8125rem !important; }

.me-10 {
  margin-right: 3.125rem !important; }

.me-11 {
  margin-right: 3.4375rem !important; }

.me-12 {
  margin-right: 3.75rem !important; }

.me-13 {
  margin-right: 4.0625rem !important; }

.me-14 {
  margin-right: 4.375rem !important; }

.me-15 {
  margin-right: 4.6875rem !important; }

.me-16 {
  margin-right: 5rem !important; }

.me-17 {
  margin-right: 5.3125rem !important; }

.me-18 {
  margin-right: 5.625rem !important; }

.me-19 {
  margin-right: 5.9375rem !important; }

.me-20 {
  margin-right: 6.25rem !important; }

.me-21 {
  margin-right: 6.5625rem !important; }

.me-22 {
  margin-right: 6.875rem !important; }

.me-23 {
  margin-right: 7.1875rem !important; }

.me-24 {
  margin-right: 7.5rem !important; }

.me-25 {
  margin-right: 7.8125rem !important; }

.me-26 {
  margin-right: 8.125rem !important; }

.me-27 {
  margin-right: 8.4375rem !important; }

.me-28 {
  margin-right: 8.75rem !important; }

.me-29 {
  margin-right: 9.0625rem !important; }

.me-30 {
  margin-right: 9.375rem !important; }

.me-31 {
  margin-right: 9.6875rem !important; }

.me-32 {
  margin-right: 10rem !important; }

.me-33 {
  margin-right: 10.3125rem !important; }

.me-34 {
  margin-right: 10.625rem !important; }

.me-35 {
  margin-right: 10.9375rem !important; }

.me-36 {
  margin-right: 11.25rem !important; }

.me-37 {
  margin-right: 11.5625rem !important; }

.me-38 {
  margin-right: 11.875rem !important; }

.me-39 {
  margin-right: 12.1875rem !important; }

.me-40 {
  margin-right: 12.5rem !important; }

.me-base {
  margin-right: calc(20rem / 16) !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.3125rem !important; }

.mb-2 {
  margin-bottom: 0.625rem !important; }

.mb-3 {
  margin-bottom: 0.9375rem !important; }

.mb-4 {
  margin-bottom: 1.25rem !important; }

.mb-5 {
  margin-bottom: 1.5625rem !important; }

.mb-6 {
  margin-bottom: 1.875rem !important; }

.mb-7 {
  margin-bottom: 2.1875rem !important; }

.mb-8 {
  margin-bottom: 2.5rem !important; }

.mb-9 {
  margin-bottom: 2.8125rem !important; }

.mb-10 {
  margin-bottom: 3.125rem !important; }

.mb-11 {
  margin-bottom: 3.4375rem !important; }

.mb-12 {
  margin-bottom: 3.75rem !important; }

.mb-13 {
  margin-bottom: 4.0625rem !important; }

.mb-14 {
  margin-bottom: 4.375rem !important; }

.mb-15 {
  margin-bottom: 4.6875rem !important; }

.mb-16 {
  margin-bottom: 5rem !important; }

.mb-17 {
  margin-bottom: 5.3125rem !important; }

.mb-18 {
  margin-bottom: 5.625rem !important; }

.mb-19 {
  margin-bottom: 5.9375rem !important; }

.mb-20 {
  margin-bottom: 6.25rem !important; }

.mb-21 {
  margin-bottom: 6.5625rem !important; }

.mb-22 {
  margin-bottom: 6.875rem !important; }

.mb-23 {
  margin-bottom: 7.1875rem !important; }

.mb-24 {
  margin-bottom: 7.5rem !important; }

.mb-25 {
  margin-bottom: 7.8125rem !important; }

.mb-26 {
  margin-bottom: 8.125rem !important; }

.mb-27 {
  margin-bottom: 8.4375rem !important; }

.mb-28 {
  margin-bottom: 8.75rem !important; }

.mb-29 {
  margin-bottom: 9.0625rem !important; }

.mb-30 {
  margin-bottom: 9.375rem !important; }

.mb-31 {
  margin-bottom: 9.6875rem !important; }

.mb-32 {
  margin-bottom: 10rem !important; }

.mb-33 {
  margin-bottom: 10.3125rem !important; }

.mb-34 {
  margin-bottom: 10.625rem !important; }

.mb-35 {
  margin-bottom: 10.9375rem !important; }

.mb-36 {
  margin-bottom: 11.25rem !important; }

.mb-37 {
  margin-bottom: 11.5625rem !important; }

.mb-38 {
  margin-bottom: 11.875rem !important; }

.mb-39 {
  margin-bottom: 12.1875rem !important; }

.mb-40 {
  margin-bottom: 12.5rem !important; }

.mb-base {
  margin-bottom: calc(20rem / 16) !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.3125rem !important; }

.ms-2 {
  margin-left: 0.625rem !important; }

.ms-3 {
  margin-left: 0.9375rem !important; }

.ms-4 {
  margin-left: 1.25rem !important; }

.ms-5 {
  margin-left: 1.5625rem !important; }

.ms-6 {
  margin-left: 1.875rem !important; }

.ms-7 {
  margin-left: 2.1875rem !important; }

.ms-8 {
  margin-left: 2.5rem !important; }

.ms-9 {
  margin-left: 2.8125rem !important; }

.ms-10 {
  margin-left: 3.125rem !important; }

.ms-11 {
  margin-left: 3.4375rem !important; }

.ms-12 {
  margin-left: 3.75rem !important; }

.ms-13 {
  margin-left: 4.0625rem !important; }

.ms-14 {
  margin-left: 4.375rem !important; }

.ms-15 {
  margin-left: 4.6875rem !important; }

.ms-16 {
  margin-left: 5rem !important; }

.ms-17 {
  margin-left: 5.3125rem !important; }

.ms-18 {
  margin-left: 5.625rem !important; }

.ms-19 {
  margin-left: 5.9375rem !important; }

.ms-20 {
  margin-left: 6.25rem !important; }

.ms-21 {
  margin-left: 6.5625rem !important; }

.ms-22 {
  margin-left: 6.875rem !important; }

.ms-23 {
  margin-left: 7.1875rem !important; }

.ms-24 {
  margin-left: 7.5rem !important; }

.ms-25 {
  margin-left: 7.8125rem !important; }

.ms-26 {
  margin-left: 8.125rem !important; }

.ms-27 {
  margin-left: 8.4375rem !important; }

.ms-28 {
  margin-left: 8.75rem !important; }

.ms-29 {
  margin-left: 9.0625rem !important; }

.ms-30 {
  margin-left: 9.375rem !important; }

.ms-31 {
  margin-left: 9.6875rem !important; }

.ms-32 {
  margin-left: 10rem !important; }

.ms-33 {
  margin-left: 10.3125rem !important; }

.ms-34 {
  margin-left: 10.625rem !important; }

.ms-35 {
  margin-left: 10.9375rem !important; }

.ms-36 {
  margin-left: 11.25rem !important; }

.ms-37 {
  margin-left: 11.5625rem !important; }

.ms-38 {
  margin-left: 11.875rem !important; }

.ms-39 {
  margin-left: 12.1875rem !important; }

.ms-40 {
  margin-left: 12.5rem !important; }

.ms-base {
  margin-left: calc(20rem / 16) !important; }

.ms-auto {
  margin-left: auto !important; }

.m-n1 {
  margin: -0.3125rem !important; }

.m-n2 {
  margin: -0.625rem !important; }

.m-n3 {
  margin: -0.9375rem !important; }

.m-n4 {
  margin: -1.25rem !important; }

.m-n5 {
  margin: -1.5625rem !important; }

.m-n6 {
  margin: -1.875rem !important; }

.m-n7 {
  margin: -2.1875rem !important; }

.m-n8 {
  margin: -2.5rem !important; }

.m-n9 {
  margin: -2.8125rem !important; }

.m-n10 {
  margin: -3.125rem !important; }

.m-n11 {
  margin: -3.4375rem !important; }

.m-n12 {
  margin: -3.75rem !important; }

.m-n13 {
  margin: -4.0625rem !important; }

.m-n14 {
  margin: -4.375rem !important; }

.m-n15 {
  margin: -4.6875rem !important; }

.m-n16 {
  margin: -5rem !important; }

.m-n17 {
  margin: -5.3125rem !important; }

.m-n18 {
  margin: -5.625rem !important; }

.m-n19 {
  margin: -5.9375rem !important; }

.m-n20 {
  margin: -6.25rem !important; }

.m-n21 {
  margin: -6.5625rem !important; }

.m-n22 {
  margin: -6.875rem !important; }

.m-n23 {
  margin: -7.1875rem !important; }

.m-n24 {
  margin: -7.5rem !important; }

.m-n25 {
  margin: -7.8125rem !important; }

.m-n26 {
  margin: -8.125rem !important; }

.m-n27 {
  margin: -8.4375rem !important; }

.m-n28 {
  margin: -8.75rem !important; }

.m-n29 {
  margin: -9.0625rem !important; }

.m-n30 {
  margin: -9.375rem !important; }

.m-n31 {
  margin: -9.6875rem !important; }

.m-n32 {
  margin: -10rem !important; }

.m-n33 {
  margin: -10.3125rem !important; }

.m-n34 {
  margin: -10.625rem !important; }

.m-n35 {
  margin: -10.9375rem !important; }

.m-n36 {
  margin: -11.25rem !important; }

.m-n37 {
  margin: -11.5625rem !important; }

.m-n38 {
  margin: -11.875rem !important; }

.m-n39 {
  margin: -12.1875rem !important; }

.m-n40 {
  margin: -12.5rem !important; }

.m-nbase {
  margin: -calc(20rem / 16) !important; }

.mx-n1 {
  margin-right: -0.3125rem !important;
  margin-left: -0.3125rem !important; }

.mx-n2 {
  margin-right: -0.625rem !important;
  margin-left: -0.625rem !important; }

.mx-n3 {
  margin-right: -0.9375rem !important;
  margin-left: -0.9375rem !important; }

.mx-n4 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important; }

.mx-n5 {
  margin-right: -1.5625rem !important;
  margin-left: -1.5625rem !important; }

.mx-n6 {
  margin-right: -1.875rem !important;
  margin-left: -1.875rem !important; }

.mx-n7 {
  margin-right: -2.1875rem !important;
  margin-left: -2.1875rem !important; }

.mx-n8 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important; }

.mx-n9 {
  margin-right: -2.8125rem !important;
  margin-left: -2.8125rem !important; }

.mx-n10 {
  margin-right: -3.125rem !important;
  margin-left: -3.125rem !important; }

.mx-n11 {
  margin-right: -3.4375rem !important;
  margin-left: -3.4375rem !important; }

.mx-n12 {
  margin-right: -3.75rem !important;
  margin-left: -3.75rem !important; }

.mx-n13 {
  margin-right: -4.0625rem !important;
  margin-left: -4.0625rem !important; }

.mx-n14 {
  margin-right: -4.375rem !important;
  margin-left: -4.375rem !important; }

.mx-n15 {
  margin-right: -4.6875rem !important;
  margin-left: -4.6875rem !important; }

.mx-n16 {
  margin-right: -5rem !important;
  margin-left: -5rem !important; }

.mx-n17 {
  margin-right: -5.3125rem !important;
  margin-left: -5.3125rem !important; }

.mx-n18 {
  margin-right: -5.625rem !important;
  margin-left: -5.625rem !important; }

.mx-n19 {
  margin-right: -5.9375rem !important;
  margin-left: -5.9375rem !important; }

.mx-n20 {
  margin-right: -6.25rem !important;
  margin-left: -6.25rem !important; }

.mx-n21 {
  margin-right: -6.5625rem !important;
  margin-left: -6.5625rem !important; }

.mx-n22 {
  margin-right: -6.875rem !important;
  margin-left: -6.875rem !important; }

.mx-n23 {
  margin-right: -7.1875rem !important;
  margin-left: -7.1875rem !important; }

.mx-n24 {
  margin-right: -7.5rem !important;
  margin-left: -7.5rem !important; }

.mx-n25 {
  margin-right: -7.8125rem !important;
  margin-left: -7.8125rem !important; }

.mx-n26 {
  margin-right: -8.125rem !important;
  margin-left: -8.125rem !important; }

.mx-n27 {
  margin-right: -8.4375rem !important;
  margin-left: -8.4375rem !important; }

.mx-n28 {
  margin-right: -8.75rem !important;
  margin-left: -8.75rem !important; }

.mx-n29 {
  margin-right: -9.0625rem !important;
  margin-left: -9.0625rem !important; }

.mx-n30 {
  margin-right: -9.375rem !important;
  margin-left: -9.375rem !important; }

.mx-n31 {
  margin-right: -9.6875rem !important;
  margin-left: -9.6875rem !important; }

.mx-n32 {
  margin-right: -10rem !important;
  margin-left: -10rem !important; }

.mx-n33 {
  margin-right: -10.3125rem !important;
  margin-left: -10.3125rem !important; }

.mx-n34 {
  margin-right: -10.625rem !important;
  margin-left: -10.625rem !important; }

.mx-n35 {
  margin-right: -10.9375rem !important;
  margin-left: -10.9375rem !important; }

.mx-n36 {
  margin-right: -11.25rem !important;
  margin-left: -11.25rem !important; }

.mx-n37 {
  margin-right: -11.5625rem !important;
  margin-left: -11.5625rem !important; }

.mx-n38 {
  margin-right: -11.875rem !important;
  margin-left: -11.875rem !important; }

.mx-n39 {
  margin-right: -12.1875rem !important;
  margin-left: -12.1875rem !important; }

.mx-n40 {
  margin-right: -12.5rem !important;
  margin-left: -12.5rem !important; }

.mx-nbase {
  margin-right: -calc(20rem / 16) !important;
  margin-left: -calc(20rem / 16) !important; }

.my-n1 {
  margin-top: -0.3125rem !important;
  margin-bottom: -0.3125rem !important; }

.my-n2 {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important; }

.my-n3 {
  margin-top: -0.9375rem !important;
  margin-bottom: -0.9375rem !important; }

.my-n4 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important; }

.my-n5 {
  margin-top: -1.5625rem !important;
  margin-bottom: -1.5625rem !important; }

.my-n6 {
  margin-top: -1.875rem !important;
  margin-bottom: -1.875rem !important; }

.my-n7 {
  margin-top: -2.1875rem !important;
  margin-bottom: -2.1875rem !important; }

.my-n8 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important; }

.my-n9 {
  margin-top: -2.8125rem !important;
  margin-bottom: -2.8125rem !important; }

.my-n10 {
  margin-top: -3.125rem !important;
  margin-bottom: -3.125rem !important; }

.my-n11 {
  margin-top: -3.4375rem !important;
  margin-bottom: -3.4375rem !important; }

.my-n12 {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important; }

.my-n13 {
  margin-top: -4.0625rem !important;
  margin-bottom: -4.0625rem !important; }

.my-n14 {
  margin-top: -4.375rem !important;
  margin-bottom: -4.375rem !important; }

.my-n15 {
  margin-top: -4.6875rem !important;
  margin-bottom: -4.6875rem !important; }

.my-n16 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important; }

.my-n17 {
  margin-top: -5.3125rem !important;
  margin-bottom: -5.3125rem !important; }

.my-n18 {
  margin-top: -5.625rem !important;
  margin-bottom: -5.625rem !important; }

.my-n19 {
  margin-top: -5.9375rem !important;
  margin-bottom: -5.9375rem !important; }

.my-n20 {
  margin-top: -6.25rem !important;
  margin-bottom: -6.25rem !important; }

.my-n21 {
  margin-top: -6.5625rem !important;
  margin-bottom: -6.5625rem !important; }

.my-n22 {
  margin-top: -6.875rem !important;
  margin-bottom: -6.875rem !important; }

.my-n23 {
  margin-top: -7.1875rem !important;
  margin-bottom: -7.1875rem !important; }

.my-n24 {
  margin-top: -7.5rem !important;
  margin-bottom: -7.5rem !important; }

.my-n25 {
  margin-top: -7.8125rem !important;
  margin-bottom: -7.8125rem !important; }

.my-n26 {
  margin-top: -8.125rem !important;
  margin-bottom: -8.125rem !important; }

.my-n27 {
  margin-top: -8.4375rem !important;
  margin-bottom: -8.4375rem !important; }

.my-n28 {
  margin-top: -8.75rem !important;
  margin-bottom: -8.75rem !important; }

.my-n29 {
  margin-top: -9.0625rem !important;
  margin-bottom: -9.0625rem !important; }

.my-n30 {
  margin-top: -9.375rem !important;
  margin-bottom: -9.375rem !important; }

.my-n31 {
  margin-top: -9.6875rem !important;
  margin-bottom: -9.6875rem !important; }

.my-n32 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important; }

.my-n33 {
  margin-top: -10.3125rem !important;
  margin-bottom: -10.3125rem !important; }

.my-n34 {
  margin-top: -10.625rem !important;
  margin-bottom: -10.625rem !important; }

.my-n35 {
  margin-top: -10.9375rem !important;
  margin-bottom: -10.9375rem !important; }

.my-n36 {
  margin-top: -11.25rem !important;
  margin-bottom: -11.25rem !important; }

.my-n37 {
  margin-top: -11.5625rem !important;
  margin-bottom: -11.5625rem !important; }

.my-n38 {
  margin-top: -11.875rem !important;
  margin-bottom: -11.875rem !important; }

.my-n39 {
  margin-top: -12.1875rem !important;
  margin-bottom: -12.1875rem !important; }

.my-n40 {
  margin-top: -12.5rem !important;
  margin-bottom: -12.5rem !important; }

.my-nbase {
  margin-top: -calc(20rem / 16) !important;
  margin-bottom: -calc(20rem / 16) !important; }

.mt-n1 {
  margin-top: -0.3125rem !important; }

.mt-n2 {
  margin-top: -0.625rem !important; }

.mt-n3 {
  margin-top: -0.9375rem !important; }

.mt-n4 {
  margin-top: -1.25rem !important; }

.mt-n5 {
  margin-top: -1.5625rem !important; }

.mt-n6 {
  margin-top: -1.875rem !important; }

.mt-n7 {
  margin-top: -2.1875rem !important; }

.mt-n8 {
  margin-top: -2.5rem !important; }

.mt-n9 {
  margin-top: -2.8125rem !important; }

.mt-n10 {
  margin-top: -3.125rem !important; }

.mt-n11 {
  margin-top: -3.4375rem !important; }

.mt-n12 {
  margin-top: -3.75rem !important; }

.mt-n13 {
  margin-top: -4.0625rem !important; }

.mt-n14 {
  margin-top: -4.375rem !important; }

.mt-n15 {
  margin-top: -4.6875rem !important; }

.mt-n16 {
  margin-top: -5rem !important; }

.mt-n17 {
  margin-top: -5.3125rem !important; }

.mt-n18 {
  margin-top: -5.625rem !important; }

.mt-n19 {
  margin-top: -5.9375rem !important; }

.mt-n20 {
  margin-top: -6.25rem !important; }

.mt-n21 {
  margin-top: -6.5625rem !important; }

.mt-n22 {
  margin-top: -6.875rem !important; }

.mt-n23 {
  margin-top: -7.1875rem !important; }

.mt-n24 {
  margin-top: -7.5rem !important; }

.mt-n25 {
  margin-top: -7.8125rem !important; }

.mt-n26 {
  margin-top: -8.125rem !important; }

.mt-n27 {
  margin-top: -8.4375rem !important; }

.mt-n28 {
  margin-top: -8.75rem !important; }

.mt-n29 {
  margin-top: -9.0625rem !important; }

.mt-n30 {
  margin-top: -9.375rem !important; }

.mt-n31 {
  margin-top: -9.6875rem !important; }

.mt-n32 {
  margin-top: -10rem !important; }

.mt-n33 {
  margin-top: -10.3125rem !important; }

.mt-n34 {
  margin-top: -10.625rem !important; }

.mt-n35 {
  margin-top: -10.9375rem !important; }

.mt-n36 {
  margin-top: -11.25rem !important; }

.mt-n37 {
  margin-top: -11.5625rem !important; }

.mt-n38 {
  margin-top: -11.875rem !important; }

.mt-n39 {
  margin-top: -12.1875rem !important; }

.mt-n40 {
  margin-top: -12.5rem !important; }

.mt-nbase {
  margin-top: -calc(20rem / 16) !important; }

.me-n1 {
  margin-right: -0.3125rem !important; }

.me-n2 {
  margin-right: -0.625rem !important; }

.me-n3 {
  margin-right: -0.9375rem !important; }

.me-n4 {
  margin-right: -1.25rem !important; }

.me-n5 {
  margin-right: -1.5625rem !important; }

.me-n6 {
  margin-right: -1.875rem !important; }

.me-n7 {
  margin-right: -2.1875rem !important; }

.me-n8 {
  margin-right: -2.5rem !important; }

.me-n9 {
  margin-right: -2.8125rem !important; }

.me-n10 {
  margin-right: -3.125rem !important; }

.me-n11 {
  margin-right: -3.4375rem !important; }

.me-n12 {
  margin-right: -3.75rem !important; }

.me-n13 {
  margin-right: -4.0625rem !important; }

.me-n14 {
  margin-right: -4.375rem !important; }

.me-n15 {
  margin-right: -4.6875rem !important; }

.me-n16 {
  margin-right: -5rem !important; }

.me-n17 {
  margin-right: -5.3125rem !important; }

.me-n18 {
  margin-right: -5.625rem !important; }

.me-n19 {
  margin-right: -5.9375rem !important; }

.me-n20 {
  margin-right: -6.25rem !important; }

.me-n21 {
  margin-right: -6.5625rem !important; }

.me-n22 {
  margin-right: -6.875rem !important; }

.me-n23 {
  margin-right: -7.1875rem !important; }

.me-n24 {
  margin-right: -7.5rem !important; }

.me-n25 {
  margin-right: -7.8125rem !important; }

.me-n26 {
  margin-right: -8.125rem !important; }

.me-n27 {
  margin-right: -8.4375rem !important; }

.me-n28 {
  margin-right: -8.75rem !important; }

.me-n29 {
  margin-right: -9.0625rem !important; }

.me-n30 {
  margin-right: -9.375rem !important; }

.me-n31 {
  margin-right: -9.6875rem !important; }

.me-n32 {
  margin-right: -10rem !important; }

.me-n33 {
  margin-right: -10.3125rem !important; }

.me-n34 {
  margin-right: -10.625rem !important; }

.me-n35 {
  margin-right: -10.9375rem !important; }

.me-n36 {
  margin-right: -11.25rem !important; }

.me-n37 {
  margin-right: -11.5625rem !important; }

.me-n38 {
  margin-right: -11.875rem !important; }

.me-n39 {
  margin-right: -12.1875rem !important; }

.me-n40 {
  margin-right: -12.5rem !important; }

.me-nbase {
  margin-right: -calc(20rem / 16) !important; }

.mb-n1 {
  margin-bottom: -0.3125rem !important; }

.mb-n2 {
  margin-bottom: -0.625rem !important; }

.mb-n3 {
  margin-bottom: -0.9375rem !important; }

.mb-n4 {
  margin-bottom: -1.25rem !important; }

.mb-n5 {
  margin-bottom: -1.5625rem !important; }

.mb-n6 {
  margin-bottom: -1.875rem !important; }

.mb-n7 {
  margin-bottom: -2.1875rem !important; }

.mb-n8 {
  margin-bottom: -2.5rem !important; }

.mb-n9 {
  margin-bottom: -2.8125rem !important; }

.mb-n10 {
  margin-bottom: -3.125rem !important; }

.mb-n11 {
  margin-bottom: -3.4375rem !important; }

.mb-n12 {
  margin-bottom: -3.75rem !important; }

.mb-n13 {
  margin-bottom: -4.0625rem !important; }

.mb-n14 {
  margin-bottom: -4.375rem !important; }

.mb-n15 {
  margin-bottom: -4.6875rem !important; }

.mb-n16 {
  margin-bottom: -5rem !important; }

.mb-n17 {
  margin-bottom: -5.3125rem !important; }

.mb-n18 {
  margin-bottom: -5.625rem !important; }

.mb-n19 {
  margin-bottom: -5.9375rem !important; }

.mb-n20 {
  margin-bottom: -6.25rem !important; }

.mb-n21 {
  margin-bottom: -6.5625rem !important; }

.mb-n22 {
  margin-bottom: -6.875rem !important; }

.mb-n23 {
  margin-bottom: -7.1875rem !important; }

.mb-n24 {
  margin-bottom: -7.5rem !important; }

.mb-n25 {
  margin-bottom: -7.8125rem !important; }

.mb-n26 {
  margin-bottom: -8.125rem !important; }

.mb-n27 {
  margin-bottom: -8.4375rem !important; }

.mb-n28 {
  margin-bottom: -8.75rem !important; }

.mb-n29 {
  margin-bottom: -9.0625rem !important; }

.mb-n30 {
  margin-bottom: -9.375rem !important; }

.mb-n31 {
  margin-bottom: -9.6875rem !important; }

.mb-n32 {
  margin-bottom: -10rem !important; }

.mb-n33 {
  margin-bottom: -10.3125rem !important; }

.mb-n34 {
  margin-bottom: -10.625rem !important; }

.mb-n35 {
  margin-bottom: -10.9375rem !important; }

.mb-n36 {
  margin-bottom: -11.25rem !important; }

.mb-n37 {
  margin-bottom: -11.5625rem !important; }

.mb-n38 {
  margin-bottom: -11.875rem !important; }

.mb-n39 {
  margin-bottom: -12.1875rem !important; }

.mb-n40 {
  margin-bottom: -12.5rem !important; }

.mb-nbase {
  margin-bottom: -calc(20rem / 16) !important; }

.ms-n1 {
  margin-left: -0.3125rem !important; }

.ms-n2 {
  margin-left: -0.625rem !important; }

.ms-n3 {
  margin-left: -0.9375rem !important; }

.ms-n4 {
  margin-left: -1.25rem !important; }

.ms-n5 {
  margin-left: -1.5625rem !important; }

.ms-n6 {
  margin-left: -1.875rem !important; }

.ms-n7 {
  margin-left: -2.1875rem !important; }

.ms-n8 {
  margin-left: -2.5rem !important; }

.ms-n9 {
  margin-left: -2.8125rem !important; }

.ms-n10 {
  margin-left: -3.125rem !important; }

.ms-n11 {
  margin-left: -3.4375rem !important; }

.ms-n12 {
  margin-left: -3.75rem !important; }

.ms-n13 {
  margin-left: -4.0625rem !important; }

.ms-n14 {
  margin-left: -4.375rem !important; }

.ms-n15 {
  margin-left: -4.6875rem !important; }

.ms-n16 {
  margin-left: -5rem !important; }

.ms-n17 {
  margin-left: -5.3125rem !important; }

.ms-n18 {
  margin-left: -5.625rem !important; }

.ms-n19 {
  margin-left: -5.9375rem !important; }

.ms-n20 {
  margin-left: -6.25rem !important; }

.ms-n21 {
  margin-left: -6.5625rem !important; }

.ms-n22 {
  margin-left: -6.875rem !important; }

.ms-n23 {
  margin-left: -7.1875rem !important; }

.ms-n24 {
  margin-left: -7.5rem !important; }

.ms-n25 {
  margin-left: -7.8125rem !important; }

.ms-n26 {
  margin-left: -8.125rem !important; }

.ms-n27 {
  margin-left: -8.4375rem !important; }

.ms-n28 {
  margin-left: -8.75rem !important; }

.ms-n29 {
  margin-left: -9.0625rem !important; }

.ms-n30 {
  margin-left: -9.375rem !important; }

.ms-n31 {
  margin-left: -9.6875rem !important; }

.ms-n32 {
  margin-left: -10rem !important; }

.ms-n33 {
  margin-left: -10.3125rem !important; }

.ms-n34 {
  margin-left: -10.625rem !important; }

.ms-n35 {
  margin-left: -10.9375rem !important; }

.ms-n36 {
  margin-left: -11.25rem !important; }

.ms-n37 {
  margin-left: -11.5625rem !important; }

.ms-n38 {
  margin-left: -11.875rem !important; }

.ms-n39 {
  margin-left: -12.1875rem !important; }

.ms-n40 {
  margin-left: -12.5rem !important; }

.ms-nbase {
  margin-left: -calc(20rem / 16) !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.3125rem !important; }

.p-2 {
  padding: 0.625rem !important; }

.p-3 {
  padding: 0.9375rem !important; }

.p-4 {
  padding: 1.25rem !important; }

.p-5 {
  padding: 1.5625rem !important; }

.p-6 {
  padding: 1.875rem !important; }

.p-7 {
  padding: 2.1875rem !important; }

.p-8 {
  padding: 2.5rem !important; }

.p-9 {
  padding: 2.8125rem !important; }

.p-10 {
  padding: 3.125rem !important; }

.p-11 {
  padding: 3.4375rem !important; }

.p-12 {
  padding: 3.75rem !important; }

.p-13 {
  padding: 4.0625rem !important; }

.p-14 {
  padding: 4.375rem !important; }

.p-15 {
  padding: 4.6875rem !important; }

.p-16 {
  padding: 5rem !important; }

.p-17 {
  padding: 5.3125rem !important; }

.p-18 {
  padding: 5.625rem !important; }

.p-19 {
  padding: 5.9375rem !important; }

.p-20 {
  padding: 6.25rem !important; }

.p-21 {
  padding: 6.5625rem !important; }

.p-22 {
  padding: 6.875rem !important; }

.p-23 {
  padding: 7.1875rem !important; }

.p-24 {
  padding: 7.5rem !important; }

.p-25 {
  padding: 7.8125rem !important; }

.p-26 {
  padding: 8.125rem !important; }

.p-27 {
  padding: 8.4375rem !important; }

.p-28 {
  padding: 8.75rem !important; }

.p-29 {
  padding: 9.0625rem !important; }

.p-30 {
  padding: 9.375rem !important; }

.p-31 {
  padding: 9.6875rem !important; }

.p-32 {
  padding: 10rem !important; }

.p-33 {
  padding: 10.3125rem !important; }

.p-34 {
  padding: 10.625rem !important; }

.p-35 {
  padding: 10.9375rem !important; }

.p-36 {
  padding: 11.25rem !important; }

.p-37 {
  padding: 11.5625rem !important; }

.p-38 {
  padding: 11.875rem !important; }

.p-39 {
  padding: 12.1875rem !important; }

.p-40 {
  padding: 12.5rem !important; }

.p-base {
  padding: calc(20rem / 16) !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.3125rem !important;
  padding-left: 0.3125rem !important; }

.px-2 {
  padding-right: 0.625rem !important;
  padding-left: 0.625rem !important; }

.px-3 {
  padding-right: 0.9375rem !important;
  padding-left: 0.9375rem !important; }

.px-4 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important; }

.px-5 {
  padding-right: 1.5625rem !important;
  padding-left: 1.5625rem !important; }

.px-6 {
  padding-right: 1.875rem !important;
  padding-left: 1.875rem !important; }

.px-7 {
  padding-right: 2.1875rem !important;
  padding-left: 2.1875rem !important; }

.px-8 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important; }

.px-9 {
  padding-right: 2.8125rem !important;
  padding-left: 2.8125rem !important; }

.px-10 {
  padding-right: 3.125rem !important;
  padding-left: 3.125rem !important; }

.px-11 {
  padding-right: 3.4375rem !important;
  padding-left: 3.4375rem !important; }

.px-12 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important; }

.px-13 {
  padding-right: 4.0625rem !important;
  padding-left: 4.0625rem !important; }

.px-14 {
  padding-right: 4.375rem !important;
  padding-left: 4.375rem !important; }

.px-15 {
  padding-right: 4.6875rem !important;
  padding-left: 4.6875rem !important; }

.px-16 {
  padding-right: 5rem !important;
  padding-left: 5rem !important; }

.px-17 {
  padding-right: 5.3125rem !important;
  padding-left: 5.3125rem !important; }

.px-18 {
  padding-right: 5.625rem !important;
  padding-left: 5.625rem !important; }

.px-19 {
  padding-right: 5.9375rem !important;
  padding-left: 5.9375rem !important; }

.px-20 {
  padding-right: 6.25rem !important;
  padding-left: 6.25rem !important; }

.px-21 {
  padding-right: 6.5625rem !important;
  padding-left: 6.5625rem !important; }

.px-22 {
  padding-right: 6.875rem !important;
  padding-left: 6.875rem !important; }

.px-23 {
  padding-right: 7.1875rem !important;
  padding-left: 7.1875rem !important; }

.px-24 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important; }

.px-25 {
  padding-right: 7.8125rem !important;
  padding-left: 7.8125rem !important; }

.px-26 {
  padding-right: 8.125rem !important;
  padding-left: 8.125rem !important; }

.px-27 {
  padding-right: 8.4375rem !important;
  padding-left: 8.4375rem !important; }

.px-28 {
  padding-right: 8.75rem !important;
  padding-left: 8.75rem !important; }

.px-29 {
  padding-right: 9.0625rem !important;
  padding-left: 9.0625rem !important; }

.px-30 {
  padding-right: 9.375rem !important;
  padding-left: 9.375rem !important; }

.px-31 {
  padding-right: 9.6875rem !important;
  padding-left: 9.6875rem !important; }

.px-32 {
  padding-right: 10rem !important;
  padding-left: 10rem !important; }

.px-33 {
  padding-right: 10.3125rem !important;
  padding-left: 10.3125rem !important; }

.px-34 {
  padding-right: 10.625rem !important;
  padding-left: 10.625rem !important; }

.px-35 {
  padding-right: 10.9375rem !important;
  padding-left: 10.9375rem !important; }

.px-36 {
  padding-right: 11.25rem !important;
  padding-left: 11.25rem !important; }

.px-37 {
  padding-right: 11.5625rem !important;
  padding-left: 11.5625rem !important; }

.px-38 {
  padding-right: 11.875rem !important;
  padding-left: 11.875rem !important; }

.px-39 {
  padding-right: 12.1875rem !important;
  padding-left: 12.1875rem !important; }

.px-40 {
  padding-right: 12.5rem !important;
  padding-left: 12.5rem !important; }

.px-base {
  padding-right: calc(20rem / 16) !important;
  padding-left: calc(20rem / 16) !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important; }

.py-2 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important; }

.py-3 {
  padding-top: 0.9375rem !important;
  padding-bottom: 0.9375rem !important; }

.py-4 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important; }

.py-5 {
  padding-top: 1.5625rem !important;
  padding-bottom: 1.5625rem !important; }

.py-6 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important; }

.py-7 {
  padding-top: 2.1875rem !important;
  padding-bottom: 2.1875rem !important; }

.py-8 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important; }

.py-9 {
  padding-top: 2.8125rem !important;
  padding-bottom: 2.8125rem !important; }

.py-10 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important; }

.py-11 {
  padding-top: 3.4375rem !important;
  padding-bottom: 3.4375rem !important; }

.py-12 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important; }

.py-13 {
  padding-top: 4.0625rem !important;
  padding-bottom: 4.0625rem !important; }

.py-14 {
  padding-top: 4.375rem !important;
  padding-bottom: 4.375rem !important; }

.py-15 {
  padding-top: 4.6875rem !important;
  padding-bottom: 4.6875rem !important; }

.py-16 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important; }

.py-17 {
  padding-top: 5.3125rem !important;
  padding-bottom: 5.3125rem !important; }

.py-18 {
  padding-top: 5.625rem !important;
  padding-bottom: 5.625rem !important; }

.py-19 {
  padding-top: 5.9375rem !important;
  padding-bottom: 5.9375rem !important; }

.py-20 {
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important; }

.py-21 {
  padding-top: 6.5625rem !important;
  padding-bottom: 6.5625rem !important; }

.py-22 {
  padding-top: 6.875rem !important;
  padding-bottom: 6.875rem !important; }

.py-23 {
  padding-top: 7.1875rem !important;
  padding-bottom: 7.1875rem !important; }

.py-24 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important; }

.py-25 {
  padding-top: 7.8125rem !important;
  padding-bottom: 7.8125rem !important; }

.py-26 {
  padding-top: 8.125rem !important;
  padding-bottom: 8.125rem !important; }

.py-27 {
  padding-top: 8.4375rem !important;
  padding-bottom: 8.4375rem !important; }

.py-28 {
  padding-top: 8.75rem !important;
  padding-bottom: 8.75rem !important; }

.py-29 {
  padding-top: 9.0625rem !important;
  padding-bottom: 9.0625rem !important; }

.py-30 {
  padding-top: 9.375rem !important;
  padding-bottom: 9.375rem !important; }

.py-31 {
  padding-top: 9.6875rem !important;
  padding-bottom: 9.6875rem !important; }

.py-32 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important; }

.py-33 {
  padding-top: 10.3125rem !important;
  padding-bottom: 10.3125rem !important; }

.py-34 {
  padding-top: 10.625rem !important;
  padding-bottom: 10.625rem !important; }

.py-35 {
  padding-top: 10.9375rem !important;
  padding-bottom: 10.9375rem !important; }

.py-36 {
  padding-top: 11.25rem !important;
  padding-bottom: 11.25rem !important; }

.py-37 {
  padding-top: 11.5625rem !important;
  padding-bottom: 11.5625rem !important; }

.py-38 {
  padding-top: 11.875rem !important;
  padding-bottom: 11.875rem !important; }

.py-39 {
  padding-top: 12.1875rem !important;
  padding-bottom: 12.1875rem !important; }

.py-40 {
  padding-top: 12.5rem !important;
  padding-bottom: 12.5rem !important; }

.py-base {
  padding-top: calc(20rem / 16) !important;
  padding-bottom: calc(20rem / 16) !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.3125rem !important; }

.pt-2 {
  padding-top: 0.625rem !important; }

.pt-3 {
  padding-top: 0.9375rem !important; }

.pt-4 {
  padding-top: 1.25rem !important; }

.pt-5 {
  padding-top: 1.5625rem !important; }

.pt-6 {
  padding-top: 1.875rem !important; }

.pt-7 {
  padding-top: 2.1875rem !important; }

.pt-8 {
  padding-top: 2.5rem !important; }

.pt-9 {
  padding-top: 2.8125rem !important; }

.pt-10 {
  padding-top: 3.125rem !important; }

.pt-11 {
  padding-top: 3.4375rem !important; }

.pt-12 {
  padding-top: 3.75rem !important; }

.pt-13 {
  padding-top: 4.0625rem !important; }

.pt-14 {
  padding-top: 4.375rem !important; }

.pt-15 {
  padding-top: 4.6875rem !important; }

.pt-16 {
  padding-top: 5rem !important; }

.pt-17 {
  padding-top: 5.3125rem !important; }

.pt-18 {
  padding-top: 5.625rem !important; }

.pt-19 {
  padding-top: 5.9375rem !important; }

.pt-20 {
  padding-top: 6.25rem !important; }

.pt-21 {
  padding-top: 6.5625rem !important; }

.pt-22 {
  padding-top: 6.875rem !important; }

.pt-23 {
  padding-top: 7.1875rem !important; }

.pt-24 {
  padding-top: 7.5rem !important; }

.pt-25 {
  padding-top: 7.8125rem !important; }

.pt-26 {
  padding-top: 8.125rem !important; }

.pt-27 {
  padding-top: 8.4375rem !important; }

.pt-28 {
  padding-top: 8.75rem !important; }

.pt-29 {
  padding-top: 9.0625rem !important; }

.pt-30 {
  padding-top: 9.375rem !important; }

.pt-31 {
  padding-top: 9.6875rem !important; }

.pt-32 {
  padding-top: 10rem !important; }

.pt-33 {
  padding-top: 10.3125rem !important; }

.pt-34 {
  padding-top: 10.625rem !important; }

.pt-35 {
  padding-top: 10.9375rem !important; }

.pt-36 {
  padding-top: 11.25rem !important; }

.pt-37 {
  padding-top: 11.5625rem !important; }

.pt-38 {
  padding-top: 11.875rem !important; }

.pt-39 {
  padding-top: 12.1875rem !important; }

.pt-40 {
  padding-top: 12.5rem !important; }

.pt-base {
  padding-top: calc(20rem / 16) !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.3125rem !important; }

.pe-2 {
  padding-right: 0.625rem !important; }

.pe-3 {
  padding-right: 0.9375rem !important; }

.pe-4 {
  padding-right: 1.25rem !important; }

.pe-5 {
  padding-right: 1.5625rem !important; }

.pe-6 {
  padding-right: 1.875rem !important; }

.pe-7 {
  padding-right: 2.1875rem !important; }

.pe-8 {
  padding-right: 2.5rem !important; }

.pe-9 {
  padding-right: 2.8125rem !important; }

.pe-10 {
  padding-right: 3.125rem !important; }

.pe-11 {
  padding-right: 3.4375rem !important; }

.pe-12 {
  padding-right: 3.75rem !important; }

.pe-13 {
  padding-right: 4.0625rem !important; }

.pe-14 {
  padding-right: 4.375rem !important; }

.pe-15 {
  padding-right: 4.6875rem !important; }

.pe-16 {
  padding-right: 5rem !important; }

.pe-17 {
  padding-right: 5.3125rem !important; }

.pe-18 {
  padding-right: 5.625rem !important; }

.pe-19 {
  padding-right: 5.9375rem !important; }

.pe-20 {
  padding-right: 6.25rem !important; }

.pe-21 {
  padding-right: 6.5625rem !important; }

.pe-22 {
  padding-right: 6.875rem !important; }

.pe-23 {
  padding-right: 7.1875rem !important; }

.pe-24 {
  padding-right: 7.5rem !important; }

.pe-25 {
  padding-right: 7.8125rem !important; }

.pe-26 {
  padding-right: 8.125rem !important; }

.pe-27 {
  padding-right: 8.4375rem !important; }

.pe-28 {
  padding-right: 8.75rem !important; }

.pe-29 {
  padding-right: 9.0625rem !important; }

.pe-30 {
  padding-right: 9.375rem !important; }

.pe-31 {
  padding-right: 9.6875rem !important; }

.pe-32 {
  padding-right: 10rem !important; }

.pe-33 {
  padding-right: 10.3125rem !important; }

.pe-34 {
  padding-right: 10.625rem !important; }

.pe-35 {
  padding-right: 10.9375rem !important; }

.pe-36 {
  padding-right: 11.25rem !important; }

.pe-37 {
  padding-right: 11.5625rem !important; }

.pe-38 {
  padding-right: 11.875rem !important; }

.pe-39 {
  padding-right: 12.1875rem !important; }

.pe-40 {
  padding-right: 12.5rem !important; }

.pe-base {
  padding-right: calc(20rem / 16) !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.3125rem !important; }

.pb-2 {
  padding-bottom: 0.625rem !important; }

.pb-3 {
  padding-bottom: 0.9375rem !important; }

.pb-4 {
  padding-bottom: 1.25rem !important; }

.pb-5 {
  padding-bottom: 1.5625rem !important; }

.pb-6 {
  padding-bottom: 1.875rem !important; }

.pb-7 {
  padding-bottom: 2.1875rem !important; }

.pb-8 {
  padding-bottom: 2.5rem !important; }

.pb-9 {
  padding-bottom: 2.8125rem !important; }

.pb-10 {
  padding-bottom: 3.125rem !important; }

.pb-11 {
  padding-bottom: 3.4375rem !important; }

.pb-12 {
  padding-bottom: 3.75rem !important; }

.pb-13 {
  padding-bottom: 4.0625rem !important; }

.pb-14 {
  padding-bottom: 4.375rem !important; }

.pb-15 {
  padding-bottom: 4.6875rem !important; }

.pb-16 {
  padding-bottom: 5rem !important; }

.pb-17 {
  padding-bottom: 5.3125rem !important; }

.pb-18 {
  padding-bottom: 5.625rem !important; }

.pb-19 {
  padding-bottom: 5.9375rem !important; }

.pb-20 {
  padding-bottom: 6.25rem !important; }

.pb-21 {
  padding-bottom: 6.5625rem !important; }

.pb-22 {
  padding-bottom: 6.875rem !important; }

.pb-23 {
  padding-bottom: 7.1875rem !important; }

.pb-24 {
  padding-bottom: 7.5rem !important; }

.pb-25 {
  padding-bottom: 7.8125rem !important; }

.pb-26 {
  padding-bottom: 8.125rem !important; }

.pb-27 {
  padding-bottom: 8.4375rem !important; }

.pb-28 {
  padding-bottom: 8.75rem !important; }

.pb-29 {
  padding-bottom: 9.0625rem !important; }

.pb-30 {
  padding-bottom: 9.375rem !important; }

.pb-31 {
  padding-bottom: 9.6875rem !important; }

.pb-32 {
  padding-bottom: 10rem !important; }

.pb-33 {
  padding-bottom: 10.3125rem !important; }

.pb-34 {
  padding-bottom: 10.625rem !important; }

.pb-35 {
  padding-bottom: 10.9375rem !important; }

.pb-36 {
  padding-bottom: 11.25rem !important; }

.pb-37 {
  padding-bottom: 11.5625rem !important; }

.pb-38 {
  padding-bottom: 11.875rem !important; }

.pb-39 {
  padding-bottom: 12.1875rem !important; }

.pb-40 {
  padding-bottom: 12.5rem !important; }

.pb-base {
  padding-bottom: calc(20rem / 16) !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.3125rem !important; }

.ps-2 {
  padding-left: 0.625rem !important; }

.ps-3 {
  padding-left: 0.9375rem !important; }

.ps-4 {
  padding-left: 1.25rem !important; }

.ps-5 {
  padding-left: 1.5625rem !important; }

.ps-6 {
  padding-left: 1.875rem !important; }

.ps-7 {
  padding-left: 2.1875rem !important; }

.ps-8 {
  padding-left: 2.5rem !important; }

.ps-9 {
  padding-left: 2.8125rem !important; }

.ps-10 {
  padding-left: 3.125rem !important; }

.ps-11 {
  padding-left: 3.4375rem !important; }

.ps-12 {
  padding-left: 3.75rem !important; }

.ps-13 {
  padding-left: 4.0625rem !important; }

.ps-14 {
  padding-left: 4.375rem !important; }

.ps-15 {
  padding-left: 4.6875rem !important; }

.ps-16 {
  padding-left: 5rem !important; }

.ps-17 {
  padding-left: 5.3125rem !important; }

.ps-18 {
  padding-left: 5.625rem !important; }

.ps-19 {
  padding-left: 5.9375rem !important; }

.ps-20 {
  padding-left: 6.25rem !important; }

.ps-21 {
  padding-left: 6.5625rem !important; }

.ps-22 {
  padding-left: 6.875rem !important; }

.ps-23 {
  padding-left: 7.1875rem !important; }

.ps-24 {
  padding-left: 7.5rem !important; }

.ps-25 {
  padding-left: 7.8125rem !important; }

.ps-26 {
  padding-left: 8.125rem !important; }

.ps-27 {
  padding-left: 8.4375rem !important; }

.ps-28 {
  padding-left: 8.75rem !important; }

.ps-29 {
  padding-left: 9.0625rem !important; }

.ps-30 {
  padding-left: 9.375rem !important; }

.ps-31 {
  padding-left: 9.6875rem !important; }

.ps-32 {
  padding-left: 10rem !important; }

.ps-33 {
  padding-left: 10.3125rem !important; }

.ps-34 {
  padding-left: 10.625rem !important; }

.ps-35 {
  padding-left: 10.9375rem !important; }

.ps-36 {
  padding-left: 11.25rem !important; }

.ps-37 {
  padding-left: 11.5625rem !important; }

.ps-38 {
  padding-left: 11.875rem !important; }

.ps-39 {
  padding-left: 12.1875rem !important; }

.ps-40 {
  padding-left: 12.5rem !important; }

.ps-base {
  padding-left: calc(20rem / 16) !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.3125rem !important; }

.gap-2 {
  gap: 0.625rem !important; }

.gap-3 {
  gap: 0.9375rem !important; }

.gap-4 {
  gap: 1.25rem !important; }

.gap-5 {
  gap: 1.5625rem !important; }

.gap-6 {
  gap: 1.875rem !important; }

.gap-7 {
  gap: 2.1875rem !important; }

.gap-8 {
  gap: 2.5rem !important; }

.gap-9 {
  gap: 2.8125rem !important; }

.gap-10 {
  gap: 3.125rem !important; }

.gap-11 {
  gap: 3.4375rem !important; }

.gap-12 {
  gap: 3.75rem !important; }

.gap-13 {
  gap: 4.0625rem !important; }

.gap-14 {
  gap: 4.375rem !important; }

.gap-15 {
  gap: 4.6875rem !important; }

.gap-16 {
  gap: 5rem !important; }

.gap-17 {
  gap: 5.3125rem !important; }

.gap-18 {
  gap: 5.625rem !important; }

.gap-19 {
  gap: 5.9375rem !important; }

.gap-20 {
  gap: 6.25rem !important; }

.gap-21 {
  gap: 6.5625rem !important; }

.gap-22 {
  gap: 6.875rem !important; }

.gap-23 {
  gap: 7.1875rem !important; }

.gap-24 {
  gap: 7.5rem !important; }

.gap-25 {
  gap: 7.8125rem !important; }

.gap-26 {
  gap: 8.125rem !important; }

.gap-27 {
  gap: 8.4375rem !important; }

.gap-28 {
  gap: 8.75rem !important; }

.gap-29 {
  gap: 9.0625rem !important; }

.gap-30 {
  gap: 9.375rem !important; }

.gap-31 {
  gap: 9.6875rem !important; }

.gap-32 {
  gap: 10rem !important; }

.gap-33 {
  gap: 10.3125rem !important; }

.gap-34 {
  gap: 10.625rem !important; }

.gap-35 {
  gap: 10.9375rem !important; }

.gap-36 {
  gap: 11.25rem !important; }

.gap-37 {
  gap: 11.5625rem !important; }

.gap-38 {
  gap: 11.875rem !important; }

.gap-39 {
  gap: 12.1875rem !important; }

.gap-40 {
  gap: 12.5rem !important; }

.gap-base {
  gap: calc(20rem / 16) !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #B5ADA6 !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: var(--bs-border-radius) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important; }

.rounded-2 {
  border-radius: var(--bs-border-radius) !important; }

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important; }

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important; }

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important; }

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.3125rem !important; }
  .m-sm-2 {
    margin: 0.625rem !important; }
  .m-sm-3 {
    margin: 0.9375rem !important; }
  .m-sm-4 {
    margin: 1.25rem !important; }
  .m-sm-5 {
    margin: 1.5625rem !important; }
  .m-sm-6 {
    margin: 1.875rem !important; }
  .m-sm-7 {
    margin: 2.1875rem !important; }
  .m-sm-8 {
    margin: 2.5rem !important; }
  .m-sm-9 {
    margin: 2.8125rem !important; }
  .m-sm-10 {
    margin: 3.125rem !important; }
  .m-sm-11 {
    margin: 3.4375rem !important; }
  .m-sm-12 {
    margin: 3.75rem !important; }
  .m-sm-13 {
    margin: 4.0625rem !important; }
  .m-sm-14 {
    margin: 4.375rem !important; }
  .m-sm-15 {
    margin: 4.6875rem !important; }
  .m-sm-16 {
    margin: 5rem !important; }
  .m-sm-17 {
    margin: 5.3125rem !important; }
  .m-sm-18 {
    margin: 5.625rem !important; }
  .m-sm-19 {
    margin: 5.9375rem !important; }
  .m-sm-20 {
    margin: 6.25rem !important; }
  .m-sm-21 {
    margin: 6.5625rem !important; }
  .m-sm-22 {
    margin: 6.875rem !important; }
  .m-sm-23 {
    margin: 7.1875rem !important; }
  .m-sm-24 {
    margin: 7.5rem !important; }
  .m-sm-25 {
    margin: 7.8125rem !important; }
  .m-sm-26 {
    margin: 8.125rem !important; }
  .m-sm-27 {
    margin: 8.4375rem !important; }
  .m-sm-28 {
    margin: 8.75rem !important; }
  .m-sm-29 {
    margin: 9.0625rem !important; }
  .m-sm-30 {
    margin: 9.375rem !important; }
  .m-sm-31 {
    margin: 9.6875rem !important; }
  .m-sm-32 {
    margin: 10rem !important; }
  .m-sm-33 {
    margin: 10.3125rem !important; }
  .m-sm-34 {
    margin: 10.625rem !important; }
  .m-sm-35 {
    margin: 10.9375rem !important; }
  .m-sm-36 {
    margin: 11.25rem !important; }
  .m-sm-37 {
    margin: 11.5625rem !important; }
  .m-sm-38 {
    margin: 11.875rem !important; }
  .m-sm-39 {
    margin: 12.1875rem !important; }
  .m-sm-40 {
    margin: 12.5rem !important; }
  .m-sm-base {
    margin: calc(20rem / 16) !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important; }
  .mx-sm-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important; }
  .mx-sm-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important; }
  .mx-sm-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important; }
  .mx-sm-5 {
    margin-right: 1.5625rem !important;
    margin-left: 1.5625rem !important; }
  .mx-sm-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important; }
  .mx-sm-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important; }
  .mx-sm-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-sm-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important; }
  .mx-sm-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important; }
  .mx-sm-11 {
    margin-right: 3.4375rem !important;
    margin-left: 3.4375rem !important; }
  .mx-sm-12 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important; }
  .mx-sm-13 {
    margin-right: 4.0625rem !important;
    margin-left: 4.0625rem !important; }
  .mx-sm-14 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important; }
  .mx-sm-15 {
    margin-right: 4.6875rem !important;
    margin-left: 4.6875rem !important; }
  .mx-sm-16 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-sm-17 {
    margin-right: 5.3125rem !important;
    margin-left: 5.3125rem !important; }
  .mx-sm-18 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important; }
  .mx-sm-19 {
    margin-right: 5.9375rem !important;
    margin-left: 5.9375rem !important; }
  .mx-sm-20 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important; }
  .mx-sm-21 {
    margin-right: 6.5625rem !important;
    margin-left: 6.5625rem !important; }
  .mx-sm-22 {
    margin-right: 6.875rem !important;
    margin-left: 6.875rem !important; }
  .mx-sm-23 {
    margin-right: 7.1875rem !important;
    margin-left: 7.1875rem !important; }
  .mx-sm-24 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-sm-25 {
    margin-right: 7.8125rem !important;
    margin-left: 7.8125rem !important; }
  .mx-sm-26 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important; }
  .mx-sm-27 {
    margin-right: 8.4375rem !important;
    margin-left: 8.4375rem !important; }
  .mx-sm-28 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important; }
  .mx-sm-29 {
    margin-right: 9.0625rem !important;
    margin-left: 9.0625rem !important; }
  .mx-sm-30 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important; }
  .mx-sm-31 {
    margin-right: 9.6875rem !important;
    margin-left: 9.6875rem !important; }
  .mx-sm-32 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-sm-33 {
    margin-right: 10.3125rem !important;
    margin-left: 10.3125rem !important; }
  .mx-sm-34 {
    margin-right: 10.625rem !important;
    margin-left: 10.625rem !important; }
  .mx-sm-35 {
    margin-right: 10.9375rem !important;
    margin-left: 10.9375rem !important; }
  .mx-sm-36 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important; }
  .mx-sm-37 {
    margin-right: 11.5625rem !important;
    margin-left: 11.5625rem !important; }
  .mx-sm-38 {
    margin-right: 11.875rem !important;
    margin-left: 11.875rem !important; }
  .mx-sm-39 {
    margin-right: 12.1875rem !important;
    margin-left: 12.1875rem !important; }
  .mx-sm-40 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-sm-base {
    margin-right: calc(20rem / 16) !important;
    margin-left: calc(20rem / 16) !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important; }
  .my-sm-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important; }
  .my-sm-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important; }
  .my-sm-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .my-sm-5 {
    margin-top: 1.5625rem !important;
    margin-bottom: 1.5625rem !important; }
  .my-sm-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important; }
  .my-sm-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important; }
  .my-sm-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-sm-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important; }
  .my-sm-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important; }
  .my-sm-11 {
    margin-top: 3.4375rem !important;
    margin-bottom: 3.4375rem !important; }
  .my-sm-12 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important; }
  .my-sm-13 {
    margin-top: 4.0625rem !important;
    margin-bottom: 4.0625rem !important; }
  .my-sm-14 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important; }
  .my-sm-15 {
    margin-top: 4.6875rem !important;
    margin-bottom: 4.6875rem !important; }
  .my-sm-16 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-sm-17 {
    margin-top: 5.3125rem !important;
    margin-bottom: 5.3125rem !important; }
  .my-sm-18 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important; }
  .my-sm-19 {
    margin-top: 5.9375rem !important;
    margin-bottom: 5.9375rem !important; }
  .my-sm-20 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important; }
  .my-sm-21 {
    margin-top: 6.5625rem !important;
    margin-bottom: 6.5625rem !important; }
  .my-sm-22 {
    margin-top: 6.875rem !important;
    margin-bottom: 6.875rem !important; }
  .my-sm-23 {
    margin-top: 7.1875rem !important;
    margin-bottom: 7.1875rem !important; }
  .my-sm-24 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-sm-25 {
    margin-top: 7.8125rem !important;
    margin-bottom: 7.8125rem !important; }
  .my-sm-26 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important; }
  .my-sm-27 {
    margin-top: 8.4375rem !important;
    margin-bottom: 8.4375rem !important; }
  .my-sm-28 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important; }
  .my-sm-29 {
    margin-top: 9.0625rem !important;
    margin-bottom: 9.0625rem !important; }
  .my-sm-30 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important; }
  .my-sm-31 {
    margin-top: 9.6875rem !important;
    margin-bottom: 9.6875rem !important; }
  .my-sm-32 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-sm-33 {
    margin-top: 10.3125rem !important;
    margin-bottom: 10.3125rem !important; }
  .my-sm-34 {
    margin-top: 10.625rem !important;
    margin-bottom: 10.625rem !important; }
  .my-sm-35 {
    margin-top: 10.9375rem !important;
    margin-bottom: 10.9375rem !important; }
  .my-sm-36 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important; }
  .my-sm-37 {
    margin-top: 11.5625rem !important;
    margin-bottom: 11.5625rem !important; }
  .my-sm-38 {
    margin-top: 11.875rem !important;
    margin-bottom: 11.875rem !important; }
  .my-sm-39 {
    margin-top: 12.1875rem !important;
    margin-bottom: 12.1875rem !important; }
  .my-sm-40 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-sm-base {
    margin-top: calc(20rem / 16) !important;
    margin-bottom: calc(20rem / 16) !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.3125rem !important; }
  .mt-sm-2 {
    margin-top: 0.625rem !important; }
  .mt-sm-3 {
    margin-top: 0.9375rem !important; }
  .mt-sm-4 {
    margin-top: 1.25rem !important; }
  .mt-sm-5 {
    margin-top: 1.5625rem !important; }
  .mt-sm-6 {
    margin-top: 1.875rem !important; }
  .mt-sm-7 {
    margin-top: 2.1875rem !important; }
  .mt-sm-8 {
    margin-top: 2.5rem !important; }
  .mt-sm-9 {
    margin-top: 2.8125rem !important; }
  .mt-sm-10 {
    margin-top: 3.125rem !important; }
  .mt-sm-11 {
    margin-top: 3.4375rem !important; }
  .mt-sm-12 {
    margin-top: 3.75rem !important; }
  .mt-sm-13 {
    margin-top: 4.0625rem !important; }
  .mt-sm-14 {
    margin-top: 4.375rem !important; }
  .mt-sm-15 {
    margin-top: 4.6875rem !important; }
  .mt-sm-16 {
    margin-top: 5rem !important; }
  .mt-sm-17 {
    margin-top: 5.3125rem !important; }
  .mt-sm-18 {
    margin-top: 5.625rem !important; }
  .mt-sm-19 {
    margin-top: 5.9375rem !important; }
  .mt-sm-20 {
    margin-top: 6.25rem !important; }
  .mt-sm-21 {
    margin-top: 6.5625rem !important; }
  .mt-sm-22 {
    margin-top: 6.875rem !important; }
  .mt-sm-23 {
    margin-top: 7.1875rem !important; }
  .mt-sm-24 {
    margin-top: 7.5rem !important; }
  .mt-sm-25 {
    margin-top: 7.8125rem !important; }
  .mt-sm-26 {
    margin-top: 8.125rem !important; }
  .mt-sm-27 {
    margin-top: 8.4375rem !important; }
  .mt-sm-28 {
    margin-top: 8.75rem !important; }
  .mt-sm-29 {
    margin-top: 9.0625rem !important; }
  .mt-sm-30 {
    margin-top: 9.375rem !important; }
  .mt-sm-31 {
    margin-top: 9.6875rem !important; }
  .mt-sm-32 {
    margin-top: 10rem !important; }
  .mt-sm-33 {
    margin-top: 10.3125rem !important; }
  .mt-sm-34 {
    margin-top: 10.625rem !important; }
  .mt-sm-35 {
    margin-top: 10.9375rem !important; }
  .mt-sm-36 {
    margin-top: 11.25rem !important; }
  .mt-sm-37 {
    margin-top: 11.5625rem !important; }
  .mt-sm-38 {
    margin-top: 11.875rem !important; }
  .mt-sm-39 {
    margin-top: 12.1875rem !important; }
  .mt-sm-40 {
    margin-top: 12.5rem !important; }
  .mt-sm-base {
    margin-top: calc(20rem / 16) !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.3125rem !important; }
  .me-sm-2 {
    margin-right: 0.625rem !important; }
  .me-sm-3 {
    margin-right: 0.9375rem !important; }
  .me-sm-4 {
    margin-right: 1.25rem !important; }
  .me-sm-5 {
    margin-right: 1.5625rem !important; }
  .me-sm-6 {
    margin-right: 1.875rem !important; }
  .me-sm-7 {
    margin-right: 2.1875rem !important; }
  .me-sm-8 {
    margin-right: 2.5rem !important; }
  .me-sm-9 {
    margin-right: 2.8125rem !important; }
  .me-sm-10 {
    margin-right: 3.125rem !important; }
  .me-sm-11 {
    margin-right: 3.4375rem !important; }
  .me-sm-12 {
    margin-right: 3.75rem !important; }
  .me-sm-13 {
    margin-right: 4.0625rem !important; }
  .me-sm-14 {
    margin-right: 4.375rem !important; }
  .me-sm-15 {
    margin-right: 4.6875rem !important; }
  .me-sm-16 {
    margin-right: 5rem !important; }
  .me-sm-17 {
    margin-right: 5.3125rem !important; }
  .me-sm-18 {
    margin-right: 5.625rem !important; }
  .me-sm-19 {
    margin-right: 5.9375rem !important; }
  .me-sm-20 {
    margin-right: 6.25rem !important; }
  .me-sm-21 {
    margin-right: 6.5625rem !important; }
  .me-sm-22 {
    margin-right: 6.875rem !important; }
  .me-sm-23 {
    margin-right: 7.1875rem !important; }
  .me-sm-24 {
    margin-right: 7.5rem !important; }
  .me-sm-25 {
    margin-right: 7.8125rem !important; }
  .me-sm-26 {
    margin-right: 8.125rem !important; }
  .me-sm-27 {
    margin-right: 8.4375rem !important; }
  .me-sm-28 {
    margin-right: 8.75rem !important; }
  .me-sm-29 {
    margin-right: 9.0625rem !important; }
  .me-sm-30 {
    margin-right: 9.375rem !important; }
  .me-sm-31 {
    margin-right: 9.6875rem !important; }
  .me-sm-32 {
    margin-right: 10rem !important; }
  .me-sm-33 {
    margin-right: 10.3125rem !important; }
  .me-sm-34 {
    margin-right: 10.625rem !important; }
  .me-sm-35 {
    margin-right: 10.9375rem !important; }
  .me-sm-36 {
    margin-right: 11.25rem !important; }
  .me-sm-37 {
    margin-right: 11.5625rem !important; }
  .me-sm-38 {
    margin-right: 11.875rem !important; }
  .me-sm-39 {
    margin-right: 12.1875rem !important; }
  .me-sm-40 {
    margin-right: 12.5rem !important; }
  .me-sm-base {
    margin-right: calc(20rem / 16) !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.3125rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.625rem !important; }
  .mb-sm-3 {
    margin-bottom: 0.9375rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.25rem !important; }
  .mb-sm-5 {
    margin-bottom: 1.5625rem !important; }
  .mb-sm-6 {
    margin-bottom: 1.875rem !important; }
  .mb-sm-7 {
    margin-bottom: 2.1875rem !important; }
  .mb-sm-8 {
    margin-bottom: 2.5rem !important; }
  .mb-sm-9 {
    margin-bottom: 2.8125rem !important; }
  .mb-sm-10 {
    margin-bottom: 3.125rem !important; }
  .mb-sm-11 {
    margin-bottom: 3.4375rem !important; }
  .mb-sm-12 {
    margin-bottom: 3.75rem !important; }
  .mb-sm-13 {
    margin-bottom: 4.0625rem !important; }
  .mb-sm-14 {
    margin-bottom: 4.375rem !important; }
  .mb-sm-15 {
    margin-bottom: 4.6875rem !important; }
  .mb-sm-16 {
    margin-bottom: 5rem !important; }
  .mb-sm-17 {
    margin-bottom: 5.3125rem !important; }
  .mb-sm-18 {
    margin-bottom: 5.625rem !important; }
  .mb-sm-19 {
    margin-bottom: 5.9375rem !important; }
  .mb-sm-20 {
    margin-bottom: 6.25rem !important; }
  .mb-sm-21 {
    margin-bottom: 6.5625rem !important; }
  .mb-sm-22 {
    margin-bottom: 6.875rem !important; }
  .mb-sm-23 {
    margin-bottom: 7.1875rem !important; }
  .mb-sm-24 {
    margin-bottom: 7.5rem !important; }
  .mb-sm-25 {
    margin-bottom: 7.8125rem !important; }
  .mb-sm-26 {
    margin-bottom: 8.125rem !important; }
  .mb-sm-27 {
    margin-bottom: 8.4375rem !important; }
  .mb-sm-28 {
    margin-bottom: 8.75rem !important; }
  .mb-sm-29 {
    margin-bottom: 9.0625rem !important; }
  .mb-sm-30 {
    margin-bottom: 9.375rem !important; }
  .mb-sm-31 {
    margin-bottom: 9.6875rem !important; }
  .mb-sm-32 {
    margin-bottom: 10rem !important; }
  .mb-sm-33 {
    margin-bottom: 10.3125rem !important; }
  .mb-sm-34 {
    margin-bottom: 10.625rem !important; }
  .mb-sm-35 {
    margin-bottom: 10.9375rem !important; }
  .mb-sm-36 {
    margin-bottom: 11.25rem !important; }
  .mb-sm-37 {
    margin-bottom: 11.5625rem !important; }
  .mb-sm-38 {
    margin-bottom: 11.875rem !important; }
  .mb-sm-39 {
    margin-bottom: 12.1875rem !important; }
  .mb-sm-40 {
    margin-bottom: 12.5rem !important; }
  .mb-sm-base {
    margin-bottom: calc(20rem / 16) !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.3125rem !important; }
  .ms-sm-2 {
    margin-left: 0.625rem !important; }
  .ms-sm-3 {
    margin-left: 0.9375rem !important; }
  .ms-sm-4 {
    margin-left: 1.25rem !important; }
  .ms-sm-5 {
    margin-left: 1.5625rem !important; }
  .ms-sm-6 {
    margin-left: 1.875rem !important; }
  .ms-sm-7 {
    margin-left: 2.1875rem !important; }
  .ms-sm-8 {
    margin-left: 2.5rem !important; }
  .ms-sm-9 {
    margin-left: 2.8125rem !important; }
  .ms-sm-10 {
    margin-left: 3.125rem !important; }
  .ms-sm-11 {
    margin-left: 3.4375rem !important; }
  .ms-sm-12 {
    margin-left: 3.75rem !important; }
  .ms-sm-13 {
    margin-left: 4.0625rem !important; }
  .ms-sm-14 {
    margin-left: 4.375rem !important; }
  .ms-sm-15 {
    margin-left: 4.6875rem !important; }
  .ms-sm-16 {
    margin-left: 5rem !important; }
  .ms-sm-17 {
    margin-left: 5.3125rem !important; }
  .ms-sm-18 {
    margin-left: 5.625rem !important; }
  .ms-sm-19 {
    margin-left: 5.9375rem !important; }
  .ms-sm-20 {
    margin-left: 6.25rem !important; }
  .ms-sm-21 {
    margin-left: 6.5625rem !important; }
  .ms-sm-22 {
    margin-left: 6.875rem !important; }
  .ms-sm-23 {
    margin-left: 7.1875rem !important; }
  .ms-sm-24 {
    margin-left: 7.5rem !important; }
  .ms-sm-25 {
    margin-left: 7.8125rem !important; }
  .ms-sm-26 {
    margin-left: 8.125rem !important; }
  .ms-sm-27 {
    margin-left: 8.4375rem !important; }
  .ms-sm-28 {
    margin-left: 8.75rem !important; }
  .ms-sm-29 {
    margin-left: 9.0625rem !important; }
  .ms-sm-30 {
    margin-left: 9.375rem !important; }
  .ms-sm-31 {
    margin-left: 9.6875rem !important; }
  .ms-sm-32 {
    margin-left: 10rem !important; }
  .ms-sm-33 {
    margin-left: 10.3125rem !important; }
  .ms-sm-34 {
    margin-left: 10.625rem !important; }
  .ms-sm-35 {
    margin-left: 10.9375rem !important; }
  .ms-sm-36 {
    margin-left: 11.25rem !important; }
  .ms-sm-37 {
    margin-left: 11.5625rem !important; }
  .ms-sm-38 {
    margin-left: 11.875rem !important; }
  .ms-sm-39 {
    margin-left: 12.1875rem !important; }
  .ms-sm-40 {
    margin-left: 12.5rem !important; }
  .ms-sm-base {
    margin-left: calc(20rem / 16) !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .m-sm-n1 {
    margin: -0.3125rem !important; }
  .m-sm-n2 {
    margin: -0.625rem !important; }
  .m-sm-n3 {
    margin: -0.9375rem !important; }
  .m-sm-n4 {
    margin: -1.25rem !important; }
  .m-sm-n5 {
    margin: -1.5625rem !important; }
  .m-sm-n6 {
    margin: -1.875rem !important; }
  .m-sm-n7 {
    margin: -2.1875rem !important; }
  .m-sm-n8 {
    margin: -2.5rem !important; }
  .m-sm-n9 {
    margin: -2.8125rem !important; }
  .m-sm-n10 {
    margin: -3.125rem !important; }
  .m-sm-n11 {
    margin: -3.4375rem !important; }
  .m-sm-n12 {
    margin: -3.75rem !important; }
  .m-sm-n13 {
    margin: -4.0625rem !important; }
  .m-sm-n14 {
    margin: -4.375rem !important; }
  .m-sm-n15 {
    margin: -4.6875rem !important; }
  .m-sm-n16 {
    margin: -5rem !important; }
  .m-sm-n17 {
    margin: -5.3125rem !important; }
  .m-sm-n18 {
    margin: -5.625rem !important; }
  .m-sm-n19 {
    margin: -5.9375rem !important; }
  .m-sm-n20 {
    margin: -6.25rem !important; }
  .m-sm-n21 {
    margin: -6.5625rem !important; }
  .m-sm-n22 {
    margin: -6.875rem !important; }
  .m-sm-n23 {
    margin: -7.1875rem !important; }
  .m-sm-n24 {
    margin: -7.5rem !important; }
  .m-sm-n25 {
    margin: -7.8125rem !important; }
  .m-sm-n26 {
    margin: -8.125rem !important; }
  .m-sm-n27 {
    margin: -8.4375rem !important; }
  .m-sm-n28 {
    margin: -8.75rem !important; }
  .m-sm-n29 {
    margin: -9.0625rem !important; }
  .m-sm-n30 {
    margin: -9.375rem !important; }
  .m-sm-n31 {
    margin: -9.6875rem !important; }
  .m-sm-n32 {
    margin: -10rem !important; }
  .m-sm-n33 {
    margin: -10.3125rem !important; }
  .m-sm-n34 {
    margin: -10.625rem !important; }
  .m-sm-n35 {
    margin: -10.9375rem !important; }
  .m-sm-n36 {
    margin: -11.25rem !important; }
  .m-sm-n37 {
    margin: -11.5625rem !important; }
  .m-sm-n38 {
    margin: -11.875rem !important; }
  .m-sm-n39 {
    margin: -12.1875rem !important; }
  .m-sm-n40 {
    margin: -12.5rem !important; }
  .m-sm-nbase {
    margin: -calc(20rem / 16) !important; }
  .mx-sm-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important; }
  .mx-sm-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important; }
  .mx-sm-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important; }
  .mx-sm-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important; }
  .mx-sm-n5 {
    margin-right: -1.5625rem !important;
    margin-left: -1.5625rem !important; }
  .mx-sm-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important; }
  .mx-sm-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important; }
  .mx-sm-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-sm-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important; }
  .mx-sm-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important; }
  .mx-sm-n11 {
    margin-right: -3.4375rem !important;
    margin-left: -3.4375rem !important; }
  .mx-sm-n12 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important; }
  .mx-sm-n13 {
    margin-right: -4.0625rem !important;
    margin-left: -4.0625rem !important; }
  .mx-sm-n14 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important; }
  .mx-sm-n15 {
    margin-right: -4.6875rem !important;
    margin-left: -4.6875rem !important; }
  .mx-sm-n16 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-sm-n17 {
    margin-right: -5.3125rem !important;
    margin-left: -5.3125rem !important; }
  .mx-sm-n18 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important; }
  .mx-sm-n19 {
    margin-right: -5.9375rem !important;
    margin-left: -5.9375rem !important; }
  .mx-sm-n20 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important; }
  .mx-sm-n21 {
    margin-right: -6.5625rem !important;
    margin-left: -6.5625rem !important; }
  .mx-sm-n22 {
    margin-right: -6.875rem !important;
    margin-left: -6.875rem !important; }
  .mx-sm-n23 {
    margin-right: -7.1875rem !important;
    margin-left: -7.1875rem !important; }
  .mx-sm-n24 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-sm-n25 {
    margin-right: -7.8125rem !important;
    margin-left: -7.8125rem !important; }
  .mx-sm-n26 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important; }
  .mx-sm-n27 {
    margin-right: -8.4375rem !important;
    margin-left: -8.4375rem !important; }
  .mx-sm-n28 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important; }
  .mx-sm-n29 {
    margin-right: -9.0625rem !important;
    margin-left: -9.0625rem !important; }
  .mx-sm-n30 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important; }
  .mx-sm-n31 {
    margin-right: -9.6875rem !important;
    margin-left: -9.6875rem !important; }
  .mx-sm-n32 {
    margin-right: -10rem !important;
    margin-left: -10rem !important; }
  .mx-sm-n33 {
    margin-right: -10.3125rem !important;
    margin-left: -10.3125rem !important; }
  .mx-sm-n34 {
    margin-right: -10.625rem !important;
    margin-left: -10.625rem !important; }
  .mx-sm-n35 {
    margin-right: -10.9375rem !important;
    margin-left: -10.9375rem !important; }
  .mx-sm-n36 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important; }
  .mx-sm-n37 {
    margin-right: -11.5625rem !important;
    margin-left: -11.5625rem !important; }
  .mx-sm-n38 {
    margin-right: -11.875rem !important;
    margin-left: -11.875rem !important; }
  .mx-sm-n39 {
    margin-right: -12.1875rem !important;
    margin-left: -12.1875rem !important; }
  .mx-sm-n40 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important; }
  .mx-sm-nbase {
    margin-right: -calc(20rem / 16) !important;
    margin-left: -calc(20rem / 16) !important; }
  .my-sm-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important; }
  .my-sm-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important; }
  .my-sm-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important; }
  .my-sm-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .my-sm-n5 {
    margin-top: -1.5625rem !important;
    margin-bottom: -1.5625rem !important; }
  .my-sm-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important; }
  .my-sm-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important; }
  .my-sm-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-sm-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important; }
  .my-sm-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important; }
  .my-sm-n11 {
    margin-top: -3.4375rem !important;
    margin-bottom: -3.4375rem !important; }
  .my-sm-n12 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important; }
  .my-sm-n13 {
    margin-top: -4.0625rem !important;
    margin-bottom: -4.0625rem !important; }
  .my-sm-n14 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important; }
  .my-sm-n15 {
    margin-top: -4.6875rem !important;
    margin-bottom: -4.6875rem !important; }
  .my-sm-n16 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-sm-n17 {
    margin-top: -5.3125rem !important;
    margin-bottom: -5.3125rem !important; }
  .my-sm-n18 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important; }
  .my-sm-n19 {
    margin-top: -5.9375rem !important;
    margin-bottom: -5.9375rem !important; }
  .my-sm-n20 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important; }
  .my-sm-n21 {
    margin-top: -6.5625rem !important;
    margin-bottom: -6.5625rem !important; }
  .my-sm-n22 {
    margin-top: -6.875rem !important;
    margin-bottom: -6.875rem !important; }
  .my-sm-n23 {
    margin-top: -7.1875rem !important;
    margin-bottom: -7.1875rem !important; }
  .my-sm-n24 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-sm-n25 {
    margin-top: -7.8125rem !important;
    margin-bottom: -7.8125rem !important; }
  .my-sm-n26 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important; }
  .my-sm-n27 {
    margin-top: -8.4375rem !important;
    margin-bottom: -8.4375rem !important; }
  .my-sm-n28 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important; }
  .my-sm-n29 {
    margin-top: -9.0625rem !important;
    margin-bottom: -9.0625rem !important; }
  .my-sm-n30 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important; }
  .my-sm-n31 {
    margin-top: -9.6875rem !important;
    margin-bottom: -9.6875rem !important; }
  .my-sm-n32 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .my-sm-n33 {
    margin-top: -10.3125rem !important;
    margin-bottom: -10.3125rem !important; }
  .my-sm-n34 {
    margin-top: -10.625rem !important;
    margin-bottom: -10.625rem !important; }
  .my-sm-n35 {
    margin-top: -10.9375rem !important;
    margin-bottom: -10.9375rem !important; }
  .my-sm-n36 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important; }
  .my-sm-n37 {
    margin-top: -11.5625rem !important;
    margin-bottom: -11.5625rem !important; }
  .my-sm-n38 {
    margin-top: -11.875rem !important;
    margin-bottom: -11.875rem !important; }
  .my-sm-n39 {
    margin-top: -12.1875rem !important;
    margin-bottom: -12.1875rem !important; }
  .my-sm-n40 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important; }
  .my-sm-nbase {
    margin-top: -calc(20rem / 16) !important;
    margin-bottom: -calc(20rem / 16) !important; }
  .mt-sm-n1 {
    margin-top: -0.3125rem !important; }
  .mt-sm-n2 {
    margin-top: -0.625rem !important; }
  .mt-sm-n3 {
    margin-top: -0.9375rem !important; }
  .mt-sm-n4 {
    margin-top: -1.25rem !important; }
  .mt-sm-n5 {
    margin-top: -1.5625rem !important; }
  .mt-sm-n6 {
    margin-top: -1.875rem !important; }
  .mt-sm-n7 {
    margin-top: -2.1875rem !important; }
  .mt-sm-n8 {
    margin-top: -2.5rem !important; }
  .mt-sm-n9 {
    margin-top: -2.8125rem !important; }
  .mt-sm-n10 {
    margin-top: -3.125rem !important; }
  .mt-sm-n11 {
    margin-top: -3.4375rem !important; }
  .mt-sm-n12 {
    margin-top: -3.75rem !important; }
  .mt-sm-n13 {
    margin-top: -4.0625rem !important; }
  .mt-sm-n14 {
    margin-top: -4.375rem !important; }
  .mt-sm-n15 {
    margin-top: -4.6875rem !important; }
  .mt-sm-n16 {
    margin-top: -5rem !important; }
  .mt-sm-n17 {
    margin-top: -5.3125rem !important; }
  .mt-sm-n18 {
    margin-top: -5.625rem !important; }
  .mt-sm-n19 {
    margin-top: -5.9375rem !important; }
  .mt-sm-n20 {
    margin-top: -6.25rem !important; }
  .mt-sm-n21 {
    margin-top: -6.5625rem !important; }
  .mt-sm-n22 {
    margin-top: -6.875rem !important; }
  .mt-sm-n23 {
    margin-top: -7.1875rem !important; }
  .mt-sm-n24 {
    margin-top: -7.5rem !important; }
  .mt-sm-n25 {
    margin-top: -7.8125rem !important; }
  .mt-sm-n26 {
    margin-top: -8.125rem !important; }
  .mt-sm-n27 {
    margin-top: -8.4375rem !important; }
  .mt-sm-n28 {
    margin-top: -8.75rem !important; }
  .mt-sm-n29 {
    margin-top: -9.0625rem !important; }
  .mt-sm-n30 {
    margin-top: -9.375rem !important; }
  .mt-sm-n31 {
    margin-top: -9.6875rem !important; }
  .mt-sm-n32 {
    margin-top: -10rem !important; }
  .mt-sm-n33 {
    margin-top: -10.3125rem !important; }
  .mt-sm-n34 {
    margin-top: -10.625rem !important; }
  .mt-sm-n35 {
    margin-top: -10.9375rem !important; }
  .mt-sm-n36 {
    margin-top: -11.25rem !important; }
  .mt-sm-n37 {
    margin-top: -11.5625rem !important; }
  .mt-sm-n38 {
    margin-top: -11.875rem !important; }
  .mt-sm-n39 {
    margin-top: -12.1875rem !important; }
  .mt-sm-n40 {
    margin-top: -12.5rem !important; }
  .mt-sm-nbase {
    margin-top: -calc(20rem / 16) !important; }
  .me-sm-n1 {
    margin-right: -0.3125rem !important; }
  .me-sm-n2 {
    margin-right: -0.625rem !important; }
  .me-sm-n3 {
    margin-right: -0.9375rem !important; }
  .me-sm-n4 {
    margin-right: -1.25rem !important; }
  .me-sm-n5 {
    margin-right: -1.5625rem !important; }
  .me-sm-n6 {
    margin-right: -1.875rem !important; }
  .me-sm-n7 {
    margin-right: -2.1875rem !important; }
  .me-sm-n8 {
    margin-right: -2.5rem !important; }
  .me-sm-n9 {
    margin-right: -2.8125rem !important; }
  .me-sm-n10 {
    margin-right: -3.125rem !important; }
  .me-sm-n11 {
    margin-right: -3.4375rem !important; }
  .me-sm-n12 {
    margin-right: -3.75rem !important; }
  .me-sm-n13 {
    margin-right: -4.0625rem !important; }
  .me-sm-n14 {
    margin-right: -4.375rem !important; }
  .me-sm-n15 {
    margin-right: -4.6875rem !important; }
  .me-sm-n16 {
    margin-right: -5rem !important; }
  .me-sm-n17 {
    margin-right: -5.3125rem !important; }
  .me-sm-n18 {
    margin-right: -5.625rem !important; }
  .me-sm-n19 {
    margin-right: -5.9375rem !important; }
  .me-sm-n20 {
    margin-right: -6.25rem !important; }
  .me-sm-n21 {
    margin-right: -6.5625rem !important; }
  .me-sm-n22 {
    margin-right: -6.875rem !important; }
  .me-sm-n23 {
    margin-right: -7.1875rem !important; }
  .me-sm-n24 {
    margin-right: -7.5rem !important; }
  .me-sm-n25 {
    margin-right: -7.8125rem !important; }
  .me-sm-n26 {
    margin-right: -8.125rem !important; }
  .me-sm-n27 {
    margin-right: -8.4375rem !important; }
  .me-sm-n28 {
    margin-right: -8.75rem !important; }
  .me-sm-n29 {
    margin-right: -9.0625rem !important; }
  .me-sm-n30 {
    margin-right: -9.375rem !important; }
  .me-sm-n31 {
    margin-right: -9.6875rem !important; }
  .me-sm-n32 {
    margin-right: -10rem !important; }
  .me-sm-n33 {
    margin-right: -10.3125rem !important; }
  .me-sm-n34 {
    margin-right: -10.625rem !important; }
  .me-sm-n35 {
    margin-right: -10.9375rem !important; }
  .me-sm-n36 {
    margin-right: -11.25rem !important; }
  .me-sm-n37 {
    margin-right: -11.5625rem !important; }
  .me-sm-n38 {
    margin-right: -11.875rem !important; }
  .me-sm-n39 {
    margin-right: -12.1875rem !important; }
  .me-sm-n40 {
    margin-right: -12.5rem !important; }
  .me-sm-nbase {
    margin-right: -calc(20rem / 16) !important; }
  .mb-sm-n1 {
    margin-bottom: -0.3125rem !important; }
  .mb-sm-n2 {
    margin-bottom: -0.625rem !important; }
  .mb-sm-n3 {
    margin-bottom: -0.9375rem !important; }
  .mb-sm-n4 {
    margin-bottom: -1.25rem !important; }
  .mb-sm-n5 {
    margin-bottom: -1.5625rem !important; }
  .mb-sm-n6 {
    margin-bottom: -1.875rem !important; }
  .mb-sm-n7 {
    margin-bottom: -2.1875rem !important; }
  .mb-sm-n8 {
    margin-bottom: -2.5rem !important; }
  .mb-sm-n9 {
    margin-bottom: -2.8125rem !important; }
  .mb-sm-n10 {
    margin-bottom: -3.125rem !important; }
  .mb-sm-n11 {
    margin-bottom: -3.4375rem !important; }
  .mb-sm-n12 {
    margin-bottom: -3.75rem !important; }
  .mb-sm-n13 {
    margin-bottom: -4.0625rem !important; }
  .mb-sm-n14 {
    margin-bottom: -4.375rem !important; }
  .mb-sm-n15 {
    margin-bottom: -4.6875rem !important; }
  .mb-sm-n16 {
    margin-bottom: -5rem !important; }
  .mb-sm-n17 {
    margin-bottom: -5.3125rem !important; }
  .mb-sm-n18 {
    margin-bottom: -5.625rem !important; }
  .mb-sm-n19 {
    margin-bottom: -5.9375rem !important; }
  .mb-sm-n20 {
    margin-bottom: -6.25rem !important; }
  .mb-sm-n21 {
    margin-bottom: -6.5625rem !important; }
  .mb-sm-n22 {
    margin-bottom: -6.875rem !important; }
  .mb-sm-n23 {
    margin-bottom: -7.1875rem !important; }
  .mb-sm-n24 {
    margin-bottom: -7.5rem !important; }
  .mb-sm-n25 {
    margin-bottom: -7.8125rem !important; }
  .mb-sm-n26 {
    margin-bottom: -8.125rem !important; }
  .mb-sm-n27 {
    margin-bottom: -8.4375rem !important; }
  .mb-sm-n28 {
    margin-bottom: -8.75rem !important; }
  .mb-sm-n29 {
    margin-bottom: -9.0625rem !important; }
  .mb-sm-n30 {
    margin-bottom: -9.375rem !important; }
  .mb-sm-n31 {
    margin-bottom: -9.6875rem !important; }
  .mb-sm-n32 {
    margin-bottom: -10rem !important; }
  .mb-sm-n33 {
    margin-bottom: -10.3125rem !important; }
  .mb-sm-n34 {
    margin-bottom: -10.625rem !important; }
  .mb-sm-n35 {
    margin-bottom: -10.9375rem !important; }
  .mb-sm-n36 {
    margin-bottom: -11.25rem !important; }
  .mb-sm-n37 {
    margin-bottom: -11.5625rem !important; }
  .mb-sm-n38 {
    margin-bottom: -11.875rem !important; }
  .mb-sm-n39 {
    margin-bottom: -12.1875rem !important; }
  .mb-sm-n40 {
    margin-bottom: -12.5rem !important; }
  .mb-sm-nbase {
    margin-bottom: -calc(20rem / 16) !important; }
  .ms-sm-n1 {
    margin-left: -0.3125rem !important; }
  .ms-sm-n2 {
    margin-left: -0.625rem !important; }
  .ms-sm-n3 {
    margin-left: -0.9375rem !important; }
  .ms-sm-n4 {
    margin-left: -1.25rem !important; }
  .ms-sm-n5 {
    margin-left: -1.5625rem !important; }
  .ms-sm-n6 {
    margin-left: -1.875rem !important; }
  .ms-sm-n7 {
    margin-left: -2.1875rem !important; }
  .ms-sm-n8 {
    margin-left: -2.5rem !important; }
  .ms-sm-n9 {
    margin-left: -2.8125rem !important; }
  .ms-sm-n10 {
    margin-left: -3.125rem !important; }
  .ms-sm-n11 {
    margin-left: -3.4375rem !important; }
  .ms-sm-n12 {
    margin-left: -3.75rem !important; }
  .ms-sm-n13 {
    margin-left: -4.0625rem !important; }
  .ms-sm-n14 {
    margin-left: -4.375rem !important; }
  .ms-sm-n15 {
    margin-left: -4.6875rem !important; }
  .ms-sm-n16 {
    margin-left: -5rem !important; }
  .ms-sm-n17 {
    margin-left: -5.3125rem !important; }
  .ms-sm-n18 {
    margin-left: -5.625rem !important; }
  .ms-sm-n19 {
    margin-left: -5.9375rem !important; }
  .ms-sm-n20 {
    margin-left: -6.25rem !important; }
  .ms-sm-n21 {
    margin-left: -6.5625rem !important; }
  .ms-sm-n22 {
    margin-left: -6.875rem !important; }
  .ms-sm-n23 {
    margin-left: -7.1875rem !important; }
  .ms-sm-n24 {
    margin-left: -7.5rem !important; }
  .ms-sm-n25 {
    margin-left: -7.8125rem !important; }
  .ms-sm-n26 {
    margin-left: -8.125rem !important; }
  .ms-sm-n27 {
    margin-left: -8.4375rem !important; }
  .ms-sm-n28 {
    margin-left: -8.75rem !important; }
  .ms-sm-n29 {
    margin-left: -9.0625rem !important; }
  .ms-sm-n30 {
    margin-left: -9.375rem !important; }
  .ms-sm-n31 {
    margin-left: -9.6875rem !important; }
  .ms-sm-n32 {
    margin-left: -10rem !important; }
  .ms-sm-n33 {
    margin-left: -10.3125rem !important; }
  .ms-sm-n34 {
    margin-left: -10.625rem !important; }
  .ms-sm-n35 {
    margin-left: -10.9375rem !important; }
  .ms-sm-n36 {
    margin-left: -11.25rem !important; }
  .ms-sm-n37 {
    margin-left: -11.5625rem !important; }
  .ms-sm-n38 {
    margin-left: -11.875rem !important; }
  .ms-sm-n39 {
    margin-left: -12.1875rem !important; }
  .ms-sm-n40 {
    margin-left: -12.5rem !important; }
  .ms-sm-nbase {
    margin-left: -calc(20rem / 16) !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.3125rem !important; }
  .p-sm-2 {
    padding: 0.625rem !important; }
  .p-sm-3 {
    padding: 0.9375rem !important; }
  .p-sm-4 {
    padding: 1.25rem !important; }
  .p-sm-5 {
    padding: 1.5625rem !important; }
  .p-sm-6 {
    padding: 1.875rem !important; }
  .p-sm-7 {
    padding: 2.1875rem !important; }
  .p-sm-8 {
    padding: 2.5rem !important; }
  .p-sm-9 {
    padding: 2.8125rem !important; }
  .p-sm-10 {
    padding: 3.125rem !important; }
  .p-sm-11 {
    padding: 3.4375rem !important; }
  .p-sm-12 {
    padding: 3.75rem !important; }
  .p-sm-13 {
    padding: 4.0625rem !important; }
  .p-sm-14 {
    padding: 4.375rem !important; }
  .p-sm-15 {
    padding: 4.6875rem !important; }
  .p-sm-16 {
    padding: 5rem !important; }
  .p-sm-17 {
    padding: 5.3125rem !important; }
  .p-sm-18 {
    padding: 5.625rem !important; }
  .p-sm-19 {
    padding: 5.9375rem !important; }
  .p-sm-20 {
    padding: 6.25rem !important; }
  .p-sm-21 {
    padding: 6.5625rem !important; }
  .p-sm-22 {
    padding: 6.875rem !important; }
  .p-sm-23 {
    padding: 7.1875rem !important; }
  .p-sm-24 {
    padding: 7.5rem !important; }
  .p-sm-25 {
    padding: 7.8125rem !important; }
  .p-sm-26 {
    padding: 8.125rem !important; }
  .p-sm-27 {
    padding: 8.4375rem !important; }
  .p-sm-28 {
    padding: 8.75rem !important; }
  .p-sm-29 {
    padding: 9.0625rem !important; }
  .p-sm-30 {
    padding: 9.375rem !important; }
  .p-sm-31 {
    padding: 9.6875rem !important; }
  .p-sm-32 {
    padding: 10rem !important; }
  .p-sm-33 {
    padding: 10.3125rem !important; }
  .p-sm-34 {
    padding: 10.625rem !important; }
  .p-sm-35 {
    padding: 10.9375rem !important; }
  .p-sm-36 {
    padding: 11.25rem !important; }
  .p-sm-37 {
    padding: 11.5625rem !important; }
  .p-sm-38 {
    padding: 11.875rem !important; }
  .p-sm-39 {
    padding: 12.1875rem !important; }
  .p-sm-40 {
    padding: 12.5rem !important; }
  .p-sm-base {
    padding: calc(20rem / 16) !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important; }
  .px-sm-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important; }
  .px-sm-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important; }
  .px-sm-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important; }
  .px-sm-5 {
    padding-right: 1.5625rem !important;
    padding-left: 1.5625rem !important; }
  .px-sm-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important; }
  .px-sm-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important; }
  .px-sm-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-sm-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important; }
  .px-sm-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important; }
  .px-sm-11 {
    padding-right: 3.4375rem !important;
    padding-left: 3.4375rem !important; }
  .px-sm-12 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important; }
  .px-sm-13 {
    padding-right: 4.0625rem !important;
    padding-left: 4.0625rem !important; }
  .px-sm-14 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important; }
  .px-sm-15 {
    padding-right: 4.6875rem !important;
    padding-left: 4.6875rem !important; }
  .px-sm-16 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-sm-17 {
    padding-right: 5.3125rem !important;
    padding-left: 5.3125rem !important; }
  .px-sm-18 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important; }
  .px-sm-19 {
    padding-right: 5.9375rem !important;
    padding-left: 5.9375rem !important; }
  .px-sm-20 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important; }
  .px-sm-21 {
    padding-right: 6.5625rem !important;
    padding-left: 6.5625rem !important; }
  .px-sm-22 {
    padding-right: 6.875rem !important;
    padding-left: 6.875rem !important; }
  .px-sm-23 {
    padding-right: 7.1875rem !important;
    padding-left: 7.1875rem !important; }
  .px-sm-24 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-sm-25 {
    padding-right: 7.8125rem !important;
    padding-left: 7.8125rem !important; }
  .px-sm-26 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important; }
  .px-sm-27 {
    padding-right: 8.4375rem !important;
    padding-left: 8.4375rem !important; }
  .px-sm-28 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important; }
  .px-sm-29 {
    padding-right: 9.0625rem !important;
    padding-left: 9.0625rem !important; }
  .px-sm-30 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important; }
  .px-sm-31 {
    padding-right: 9.6875rem !important;
    padding-left: 9.6875rem !important; }
  .px-sm-32 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-sm-33 {
    padding-right: 10.3125rem !important;
    padding-left: 10.3125rem !important; }
  .px-sm-34 {
    padding-right: 10.625rem !important;
    padding-left: 10.625rem !important; }
  .px-sm-35 {
    padding-right: 10.9375rem !important;
    padding-left: 10.9375rem !important; }
  .px-sm-36 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important; }
  .px-sm-37 {
    padding-right: 11.5625rem !important;
    padding-left: 11.5625rem !important; }
  .px-sm-38 {
    padding-right: 11.875rem !important;
    padding-left: 11.875rem !important; }
  .px-sm-39 {
    padding-right: 12.1875rem !important;
    padding-left: 12.1875rem !important; }
  .px-sm-40 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-sm-base {
    padding-right: calc(20rem / 16) !important;
    padding-left: calc(20rem / 16) !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important; }
  .py-sm-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important; }
  .py-sm-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important; }
  .py-sm-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .py-sm-5 {
    padding-top: 1.5625rem !important;
    padding-bottom: 1.5625rem !important; }
  .py-sm-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important; }
  .py-sm-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important; }
  .py-sm-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-sm-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important; }
  .py-sm-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important; }
  .py-sm-11 {
    padding-top: 3.4375rem !important;
    padding-bottom: 3.4375rem !important; }
  .py-sm-12 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important; }
  .py-sm-13 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important; }
  .py-sm-14 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important; }
  .py-sm-15 {
    padding-top: 4.6875rem !important;
    padding-bottom: 4.6875rem !important; }
  .py-sm-16 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-sm-17 {
    padding-top: 5.3125rem !important;
    padding-bottom: 5.3125rem !important; }
  .py-sm-18 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important; }
  .py-sm-19 {
    padding-top: 5.9375rem !important;
    padding-bottom: 5.9375rem !important; }
  .py-sm-20 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important; }
  .py-sm-21 {
    padding-top: 6.5625rem !important;
    padding-bottom: 6.5625rem !important; }
  .py-sm-22 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important; }
  .py-sm-23 {
    padding-top: 7.1875rem !important;
    padding-bottom: 7.1875rem !important; }
  .py-sm-24 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-sm-25 {
    padding-top: 7.8125rem !important;
    padding-bottom: 7.8125rem !important; }
  .py-sm-26 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important; }
  .py-sm-27 {
    padding-top: 8.4375rem !important;
    padding-bottom: 8.4375rem !important; }
  .py-sm-28 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important; }
  .py-sm-29 {
    padding-top: 9.0625rem !important;
    padding-bottom: 9.0625rem !important; }
  .py-sm-30 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important; }
  .py-sm-31 {
    padding-top: 9.6875rem !important;
    padding-bottom: 9.6875rem !important; }
  .py-sm-32 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-sm-33 {
    padding-top: 10.3125rem !important;
    padding-bottom: 10.3125rem !important; }
  .py-sm-34 {
    padding-top: 10.625rem !important;
    padding-bottom: 10.625rem !important; }
  .py-sm-35 {
    padding-top: 10.9375rem !important;
    padding-bottom: 10.9375rem !important; }
  .py-sm-36 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important; }
  .py-sm-37 {
    padding-top: 11.5625rem !important;
    padding-bottom: 11.5625rem !important; }
  .py-sm-38 {
    padding-top: 11.875rem !important;
    padding-bottom: 11.875rem !important; }
  .py-sm-39 {
    padding-top: 12.1875rem !important;
    padding-bottom: 12.1875rem !important; }
  .py-sm-40 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-sm-base {
    padding-top: calc(20rem / 16) !important;
    padding-bottom: calc(20rem / 16) !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.3125rem !important; }
  .pt-sm-2 {
    padding-top: 0.625rem !important; }
  .pt-sm-3 {
    padding-top: 0.9375rem !important; }
  .pt-sm-4 {
    padding-top: 1.25rem !important; }
  .pt-sm-5 {
    padding-top: 1.5625rem !important; }
  .pt-sm-6 {
    padding-top: 1.875rem !important; }
  .pt-sm-7 {
    padding-top: 2.1875rem !important; }
  .pt-sm-8 {
    padding-top: 2.5rem !important; }
  .pt-sm-9 {
    padding-top: 2.8125rem !important; }
  .pt-sm-10 {
    padding-top: 3.125rem !important; }
  .pt-sm-11 {
    padding-top: 3.4375rem !important; }
  .pt-sm-12 {
    padding-top: 3.75rem !important; }
  .pt-sm-13 {
    padding-top: 4.0625rem !important; }
  .pt-sm-14 {
    padding-top: 4.375rem !important; }
  .pt-sm-15 {
    padding-top: 4.6875rem !important; }
  .pt-sm-16 {
    padding-top: 5rem !important; }
  .pt-sm-17 {
    padding-top: 5.3125rem !important; }
  .pt-sm-18 {
    padding-top: 5.625rem !important; }
  .pt-sm-19 {
    padding-top: 5.9375rem !important; }
  .pt-sm-20 {
    padding-top: 6.25rem !important; }
  .pt-sm-21 {
    padding-top: 6.5625rem !important; }
  .pt-sm-22 {
    padding-top: 6.875rem !important; }
  .pt-sm-23 {
    padding-top: 7.1875rem !important; }
  .pt-sm-24 {
    padding-top: 7.5rem !important; }
  .pt-sm-25 {
    padding-top: 7.8125rem !important; }
  .pt-sm-26 {
    padding-top: 8.125rem !important; }
  .pt-sm-27 {
    padding-top: 8.4375rem !important; }
  .pt-sm-28 {
    padding-top: 8.75rem !important; }
  .pt-sm-29 {
    padding-top: 9.0625rem !important; }
  .pt-sm-30 {
    padding-top: 9.375rem !important; }
  .pt-sm-31 {
    padding-top: 9.6875rem !important; }
  .pt-sm-32 {
    padding-top: 10rem !important; }
  .pt-sm-33 {
    padding-top: 10.3125rem !important; }
  .pt-sm-34 {
    padding-top: 10.625rem !important; }
  .pt-sm-35 {
    padding-top: 10.9375rem !important; }
  .pt-sm-36 {
    padding-top: 11.25rem !important; }
  .pt-sm-37 {
    padding-top: 11.5625rem !important; }
  .pt-sm-38 {
    padding-top: 11.875rem !important; }
  .pt-sm-39 {
    padding-top: 12.1875rem !important; }
  .pt-sm-40 {
    padding-top: 12.5rem !important; }
  .pt-sm-base {
    padding-top: calc(20rem / 16) !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.3125rem !important; }
  .pe-sm-2 {
    padding-right: 0.625rem !important; }
  .pe-sm-3 {
    padding-right: 0.9375rem !important; }
  .pe-sm-4 {
    padding-right: 1.25rem !important; }
  .pe-sm-5 {
    padding-right: 1.5625rem !important; }
  .pe-sm-6 {
    padding-right: 1.875rem !important; }
  .pe-sm-7 {
    padding-right: 2.1875rem !important; }
  .pe-sm-8 {
    padding-right: 2.5rem !important; }
  .pe-sm-9 {
    padding-right: 2.8125rem !important; }
  .pe-sm-10 {
    padding-right: 3.125rem !important; }
  .pe-sm-11 {
    padding-right: 3.4375rem !important; }
  .pe-sm-12 {
    padding-right: 3.75rem !important; }
  .pe-sm-13 {
    padding-right: 4.0625rem !important; }
  .pe-sm-14 {
    padding-right: 4.375rem !important; }
  .pe-sm-15 {
    padding-right: 4.6875rem !important; }
  .pe-sm-16 {
    padding-right: 5rem !important; }
  .pe-sm-17 {
    padding-right: 5.3125rem !important; }
  .pe-sm-18 {
    padding-right: 5.625rem !important; }
  .pe-sm-19 {
    padding-right: 5.9375rem !important; }
  .pe-sm-20 {
    padding-right: 6.25rem !important; }
  .pe-sm-21 {
    padding-right: 6.5625rem !important; }
  .pe-sm-22 {
    padding-right: 6.875rem !important; }
  .pe-sm-23 {
    padding-right: 7.1875rem !important; }
  .pe-sm-24 {
    padding-right: 7.5rem !important; }
  .pe-sm-25 {
    padding-right: 7.8125rem !important; }
  .pe-sm-26 {
    padding-right: 8.125rem !important; }
  .pe-sm-27 {
    padding-right: 8.4375rem !important; }
  .pe-sm-28 {
    padding-right: 8.75rem !important; }
  .pe-sm-29 {
    padding-right: 9.0625rem !important; }
  .pe-sm-30 {
    padding-right: 9.375rem !important; }
  .pe-sm-31 {
    padding-right: 9.6875rem !important; }
  .pe-sm-32 {
    padding-right: 10rem !important; }
  .pe-sm-33 {
    padding-right: 10.3125rem !important; }
  .pe-sm-34 {
    padding-right: 10.625rem !important; }
  .pe-sm-35 {
    padding-right: 10.9375rem !important; }
  .pe-sm-36 {
    padding-right: 11.25rem !important; }
  .pe-sm-37 {
    padding-right: 11.5625rem !important; }
  .pe-sm-38 {
    padding-right: 11.875rem !important; }
  .pe-sm-39 {
    padding-right: 12.1875rem !important; }
  .pe-sm-40 {
    padding-right: 12.5rem !important; }
  .pe-sm-base {
    padding-right: calc(20rem / 16) !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.3125rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.625rem !important; }
  .pb-sm-3 {
    padding-bottom: 0.9375rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.25rem !important; }
  .pb-sm-5 {
    padding-bottom: 1.5625rem !important; }
  .pb-sm-6 {
    padding-bottom: 1.875rem !important; }
  .pb-sm-7 {
    padding-bottom: 2.1875rem !important; }
  .pb-sm-8 {
    padding-bottom: 2.5rem !important; }
  .pb-sm-9 {
    padding-bottom: 2.8125rem !important; }
  .pb-sm-10 {
    padding-bottom: 3.125rem !important; }
  .pb-sm-11 {
    padding-bottom: 3.4375rem !important; }
  .pb-sm-12 {
    padding-bottom: 3.75rem !important; }
  .pb-sm-13 {
    padding-bottom: 4.0625rem !important; }
  .pb-sm-14 {
    padding-bottom: 4.375rem !important; }
  .pb-sm-15 {
    padding-bottom: 4.6875rem !important; }
  .pb-sm-16 {
    padding-bottom: 5rem !important; }
  .pb-sm-17 {
    padding-bottom: 5.3125rem !important; }
  .pb-sm-18 {
    padding-bottom: 5.625rem !important; }
  .pb-sm-19 {
    padding-bottom: 5.9375rem !important; }
  .pb-sm-20 {
    padding-bottom: 6.25rem !important; }
  .pb-sm-21 {
    padding-bottom: 6.5625rem !important; }
  .pb-sm-22 {
    padding-bottom: 6.875rem !important; }
  .pb-sm-23 {
    padding-bottom: 7.1875rem !important; }
  .pb-sm-24 {
    padding-bottom: 7.5rem !important; }
  .pb-sm-25 {
    padding-bottom: 7.8125rem !important; }
  .pb-sm-26 {
    padding-bottom: 8.125rem !important; }
  .pb-sm-27 {
    padding-bottom: 8.4375rem !important; }
  .pb-sm-28 {
    padding-bottom: 8.75rem !important; }
  .pb-sm-29 {
    padding-bottom: 9.0625rem !important; }
  .pb-sm-30 {
    padding-bottom: 9.375rem !important; }
  .pb-sm-31 {
    padding-bottom: 9.6875rem !important; }
  .pb-sm-32 {
    padding-bottom: 10rem !important; }
  .pb-sm-33 {
    padding-bottom: 10.3125rem !important; }
  .pb-sm-34 {
    padding-bottom: 10.625rem !important; }
  .pb-sm-35 {
    padding-bottom: 10.9375rem !important; }
  .pb-sm-36 {
    padding-bottom: 11.25rem !important; }
  .pb-sm-37 {
    padding-bottom: 11.5625rem !important; }
  .pb-sm-38 {
    padding-bottom: 11.875rem !important; }
  .pb-sm-39 {
    padding-bottom: 12.1875rem !important; }
  .pb-sm-40 {
    padding-bottom: 12.5rem !important; }
  .pb-sm-base {
    padding-bottom: calc(20rem / 16) !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.3125rem !important; }
  .ps-sm-2 {
    padding-left: 0.625rem !important; }
  .ps-sm-3 {
    padding-left: 0.9375rem !important; }
  .ps-sm-4 {
    padding-left: 1.25rem !important; }
  .ps-sm-5 {
    padding-left: 1.5625rem !important; }
  .ps-sm-6 {
    padding-left: 1.875rem !important; }
  .ps-sm-7 {
    padding-left: 2.1875rem !important; }
  .ps-sm-8 {
    padding-left: 2.5rem !important; }
  .ps-sm-9 {
    padding-left: 2.8125rem !important; }
  .ps-sm-10 {
    padding-left: 3.125rem !important; }
  .ps-sm-11 {
    padding-left: 3.4375rem !important; }
  .ps-sm-12 {
    padding-left: 3.75rem !important; }
  .ps-sm-13 {
    padding-left: 4.0625rem !important; }
  .ps-sm-14 {
    padding-left: 4.375rem !important; }
  .ps-sm-15 {
    padding-left: 4.6875rem !important; }
  .ps-sm-16 {
    padding-left: 5rem !important; }
  .ps-sm-17 {
    padding-left: 5.3125rem !important; }
  .ps-sm-18 {
    padding-left: 5.625rem !important; }
  .ps-sm-19 {
    padding-left: 5.9375rem !important; }
  .ps-sm-20 {
    padding-left: 6.25rem !important; }
  .ps-sm-21 {
    padding-left: 6.5625rem !important; }
  .ps-sm-22 {
    padding-left: 6.875rem !important; }
  .ps-sm-23 {
    padding-left: 7.1875rem !important; }
  .ps-sm-24 {
    padding-left: 7.5rem !important; }
  .ps-sm-25 {
    padding-left: 7.8125rem !important; }
  .ps-sm-26 {
    padding-left: 8.125rem !important; }
  .ps-sm-27 {
    padding-left: 8.4375rem !important; }
  .ps-sm-28 {
    padding-left: 8.75rem !important; }
  .ps-sm-29 {
    padding-left: 9.0625rem !important; }
  .ps-sm-30 {
    padding-left: 9.375rem !important; }
  .ps-sm-31 {
    padding-left: 9.6875rem !important; }
  .ps-sm-32 {
    padding-left: 10rem !important; }
  .ps-sm-33 {
    padding-left: 10.3125rem !important; }
  .ps-sm-34 {
    padding-left: 10.625rem !important; }
  .ps-sm-35 {
    padding-left: 10.9375rem !important; }
  .ps-sm-36 {
    padding-left: 11.25rem !important; }
  .ps-sm-37 {
    padding-left: 11.5625rem !important; }
  .ps-sm-38 {
    padding-left: 11.875rem !important; }
  .ps-sm-39 {
    padding-left: 12.1875rem !important; }
  .ps-sm-40 {
    padding-left: 12.5rem !important; }
  .ps-sm-base {
    padding-left: calc(20rem / 16) !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.3125rem !important; }
  .gap-sm-2 {
    gap: 0.625rem !important; }
  .gap-sm-3 {
    gap: 0.9375rem !important; }
  .gap-sm-4 {
    gap: 1.25rem !important; }
  .gap-sm-5 {
    gap: 1.5625rem !important; }
  .gap-sm-6 {
    gap: 1.875rem !important; }
  .gap-sm-7 {
    gap: 2.1875rem !important; }
  .gap-sm-8 {
    gap: 2.5rem !important; }
  .gap-sm-9 {
    gap: 2.8125rem !important; }
  .gap-sm-10 {
    gap: 3.125rem !important; }
  .gap-sm-11 {
    gap: 3.4375rem !important; }
  .gap-sm-12 {
    gap: 3.75rem !important; }
  .gap-sm-13 {
    gap: 4.0625rem !important; }
  .gap-sm-14 {
    gap: 4.375rem !important; }
  .gap-sm-15 {
    gap: 4.6875rem !important; }
  .gap-sm-16 {
    gap: 5rem !important; }
  .gap-sm-17 {
    gap: 5.3125rem !important; }
  .gap-sm-18 {
    gap: 5.625rem !important; }
  .gap-sm-19 {
    gap: 5.9375rem !important; }
  .gap-sm-20 {
    gap: 6.25rem !important; }
  .gap-sm-21 {
    gap: 6.5625rem !important; }
  .gap-sm-22 {
    gap: 6.875rem !important; }
  .gap-sm-23 {
    gap: 7.1875rem !important; }
  .gap-sm-24 {
    gap: 7.5rem !important; }
  .gap-sm-25 {
    gap: 7.8125rem !important; }
  .gap-sm-26 {
    gap: 8.125rem !important; }
  .gap-sm-27 {
    gap: 8.4375rem !important; }
  .gap-sm-28 {
    gap: 8.75rem !important; }
  .gap-sm-29 {
    gap: 9.0625rem !important; }
  .gap-sm-30 {
    gap: 9.375rem !important; }
  .gap-sm-31 {
    gap: 9.6875rem !important; }
  .gap-sm-32 {
    gap: 10rem !important; }
  .gap-sm-33 {
    gap: 10.3125rem !important; }
  .gap-sm-34 {
    gap: 10.625rem !important; }
  .gap-sm-35 {
    gap: 10.9375rem !important; }
  .gap-sm-36 {
    gap: 11.25rem !important; }
  .gap-sm-37 {
    gap: 11.5625rem !important; }
  .gap-sm-38 {
    gap: 11.875rem !important; }
  .gap-sm-39 {
    gap: 12.1875rem !important; }
  .gap-sm-40 {
    gap: 12.5rem !important; }
  .gap-sm-base {
    gap: calc(20rem / 16) !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.3125rem !important; }
  .m-md-2 {
    margin: 0.625rem !important; }
  .m-md-3 {
    margin: 0.9375rem !important; }
  .m-md-4 {
    margin: 1.25rem !important; }
  .m-md-5 {
    margin: 1.5625rem !important; }
  .m-md-6 {
    margin: 1.875rem !important; }
  .m-md-7 {
    margin: 2.1875rem !important; }
  .m-md-8 {
    margin: 2.5rem !important; }
  .m-md-9 {
    margin: 2.8125rem !important; }
  .m-md-10 {
    margin: 3.125rem !important; }
  .m-md-11 {
    margin: 3.4375rem !important; }
  .m-md-12 {
    margin: 3.75rem !important; }
  .m-md-13 {
    margin: 4.0625rem !important; }
  .m-md-14 {
    margin: 4.375rem !important; }
  .m-md-15 {
    margin: 4.6875rem !important; }
  .m-md-16 {
    margin: 5rem !important; }
  .m-md-17 {
    margin: 5.3125rem !important; }
  .m-md-18 {
    margin: 5.625rem !important; }
  .m-md-19 {
    margin: 5.9375rem !important; }
  .m-md-20 {
    margin: 6.25rem !important; }
  .m-md-21 {
    margin: 6.5625rem !important; }
  .m-md-22 {
    margin: 6.875rem !important; }
  .m-md-23 {
    margin: 7.1875rem !important; }
  .m-md-24 {
    margin: 7.5rem !important; }
  .m-md-25 {
    margin: 7.8125rem !important; }
  .m-md-26 {
    margin: 8.125rem !important; }
  .m-md-27 {
    margin: 8.4375rem !important; }
  .m-md-28 {
    margin: 8.75rem !important; }
  .m-md-29 {
    margin: 9.0625rem !important; }
  .m-md-30 {
    margin: 9.375rem !important; }
  .m-md-31 {
    margin: 9.6875rem !important; }
  .m-md-32 {
    margin: 10rem !important; }
  .m-md-33 {
    margin: 10.3125rem !important; }
  .m-md-34 {
    margin: 10.625rem !important; }
  .m-md-35 {
    margin: 10.9375rem !important; }
  .m-md-36 {
    margin: 11.25rem !important; }
  .m-md-37 {
    margin: 11.5625rem !important; }
  .m-md-38 {
    margin: 11.875rem !important; }
  .m-md-39 {
    margin: 12.1875rem !important; }
  .m-md-40 {
    margin: 12.5rem !important; }
  .m-md-base {
    margin: calc(20rem / 16) !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important; }
  .mx-md-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important; }
  .mx-md-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important; }
  .mx-md-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important; }
  .mx-md-5 {
    margin-right: 1.5625rem !important;
    margin-left: 1.5625rem !important; }
  .mx-md-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important; }
  .mx-md-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important; }
  .mx-md-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-md-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important; }
  .mx-md-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important; }
  .mx-md-11 {
    margin-right: 3.4375rem !important;
    margin-left: 3.4375rem !important; }
  .mx-md-12 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important; }
  .mx-md-13 {
    margin-right: 4.0625rem !important;
    margin-left: 4.0625rem !important; }
  .mx-md-14 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important; }
  .mx-md-15 {
    margin-right: 4.6875rem !important;
    margin-left: 4.6875rem !important; }
  .mx-md-16 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-md-17 {
    margin-right: 5.3125rem !important;
    margin-left: 5.3125rem !important; }
  .mx-md-18 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important; }
  .mx-md-19 {
    margin-right: 5.9375rem !important;
    margin-left: 5.9375rem !important; }
  .mx-md-20 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important; }
  .mx-md-21 {
    margin-right: 6.5625rem !important;
    margin-left: 6.5625rem !important; }
  .mx-md-22 {
    margin-right: 6.875rem !important;
    margin-left: 6.875rem !important; }
  .mx-md-23 {
    margin-right: 7.1875rem !important;
    margin-left: 7.1875rem !important; }
  .mx-md-24 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-md-25 {
    margin-right: 7.8125rem !important;
    margin-left: 7.8125rem !important; }
  .mx-md-26 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important; }
  .mx-md-27 {
    margin-right: 8.4375rem !important;
    margin-left: 8.4375rem !important; }
  .mx-md-28 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important; }
  .mx-md-29 {
    margin-right: 9.0625rem !important;
    margin-left: 9.0625rem !important; }
  .mx-md-30 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important; }
  .mx-md-31 {
    margin-right: 9.6875rem !important;
    margin-left: 9.6875rem !important; }
  .mx-md-32 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-md-33 {
    margin-right: 10.3125rem !important;
    margin-left: 10.3125rem !important; }
  .mx-md-34 {
    margin-right: 10.625rem !important;
    margin-left: 10.625rem !important; }
  .mx-md-35 {
    margin-right: 10.9375rem !important;
    margin-left: 10.9375rem !important; }
  .mx-md-36 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important; }
  .mx-md-37 {
    margin-right: 11.5625rem !important;
    margin-left: 11.5625rem !important; }
  .mx-md-38 {
    margin-right: 11.875rem !important;
    margin-left: 11.875rem !important; }
  .mx-md-39 {
    margin-right: 12.1875rem !important;
    margin-left: 12.1875rem !important; }
  .mx-md-40 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-md-base {
    margin-right: calc(20rem / 16) !important;
    margin-left: calc(20rem / 16) !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important; }
  .my-md-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important; }
  .my-md-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important; }
  .my-md-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .my-md-5 {
    margin-top: 1.5625rem !important;
    margin-bottom: 1.5625rem !important; }
  .my-md-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important; }
  .my-md-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important; }
  .my-md-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-md-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important; }
  .my-md-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important; }
  .my-md-11 {
    margin-top: 3.4375rem !important;
    margin-bottom: 3.4375rem !important; }
  .my-md-12 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important; }
  .my-md-13 {
    margin-top: 4.0625rem !important;
    margin-bottom: 4.0625rem !important; }
  .my-md-14 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important; }
  .my-md-15 {
    margin-top: 4.6875rem !important;
    margin-bottom: 4.6875rem !important; }
  .my-md-16 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-md-17 {
    margin-top: 5.3125rem !important;
    margin-bottom: 5.3125rem !important; }
  .my-md-18 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important; }
  .my-md-19 {
    margin-top: 5.9375rem !important;
    margin-bottom: 5.9375rem !important; }
  .my-md-20 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important; }
  .my-md-21 {
    margin-top: 6.5625rem !important;
    margin-bottom: 6.5625rem !important; }
  .my-md-22 {
    margin-top: 6.875rem !important;
    margin-bottom: 6.875rem !important; }
  .my-md-23 {
    margin-top: 7.1875rem !important;
    margin-bottom: 7.1875rem !important; }
  .my-md-24 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-md-25 {
    margin-top: 7.8125rem !important;
    margin-bottom: 7.8125rem !important; }
  .my-md-26 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important; }
  .my-md-27 {
    margin-top: 8.4375rem !important;
    margin-bottom: 8.4375rem !important; }
  .my-md-28 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important; }
  .my-md-29 {
    margin-top: 9.0625rem !important;
    margin-bottom: 9.0625rem !important; }
  .my-md-30 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important; }
  .my-md-31 {
    margin-top: 9.6875rem !important;
    margin-bottom: 9.6875rem !important; }
  .my-md-32 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-md-33 {
    margin-top: 10.3125rem !important;
    margin-bottom: 10.3125rem !important; }
  .my-md-34 {
    margin-top: 10.625rem !important;
    margin-bottom: 10.625rem !important; }
  .my-md-35 {
    margin-top: 10.9375rem !important;
    margin-bottom: 10.9375rem !important; }
  .my-md-36 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important; }
  .my-md-37 {
    margin-top: 11.5625rem !important;
    margin-bottom: 11.5625rem !important; }
  .my-md-38 {
    margin-top: 11.875rem !important;
    margin-bottom: 11.875rem !important; }
  .my-md-39 {
    margin-top: 12.1875rem !important;
    margin-bottom: 12.1875rem !important; }
  .my-md-40 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-md-base {
    margin-top: calc(20rem / 16) !important;
    margin-bottom: calc(20rem / 16) !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.3125rem !important; }
  .mt-md-2 {
    margin-top: 0.625rem !important; }
  .mt-md-3 {
    margin-top: 0.9375rem !important; }
  .mt-md-4 {
    margin-top: 1.25rem !important; }
  .mt-md-5 {
    margin-top: 1.5625rem !important; }
  .mt-md-6 {
    margin-top: 1.875rem !important; }
  .mt-md-7 {
    margin-top: 2.1875rem !important; }
  .mt-md-8 {
    margin-top: 2.5rem !important; }
  .mt-md-9 {
    margin-top: 2.8125rem !important; }
  .mt-md-10 {
    margin-top: 3.125rem !important; }
  .mt-md-11 {
    margin-top: 3.4375rem !important; }
  .mt-md-12 {
    margin-top: 3.75rem !important; }
  .mt-md-13 {
    margin-top: 4.0625rem !important; }
  .mt-md-14 {
    margin-top: 4.375rem !important; }
  .mt-md-15 {
    margin-top: 4.6875rem !important; }
  .mt-md-16 {
    margin-top: 5rem !important; }
  .mt-md-17 {
    margin-top: 5.3125rem !important; }
  .mt-md-18 {
    margin-top: 5.625rem !important; }
  .mt-md-19 {
    margin-top: 5.9375rem !important; }
  .mt-md-20 {
    margin-top: 6.25rem !important; }
  .mt-md-21 {
    margin-top: 6.5625rem !important; }
  .mt-md-22 {
    margin-top: 6.875rem !important; }
  .mt-md-23 {
    margin-top: 7.1875rem !important; }
  .mt-md-24 {
    margin-top: 7.5rem !important; }
  .mt-md-25 {
    margin-top: 7.8125rem !important; }
  .mt-md-26 {
    margin-top: 8.125rem !important; }
  .mt-md-27 {
    margin-top: 8.4375rem !important; }
  .mt-md-28 {
    margin-top: 8.75rem !important; }
  .mt-md-29 {
    margin-top: 9.0625rem !important; }
  .mt-md-30 {
    margin-top: 9.375rem !important; }
  .mt-md-31 {
    margin-top: 9.6875rem !important; }
  .mt-md-32 {
    margin-top: 10rem !important; }
  .mt-md-33 {
    margin-top: 10.3125rem !important; }
  .mt-md-34 {
    margin-top: 10.625rem !important; }
  .mt-md-35 {
    margin-top: 10.9375rem !important; }
  .mt-md-36 {
    margin-top: 11.25rem !important; }
  .mt-md-37 {
    margin-top: 11.5625rem !important; }
  .mt-md-38 {
    margin-top: 11.875rem !important; }
  .mt-md-39 {
    margin-top: 12.1875rem !important; }
  .mt-md-40 {
    margin-top: 12.5rem !important; }
  .mt-md-base {
    margin-top: calc(20rem / 16) !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.3125rem !important; }
  .me-md-2 {
    margin-right: 0.625rem !important; }
  .me-md-3 {
    margin-right: 0.9375rem !important; }
  .me-md-4 {
    margin-right: 1.25rem !important; }
  .me-md-5 {
    margin-right: 1.5625rem !important; }
  .me-md-6 {
    margin-right: 1.875rem !important; }
  .me-md-7 {
    margin-right: 2.1875rem !important; }
  .me-md-8 {
    margin-right: 2.5rem !important; }
  .me-md-9 {
    margin-right: 2.8125rem !important; }
  .me-md-10 {
    margin-right: 3.125rem !important; }
  .me-md-11 {
    margin-right: 3.4375rem !important; }
  .me-md-12 {
    margin-right: 3.75rem !important; }
  .me-md-13 {
    margin-right: 4.0625rem !important; }
  .me-md-14 {
    margin-right: 4.375rem !important; }
  .me-md-15 {
    margin-right: 4.6875rem !important; }
  .me-md-16 {
    margin-right: 5rem !important; }
  .me-md-17 {
    margin-right: 5.3125rem !important; }
  .me-md-18 {
    margin-right: 5.625rem !important; }
  .me-md-19 {
    margin-right: 5.9375rem !important; }
  .me-md-20 {
    margin-right: 6.25rem !important; }
  .me-md-21 {
    margin-right: 6.5625rem !important; }
  .me-md-22 {
    margin-right: 6.875rem !important; }
  .me-md-23 {
    margin-right: 7.1875rem !important; }
  .me-md-24 {
    margin-right: 7.5rem !important; }
  .me-md-25 {
    margin-right: 7.8125rem !important; }
  .me-md-26 {
    margin-right: 8.125rem !important; }
  .me-md-27 {
    margin-right: 8.4375rem !important; }
  .me-md-28 {
    margin-right: 8.75rem !important; }
  .me-md-29 {
    margin-right: 9.0625rem !important; }
  .me-md-30 {
    margin-right: 9.375rem !important; }
  .me-md-31 {
    margin-right: 9.6875rem !important; }
  .me-md-32 {
    margin-right: 10rem !important; }
  .me-md-33 {
    margin-right: 10.3125rem !important; }
  .me-md-34 {
    margin-right: 10.625rem !important; }
  .me-md-35 {
    margin-right: 10.9375rem !important; }
  .me-md-36 {
    margin-right: 11.25rem !important; }
  .me-md-37 {
    margin-right: 11.5625rem !important; }
  .me-md-38 {
    margin-right: 11.875rem !important; }
  .me-md-39 {
    margin-right: 12.1875rem !important; }
  .me-md-40 {
    margin-right: 12.5rem !important; }
  .me-md-base {
    margin-right: calc(20rem / 16) !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.3125rem !important; }
  .mb-md-2 {
    margin-bottom: 0.625rem !important; }
  .mb-md-3 {
    margin-bottom: 0.9375rem !important; }
  .mb-md-4 {
    margin-bottom: 1.25rem !important; }
  .mb-md-5 {
    margin-bottom: 1.5625rem !important; }
  .mb-md-6 {
    margin-bottom: 1.875rem !important; }
  .mb-md-7 {
    margin-bottom: 2.1875rem !important; }
  .mb-md-8 {
    margin-bottom: 2.5rem !important; }
  .mb-md-9 {
    margin-bottom: 2.8125rem !important; }
  .mb-md-10 {
    margin-bottom: 3.125rem !important; }
  .mb-md-11 {
    margin-bottom: 3.4375rem !important; }
  .mb-md-12 {
    margin-bottom: 3.75rem !important; }
  .mb-md-13 {
    margin-bottom: 4.0625rem !important; }
  .mb-md-14 {
    margin-bottom: 4.375rem !important; }
  .mb-md-15 {
    margin-bottom: 4.6875rem !important; }
  .mb-md-16 {
    margin-bottom: 5rem !important; }
  .mb-md-17 {
    margin-bottom: 5.3125rem !important; }
  .mb-md-18 {
    margin-bottom: 5.625rem !important; }
  .mb-md-19 {
    margin-bottom: 5.9375rem !important; }
  .mb-md-20 {
    margin-bottom: 6.25rem !important; }
  .mb-md-21 {
    margin-bottom: 6.5625rem !important; }
  .mb-md-22 {
    margin-bottom: 6.875rem !important; }
  .mb-md-23 {
    margin-bottom: 7.1875rem !important; }
  .mb-md-24 {
    margin-bottom: 7.5rem !important; }
  .mb-md-25 {
    margin-bottom: 7.8125rem !important; }
  .mb-md-26 {
    margin-bottom: 8.125rem !important; }
  .mb-md-27 {
    margin-bottom: 8.4375rem !important; }
  .mb-md-28 {
    margin-bottom: 8.75rem !important; }
  .mb-md-29 {
    margin-bottom: 9.0625rem !important; }
  .mb-md-30 {
    margin-bottom: 9.375rem !important; }
  .mb-md-31 {
    margin-bottom: 9.6875rem !important; }
  .mb-md-32 {
    margin-bottom: 10rem !important; }
  .mb-md-33 {
    margin-bottom: 10.3125rem !important; }
  .mb-md-34 {
    margin-bottom: 10.625rem !important; }
  .mb-md-35 {
    margin-bottom: 10.9375rem !important; }
  .mb-md-36 {
    margin-bottom: 11.25rem !important; }
  .mb-md-37 {
    margin-bottom: 11.5625rem !important; }
  .mb-md-38 {
    margin-bottom: 11.875rem !important; }
  .mb-md-39 {
    margin-bottom: 12.1875rem !important; }
  .mb-md-40 {
    margin-bottom: 12.5rem !important; }
  .mb-md-base {
    margin-bottom: calc(20rem / 16) !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.3125rem !important; }
  .ms-md-2 {
    margin-left: 0.625rem !important; }
  .ms-md-3 {
    margin-left: 0.9375rem !important; }
  .ms-md-4 {
    margin-left: 1.25rem !important; }
  .ms-md-5 {
    margin-left: 1.5625rem !important; }
  .ms-md-6 {
    margin-left: 1.875rem !important; }
  .ms-md-7 {
    margin-left: 2.1875rem !important; }
  .ms-md-8 {
    margin-left: 2.5rem !important; }
  .ms-md-9 {
    margin-left: 2.8125rem !important; }
  .ms-md-10 {
    margin-left: 3.125rem !important; }
  .ms-md-11 {
    margin-left: 3.4375rem !important; }
  .ms-md-12 {
    margin-left: 3.75rem !important; }
  .ms-md-13 {
    margin-left: 4.0625rem !important; }
  .ms-md-14 {
    margin-left: 4.375rem !important; }
  .ms-md-15 {
    margin-left: 4.6875rem !important; }
  .ms-md-16 {
    margin-left: 5rem !important; }
  .ms-md-17 {
    margin-left: 5.3125rem !important; }
  .ms-md-18 {
    margin-left: 5.625rem !important; }
  .ms-md-19 {
    margin-left: 5.9375rem !important; }
  .ms-md-20 {
    margin-left: 6.25rem !important; }
  .ms-md-21 {
    margin-left: 6.5625rem !important; }
  .ms-md-22 {
    margin-left: 6.875rem !important; }
  .ms-md-23 {
    margin-left: 7.1875rem !important; }
  .ms-md-24 {
    margin-left: 7.5rem !important; }
  .ms-md-25 {
    margin-left: 7.8125rem !important; }
  .ms-md-26 {
    margin-left: 8.125rem !important; }
  .ms-md-27 {
    margin-left: 8.4375rem !important; }
  .ms-md-28 {
    margin-left: 8.75rem !important; }
  .ms-md-29 {
    margin-left: 9.0625rem !important; }
  .ms-md-30 {
    margin-left: 9.375rem !important; }
  .ms-md-31 {
    margin-left: 9.6875rem !important; }
  .ms-md-32 {
    margin-left: 10rem !important; }
  .ms-md-33 {
    margin-left: 10.3125rem !important; }
  .ms-md-34 {
    margin-left: 10.625rem !important; }
  .ms-md-35 {
    margin-left: 10.9375rem !important; }
  .ms-md-36 {
    margin-left: 11.25rem !important; }
  .ms-md-37 {
    margin-left: 11.5625rem !important; }
  .ms-md-38 {
    margin-left: 11.875rem !important; }
  .ms-md-39 {
    margin-left: 12.1875rem !important; }
  .ms-md-40 {
    margin-left: 12.5rem !important; }
  .ms-md-base {
    margin-left: calc(20rem / 16) !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .m-md-n1 {
    margin: -0.3125rem !important; }
  .m-md-n2 {
    margin: -0.625rem !important; }
  .m-md-n3 {
    margin: -0.9375rem !important; }
  .m-md-n4 {
    margin: -1.25rem !important; }
  .m-md-n5 {
    margin: -1.5625rem !important; }
  .m-md-n6 {
    margin: -1.875rem !important; }
  .m-md-n7 {
    margin: -2.1875rem !important; }
  .m-md-n8 {
    margin: -2.5rem !important; }
  .m-md-n9 {
    margin: -2.8125rem !important; }
  .m-md-n10 {
    margin: -3.125rem !important; }
  .m-md-n11 {
    margin: -3.4375rem !important; }
  .m-md-n12 {
    margin: -3.75rem !important; }
  .m-md-n13 {
    margin: -4.0625rem !important; }
  .m-md-n14 {
    margin: -4.375rem !important; }
  .m-md-n15 {
    margin: -4.6875rem !important; }
  .m-md-n16 {
    margin: -5rem !important; }
  .m-md-n17 {
    margin: -5.3125rem !important; }
  .m-md-n18 {
    margin: -5.625rem !important; }
  .m-md-n19 {
    margin: -5.9375rem !important; }
  .m-md-n20 {
    margin: -6.25rem !important; }
  .m-md-n21 {
    margin: -6.5625rem !important; }
  .m-md-n22 {
    margin: -6.875rem !important; }
  .m-md-n23 {
    margin: -7.1875rem !important; }
  .m-md-n24 {
    margin: -7.5rem !important; }
  .m-md-n25 {
    margin: -7.8125rem !important; }
  .m-md-n26 {
    margin: -8.125rem !important; }
  .m-md-n27 {
    margin: -8.4375rem !important; }
  .m-md-n28 {
    margin: -8.75rem !important; }
  .m-md-n29 {
    margin: -9.0625rem !important; }
  .m-md-n30 {
    margin: -9.375rem !important; }
  .m-md-n31 {
    margin: -9.6875rem !important; }
  .m-md-n32 {
    margin: -10rem !important; }
  .m-md-n33 {
    margin: -10.3125rem !important; }
  .m-md-n34 {
    margin: -10.625rem !important; }
  .m-md-n35 {
    margin: -10.9375rem !important; }
  .m-md-n36 {
    margin: -11.25rem !important; }
  .m-md-n37 {
    margin: -11.5625rem !important; }
  .m-md-n38 {
    margin: -11.875rem !important; }
  .m-md-n39 {
    margin: -12.1875rem !important; }
  .m-md-n40 {
    margin: -12.5rem !important; }
  .m-md-nbase {
    margin: -calc(20rem / 16) !important; }
  .mx-md-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important; }
  .mx-md-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important; }
  .mx-md-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important; }
  .mx-md-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important; }
  .mx-md-n5 {
    margin-right: -1.5625rem !important;
    margin-left: -1.5625rem !important; }
  .mx-md-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important; }
  .mx-md-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important; }
  .mx-md-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-md-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important; }
  .mx-md-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important; }
  .mx-md-n11 {
    margin-right: -3.4375rem !important;
    margin-left: -3.4375rem !important; }
  .mx-md-n12 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important; }
  .mx-md-n13 {
    margin-right: -4.0625rem !important;
    margin-left: -4.0625rem !important; }
  .mx-md-n14 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important; }
  .mx-md-n15 {
    margin-right: -4.6875rem !important;
    margin-left: -4.6875rem !important; }
  .mx-md-n16 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-md-n17 {
    margin-right: -5.3125rem !important;
    margin-left: -5.3125rem !important; }
  .mx-md-n18 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important; }
  .mx-md-n19 {
    margin-right: -5.9375rem !important;
    margin-left: -5.9375rem !important; }
  .mx-md-n20 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important; }
  .mx-md-n21 {
    margin-right: -6.5625rem !important;
    margin-left: -6.5625rem !important; }
  .mx-md-n22 {
    margin-right: -6.875rem !important;
    margin-left: -6.875rem !important; }
  .mx-md-n23 {
    margin-right: -7.1875rem !important;
    margin-left: -7.1875rem !important; }
  .mx-md-n24 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-md-n25 {
    margin-right: -7.8125rem !important;
    margin-left: -7.8125rem !important; }
  .mx-md-n26 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important; }
  .mx-md-n27 {
    margin-right: -8.4375rem !important;
    margin-left: -8.4375rem !important; }
  .mx-md-n28 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important; }
  .mx-md-n29 {
    margin-right: -9.0625rem !important;
    margin-left: -9.0625rem !important; }
  .mx-md-n30 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important; }
  .mx-md-n31 {
    margin-right: -9.6875rem !important;
    margin-left: -9.6875rem !important; }
  .mx-md-n32 {
    margin-right: -10rem !important;
    margin-left: -10rem !important; }
  .mx-md-n33 {
    margin-right: -10.3125rem !important;
    margin-left: -10.3125rem !important; }
  .mx-md-n34 {
    margin-right: -10.625rem !important;
    margin-left: -10.625rem !important; }
  .mx-md-n35 {
    margin-right: -10.9375rem !important;
    margin-left: -10.9375rem !important; }
  .mx-md-n36 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important; }
  .mx-md-n37 {
    margin-right: -11.5625rem !important;
    margin-left: -11.5625rem !important; }
  .mx-md-n38 {
    margin-right: -11.875rem !important;
    margin-left: -11.875rem !important; }
  .mx-md-n39 {
    margin-right: -12.1875rem !important;
    margin-left: -12.1875rem !important; }
  .mx-md-n40 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important; }
  .mx-md-nbase {
    margin-right: -calc(20rem / 16) !important;
    margin-left: -calc(20rem / 16) !important; }
  .my-md-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important; }
  .my-md-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important; }
  .my-md-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important; }
  .my-md-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .my-md-n5 {
    margin-top: -1.5625rem !important;
    margin-bottom: -1.5625rem !important; }
  .my-md-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important; }
  .my-md-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important; }
  .my-md-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-md-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important; }
  .my-md-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important; }
  .my-md-n11 {
    margin-top: -3.4375rem !important;
    margin-bottom: -3.4375rem !important; }
  .my-md-n12 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important; }
  .my-md-n13 {
    margin-top: -4.0625rem !important;
    margin-bottom: -4.0625rem !important; }
  .my-md-n14 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important; }
  .my-md-n15 {
    margin-top: -4.6875rem !important;
    margin-bottom: -4.6875rem !important; }
  .my-md-n16 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-md-n17 {
    margin-top: -5.3125rem !important;
    margin-bottom: -5.3125rem !important; }
  .my-md-n18 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important; }
  .my-md-n19 {
    margin-top: -5.9375rem !important;
    margin-bottom: -5.9375rem !important; }
  .my-md-n20 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important; }
  .my-md-n21 {
    margin-top: -6.5625rem !important;
    margin-bottom: -6.5625rem !important; }
  .my-md-n22 {
    margin-top: -6.875rem !important;
    margin-bottom: -6.875rem !important; }
  .my-md-n23 {
    margin-top: -7.1875rem !important;
    margin-bottom: -7.1875rem !important; }
  .my-md-n24 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-md-n25 {
    margin-top: -7.8125rem !important;
    margin-bottom: -7.8125rem !important; }
  .my-md-n26 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important; }
  .my-md-n27 {
    margin-top: -8.4375rem !important;
    margin-bottom: -8.4375rem !important; }
  .my-md-n28 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important; }
  .my-md-n29 {
    margin-top: -9.0625rem !important;
    margin-bottom: -9.0625rem !important; }
  .my-md-n30 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important; }
  .my-md-n31 {
    margin-top: -9.6875rem !important;
    margin-bottom: -9.6875rem !important; }
  .my-md-n32 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .my-md-n33 {
    margin-top: -10.3125rem !important;
    margin-bottom: -10.3125rem !important; }
  .my-md-n34 {
    margin-top: -10.625rem !important;
    margin-bottom: -10.625rem !important; }
  .my-md-n35 {
    margin-top: -10.9375rem !important;
    margin-bottom: -10.9375rem !important; }
  .my-md-n36 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important; }
  .my-md-n37 {
    margin-top: -11.5625rem !important;
    margin-bottom: -11.5625rem !important; }
  .my-md-n38 {
    margin-top: -11.875rem !important;
    margin-bottom: -11.875rem !important; }
  .my-md-n39 {
    margin-top: -12.1875rem !important;
    margin-bottom: -12.1875rem !important; }
  .my-md-n40 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important; }
  .my-md-nbase {
    margin-top: -calc(20rem / 16) !important;
    margin-bottom: -calc(20rem / 16) !important; }
  .mt-md-n1 {
    margin-top: -0.3125rem !important; }
  .mt-md-n2 {
    margin-top: -0.625rem !important; }
  .mt-md-n3 {
    margin-top: -0.9375rem !important; }
  .mt-md-n4 {
    margin-top: -1.25rem !important; }
  .mt-md-n5 {
    margin-top: -1.5625rem !important; }
  .mt-md-n6 {
    margin-top: -1.875rem !important; }
  .mt-md-n7 {
    margin-top: -2.1875rem !important; }
  .mt-md-n8 {
    margin-top: -2.5rem !important; }
  .mt-md-n9 {
    margin-top: -2.8125rem !important; }
  .mt-md-n10 {
    margin-top: -3.125rem !important; }
  .mt-md-n11 {
    margin-top: -3.4375rem !important; }
  .mt-md-n12 {
    margin-top: -3.75rem !important; }
  .mt-md-n13 {
    margin-top: -4.0625rem !important; }
  .mt-md-n14 {
    margin-top: -4.375rem !important; }
  .mt-md-n15 {
    margin-top: -4.6875rem !important; }
  .mt-md-n16 {
    margin-top: -5rem !important; }
  .mt-md-n17 {
    margin-top: -5.3125rem !important; }
  .mt-md-n18 {
    margin-top: -5.625rem !important; }
  .mt-md-n19 {
    margin-top: -5.9375rem !important; }
  .mt-md-n20 {
    margin-top: -6.25rem !important; }
  .mt-md-n21 {
    margin-top: -6.5625rem !important; }
  .mt-md-n22 {
    margin-top: -6.875rem !important; }
  .mt-md-n23 {
    margin-top: -7.1875rem !important; }
  .mt-md-n24 {
    margin-top: -7.5rem !important; }
  .mt-md-n25 {
    margin-top: -7.8125rem !important; }
  .mt-md-n26 {
    margin-top: -8.125rem !important; }
  .mt-md-n27 {
    margin-top: -8.4375rem !important; }
  .mt-md-n28 {
    margin-top: -8.75rem !important; }
  .mt-md-n29 {
    margin-top: -9.0625rem !important; }
  .mt-md-n30 {
    margin-top: -9.375rem !important; }
  .mt-md-n31 {
    margin-top: -9.6875rem !important; }
  .mt-md-n32 {
    margin-top: -10rem !important; }
  .mt-md-n33 {
    margin-top: -10.3125rem !important; }
  .mt-md-n34 {
    margin-top: -10.625rem !important; }
  .mt-md-n35 {
    margin-top: -10.9375rem !important; }
  .mt-md-n36 {
    margin-top: -11.25rem !important; }
  .mt-md-n37 {
    margin-top: -11.5625rem !important; }
  .mt-md-n38 {
    margin-top: -11.875rem !important; }
  .mt-md-n39 {
    margin-top: -12.1875rem !important; }
  .mt-md-n40 {
    margin-top: -12.5rem !important; }
  .mt-md-nbase {
    margin-top: -calc(20rem / 16) !important; }
  .me-md-n1 {
    margin-right: -0.3125rem !important; }
  .me-md-n2 {
    margin-right: -0.625rem !important; }
  .me-md-n3 {
    margin-right: -0.9375rem !important; }
  .me-md-n4 {
    margin-right: -1.25rem !important; }
  .me-md-n5 {
    margin-right: -1.5625rem !important; }
  .me-md-n6 {
    margin-right: -1.875rem !important; }
  .me-md-n7 {
    margin-right: -2.1875rem !important; }
  .me-md-n8 {
    margin-right: -2.5rem !important; }
  .me-md-n9 {
    margin-right: -2.8125rem !important; }
  .me-md-n10 {
    margin-right: -3.125rem !important; }
  .me-md-n11 {
    margin-right: -3.4375rem !important; }
  .me-md-n12 {
    margin-right: -3.75rem !important; }
  .me-md-n13 {
    margin-right: -4.0625rem !important; }
  .me-md-n14 {
    margin-right: -4.375rem !important; }
  .me-md-n15 {
    margin-right: -4.6875rem !important; }
  .me-md-n16 {
    margin-right: -5rem !important; }
  .me-md-n17 {
    margin-right: -5.3125rem !important; }
  .me-md-n18 {
    margin-right: -5.625rem !important; }
  .me-md-n19 {
    margin-right: -5.9375rem !important; }
  .me-md-n20 {
    margin-right: -6.25rem !important; }
  .me-md-n21 {
    margin-right: -6.5625rem !important; }
  .me-md-n22 {
    margin-right: -6.875rem !important; }
  .me-md-n23 {
    margin-right: -7.1875rem !important; }
  .me-md-n24 {
    margin-right: -7.5rem !important; }
  .me-md-n25 {
    margin-right: -7.8125rem !important; }
  .me-md-n26 {
    margin-right: -8.125rem !important; }
  .me-md-n27 {
    margin-right: -8.4375rem !important; }
  .me-md-n28 {
    margin-right: -8.75rem !important; }
  .me-md-n29 {
    margin-right: -9.0625rem !important; }
  .me-md-n30 {
    margin-right: -9.375rem !important; }
  .me-md-n31 {
    margin-right: -9.6875rem !important; }
  .me-md-n32 {
    margin-right: -10rem !important; }
  .me-md-n33 {
    margin-right: -10.3125rem !important; }
  .me-md-n34 {
    margin-right: -10.625rem !important; }
  .me-md-n35 {
    margin-right: -10.9375rem !important; }
  .me-md-n36 {
    margin-right: -11.25rem !important; }
  .me-md-n37 {
    margin-right: -11.5625rem !important; }
  .me-md-n38 {
    margin-right: -11.875rem !important; }
  .me-md-n39 {
    margin-right: -12.1875rem !important; }
  .me-md-n40 {
    margin-right: -12.5rem !important; }
  .me-md-nbase {
    margin-right: -calc(20rem / 16) !important; }
  .mb-md-n1 {
    margin-bottom: -0.3125rem !important; }
  .mb-md-n2 {
    margin-bottom: -0.625rem !important; }
  .mb-md-n3 {
    margin-bottom: -0.9375rem !important; }
  .mb-md-n4 {
    margin-bottom: -1.25rem !important; }
  .mb-md-n5 {
    margin-bottom: -1.5625rem !important; }
  .mb-md-n6 {
    margin-bottom: -1.875rem !important; }
  .mb-md-n7 {
    margin-bottom: -2.1875rem !important; }
  .mb-md-n8 {
    margin-bottom: -2.5rem !important; }
  .mb-md-n9 {
    margin-bottom: -2.8125rem !important; }
  .mb-md-n10 {
    margin-bottom: -3.125rem !important; }
  .mb-md-n11 {
    margin-bottom: -3.4375rem !important; }
  .mb-md-n12 {
    margin-bottom: -3.75rem !important; }
  .mb-md-n13 {
    margin-bottom: -4.0625rem !important; }
  .mb-md-n14 {
    margin-bottom: -4.375rem !important; }
  .mb-md-n15 {
    margin-bottom: -4.6875rem !important; }
  .mb-md-n16 {
    margin-bottom: -5rem !important; }
  .mb-md-n17 {
    margin-bottom: -5.3125rem !important; }
  .mb-md-n18 {
    margin-bottom: -5.625rem !important; }
  .mb-md-n19 {
    margin-bottom: -5.9375rem !important; }
  .mb-md-n20 {
    margin-bottom: -6.25rem !important; }
  .mb-md-n21 {
    margin-bottom: -6.5625rem !important; }
  .mb-md-n22 {
    margin-bottom: -6.875rem !important; }
  .mb-md-n23 {
    margin-bottom: -7.1875rem !important; }
  .mb-md-n24 {
    margin-bottom: -7.5rem !important; }
  .mb-md-n25 {
    margin-bottom: -7.8125rem !important; }
  .mb-md-n26 {
    margin-bottom: -8.125rem !important; }
  .mb-md-n27 {
    margin-bottom: -8.4375rem !important; }
  .mb-md-n28 {
    margin-bottom: -8.75rem !important; }
  .mb-md-n29 {
    margin-bottom: -9.0625rem !important; }
  .mb-md-n30 {
    margin-bottom: -9.375rem !important; }
  .mb-md-n31 {
    margin-bottom: -9.6875rem !important; }
  .mb-md-n32 {
    margin-bottom: -10rem !important; }
  .mb-md-n33 {
    margin-bottom: -10.3125rem !important; }
  .mb-md-n34 {
    margin-bottom: -10.625rem !important; }
  .mb-md-n35 {
    margin-bottom: -10.9375rem !important; }
  .mb-md-n36 {
    margin-bottom: -11.25rem !important; }
  .mb-md-n37 {
    margin-bottom: -11.5625rem !important; }
  .mb-md-n38 {
    margin-bottom: -11.875rem !important; }
  .mb-md-n39 {
    margin-bottom: -12.1875rem !important; }
  .mb-md-n40 {
    margin-bottom: -12.5rem !important; }
  .mb-md-nbase {
    margin-bottom: -calc(20rem / 16) !important; }
  .ms-md-n1 {
    margin-left: -0.3125rem !important; }
  .ms-md-n2 {
    margin-left: -0.625rem !important; }
  .ms-md-n3 {
    margin-left: -0.9375rem !important; }
  .ms-md-n4 {
    margin-left: -1.25rem !important; }
  .ms-md-n5 {
    margin-left: -1.5625rem !important; }
  .ms-md-n6 {
    margin-left: -1.875rem !important; }
  .ms-md-n7 {
    margin-left: -2.1875rem !important; }
  .ms-md-n8 {
    margin-left: -2.5rem !important; }
  .ms-md-n9 {
    margin-left: -2.8125rem !important; }
  .ms-md-n10 {
    margin-left: -3.125rem !important; }
  .ms-md-n11 {
    margin-left: -3.4375rem !important; }
  .ms-md-n12 {
    margin-left: -3.75rem !important; }
  .ms-md-n13 {
    margin-left: -4.0625rem !important; }
  .ms-md-n14 {
    margin-left: -4.375rem !important; }
  .ms-md-n15 {
    margin-left: -4.6875rem !important; }
  .ms-md-n16 {
    margin-left: -5rem !important; }
  .ms-md-n17 {
    margin-left: -5.3125rem !important; }
  .ms-md-n18 {
    margin-left: -5.625rem !important; }
  .ms-md-n19 {
    margin-left: -5.9375rem !important; }
  .ms-md-n20 {
    margin-left: -6.25rem !important; }
  .ms-md-n21 {
    margin-left: -6.5625rem !important; }
  .ms-md-n22 {
    margin-left: -6.875rem !important; }
  .ms-md-n23 {
    margin-left: -7.1875rem !important; }
  .ms-md-n24 {
    margin-left: -7.5rem !important; }
  .ms-md-n25 {
    margin-left: -7.8125rem !important; }
  .ms-md-n26 {
    margin-left: -8.125rem !important; }
  .ms-md-n27 {
    margin-left: -8.4375rem !important; }
  .ms-md-n28 {
    margin-left: -8.75rem !important; }
  .ms-md-n29 {
    margin-left: -9.0625rem !important; }
  .ms-md-n30 {
    margin-left: -9.375rem !important; }
  .ms-md-n31 {
    margin-left: -9.6875rem !important; }
  .ms-md-n32 {
    margin-left: -10rem !important; }
  .ms-md-n33 {
    margin-left: -10.3125rem !important; }
  .ms-md-n34 {
    margin-left: -10.625rem !important; }
  .ms-md-n35 {
    margin-left: -10.9375rem !important; }
  .ms-md-n36 {
    margin-left: -11.25rem !important; }
  .ms-md-n37 {
    margin-left: -11.5625rem !important; }
  .ms-md-n38 {
    margin-left: -11.875rem !important; }
  .ms-md-n39 {
    margin-left: -12.1875rem !important; }
  .ms-md-n40 {
    margin-left: -12.5rem !important; }
  .ms-md-nbase {
    margin-left: -calc(20rem / 16) !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.3125rem !important; }
  .p-md-2 {
    padding: 0.625rem !important; }
  .p-md-3 {
    padding: 0.9375rem !important; }
  .p-md-4 {
    padding: 1.25rem !important; }
  .p-md-5 {
    padding: 1.5625rem !important; }
  .p-md-6 {
    padding: 1.875rem !important; }
  .p-md-7 {
    padding: 2.1875rem !important; }
  .p-md-8 {
    padding: 2.5rem !important; }
  .p-md-9 {
    padding: 2.8125rem !important; }
  .p-md-10 {
    padding: 3.125rem !important; }
  .p-md-11 {
    padding: 3.4375rem !important; }
  .p-md-12 {
    padding: 3.75rem !important; }
  .p-md-13 {
    padding: 4.0625rem !important; }
  .p-md-14 {
    padding: 4.375rem !important; }
  .p-md-15 {
    padding: 4.6875rem !important; }
  .p-md-16 {
    padding: 5rem !important; }
  .p-md-17 {
    padding: 5.3125rem !important; }
  .p-md-18 {
    padding: 5.625rem !important; }
  .p-md-19 {
    padding: 5.9375rem !important; }
  .p-md-20 {
    padding: 6.25rem !important; }
  .p-md-21 {
    padding: 6.5625rem !important; }
  .p-md-22 {
    padding: 6.875rem !important; }
  .p-md-23 {
    padding: 7.1875rem !important; }
  .p-md-24 {
    padding: 7.5rem !important; }
  .p-md-25 {
    padding: 7.8125rem !important; }
  .p-md-26 {
    padding: 8.125rem !important; }
  .p-md-27 {
    padding: 8.4375rem !important; }
  .p-md-28 {
    padding: 8.75rem !important; }
  .p-md-29 {
    padding: 9.0625rem !important; }
  .p-md-30 {
    padding: 9.375rem !important; }
  .p-md-31 {
    padding: 9.6875rem !important; }
  .p-md-32 {
    padding: 10rem !important; }
  .p-md-33 {
    padding: 10.3125rem !important; }
  .p-md-34 {
    padding: 10.625rem !important; }
  .p-md-35 {
    padding: 10.9375rem !important; }
  .p-md-36 {
    padding: 11.25rem !important; }
  .p-md-37 {
    padding: 11.5625rem !important; }
  .p-md-38 {
    padding: 11.875rem !important; }
  .p-md-39 {
    padding: 12.1875rem !important; }
  .p-md-40 {
    padding: 12.5rem !important; }
  .p-md-base {
    padding: calc(20rem / 16) !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important; }
  .px-md-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important; }
  .px-md-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important; }
  .px-md-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important; }
  .px-md-5 {
    padding-right: 1.5625rem !important;
    padding-left: 1.5625rem !important; }
  .px-md-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important; }
  .px-md-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important; }
  .px-md-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-md-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important; }
  .px-md-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important; }
  .px-md-11 {
    padding-right: 3.4375rem !important;
    padding-left: 3.4375rem !important; }
  .px-md-12 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important; }
  .px-md-13 {
    padding-right: 4.0625rem !important;
    padding-left: 4.0625rem !important; }
  .px-md-14 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important; }
  .px-md-15 {
    padding-right: 4.6875rem !important;
    padding-left: 4.6875rem !important; }
  .px-md-16 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-md-17 {
    padding-right: 5.3125rem !important;
    padding-left: 5.3125rem !important; }
  .px-md-18 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important; }
  .px-md-19 {
    padding-right: 5.9375rem !important;
    padding-left: 5.9375rem !important; }
  .px-md-20 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important; }
  .px-md-21 {
    padding-right: 6.5625rem !important;
    padding-left: 6.5625rem !important; }
  .px-md-22 {
    padding-right: 6.875rem !important;
    padding-left: 6.875rem !important; }
  .px-md-23 {
    padding-right: 7.1875rem !important;
    padding-left: 7.1875rem !important; }
  .px-md-24 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-md-25 {
    padding-right: 7.8125rem !important;
    padding-left: 7.8125rem !important; }
  .px-md-26 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important; }
  .px-md-27 {
    padding-right: 8.4375rem !important;
    padding-left: 8.4375rem !important; }
  .px-md-28 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important; }
  .px-md-29 {
    padding-right: 9.0625rem !important;
    padding-left: 9.0625rem !important; }
  .px-md-30 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important; }
  .px-md-31 {
    padding-right: 9.6875rem !important;
    padding-left: 9.6875rem !important; }
  .px-md-32 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-md-33 {
    padding-right: 10.3125rem !important;
    padding-left: 10.3125rem !important; }
  .px-md-34 {
    padding-right: 10.625rem !important;
    padding-left: 10.625rem !important; }
  .px-md-35 {
    padding-right: 10.9375rem !important;
    padding-left: 10.9375rem !important; }
  .px-md-36 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important; }
  .px-md-37 {
    padding-right: 11.5625rem !important;
    padding-left: 11.5625rem !important; }
  .px-md-38 {
    padding-right: 11.875rem !important;
    padding-left: 11.875rem !important; }
  .px-md-39 {
    padding-right: 12.1875rem !important;
    padding-left: 12.1875rem !important; }
  .px-md-40 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-md-base {
    padding-right: calc(20rem / 16) !important;
    padding-left: calc(20rem / 16) !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important; }
  .py-md-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important; }
  .py-md-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important; }
  .py-md-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .py-md-5 {
    padding-top: 1.5625rem !important;
    padding-bottom: 1.5625rem !important; }
  .py-md-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important; }
  .py-md-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important; }
  .py-md-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-md-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important; }
  .py-md-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important; }
  .py-md-11 {
    padding-top: 3.4375rem !important;
    padding-bottom: 3.4375rem !important; }
  .py-md-12 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important; }
  .py-md-13 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important; }
  .py-md-14 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important; }
  .py-md-15 {
    padding-top: 4.6875rem !important;
    padding-bottom: 4.6875rem !important; }
  .py-md-16 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-md-17 {
    padding-top: 5.3125rem !important;
    padding-bottom: 5.3125rem !important; }
  .py-md-18 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important; }
  .py-md-19 {
    padding-top: 5.9375rem !important;
    padding-bottom: 5.9375rem !important; }
  .py-md-20 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important; }
  .py-md-21 {
    padding-top: 6.5625rem !important;
    padding-bottom: 6.5625rem !important; }
  .py-md-22 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important; }
  .py-md-23 {
    padding-top: 7.1875rem !important;
    padding-bottom: 7.1875rem !important; }
  .py-md-24 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-md-25 {
    padding-top: 7.8125rem !important;
    padding-bottom: 7.8125rem !important; }
  .py-md-26 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important; }
  .py-md-27 {
    padding-top: 8.4375rem !important;
    padding-bottom: 8.4375rem !important; }
  .py-md-28 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important; }
  .py-md-29 {
    padding-top: 9.0625rem !important;
    padding-bottom: 9.0625rem !important; }
  .py-md-30 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important; }
  .py-md-31 {
    padding-top: 9.6875rem !important;
    padding-bottom: 9.6875rem !important; }
  .py-md-32 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-md-33 {
    padding-top: 10.3125rem !important;
    padding-bottom: 10.3125rem !important; }
  .py-md-34 {
    padding-top: 10.625rem !important;
    padding-bottom: 10.625rem !important; }
  .py-md-35 {
    padding-top: 10.9375rem !important;
    padding-bottom: 10.9375rem !important; }
  .py-md-36 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important; }
  .py-md-37 {
    padding-top: 11.5625rem !important;
    padding-bottom: 11.5625rem !important; }
  .py-md-38 {
    padding-top: 11.875rem !important;
    padding-bottom: 11.875rem !important; }
  .py-md-39 {
    padding-top: 12.1875rem !important;
    padding-bottom: 12.1875rem !important; }
  .py-md-40 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-md-base {
    padding-top: calc(20rem / 16) !important;
    padding-bottom: calc(20rem / 16) !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.3125rem !important; }
  .pt-md-2 {
    padding-top: 0.625rem !important; }
  .pt-md-3 {
    padding-top: 0.9375rem !important; }
  .pt-md-4 {
    padding-top: 1.25rem !important; }
  .pt-md-5 {
    padding-top: 1.5625rem !important; }
  .pt-md-6 {
    padding-top: 1.875rem !important; }
  .pt-md-7 {
    padding-top: 2.1875rem !important; }
  .pt-md-8 {
    padding-top: 2.5rem !important; }
  .pt-md-9 {
    padding-top: 2.8125rem !important; }
  .pt-md-10 {
    padding-top: 3.125rem !important; }
  .pt-md-11 {
    padding-top: 3.4375rem !important; }
  .pt-md-12 {
    padding-top: 3.75rem !important; }
  .pt-md-13 {
    padding-top: 4.0625rem !important; }
  .pt-md-14 {
    padding-top: 4.375rem !important; }
  .pt-md-15 {
    padding-top: 4.6875rem !important; }
  .pt-md-16 {
    padding-top: 5rem !important; }
  .pt-md-17 {
    padding-top: 5.3125rem !important; }
  .pt-md-18 {
    padding-top: 5.625rem !important; }
  .pt-md-19 {
    padding-top: 5.9375rem !important; }
  .pt-md-20 {
    padding-top: 6.25rem !important; }
  .pt-md-21 {
    padding-top: 6.5625rem !important; }
  .pt-md-22 {
    padding-top: 6.875rem !important; }
  .pt-md-23 {
    padding-top: 7.1875rem !important; }
  .pt-md-24 {
    padding-top: 7.5rem !important; }
  .pt-md-25 {
    padding-top: 7.8125rem !important; }
  .pt-md-26 {
    padding-top: 8.125rem !important; }
  .pt-md-27 {
    padding-top: 8.4375rem !important; }
  .pt-md-28 {
    padding-top: 8.75rem !important; }
  .pt-md-29 {
    padding-top: 9.0625rem !important; }
  .pt-md-30 {
    padding-top: 9.375rem !important; }
  .pt-md-31 {
    padding-top: 9.6875rem !important; }
  .pt-md-32 {
    padding-top: 10rem !important; }
  .pt-md-33 {
    padding-top: 10.3125rem !important; }
  .pt-md-34 {
    padding-top: 10.625rem !important; }
  .pt-md-35 {
    padding-top: 10.9375rem !important; }
  .pt-md-36 {
    padding-top: 11.25rem !important; }
  .pt-md-37 {
    padding-top: 11.5625rem !important; }
  .pt-md-38 {
    padding-top: 11.875rem !important; }
  .pt-md-39 {
    padding-top: 12.1875rem !important; }
  .pt-md-40 {
    padding-top: 12.5rem !important; }
  .pt-md-base {
    padding-top: calc(20rem / 16) !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.3125rem !important; }
  .pe-md-2 {
    padding-right: 0.625rem !important; }
  .pe-md-3 {
    padding-right: 0.9375rem !important; }
  .pe-md-4 {
    padding-right: 1.25rem !important; }
  .pe-md-5 {
    padding-right: 1.5625rem !important; }
  .pe-md-6 {
    padding-right: 1.875rem !important; }
  .pe-md-7 {
    padding-right: 2.1875rem !important; }
  .pe-md-8 {
    padding-right: 2.5rem !important; }
  .pe-md-9 {
    padding-right: 2.8125rem !important; }
  .pe-md-10 {
    padding-right: 3.125rem !important; }
  .pe-md-11 {
    padding-right: 3.4375rem !important; }
  .pe-md-12 {
    padding-right: 3.75rem !important; }
  .pe-md-13 {
    padding-right: 4.0625rem !important; }
  .pe-md-14 {
    padding-right: 4.375rem !important; }
  .pe-md-15 {
    padding-right: 4.6875rem !important; }
  .pe-md-16 {
    padding-right: 5rem !important; }
  .pe-md-17 {
    padding-right: 5.3125rem !important; }
  .pe-md-18 {
    padding-right: 5.625rem !important; }
  .pe-md-19 {
    padding-right: 5.9375rem !important; }
  .pe-md-20 {
    padding-right: 6.25rem !important; }
  .pe-md-21 {
    padding-right: 6.5625rem !important; }
  .pe-md-22 {
    padding-right: 6.875rem !important; }
  .pe-md-23 {
    padding-right: 7.1875rem !important; }
  .pe-md-24 {
    padding-right: 7.5rem !important; }
  .pe-md-25 {
    padding-right: 7.8125rem !important; }
  .pe-md-26 {
    padding-right: 8.125rem !important; }
  .pe-md-27 {
    padding-right: 8.4375rem !important; }
  .pe-md-28 {
    padding-right: 8.75rem !important; }
  .pe-md-29 {
    padding-right: 9.0625rem !important; }
  .pe-md-30 {
    padding-right: 9.375rem !important; }
  .pe-md-31 {
    padding-right: 9.6875rem !important; }
  .pe-md-32 {
    padding-right: 10rem !important; }
  .pe-md-33 {
    padding-right: 10.3125rem !important; }
  .pe-md-34 {
    padding-right: 10.625rem !important; }
  .pe-md-35 {
    padding-right: 10.9375rem !important; }
  .pe-md-36 {
    padding-right: 11.25rem !important; }
  .pe-md-37 {
    padding-right: 11.5625rem !important; }
  .pe-md-38 {
    padding-right: 11.875rem !important; }
  .pe-md-39 {
    padding-right: 12.1875rem !important; }
  .pe-md-40 {
    padding-right: 12.5rem !important; }
  .pe-md-base {
    padding-right: calc(20rem / 16) !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.3125rem !important; }
  .pb-md-2 {
    padding-bottom: 0.625rem !important; }
  .pb-md-3 {
    padding-bottom: 0.9375rem !important; }
  .pb-md-4 {
    padding-bottom: 1.25rem !important; }
  .pb-md-5 {
    padding-bottom: 1.5625rem !important; }
  .pb-md-6 {
    padding-bottom: 1.875rem !important; }
  .pb-md-7 {
    padding-bottom: 2.1875rem !important; }
  .pb-md-8 {
    padding-bottom: 2.5rem !important; }
  .pb-md-9 {
    padding-bottom: 2.8125rem !important; }
  .pb-md-10 {
    padding-bottom: 3.125rem !important; }
  .pb-md-11 {
    padding-bottom: 3.4375rem !important; }
  .pb-md-12 {
    padding-bottom: 3.75rem !important; }
  .pb-md-13 {
    padding-bottom: 4.0625rem !important; }
  .pb-md-14 {
    padding-bottom: 4.375rem !important; }
  .pb-md-15 {
    padding-bottom: 4.6875rem !important; }
  .pb-md-16 {
    padding-bottom: 5rem !important; }
  .pb-md-17 {
    padding-bottom: 5.3125rem !important; }
  .pb-md-18 {
    padding-bottom: 5.625rem !important; }
  .pb-md-19 {
    padding-bottom: 5.9375rem !important; }
  .pb-md-20 {
    padding-bottom: 6.25rem !important; }
  .pb-md-21 {
    padding-bottom: 6.5625rem !important; }
  .pb-md-22 {
    padding-bottom: 6.875rem !important; }
  .pb-md-23 {
    padding-bottom: 7.1875rem !important; }
  .pb-md-24 {
    padding-bottom: 7.5rem !important; }
  .pb-md-25 {
    padding-bottom: 7.8125rem !important; }
  .pb-md-26 {
    padding-bottom: 8.125rem !important; }
  .pb-md-27 {
    padding-bottom: 8.4375rem !important; }
  .pb-md-28 {
    padding-bottom: 8.75rem !important; }
  .pb-md-29 {
    padding-bottom: 9.0625rem !important; }
  .pb-md-30 {
    padding-bottom: 9.375rem !important; }
  .pb-md-31 {
    padding-bottom: 9.6875rem !important; }
  .pb-md-32 {
    padding-bottom: 10rem !important; }
  .pb-md-33 {
    padding-bottom: 10.3125rem !important; }
  .pb-md-34 {
    padding-bottom: 10.625rem !important; }
  .pb-md-35 {
    padding-bottom: 10.9375rem !important; }
  .pb-md-36 {
    padding-bottom: 11.25rem !important; }
  .pb-md-37 {
    padding-bottom: 11.5625rem !important; }
  .pb-md-38 {
    padding-bottom: 11.875rem !important; }
  .pb-md-39 {
    padding-bottom: 12.1875rem !important; }
  .pb-md-40 {
    padding-bottom: 12.5rem !important; }
  .pb-md-base {
    padding-bottom: calc(20rem / 16) !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.3125rem !important; }
  .ps-md-2 {
    padding-left: 0.625rem !important; }
  .ps-md-3 {
    padding-left: 0.9375rem !important; }
  .ps-md-4 {
    padding-left: 1.25rem !important; }
  .ps-md-5 {
    padding-left: 1.5625rem !important; }
  .ps-md-6 {
    padding-left: 1.875rem !important; }
  .ps-md-7 {
    padding-left: 2.1875rem !important; }
  .ps-md-8 {
    padding-left: 2.5rem !important; }
  .ps-md-9 {
    padding-left: 2.8125rem !important; }
  .ps-md-10 {
    padding-left: 3.125rem !important; }
  .ps-md-11 {
    padding-left: 3.4375rem !important; }
  .ps-md-12 {
    padding-left: 3.75rem !important; }
  .ps-md-13 {
    padding-left: 4.0625rem !important; }
  .ps-md-14 {
    padding-left: 4.375rem !important; }
  .ps-md-15 {
    padding-left: 4.6875rem !important; }
  .ps-md-16 {
    padding-left: 5rem !important; }
  .ps-md-17 {
    padding-left: 5.3125rem !important; }
  .ps-md-18 {
    padding-left: 5.625rem !important; }
  .ps-md-19 {
    padding-left: 5.9375rem !important; }
  .ps-md-20 {
    padding-left: 6.25rem !important; }
  .ps-md-21 {
    padding-left: 6.5625rem !important; }
  .ps-md-22 {
    padding-left: 6.875rem !important; }
  .ps-md-23 {
    padding-left: 7.1875rem !important; }
  .ps-md-24 {
    padding-left: 7.5rem !important; }
  .ps-md-25 {
    padding-left: 7.8125rem !important; }
  .ps-md-26 {
    padding-left: 8.125rem !important; }
  .ps-md-27 {
    padding-left: 8.4375rem !important; }
  .ps-md-28 {
    padding-left: 8.75rem !important; }
  .ps-md-29 {
    padding-left: 9.0625rem !important; }
  .ps-md-30 {
    padding-left: 9.375rem !important; }
  .ps-md-31 {
    padding-left: 9.6875rem !important; }
  .ps-md-32 {
    padding-left: 10rem !important; }
  .ps-md-33 {
    padding-left: 10.3125rem !important; }
  .ps-md-34 {
    padding-left: 10.625rem !important; }
  .ps-md-35 {
    padding-left: 10.9375rem !important; }
  .ps-md-36 {
    padding-left: 11.25rem !important; }
  .ps-md-37 {
    padding-left: 11.5625rem !important; }
  .ps-md-38 {
    padding-left: 11.875rem !important; }
  .ps-md-39 {
    padding-left: 12.1875rem !important; }
  .ps-md-40 {
    padding-left: 12.5rem !important; }
  .ps-md-base {
    padding-left: calc(20rem / 16) !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.3125rem !important; }
  .gap-md-2 {
    gap: 0.625rem !important; }
  .gap-md-3 {
    gap: 0.9375rem !important; }
  .gap-md-4 {
    gap: 1.25rem !important; }
  .gap-md-5 {
    gap: 1.5625rem !important; }
  .gap-md-6 {
    gap: 1.875rem !important; }
  .gap-md-7 {
    gap: 2.1875rem !important; }
  .gap-md-8 {
    gap: 2.5rem !important; }
  .gap-md-9 {
    gap: 2.8125rem !important; }
  .gap-md-10 {
    gap: 3.125rem !important; }
  .gap-md-11 {
    gap: 3.4375rem !important; }
  .gap-md-12 {
    gap: 3.75rem !important; }
  .gap-md-13 {
    gap: 4.0625rem !important; }
  .gap-md-14 {
    gap: 4.375rem !important; }
  .gap-md-15 {
    gap: 4.6875rem !important; }
  .gap-md-16 {
    gap: 5rem !important; }
  .gap-md-17 {
    gap: 5.3125rem !important; }
  .gap-md-18 {
    gap: 5.625rem !important; }
  .gap-md-19 {
    gap: 5.9375rem !important; }
  .gap-md-20 {
    gap: 6.25rem !important; }
  .gap-md-21 {
    gap: 6.5625rem !important; }
  .gap-md-22 {
    gap: 6.875rem !important; }
  .gap-md-23 {
    gap: 7.1875rem !important; }
  .gap-md-24 {
    gap: 7.5rem !important; }
  .gap-md-25 {
    gap: 7.8125rem !important; }
  .gap-md-26 {
    gap: 8.125rem !important; }
  .gap-md-27 {
    gap: 8.4375rem !important; }
  .gap-md-28 {
    gap: 8.75rem !important; }
  .gap-md-29 {
    gap: 9.0625rem !important; }
  .gap-md-30 {
    gap: 9.375rem !important; }
  .gap-md-31 {
    gap: 9.6875rem !important; }
  .gap-md-32 {
    gap: 10rem !important; }
  .gap-md-33 {
    gap: 10.3125rem !important; }
  .gap-md-34 {
    gap: 10.625rem !important; }
  .gap-md-35 {
    gap: 10.9375rem !important; }
  .gap-md-36 {
    gap: 11.25rem !important; }
  .gap-md-37 {
    gap: 11.5625rem !important; }
  .gap-md-38 {
    gap: 11.875rem !important; }
  .gap-md-39 {
    gap: 12.1875rem !important; }
  .gap-md-40 {
    gap: 12.5rem !important; }
  .gap-md-base {
    gap: calc(20rem / 16) !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.3125rem !important; }
  .m-lg-2 {
    margin: 0.625rem !important; }
  .m-lg-3 {
    margin: 0.9375rem !important; }
  .m-lg-4 {
    margin: 1.25rem !important; }
  .m-lg-5 {
    margin: 1.5625rem !important; }
  .m-lg-6 {
    margin: 1.875rem !important; }
  .m-lg-7 {
    margin: 2.1875rem !important; }
  .m-lg-8 {
    margin: 2.5rem !important; }
  .m-lg-9 {
    margin: 2.8125rem !important; }
  .m-lg-10 {
    margin: 3.125rem !important; }
  .m-lg-11 {
    margin: 3.4375rem !important; }
  .m-lg-12 {
    margin: 3.75rem !important; }
  .m-lg-13 {
    margin: 4.0625rem !important; }
  .m-lg-14 {
    margin: 4.375rem !important; }
  .m-lg-15 {
    margin: 4.6875rem !important; }
  .m-lg-16 {
    margin: 5rem !important; }
  .m-lg-17 {
    margin: 5.3125rem !important; }
  .m-lg-18 {
    margin: 5.625rem !important; }
  .m-lg-19 {
    margin: 5.9375rem !important; }
  .m-lg-20 {
    margin: 6.25rem !important; }
  .m-lg-21 {
    margin: 6.5625rem !important; }
  .m-lg-22 {
    margin: 6.875rem !important; }
  .m-lg-23 {
    margin: 7.1875rem !important; }
  .m-lg-24 {
    margin: 7.5rem !important; }
  .m-lg-25 {
    margin: 7.8125rem !important; }
  .m-lg-26 {
    margin: 8.125rem !important; }
  .m-lg-27 {
    margin: 8.4375rem !important; }
  .m-lg-28 {
    margin: 8.75rem !important; }
  .m-lg-29 {
    margin: 9.0625rem !important; }
  .m-lg-30 {
    margin: 9.375rem !important; }
  .m-lg-31 {
    margin: 9.6875rem !important; }
  .m-lg-32 {
    margin: 10rem !important; }
  .m-lg-33 {
    margin: 10.3125rem !important; }
  .m-lg-34 {
    margin: 10.625rem !important; }
  .m-lg-35 {
    margin: 10.9375rem !important; }
  .m-lg-36 {
    margin: 11.25rem !important; }
  .m-lg-37 {
    margin: 11.5625rem !important; }
  .m-lg-38 {
    margin: 11.875rem !important; }
  .m-lg-39 {
    margin: 12.1875rem !important; }
  .m-lg-40 {
    margin: 12.5rem !important; }
  .m-lg-base {
    margin: calc(20rem / 16) !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important; }
  .mx-lg-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important; }
  .mx-lg-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important; }
  .mx-lg-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important; }
  .mx-lg-5 {
    margin-right: 1.5625rem !important;
    margin-left: 1.5625rem !important; }
  .mx-lg-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important; }
  .mx-lg-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important; }
  .mx-lg-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-lg-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important; }
  .mx-lg-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important; }
  .mx-lg-11 {
    margin-right: 3.4375rem !important;
    margin-left: 3.4375rem !important; }
  .mx-lg-12 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important; }
  .mx-lg-13 {
    margin-right: 4.0625rem !important;
    margin-left: 4.0625rem !important; }
  .mx-lg-14 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important; }
  .mx-lg-15 {
    margin-right: 4.6875rem !important;
    margin-left: 4.6875rem !important; }
  .mx-lg-16 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-lg-17 {
    margin-right: 5.3125rem !important;
    margin-left: 5.3125rem !important; }
  .mx-lg-18 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important; }
  .mx-lg-19 {
    margin-right: 5.9375rem !important;
    margin-left: 5.9375rem !important; }
  .mx-lg-20 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important; }
  .mx-lg-21 {
    margin-right: 6.5625rem !important;
    margin-left: 6.5625rem !important; }
  .mx-lg-22 {
    margin-right: 6.875rem !important;
    margin-left: 6.875rem !important; }
  .mx-lg-23 {
    margin-right: 7.1875rem !important;
    margin-left: 7.1875rem !important; }
  .mx-lg-24 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-lg-25 {
    margin-right: 7.8125rem !important;
    margin-left: 7.8125rem !important; }
  .mx-lg-26 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important; }
  .mx-lg-27 {
    margin-right: 8.4375rem !important;
    margin-left: 8.4375rem !important; }
  .mx-lg-28 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important; }
  .mx-lg-29 {
    margin-right: 9.0625rem !important;
    margin-left: 9.0625rem !important; }
  .mx-lg-30 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important; }
  .mx-lg-31 {
    margin-right: 9.6875rem !important;
    margin-left: 9.6875rem !important; }
  .mx-lg-32 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-lg-33 {
    margin-right: 10.3125rem !important;
    margin-left: 10.3125rem !important; }
  .mx-lg-34 {
    margin-right: 10.625rem !important;
    margin-left: 10.625rem !important; }
  .mx-lg-35 {
    margin-right: 10.9375rem !important;
    margin-left: 10.9375rem !important; }
  .mx-lg-36 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important; }
  .mx-lg-37 {
    margin-right: 11.5625rem !important;
    margin-left: 11.5625rem !important; }
  .mx-lg-38 {
    margin-right: 11.875rem !important;
    margin-left: 11.875rem !important; }
  .mx-lg-39 {
    margin-right: 12.1875rem !important;
    margin-left: 12.1875rem !important; }
  .mx-lg-40 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-lg-base {
    margin-right: calc(20rem / 16) !important;
    margin-left: calc(20rem / 16) !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important; }
  .my-lg-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important; }
  .my-lg-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important; }
  .my-lg-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .my-lg-5 {
    margin-top: 1.5625rem !important;
    margin-bottom: 1.5625rem !important; }
  .my-lg-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important; }
  .my-lg-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important; }
  .my-lg-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-lg-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important; }
  .my-lg-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important; }
  .my-lg-11 {
    margin-top: 3.4375rem !important;
    margin-bottom: 3.4375rem !important; }
  .my-lg-12 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important; }
  .my-lg-13 {
    margin-top: 4.0625rem !important;
    margin-bottom: 4.0625rem !important; }
  .my-lg-14 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important; }
  .my-lg-15 {
    margin-top: 4.6875rem !important;
    margin-bottom: 4.6875rem !important; }
  .my-lg-16 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-lg-17 {
    margin-top: 5.3125rem !important;
    margin-bottom: 5.3125rem !important; }
  .my-lg-18 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important; }
  .my-lg-19 {
    margin-top: 5.9375rem !important;
    margin-bottom: 5.9375rem !important; }
  .my-lg-20 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important; }
  .my-lg-21 {
    margin-top: 6.5625rem !important;
    margin-bottom: 6.5625rem !important; }
  .my-lg-22 {
    margin-top: 6.875rem !important;
    margin-bottom: 6.875rem !important; }
  .my-lg-23 {
    margin-top: 7.1875rem !important;
    margin-bottom: 7.1875rem !important; }
  .my-lg-24 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-lg-25 {
    margin-top: 7.8125rem !important;
    margin-bottom: 7.8125rem !important; }
  .my-lg-26 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important; }
  .my-lg-27 {
    margin-top: 8.4375rem !important;
    margin-bottom: 8.4375rem !important; }
  .my-lg-28 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important; }
  .my-lg-29 {
    margin-top: 9.0625rem !important;
    margin-bottom: 9.0625rem !important; }
  .my-lg-30 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important; }
  .my-lg-31 {
    margin-top: 9.6875rem !important;
    margin-bottom: 9.6875rem !important; }
  .my-lg-32 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-lg-33 {
    margin-top: 10.3125rem !important;
    margin-bottom: 10.3125rem !important; }
  .my-lg-34 {
    margin-top: 10.625rem !important;
    margin-bottom: 10.625rem !important; }
  .my-lg-35 {
    margin-top: 10.9375rem !important;
    margin-bottom: 10.9375rem !important; }
  .my-lg-36 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important; }
  .my-lg-37 {
    margin-top: 11.5625rem !important;
    margin-bottom: 11.5625rem !important; }
  .my-lg-38 {
    margin-top: 11.875rem !important;
    margin-bottom: 11.875rem !important; }
  .my-lg-39 {
    margin-top: 12.1875rem !important;
    margin-bottom: 12.1875rem !important; }
  .my-lg-40 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-lg-base {
    margin-top: calc(20rem / 16) !important;
    margin-bottom: calc(20rem / 16) !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.3125rem !important; }
  .mt-lg-2 {
    margin-top: 0.625rem !important; }
  .mt-lg-3 {
    margin-top: 0.9375rem !important; }
  .mt-lg-4 {
    margin-top: 1.25rem !important; }
  .mt-lg-5 {
    margin-top: 1.5625rem !important; }
  .mt-lg-6 {
    margin-top: 1.875rem !important; }
  .mt-lg-7 {
    margin-top: 2.1875rem !important; }
  .mt-lg-8 {
    margin-top: 2.5rem !important; }
  .mt-lg-9 {
    margin-top: 2.8125rem !important; }
  .mt-lg-10 {
    margin-top: 3.125rem !important; }
  .mt-lg-11 {
    margin-top: 3.4375rem !important; }
  .mt-lg-12 {
    margin-top: 3.75rem !important; }
  .mt-lg-13 {
    margin-top: 4.0625rem !important; }
  .mt-lg-14 {
    margin-top: 4.375rem !important; }
  .mt-lg-15 {
    margin-top: 4.6875rem !important; }
  .mt-lg-16 {
    margin-top: 5rem !important; }
  .mt-lg-17 {
    margin-top: 5.3125rem !important; }
  .mt-lg-18 {
    margin-top: 5.625rem !important; }
  .mt-lg-19 {
    margin-top: 5.9375rem !important; }
  .mt-lg-20 {
    margin-top: 6.25rem !important; }
  .mt-lg-21 {
    margin-top: 6.5625rem !important; }
  .mt-lg-22 {
    margin-top: 6.875rem !important; }
  .mt-lg-23 {
    margin-top: 7.1875rem !important; }
  .mt-lg-24 {
    margin-top: 7.5rem !important; }
  .mt-lg-25 {
    margin-top: 7.8125rem !important; }
  .mt-lg-26 {
    margin-top: 8.125rem !important; }
  .mt-lg-27 {
    margin-top: 8.4375rem !important; }
  .mt-lg-28 {
    margin-top: 8.75rem !important; }
  .mt-lg-29 {
    margin-top: 9.0625rem !important; }
  .mt-lg-30 {
    margin-top: 9.375rem !important; }
  .mt-lg-31 {
    margin-top: 9.6875rem !important; }
  .mt-lg-32 {
    margin-top: 10rem !important; }
  .mt-lg-33 {
    margin-top: 10.3125rem !important; }
  .mt-lg-34 {
    margin-top: 10.625rem !important; }
  .mt-lg-35 {
    margin-top: 10.9375rem !important; }
  .mt-lg-36 {
    margin-top: 11.25rem !important; }
  .mt-lg-37 {
    margin-top: 11.5625rem !important; }
  .mt-lg-38 {
    margin-top: 11.875rem !important; }
  .mt-lg-39 {
    margin-top: 12.1875rem !important; }
  .mt-lg-40 {
    margin-top: 12.5rem !important; }
  .mt-lg-base {
    margin-top: calc(20rem / 16) !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.3125rem !important; }
  .me-lg-2 {
    margin-right: 0.625rem !important; }
  .me-lg-3 {
    margin-right: 0.9375rem !important; }
  .me-lg-4 {
    margin-right: 1.25rem !important; }
  .me-lg-5 {
    margin-right: 1.5625rem !important; }
  .me-lg-6 {
    margin-right: 1.875rem !important; }
  .me-lg-7 {
    margin-right: 2.1875rem !important; }
  .me-lg-8 {
    margin-right: 2.5rem !important; }
  .me-lg-9 {
    margin-right: 2.8125rem !important; }
  .me-lg-10 {
    margin-right: 3.125rem !important; }
  .me-lg-11 {
    margin-right: 3.4375rem !important; }
  .me-lg-12 {
    margin-right: 3.75rem !important; }
  .me-lg-13 {
    margin-right: 4.0625rem !important; }
  .me-lg-14 {
    margin-right: 4.375rem !important; }
  .me-lg-15 {
    margin-right: 4.6875rem !important; }
  .me-lg-16 {
    margin-right: 5rem !important; }
  .me-lg-17 {
    margin-right: 5.3125rem !important; }
  .me-lg-18 {
    margin-right: 5.625rem !important; }
  .me-lg-19 {
    margin-right: 5.9375rem !important; }
  .me-lg-20 {
    margin-right: 6.25rem !important; }
  .me-lg-21 {
    margin-right: 6.5625rem !important; }
  .me-lg-22 {
    margin-right: 6.875rem !important; }
  .me-lg-23 {
    margin-right: 7.1875rem !important; }
  .me-lg-24 {
    margin-right: 7.5rem !important; }
  .me-lg-25 {
    margin-right: 7.8125rem !important; }
  .me-lg-26 {
    margin-right: 8.125rem !important; }
  .me-lg-27 {
    margin-right: 8.4375rem !important; }
  .me-lg-28 {
    margin-right: 8.75rem !important; }
  .me-lg-29 {
    margin-right: 9.0625rem !important; }
  .me-lg-30 {
    margin-right: 9.375rem !important; }
  .me-lg-31 {
    margin-right: 9.6875rem !important; }
  .me-lg-32 {
    margin-right: 10rem !important; }
  .me-lg-33 {
    margin-right: 10.3125rem !important; }
  .me-lg-34 {
    margin-right: 10.625rem !important; }
  .me-lg-35 {
    margin-right: 10.9375rem !important; }
  .me-lg-36 {
    margin-right: 11.25rem !important; }
  .me-lg-37 {
    margin-right: 11.5625rem !important; }
  .me-lg-38 {
    margin-right: 11.875rem !important; }
  .me-lg-39 {
    margin-right: 12.1875rem !important; }
  .me-lg-40 {
    margin-right: 12.5rem !important; }
  .me-lg-base {
    margin-right: calc(20rem / 16) !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.3125rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.625rem !important; }
  .mb-lg-3 {
    margin-bottom: 0.9375rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.25rem !important; }
  .mb-lg-5 {
    margin-bottom: 1.5625rem !important; }
  .mb-lg-6 {
    margin-bottom: 1.875rem !important; }
  .mb-lg-7 {
    margin-bottom: 2.1875rem !important; }
  .mb-lg-8 {
    margin-bottom: 2.5rem !important; }
  .mb-lg-9 {
    margin-bottom: 2.8125rem !important; }
  .mb-lg-10 {
    margin-bottom: 3.125rem !important; }
  .mb-lg-11 {
    margin-bottom: 3.4375rem !important; }
  .mb-lg-12 {
    margin-bottom: 3.75rem !important; }
  .mb-lg-13 {
    margin-bottom: 4.0625rem !important; }
  .mb-lg-14 {
    margin-bottom: 4.375rem !important; }
  .mb-lg-15 {
    margin-bottom: 4.6875rem !important; }
  .mb-lg-16 {
    margin-bottom: 5rem !important; }
  .mb-lg-17 {
    margin-bottom: 5.3125rem !important; }
  .mb-lg-18 {
    margin-bottom: 5.625rem !important; }
  .mb-lg-19 {
    margin-bottom: 5.9375rem !important; }
  .mb-lg-20 {
    margin-bottom: 6.25rem !important; }
  .mb-lg-21 {
    margin-bottom: 6.5625rem !important; }
  .mb-lg-22 {
    margin-bottom: 6.875rem !important; }
  .mb-lg-23 {
    margin-bottom: 7.1875rem !important; }
  .mb-lg-24 {
    margin-bottom: 7.5rem !important; }
  .mb-lg-25 {
    margin-bottom: 7.8125rem !important; }
  .mb-lg-26 {
    margin-bottom: 8.125rem !important; }
  .mb-lg-27 {
    margin-bottom: 8.4375rem !important; }
  .mb-lg-28 {
    margin-bottom: 8.75rem !important; }
  .mb-lg-29 {
    margin-bottom: 9.0625rem !important; }
  .mb-lg-30 {
    margin-bottom: 9.375rem !important; }
  .mb-lg-31 {
    margin-bottom: 9.6875rem !important; }
  .mb-lg-32 {
    margin-bottom: 10rem !important; }
  .mb-lg-33 {
    margin-bottom: 10.3125rem !important; }
  .mb-lg-34 {
    margin-bottom: 10.625rem !important; }
  .mb-lg-35 {
    margin-bottom: 10.9375rem !important; }
  .mb-lg-36 {
    margin-bottom: 11.25rem !important; }
  .mb-lg-37 {
    margin-bottom: 11.5625rem !important; }
  .mb-lg-38 {
    margin-bottom: 11.875rem !important; }
  .mb-lg-39 {
    margin-bottom: 12.1875rem !important; }
  .mb-lg-40 {
    margin-bottom: 12.5rem !important; }
  .mb-lg-base {
    margin-bottom: calc(20rem / 16) !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.3125rem !important; }
  .ms-lg-2 {
    margin-left: 0.625rem !important; }
  .ms-lg-3 {
    margin-left: 0.9375rem !important; }
  .ms-lg-4 {
    margin-left: 1.25rem !important; }
  .ms-lg-5 {
    margin-left: 1.5625rem !important; }
  .ms-lg-6 {
    margin-left: 1.875rem !important; }
  .ms-lg-7 {
    margin-left: 2.1875rem !important; }
  .ms-lg-8 {
    margin-left: 2.5rem !important; }
  .ms-lg-9 {
    margin-left: 2.8125rem !important; }
  .ms-lg-10 {
    margin-left: 3.125rem !important; }
  .ms-lg-11 {
    margin-left: 3.4375rem !important; }
  .ms-lg-12 {
    margin-left: 3.75rem !important; }
  .ms-lg-13 {
    margin-left: 4.0625rem !important; }
  .ms-lg-14 {
    margin-left: 4.375rem !important; }
  .ms-lg-15 {
    margin-left: 4.6875rem !important; }
  .ms-lg-16 {
    margin-left: 5rem !important; }
  .ms-lg-17 {
    margin-left: 5.3125rem !important; }
  .ms-lg-18 {
    margin-left: 5.625rem !important; }
  .ms-lg-19 {
    margin-left: 5.9375rem !important; }
  .ms-lg-20 {
    margin-left: 6.25rem !important; }
  .ms-lg-21 {
    margin-left: 6.5625rem !important; }
  .ms-lg-22 {
    margin-left: 6.875rem !important; }
  .ms-lg-23 {
    margin-left: 7.1875rem !important; }
  .ms-lg-24 {
    margin-left: 7.5rem !important; }
  .ms-lg-25 {
    margin-left: 7.8125rem !important; }
  .ms-lg-26 {
    margin-left: 8.125rem !important; }
  .ms-lg-27 {
    margin-left: 8.4375rem !important; }
  .ms-lg-28 {
    margin-left: 8.75rem !important; }
  .ms-lg-29 {
    margin-left: 9.0625rem !important; }
  .ms-lg-30 {
    margin-left: 9.375rem !important; }
  .ms-lg-31 {
    margin-left: 9.6875rem !important; }
  .ms-lg-32 {
    margin-left: 10rem !important; }
  .ms-lg-33 {
    margin-left: 10.3125rem !important; }
  .ms-lg-34 {
    margin-left: 10.625rem !important; }
  .ms-lg-35 {
    margin-left: 10.9375rem !important; }
  .ms-lg-36 {
    margin-left: 11.25rem !important; }
  .ms-lg-37 {
    margin-left: 11.5625rem !important; }
  .ms-lg-38 {
    margin-left: 11.875rem !important; }
  .ms-lg-39 {
    margin-left: 12.1875rem !important; }
  .ms-lg-40 {
    margin-left: 12.5rem !important; }
  .ms-lg-base {
    margin-left: calc(20rem / 16) !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .m-lg-n1 {
    margin: -0.3125rem !important; }
  .m-lg-n2 {
    margin: -0.625rem !important; }
  .m-lg-n3 {
    margin: -0.9375rem !important; }
  .m-lg-n4 {
    margin: -1.25rem !important; }
  .m-lg-n5 {
    margin: -1.5625rem !important; }
  .m-lg-n6 {
    margin: -1.875rem !important; }
  .m-lg-n7 {
    margin: -2.1875rem !important; }
  .m-lg-n8 {
    margin: -2.5rem !important; }
  .m-lg-n9 {
    margin: -2.8125rem !important; }
  .m-lg-n10 {
    margin: -3.125rem !important; }
  .m-lg-n11 {
    margin: -3.4375rem !important; }
  .m-lg-n12 {
    margin: -3.75rem !important; }
  .m-lg-n13 {
    margin: -4.0625rem !important; }
  .m-lg-n14 {
    margin: -4.375rem !important; }
  .m-lg-n15 {
    margin: -4.6875rem !important; }
  .m-lg-n16 {
    margin: -5rem !important; }
  .m-lg-n17 {
    margin: -5.3125rem !important; }
  .m-lg-n18 {
    margin: -5.625rem !important; }
  .m-lg-n19 {
    margin: -5.9375rem !important; }
  .m-lg-n20 {
    margin: -6.25rem !important; }
  .m-lg-n21 {
    margin: -6.5625rem !important; }
  .m-lg-n22 {
    margin: -6.875rem !important; }
  .m-lg-n23 {
    margin: -7.1875rem !important; }
  .m-lg-n24 {
    margin: -7.5rem !important; }
  .m-lg-n25 {
    margin: -7.8125rem !important; }
  .m-lg-n26 {
    margin: -8.125rem !important; }
  .m-lg-n27 {
    margin: -8.4375rem !important; }
  .m-lg-n28 {
    margin: -8.75rem !important; }
  .m-lg-n29 {
    margin: -9.0625rem !important; }
  .m-lg-n30 {
    margin: -9.375rem !important; }
  .m-lg-n31 {
    margin: -9.6875rem !important; }
  .m-lg-n32 {
    margin: -10rem !important; }
  .m-lg-n33 {
    margin: -10.3125rem !important; }
  .m-lg-n34 {
    margin: -10.625rem !important; }
  .m-lg-n35 {
    margin: -10.9375rem !important; }
  .m-lg-n36 {
    margin: -11.25rem !important; }
  .m-lg-n37 {
    margin: -11.5625rem !important; }
  .m-lg-n38 {
    margin: -11.875rem !important; }
  .m-lg-n39 {
    margin: -12.1875rem !important; }
  .m-lg-n40 {
    margin: -12.5rem !important; }
  .m-lg-nbase {
    margin: -calc(20rem / 16) !important; }
  .mx-lg-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important; }
  .mx-lg-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important; }
  .mx-lg-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important; }
  .mx-lg-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important; }
  .mx-lg-n5 {
    margin-right: -1.5625rem !important;
    margin-left: -1.5625rem !important; }
  .mx-lg-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important; }
  .mx-lg-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important; }
  .mx-lg-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-lg-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important; }
  .mx-lg-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important; }
  .mx-lg-n11 {
    margin-right: -3.4375rem !important;
    margin-left: -3.4375rem !important; }
  .mx-lg-n12 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important; }
  .mx-lg-n13 {
    margin-right: -4.0625rem !important;
    margin-left: -4.0625rem !important; }
  .mx-lg-n14 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important; }
  .mx-lg-n15 {
    margin-right: -4.6875rem !important;
    margin-left: -4.6875rem !important; }
  .mx-lg-n16 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-lg-n17 {
    margin-right: -5.3125rem !important;
    margin-left: -5.3125rem !important; }
  .mx-lg-n18 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important; }
  .mx-lg-n19 {
    margin-right: -5.9375rem !important;
    margin-left: -5.9375rem !important; }
  .mx-lg-n20 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important; }
  .mx-lg-n21 {
    margin-right: -6.5625rem !important;
    margin-left: -6.5625rem !important; }
  .mx-lg-n22 {
    margin-right: -6.875rem !important;
    margin-left: -6.875rem !important; }
  .mx-lg-n23 {
    margin-right: -7.1875rem !important;
    margin-left: -7.1875rem !important; }
  .mx-lg-n24 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-lg-n25 {
    margin-right: -7.8125rem !important;
    margin-left: -7.8125rem !important; }
  .mx-lg-n26 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important; }
  .mx-lg-n27 {
    margin-right: -8.4375rem !important;
    margin-left: -8.4375rem !important; }
  .mx-lg-n28 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important; }
  .mx-lg-n29 {
    margin-right: -9.0625rem !important;
    margin-left: -9.0625rem !important; }
  .mx-lg-n30 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important; }
  .mx-lg-n31 {
    margin-right: -9.6875rem !important;
    margin-left: -9.6875rem !important; }
  .mx-lg-n32 {
    margin-right: -10rem !important;
    margin-left: -10rem !important; }
  .mx-lg-n33 {
    margin-right: -10.3125rem !important;
    margin-left: -10.3125rem !important; }
  .mx-lg-n34 {
    margin-right: -10.625rem !important;
    margin-left: -10.625rem !important; }
  .mx-lg-n35 {
    margin-right: -10.9375rem !important;
    margin-left: -10.9375rem !important; }
  .mx-lg-n36 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important; }
  .mx-lg-n37 {
    margin-right: -11.5625rem !important;
    margin-left: -11.5625rem !important; }
  .mx-lg-n38 {
    margin-right: -11.875rem !important;
    margin-left: -11.875rem !important; }
  .mx-lg-n39 {
    margin-right: -12.1875rem !important;
    margin-left: -12.1875rem !important; }
  .mx-lg-n40 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important; }
  .mx-lg-nbase {
    margin-right: -calc(20rem / 16) !important;
    margin-left: -calc(20rem / 16) !important; }
  .my-lg-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important; }
  .my-lg-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important; }
  .my-lg-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important; }
  .my-lg-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .my-lg-n5 {
    margin-top: -1.5625rem !important;
    margin-bottom: -1.5625rem !important; }
  .my-lg-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important; }
  .my-lg-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important; }
  .my-lg-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-lg-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important; }
  .my-lg-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important; }
  .my-lg-n11 {
    margin-top: -3.4375rem !important;
    margin-bottom: -3.4375rem !important; }
  .my-lg-n12 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important; }
  .my-lg-n13 {
    margin-top: -4.0625rem !important;
    margin-bottom: -4.0625rem !important; }
  .my-lg-n14 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important; }
  .my-lg-n15 {
    margin-top: -4.6875rem !important;
    margin-bottom: -4.6875rem !important; }
  .my-lg-n16 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-lg-n17 {
    margin-top: -5.3125rem !important;
    margin-bottom: -5.3125rem !important; }
  .my-lg-n18 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important; }
  .my-lg-n19 {
    margin-top: -5.9375rem !important;
    margin-bottom: -5.9375rem !important; }
  .my-lg-n20 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important; }
  .my-lg-n21 {
    margin-top: -6.5625rem !important;
    margin-bottom: -6.5625rem !important; }
  .my-lg-n22 {
    margin-top: -6.875rem !important;
    margin-bottom: -6.875rem !important; }
  .my-lg-n23 {
    margin-top: -7.1875rem !important;
    margin-bottom: -7.1875rem !important; }
  .my-lg-n24 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-lg-n25 {
    margin-top: -7.8125rem !important;
    margin-bottom: -7.8125rem !important; }
  .my-lg-n26 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important; }
  .my-lg-n27 {
    margin-top: -8.4375rem !important;
    margin-bottom: -8.4375rem !important; }
  .my-lg-n28 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important; }
  .my-lg-n29 {
    margin-top: -9.0625rem !important;
    margin-bottom: -9.0625rem !important; }
  .my-lg-n30 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important; }
  .my-lg-n31 {
    margin-top: -9.6875rem !important;
    margin-bottom: -9.6875rem !important; }
  .my-lg-n32 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .my-lg-n33 {
    margin-top: -10.3125rem !important;
    margin-bottom: -10.3125rem !important; }
  .my-lg-n34 {
    margin-top: -10.625rem !important;
    margin-bottom: -10.625rem !important; }
  .my-lg-n35 {
    margin-top: -10.9375rem !important;
    margin-bottom: -10.9375rem !important; }
  .my-lg-n36 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important; }
  .my-lg-n37 {
    margin-top: -11.5625rem !important;
    margin-bottom: -11.5625rem !important; }
  .my-lg-n38 {
    margin-top: -11.875rem !important;
    margin-bottom: -11.875rem !important; }
  .my-lg-n39 {
    margin-top: -12.1875rem !important;
    margin-bottom: -12.1875rem !important; }
  .my-lg-n40 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important; }
  .my-lg-nbase {
    margin-top: -calc(20rem / 16) !important;
    margin-bottom: -calc(20rem / 16) !important; }
  .mt-lg-n1 {
    margin-top: -0.3125rem !important; }
  .mt-lg-n2 {
    margin-top: -0.625rem !important; }
  .mt-lg-n3 {
    margin-top: -0.9375rem !important; }
  .mt-lg-n4 {
    margin-top: -1.25rem !important; }
  .mt-lg-n5 {
    margin-top: -1.5625rem !important; }
  .mt-lg-n6 {
    margin-top: -1.875rem !important; }
  .mt-lg-n7 {
    margin-top: -2.1875rem !important; }
  .mt-lg-n8 {
    margin-top: -2.5rem !important; }
  .mt-lg-n9 {
    margin-top: -2.8125rem !important; }
  .mt-lg-n10 {
    margin-top: -3.125rem !important; }
  .mt-lg-n11 {
    margin-top: -3.4375rem !important; }
  .mt-lg-n12 {
    margin-top: -3.75rem !important; }
  .mt-lg-n13 {
    margin-top: -4.0625rem !important; }
  .mt-lg-n14 {
    margin-top: -4.375rem !important; }
  .mt-lg-n15 {
    margin-top: -4.6875rem !important; }
  .mt-lg-n16 {
    margin-top: -5rem !important; }
  .mt-lg-n17 {
    margin-top: -5.3125rem !important; }
  .mt-lg-n18 {
    margin-top: -5.625rem !important; }
  .mt-lg-n19 {
    margin-top: -5.9375rem !important; }
  .mt-lg-n20 {
    margin-top: -6.25rem !important; }
  .mt-lg-n21 {
    margin-top: -6.5625rem !important; }
  .mt-lg-n22 {
    margin-top: -6.875rem !important; }
  .mt-lg-n23 {
    margin-top: -7.1875rem !important; }
  .mt-lg-n24 {
    margin-top: -7.5rem !important; }
  .mt-lg-n25 {
    margin-top: -7.8125rem !important; }
  .mt-lg-n26 {
    margin-top: -8.125rem !important; }
  .mt-lg-n27 {
    margin-top: -8.4375rem !important; }
  .mt-lg-n28 {
    margin-top: -8.75rem !important; }
  .mt-lg-n29 {
    margin-top: -9.0625rem !important; }
  .mt-lg-n30 {
    margin-top: -9.375rem !important; }
  .mt-lg-n31 {
    margin-top: -9.6875rem !important; }
  .mt-lg-n32 {
    margin-top: -10rem !important; }
  .mt-lg-n33 {
    margin-top: -10.3125rem !important; }
  .mt-lg-n34 {
    margin-top: -10.625rem !important; }
  .mt-lg-n35 {
    margin-top: -10.9375rem !important; }
  .mt-lg-n36 {
    margin-top: -11.25rem !important; }
  .mt-lg-n37 {
    margin-top: -11.5625rem !important; }
  .mt-lg-n38 {
    margin-top: -11.875rem !important; }
  .mt-lg-n39 {
    margin-top: -12.1875rem !important; }
  .mt-lg-n40 {
    margin-top: -12.5rem !important; }
  .mt-lg-nbase {
    margin-top: -calc(20rem / 16) !important; }
  .me-lg-n1 {
    margin-right: -0.3125rem !important; }
  .me-lg-n2 {
    margin-right: -0.625rem !important; }
  .me-lg-n3 {
    margin-right: -0.9375rem !important; }
  .me-lg-n4 {
    margin-right: -1.25rem !important; }
  .me-lg-n5 {
    margin-right: -1.5625rem !important; }
  .me-lg-n6 {
    margin-right: -1.875rem !important; }
  .me-lg-n7 {
    margin-right: -2.1875rem !important; }
  .me-lg-n8 {
    margin-right: -2.5rem !important; }
  .me-lg-n9 {
    margin-right: -2.8125rem !important; }
  .me-lg-n10 {
    margin-right: -3.125rem !important; }
  .me-lg-n11 {
    margin-right: -3.4375rem !important; }
  .me-lg-n12 {
    margin-right: -3.75rem !important; }
  .me-lg-n13 {
    margin-right: -4.0625rem !important; }
  .me-lg-n14 {
    margin-right: -4.375rem !important; }
  .me-lg-n15 {
    margin-right: -4.6875rem !important; }
  .me-lg-n16 {
    margin-right: -5rem !important; }
  .me-lg-n17 {
    margin-right: -5.3125rem !important; }
  .me-lg-n18 {
    margin-right: -5.625rem !important; }
  .me-lg-n19 {
    margin-right: -5.9375rem !important; }
  .me-lg-n20 {
    margin-right: -6.25rem !important; }
  .me-lg-n21 {
    margin-right: -6.5625rem !important; }
  .me-lg-n22 {
    margin-right: -6.875rem !important; }
  .me-lg-n23 {
    margin-right: -7.1875rem !important; }
  .me-lg-n24 {
    margin-right: -7.5rem !important; }
  .me-lg-n25 {
    margin-right: -7.8125rem !important; }
  .me-lg-n26 {
    margin-right: -8.125rem !important; }
  .me-lg-n27 {
    margin-right: -8.4375rem !important; }
  .me-lg-n28 {
    margin-right: -8.75rem !important; }
  .me-lg-n29 {
    margin-right: -9.0625rem !important; }
  .me-lg-n30 {
    margin-right: -9.375rem !important; }
  .me-lg-n31 {
    margin-right: -9.6875rem !important; }
  .me-lg-n32 {
    margin-right: -10rem !important; }
  .me-lg-n33 {
    margin-right: -10.3125rem !important; }
  .me-lg-n34 {
    margin-right: -10.625rem !important; }
  .me-lg-n35 {
    margin-right: -10.9375rem !important; }
  .me-lg-n36 {
    margin-right: -11.25rem !important; }
  .me-lg-n37 {
    margin-right: -11.5625rem !important; }
  .me-lg-n38 {
    margin-right: -11.875rem !important; }
  .me-lg-n39 {
    margin-right: -12.1875rem !important; }
  .me-lg-n40 {
    margin-right: -12.5rem !important; }
  .me-lg-nbase {
    margin-right: -calc(20rem / 16) !important; }
  .mb-lg-n1 {
    margin-bottom: -0.3125rem !important; }
  .mb-lg-n2 {
    margin-bottom: -0.625rem !important; }
  .mb-lg-n3 {
    margin-bottom: -0.9375rem !important; }
  .mb-lg-n4 {
    margin-bottom: -1.25rem !important; }
  .mb-lg-n5 {
    margin-bottom: -1.5625rem !important; }
  .mb-lg-n6 {
    margin-bottom: -1.875rem !important; }
  .mb-lg-n7 {
    margin-bottom: -2.1875rem !important; }
  .mb-lg-n8 {
    margin-bottom: -2.5rem !important; }
  .mb-lg-n9 {
    margin-bottom: -2.8125rem !important; }
  .mb-lg-n10 {
    margin-bottom: -3.125rem !important; }
  .mb-lg-n11 {
    margin-bottom: -3.4375rem !important; }
  .mb-lg-n12 {
    margin-bottom: -3.75rem !important; }
  .mb-lg-n13 {
    margin-bottom: -4.0625rem !important; }
  .mb-lg-n14 {
    margin-bottom: -4.375rem !important; }
  .mb-lg-n15 {
    margin-bottom: -4.6875rem !important; }
  .mb-lg-n16 {
    margin-bottom: -5rem !important; }
  .mb-lg-n17 {
    margin-bottom: -5.3125rem !important; }
  .mb-lg-n18 {
    margin-bottom: -5.625rem !important; }
  .mb-lg-n19 {
    margin-bottom: -5.9375rem !important; }
  .mb-lg-n20 {
    margin-bottom: -6.25rem !important; }
  .mb-lg-n21 {
    margin-bottom: -6.5625rem !important; }
  .mb-lg-n22 {
    margin-bottom: -6.875rem !important; }
  .mb-lg-n23 {
    margin-bottom: -7.1875rem !important; }
  .mb-lg-n24 {
    margin-bottom: -7.5rem !important; }
  .mb-lg-n25 {
    margin-bottom: -7.8125rem !important; }
  .mb-lg-n26 {
    margin-bottom: -8.125rem !important; }
  .mb-lg-n27 {
    margin-bottom: -8.4375rem !important; }
  .mb-lg-n28 {
    margin-bottom: -8.75rem !important; }
  .mb-lg-n29 {
    margin-bottom: -9.0625rem !important; }
  .mb-lg-n30 {
    margin-bottom: -9.375rem !important; }
  .mb-lg-n31 {
    margin-bottom: -9.6875rem !important; }
  .mb-lg-n32 {
    margin-bottom: -10rem !important; }
  .mb-lg-n33 {
    margin-bottom: -10.3125rem !important; }
  .mb-lg-n34 {
    margin-bottom: -10.625rem !important; }
  .mb-lg-n35 {
    margin-bottom: -10.9375rem !important; }
  .mb-lg-n36 {
    margin-bottom: -11.25rem !important; }
  .mb-lg-n37 {
    margin-bottom: -11.5625rem !important; }
  .mb-lg-n38 {
    margin-bottom: -11.875rem !important; }
  .mb-lg-n39 {
    margin-bottom: -12.1875rem !important; }
  .mb-lg-n40 {
    margin-bottom: -12.5rem !important; }
  .mb-lg-nbase {
    margin-bottom: -calc(20rem / 16) !important; }
  .ms-lg-n1 {
    margin-left: -0.3125rem !important; }
  .ms-lg-n2 {
    margin-left: -0.625rem !important; }
  .ms-lg-n3 {
    margin-left: -0.9375rem !important; }
  .ms-lg-n4 {
    margin-left: -1.25rem !important; }
  .ms-lg-n5 {
    margin-left: -1.5625rem !important; }
  .ms-lg-n6 {
    margin-left: -1.875rem !important; }
  .ms-lg-n7 {
    margin-left: -2.1875rem !important; }
  .ms-lg-n8 {
    margin-left: -2.5rem !important; }
  .ms-lg-n9 {
    margin-left: -2.8125rem !important; }
  .ms-lg-n10 {
    margin-left: -3.125rem !important; }
  .ms-lg-n11 {
    margin-left: -3.4375rem !important; }
  .ms-lg-n12 {
    margin-left: -3.75rem !important; }
  .ms-lg-n13 {
    margin-left: -4.0625rem !important; }
  .ms-lg-n14 {
    margin-left: -4.375rem !important; }
  .ms-lg-n15 {
    margin-left: -4.6875rem !important; }
  .ms-lg-n16 {
    margin-left: -5rem !important; }
  .ms-lg-n17 {
    margin-left: -5.3125rem !important; }
  .ms-lg-n18 {
    margin-left: -5.625rem !important; }
  .ms-lg-n19 {
    margin-left: -5.9375rem !important; }
  .ms-lg-n20 {
    margin-left: -6.25rem !important; }
  .ms-lg-n21 {
    margin-left: -6.5625rem !important; }
  .ms-lg-n22 {
    margin-left: -6.875rem !important; }
  .ms-lg-n23 {
    margin-left: -7.1875rem !important; }
  .ms-lg-n24 {
    margin-left: -7.5rem !important; }
  .ms-lg-n25 {
    margin-left: -7.8125rem !important; }
  .ms-lg-n26 {
    margin-left: -8.125rem !important; }
  .ms-lg-n27 {
    margin-left: -8.4375rem !important; }
  .ms-lg-n28 {
    margin-left: -8.75rem !important; }
  .ms-lg-n29 {
    margin-left: -9.0625rem !important; }
  .ms-lg-n30 {
    margin-left: -9.375rem !important; }
  .ms-lg-n31 {
    margin-left: -9.6875rem !important; }
  .ms-lg-n32 {
    margin-left: -10rem !important; }
  .ms-lg-n33 {
    margin-left: -10.3125rem !important; }
  .ms-lg-n34 {
    margin-left: -10.625rem !important; }
  .ms-lg-n35 {
    margin-left: -10.9375rem !important; }
  .ms-lg-n36 {
    margin-left: -11.25rem !important; }
  .ms-lg-n37 {
    margin-left: -11.5625rem !important; }
  .ms-lg-n38 {
    margin-left: -11.875rem !important; }
  .ms-lg-n39 {
    margin-left: -12.1875rem !important; }
  .ms-lg-n40 {
    margin-left: -12.5rem !important; }
  .ms-lg-nbase {
    margin-left: -calc(20rem / 16) !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.3125rem !important; }
  .p-lg-2 {
    padding: 0.625rem !important; }
  .p-lg-3 {
    padding: 0.9375rem !important; }
  .p-lg-4 {
    padding: 1.25rem !important; }
  .p-lg-5 {
    padding: 1.5625rem !important; }
  .p-lg-6 {
    padding: 1.875rem !important; }
  .p-lg-7 {
    padding: 2.1875rem !important; }
  .p-lg-8 {
    padding: 2.5rem !important; }
  .p-lg-9 {
    padding: 2.8125rem !important; }
  .p-lg-10 {
    padding: 3.125rem !important; }
  .p-lg-11 {
    padding: 3.4375rem !important; }
  .p-lg-12 {
    padding: 3.75rem !important; }
  .p-lg-13 {
    padding: 4.0625rem !important; }
  .p-lg-14 {
    padding: 4.375rem !important; }
  .p-lg-15 {
    padding: 4.6875rem !important; }
  .p-lg-16 {
    padding: 5rem !important; }
  .p-lg-17 {
    padding: 5.3125rem !important; }
  .p-lg-18 {
    padding: 5.625rem !important; }
  .p-lg-19 {
    padding: 5.9375rem !important; }
  .p-lg-20 {
    padding: 6.25rem !important; }
  .p-lg-21 {
    padding: 6.5625rem !important; }
  .p-lg-22 {
    padding: 6.875rem !important; }
  .p-lg-23 {
    padding: 7.1875rem !important; }
  .p-lg-24 {
    padding: 7.5rem !important; }
  .p-lg-25 {
    padding: 7.8125rem !important; }
  .p-lg-26 {
    padding: 8.125rem !important; }
  .p-lg-27 {
    padding: 8.4375rem !important; }
  .p-lg-28 {
    padding: 8.75rem !important; }
  .p-lg-29 {
    padding: 9.0625rem !important; }
  .p-lg-30 {
    padding: 9.375rem !important; }
  .p-lg-31 {
    padding: 9.6875rem !important; }
  .p-lg-32 {
    padding: 10rem !important; }
  .p-lg-33 {
    padding: 10.3125rem !important; }
  .p-lg-34 {
    padding: 10.625rem !important; }
  .p-lg-35 {
    padding: 10.9375rem !important; }
  .p-lg-36 {
    padding: 11.25rem !important; }
  .p-lg-37 {
    padding: 11.5625rem !important; }
  .p-lg-38 {
    padding: 11.875rem !important; }
  .p-lg-39 {
    padding: 12.1875rem !important; }
  .p-lg-40 {
    padding: 12.5rem !important; }
  .p-lg-base {
    padding: calc(20rem / 16) !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important; }
  .px-lg-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important; }
  .px-lg-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important; }
  .px-lg-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important; }
  .px-lg-5 {
    padding-right: 1.5625rem !important;
    padding-left: 1.5625rem !important; }
  .px-lg-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important; }
  .px-lg-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important; }
  .px-lg-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-lg-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important; }
  .px-lg-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important; }
  .px-lg-11 {
    padding-right: 3.4375rem !important;
    padding-left: 3.4375rem !important; }
  .px-lg-12 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important; }
  .px-lg-13 {
    padding-right: 4.0625rem !important;
    padding-left: 4.0625rem !important; }
  .px-lg-14 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important; }
  .px-lg-15 {
    padding-right: 4.6875rem !important;
    padding-left: 4.6875rem !important; }
  .px-lg-16 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-lg-17 {
    padding-right: 5.3125rem !important;
    padding-left: 5.3125rem !important; }
  .px-lg-18 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important; }
  .px-lg-19 {
    padding-right: 5.9375rem !important;
    padding-left: 5.9375rem !important; }
  .px-lg-20 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important; }
  .px-lg-21 {
    padding-right: 6.5625rem !important;
    padding-left: 6.5625rem !important; }
  .px-lg-22 {
    padding-right: 6.875rem !important;
    padding-left: 6.875rem !important; }
  .px-lg-23 {
    padding-right: 7.1875rem !important;
    padding-left: 7.1875rem !important; }
  .px-lg-24 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-lg-25 {
    padding-right: 7.8125rem !important;
    padding-left: 7.8125rem !important; }
  .px-lg-26 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important; }
  .px-lg-27 {
    padding-right: 8.4375rem !important;
    padding-left: 8.4375rem !important; }
  .px-lg-28 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important; }
  .px-lg-29 {
    padding-right: 9.0625rem !important;
    padding-left: 9.0625rem !important; }
  .px-lg-30 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important; }
  .px-lg-31 {
    padding-right: 9.6875rem !important;
    padding-left: 9.6875rem !important; }
  .px-lg-32 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-lg-33 {
    padding-right: 10.3125rem !important;
    padding-left: 10.3125rem !important; }
  .px-lg-34 {
    padding-right: 10.625rem !important;
    padding-left: 10.625rem !important; }
  .px-lg-35 {
    padding-right: 10.9375rem !important;
    padding-left: 10.9375rem !important; }
  .px-lg-36 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important; }
  .px-lg-37 {
    padding-right: 11.5625rem !important;
    padding-left: 11.5625rem !important; }
  .px-lg-38 {
    padding-right: 11.875rem !important;
    padding-left: 11.875rem !important; }
  .px-lg-39 {
    padding-right: 12.1875rem !important;
    padding-left: 12.1875rem !important; }
  .px-lg-40 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-lg-base {
    padding-right: calc(20rem / 16) !important;
    padding-left: calc(20rem / 16) !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important; }
  .py-lg-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important; }
  .py-lg-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important; }
  .py-lg-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .py-lg-5 {
    padding-top: 1.5625rem !important;
    padding-bottom: 1.5625rem !important; }
  .py-lg-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important; }
  .py-lg-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important; }
  .py-lg-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-lg-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important; }
  .py-lg-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important; }
  .py-lg-11 {
    padding-top: 3.4375rem !important;
    padding-bottom: 3.4375rem !important; }
  .py-lg-12 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important; }
  .py-lg-13 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important; }
  .py-lg-14 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important; }
  .py-lg-15 {
    padding-top: 4.6875rem !important;
    padding-bottom: 4.6875rem !important; }
  .py-lg-16 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-lg-17 {
    padding-top: 5.3125rem !important;
    padding-bottom: 5.3125rem !important; }
  .py-lg-18 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important; }
  .py-lg-19 {
    padding-top: 5.9375rem !important;
    padding-bottom: 5.9375rem !important; }
  .py-lg-20 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important; }
  .py-lg-21 {
    padding-top: 6.5625rem !important;
    padding-bottom: 6.5625rem !important; }
  .py-lg-22 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important; }
  .py-lg-23 {
    padding-top: 7.1875rem !important;
    padding-bottom: 7.1875rem !important; }
  .py-lg-24 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-lg-25 {
    padding-top: 7.8125rem !important;
    padding-bottom: 7.8125rem !important; }
  .py-lg-26 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important; }
  .py-lg-27 {
    padding-top: 8.4375rem !important;
    padding-bottom: 8.4375rem !important; }
  .py-lg-28 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important; }
  .py-lg-29 {
    padding-top: 9.0625rem !important;
    padding-bottom: 9.0625rem !important; }
  .py-lg-30 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important; }
  .py-lg-31 {
    padding-top: 9.6875rem !important;
    padding-bottom: 9.6875rem !important; }
  .py-lg-32 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-lg-33 {
    padding-top: 10.3125rem !important;
    padding-bottom: 10.3125rem !important; }
  .py-lg-34 {
    padding-top: 10.625rem !important;
    padding-bottom: 10.625rem !important; }
  .py-lg-35 {
    padding-top: 10.9375rem !important;
    padding-bottom: 10.9375rem !important; }
  .py-lg-36 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important; }
  .py-lg-37 {
    padding-top: 11.5625rem !important;
    padding-bottom: 11.5625rem !important; }
  .py-lg-38 {
    padding-top: 11.875rem !important;
    padding-bottom: 11.875rem !important; }
  .py-lg-39 {
    padding-top: 12.1875rem !important;
    padding-bottom: 12.1875rem !important; }
  .py-lg-40 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-lg-base {
    padding-top: calc(20rem / 16) !important;
    padding-bottom: calc(20rem / 16) !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.3125rem !important; }
  .pt-lg-2 {
    padding-top: 0.625rem !important; }
  .pt-lg-3 {
    padding-top: 0.9375rem !important; }
  .pt-lg-4 {
    padding-top: 1.25rem !important; }
  .pt-lg-5 {
    padding-top: 1.5625rem !important; }
  .pt-lg-6 {
    padding-top: 1.875rem !important; }
  .pt-lg-7 {
    padding-top: 2.1875rem !important; }
  .pt-lg-8 {
    padding-top: 2.5rem !important; }
  .pt-lg-9 {
    padding-top: 2.8125rem !important; }
  .pt-lg-10 {
    padding-top: 3.125rem !important; }
  .pt-lg-11 {
    padding-top: 3.4375rem !important; }
  .pt-lg-12 {
    padding-top: 3.75rem !important; }
  .pt-lg-13 {
    padding-top: 4.0625rem !important; }
  .pt-lg-14 {
    padding-top: 4.375rem !important; }
  .pt-lg-15 {
    padding-top: 4.6875rem !important; }
  .pt-lg-16 {
    padding-top: 5rem !important; }
  .pt-lg-17 {
    padding-top: 5.3125rem !important; }
  .pt-lg-18 {
    padding-top: 5.625rem !important; }
  .pt-lg-19 {
    padding-top: 5.9375rem !important; }
  .pt-lg-20 {
    padding-top: 6.25rem !important; }
  .pt-lg-21 {
    padding-top: 6.5625rem !important; }
  .pt-lg-22 {
    padding-top: 6.875rem !important; }
  .pt-lg-23 {
    padding-top: 7.1875rem !important; }
  .pt-lg-24 {
    padding-top: 7.5rem !important; }
  .pt-lg-25 {
    padding-top: 7.8125rem !important; }
  .pt-lg-26 {
    padding-top: 8.125rem !important; }
  .pt-lg-27 {
    padding-top: 8.4375rem !important; }
  .pt-lg-28 {
    padding-top: 8.75rem !important; }
  .pt-lg-29 {
    padding-top: 9.0625rem !important; }
  .pt-lg-30 {
    padding-top: 9.375rem !important; }
  .pt-lg-31 {
    padding-top: 9.6875rem !important; }
  .pt-lg-32 {
    padding-top: 10rem !important; }
  .pt-lg-33 {
    padding-top: 10.3125rem !important; }
  .pt-lg-34 {
    padding-top: 10.625rem !important; }
  .pt-lg-35 {
    padding-top: 10.9375rem !important; }
  .pt-lg-36 {
    padding-top: 11.25rem !important; }
  .pt-lg-37 {
    padding-top: 11.5625rem !important; }
  .pt-lg-38 {
    padding-top: 11.875rem !important; }
  .pt-lg-39 {
    padding-top: 12.1875rem !important; }
  .pt-lg-40 {
    padding-top: 12.5rem !important; }
  .pt-lg-base {
    padding-top: calc(20rem / 16) !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.3125rem !important; }
  .pe-lg-2 {
    padding-right: 0.625rem !important; }
  .pe-lg-3 {
    padding-right: 0.9375rem !important; }
  .pe-lg-4 {
    padding-right: 1.25rem !important; }
  .pe-lg-5 {
    padding-right: 1.5625rem !important; }
  .pe-lg-6 {
    padding-right: 1.875rem !important; }
  .pe-lg-7 {
    padding-right: 2.1875rem !important; }
  .pe-lg-8 {
    padding-right: 2.5rem !important; }
  .pe-lg-9 {
    padding-right: 2.8125rem !important; }
  .pe-lg-10 {
    padding-right: 3.125rem !important; }
  .pe-lg-11 {
    padding-right: 3.4375rem !important; }
  .pe-lg-12 {
    padding-right: 3.75rem !important; }
  .pe-lg-13 {
    padding-right: 4.0625rem !important; }
  .pe-lg-14 {
    padding-right: 4.375rem !important; }
  .pe-lg-15 {
    padding-right: 4.6875rem !important; }
  .pe-lg-16 {
    padding-right: 5rem !important; }
  .pe-lg-17 {
    padding-right: 5.3125rem !important; }
  .pe-lg-18 {
    padding-right: 5.625rem !important; }
  .pe-lg-19 {
    padding-right: 5.9375rem !important; }
  .pe-lg-20 {
    padding-right: 6.25rem !important; }
  .pe-lg-21 {
    padding-right: 6.5625rem !important; }
  .pe-lg-22 {
    padding-right: 6.875rem !important; }
  .pe-lg-23 {
    padding-right: 7.1875rem !important; }
  .pe-lg-24 {
    padding-right: 7.5rem !important; }
  .pe-lg-25 {
    padding-right: 7.8125rem !important; }
  .pe-lg-26 {
    padding-right: 8.125rem !important; }
  .pe-lg-27 {
    padding-right: 8.4375rem !important; }
  .pe-lg-28 {
    padding-right: 8.75rem !important; }
  .pe-lg-29 {
    padding-right: 9.0625rem !important; }
  .pe-lg-30 {
    padding-right: 9.375rem !important; }
  .pe-lg-31 {
    padding-right: 9.6875rem !important; }
  .pe-lg-32 {
    padding-right: 10rem !important; }
  .pe-lg-33 {
    padding-right: 10.3125rem !important; }
  .pe-lg-34 {
    padding-right: 10.625rem !important; }
  .pe-lg-35 {
    padding-right: 10.9375rem !important; }
  .pe-lg-36 {
    padding-right: 11.25rem !important; }
  .pe-lg-37 {
    padding-right: 11.5625rem !important; }
  .pe-lg-38 {
    padding-right: 11.875rem !important; }
  .pe-lg-39 {
    padding-right: 12.1875rem !important; }
  .pe-lg-40 {
    padding-right: 12.5rem !important; }
  .pe-lg-base {
    padding-right: calc(20rem / 16) !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.3125rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.625rem !important; }
  .pb-lg-3 {
    padding-bottom: 0.9375rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.25rem !important; }
  .pb-lg-5 {
    padding-bottom: 1.5625rem !important; }
  .pb-lg-6 {
    padding-bottom: 1.875rem !important; }
  .pb-lg-7 {
    padding-bottom: 2.1875rem !important; }
  .pb-lg-8 {
    padding-bottom: 2.5rem !important; }
  .pb-lg-9 {
    padding-bottom: 2.8125rem !important; }
  .pb-lg-10 {
    padding-bottom: 3.125rem !important; }
  .pb-lg-11 {
    padding-bottom: 3.4375rem !important; }
  .pb-lg-12 {
    padding-bottom: 3.75rem !important; }
  .pb-lg-13 {
    padding-bottom: 4.0625rem !important; }
  .pb-lg-14 {
    padding-bottom: 4.375rem !important; }
  .pb-lg-15 {
    padding-bottom: 4.6875rem !important; }
  .pb-lg-16 {
    padding-bottom: 5rem !important; }
  .pb-lg-17 {
    padding-bottom: 5.3125rem !important; }
  .pb-lg-18 {
    padding-bottom: 5.625rem !important; }
  .pb-lg-19 {
    padding-bottom: 5.9375rem !important; }
  .pb-lg-20 {
    padding-bottom: 6.25rem !important; }
  .pb-lg-21 {
    padding-bottom: 6.5625rem !important; }
  .pb-lg-22 {
    padding-bottom: 6.875rem !important; }
  .pb-lg-23 {
    padding-bottom: 7.1875rem !important; }
  .pb-lg-24 {
    padding-bottom: 7.5rem !important; }
  .pb-lg-25 {
    padding-bottom: 7.8125rem !important; }
  .pb-lg-26 {
    padding-bottom: 8.125rem !important; }
  .pb-lg-27 {
    padding-bottom: 8.4375rem !important; }
  .pb-lg-28 {
    padding-bottom: 8.75rem !important; }
  .pb-lg-29 {
    padding-bottom: 9.0625rem !important; }
  .pb-lg-30 {
    padding-bottom: 9.375rem !important; }
  .pb-lg-31 {
    padding-bottom: 9.6875rem !important; }
  .pb-lg-32 {
    padding-bottom: 10rem !important; }
  .pb-lg-33 {
    padding-bottom: 10.3125rem !important; }
  .pb-lg-34 {
    padding-bottom: 10.625rem !important; }
  .pb-lg-35 {
    padding-bottom: 10.9375rem !important; }
  .pb-lg-36 {
    padding-bottom: 11.25rem !important; }
  .pb-lg-37 {
    padding-bottom: 11.5625rem !important; }
  .pb-lg-38 {
    padding-bottom: 11.875rem !important; }
  .pb-lg-39 {
    padding-bottom: 12.1875rem !important; }
  .pb-lg-40 {
    padding-bottom: 12.5rem !important; }
  .pb-lg-base {
    padding-bottom: calc(20rem / 16) !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.3125rem !important; }
  .ps-lg-2 {
    padding-left: 0.625rem !important; }
  .ps-lg-3 {
    padding-left: 0.9375rem !important; }
  .ps-lg-4 {
    padding-left: 1.25rem !important; }
  .ps-lg-5 {
    padding-left: 1.5625rem !important; }
  .ps-lg-6 {
    padding-left: 1.875rem !important; }
  .ps-lg-7 {
    padding-left: 2.1875rem !important; }
  .ps-lg-8 {
    padding-left: 2.5rem !important; }
  .ps-lg-9 {
    padding-left: 2.8125rem !important; }
  .ps-lg-10 {
    padding-left: 3.125rem !important; }
  .ps-lg-11 {
    padding-left: 3.4375rem !important; }
  .ps-lg-12 {
    padding-left: 3.75rem !important; }
  .ps-lg-13 {
    padding-left: 4.0625rem !important; }
  .ps-lg-14 {
    padding-left: 4.375rem !important; }
  .ps-lg-15 {
    padding-left: 4.6875rem !important; }
  .ps-lg-16 {
    padding-left: 5rem !important; }
  .ps-lg-17 {
    padding-left: 5.3125rem !important; }
  .ps-lg-18 {
    padding-left: 5.625rem !important; }
  .ps-lg-19 {
    padding-left: 5.9375rem !important; }
  .ps-lg-20 {
    padding-left: 6.25rem !important; }
  .ps-lg-21 {
    padding-left: 6.5625rem !important; }
  .ps-lg-22 {
    padding-left: 6.875rem !important; }
  .ps-lg-23 {
    padding-left: 7.1875rem !important; }
  .ps-lg-24 {
    padding-left: 7.5rem !important; }
  .ps-lg-25 {
    padding-left: 7.8125rem !important; }
  .ps-lg-26 {
    padding-left: 8.125rem !important; }
  .ps-lg-27 {
    padding-left: 8.4375rem !important; }
  .ps-lg-28 {
    padding-left: 8.75rem !important; }
  .ps-lg-29 {
    padding-left: 9.0625rem !important; }
  .ps-lg-30 {
    padding-left: 9.375rem !important; }
  .ps-lg-31 {
    padding-left: 9.6875rem !important; }
  .ps-lg-32 {
    padding-left: 10rem !important; }
  .ps-lg-33 {
    padding-left: 10.3125rem !important; }
  .ps-lg-34 {
    padding-left: 10.625rem !important; }
  .ps-lg-35 {
    padding-left: 10.9375rem !important; }
  .ps-lg-36 {
    padding-left: 11.25rem !important; }
  .ps-lg-37 {
    padding-left: 11.5625rem !important; }
  .ps-lg-38 {
    padding-left: 11.875rem !important; }
  .ps-lg-39 {
    padding-left: 12.1875rem !important; }
  .ps-lg-40 {
    padding-left: 12.5rem !important; }
  .ps-lg-base {
    padding-left: calc(20rem / 16) !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.3125rem !important; }
  .gap-lg-2 {
    gap: 0.625rem !important; }
  .gap-lg-3 {
    gap: 0.9375rem !important; }
  .gap-lg-4 {
    gap: 1.25rem !important; }
  .gap-lg-5 {
    gap: 1.5625rem !important; }
  .gap-lg-6 {
    gap: 1.875rem !important; }
  .gap-lg-7 {
    gap: 2.1875rem !important; }
  .gap-lg-8 {
    gap: 2.5rem !important; }
  .gap-lg-9 {
    gap: 2.8125rem !important; }
  .gap-lg-10 {
    gap: 3.125rem !important; }
  .gap-lg-11 {
    gap: 3.4375rem !important; }
  .gap-lg-12 {
    gap: 3.75rem !important; }
  .gap-lg-13 {
    gap: 4.0625rem !important; }
  .gap-lg-14 {
    gap: 4.375rem !important; }
  .gap-lg-15 {
    gap: 4.6875rem !important; }
  .gap-lg-16 {
    gap: 5rem !important; }
  .gap-lg-17 {
    gap: 5.3125rem !important; }
  .gap-lg-18 {
    gap: 5.625rem !important; }
  .gap-lg-19 {
    gap: 5.9375rem !important; }
  .gap-lg-20 {
    gap: 6.25rem !important; }
  .gap-lg-21 {
    gap: 6.5625rem !important; }
  .gap-lg-22 {
    gap: 6.875rem !important; }
  .gap-lg-23 {
    gap: 7.1875rem !important; }
  .gap-lg-24 {
    gap: 7.5rem !important; }
  .gap-lg-25 {
    gap: 7.8125rem !important; }
  .gap-lg-26 {
    gap: 8.125rem !important; }
  .gap-lg-27 {
    gap: 8.4375rem !important; }
  .gap-lg-28 {
    gap: 8.75rem !important; }
  .gap-lg-29 {
    gap: 9.0625rem !important; }
  .gap-lg-30 {
    gap: 9.375rem !important; }
  .gap-lg-31 {
    gap: 9.6875rem !important; }
  .gap-lg-32 {
    gap: 10rem !important; }
  .gap-lg-33 {
    gap: 10.3125rem !important; }
  .gap-lg-34 {
    gap: 10.625rem !important; }
  .gap-lg-35 {
    gap: 10.9375rem !important; }
  .gap-lg-36 {
    gap: 11.25rem !important; }
  .gap-lg-37 {
    gap: 11.5625rem !important; }
  .gap-lg-38 {
    gap: 11.875rem !important; }
  .gap-lg-39 {
    gap: 12.1875rem !important; }
  .gap-lg-40 {
    gap: 12.5rem !important; }
  .gap-lg-base {
    gap: calc(20rem / 16) !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.3125rem !important; }
  .m-xl-2 {
    margin: 0.625rem !important; }
  .m-xl-3 {
    margin: 0.9375rem !important; }
  .m-xl-4 {
    margin: 1.25rem !important; }
  .m-xl-5 {
    margin: 1.5625rem !important; }
  .m-xl-6 {
    margin: 1.875rem !important; }
  .m-xl-7 {
    margin: 2.1875rem !important; }
  .m-xl-8 {
    margin: 2.5rem !important; }
  .m-xl-9 {
    margin: 2.8125rem !important; }
  .m-xl-10 {
    margin: 3.125rem !important; }
  .m-xl-11 {
    margin: 3.4375rem !important; }
  .m-xl-12 {
    margin: 3.75rem !important; }
  .m-xl-13 {
    margin: 4.0625rem !important; }
  .m-xl-14 {
    margin: 4.375rem !important; }
  .m-xl-15 {
    margin: 4.6875rem !important; }
  .m-xl-16 {
    margin: 5rem !important; }
  .m-xl-17 {
    margin: 5.3125rem !important; }
  .m-xl-18 {
    margin: 5.625rem !important; }
  .m-xl-19 {
    margin: 5.9375rem !important; }
  .m-xl-20 {
    margin: 6.25rem !important; }
  .m-xl-21 {
    margin: 6.5625rem !important; }
  .m-xl-22 {
    margin: 6.875rem !important; }
  .m-xl-23 {
    margin: 7.1875rem !important; }
  .m-xl-24 {
    margin: 7.5rem !important; }
  .m-xl-25 {
    margin: 7.8125rem !important; }
  .m-xl-26 {
    margin: 8.125rem !important; }
  .m-xl-27 {
    margin: 8.4375rem !important; }
  .m-xl-28 {
    margin: 8.75rem !important; }
  .m-xl-29 {
    margin: 9.0625rem !important; }
  .m-xl-30 {
    margin: 9.375rem !important; }
  .m-xl-31 {
    margin: 9.6875rem !important; }
  .m-xl-32 {
    margin: 10rem !important; }
  .m-xl-33 {
    margin: 10.3125rem !important; }
  .m-xl-34 {
    margin: 10.625rem !important; }
  .m-xl-35 {
    margin: 10.9375rem !important; }
  .m-xl-36 {
    margin: 11.25rem !important; }
  .m-xl-37 {
    margin: 11.5625rem !important; }
  .m-xl-38 {
    margin: 11.875rem !important; }
  .m-xl-39 {
    margin: 12.1875rem !important; }
  .m-xl-40 {
    margin: 12.5rem !important; }
  .m-xl-base {
    margin: calc(20rem / 16) !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important; }
  .mx-xl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important; }
  .mx-xl-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important; }
  .mx-xl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important; }
  .mx-xl-5 {
    margin-right: 1.5625rem !important;
    margin-left: 1.5625rem !important; }
  .mx-xl-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important; }
  .mx-xl-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important; }
  .mx-xl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-xl-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important; }
  .mx-xl-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important; }
  .mx-xl-11 {
    margin-right: 3.4375rem !important;
    margin-left: 3.4375rem !important; }
  .mx-xl-12 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important; }
  .mx-xl-13 {
    margin-right: 4.0625rem !important;
    margin-left: 4.0625rem !important; }
  .mx-xl-14 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important; }
  .mx-xl-15 {
    margin-right: 4.6875rem !important;
    margin-left: 4.6875rem !important; }
  .mx-xl-16 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-xl-17 {
    margin-right: 5.3125rem !important;
    margin-left: 5.3125rem !important; }
  .mx-xl-18 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important; }
  .mx-xl-19 {
    margin-right: 5.9375rem !important;
    margin-left: 5.9375rem !important; }
  .mx-xl-20 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important; }
  .mx-xl-21 {
    margin-right: 6.5625rem !important;
    margin-left: 6.5625rem !important; }
  .mx-xl-22 {
    margin-right: 6.875rem !important;
    margin-left: 6.875rem !important; }
  .mx-xl-23 {
    margin-right: 7.1875rem !important;
    margin-left: 7.1875rem !important; }
  .mx-xl-24 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-xl-25 {
    margin-right: 7.8125rem !important;
    margin-left: 7.8125rem !important; }
  .mx-xl-26 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important; }
  .mx-xl-27 {
    margin-right: 8.4375rem !important;
    margin-left: 8.4375rem !important; }
  .mx-xl-28 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important; }
  .mx-xl-29 {
    margin-right: 9.0625rem !important;
    margin-left: 9.0625rem !important; }
  .mx-xl-30 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important; }
  .mx-xl-31 {
    margin-right: 9.6875rem !important;
    margin-left: 9.6875rem !important; }
  .mx-xl-32 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-xl-33 {
    margin-right: 10.3125rem !important;
    margin-left: 10.3125rem !important; }
  .mx-xl-34 {
    margin-right: 10.625rem !important;
    margin-left: 10.625rem !important; }
  .mx-xl-35 {
    margin-right: 10.9375rem !important;
    margin-left: 10.9375rem !important; }
  .mx-xl-36 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important; }
  .mx-xl-37 {
    margin-right: 11.5625rem !important;
    margin-left: 11.5625rem !important; }
  .mx-xl-38 {
    margin-right: 11.875rem !important;
    margin-left: 11.875rem !important; }
  .mx-xl-39 {
    margin-right: 12.1875rem !important;
    margin-left: 12.1875rem !important; }
  .mx-xl-40 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-xl-base {
    margin-right: calc(20rem / 16) !important;
    margin-left: calc(20rem / 16) !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important; }
  .my-xl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important; }
  .my-xl-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important; }
  .my-xl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .my-xl-5 {
    margin-top: 1.5625rem !important;
    margin-bottom: 1.5625rem !important; }
  .my-xl-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important; }
  .my-xl-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important; }
  .my-xl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-xl-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important; }
  .my-xl-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important; }
  .my-xl-11 {
    margin-top: 3.4375rem !important;
    margin-bottom: 3.4375rem !important; }
  .my-xl-12 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important; }
  .my-xl-13 {
    margin-top: 4.0625rem !important;
    margin-bottom: 4.0625rem !important; }
  .my-xl-14 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important; }
  .my-xl-15 {
    margin-top: 4.6875rem !important;
    margin-bottom: 4.6875rem !important; }
  .my-xl-16 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-xl-17 {
    margin-top: 5.3125rem !important;
    margin-bottom: 5.3125rem !important; }
  .my-xl-18 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important; }
  .my-xl-19 {
    margin-top: 5.9375rem !important;
    margin-bottom: 5.9375rem !important; }
  .my-xl-20 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important; }
  .my-xl-21 {
    margin-top: 6.5625rem !important;
    margin-bottom: 6.5625rem !important; }
  .my-xl-22 {
    margin-top: 6.875rem !important;
    margin-bottom: 6.875rem !important; }
  .my-xl-23 {
    margin-top: 7.1875rem !important;
    margin-bottom: 7.1875rem !important; }
  .my-xl-24 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-xl-25 {
    margin-top: 7.8125rem !important;
    margin-bottom: 7.8125rem !important; }
  .my-xl-26 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important; }
  .my-xl-27 {
    margin-top: 8.4375rem !important;
    margin-bottom: 8.4375rem !important; }
  .my-xl-28 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important; }
  .my-xl-29 {
    margin-top: 9.0625rem !important;
    margin-bottom: 9.0625rem !important; }
  .my-xl-30 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important; }
  .my-xl-31 {
    margin-top: 9.6875rem !important;
    margin-bottom: 9.6875rem !important; }
  .my-xl-32 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-xl-33 {
    margin-top: 10.3125rem !important;
    margin-bottom: 10.3125rem !important; }
  .my-xl-34 {
    margin-top: 10.625rem !important;
    margin-bottom: 10.625rem !important; }
  .my-xl-35 {
    margin-top: 10.9375rem !important;
    margin-bottom: 10.9375rem !important; }
  .my-xl-36 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important; }
  .my-xl-37 {
    margin-top: 11.5625rem !important;
    margin-bottom: 11.5625rem !important; }
  .my-xl-38 {
    margin-top: 11.875rem !important;
    margin-bottom: 11.875rem !important; }
  .my-xl-39 {
    margin-top: 12.1875rem !important;
    margin-bottom: 12.1875rem !important; }
  .my-xl-40 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-xl-base {
    margin-top: calc(20rem / 16) !important;
    margin-bottom: calc(20rem / 16) !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.3125rem !important; }
  .mt-xl-2 {
    margin-top: 0.625rem !important; }
  .mt-xl-3 {
    margin-top: 0.9375rem !important; }
  .mt-xl-4 {
    margin-top: 1.25rem !important; }
  .mt-xl-5 {
    margin-top: 1.5625rem !important; }
  .mt-xl-6 {
    margin-top: 1.875rem !important; }
  .mt-xl-7 {
    margin-top: 2.1875rem !important; }
  .mt-xl-8 {
    margin-top: 2.5rem !important; }
  .mt-xl-9 {
    margin-top: 2.8125rem !important; }
  .mt-xl-10 {
    margin-top: 3.125rem !important; }
  .mt-xl-11 {
    margin-top: 3.4375rem !important; }
  .mt-xl-12 {
    margin-top: 3.75rem !important; }
  .mt-xl-13 {
    margin-top: 4.0625rem !important; }
  .mt-xl-14 {
    margin-top: 4.375rem !important; }
  .mt-xl-15 {
    margin-top: 4.6875rem !important; }
  .mt-xl-16 {
    margin-top: 5rem !important; }
  .mt-xl-17 {
    margin-top: 5.3125rem !important; }
  .mt-xl-18 {
    margin-top: 5.625rem !important; }
  .mt-xl-19 {
    margin-top: 5.9375rem !important; }
  .mt-xl-20 {
    margin-top: 6.25rem !important; }
  .mt-xl-21 {
    margin-top: 6.5625rem !important; }
  .mt-xl-22 {
    margin-top: 6.875rem !important; }
  .mt-xl-23 {
    margin-top: 7.1875rem !important; }
  .mt-xl-24 {
    margin-top: 7.5rem !important; }
  .mt-xl-25 {
    margin-top: 7.8125rem !important; }
  .mt-xl-26 {
    margin-top: 8.125rem !important; }
  .mt-xl-27 {
    margin-top: 8.4375rem !important; }
  .mt-xl-28 {
    margin-top: 8.75rem !important; }
  .mt-xl-29 {
    margin-top: 9.0625rem !important; }
  .mt-xl-30 {
    margin-top: 9.375rem !important; }
  .mt-xl-31 {
    margin-top: 9.6875rem !important; }
  .mt-xl-32 {
    margin-top: 10rem !important; }
  .mt-xl-33 {
    margin-top: 10.3125rem !important; }
  .mt-xl-34 {
    margin-top: 10.625rem !important; }
  .mt-xl-35 {
    margin-top: 10.9375rem !important; }
  .mt-xl-36 {
    margin-top: 11.25rem !important; }
  .mt-xl-37 {
    margin-top: 11.5625rem !important; }
  .mt-xl-38 {
    margin-top: 11.875rem !important; }
  .mt-xl-39 {
    margin-top: 12.1875rem !important; }
  .mt-xl-40 {
    margin-top: 12.5rem !important; }
  .mt-xl-base {
    margin-top: calc(20rem / 16) !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.3125rem !important; }
  .me-xl-2 {
    margin-right: 0.625rem !important; }
  .me-xl-3 {
    margin-right: 0.9375rem !important; }
  .me-xl-4 {
    margin-right: 1.25rem !important; }
  .me-xl-5 {
    margin-right: 1.5625rem !important; }
  .me-xl-6 {
    margin-right: 1.875rem !important; }
  .me-xl-7 {
    margin-right: 2.1875rem !important; }
  .me-xl-8 {
    margin-right: 2.5rem !important; }
  .me-xl-9 {
    margin-right: 2.8125rem !important; }
  .me-xl-10 {
    margin-right: 3.125rem !important; }
  .me-xl-11 {
    margin-right: 3.4375rem !important; }
  .me-xl-12 {
    margin-right: 3.75rem !important; }
  .me-xl-13 {
    margin-right: 4.0625rem !important; }
  .me-xl-14 {
    margin-right: 4.375rem !important; }
  .me-xl-15 {
    margin-right: 4.6875rem !important; }
  .me-xl-16 {
    margin-right: 5rem !important; }
  .me-xl-17 {
    margin-right: 5.3125rem !important; }
  .me-xl-18 {
    margin-right: 5.625rem !important; }
  .me-xl-19 {
    margin-right: 5.9375rem !important; }
  .me-xl-20 {
    margin-right: 6.25rem !important; }
  .me-xl-21 {
    margin-right: 6.5625rem !important; }
  .me-xl-22 {
    margin-right: 6.875rem !important; }
  .me-xl-23 {
    margin-right: 7.1875rem !important; }
  .me-xl-24 {
    margin-right: 7.5rem !important; }
  .me-xl-25 {
    margin-right: 7.8125rem !important; }
  .me-xl-26 {
    margin-right: 8.125rem !important; }
  .me-xl-27 {
    margin-right: 8.4375rem !important; }
  .me-xl-28 {
    margin-right: 8.75rem !important; }
  .me-xl-29 {
    margin-right: 9.0625rem !important; }
  .me-xl-30 {
    margin-right: 9.375rem !important; }
  .me-xl-31 {
    margin-right: 9.6875rem !important; }
  .me-xl-32 {
    margin-right: 10rem !important; }
  .me-xl-33 {
    margin-right: 10.3125rem !important; }
  .me-xl-34 {
    margin-right: 10.625rem !important; }
  .me-xl-35 {
    margin-right: 10.9375rem !important; }
  .me-xl-36 {
    margin-right: 11.25rem !important; }
  .me-xl-37 {
    margin-right: 11.5625rem !important; }
  .me-xl-38 {
    margin-right: 11.875rem !important; }
  .me-xl-39 {
    margin-right: 12.1875rem !important; }
  .me-xl-40 {
    margin-right: 12.5rem !important; }
  .me-xl-base {
    margin-right: calc(20rem / 16) !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.3125rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.625rem !important; }
  .mb-xl-3 {
    margin-bottom: 0.9375rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.25rem !important; }
  .mb-xl-5 {
    margin-bottom: 1.5625rem !important; }
  .mb-xl-6 {
    margin-bottom: 1.875rem !important; }
  .mb-xl-7 {
    margin-bottom: 2.1875rem !important; }
  .mb-xl-8 {
    margin-bottom: 2.5rem !important; }
  .mb-xl-9 {
    margin-bottom: 2.8125rem !important; }
  .mb-xl-10 {
    margin-bottom: 3.125rem !important; }
  .mb-xl-11 {
    margin-bottom: 3.4375rem !important; }
  .mb-xl-12 {
    margin-bottom: 3.75rem !important; }
  .mb-xl-13 {
    margin-bottom: 4.0625rem !important; }
  .mb-xl-14 {
    margin-bottom: 4.375rem !important; }
  .mb-xl-15 {
    margin-bottom: 4.6875rem !important; }
  .mb-xl-16 {
    margin-bottom: 5rem !important; }
  .mb-xl-17 {
    margin-bottom: 5.3125rem !important; }
  .mb-xl-18 {
    margin-bottom: 5.625rem !important; }
  .mb-xl-19 {
    margin-bottom: 5.9375rem !important; }
  .mb-xl-20 {
    margin-bottom: 6.25rem !important; }
  .mb-xl-21 {
    margin-bottom: 6.5625rem !important; }
  .mb-xl-22 {
    margin-bottom: 6.875rem !important; }
  .mb-xl-23 {
    margin-bottom: 7.1875rem !important; }
  .mb-xl-24 {
    margin-bottom: 7.5rem !important; }
  .mb-xl-25 {
    margin-bottom: 7.8125rem !important; }
  .mb-xl-26 {
    margin-bottom: 8.125rem !important; }
  .mb-xl-27 {
    margin-bottom: 8.4375rem !important; }
  .mb-xl-28 {
    margin-bottom: 8.75rem !important; }
  .mb-xl-29 {
    margin-bottom: 9.0625rem !important; }
  .mb-xl-30 {
    margin-bottom: 9.375rem !important; }
  .mb-xl-31 {
    margin-bottom: 9.6875rem !important; }
  .mb-xl-32 {
    margin-bottom: 10rem !important; }
  .mb-xl-33 {
    margin-bottom: 10.3125rem !important; }
  .mb-xl-34 {
    margin-bottom: 10.625rem !important; }
  .mb-xl-35 {
    margin-bottom: 10.9375rem !important; }
  .mb-xl-36 {
    margin-bottom: 11.25rem !important; }
  .mb-xl-37 {
    margin-bottom: 11.5625rem !important; }
  .mb-xl-38 {
    margin-bottom: 11.875rem !important; }
  .mb-xl-39 {
    margin-bottom: 12.1875rem !important; }
  .mb-xl-40 {
    margin-bottom: 12.5rem !important; }
  .mb-xl-base {
    margin-bottom: calc(20rem / 16) !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.3125rem !important; }
  .ms-xl-2 {
    margin-left: 0.625rem !important; }
  .ms-xl-3 {
    margin-left: 0.9375rem !important; }
  .ms-xl-4 {
    margin-left: 1.25rem !important; }
  .ms-xl-5 {
    margin-left: 1.5625rem !important; }
  .ms-xl-6 {
    margin-left: 1.875rem !important; }
  .ms-xl-7 {
    margin-left: 2.1875rem !important; }
  .ms-xl-8 {
    margin-left: 2.5rem !important; }
  .ms-xl-9 {
    margin-left: 2.8125rem !important; }
  .ms-xl-10 {
    margin-left: 3.125rem !important; }
  .ms-xl-11 {
    margin-left: 3.4375rem !important; }
  .ms-xl-12 {
    margin-left: 3.75rem !important; }
  .ms-xl-13 {
    margin-left: 4.0625rem !important; }
  .ms-xl-14 {
    margin-left: 4.375rem !important; }
  .ms-xl-15 {
    margin-left: 4.6875rem !important; }
  .ms-xl-16 {
    margin-left: 5rem !important; }
  .ms-xl-17 {
    margin-left: 5.3125rem !important; }
  .ms-xl-18 {
    margin-left: 5.625rem !important; }
  .ms-xl-19 {
    margin-left: 5.9375rem !important; }
  .ms-xl-20 {
    margin-left: 6.25rem !important; }
  .ms-xl-21 {
    margin-left: 6.5625rem !important; }
  .ms-xl-22 {
    margin-left: 6.875rem !important; }
  .ms-xl-23 {
    margin-left: 7.1875rem !important; }
  .ms-xl-24 {
    margin-left: 7.5rem !important; }
  .ms-xl-25 {
    margin-left: 7.8125rem !important; }
  .ms-xl-26 {
    margin-left: 8.125rem !important; }
  .ms-xl-27 {
    margin-left: 8.4375rem !important; }
  .ms-xl-28 {
    margin-left: 8.75rem !important; }
  .ms-xl-29 {
    margin-left: 9.0625rem !important; }
  .ms-xl-30 {
    margin-left: 9.375rem !important; }
  .ms-xl-31 {
    margin-left: 9.6875rem !important; }
  .ms-xl-32 {
    margin-left: 10rem !important; }
  .ms-xl-33 {
    margin-left: 10.3125rem !important; }
  .ms-xl-34 {
    margin-left: 10.625rem !important; }
  .ms-xl-35 {
    margin-left: 10.9375rem !important; }
  .ms-xl-36 {
    margin-left: 11.25rem !important; }
  .ms-xl-37 {
    margin-left: 11.5625rem !important; }
  .ms-xl-38 {
    margin-left: 11.875rem !important; }
  .ms-xl-39 {
    margin-left: 12.1875rem !important; }
  .ms-xl-40 {
    margin-left: 12.5rem !important; }
  .ms-xl-base {
    margin-left: calc(20rem / 16) !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .m-xl-n1 {
    margin: -0.3125rem !important; }
  .m-xl-n2 {
    margin: -0.625rem !important; }
  .m-xl-n3 {
    margin: -0.9375rem !important; }
  .m-xl-n4 {
    margin: -1.25rem !important; }
  .m-xl-n5 {
    margin: -1.5625rem !important; }
  .m-xl-n6 {
    margin: -1.875rem !important; }
  .m-xl-n7 {
    margin: -2.1875rem !important; }
  .m-xl-n8 {
    margin: -2.5rem !important; }
  .m-xl-n9 {
    margin: -2.8125rem !important; }
  .m-xl-n10 {
    margin: -3.125rem !important; }
  .m-xl-n11 {
    margin: -3.4375rem !important; }
  .m-xl-n12 {
    margin: -3.75rem !important; }
  .m-xl-n13 {
    margin: -4.0625rem !important; }
  .m-xl-n14 {
    margin: -4.375rem !important; }
  .m-xl-n15 {
    margin: -4.6875rem !important; }
  .m-xl-n16 {
    margin: -5rem !important; }
  .m-xl-n17 {
    margin: -5.3125rem !important; }
  .m-xl-n18 {
    margin: -5.625rem !important; }
  .m-xl-n19 {
    margin: -5.9375rem !important; }
  .m-xl-n20 {
    margin: -6.25rem !important; }
  .m-xl-n21 {
    margin: -6.5625rem !important; }
  .m-xl-n22 {
    margin: -6.875rem !important; }
  .m-xl-n23 {
    margin: -7.1875rem !important; }
  .m-xl-n24 {
    margin: -7.5rem !important; }
  .m-xl-n25 {
    margin: -7.8125rem !important; }
  .m-xl-n26 {
    margin: -8.125rem !important; }
  .m-xl-n27 {
    margin: -8.4375rem !important; }
  .m-xl-n28 {
    margin: -8.75rem !important; }
  .m-xl-n29 {
    margin: -9.0625rem !important; }
  .m-xl-n30 {
    margin: -9.375rem !important; }
  .m-xl-n31 {
    margin: -9.6875rem !important; }
  .m-xl-n32 {
    margin: -10rem !important; }
  .m-xl-n33 {
    margin: -10.3125rem !important; }
  .m-xl-n34 {
    margin: -10.625rem !important; }
  .m-xl-n35 {
    margin: -10.9375rem !important; }
  .m-xl-n36 {
    margin: -11.25rem !important; }
  .m-xl-n37 {
    margin: -11.5625rem !important; }
  .m-xl-n38 {
    margin: -11.875rem !important; }
  .m-xl-n39 {
    margin: -12.1875rem !important; }
  .m-xl-n40 {
    margin: -12.5rem !important; }
  .m-xl-nbase {
    margin: -calc(20rem / 16) !important; }
  .mx-xl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important; }
  .mx-xl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important; }
  .mx-xl-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important; }
  .mx-xl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important; }
  .mx-xl-n5 {
    margin-right: -1.5625rem !important;
    margin-left: -1.5625rem !important; }
  .mx-xl-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important; }
  .mx-xl-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important; }
  .mx-xl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-xl-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important; }
  .mx-xl-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important; }
  .mx-xl-n11 {
    margin-right: -3.4375rem !important;
    margin-left: -3.4375rem !important; }
  .mx-xl-n12 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important; }
  .mx-xl-n13 {
    margin-right: -4.0625rem !important;
    margin-left: -4.0625rem !important; }
  .mx-xl-n14 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important; }
  .mx-xl-n15 {
    margin-right: -4.6875rem !important;
    margin-left: -4.6875rem !important; }
  .mx-xl-n16 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-xl-n17 {
    margin-right: -5.3125rem !important;
    margin-left: -5.3125rem !important; }
  .mx-xl-n18 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important; }
  .mx-xl-n19 {
    margin-right: -5.9375rem !important;
    margin-left: -5.9375rem !important; }
  .mx-xl-n20 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important; }
  .mx-xl-n21 {
    margin-right: -6.5625rem !important;
    margin-left: -6.5625rem !important; }
  .mx-xl-n22 {
    margin-right: -6.875rem !important;
    margin-left: -6.875rem !important; }
  .mx-xl-n23 {
    margin-right: -7.1875rem !important;
    margin-left: -7.1875rem !important; }
  .mx-xl-n24 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-xl-n25 {
    margin-right: -7.8125rem !important;
    margin-left: -7.8125rem !important; }
  .mx-xl-n26 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important; }
  .mx-xl-n27 {
    margin-right: -8.4375rem !important;
    margin-left: -8.4375rem !important; }
  .mx-xl-n28 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important; }
  .mx-xl-n29 {
    margin-right: -9.0625rem !important;
    margin-left: -9.0625rem !important; }
  .mx-xl-n30 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important; }
  .mx-xl-n31 {
    margin-right: -9.6875rem !important;
    margin-left: -9.6875rem !important; }
  .mx-xl-n32 {
    margin-right: -10rem !important;
    margin-left: -10rem !important; }
  .mx-xl-n33 {
    margin-right: -10.3125rem !important;
    margin-left: -10.3125rem !important; }
  .mx-xl-n34 {
    margin-right: -10.625rem !important;
    margin-left: -10.625rem !important; }
  .mx-xl-n35 {
    margin-right: -10.9375rem !important;
    margin-left: -10.9375rem !important; }
  .mx-xl-n36 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important; }
  .mx-xl-n37 {
    margin-right: -11.5625rem !important;
    margin-left: -11.5625rem !important; }
  .mx-xl-n38 {
    margin-right: -11.875rem !important;
    margin-left: -11.875rem !important; }
  .mx-xl-n39 {
    margin-right: -12.1875rem !important;
    margin-left: -12.1875rem !important; }
  .mx-xl-n40 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important; }
  .mx-xl-nbase {
    margin-right: -calc(20rem / 16) !important;
    margin-left: -calc(20rem / 16) !important; }
  .my-xl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important; }
  .my-xl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important; }
  .my-xl-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important; }
  .my-xl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .my-xl-n5 {
    margin-top: -1.5625rem !important;
    margin-bottom: -1.5625rem !important; }
  .my-xl-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important; }
  .my-xl-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important; }
  .my-xl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-xl-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important; }
  .my-xl-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important; }
  .my-xl-n11 {
    margin-top: -3.4375rem !important;
    margin-bottom: -3.4375rem !important; }
  .my-xl-n12 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important; }
  .my-xl-n13 {
    margin-top: -4.0625rem !important;
    margin-bottom: -4.0625rem !important; }
  .my-xl-n14 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important; }
  .my-xl-n15 {
    margin-top: -4.6875rem !important;
    margin-bottom: -4.6875rem !important; }
  .my-xl-n16 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-xl-n17 {
    margin-top: -5.3125rem !important;
    margin-bottom: -5.3125rem !important; }
  .my-xl-n18 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important; }
  .my-xl-n19 {
    margin-top: -5.9375rem !important;
    margin-bottom: -5.9375rem !important; }
  .my-xl-n20 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important; }
  .my-xl-n21 {
    margin-top: -6.5625rem !important;
    margin-bottom: -6.5625rem !important; }
  .my-xl-n22 {
    margin-top: -6.875rem !important;
    margin-bottom: -6.875rem !important; }
  .my-xl-n23 {
    margin-top: -7.1875rem !important;
    margin-bottom: -7.1875rem !important; }
  .my-xl-n24 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-xl-n25 {
    margin-top: -7.8125rem !important;
    margin-bottom: -7.8125rem !important; }
  .my-xl-n26 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important; }
  .my-xl-n27 {
    margin-top: -8.4375rem !important;
    margin-bottom: -8.4375rem !important; }
  .my-xl-n28 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important; }
  .my-xl-n29 {
    margin-top: -9.0625rem !important;
    margin-bottom: -9.0625rem !important; }
  .my-xl-n30 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important; }
  .my-xl-n31 {
    margin-top: -9.6875rem !important;
    margin-bottom: -9.6875rem !important; }
  .my-xl-n32 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .my-xl-n33 {
    margin-top: -10.3125rem !important;
    margin-bottom: -10.3125rem !important; }
  .my-xl-n34 {
    margin-top: -10.625rem !important;
    margin-bottom: -10.625rem !important; }
  .my-xl-n35 {
    margin-top: -10.9375rem !important;
    margin-bottom: -10.9375rem !important; }
  .my-xl-n36 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important; }
  .my-xl-n37 {
    margin-top: -11.5625rem !important;
    margin-bottom: -11.5625rem !important; }
  .my-xl-n38 {
    margin-top: -11.875rem !important;
    margin-bottom: -11.875rem !important; }
  .my-xl-n39 {
    margin-top: -12.1875rem !important;
    margin-bottom: -12.1875rem !important; }
  .my-xl-n40 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important; }
  .my-xl-nbase {
    margin-top: -calc(20rem / 16) !important;
    margin-bottom: -calc(20rem / 16) !important; }
  .mt-xl-n1 {
    margin-top: -0.3125rem !important; }
  .mt-xl-n2 {
    margin-top: -0.625rem !important; }
  .mt-xl-n3 {
    margin-top: -0.9375rem !important; }
  .mt-xl-n4 {
    margin-top: -1.25rem !important; }
  .mt-xl-n5 {
    margin-top: -1.5625rem !important; }
  .mt-xl-n6 {
    margin-top: -1.875rem !important; }
  .mt-xl-n7 {
    margin-top: -2.1875rem !important; }
  .mt-xl-n8 {
    margin-top: -2.5rem !important; }
  .mt-xl-n9 {
    margin-top: -2.8125rem !important; }
  .mt-xl-n10 {
    margin-top: -3.125rem !important; }
  .mt-xl-n11 {
    margin-top: -3.4375rem !important; }
  .mt-xl-n12 {
    margin-top: -3.75rem !important; }
  .mt-xl-n13 {
    margin-top: -4.0625rem !important; }
  .mt-xl-n14 {
    margin-top: -4.375rem !important; }
  .mt-xl-n15 {
    margin-top: -4.6875rem !important; }
  .mt-xl-n16 {
    margin-top: -5rem !important; }
  .mt-xl-n17 {
    margin-top: -5.3125rem !important; }
  .mt-xl-n18 {
    margin-top: -5.625rem !important; }
  .mt-xl-n19 {
    margin-top: -5.9375rem !important; }
  .mt-xl-n20 {
    margin-top: -6.25rem !important; }
  .mt-xl-n21 {
    margin-top: -6.5625rem !important; }
  .mt-xl-n22 {
    margin-top: -6.875rem !important; }
  .mt-xl-n23 {
    margin-top: -7.1875rem !important; }
  .mt-xl-n24 {
    margin-top: -7.5rem !important; }
  .mt-xl-n25 {
    margin-top: -7.8125rem !important; }
  .mt-xl-n26 {
    margin-top: -8.125rem !important; }
  .mt-xl-n27 {
    margin-top: -8.4375rem !important; }
  .mt-xl-n28 {
    margin-top: -8.75rem !important; }
  .mt-xl-n29 {
    margin-top: -9.0625rem !important; }
  .mt-xl-n30 {
    margin-top: -9.375rem !important; }
  .mt-xl-n31 {
    margin-top: -9.6875rem !important; }
  .mt-xl-n32 {
    margin-top: -10rem !important; }
  .mt-xl-n33 {
    margin-top: -10.3125rem !important; }
  .mt-xl-n34 {
    margin-top: -10.625rem !important; }
  .mt-xl-n35 {
    margin-top: -10.9375rem !important; }
  .mt-xl-n36 {
    margin-top: -11.25rem !important; }
  .mt-xl-n37 {
    margin-top: -11.5625rem !important; }
  .mt-xl-n38 {
    margin-top: -11.875rem !important; }
  .mt-xl-n39 {
    margin-top: -12.1875rem !important; }
  .mt-xl-n40 {
    margin-top: -12.5rem !important; }
  .mt-xl-nbase {
    margin-top: -calc(20rem / 16) !important; }
  .me-xl-n1 {
    margin-right: -0.3125rem !important; }
  .me-xl-n2 {
    margin-right: -0.625rem !important; }
  .me-xl-n3 {
    margin-right: -0.9375rem !important; }
  .me-xl-n4 {
    margin-right: -1.25rem !important; }
  .me-xl-n5 {
    margin-right: -1.5625rem !important; }
  .me-xl-n6 {
    margin-right: -1.875rem !important; }
  .me-xl-n7 {
    margin-right: -2.1875rem !important; }
  .me-xl-n8 {
    margin-right: -2.5rem !important; }
  .me-xl-n9 {
    margin-right: -2.8125rem !important; }
  .me-xl-n10 {
    margin-right: -3.125rem !important; }
  .me-xl-n11 {
    margin-right: -3.4375rem !important; }
  .me-xl-n12 {
    margin-right: -3.75rem !important; }
  .me-xl-n13 {
    margin-right: -4.0625rem !important; }
  .me-xl-n14 {
    margin-right: -4.375rem !important; }
  .me-xl-n15 {
    margin-right: -4.6875rem !important; }
  .me-xl-n16 {
    margin-right: -5rem !important; }
  .me-xl-n17 {
    margin-right: -5.3125rem !important; }
  .me-xl-n18 {
    margin-right: -5.625rem !important; }
  .me-xl-n19 {
    margin-right: -5.9375rem !important; }
  .me-xl-n20 {
    margin-right: -6.25rem !important; }
  .me-xl-n21 {
    margin-right: -6.5625rem !important; }
  .me-xl-n22 {
    margin-right: -6.875rem !important; }
  .me-xl-n23 {
    margin-right: -7.1875rem !important; }
  .me-xl-n24 {
    margin-right: -7.5rem !important; }
  .me-xl-n25 {
    margin-right: -7.8125rem !important; }
  .me-xl-n26 {
    margin-right: -8.125rem !important; }
  .me-xl-n27 {
    margin-right: -8.4375rem !important; }
  .me-xl-n28 {
    margin-right: -8.75rem !important; }
  .me-xl-n29 {
    margin-right: -9.0625rem !important; }
  .me-xl-n30 {
    margin-right: -9.375rem !important; }
  .me-xl-n31 {
    margin-right: -9.6875rem !important; }
  .me-xl-n32 {
    margin-right: -10rem !important; }
  .me-xl-n33 {
    margin-right: -10.3125rem !important; }
  .me-xl-n34 {
    margin-right: -10.625rem !important; }
  .me-xl-n35 {
    margin-right: -10.9375rem !important; }
  .me-xl-n36 {
    margin-right: -11.25rem !important; }
  .me-xl-n37 {
    margin-right: -11.5625rem !important; }
  .me-xl-n38 {
    margin-right: -11.875rem !important; }
  .me-xl-n39 {
    margin-right: -12.1875rem !important; }
  .me-xl-n40 {
    margin-right: -12.5rem !important; }
  .me-xl-nbase {
    margin-right: -calc(20rem / 16) !important; }
  .mb-xl-n1 {
    margin-bottom: -0.3125rem !important; }
  .mb-xl-n2 {
    margin-bottom: -0.625rem !important; }
  .mb-xl-n3 {
    margin-bottom: -0.9375rem !important; }
  .mb-xl-n4 {
    margin-bottom: -1.25rem !important; }
  .mb-xl-n5 {
    margin-bottom: -1.5625rem !important; }
  .mb-xl-n6 {
    margin-bottom: -1.875rem !important; }
  .mb-xl-n7 {
    margin-bottom: -2.1875rem !important; }
  .mb-xl-n8 {
    margin-bottom: -2.5rem !important; }
  .mb-xl-n9 {
    margin-bottom: -2.8125rem !important; }
  .mb-xl-n10 {
    margin-bottom: -3.125rem !important; }
  .mb-xl-n11 {
    margin-bottom: -3.4375rem !important; }
  .mb-xl-n12 {
    margin-bottom: -3.75rem !important; }
  .mb-xl-n13 {
    margin-bottom: -4.0625rem !important; }
  .mb-xl-n14 {
    margin-bottom: -4.375rem !important; }
  .mb-xl-n15 {
    margin-bottom: -4.6875rem !important; }
  .mb-xl-n16 {
    margin-bottom: -5rem !important; }
  .mb-xl-n17 {
    margin-bottom: -5.3125rem !important; }
  .mb-xl-n18 {
    margin-bottom: -5.625rem !important; }
  .mb-xl-n19 {
    margin-bottom: -5.9375rem !important; }
  .mb-xl-n20 {
    margin-bottom: -6.25rem !important; }
  .mb-xl-n21 {
    margin-bottom: -6.5625rem !important; }
  .mb-xl-n22 {
    margin-bottom: -6.875rem !important; }
  .mb-xl-n23 {
    margin-bottom: -7.1875rem !important; }
  .mb-xl-n24 {
    margin-bottom: -7.5rem !important; }
  .mb-xl-n25 {
    margin-bottom: -7.8125rem !important; }
  .mb-xl-n26 {
    margin-bottom: -8.125rem !important; }
  .mb-xl-n27 {
    margin-bottom: -8.4375rem !important; }
  .mb-xl-n28 {
    margin-bottom: -8.75rem !important; }
  .mb-xl-n29 {
    margin-bottom: -9.0625rem !important; }
  .mb-xl-n30 {
    margin-bottom: -9.375rem !important; }
  .mb-xl-n31 {
    margin-bottom: -9.6875rem !important; }
  .mb-xl-n32 {
    margin-bottom: -10rem !important; }
  .mb-xl-n33 {
    margin-bottom: -10.3125rem !important; }
  .mb-xl-n34 {
    margin-bottom: -10.625rem !important; }
  .mb-xl-n35 {
    margin-bottom: -10.9375rem !important; }
  .mb-xl-n36 {
    margin-bottom: -11.25rem !important; }
  .mb-xl-n37 {
    margin-bottom: -11.5625rem !important; }
  .mb-xl-n38 {
    margin-bottom: -11.875rem !important; }
  .mb-xl-n39 {
    margin-bottom: -12.1875rem !important; }
  .mb-xl-n40 {
    margin-bottom: -12.5rem !important; }
  .mb-xl-nbase {
    margin-bottom: -calc(20rem / 16) !important; }
  .ms-xl-n1 {
    margin-left: -0.3125rem !important; }
  .ms-xl-n2 {
    margin-left: -0.625rem !important; }
  .ms-xl-n3 {
    margin-left: -0.9375rem !important; }
  .ms-xl-n4 {
    margin-left: -1.25rem !important; }
  .ms-xl-n5 {
    margin-left: -1.5625rem !important; }
  .ms-xl-n6 {
    margin-left: -1.875rem !important; }
  .ms-xl-n7 {
    margin-left: -2.1875rem !important; }
  .ms-xl-n8 {
    margin-left: -2.5rem !important; }
  .ms-xl-n9 {
    margin-left: -2.8125rem !important; }
  .ms-xl-n10 {
    margin-left: -3.125rem !important; }
  .ms-xl-n11 {
    margin-left: -3.4375rem !important; }
  .ms-xl-n12 {
    margin-left: -3.75rem !important; }
  .ms-xl-n13 {
    margin-left: -4.0625rem !important; }
  .ms-xl-n14 {
    margin-left: -4.375rem !important; }
  .ms-xl-n15 {
    margin-left: -4.6875rem !important; }
  .ms-xl-n16 {
    margin-left: -5rem !important; }
  .ms-xl-n17 {
    margin-left: -5.3125rem !important; }
  .ms-xl-n18 {
    margin-left: -5.625rem !important; }
  .ms-xl-n19 {
    margin-left: -5.9375rem !important; }
  .ms-xl-n20 {
    margin-left: -6.25rem !important; }
  .ms-xl-n21 {
    margin-left: -6.5625rem !important; }
  .ms-xl-n22 {
    margin-left: -6.875rem !important; }
  .ms-xl-n23 {
    margin-left: -7.1875rem !important; }
  .ms-xl-n24 {
    margin-left: -7.5rem !important; }
  .ms-xl-n25 {
    margin-left: -7.8125rem !important; }
  .ms-xl-n26 {
    margin-left: -8.125rem !important; }
  .ms-xl-n27 {
    margin-left: -8.4375rem !important; }
  .ms-xl-n28 {
    margin-left: -8.75rem !important; }
  .ms-xl-n29 {
    margin-left: -9.0625rem !important; }
  .ms-xl-n30 {
    margin-left: -9.375rem !important; }
  .ms-xl-n31 {
    margin-left: -9.6875rem !important; }
  .ms-xl-n32 {
    margin-left: -10rem !important; }
  .ms-xl-n33 {
    margin-left: -10.3125rem !important; }
  .ms-xl-n34 {
    margin-left: -10.625rem !important; }
  .ms-xl-n35 {
    margin-left: -10.9375rem !important; }
  .ms-xl-n36 {
    margin-left: -11.25rem !important; }
  .ms-xl-n37 {
    margin-left: -11.5625rem !important; }
  .ms-xl-n38 {
    margin-left: -11.875rem !important; }
  .ms-xl-n39 {
    margin-left: -12.1875rem !important; }
  .ms-xl-n40 {
    margin-left: -12.5rem !important; }
  .ms-xl-nbase {
    margin-left: -calc(20rem / 16) !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.3125rem !important; }
  .p-xl-2 {
    padding: 0.625rem !important; }
  .p-xl-3 {
    padding: 0.9375rem !important; }
  .p-xl-4 {
    padding: 1.25rem !important; }
  .p-xl-5 {
    padding: 1.5625rem !important; }
  .p-xl-6 {
    padding: 1.875rem !important; }
  .p-xl-7 {
    padding: 2.1875rem !important; }
  .p-xl-8 {
    padding: 2.5rem !important; }
  .p-xl-9 {
    padding: 2.8125rem !important; }
  .p-xl-10 {
    padding: 3.125rem !important; }
  .p-xl-11 {
    padding: 3.4375rem !important; }
  .p-xl-12 {
    padding: 3.75rem !important; }
  .p-xl-13 {
    padding: 4.0625rem !important; }
  .p-xl-14 {
    padding: 4.375rem !important; }
  .p-xl-15 {
    padding: 4.6875rem !important; }
  .p-xl-16 {
    padding: 5rem !important; }
  .p-xl-17 {
    padding: 5.3125rem !important; }
  .p-xl-18 {
    padding: 5.625rem !important; }
  .p-xl-19 {
    padding: 5.9375rem !important; }
  .p-xl-20 {
    padding: 6.25rem !important; }
  .p-xl-21 {
    padding: 6.5625rem !important; }
  .p-xl-22 {
    padding: 6.875rem !important; }
  .p-xl-23 {
    padding: 7.1875rem !important; }
  .p-xl-24 {
    padding: 7.5rem !important; }
  .p-xl-25 {
    padding: 7.8125rem !important; }
  .p-xl-26 {
    padding: 8.125rem !important; }
  .p-xl-27 {
    padding: 8.4375rem !important; }
  .p-xl-28 {
    padding: 8.75rem !important; }
  .p-xl-29 {
    padding: 9.0625rem !important; }
  .p-xl-30 {
    padding: 9.375rem !important; }
  .p-xl-31 {
    padding: 9.6875rem !important; }
  .p-xl-32 {
    padding: 10rem !important; }
  .p-xl-33 {
    padding: 10.3125rem !important; }
  .p-xl-34 {
    padding: 10.625rem !important; }
  .p-xl-35 {
    padding: 10.9375rem !important; }
  .p-xl-36 {
    padding: 11.25rem !important; }
  .p-xl-37 {
    padding: 11.5625rem !important; }
  .p-xl-38 {
    padding: 11.875rem !important; }
  .p-xl-39 {
    padding: 12.1875rem !important; }
  .p-xl-40 {
    padding: 12.5rem !important; }
  .p-xl-base {
    padding: calc(20rem / 16) !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important; }
  .px-xl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important; }
  .px-xl-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important; }
  .px-xl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important; }
  .px-xl-5 {
    padding-right: 1.5625rem !important;
    padding-left: 1.5625rem !important; }
  .px-xl-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important; }
  .px-xl-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important; }
  .px-xl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-xl-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important; }
  .px-xl-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important; }
  .px-xl-11 {
    padding-right: 3.4375rem !important;
    padding-left: 3.4375rem !important; }
  .px-xl-12 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important; }
  .px-xl-13 {
    padding-right: 4.0625rem !important;
    padding-left: 4.0625rem !important; }
  .px-xl-14 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important; }
  .px-xl-15 {
    padding-right: 4.6875rem !important;
    padding-left: 4.6875rem !important; }
  .px-xl-16 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-xl-17 {
    padding-right: 5.3125rem !important;
    padding-left: 5.3125rem !important; }
  .px-xl-18 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important; }
  .px-xl-19 {
    padding-right: 5.9375rem !important;
    padding-left: 5.9375rem !important; }
  .px-xl-20 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important; }
  .px-xl-21 {
    padding-right: 6.5625rem !important;
    padding-left: 6.5625rem !important; }
  .px-xl-22 {
    padding-right: 6.875rem !important;
    padding-left: 6.875rem !important; }
  .px-xl-23 {
    padding-right: 7.1875rem !important;
    padding-left: 7.1875rem !important; }
  .px-xl-24 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-xl-25 {
    padding-right: 7.8125rem !important;
    padding-left: 7.8125rem !important; }
  .px-xl-26 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important; }
  .px-xl-27 {
    padding-right: 8.4375rem !important;
    padding-left: 8.4375rem !important; }
  .px-xl-28 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important; }
  .px-xl-29 {
    padding-right: 9.0625rem !important;
    padding-left: 9.0625rem !important; }
  .px-xl-30 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important; }
  .px-xl-31 {
    padding-right: 9.6875rem !important;
    padding-left: 9.6875rem !important; }
  .px-xl-32 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-xl-33 {
    padding-right: 10.3125rem !important;
    padding-left: 10.3125rem !important; }
  .px-xl-34 {
    padding-right: 10.625rem !important;
    padding-left: 10.625rem !important; }
  .px-xl-35 {
    padding-right: 10.9375rem !important;
    padding-left: 10.9375rem !important; }
  .px-xl-36 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important; }
  .px-xl-37 {
    padding-right: 11.5625rem !important;
    padding-left: 11.5625rem !important; }
  .px-xl-38 {
    padding-right: 11.875rem !important;
    padding-left: 11.875rem !important; }
  .px-xl-39 {
    padding-right: 12.1875rem !important;
    padding-left: 12.1875rem !important; }
  .px-xl-40 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-xl-base {
    padding-right: calc(20rem / 16) !important;
    padding-left: calc(20rem / 16) !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important; }
  .py-xl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important; }
  .py-xl-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important; }
  .py-xl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .py-xl-5 {
    padding-top: 1.5625rem !important;
    padding-bottom: 1.5625rem !important; }
  .py-xl-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important; }
  .py-xl-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important; }
  .py-xl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-xl-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important; }
  .py-xl-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important; }
  .py-xl-11 {
    padding-top: 3.4375rem !important;
    padding-bottom: 3.4375rem !important; }
  .py-xl-12 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important; }
  .py-xl-13 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important; }
  .py-xl-14 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important; }
  .py-xl-15 {
    padding-top: 4.6875rem !important;
    padding-bottom: 4.6875rem !important; }
  .py-xl-16 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-xl-17 {
    padding-top: 5.3125rem !important;
    padding-bottom: 5.3125rem !important; }
  .py-xl-18 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important; }
  .py-xl-19 {
    padding-top: 5.9375rem !important;
    padding-bottom: 5.9375rem !important; }
  .py-xl-20 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important; }
  .py-xl-21 {
    padding-top: 6.5625rem !important;
    padding-bottom: 6.5625rem !important; }
  .py-xl-22 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important; }
  .py-xl-23 {
    padding-top: 7.1875rem !important;
    padding-bottom: 7.1875rem !important; }
  .py-xl-24 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-xl-25 {
    padding-top: 7.8125rem !important;
    padding-bottom: 7.8125rem !important; }
  .py-xl-26 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important; }
  .py-xl-27 {
    padding-top: 8.4375rem !important;
    padding-bottom: 8.4375rem !important; }
  .py-xl-28 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important; }
  .py-xl-29 {
    padding-top: 9.0625rem !important;
    padding-bottom: 9.0625rem !important; }
  .py-xl-30 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important; }
  .py-xl-31 {
    padding-top: 9.6875rem !important;
    padding-bottom: 9.6875rem !important; }
  .py-xl-32 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-xl-33 {
    padding-top: 10.3125rem !important;
    padding-bottom: 10.3125rem !important; }
  .py-xl-34 {
    padding-top: 10.625rem !important;
    padding-bottom: 10.625rem !important; }
  .py-xl-35 {
    padding-top: 10.9375rem !important;
    padding-bottom: 10.9375rem !important; }
  .py-xl-36 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important; }
  .py-xl-37 {
    padding-top: 11.5625rem !important;
    padding-bottom: 11.5625rem !important; }
  .py-xl-38 {
    padding-top: 11.875rem !important;
    padding-bottom: 11.875rem !important; }
  .py-xl-39 {
    padding-top: 12.1875rem !important;
    padding-bottom: 12.1875rem !important; }
  .py-xl-40 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-xl-base {
    padding-top: calc(20rem / 16) !important;
    padding-bottom: calc(20rem / 16) !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.3125rem !important; }
  .pt-xl-2 {
    padding-top: 0.625rem !important; }
  .pt-xl-3 {
    padding-top: 0.9375rem !important; }
  .pt-xl-4 {
    padding-top: 1.25rem !important; }
  .pt-xl-5 {
    padding-top: 1.5625rem !important; }
  .pt-xl-6 {
    padding-top: 1.875rem !important; }
  .pt-xl-7 {
    padding-top: 2.1875rem !important; }
  .pt-xl-8 {
    padding-top: 2.5rem !important; }
  .pt-xl-9 {
    padding-top: 2.8125rem !important; }
  .pt-xl-10 {
    padding-top: 3.125rem !important; }
  .pt-xl-11 {
    padding-top: 3.4375rem !important; }
  .pt-xl-12 {
    padding-top: 3.75rem !important; }
  .pt-xl-13 {
    padding-top: 4.0625rem !important; }
  .pt-xl-14 {
    padding-top: 4.375rem !important; }
  .pt-xl-15 {
    padding-top: 4.6875rem !important; }
  .pt-xl-16 {
    padding-top: 5rem !important; }
  .pt-xl-17 {
    padding-top: 5.3125rem !important; }
  .pt-xl-18 {
    padding-top: 5.625rem !important; }
  .pt-xl-19 {
    padding-top: 5.9375rem !important; }
  .pt-xl-20 {
    padding-top: 6.25rem !important; }
  .pt-xl-21 {
    padding-top: 6.5625rem !important; }
  .pt-xl-22 {
    padding-top: 6.875rem !important; }
  .pt-xl-23 {
    padding-top: 7.1875rem !important; }
  .pt-xl-24 {
    padding-top: 7.5rem !important; }
  .pt-xl-25 {
    padding-top: 7.8125rem !important; }
  .pt-xl-26 {
    padding-top: 8.125rem !important; }
  .pt-xl-27 {
    padding-top: 8.4375rem !important; }
  .pt-xl-28 {
    padding-top: 8.75rem !important; }
  .pt-xl-29 {
    padding-top: 9.0625rem !important; }
  .pt-xl-30 {
    padding-top: 9.375rem !important; }
  .pt-xl-31 {
    padding-top: 9.6875rem !important; }
  .pt-xl-32 {
    padding-top: 10rem !important; }
  .pt-xl-33 {
    padding-top: 10.3125rem !important; }
  .pt-xl-34 {
    padding-top: 10.625rem !important; }
  .pt-xl-35 {
    padding-top: 10.9375rem !important; }
  .pt-xl-36 {
    padding-top: 11.25rem !important; }
  .pt-xl-37 {
    padding-top: 11.5625rem !important; }
  .pt-xl-38 {
    padding-top: 11.875rem !important; }
  .pt-xl-39 {
    padding-top: 12.1875rem !important; }
  .pt-xl-40 {
    padding-top: 12.5rem !important; }
  .pt-xl-base {
    padding-top: calc(20rem / 16) !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.3125rem !important; }
  .pe-xl-2 {
    padding-right: 0.625rem !important; }
  .pe-xl-3 {
    padding-right: 0.9375rem !important; }
  .pe-xl-4 {
    padding-right: 1.25rem !important; }
  .pe-xl-5 {
    padding-right: 1.5625rem !important; }
  .pe-xl-6 {
    padding-right: 1.875rem !important; }
  .pe-xl-7 {
    padding-right: 2.1875rem !important; }
  .pe-xl-8 {
    padding-right: 2.5rem !important; }
  .pe-xl-9 {
    padding-right: 2.8125rem !important; }
  .pe-xl-10 {
    padding-right: 3.125rem !important; }
  .pe-xl-11 {
    padding-right: 3.4375rem !important; }
  .pe-xl-12 {
    padding-right: 3.75rem !important; }
  .pe-xl-13 {
    padding-right: 4.0625rem !important; }
  .pe-xl-14 {
    padding-right: 4.375rem !important; }
  .pe-xl-15 {
    padding-right: 4.6875rem !important; }
  .pe-xl-16 {
    padding-right: 5rem !important; }
  .pe-xl-17 {
    padding-right: 5.3125rem !important; }
  .pe-xl-18 {
    padding-right: 5.625rem !important; }
  .pe-xl-19 {
    padding-right: 5.9375rem !important; }
  .pe-xl-20 {
    padding-right: 6.25rem !important; }
  .pe-xl-21 {
    padding-right: 6.5625rem !important; }
  .pe-xl-22 {
    padding-right: 6.875rem !important; }
  .pe-xl-23 {
    padding-right: 7.1875rem !important; }
  .pe-xl-24 {
    padding-right: 7.5rem !important; }
  .pe-xl-25 {
    padding-right: 7.8125rem !important; }
  .pe-xl-26 {
    padding-right: 8.125rem !important; }
  .pe-xl-27 {
    padding-right: 8.4375rem !important; }
  .pe-xl-28 {
    padding-right: 8.75rem !important; }
  .pe-xl-29 {
    padding-right: 9.0625rem !important; }
  .pe-xl-30 {
    padding-right: 9.375rem !important; }
  .pe-xl-31 {
    padding-right: 9.6875rem !important; }
  .pe-xl-32 {
    padding-right: 10rem !important; }
  .pe-xl-33 {
    padding-right: 10.3125rem !important; }
  .pe-xl-34 {
    padding-right: 10.625rem !important; }
  .pe-xl-35 {
    padding-right: 10.9375rem !important; }
  .pe-xl-36 {
    padding-right: 11.25rem !important; }
  .pe-xl-37 {
    padding-right: 11.5625rem !important; }
  .pe-xl-38 {
    padding-right: 11.875rem !important; }
  .pe-xl-39 {
    padding-right: 12.1875rem !important; }
  .pe-xl-40 {
    padding-right: 12.5rem !important; }
  .pe-xl-base {
    padding-right: calc(20rem / 16) !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.3125rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.625rem !important; }
  .pb-xl-3 {
    padding-bottom: 0.9375rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.25rem !important; }
  .pb-xl-5 {
    padding-bottom: 1.5625rem !important; }
  .pb-xl-6 {
    padding-bottom: 1.875rem !important; }
  .pb-xl-7 {
    padding-bottom: 2.1875rem !important; }
  .pb-xl-8 {
    padding-bottom: 2.5rem !important; }
  .pb-xl-9 {
    padding-bottom: 2.8125rem !important; }
  .pb-xl-10 {
    padding-bottom: 3.125rem !important; }
  .pb-xl-11 {
    padding-bottom: 3.4375rem !important; }
  .pb-xl-12 {
    padding-bottom: 3.75rem !important; }
  .pb-xl-13 {
    padding-bottom: 4.0625rem !important; }
  .pb-xl-14 {
    padding-bottom: 4.375rem !important; }
  .pb-xl-15 {
    padding-bottom: 4.6875rem !important; }
  .pb-xl-16 {
    padding-bottom: 5rem !important; }
  .pb-xl-17 {
    padding-bottom: 5.3125rem !important; }
  .pb-xl-18 {
    padding-bottom: 5.625rem !important; }
  .pb-xl-19 {
    padding-bottom: 5.9375rem !important; }
  .pb-xl-20 {
    padding-bottom: 6.25rem !important; }
  .pb-xl-21 {
    padding-bottom: 6.5625rem !important; }
  .pb-xl-22 {
    padding-bottom: 6.875rem !important; }
  .pb-xl-23 {
    padding-bottom: 7.1875rem !important; }
  .pb-xl-24 {
    padding-bottom: 7.5rem !important; }
  .pb-xl-25 {
    padding-bottom: 7.8125rem !important; }
  .pb-xl-26 {
    padding-bottom: 8.125rem !important; }
  .pb-xl-27 {
    padding-bottom: 8.4375rem !important; }
  .pb-xl-28 {
    padding-bottom: 8.75rem !important; }
  .pb-xl-29 {
    padding-bottom: 9.0625rem !important; }
  .pb-xl-30 {
    padding-bottom: 9.375rem !important; }
  .pb-xl-31 {
    padding-bottom: 9.6875rem !important; }
  .pb-xl-32 {
    padding-bottom: 10rem !important; }
  .pb-xl-33 {
    padding-bottom: 10.3125rem !important; }
  .pb-xl-34 {
    padding-bottom: 10.625rem !important; }
  .pb-xl-35 {
    padding-bottom: 10.9375rem !important; }
  .pb-xl-36 {
    padding-bottom: 11.25rem !important; }
  .pb-xl-37 {
    padding-bottom: 11.5625rem !important; }
  .pb-xl-38 {
    padding-bottom: 11.875rem !important; }
  .pb-xl-39 {
    padding-bottom: 12.1875rem !important; }
  .pb-xl-40 {
    padding-bottom: 12.5rem !important; }
  .pb-xl-base {
    padding-bottom: calc(20rem / 16) !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.3125rem !important; }
  .ps-xl-2 {
    padding-left: 0.625rem !important; }
  .ps-xl-3 {
    padding-left: 0.9375rem !important; }
  .ps-xl-4 {
    padding-left: 1.25rem !important; }
  .ps-xl-5 {
    padding-left: 1.5625rem !important; }
  .ps-xl-6 {
    padding-left: 1.875rem !important; }
  .ps-xl-7 {
    padding-left: 2.1875rem !important; }
  .ps-xl-8 {
    padding-left: 2.5rem !important; }
  .ps-xl-9 {
    padding-left: 2.8125rem !important; }
  .ps-xl-10 {
    padding-left: 3.125rem !important; }
  .ps-xl-11 {
    padding-left: 3.4375rem !important; }
  .ps-xl-12 {
    padding-left: 3.75rem !important; }
  .ps-xl-13 {
    padding-left: 4.0625rem !important; }
  .ps-xl-14 {
    padding-left: 4.375rem !important; }
  .ps-xl-15 {
    padding-left: 4.6875rem !important; }
  .ps-xl-16 {
    padding-left: 5rem !important; }
  .ps-xl-17 {
    padding-left: 5.3125rem !important; }
  .ps-xl-18 {
    padding-left: 5.625rem !important; }
  .ps-xl-19 {
    padding-left: 5.9375rem !important; }
  .ps-xl-20 {
    padding-left: 6.25rem !important; }
  .ps-xl-21 {
    padding-left: 6.5625rem !important; }
  .ps-xl-22 {
    padding-left: 6.875rem !important; }
  .ps-xl-23 {
    padding-left: 7.1875rem !important; }
  .ps-xl-24 {
    padding-left: 7.5rem !important; }
  .ps-xl-25 {
    padding-left: 7.8125rem !important; }
  .ps-xl-26 {
    padding-left: 8.125rem !important; }
  .ps-xl-27 {
    padding-left: 8.4375rem !important; }
  .ps-xl-28 {
    padding-left: 8.75rem !important; }
  .ps-xl-29 {
    padding-left: 9.0625rem !important; }
  .ps-xl-30 {
    padding-left: 9.375rem !important; }
  .ps-xl-31 {
    padding-left: 9.6875rem !important; }
  .ps-xl-32 {
    padding-left: 10rem !important; }
  .ps-xl-33 {
    padding-left: 10.3125rem !important; }
  .ps-xl-34 {
    padding-left: 10.625rem !important; }
  .ps-xl-35 {
    padding-left: 10.9375rem !important; }
  .ps-xl-36 {
    padding-left: 11.25rem !important; }
  .ps-xl-37 {
    padding-left: 11.5625rem !important; }
  .ps-xl-38 {
    padding-left: 11.875rem !important; }
  .ps-xl-39 {
    padding-left: 12.1875rem !important; }
  .ps-xl-40 {
    padding-left: 12.5rem !important; }
  .ps-xl-base {
    padding-left: calc(20rem / 16) !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.3125rem !important; }
  .gap-xl-2 {
    gap: 0.625rem !important; }
  .gap-xl-3 {
    gap: 0.9375rem !important; }
  .gap-xl-4 {
    gap: 1.25rem !important; }
  .gap-xl-5 {
    gap: 1.5625rem !important; }
  .gap-xl-6 {
    gap: 1.875rem !important; }
  .gap-xl-7 {
    gap: 2.1875rem !important; }
  .gap-xl-8 {
    gap: 2.5rem !important; }
  .gap-xl-9 {
    gap: 2.8125rem !important; }
  .gap-xl-10 {
    gap: 3.125rem !important; }
  .gap-xl-11 {
    gap: 3.4375rem !important; }
  .gap-xl-12 {
    gap: 3.75rem !important; }
  .gap-xl-13 {
    gap: 4.0625rem !important; }
  .gap-xl-14 {
    gap: 4.375rem !important; }
  .gap-xl-15 {
    gap: 4.6875rem !important; }
  .gap-xl-16 {
    gap: 5rem !important; }
  .gap-xl-17 {
    gap: 5.3125rem !important; }
  .gap-xl-18 {
    gap: 5.625rem !important; }
  .gap-xl-19 {
    gap: 5.9375rem !important; }
  .gap-xl-20 {
    gap: 6.25rem !important; }
  .gap-xl-21 {
    gap: 6.5625rem !important; }
  .gap-xl-22 {
    gap: 6.875rem !important; }
  .gap-xl-23 {
    gap: 7.1875rem !important; }
  .gap-xl-24 {
    gap: 7.5rem !important; }
  .gap-xl-25 {
    gap: 7.8125rem !important; }
  .gap-xl-26 {
    gap: 8.125rem !important; }
  .gap-xl-27 {
    gap: 8.4375rem !important; }
  .gap-xl-28 {
    gap: 8.75rem !important; }
  .gap-xl-29 {
    gap: 9.0625rem !important; }
  .gap-xl-30 {
    gap: 9.375rem !important; }
  .gap-xl-31 {
    gap: 9.6875rem !important; }
  .gap-xl-32 {
    gap: 10rem !important; }
  .gap-xl-33 {
    gap: 10.3125rem !important; }
  .gap-xl-34 {
    gap: 10.625rem !important; }
  .gap-xl-35 {
    gap: 10.9375rem !important; }
  .gap-xl-36 {
    gap: 11.25rem !important; }
  .gap-xl-37 {
    gap: 11.5625rem !important; }
  .gap-xl-38 {
    gap: 11.875rem !important; }
  .gap-xl-39 {
    gap: 12.1875rem !important; }
  .gap-xl-40 {
    gap: 12.5rem !important; }
  .gap-xl-base {
    gap: calc(20rem / 16) !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.3125rem !important; }
  .m-xxl-2 {
    margin: 0.625rem !important; }
  .m-xxl-3 {
    margin: 0.9375rem !important; }
  .m-xxl-4 {
    margin: 1.25rem !important; }
  .m-xxl-5 {
    margin: 1.5625rem !important; }
  .m-xxl-6 {
    margin: 1.875rem !important; }
  .m-xxl-7 {
    margin: 2.1875rem !important; }
  .m-xxl-8 {
    margin: 2.5rem !important; }
  .m-xxl-9 {
    margin: 2.8125rem !important; }
  .m-xxl-10 {
    margin: 3.125rem !important; }
  .m-xxl-11 {
    margin: 3.4375rem !important; }
  .m-xxl-12 {
    margin: 3.75rem !important; }
  .m-xxl-13 {
    margin: 4.0625rem !important; }
  .m-xxl-14 {
    margin: 4.375rem !important; }
  .m-xxl-15 {
    margin: 4.6875rem !important; }
  .m-xxl-16 {
    margin: 5rem !important; }
  .m-xxl-17 {
    margin: 5.3125rem !important; }
  .m-xxl-18 {
    margin: 5.625rem !important; }
  .m-xxl-19 {
    margin: 5.9375rem !important; }
  .m-xxl-20 {
    margin: 6.25rem !important; }
  .m-xxl-21 {
    margin: 6.5625rem !important; }
  .m-xxl-22 {
    margin: 6.875rem !important; }
  .m-xxl-23 {
    margin: 7.1875rem !important; }
  .m-xxl-24 {
    margin: 7.5rem !important; }
  .m-xxl-25 {
    margin: 7.8125rem !important; }
  .m-xxl-26 {
    margin: 8.125rem !important; }
  .m-xxl-27 {
    margin: 8.4375rem !important; }
  .m-xxl-28 {
    margin: 8.75rem !important; }
  .m-xxl-29 {
    margin: 9.0625rem !important; }
  .m-xxl-30 {
    margin: 9.375rem !important; }
  .m-xxl-31 {
    margin: 9.6875rem !important; }
  .m-xxl-32 {
    margin: 10rem !important; }
  .m-xxl-33 {
    margin: 10.3125rem !important; }
  .m-xxl-34 {
    margin: 10.625rem !important; }
  .m-xxl-35 {
    margin: 10.9375rem !important; }
  .m-xxl-36 {
    margin: 11.25rem !important; }
  .m-xxl-37 {
    margin: 11.5625rem !important; }
  .m-xxl-38 {
    margin: 11.875rem !important; }
  .m-xxl-39 {
    margin: 12.1875rem !important; }
  .m-xxl-40 {
    margin: 12.5rem !important; }
  .m-xxl-base {
    margin: calc(20rem / 16) !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important; }
  .mx-xxl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important; }
  .mx-xxl-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important; }
  .mx-xxl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important; }
  .mx-xxl-5 {
    margin-right: 1.5625rem !important;
    margin-left: 1.5625rem !important; }
  .mx-xxl-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important; }
  .mx-xxl-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important; }
  .mx-xxl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-xxl-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important; }
  .mx-xxl-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important; }
  .mx-xxl-11 {
    margin-right: 3.4375rem !important;
    margin-left: 3.4375rem !important; }
  .mx-xxl-12 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important; }
  .mx-xxl-13 {
    margin-right: 4.0625rem !important;
    margin-left: 4.0625rem !important; }
  .mx-xxl-14 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important; }
  .mx-xxl-15 {
    margin-right: 4.6875rem !important;
    margin-left: 4.6875rem !important; }
  .mx-xxl-16 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-xxl-17 {
    margin-right: 5.3125rem !important;
    margin-left: 5.3125rem !important; }
  .mx-xxl-18 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important; }
  .mx-xxl-19 {
    margin-right: 5.9375rem !important;
    margin-left: 5.9375rem !important; }
  .mx-xxl-20 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important; }
  .mx-xxl-21 {
    margin-right: 6.5625rem !important;
    margin-left: 6.5625rem !important; }
  .mx-xxl-22 {
    margin-right: 6.875rem !important;
    margin-left: 6.875rem !important; }
  .mx-xxl-23 {
    margin-right: 7.1875rem !important;
    margin-left: 7.1875rem !important; }
  .mx-xxl-24 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-xxl-25 {
    margin-right: 7.8125rem !important;
    margin-left: 7.8125rem !important; }
  .mx-xxl-26 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important; }
  .mx-xxl-27 {
    margin-right: 8.4375rem !important;
    margin-left: 8.4375rem !important; }
  .mx-xxl-28 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important; }
  .mx-xxl-29 {
    margin-right: 9.0625rem !important;
    margin-left: 9.0625rem !important; }
  .mx-xxl-30 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important; }
  .mx-xxl-31 {
    margin-right: 9.6875rem !important;
    margin-left: 9.6875rem !important; }
  .mx-xxl-32 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-xxl-33 {
    margin-right: 10.3125rem !important;
    margin-left: 10.3125rem !important; }
  .mx-xxl-34 {
    margin-right: 10.625rem !important;
    margin-left: 10.625rem !important; }
  .mx-xxl-35 {
    margin-right: 10.9375rem !important;
    margin-left: 10.9375rem !important; }
  .mx-xxl-36 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important; }
  .mx-xxl-37 {
    margin-right: 11.5625rem !important;
    margin-left: 11.5625rem !important; }
  .mx-xxl-38 {
    margin-right: 11.875rem !important;
    margin-left: 11.875rem !important; }
  .mx-xxl-39 {
    margin-right: 12.1875rem !important;
    margin-left: 12.1875rem !important; }
  .mx-xxl-40 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-xxl-base {
    margin-right: calc(20rem / 16) !important;
    margin-left: calc(20rem / 16) !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important; }
  .my-xxl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important; }
  .my-xxl-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important; }
  .my-xxl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .my-xxl-5 {
    margin-top: 1.5625rem !important;
    margin-bottom: 1.5625rem !important; }
  .my-xxl-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important; }
  .my-xxl-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important; }
  .my-xxl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-xxl-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important; }
  .my-xxl-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important; }
  .my-xxl-11 {
    margin-top: 3.4375rem !important;
    margin-bottom: 3.4375rem !important; }
  .my-xxl-12 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important; }
  .my-xxl-13 {
    margin-top: 4.0625rem !important;
    margin-bottom: 4.0625rem !important; }
  .my-xxl-14 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important; }
  .my-xxl-15 {
    margin-top: 4.6875rem !important;
    margin-bottom: 4.6875rem !important; }
  .my-xxl-16 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-xxl-17 {
    margin-top: 5.3125rem !important;
    margin-bottom: 5.3125rem !important; }
  .my-xxl-18 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important; }
  .my-xxl-19 {
    margin-top: 5.9375rem !important;
    margin-bottom: 5.9375rem !important; }
  .my-xxl-20 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important; }
  .my-xxl-21 {
    margin-top: 6.5625rem !important;
    margin-bottom: 6.5625rem !important; }
  .my-xxl-22 {
    margin-top: 6.875rem !important;
    margin-bottom: 6.875rem !important; }
  .my-xxl-23 {
    margin-top: 7.1875rem !important;
    margin-bottom: 7.1875rem !important; }
  .my-xxl-24 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-xxl-25 {
    margin-top: 7.8125rem !important;
    margin-bottom: 7.8125rem !important; }
  .my-xxl-26 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important; }
  .my-xxl-27 {
    margin-top: 8.4375rem !important;
    margin-bottom: 8.4375rem !important; }
  .my-xxl-28 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important; }
  .my-xxl-29 {
    margin-top: 9.0625rem !important;
    margin-bottom: 9.0625rem !important; }
  .my-xxl-30 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important; }
  .my-xxl-31 {
    margin-top: 9.6875rem !important;
    margin-bottom: 9.6875rem !important; }
  .my-xxl-32 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-xxl-33 {
    margin-top: 10.3125rem !important;
    margin-bottom: 10.3125rem !important; }
  .my-xxl-34 {
    margin-top: 10.625rem !important;
    margin-bottom: 10.625rem !important; }
  .my-xxl-35 {
    margin-top: 10.9375rem !important;
    margin-bottom: 10.9375rem !important; }
  .my-xxl-36 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important; }
  .my-xxl-37 {
    margin-top: 11.5625rem !important;
    margin-bottom: 11.5625rem !important; }
  .my-xxl-38 {
    margin-top: 11.875rem !important;
    margin-bottom: 11.875rem !important; }
  .my-xxl-39 {
    margin-top: 12.1875rem !important;
    margin-bottom: 12.1875rem !important; }
  .my-xxl-40 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-xxl-base {
    margin-top: calc(20rem / 16) !important;
    margin-bottom: calc(20rem / 16) !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.3125rem !important; }
  .mt-xxl-2 {
    margin-top: 0.625rem !important; }
  .mt-xxl-3 {
    margin-top: 0.9375rem !important; }
  .mt-xxl-4 {
    margin-top: 1.25rem !important; }
  .mt-xxl-5 {
    margin-top: 1.5625rem !important; }
  .mt-xxl-6 {
    margin-top: 1.875rem !important; }
  .mt-xxl-7 {
    margin-top: 2.1875rem !important; }
  .mt-xxl-8 {
    margin-top: 2.5rem !important; }
  .mt-xxl-9 {
    margin-top: 2.8125rem !important; }
  .mt-xxl-10 {
    margin-top: 3.125rem !important; }
  .mt-xxl-11 {
    margin-top: 3.4375rem !important; }
  .mt-xxl-12 {
    margin-top: 3.75rem !important; }
  .mt-xxl-13 {
    margin-top: 4.0625rem !important; }
  .mt-xxl-14 {
    margin-top: 4.375rem !important; }
  .mt-xxl-15 {
    margin-top: 4.6875rem !important; }
  .mt-xxl-16 {
    margin-top: 5rem !important; }
  .mt-xxl-17 {
    margin-top: 5.3125rem !important; }
  .mt-xxl-18 {
    margin-top: 5.625rem !important; }
  .mt-xxl-19 {
    margin-top: 5.9375rem !important; }
  .mt-xxl-20 {
    margin-top: 6.25rem !important; }
  .mt-xxl-21 {
    margin-top: 6.5625rem !important; }
  .mt-xxl-22 {
    margin-top: 6.875rem !important; }
  .mt-xxl-23 {
    margin-top: 7.1875rem !important; }
  .mt-xxl-24 {
    margin-top: 7.5rem !important; }
  .mt-xxl-25 {
    margin-top: 7.8125rem !important; }
  .mt-xxl-26 {
    margin-top: 8.125rem !important; }
  .mt-xxl-27 {
    margin-top: 8.4375rem !important; }
  .mt-xxl-28 {
    margin-top: 8.75rem !important; }
  .mt-xxl-29 {
    margin-top: 9.0625rem !important; }
  .mt-xxl-30 {
    margin-top: 9.375rem !important; }
  .mt-xxl-31 {
    margin-top: 9.6875rem !important; }
  .mt-xxl-32 {
    margin-top: 10rem !important; }
  .mt-xxl-33 {
    margin-top: 10.3125rem !important; }
  .mt-xxl-34 {
    margin-top: 10.625rem !important; }
  .mt-xxl-35 {
    margin-top: 10.9375rem !important; }
  .mt-xxl-36 {
    margin-top: 11.25rem !important; }
  .mt-xxl-37 {
    margin-top: 11.5625rem !important; }
  .mt-xxl-38 {
    margin-top: 11.875rem !important; }
  .mt-xxl-39 {
    margin-top: 12.1875rem !important; }
  .mt-xxl-40 {
    margin-top: 12.5rem !important; }
  .mt-xxl-base {
    margin-top: calc(20rem / 16) !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.3125rem !important; }
  .me-xxl-2 {
    margin-right: 0.625rem !important; }
  .me-xxl-3 {
    margin-right: 0.9375rem !important; }
  .me-xxl-4 {
    margin-right: 1.25rem !important; }
  .me-xxl-5 {
    margin-right: 1.5625rem !important; }
  .me-xxl-6 {
    margin-right: 1.875rem !important; }
  .me-xxl-7 {
    margin-right: 2.1875rem !important; }
  .me-xxl-8 {
    margin-right: 2.5rem !important; }
  .me-xxl-9 {
    margin-right: 2.8125rem !important; }
  .me-xxl-10 {
    margin-right: 3.125rem !important; }
  .me-xxl-11 {
    margin-right: 3.4375rem !important; }
  .me-xxl-12 {
    margin-right: 3.75rem !important; }
  .me-xxl-13 {
    margin-right: 4.0625rem !important; }
  .me-xxl-14 {
    margin-right: 4.375rem !important; }
  .me-xxl-15 {
    margin-right: 4.6875rem !important; }
  .me-xxl-16 {
    margin-right: 5rem !important; }
  .me-xxl-17 {
    margin-right: 5.3125rem !important; }
  .me-xxl-18 {
    margin-right: 5.625rem !important; }
  .me-xxl-19 {
    margin-right: 5.9375rem !important; }
  .me-xxl-20 {
    margin-right: 6.25rem !important; }
  .me-xxl-21 {
    margin-right: 6.5625rem !important; }
  .me-xxl-22 {
    margin-right: 6.875rem !important; }
  .me-xxl-23 {
    margin-right: 7.1875rem !important; }
  .me-xxl-24 {
    margin-right: 7.5rem !important; }
  .me-xxl-25 {
    margin-right: 7.8125rem !important; }
  .me-xxl-26 {
    margin-right: 8.125rem !important; }
  .me-xxl-27 {
    margin-right: 8.4375rem !important; }
  .me-xxl-28 {
    margin-right: 8.75rem !important; }
  .me-xxl-29 {
    margin-right: 9.0625rem !important; }
  .me-xxl-30 {
    margin-right: 9.375rem !important; }
  .me-xxl-31 {
    margin-right: 9.6875rem !important; }
  .me-xxl-32 {
    margin-right: 10rem !important; }
  .me-xxl-33 {
    margin-right: 10.3125rem !important; }
  .me-xxl-34 {
    margin-right: 10.625rem !important; }
  .me-xxl-35 {
    margin-right: 10.9375rem !important; }
  .me-xxl-36 {
    margin-right: 11.25rem !important; }
  .me-xxl-37 {
    margin-right: 11.5625rem !important; }
  .me-xxl-38 {
    margin-right: 11.875rem !important; }
  .me-xxl-39 {
    margin-right: 12.1875rem !important; }
  .me-xxl-40 {
    margin-right: 12.5rem !important; }
  .me-xxl-base {
    margin-right: calc(20rem / 16) !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.3125rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.625rem !important; }
  .mb-xxl-3 {
    margin-bottom: 0.9375rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.25rem !important; }
  .mb-xxl-5 {
    margin-bottom: 1.5625rem !important; }
  .mb-xxl-6 {
    margin-bottom: 1.875rem !important; }
  .mb-xxl-7 {
    margin-bottom: 2.1875rem !important; }
  .mb-xxl-8 {
    margin-bottom: 2.5rem !important; }
  .mb-xxl-9 {
    margin-bottom: 2.8125rem !important; }
  .mb-xxl-10 {
    margin-bottom: 3.125rem !important; }
  .mb-xxl-11 {
    margin-bottom: 3.4375rem !important; }
  .mb-xxl-12 {
    margin-bottom: 3.75rem !important; }
  .mb-xxl-13 {
    margin-bottom: 4.0625rem !important; }
  .mb-xxl-14 {
    margin-bottom: 4.375rem !important; }
  .mb-xxl-15 {
    margin-bottom: 4.6875rem !important; }
  .mb-xxl-16 {
    margin-bottom: 5rem !important; }
  .mb-xxl-17 {
    margin-bottom: 5.3125rem !important; }
  .mb-xxl-18 {
    margin-bottom: 5.625rem !important; }
  .mb-xxl-19 {
    margin-bottom: 5.9375rem !important; }
  .mb-xxl-20 {
    margin-bottom: 6.25rem !important; }
  .mb-xxl-21 {
    margin-bottom: 6.5625rem !important; }
  .mb-xxl-22 {
    margin-bottom: 6.875rem !important; }
  .mb-xxl-23 {
    margin-bottom: 7.1875rem !important; }
  .mb-xxl-24 {
    margin-bottom: 7.5rem !important; }
  .mb-xxl-25 {
    margin-bottom: 7.8125rem !important; }
  .mb-xxl-26 {
    margin-bottom: 8.125rem !important; }
  .mb-xxl-27 {
    margin-bottom: 8.4375rem !important; }
  .mb-xxl-28 {
    margin-bottom: 8.75rem !important; }
  .mb-xxl-29 {
    margin-bottom: 9.0625rem !important; }
  .mb-xxl-30 {
    margin-bottom: 9.375rem !important; }
  .mb-xxl-31 {
    margin-bottom: 9.6875rem !important; }
  .mb-xxl-32 {
    margin-bottom: 10rem !important; }
  .mb-xxl-33 {
    margin-bottom: 10.3125rem !important; }
  .mb-xxl-34 {
    margin-bottom: 10.625rem !important; }
  .mb-xxl-35 {
    margin-bottom: 10.9375rem !important; }
  .mb-xxl-36 {
    margin-bottom: 11.25rem !important; }
  .mb-xxl-37 {
    margin-bottom: 11.5625rem !important; }
  .mb-xxl-38 {
    margin-bottom: 11.875rem !important; }
  .mb-xxl-39 {
    margin-bottom: 12.1875rem !important; }
  .mb-xxl-40 {
    margin-bottom: 12.5rem !important; }
  .mb-xxl-base {
    margin-bottom: calc(20rem / 16) !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.3125rem !important; }
  .ms-xxl-2 {
    margin-left: 0.625rem !important; }
  .ms-xxl-3 {
    margin-left: 0.9375rem !important; }
  .ms-xxl-4 {
    margin-left: 1.25rem !important; }
  .ms-xxl-5 {
    margin-left: 1.5625rem !important; }
  .ms-xxl-6 {
    margin-left: 1.875rem !important; }
  .ms-xxl-7 {
    margin-left: 2.1875rem !important; }
  .ms-xxl-8 {
    margin-left: 2.5rem !important; }
  .ms-xxl-9 {
    margin-left: 2.8125rem !important; }
  .ms-xxl-10 {
    margin-left: 3.125rem !important; }
  .ms-xxl-11 {
    margin-left: 3.4375rem !important; }
  .ms-xxl-12 {
    margin-left: 3.75rem !important; }
  .ms-xxl-13 {
    margin-left: 4.0625rem !important; }
  .ms-xxl-14 {
    margin-left: 4.375rem !important; }
  .ms-xxl-15 {
    margin-left: 4.6875rem !important; }
  .ms-xxl-16 {
    margin-left: 5rem !important; }
  .ms-xxl-17 {
    margin-left: 5.3125rem !important; }
  .ms-xxl-18 {
    margin-left: 5.625rem !important; }
  .ms-xxl-19 {
    margin-left: 5.9375rem !important; }
  .ms-xxl-20 {
    margin-left: 6.25rem !important; }
  .ms-xxl-21 {
    margin-left: 6.5625rem !important; }
  .ms-xxl-22 {
    margin-left: 6.875rem !important; }
  .ms-xxl-23 {
    margin-left: 7.1875rem !important; }
  .ms-xxl-24 {
    margin-left: 7.5rem !important; }
  .ms-xxl-25 {
    margin-left: 7.8125rem !important; }
  .ms-xxl-26 {
    margin-left: 8.125rem !important; }
  .ms-xxl-27 {
    margin-left: 8.4375rem !important; }
  .ms-xxl-28 {
    margin-left: 8.75rem !important; }
  .ms-xxl-29 {
    margin-left: 9.0625rem !important; }
  .ms-xxl-30 {
    margin-left: 9.375rem !important; }
  .ms-xxl-31 {
    margin-left: 9.6875rem !important; }
  .ms-xxl-32 {
    margin-left: 10rem !important; }
  .ms-xxl-33 {
    margin-left: 10.3125rem !important; }
  .ms-xxl-34 {
    margin-left: 10.625rem !important; }
  .ms-xxl-35 {
    margin-left: 10.9375rem !important; }
  .ms-xxl-36 {
    margin-left: 11.25rem !important; }
  .ms-xxl-37 {
    margin-left: 11.5625rem !important; }
  .ms-xxl-38 {
    margin-left: 11.875rem !important; }
  .ms-xxl-39 {
    margin-left: 12.1875rem !important; }
  .ms-xxl-40 {
    margin-left: 12.5rem !important; }
  .ms-xxl-base {
    margin-left: calc(20rem / 16) !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .m-xxl-n1 {
    margin: -0.3125rem !important; }
  .m-xxl-n2 {
    margin: -0.625rem !important; }
  .m-xxl-n3 {
    margin: -0.9375rem !important; }
  .m-xxl-n4 {
    margin: -1.25rem !important; }
  .m-xxl-n5 {
    margin: -1.5625rem !important; }
  .m-xxl-n6 {
    margin: -1.875rem !important; }
  .m-xxl-n7 {
    margin: -2.1875rem !important; }
  .m-xxl-n8 {
    margin: -2.5rem !important; }
  .m-xxl-n9 {
    margin: -2.8125rem !important; }
  .m-xxl-n10 {
    margin: -3.125rem !important; }
  .m-xxl-n11 {
    margin: -3.4375rem !important; }
  .m-xxl-n12 {
    margin: -3.75rem !important; }
  .m-xxl-n13 {
    margin: -4.0625rem !important; }
  .m-xxl-n14 {
    margin: -4.375rem !important; }
  .m-xxl-n15 {
    margin: -4.6875rem !important; }
  .m-xxl-n16 {
    margin: -5rem !important; }
  .m-xxl-n17 {
    margin: -5.3125rem !important; }
  .m-xxl-n18 {
    margin: -5.625rem !important; }
  .m-xxl-n19 {
    margin: -5.9375rem !important; }
  .m-xxl-n20 {
    margin: -6.25rem !important; }
  .m-xxl-n21 {
    margin: -6.5625rem !important; }
  .m-xxl-n22 {
    margin: -6.875rem !important; }
  .m-xxl-n23 {
    margin: -7.1875rem !important; }
  .m-xxl-n24 {
    margin: -7.5rem !important; }
  .m-xxl-n25 {
    margin: -7.8125rem !important; }
  .m-xxl-n26 {
    margin: -8.125rem !important; }
  .m-xxl-n27 {
    margin: -8.4375rem !important; }
  .m-xxl-n28 {
    margin: -8.75rem !important; }
  .m-xxl-n29 {
    margin: -9.0625rem !important; }
  .m-xxl-n30 {
    margin: -9.375rem !important; }
  .m-xxl-n31 {
    margin: -9.6875rem !important; }
  .m-xxl-n32 {
    margin: -10rem !important; }
  .m-xxl-n33 {
    margin: -10.3125rem !important; }
  .m-xxl-n34 {
    margin: -10.625rem !important; }
  .m-xxl-n35 {
    margin: -10.9375rem !important; }
  .m-xxl-n36 {
    margin: -11.25rem !important; }
  .m-xxl-n37 {
    margin: -11.5625rem !important; }
  .m-xxl-n38 {
    margin: -11.875rem !important; }
  .m-xxl-n39 {
    margin: -12.1875rem !important; }
  .m-xxl-n40 {
    margin: -12.5rem !important; }
  .m-xxl-nbase {
    margin: -calc(20rem / 16) !important; }
  .mx-xxl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important; }
  .mx-xxl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important; }
  .mx-xxl-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important; }
  .mx-xxl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important; }
  .mx-xxl-n5 {
    margin-right: -1.5625rem !important;
    margin-left: -1.5625rem !important; }
  .mx-xxl-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important; }
  .mx-xxl-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important; }
  .mx-xxl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-xxl-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important; }
  .mx-xxl-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important; }
  .mx-xxl-n11 {
    margin-right: -3.4375rem !important;
    margin-left: -3.4375rem !important; }
  .mx-xxl-n12 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important; }
  .mx-xxl-n13 {
    margin-right: -4.0625rem !important;
    margin-left: -4.0625rem !important; }
  .mx-xxl-n14 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important; }
  .mx-xxl-n15 {
    margin-right: -4.6875rem !important;
    margin-left: -4.6875rem !important; }
  .mx-xxl-n16 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-xxl-n17 {
    margin-right: -5.3125rem !important;
    margin-left: -5.3125rem !important; }
  .mx-xxl-n18 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important; }
  .mx-xxl-n19 {
    margin-right: -5.9375rem !important;
    margin-left: -5.9375rem !important; }
  .mx-xxl-n20 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important; }
  .mx-xxl-n21 {
    margin-right: -6.5625rem !important;
    margin-left: -6.5625rem !important; }
  .mx-xxl-n22 {
    margin-right: -6.875rem !important;
    margin-left: -6.875rem !important; }
  .mx-xxl-n23 {
    margin-right: -7.1875rem !important;
    margin-left: -7.1875rem !important; }
  .mx-xxl-n24 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-xxl-n25 {
    margin-right: -7.8125rem !important;
    margin-left: -7.8125rem !important; }
  .mx-xxl-n26 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important; }
  .mx-xxl-n27 {
    margin-right: -8.4375rem !important;
    margin-left: -8.4375rem !important; }
  .mx-xxl-n28 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important; }
  .mx-xxl-n29 {
    margin-right: -9.0625rem !important;
    margin-left: -9.0625rem !important; }
  .mx-xxl-n30 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important; }
  .mx-xxl-n31 {
    margin-right: -9.6875rem !important;
    margin-left: -9.6875rem !important; }
  .mx-xxl-n32 {
    margin-right: -10rem !important;
    margin-left: -10rem !important; }
  .mx-xxl-n33 {
    margin-right: -10.3125rem !important;
    margin-left: -10.3125rem !important; }
  .mx-xxl-n34 {
    margin-right: -10.625rem !important;
    margin-left: -10.625rem !important; }
  .mx-xxl-n35 {
    margin-right: -10.9375rem !important;
    margin-left: -10.9375rem !important; }
  .mx-xxl-n36 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important; }
  .mx-xxl-n37 {
    margin-right: -11.5625rem !important;
    margin-left: -11.5625rem !important; }
  .mx-xxl-n38 {
    margin-right: -11.875rem !important;
    margin-left: -11.875rem !important; }
  .mx-xxl-n39 {
    margin-right: -12.1875rem !important;
    margin-left: -12.1875rem !important; }
  .mx-xxl-n40 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important; }
  .mx-xxl-nbase {
    margin-right: -calc(20rem / 16) !important;
    margin-left: -calc(20rem / 16) !important; }
  .my-xxl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important; }
  .my-xxl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important; }
  .my-xxl-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important; }
  .my-xxl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .my-xxl-n5 {
    margin-top: -1.5625rem !important;
    margin-bottom: -1.5625rem !important; }
  .my-xxl-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important; }
  .my-xxl-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important; }
  .my-xxl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-xxl-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important; }
  .my-xxl-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important; }
  .my-xxl-n11 {
    margin-top: -3.4375rem !important;
    margin-bottom: -3.4375rem !important; }
  .my-xxl-n12 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important; }
  .my-xxl-n13 {
    margin-top: -4.0625rem !important;
    margin-bottom: -4.0625rem !important; }
  .my-xxl-n14 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important; }
  .my-xxl-n15 {
    margin-top: -4.6875rem !important;
    margin-bottom: -4.6875rem !important; }
  .my-xxl-n16 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-xxl-n17 {
    margin-top: -5.3125rem !important;
    margin-bottom: -5.3125rem !important; }
  .my-xxl-n18 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important; }
  .my-xxl-n19 {
    margin-top: -5.9375rem !important;
    margin-bottom: -5.9375rem !important; }
  .my-xxl-n20 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important; }
  .my-xxl-n21 {
    margin-top: -6.5625rem !important;
    margin-bottom: -6.5625rem !important; }
  .my-xxl-n22 {
    margin-top: -6.875rem !important;
    margin-bottom: -6.875rem !important; }
  .my-xxl-n23 {
    margin-top: -7.1875rem !important;
    margin-bottom: -7.1875rem !important; }
  .my-xxl-n24 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-xxl-n25 {
    margin-top: -7.8125rem !important;
    margin-bottom: -7.8125rem !important; }
  .my-xxl-n26 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important; }
  .my-xxl-n27 {
    margin-top: -8.4375rem !important;
    margin-bottom: -8.4375rem !important; }
  .my-xxl-n28 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important; }
  .my-xxl-n29 {
    margin-top: -9.0625rem !important;
    margin-bottom: -9.0625rem !important; }
  .my-xxl-n30 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important; }
  .my-xxl-n31 {
    margin-top: -9.6875rem !important;
    margin-bottom: -9.6875rem !important; }
  .my-xxl-n32 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .my-xxl-n33 {
    margin-top: -10.3125rem !important;
    margin-bottom: -10.3125rem !important; }
  .my-xxl-n34 {
    margin-top: -10.625rem !important;
    margin-bottom: -10.625rem !important; }
  .my-xxl-n35 {
    margin-top: -10.9375rem !important;
    margin-bottom: -10.9375rem !important; }
  .my-xxl-n36 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important; }
  .my-xxl-n37 {
    margin-top: -11.5625rem !important;
    margin-bottom: -11.5625rem !important; }
  .my-xxl-n38 {
    margin-top: -11.875rem !important;
    margin-bottom: -11.875rem !important; }
  .my-xxl-n39 {
    margin-top: -12.1875rem !important;
    margin-bottom: -12.1875rem !important; }
  .my-xxl-n40 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important; }
  .my-xxl-nbase {
    margin-top: -calc(20rem / 16) !important;
    margin-bottom: -calc(20rem / 16) !important; }
  .mt-xxl-n1 {
    margin-top: -0.3125rem !important; }
  .mt-xxl-n2 {
    margin-top: -0.625rem !important; }
  .mt-xxl-n3 {
    margin-top: -0.9375rem !important; }
  .mt-xxl-n4 {
    margin-top: -1.25rem !important; }
  .mt-xxl-n5 {
    margin-top: -1.5625rem !important; }
  .mt-xxl-n6 {
    margin-top: -1.875rem !important; }
  .mt-xxl-n7 {
    margin-top: -2.1875rem !important; }
  .mt-xxl-n8 {
    margin-top: -2.5rem !important; }
  .mt-xxl-n9 {
    margin-top: -2.8125rem !important; }
  .mt-xxl-n10 {
    margin-top: -3.125rem !important; }
  .mt-xxl-n11 {
    margin-top: -3.4375rem !important; }
  .mt-xxl-n12 {
    margin-top: -3.75rem !important; }
  .mt-xxl-n13 {
    margin-top: -4.0625rem !important; }
  .mt-xxl-n14 {
    margin-top: -4.375rem !important; }
  .mt-xxl-n15 {
    margin-top: -4.6875rem !important; }
  .mt-xxl-n16 {
    margin-top: -5rem !important; }
  .mt-xxl-n17 {
    margin-top: -5.3125rem !important; }
  .mt-xxl-n18 {
    margin-top: -5.625rem !important; }
  .mt-xxl-n19 {
    margin-top: -5.9375rem !important; }
  .mt-xxl-n20 {
    margin-top: -6.25rem !important; }
  .mt-xxl-n21 {
    margin-top: -6.5625rem !important; }
  .mt-xxl-n22 {
    margin-top: -6.875rem !important; }
  .mt-xxl-n23 {
    margin-top: -7.1875rem !important; }
  .mt-xxl-n24 {
    margin-top: -7.5rem !important; }
  .mt-xxl-n25 {
    margin-top: -7.8125rem !important; }
  .mt-xxl-n26 {
    margin-top: -8.125rem !important; }
  .mt-xxl-n27 {
    margin-top: -8.4375rem !important; }
  .mt-xxl-n28 {
    margin-top: -8.75rem !important; }
  .mt-xxl-n29 {
    margin-top: -9.0625rem !important; }
  .mt-xxl-n30 {
    margin-top: -9.375rem !important; }
  .mt-xxl-n31 {
    margin-top: -9.6875rem !important; }
  .mt-xxl-n32 {
    margin-top: -10rem !important; }
  .mt-xxl-n33 {
    margin-top: -10.3125rem !important; }
  .mt-xxl-n34 {
    margin-top: -10.625rem !important; }
  .mt-xxl-n35 {
    margin-top: -10.9375rem !important; }
  .mt-xxl-n36 {
    margin-top: -11.25rem !important; }
  .mt-xxl-n37 {
    margin-top: -11.5625rem !important; }
  .mt-xxl-n38 {
    margin-top: -11.875rem !important; }
  .mt-xxl-n39 {
    margin-top: -12.1875rem !important; }
  .mt-xxl-n40 {
    margin-top: -12.5rem !important; }
  .mt-xxl-nbase {
    margin-top: -calc(20rem / 16) !important; }
  .me-xxl-n1 {
    margin-right: -0.3125rem !important; }
  .me-xxl-n2 {
    margin-right: -0.625rem !important; }
  .me-xxl-n3 {
    margin-right: -0.9375rem !important; }
  .me-xxl-n4 {
    margin-right: -1.25rem !important; }
  .me-xxl-n5 {
    margin-right: -1.5625rem !important; }
  .me-xxl-n6 {
    margin-right: -1.875rem !important; }
  .me-xxl-n7 {
    margin-right: -2.1875rem !important; }
  .me-xxl-n8 {
    margin-right: -2.5rem !important; }
  .me-xxl-n9 {
    margin-right: -2.8125rem !important; }
  .me-xxl-n10 {
    margin-right: -3.125rem !important; }
  .me-xxl-n11 {
    margin-right: -3.4375rem !important; }
  .me-xxl-n12 {
    margin-right: -3.75rem !important; }
  .me-xxl-n13 {
    margin-right: -4.0625rem !important; }
  .me-xxl-n14 {
    margin-right: -4.375rem !important; }
  .me-xxl-n15 {
    margin-right: -4.6875rem !important; }
  .me-xxl-n16 {
    margin-right: -5rem !important; }
  .me-xxl-n17 {
    margin-right: -5.3125rem !important; }
  .me-xxl-n18 {
    margin-right: -5.625rem !important; }
  .me-xxl-n19 {
    margin-right: -5.9375rem !important; }
  .me-xxl-n20 {
    margin-right: -6.25rem !important; }
  .me-xxl-n21 {
    margin-right: -6.5625rem !important; }
  .me-xxl-n22 {
    margin-right: -6.875rem !important; }
  .me-xxl-n23 {
    margin-right: -7.1875rem !important; }
  .me-xxl-n24 {
    margin-right: -7.5rem !important; }
  .me-xxl-n25 {
    margin-right: -7.8125rem !important; }
  .me-xxl-n26 {
    margin-right: -8.125rem !important; }
  .me-xxl-n27 {
    margin-right: -8.4375rem !important; }
  .me-xxl-n28 {
    margin-right: -8.75rem !important; }
  .me-xxl-n29 {
    margin-right: -9.0625rem !important; }
  .me-xxl-n30 {
    margin-right: -9.375rem !important; }
  .me-xxl-n31 {
    margin-right: -9.6875rem !important; }
  .me-xxl-n32 {
    margin-right: -10rem !important; }
  .me-xxl-n33 {
    margin-right: -10.3125rem !important; }
  .me-xxl-n34 {
    margin-right: -10.625rem !important; }
  .me-xxl-n35 {
    margin-right: -10.9375rem !important; }
  .me-xxl-n36 {
    margin-right: -11.25rem !important; }
  .me-xxl-n37 {
    margin-right: -11.5625rem !important; }
  .me-xxl-n38 {
    margin-right: -11.875rem !important; }
  .me-xxl-n39 {
    margin-right: -12.1875rem !important; }
  .me-xxl-n40 {
    margin-right: -12.5rem !important; }
  .me-xxl-nbase {
    margin-right: -calc(20rem / 16) !important; }
  .mb-xxl-n1 {
    margin-bottom: -0.3125rem !important; }
  .mb-xxl-n2 {
    margin-bottom: -0.625rem !important; }
  .mb-xxl-n3 {
    margin-bottom: -0.9375rem !important; }
  .mb-xxl-n4 {
    margin-bottom: -1.25rem !important; }
  .mb-xxl-n5 {
    margin-bottom: -1.5625rem !important; }
  .mb-xxl-n6 {
    margin-bottom: -1.875rem !important; }
  .mb-xxl-n7 {
    margin-bottom: -2.1875rem !important; }
  .mb-xxl-n8 {
    margin-bottom: -2.5rem !important; }
  .mb-xxl-n9 {
    margin-bottom: -2.8125rem !important; }
  .mb-xxl-n10 {
    margin-bottom: -3.125rem !important; }
  .mb-xxl-n11 {
    margin-bottom: -3.4375rem !important; }
  .mb-xxl-n12 {
    margin-bottom: -3.75rem !important; }
  .mb-xxl-n13 {
    margin-bottom: -4.0625rem !important; }
  .mb-xxl-n14 {
    margin-bottom: -4.375rem !important; }
  .mb-xxl-n15 {
    margin-bottom: -4.6875rem !important; }
  .mb-xxl-n16 {
    margin-bottom: -5rem !important; }
  .mb-xxl-n17 {
    margin-bottom: -5.3125rem !important; }
  .mb-xxl-n18 {
    margin-bottom: -5.625rem !important; }
  .mb-xxl-n19 {
    margin-bottom: -5.9375rem !important; }
  .mb-xxl-n20 {
    margin-bottom: -6.25rem !important; }
  .mb-xxl-n21 {
    margin-bottom: -6.5625rem !important; }
  .mb-xxl-n22 {
    margin-bottom: -6.875rem !important; }
  .mb-xxl-n23 {
    margin-bottom: -7.1875rem !important; }
  .mb-xxl-n24 {
    margin-bottom: -7.5rem !important; }
  .mb-xxl-n25 {
    margin-bottom: -7.8125rem !important; }
  .mb-xxl-n26 {
    margin-bottom: -8.125rem !important; }
  .mb-xxl-n27 {
    margin-bottom: -8.4375rem !important; }
  .mb-xxl-n28 {
    margin-bottom: -8.75rem !important; }
  .mb-xxl-n29 {
    margin-bottom: -9.0625rem !important; }
  .mb-xxl-n30 {
    margin-bottom: -9.375rem !important; }
  .mb-xxl-n31 {
    margin-bottom: -9.6875rem !important; }
  .mb-xxl-n32 {
    margin-bottom: -10rem !important; }
  .mb-xxl-n33 {
    margin-bottom: -10.3125rem !important; }
  .mb-xxl-n34 {
    margin-bottom: -10.625rem !important; }
  .mb-xxl-n35 {
    margin-bottom: -10.9375rem !important; }
  .mb-xxl-n36 {
    margin-bottom: -11.25rem !important; }
  .mb-xxl-n37 {
    margin-bottom: -11.5625rem !important; }
  .mb-xxl-n38 {
    margin-bottom: -11.875rem !important; }
  .mb-xxl-n39 {
    margin-bottom: -12.1875rem !important; }
  .mb-xxl-n40 {
    margin-bottom: -12.5rem !important; }
  .mb-xxl-nbase {
    margin-bottom: -calc(20rem / 16) !important; }
  .ms-xxl-n1 {
    margin-left: -0.3125rem !important; }
  .ms-xxl-n2 {
    margin-left: -0.625rem !important; }
  .ms-xxl-n3 {
    margin-left: -0.9375rem !important; }
  .ms-xxl-n4 {
    margin-left: -1.25rem !important; }
  .ms-xxl-n5 {
    margin-left: -1.5625rem !important; }
  .ms-xxl-n6 {
    margin-left: -1.875rem !important; }
  .ms-xxl-n7 {
    margin-left: -2.1875rem !important; }
  .ms-xxl-n8 {
    margin-left: -2.5rem !important; }
  .ms-xxl-n9 {
    margin-left: -2.8125rem !important; }
  .ms-xxl-n10 {
    margin-left: -3.125rem !important; }
  .ms-xxl-n11 {
    margin-left: -3.4375rem !important; }
  .ms-xxl-n12 {
    margin-left: -3.75rem !important; }
  .ms-xxl-n13 {
    margin-left: -4.0625rem !important; }
  .ms-xxl-n14 {
    margin-left: -4.375rem !important; }
  .ms-xxl-n15 {
    margin-left: -4.6875rem !important; }
  .ms-xxl-n16 {
    margin-left: -5rem !important; }
  .ms-xxl-n17 {
    margin-left: -5.3125rem !important; }
  .ms-xxl-n18 {
    margin-left: -5.625rem !important; }
  .ms-xxl-n19 {
    margin-left: -5.9375rem !important; }
  .ms-xxl-n20 {
    margin-left: -6.25rem !important; }
  .ms-xxl-n21 {
    margin-left: -6.5625rem !important; }
  .ms-xxl-n22 {
    margin-left: -6.875rem !important; }
  .ms-xxl-n23 {
    margin-left: -7.1875rem !important; }
  .ms-xxl-n24 {
    margin-left: -7.5rem !important; }
  .ms-xxl-n25 {
    margin-left: -7.8125rem !important; }
  .ms-xxl-n26 {
    margin-left: -8.125rem !important; }
  .ms-xxl-n27 {
    margin-left: -8.4375rem !important; }
  .ms-xxl-n28 {
    margin-left: -8.75rem !important; }
  .ms-xxl-n29 {
    margin-left: -9.0625rem !important; }
  .ms-xxl-n30 {
    margin-left: -9.375rem !important; }
  .ms-xxl-n31 {
    margin-left: -9.6875rem !important; }
  .ms-xxl-n32 {
    margin-left: -10rem !important; }
  .ms-xxl-n33 {
    margin-left: -10.3125rem !important; }
  .ms-xxl-n34 {
    margin-left: -10.625rem !important; }
  .ms-xxl-n35 {
    margin-left: -10.9375rem !important; }
  .ms-xxl-n36 {
    margin-left: -11.25rem !important; }
  .ms-xxl-n37 {
    margin-left: -11.5625rem !important; }
  .ms-xxl-n38 {
    margin-left: -11.875rem !important; }
  .ms-xxl-n39 {
    margin-left: -12.1875rem !important; }
  .ms-xxl-n40 {
    margin-left: -12.5rem !important; }
  .ms-xxl-nbase {
    margin-left: -calc(20rem / 16) !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.3125rem !important; }
  .p-xxl-2 {
    padding: 0.625rem !important; }
  .p-xxl-3 {
    padding: 0.9375rem !important; }
  .p-xxl-4 {
    padding: 1.25rem !important; }
  .p-xxl-5 {
    padding: 1.5625rem !important; }
  .p-xxl-6 {
    padding: 1.875rem !important; }
  .p-xxl-7 {
    padding: 2.1875rem !important; }
  .p-xxl-8 {
    padding: 2.5rem !important; }
  .p-xxl-9 {
    padding: 2.8125rem !important; }
  .p-xxl-10 {
    padding: 3.125rem !important; }
  .p-xxl-11 {
    padding: 3.4375rem !important; }
  .p-xxl-12 {
    padding: 3.75rem !important; }
  .p-xxl-13 {
    padding: 4.0625rem !important; }
  .p-xxl-14 {
    padding: 4.375rem !important; }
  .p-xxl-15 {
    padding: 4.6875rem !important; }
  .p-xxl-16 {
    padding: 5rem !important; }
  .p-xxl-17 {
    padding: 5.3125rem !important; }
  .p-xxl-18 {
    padding: 5.625rem !important; }
  .p-xxl-19 {
    padding: 5.9375rem !important; }
  .p-xxl-20 {
    padding: 6.25rem !important; }
  .p-xxl-21 {
    padding: 6.5625rem !important; }
  .p-xxl-22 {
    padding: 6.875rem !important; }
  .p-xxl-23 {
    padding: 7.1875rem !important; }
  .p-xxl-24 {
    padding: 7.5rem !important; }
  .p-xxl-25 {
    padding: 7.8125rem !important; }
  .p-xxl-26 {
    padding: 8.125rem !important; }
  .p-xxl-27 {
    padding: 8.4375rem !important; }
  .p-xxl-28 {
    padding: 8.75rem !important; }
  .p-xxl-29 {
    padding: 9.0625rem !important; }
  .p-xxl-30 {
    padding: 9.375rem !important; }
  .p-xxl-31 {
    padding: 9.6875rem !important; }
  .p-xxl-32 {
    padding: 10rem !important; }
  .p-xxl-33 {
    padding: 10.3125rem !important; }
  .p-xxl-34 {
    padding: 10.625rem !important; }
  .p-xxl-35 {
    padding: 10.9375rem !important; }
  .p-xxl-36 {
    padding: 11.25rem !important; }
  .p-xxl-37 {
    padding: 11.5625rem !important; }
  .p-xxl-38 {
    padding: 11.875rem !important; }
  .p-xxl-39 {
    padding: 12.1875rem !important; }
  .p-xxl-40 {
    padding: 12.5rem !important; }
  .p-xxl-base {
    padding: calc(20rem / 16) !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important; }
  .px-xxl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important; }
  .px-xxl-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important; }
  .px-xxl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important; }
  .px-xxl-5 {
    padding-right: 1.5625rem !important;
    padding-left: 1.5625rem !important; }
  .px-xxl-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important; }
  .px-xxl-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important; }
  .px-xxl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-xxl-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important; }
  .px-xxl-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important; }
  .px-xxl-11 {
    padding-right: 3.4375rem !important;
    padding-left: 3.4375rem !important; }
  .px-xxl-12 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important; }
  .px-xxl-13 {
    padding-right: 4.0625rem !important;
    padding-left: 4.0625rem !important; }
  .px-xxl-14 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important; }
  .px-xxl-15 {
    padding-right: 4.6875rem !important;
    padding-left: 4.6875rem !important; }
  .px-xxl-16 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-xxl-17 {
    padding-right: 5.3125rem !important;
    padding-left: 5.3125rem !important; }
  .px-xxl-18 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important; }
  .px-xxl-19 {
    padding-right: 5.9375rem !important;
    padding-left: 5.9375rem !important; }
  .px-xxl-20 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important; }
  .px-xxl-21 {
    padding-right: 6.5625rem !important;
    padding-left: 6.5625rem !important; }
  .px-xxl-22 {
    padding-right: 6.875rem !important;
    padding-left: 6.875rem !important; }
  .px-xxl-23 {
    padding-right: 7.1875rem !important;
    padding-left: 7.1875rem !important; }
  .px-xxl-24 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-xxl-25 {
    padding-right: 7.8125rem !important;
    padding-left: 7.8125rem !important; }
  .px-xxl-26 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important; }
  .px-xxl-27 {
    padding-right: 8.4375rem !important;
    padding-left: 8.4375rem !important; }
  .px-xxl-28 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important; }
  .px-xxl-29 {
    padding-right: 9.0625rem !important;
    padding-left: 9.0625rem !important; }
  .px-xxl-30 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important; }
  .px-xxl-31 {
    padding-right: 9.6875rem !important;
    padding-left: 9.6875rem !important; }
  .px-xxl-32 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-xxl-33 {
    padding-right: 10.3125rem !important;
    padding-left: 10.3125rem !important; }
  .px-xxl-34 {
    padding-right: 10.625rem !important;
    padding-left: 10.625rem !important; }
  .px-xxl-35 {
    padding-right: 10.9375rem !important;
    padding-left: 10.9375rem !important; }
  .px-xxl-36 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important; }
  .px-xxl-37 {
    padding-right: 11.5625rem !important;
    padding-left: 11.5625rem !important; }
  .px-xxl-38 {
    padding-right: 11.875rem !important;
    padding-left: 11.875rem !important; }
  .px-xxl-39 {
    padding-right: 12.1875rem !important;
    padding-left: 12.1875rem !important; }
  .px-xxl-40 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-xxl-base {
    padding-right: calc(20rem / 16) !important;
    padding-left: calc(20rem / 16) !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important; }
  .py-xxl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important; }
  .py-xxl-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important; }
  .py-xxl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .py-xxl-5 {
    padding-top: 1.5625rem !important;
    padding-bottom: 1.5625rem !important; }
  .py-xxl-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important; }
  .py-xxl-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important; }
  .py-xxl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-xxl-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important; }
  .py-xxl-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important; }
  .py-xxl-11 {
    padding-top: 3.4375rem !important;
    padding-bottom: 3.4375rem !important; }
  .py-xxl-12 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important; }
  .py-xxl-13 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important; }
  .py-xxl-14 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important; }
  .py-xxl-15 {
    padding-top: 4.6875rem !important;
    padding-bottom: 4.6875rem !important; }
  .py-xxl-16 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-xxl-17 {
    padding-top: 5.3125rem !important;
    padding-bottom: 5.3125rem !important; }
  .py-xxl-18 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important; }
  .py-xxl-19 {
    padding-top: 5.9375rem !important;
    padding-bottom: 5.9375rem !important; }
  .py-xxl-20 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important; }
  .py-xxl-21 {
    padding-top: 6.5625rem !important;
    padding-bottom: 6.5625rem !important; }
  .py-xxl-22 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important; }
  .py-xxl-23 {
    padding-top: 7.1875rem !important;
    padding-bottom: 7.1875rem !important; }
  .py-xxl-24 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-xxl-25 {
    padding-top: 7.8125rem !important;
    padding-bottom: 7.8125rem !important; }
  .py-xxl-26 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important; }
  .py-xxl-27 {
    padding-top: 8.4375rem !important;
    padding-bottom: 8.4375rem !important; }
  .py-xxl-28 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important; }
  .py-xxl-29 {
    padding-top: 9.0625rem !important;
    padding-bottom: 9.0625rem !important; }
  .py-xxl-30 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important; }
  .py-xxl-31 {
    padding-top: 9.6875rem !important;
    padding-bottom: 9.6875rem !important; }
  .py-xxl-32 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-xxl-33 {
    padding-top: 10.3125rem !important;
    padding-bottom: 10.3125rem !important; }
  .py-xxl-34 {
    padding-top: 10.625rem !important;
    padding-bottom: 10.625rem !important; }
  .py-xxl-35 {
    padding-top: 10.9375rem !important;
    padding-bottom: 10.9375rem !important; }
  .py-xxl-36 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important; }
  .py-xxl-37 {
    padding-top: 11.5625rem !important;
    padding-bottom: 11.5625rem !important; }
  .py-xxl-38 {
    padding-top: 11.875rem !important;
    padding-bottom: 11.875rem !important; }
  .py-xxl-39 {
    padding-top: 12.1875rem !important;
    padding-bottom: 12.1875rem !important; }
  .py-xxl-40 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-xxl-base {
    padding-top: calc(20rem / 16) !important;
    padding-bottom: calc(20rem / 16) !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.3125rem !important; }
  .pt-xxl-2 {
    padding-top: 0.625rem !important; }
  .pt-xxl-3 {
    padding-top: 0.9375rem !important; }
  .pt-xxl-4 {
    padding-top: 1.25rem !important; }
  .pt-xxl-5 {
    padding-top: 1.5625rem !important; }
  .pt-xxl-6 {
    padding-top: 1.875rem !important; }
  .pt-xxl-7 {
    padding-top: 2.1875rem !important; }
  .pt-xxl-8 {
    padding-top: 2.5rem !important; }
  .pt-xxl-9 {
    padding-top: 2.8125rem !important; }
  .pt-xxl-10 {
    padding-top: 3.125rem !important; }
  .pt-xxl-11 {
    padding-top: 3.4375rem !important; }
  .pt-xxl-12 {
    padding-top: 3.75rem !important; }
  .pt-xxl-13 {
    padding-top: 4.0625rem !important; }
  .pt-xxl-14 {
    padding-top: 4.375rem !important; }
  .pt-xxl-15 {
    padding-top: 4.6875rem !important; }
  .pt-xxl-16 {
    padding-top: 5rem !important; }
  .pt-xxl-17 {
    padding-top: 5.3125rem !important; }
  .pt-xxl-18 {
    padding-top: 5.625rem !important; }
  .pt-xxl-19 {
    padding-top: 5.9375rem !important; }
  .pt-xxl-20 {
    padding-top: 6.25rem !important; }
  .pt-xxl-21 {
    padding-top: 6.5625rem !important; }
  .pt-xxl-22 {
    padding-top: 6.875rem !important; }
  .pt-xxl-23 {
    padding-top: 7.1875rem !important; }
  .pt-xxl-24 {
    padding-top: 7.5rem !important; }
  .pt-xxl-25 {
    padding-top: 7.8125rem !important; }
  .pt-xxl-26 {
    padding-top: 8.125rem !important; }
  .pt-xxl-27 {
    padding-top: 8.4375rem !important; }
  .pt-xxl-28 {
    padding-top: 8.75rem !important; }
  .pt-xxl-29 {
    padding-top: 9.0625rem !important; }
  .pt-xxl-30 {
    padding-top: 9.375rem !important; }
  .pt-xxl-31 {
    padding-top: 9.6875rem !important; }
  .pt-xxl-32 {
    padding-top: 10rem !important; }
  .pt-xxl-33 {
    padding-top: 10.3125rem !important; }
  .pt-xxl-34 {
    padding-top: 10.625rem !important; }
  .pt-xxl-35 {
    padding-top: 10.9375rem !important; }
  .pt-xxl-36 {
    padding-top: 11.25rem !important; }
  .pt-xxl-37 {
    padding-top: 11.5625rem !important; }
  .pt-xxl-38 {
    padding-top: 11.875rem !important; }
  .pt-xxl-39 {
    padding-top: 12.1875rem !important; }
  .pt-xxl-40 {
    padding-top: 12.5rem !important; }
  .pt-xxl-base {
    padding-top: calc(20rem / 16) !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.3125rem !important; }
  .pe-xxl-2 {
    padding-right: 0.625rem !important; }
  .pe-xxl-3 {
    padding-right: 0.9375rem !important; }
  .pe-xxl-4 {
    padding-right: 1.25rem !important; }
  .pe-xxl-5 {
    padding-right: 1.5625rem !important; }
  .pe-xxl-6 {
    padding-right: 1.875rem !important; }
  .pe-xxl-7 {
    padding-right: 2.1875rem !important; }
  .pe-xxl-8 {
    padding-right: 2.5rem !important; }
  .pe-xxl-9 {
    padding-right: 2.8125rem !important; }
  .pe-xxl-10 {
    padding-right: 3.125rem !important; }
  .pe-xxl-11 {
    padding-right: 3.4375rem !important; }
  .pe-xxl-12 {
    padding-right: 3.75rem !important; }
  .pe-xxl-13 {
    padding-right: 4.0625rem !important; }
  .pe-xxl-14 {
    padding-right: 4.375rem !important; }
  .pe-xxl-15 {
    padding-right: 4.6875rem !important; }
  .pe-xxl-16 {
    padding-right: 5rem !important; }
  .pe-xxl-17 {
    padding-right: 5.3125rem !important; }
  .pe-xxl-18 {
    padding-right: 5.625rem !important; }
  .pe-xxl-19 {
    padding-right: 5.9375rem !important; }
  .pe-xxl-20 {
    padding-right: 6.25rem !important; }
  .pe-xxl-21 {
    padding-right: 6.5625rem !important; }
  .pe-xxl-22 {
    padding-right: 6.875rem !important; }
  .pe-xxl-23 {
    padding-right: 7.1875rem !important; }
  .pe-xxl-24 {
    padding-right: 7.5rem !important; }
  .pe-xxl-25 {
    padding-right: 7.8125rem !important; }
  .pe-xxl-26 {
    padding-right: 8.125rem !important; }
  .pe-xxl-27 {
    padding-right: 8.4375rem !important; }
  .pe-xxl-28 {
    padding-right: 8.75rem !important; }
  .pe-xxl-29 {
    padding-right: 9.0625rem !important; }
  .pe-xxl-30 {
    padding-right: 9.375rem !important; }
  .pe-xxl-31 {
    padding-right: 9.6875rem !important; }
  .pe-xxl-32 {
    padding-right: 10rem !important; }
  .pe-xxl-33 {
    padding-right: 10.3125rem !important; }
  .pe-xxl-34 {
    padding-right: 10.625rem !important; }
  .pe-xxl-35 {
    padding-right: 10.9375rem !important; }
  .pe-xxl-36 {
    padding-right: 11.25rem !important; }
  .pe-xxl-37 {
    padding-right: 11.5625rem !important; }
  .pe-xxl-38 {
    padding-right: 11.875rem !important; }
  .pe-xxl-39 {
    padding-right: 12.1875rem !important; }
  .pe-xxl-40 {
    padding-right: 12.5rem !important; }
  .pe-xxl-base {
    padding-right: calc(20rem / 16) !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.3125rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.625rem !important; }
  .pb-xxl-3 {
    padding-bottom: 0.9375rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.25rem !important; }
  .pb-xxl-5 {
    padding-bottom: 1.5625rem !important; }
  .pb-xxl-6 {
    padding-bottom: 1.875rem !important; }
  .pb-xxl-7 {
    padding-bottom: 2.1875rem !important; }
  .pb-xxl-8 {
    padding-bottom: 2.5rem !important; }
  .pb-xxl-9 {
    padding-bottom: 2.8125rem !important; }
  .pb-xxl-10 {
    padding-bottom: 3.125rem !important; }
  .pb-xxl-11 {
    padding-bottom: 3.4375rem !important; }
  .pb-xxl-12 {
    padding-bottom: 3.75rem !important; }
  .pb-xxl-13 {
    padding-bottom: 4.0625rem !important; }
  .pb-xxl-14 {
    padding-bottom: 4.375rem !important; }
  .pb-xxl-15 {
    padding-bottom: 4.6875rem !important; }
  .pb-xxl-16 {
    padding-bottom: 5rem !important; }
  .pb-xxl-17 {
    padding-bottom: 5.3125rem !important; }
  .pb-xxl-18 {
    padding-bottom: 5.625rem !important; }
  .pb-xxl-19 {
    padding-bottom: 5.9375rem !important; }
  .pb-xxl-20 {
    padding-bottom: 6.25rem !important; }
  .pb-xxl-21 {
    padding-bottom: 6.5625rem !important; }
  .pb-xxl-22 {
    padding-bottom: 6.875rem !important; }
  .pb-xxl-23 {
    padding-bottom: 7.1875rem !important; }
  .pb-xxl-24 {
    padding-bottom: 7.5rem !important; }
  .pb-xxl-25 {
    padding-bottom: 7.8125rem !important; }
  .pb-xxl-26 {
    padding-bottom: 8.125rem !important; }
  .pb-xxl-27 {
    padding-bottom: 8.4375rem !important; }
  .pb-xxl-28 {
    padding-bottom: 8.75rem !important; }
  .pb-xxl-29 {
    padding-bottom: 9.0625rem !important; }
  .pb-xxl-30 {
    padding-bottom: 9.375rem !important; }
  .pb-xxl-31 {
    padding-bottom: 9.6875rem !important; }
  .pb-xxl-32 {
    padding-bottom: 10rem !important; }
  .pb-xxl-33 {
    padding-bottom: 10.3125rem !important; }
  .pb-xxl-34 {
    padding-bottom: 10.625rem !important; }
  .pb-xxl-35 {
    padding-bottom: 10.9375rem !important; }
  .pb-xxl-36 {
    padding-bottom: 11.25rem !important; }
  .pb-xxl-37 {
    padding-bottom: 11.5625rem !important; }
  .pb-xxl-38 {
    padding-bottom: 11.875rem !important; }
  .pb-xxl-39 {
    padding-bottom: 12.1875rem !important; }
  .pb-xxl-40 {
    padding-bottom: 12.5rem !important; }
  .pb-xxl-base {
    padding-bottom: calc(20rem / 16) !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.3125rem !important; }
  .ps-xxl-2 {
    padding-left: 0.625rem !important; }
  .ps-xxl-3 {
    padding-left: 0.9375rem !important; }
  .ps-xxl-4 {
    padding-left: 1.25rem !important; }
  .ps-xxl-5 {
    padding-left: 1.5625rem !important; }
  .ps-xxl-6 {
    padding-left: 1.875rem !important; }
  .ps-xxl-7 {
    padding-left: 2.1875rem !important; }
  .ps-xxl-8 {
    padding-left: 2.5rem !important; }
  .ps-xxl-9 {
    padding-left: 2.8125rem !important; }
  .ps-xxl-10 {
    padding-left: 3.125rem !important; }
  .ps-xxl-11 {
    padding-left: 3.4375rem !important; }
  .ps-xxl-12 {
    padding-left: 3.75rem !important; }
  .ps-xxl-13 {
    padding-left: 4.0625rem !important; }
  .ps-xxl-14 {
    padding-left: 4.375rem !important; }
  .ps-xxl-15 {
    padding-left: 4.6875rem !important; }
  .ps-xxl-16 {
    padding-left: 5rem !important; }
  .ps-xxl-17 {
    padding-left: 5.3125rem !important; }
  .ps-xxl-18 {
    padding-left: 5.625rem !important; }
  .ps-xxl-19 {
    padding-left: 5.9375rem !important; }
  .ps-xxl-20 {
    padding-left: 6.25rem !important; }
  .ps-xxl-21 {
    padding-left: 6.5625rem !important; }
  .ps-xxl-22 {
    padding-left: 6.875rem !important; }
  .ps-xxl-23 {
    padding-left: 7.1875rem !important; }
  .ps-xxl-24 {
    padding-left: 7.5rem !important; }
  .ps-xxl-25 {
    padding-left: 7.8125rem !important; }
  .ps-xxl-26 {
    padding-left: 8.125rem !important; }
  .ps-xxl-27 {
    padding-left: 8.4375rem !important; }
  .ps-xxl-28 {
    padding-left: 8.75rem !important; }
  .ps-xxl-29 {
    padding-left: 9.0625rem !important; }
  .ps-xxl-30 {
    padding-left: 9.375rem !important; }
  .ps-xxl-31 {
    padding-left: 9.6875rem !important; }
  .ps-xxl-32 {
    padding-left: 10rem !important; }
  .ps-xxl-33 {
    padding-left: 10.3125rem !important; }
  .ps-xxl-34 {
    padding-left: 10.625rem !important; }
  .ps-xxl-35 {
    padding-left: 10.9375rem !important; }
  .ps-xxl-36 {
    padding-left: 11.25rem !important; }
  .ps-xxl-37 {
    padding-left: 11.5625rem !important; }
  .ps-xxl-38 {
    padding-left: 11.875rem !important; }
  .ps-xxl-39 {
    padding-left: 12.1875rem !important; }
  .ps-xxl-40 {
    padding-left: 12.5rem !important; }
  .ps-xxl-base {
    padding-left: calc(20rem / 16) !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.3125rem !important; }
  .gap-xxl-2 {
    gap: 0.625rem !important; }
  .gap-xxl-3 {
    gap: 0.9375rem !important; }
  .gap-xxl-4 {
    gap: 1.25rem !important; }
  .gap-xxl-5 {
    gap: 1.5625rem !important; }
  .gap-xxl-6 {
    gap: 1.875rem !important; }
  .gap-xxl-7 {
    gap: 2.1875rem !important; }
  .gap-xxl-8 {
    gap: 2.5rem !important; }
  .gap-xxl-9 {
    gap: 2.8125rem !important; }
  .gap-xxl-10 {
    gap: 3.125rem !important; }
  .gap-xxl-11 {
    gap: 3.4375rem !important; }
  .gap-xxl-12 {
    gap: 3.75rem !important; }
  .gap-xxl-13 {
    gap: 4.0625rem !important; }
  .gap-xxl-14 {
    gap: 4.375rem !important; }
  .gap-xxl-15 {
    gap: 4.6875rem !important; }
  .gap-xxl-16 {
    gap: 5rem !important; }
  .gap-xxl-17 {
    gap: 5.3125rem !important; }
  .gap-xxl-18 {
    gap: 5.625rem !important; }
  .gap-xxl-19 {
    gap: 5.9375rem !important; }
  .gap-xxl-20 {
    gap: 6.25rem !important; }
  .gap-xxl-21 {
    gap: 6.5625rem !important; }
  .gap-xxl-22 {
    gap: 6.875rem !important; }
  .gap-xxl-23 {
    gap: 7.1875rem !important; }
  .gap-xxl-24 {
    gap: 7.5rem !important; }
  .gap-xxl-25 {
    gap: 7.8125rem !important; }
  .gap-xxl-26 {
    gap: 8.125rem !important; }
  .gap-xxl-27 {
    gap: 8.4375rem !important; }
  .gap-xxl-28 {
    gap: 8.75rem !important; }
  .gap-xxl-29 {
    gap: 9.0625rem !important; }
  .gap-xxl-30 {
    gap: 9.375rem !important; }
  .gap-xxl-31 {
    gap: 9.6875rem !important; }
  .gap-xxl-32 {
    gap: 10rem !important; }
  .gap-xxl-33 {
    gap: 10.3125rem !important; }
  .gap-xxl-34 {
    gap: 10.625rem !important; }
  .gap-xxl-35 {
    gap: 10.9375rem !important; }
  .gap-xxl-36 {
    gap: 11.25rem !important; }
  .gap-xxl-37 {
    gap: 11.5625rem !important; }
  .gap-xxl-38 {
    gap: 11.875rem !important; }
  .gap-xxl-39 {
    gap: 12.1875rem !important; }
  .gap-xxl-40 {
    gap: 12.5rem !important; }
  .gap-xxl-base {
    gap: calc(20rem / 16) !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1921px) {
  .float-xxxl-start {
    float: left !important; }
  .float-xxxl-end {
    float: right !important; }
  .float-xxxl-none {
    float: none !important; }
  .d-xxxl-inline {
    display: inline !important; }
  .d-xxxl-inline-block {
    display: inline-block !important; }
  .d-xxxl-block {
    display: block !important; }
  .d-xxxl-grid {
    display: grid !important; }
  .d-xxxl-table {
    display: table !important; }
  .d-xxxl-table-row {
    display: table-row !important; }
  .d-xxxl-table-cell {
    display: table-cell !important; }
  .d-xxxl-flex {
    display: flex !important; }
  .d-xxxl-inline-flex {
    display: inline-flex !important; }
  .d-xxxl-none {
    display: none !important; }
  .flex-xxxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxxl-row {
    flex-direction: row !important; }
  .flex-xxxl-column {
    flex-direction: column !important; }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxxl-center {
    justify-content: center !important; }
  .justify-content-xxxl-between {
    justify-content: space-between !important; }
  .justify-content-xxxl-around {
    justify-content: space-around !important; }
  .justify-content-xxxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxxl-start {
    align-items: flex-start !important; }
  .align-items-xxxl-end {
    align-items: flex-end !important; }
  .align-items-xxxl-center {
    align-items: center !important; }
  .align-items-xxxl-baseline {
    align-items: baseline !important; }
  .align-items-xxxl-stretch {
    align-items: stretch !important; }
  .align-content-xxxl-start {
    align-content: flex-start !important; }
  .align-content-xxxl-end {
    align-content: flex-end !important; }
  .align-content-xxxl-center {
    align-content: center !important; }
  .align-content-xxxl-between {
    align-content: space-between !important; }
  .align-content-xxxl-around {
    align-content: space-around !important; }
  .align-content-xxxl-stretch {
    align-content: stretch !important; }
  .align-self-xxxl-auto {
    align-self: auto !important; }
  .align-self-xxxl-start {
    align-self: flex-start !important; }
  .align-self-xxxl-end {
    align-self: flex-end !important; }
  .align-self-xxxl-center {
    align-self: center !important; }
  .align-self-xxxl-baseline {
    align-self: baseline !important; }
  .align-self-xxxl-stretch {
    align-self: stretch !important; }
  .order-xxxl-first {
    order: -1 !important; }
  .order-xxxl-0 {
    order: 0 !important; }
  .order-xxxl-1 {
    order: 1 !important; }
  .order-xxxl-2 {
    order: 2 !important; }
  .order-xxxl-3 {
    order: 3 !important; }
  .order-xxxl-4 {
    order: 4 !important; }
  .order-xxxl-5 {
    order: 5 !important; }
  .order-xxxl-last {
    order: 6 !important; }
  .m-xxxl-0 {
    margin: 0 !important; }
  .m-xxxl-1 {
    margin: 0.3125rem !important; }
  .m-xxxl-2 {
    margin: 0.625rem !important; }
  .m-xxxl-3 {
    margin: 0.9375rem !important; }
  .m-xxxl-4 {
    margin: 1.25rem !important; }
  .m-xxxl-5 {
    margin: 1.5625rem !important; }
  .m-xxxl-6 {
    margin: 1.875rem !important; }
  .m-xxxl-7 {
    margin: 2.1875rem !important; }
  .m-xxxl-8 {
    margin: 2.5rem !important; }
  .m-xxxl-9 {
    margin: 2.8125rem !important; }
  .m-xxxl-10 {
    margin: 3.125rem !important; }
  .m-xxxl-11 {
    margin: 3.4375rem !important; }
  .m-xxxl-12 {
    margin: 3.75rem !important; }
  .m-xxxl-13 {
    margin: 4.0625rem !important; }
  .m-xxxl-14 {
    margin: 4.375rem !important; }
  .m-xxxl-15 {
    margin: 4.6875rem !important; }
  .m-xxxl-16 {
    margin: 5rem !important; }
  .m-xxxl-17 {
    margin: 5.3125rem !important; }
  .m-xxxl-18 {
    margin: 5.625rem !important; }
  .m-xxxl-19 {
    margin: 5.9375rem !important; }
  .m-xxxl-20 {
    margin: 6.25rem !important; }
  .m-xxxl-21 {
    margin: 6.5625rem !important; }
  .m-xxxl-22 {
    margin: 6.875rem !important; }
  .m-xxxl-23 {
    margin: 7.1875rem !important; }
  .m-xxxl-24 {
    margin: 7.5rem !important; }
  .m-xxxl-25 {
    margin: 7.8125rem !important; }
  .m-xxxl-26 {
    margin: 8.125rem !important; }
  .m-xxxl-27 {
    margin: 8.4375rem !important; }
  .m-xxxl-28 {
    margin: 8.75rem !important; }
  .m-xxxl-29 {
    margin: 9.0625rem !important; }
  .m-xxxl-30 {
    margin: 9.375rem !important; }
  .m-xxxl-31 {
    margin: 9.6875rem !important; }
  .m-xxxl-32 {
    margin: 10rem !important; }
  .m-xxxl-33 {
    margin: 10.3125rem !important; }
  .m-xxxl-34 {
    margin: 10.625rem !important; }
  .m-xxxl-35 {
    margin: 10.9375rem !important; }
  .m-xxxl-36 {
    margin: 11.25rem !important; }
  .m-xxxl-37 {
    margin: 11.5625rem !important; }
  .m-xxxl-38 {
    margin: 11.875rem !important; }
  .m-xxxl-39 {
    margin: 12.1875rem !important; }
  .m-xxxl-40 {
    margin: 12.5rem !important; }
  .m-xxxl-base {
    margin: calc(20rem / 16) !important; }
  .m-xxxl-auto {
    margin: auto !important; }
  .mx-xxxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxxl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important; }
  .mx-xxxl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important; }
  .mx-xxxl-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important; }
  .mx-xxxl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important; }
  .mx-xxxl-5 {
    margin-right: 1.5625rem !important;
    margin-left: 1.5625rem !important; }
  .mx-xxxl-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important; }
  .mx-xxxl-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important; }
  .mx-xxxl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-xxxl-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important; }
  .mx-xxxl-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important; }
  .mx-xxxl-11 {
    margin-right: 3.4375rem !important;
    margin-left: 3.4375rem !important; }
  .mx-xxxl-12 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important; }
  .mx-xxxl-13 {
    margin-right: 4.0625rem !important;
    margin-left: 4.0625rem !important; }
  .mx-xxxl-14 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important; }
  .mx-xxxl-15 {
    margin-right: 4.6875rem !important;
    margin-left: 4.6875rem !important; }
  .mx-xxxl-16 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-xxxl-17 {
    margin-right: 5.3125rem !important;
    margin-left: 5.3125rem !important; }
  .mx-xxxl-18 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important; }
  .mx-xxxl-19 {
    margin-right: 5.9375rem !important;
    margin-left: 5.9375rem !important; }
  .mx-xxxl-20 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important; }
  .mx-xxxl-21 {
    margin-right: 6.5625rem !important;
    margin-left: 6.5625rem !important; }
  .mx-xxxl-22 {
    margin-right: 6.875rem !important;
    margin-left: 6.875rem !important; }
  .mx-xxxl-23 {
    margin-right: 7.1875rem !important;
    margin-left: 7.1875rem !important; }
  .mx-xxxl-24 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-xxxl-25 {
    margin-right: 7.8125rem !important;
    margin-left: 7.8125rem !important; }
  .mx-xxxl-26 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important; }
  .mx-xxxl-27 {
    margin-right: 8.4375rem !important;
    margin-left: 8.4375rem !important; }
  .mx-xxxl-28 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important; }
  .mx-xxxl-29 {
    margin-right: 9.0625rem !important;
    margin-left: 9.0625rem !important; }
  .mx-xxxl-30 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important; }
  .mx-xxxl-31 {
    margin-right: 9.6875rem !important;
    margin-left: 9.6875rem !important; }
  .mx-xxxl-32 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-xxxl-33 {
    margin-right: 10.3125rem !important;
    margin-left: 10.3125rem !important; }
  .mx-xxxl-34 {
    margin-right: 10.625rem !important;
    margin-left: 10.625rem !important; }
  .mx-xxxl-35 {
    margin-right: 10.9375rem !important;
    margin-left: 10.9375rem !important; }
  .mx-xxxl-36 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important; }
  .mx-xxxl-37 {
    margin-right: 11.5625rem !important;
    margin-left: 11.5625rem !important; }
  .mx-xxxl-38 {
    margin-right: 11.875rem !important;
    margin-left: 11.875rem !important; }
  .mx-xxxl-39 {
    margin-right: 12.1875rem !important;
    margin-left: 12.1875rem !important; }
  .mx-xxxl-40 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-xxxl-base {
    margin-right: calc(20rem / 16) !important;
    margin-left: calc(20rem / 16) !important; }
  .mx-xxxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxxl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important; }
  .my-xxxl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important; }
  .my-xxxl-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important; }
  .my-xxxl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .my-xxxl-5 {
    margin-top: 1.5625rem !important;
    margin-bottom: 1.5625rem !important; }
  .my-xxxl-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important; }
  .my-xxxl-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important; }
  .my-xxxl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-xxxl-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important; }
  .my-xxxl-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important; }
  .my-xxxl-11 {
    margin-top: 3.4375rem !important;
    margin-bottom: 3.4375rem !important; }
  .my-xxxl-12 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important; }
  .my-xxxl-13 {
    margin-top: 4.0625rem !important;
    margin-bottom: 4.0625rem !important; }
  .my-xxxl-14 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important; }
  .my-xxxl-15 {
    margin-top: 4.6875rem !important;
    margin-bottom: 4.6875rem !important; }
  .my-xxxl-16 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-xxxl-17 {
    margin-top: 5.3125rem !important;
    margin-bottom: 5.3125rem !important; }
  .my-xxxl-18 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important; }
  .my-xxxl-19 {
    margin-top: 5.9375rem !important;
    margin-bottom: 5.9375rem !important; }
  .my-xxxl-20 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important; }
  .my-xxxl-21 {
    margin-top: 6.5625rem !important;
    margin-bottom: 6.5625rem !important; }
  .my-xxxl-22 {
    margin-top: 6.875rem !important;
    margin-bottom: 6.875rem !important; }
  .my-xxxl-23 {
    margin-top: 7.1875rem !important;
    margin-bottom: 7.1875rem !important; }
  .my-xxxl-24 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-xxxl-25 {
    margin-top: 7.8125rem !important;
    margin-bottom: 7.8125rem !important; }
  .my-xxxl-26 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important; }
  .my-xxxl-27 {
    margin-top: 8.4375rem !important;
    margin-bottom: 8.4375rem !important; }
  .my-xxxl-28 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important; }
  .my-xxxl-29 {
    margin-top: 9.0625rem !important;
    margin-bottom: 9.0625rem !important; }
  .my-xxxl-30 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important; }
  .my-xxxl-31 {
    margin-top: 9.6875rem !important;
    margin-bottom: 9.6875rem !important; }
  .my-xxxl-32 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-xxxl-33 {
    margin-top: 10.3125rem !important;
    margin-bottom: 10.3125rem !important; }
  .my-xxxl-34 {
    margin-top: 10.625rem !important;
    margin-bottom: 10.625rem !important; }
  .my-xxxl-35 {
    margin-top: 10.9375rem !important;
    margin-bottom: 10.9375rem !important; }
  .my-xxxl-36 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important; }
  .my-xxxl-37 {
    margin-top: 11.5625rem !important;
    margin-bottom: 11.5625rem !important; }
  .my-xxxl-38 {
    margin-top: 11.875rem !important;
    margin-bottom: 11.875rem !important; }
  .my-xxxl-39 {
    margin-top: 12.1875rem !important;
    margin-bottom: 12.1875rem !important; }
  .my-xxxl-40 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-xxxl-base {
    margin-top: calc(20rem / 16) !important;
    margin-bottom: calc(20rem / 16) !important; }
  .my-xxxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxxl-0 {
    margin-top: 0 !important; }
  .mt-xxxl-1 {
    margin-top: 0.3125rem !important; }
  .mt-xxxl-2 {
    margin-top: 0.625rem !important; }
  .mt-xxxl-3 {
    margin-top: 0.9375rem !important; }
  .mt-xxxl-4 {
    margin-top: 1.25rem !important; }
  .mt-xxxl-5 {
    margin-top: 1.5625rem !important; }
  .mt-xxxl-6 {
    margin-top: 1.875rem !important; }
  .mt-xxxl-7 {
    margin-top: 2.1875rem !important; }
  .mt-xxxl-8 {
    margin-top: 2.5rem !important; }
  .mt-xxxl-9 {
    margin-top: 2.8125rem !important; }
  .mt-xxxl-10 {
    margin-top: 3.125rem !important; }
  .mt-xxxl-11 {
    margin-top: 3.4375rem !important; }
  .mt-xxxl-12 {
    margin-top: 3.75rem !important; }
  .mt-xxxl-13 {
    margin-top: 4.0625rem !important; }
  .mt-xxxl-14 {
    margin-top: 4.375rem !important; }
  .mt-xxxl-15 {
    margin-top: 4.6875rem !important; }
  .mt-xxxl-16 {
    margin-top: 5rem !important; }
  .mt-xxxl-17 {
    margin-top: 5.3125rem !important; }
  .mt-xxxl-18 {
    margin-top: 5.625rem !important; }
  .mt-xxxl-19 {
    margin-top: 5.9375rem !important; }
  .mt-xxxl-20 {
    margin-top: 6.25rem !important; }
  .mt-xxxl-21 {
    margin-top: 6.5625rem !important; }
  .mt-xxxl-22 {
    margin-top: 6.875rem !important; }
  .mt-xxxl-23 {
    margin-top: 7.1875rem !important; }
  .mt-xxxl-24 {
    margin-top: 7.5rem !important; }
  .mt-xxxl-25 {
    margin-top: 7.8125rem !important; }
  .mt-xxxl-26 {
    margin-top: 8.125rem !important; }
  .mt-xxxl-27 {
    margin-top: 8.4375rem !important; }
  .mt-xxxl-28 {
    margin-top: 8.75rem !important; }
  .mt-xxxl-29 {
    margin-top: 9.0625rem !important; }
  .mt-xxxl-30 {
    margin-top: 9.375rem !important; }
  .mt-xxxl-31 {
    margin-top: 9.6875rem !important; }
  .mt-xxxl-32 {
    margin-top: 10rem !important; }
  .mt-xxxl-33 {
    margin-top: 10.3125rem !important; }
  .mt-xxxl-34 {
    margin-top: 10.625rem !important; }
  .mt-xxxl-35 {
    margin-top: 10.9375rem !important; }
  .mt-xxxl-36 {
    margin-top: 11.25rem !important; }
  .mt-xxxl-37 {
    margin-top: 11.5625rem !important; }
  .mt-xxxl-38 {
    margin-top: 11.875rem !important; }
  .mt-xxxl-39 {
    margin-top: 12.1875rem !important; }
  .mt-xxxl-40 {
    margin-top: 12.5rem !important; }
  .mt-xxxl-base {
    margin-top: calc(20rem / 16) !important; }
  .mt-xxxl-auto {
    margin-top: auto !important; }
  .me-xxxl-0 {
    margin-right: 0 !important; }
  .me-xxxl-1 {
    margin-right: 0.3125rem !important; }
  .me-xxxl-2 {
    margin-right: 0.625rem !important; }
  .me-xxxl-3 {
    margin-right: 0.9375rem !important; }
  .me-xxxl-4 {
    margin-right: 1.25rem !important; }
  .me-xxxl-5 {
    margin-right: 1.5625rem !important; }
  .me-xxxl-6 {
    margin-right: 1.875rem !important; }
  .me-xxxl-7 {
    margin-right: 2.1875rem !important; }
  .me-xxxl-8 {
    margin-right: 2.5rem !important; }
  .me-xxxl-9 {
    margin-right: 2.8125rem !important; }
  .me-xxxl-10 {
    margin-right: 3.125rem !important; }
  .me-xxxl-11 {
    margin-right: 3.4375rem !important; }
  .me-xxxl-12 {
    margin-right: 3.75rem !important; }
  .me-xxxl-13 {
    margin-right: 4.0625rem !important; }
  .me-xxxl-14 {
    margin-right: 4.375rem !important; }
  .me-xxxl-15 {
    margin-right: 4.6875rem !important; }
  .me-xxxl-16 {
    margin-right: 5rem !important; }
  .me-xxxl-17 {
    margin-right: 5.3125rem !important; }
  .me-xxxl-18 {
    margin-right: 5.625rem !important; }
  .me-xxxl-19 {
    margin-right: 5.9375rem !important; }
  .me-xxxl-20 {
    margin-right: 6.25rem !important; }
  .me-xxxl-21 {
    margin-right: 6.5625rem !important; }
  .me-xxxl-22 {
    margin-right: 6.875rem !important; }
  .me-xxxl-23 {
    margin-right: 7.1875rem !important; }
  .me-xxxl-24 {
    margin-right: 7.5rem !important; }
  .me-xxxl-25 {
    margin-right: 7.8125rem !important; }
  .me-xxxl-26 {
    margin-right: 8.125rem !important; }
  .me-xxxl-27 {
    margin-right: 8.4375rem !important; }
  .me-xxxl-28 {
    margin-right: 8.75rem !important; }
  .me-xxxl-29 {
    margin-right: 9.0625rem !important; }
  .me-xxxl-30 {
    margin-right: 9.375rem !important; }
  .me-xxxl-31 {
    margin-right: 9.6875rem !important; }
  .me-xxxl-32 {
    margin-right: 10rem !important; }
  .me-xxxl-33 {
    margin-right: 10.3125rem !important; }
  .me-xxxl-34 {
    margin-right: 10.625rem !important; }
  .me-xxxl-35 {
    margin-right: 10.9375rem !important; }
  .me-xxxl-36 {
    margin-right: 11.25rem !important; }
  .me-xxxl-37 {
    margin-right: 11.5625rem !important; }
  .me-xxxl-38 {
    margin-right: 11.875rem !important; }
  .me-xxxl-39 {
    margin-right: 12.1875rem !important; }
  .me-xxxl-40 {
    margin-right: 12.5rem !important; }
  .me-xxxl-base {
    margin-right: calc(20rem / 16) !important; }
  .me-xxxl-auto {
    margin-right: auto !important; }
  .mb-xxxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxxl-1 {
    margin-bottom: 0.3125rem !important; }
  .mb-xxxl-2 {
    margin-bottom: 0.625rem !important; }
  .mb-xxxl-3 {
    margin-bottom: 0.9375rem !important; }
  .mb-xxxl-4 {
    margin-bottom: 1.25rem !important; }
  .mb-xxxl-5 {
    margin-bottom: 1.5625rem !important; }
  .mb-xxxl-6 {
    margin-bottom: 1.875rem !important; }
  .mb-xxxl-7 {
    margin-bottom: 2.1875rem !important; }
  .mb-xxxl-8 {
    margin-bottom: 2.5rem !important; }
  .mb-xxxl-9 {
    margin-bottom: 2.8125rem !important; }
  .mb-xxxl-10 {
    margin-bottom: 3.125rem !important; }
  .mb-xxxl-11 {
    margin-bottom: 3.4375rem !important; }
  .mb-xxxl-12 {
    margin-bottom: 3.75rem !important; }
  .mb-xxxl-13 {
    margin-bottom: 4.0625rem !important; }
  .mb-xxxl-14 {
    margin-bottom: 4.375rem !important; }
  .mb-xxxl-15 {
    margin-bottom: 4.6875rem !important; }
  .mb-xxxl-16 {
    margin-bottom: 5rem !important; }
  .mb-xxxl-17 {
    margin-bottom: 5.3125rem !important; }
  .mb-xxxl-18 {
    margin-bottom: 5.625rem !important; }
  .mb-xxxl-19 {
    margin-bottom: 5.9375rem !important; }
  .mb-xxxl-20 {
    margin-bottom: 6.25rem !important; }
  .mb-xxxl-21 {
    margin-bottom: 6.5625rem !important; }
  .mb-xxxl-22 {
    margin-bottom: 6.875rem !important; }
  .mb-xxxl-23 {
    margin-bottom: 7.1875rem !important; }
  .mb-xxxl-24 {
    margin-bottom: 7.5rem !important; }
  .mb-xxxl-25 {
    margin-bottom: 7.8125rem !important; }
  .mb-xxxl-26 {
    margin-bottom: 8.125rem !important; }
  .mb-xxxl-27 {
    margin-bottom: 8.4375rem !important; }
  .mb-xxxl-28 {
    margin-bottom: 8.75rem !important; }
  .mb-xxxl-29 {
    margin-bottom: 9.0625rem !important; }
  .mb-xxxl-30 {
    margin-bottom: 9.375rem !important; }
  .mb-xxxl-31 {
    margin-bottom: 9.6875rem !important; }
  .mb-xxxl-32 {
    margin-bottom: 10rem !important; }
  .mb-xxxl-33 {
    margin-bottom: 10.3125rem !important; }
  .mb-xxxl-34 {
    margin-bottom: 10.625rem !important; }
  .mb-xxxl-35 {
    margin-bottom: 10.9375rem !important; }
  .mb-xxxl-36 {
    margin-bottom: 11.25rem !important; }
  .mb-xxxl-37 {
    margin-bottom: 11.5625rem !important; }
  .mb-xxxl-38 {
    margin-bottom: 11.875rem !important; }
  .mb-xxxl-39 {
    margin-bottom: 12.1875rem !important; }
  .mb-xxxl-40 {
    margin-bottom: 12.5rem !important; }
  .mb-xxxl-base {
    margin-bottom: calc(20rem / 16) !important; }
  .mb-xxxl-auto {
    margin-bottom: auto !important; }
  .ms-xxxl-0 {
    margin-left: 0 !important; }
  .ms-xxxl-1 {
    margin-left: 0.3125rem !important; }
  .ms-xxxl-2 {
    margin-left: 0.625rem !important; }
  .ms-xxxl-3 {
    margin-left: 0.9375rem !important; }
  .ms-xxxl-4 {
    margin-left: 1.25rem !important; }
  .ms-xxxl-5 {
    margin-left: 1.5625rem !important; }
  .ms-xxxl-6 {
    margin-left: 1.875rem !important; }
  .ms-xxxl-7 {
    margin-left: 2.1875rem !important; }
  .ms-xxxl-8 {
    margin-left: 2.5rem !important; }
  .ms-xxxl-9 {
    margin-left: 2.8125rem !important; }
  .ms-xxxl-10 {
    margin-left: 3.125rem !important; }
  .ms-xxxl-11 {
    margin-left: 3.4375rem !important; }
  .ms-xxxl-12 {
    margin-left: 3.75rem !important; }
  .ms-xxxl-13 {
    margin-left: 4.0625rem !important; }
  .ms-xxxl-14 {
    margin-left: 4.375rem !important; }
  .ms-xxxl-15 {
    margin-left: 4.6875rem !important; }
  .ms-xxxl-16 {
    margin-left: 5rem !important; }
  .ms-xxxl-17 {
    margin-left: 5.3125rem !important; }
  .ms-xxxl-18 {
    margin-left: 5.625rem !important; }
  .ms-xxxl-19 {
    margin-left: 5.9375rem !important; }
  .ms-xxxl-20 {
    margin-left: 6.25rem !important; }
  .ms-xxxl-21 {
    margin-left: 6.5625rem !important; }
  .ms-xxxl-22 {
    margin-left: 6.875rem !important; }
  .ms-xxxl-23 {
    margin-left: 7.1875rem !important; }
  .ms-xxxl-24 {
    margin-left: 7.5rem !important; }
  .ms-xxxl-25 {
    margin-left: 7.8125rem !important; }
  .ms-xxxl-26 {
    margin-left: 8.125rem !important; }
  .ms-xxxl-27 {
    margin-left: 8.4375rem !important; }
  .ms-xxxl-28 {
    margin-left: 8.75rem !important; }
  .ms-xxxl-29 {
    margin-left: 9.0625rem !important; }
  .ms-xxxl-30 {
    margin-left: 9.375rem !important; }
  .ms-xxxl-31 {
    margin-left: 9.6875rem !important; }
  .ms-xxxl-32 {
    margin-left: 10rem !important; }
  .ms-xxxl-33 {
    margin-left: 10.3125rem !important; }
  .ms-xxxl-34 {
    margin-left: 10.625rem !important; }
  .ms-xxxl-35 {
    margin-left: 10.9375rem !important; }
  .ms-xxxl-36 {
    margin-left: 11.25rem !important; }
  .ms-xxxl-37 {
    margin-left: 11.5625rem !important; }
  .ms-xxxl-38 {
    margin-left: 11.875rem !important; }
  .ms-xxxl-39 {
    margin-left: 12.1875rem !important; }
  .ms-xxxl-40 {
    margin-left: 12.5rem !important; }
  .ms-xxxl-base {
    margin-left: calc(20rem / 16) !important; }
  .ms-xxxl-auto {
    margin-left: auto !important; }
  .m-xxxl-n1 {
    margin: -0.3125rem !important; }
  .m-xxxl-n2 {
    margin: -0.625rem !important; }
  .m-xxxl-n3 {
    margin: -0.9375rem !important; }
  .m-xxxl-n4 {
    margin: -1.25rem !important; }
  .m-xxxl-n5 {
    margin: -1.5625rem !important; }
  .m-xxxl-n6 {
    margin: -1.875rem !important; }
  .m-xxxl-n7 {
    margin: -2.1875rem !important; }
  .m-xxxl-n8 {
    margin: -2.5rem !important; }
  .m-xxxl-n9 {
    margin: -2.8125rem !important; }
  .m-xxxl-n10 {
    margin: -3.125rem !important; }
  .m-xxxl-n11 {
    margin: -3.4375rem !important; }
  .m-xxxl-n12 {
    margin: -3.75rem !important; }
  .m-xxxl-n13 {
    margin: -4.0625rem !important; }
  .m-xxxl-n14 {
    margin: -4.375rem !important; }
  .m-xxxl-n15 {
    margin: -4.6875rem !important; }
  .m-xxxl-n16 {
    margin: -5rem !important; }
  .m-xxxl-n17 {
    margin: -5.3125rem !important; }
  .m-xxxl-n18 {
    margin: -5.625rem !important; }
  .m-xxxl-n19 {
    margin: -5.9375rem !important; }
  .m-xxxl-n20 {
    margin: -6.25rem !important; }
  .m-xxxl-n21 {
    margin: -6.5625rem !important; }
  .m-xxxl-n22 {
    margin: -6.875rem !important; }
  .m-xxxl-n23 {
    margin: -7.1875rem !important; }
  .m-xxxl-n24 {
    margin: -7.5rem !important; }
  .m-xxxl-n25 {
    margin: -7.8125rem !important; }
  .m-xxxl-n26 {
    margin: -8.125rem !important; }
  .m-xxxl-n27 {
    margin: -8.4375rem !important; }
  .m-xxxl-n28 {
    margin: -8.75rem !important; }
  .m-xxxl-n29 {
    margin: -9.0625rem !important; }
  .m-xxxl-n30 {
    margin: -9.375rem !important; }
  .m-xxxl-n31 {
    margin: -9.6875rem !important; }
  .m-xxxl-n32 {
    margin: -10rem !important; }
  .m-xxxl-n33 {
    margin: -10.3125rem !important; }
  .m-xxxl-n34 {
    margin: -10.625rem !important; }
  .m-xxxl-n35 {
    margin: -10.9375rem !important; }
  .m-xxxl-n36 {
    margin: -11.25rem !important; }
  .m-xxxl-n37 {
    margin: -11.5625rem !important; }
  .m-xxxl-n38 {
    margin: -11.875rem !important; }
  .m-xxxl-n39 {
    margin: -12.1875rem !important; }
  .m-xxxl-n40 {
    margin: -12.5rem !important; }
  .m-xxxl-nbase {
    margin: -calc(20rem / 16) !important; }
  .mx-xxxl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important; }
  .mx-xxxl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important; }
  .mx-xxxl-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important; }
  .mx-xxxl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important; }
  .mx-xxxl-n5 {
    margin-right: -1.5625rem !important;
    margin-left: -1.5625rem !important; }
  .mx-xxxl-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important; }
  .mx-xxxl-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important; }
  .mx-xxxl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-xxxl-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important; }
  .mx-xxxl-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important; }
  .mx-xxxl-n11 {
    margin-right: -3.4375rem !important;
    margin-left: -3.4375rem !important; }
  .mx-xxxl-n12 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important; }
  .mx-xxxl-n13 {
    margin-right: -4.0625rem !important;
    margin-left: -4.0625rem !important; }
  .mx-xxxl-n14 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important; }
  .mx-xxxl-n15 {
    margin-right: -4.6875rem !important;
    margin-left: -4.6875rem !important; }
  .mx-xxxl-n16 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-xxxl-n17 {
    margin-right: -5.3125rem !important;
    margin-left: -5.3125rem !important; }
  .mx-xxxl-n18 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important; }
  .mx-xxxl-n19 {
    margin-right: -5.9375rem !important;
    margin-left: -5.9375rem !important; }
  .mx-xxxl-n20 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important; }
  .mx-xxxl-n21 {
    margin-right: -6.5625rem !important;
    margin-left: -6.5625rem !important; }
  .mx-xxxl-n22 {
    margin-right: -6.875rem !important;
    margin-left: -6.875rem !important; }
  .mx-xxxl-n23 {
    margin-right: -7.1875rem !important;
    margin-left: -7.1875rem !important; }
  .mx-xxxl-n24 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-xxxl-n25 {
    margin-right: -7.8125rem !important;
    margin-left: -7.8125rem !important; }
  .mx-xxxl-n26 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important; }
  .mx-xxxl-n27 {
    margin-right: -8.4375rem !important;
    margin-left: -8.4375rem !important; }
  .mx-xxxl-n28 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important; }
  .mx-xxxl-n29 {
    margin-right: -9.0625rem !important;
    margin-left: -9.0625rem !important; }
  .mx-xxxl-n30 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important; }
  .mx-xxxl-n31 {
    margin-right: -9.6875rem !important;
    margin-left: -9.6875rem !important; }
  .mx-xxxl-n32 {
    margin-right: -10rem !important;
    margin-left: -10rem !important; }
  .mx-xxxl-n33 {
    margin-right: -10.3125rem !important;
    margin-left: -10.3125rem !important; }
  .mx-xxxl-n34 {
    margin-right: -10.625rem !important;
    margin-left: -10.625rem !important; }
  .mx-xxxl-n35 {
    margin-right: -10.9375rem !important;
    margin-left: -10.9375rem !important; }
  .mx-xxxl-n36 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important; }
  .mx-xxxl-n37 {
    margin-right: -11.5625rem !important;
    margin-left: -11.5625rem !important; }
  .mx-xxxl-n38 {
    margin-right: -11.875rem !important;
    margin-left: -11.875rem !important; }
  .mx-xxxl-n39 {
    margin-right: -12.1875rem !important;
    margin-left: -12.1875rem !important; }
  .mx-xxxl-n40 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important; }
  .mx-xxxl-nbase {
    margin-right: -calc(20rem / 16) !important;
    margin-left: -calc(20rem / 16) !important; }
  .my-xxxl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important; }
  .my-xxxl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important; }
  .my-xxxl-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important; }
  .my-xxxl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .my-xxxl-n5 {
    margin-top: -1.5625rem !important;
    margin-bottom: -1.5625rem !important; }
  .my-xxxl-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important; }
  .my-xxxl-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important; }
  .my-xxxl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-xxxl-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important; }
  .my-xxxl-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important; }
  .my-xxxl-n11 {
    margin-top: -3.4375rem !important;
    margin-bottom: -3.4375rem !important; }
  .my-xxxl-n12 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important; }
  .my-xxxl-n13 {
    margin-top: -4.0625rem !important;
    margin-bottom: -4.0625rem !important; }
  .my-xxxl-n14 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important; }
  .my-xxxl-n15 {
    margin-top: -4.6875rem !important;
    margin-bottom: -4.6875rem !important; }
  .my-xxxl-n16 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-xxxl-n17 {
    margin-top: -5.3125rem !important;
    margin-bottom: -5.3125rem !important; }
  .my-xxxl-n18 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important; }
  .my-xxxl-n19 {
    margin-top: -5.9375rem !important;
    margin-bottom: -5.9375rem !important; }
  .my-xxxl-n20 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important; }
  .my-xxxl-n21 {
    margin-top: -6.5625rem !important;
    margin-bottom: -6.5625rem !important; }
  .my-xxxl-n22 {
    margin-top: -6.875rem !important;
    margin-bottom: -6.875rem !important; }
  .my-xxxl-n23 {
    margin-top: -7.1875rem !important;
    margin-bottom: -7.1875rem !important; }
  .my-xxxl-n24 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-xxxl-n25 {
    margin-top: -7.8125rem !important;
    margin-bottom: -7.8125rem !important; }
  .my-xxxl-n26 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important; }
  .my-xxxl-n27 {
    margin-top: -8.4375rem !important;
    margin-bottom: -8.4375rem !important; }
  .my-xxxl-n28 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important; }
  .my-xxxl-n29 {
    margin-top: -9.0625rem !important;
    margin-bottom: -9.0625rem !important; }
  .my-xxxl-n30 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important; }
  .my-xxxl-n31 {
    margin-top: -9.6875rem !important;
    margin-bottom: -9.6875rem !important; }
  .my-xxxl-n32 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .my-xxxl-n33 {
    margin-top: -10.3125rem !important;
    margin-bottom: -10.3125rem !important; }
  .my-xxxl-n34 {
    margin-top: -10.625rem !important;
    margin-bottom: -10.625rem !important; }
  .my-xxxl-n35 {
    margin-top: -10.9375rem !important;
    margin-bottom: -10.9375rem !important; }
  .my-xxxl-n36 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important; }
  .my-xxxl-n37 {
    margin-top: -11.5625rem !important;
    margin-bottom: -11.5625rem !important; }
  .my-xxxl-n38 {
    margin-top: -11.875rem !important;
    margin-bottom: -11.875rem !important; }
  .my-xxxl-n39 {
    margin-top: -12.1875rem !important;
    margin-bottom: -12.1875rem !important; }
  .my-xxxl-n40 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important; }
  .my-xxxl-nbase {
    margin-top: -calc(20rem / 16) !important;
    margin-bottom: -calc(20rem / 16) !important; }
  .mt-xxxl-n1 {
    margin-top: -0.3125rem !important; }
  .mt-xxxl-n2 {
    margin-top: -0.625rem !important; }
  .mt-xxxl-n3 {
    margin-top: -0.9375rem !important; }
  .mt-xxxl-n4 {
    margin-top: -1.25rem !important; }
  .mt-xxxl-n5 {
    margin-top: -1.5625rem !important; }
  .mt-xxxl-n6 {
    margin-top: -1.875rem !important; }
  .mt-xxxl-n7 {
    margin-top: -2.1875rem !important; }
  .mt-xxxl-n8 {
    margin-top: -2.5rem !important; }
  .mt-xxxl-n9 {
    margin-top: -2.8125rem !important; }
  .mt-xxxl-n10 {
    margin-top: -3.125rem !important; }
  .mt-xxxl-n11 {
    margin-top: -3.4375rem !important; }
  .mt-xxxl-n12 {
    margin-top: -3.75rem !important; }
  .mt-xxxl-n13 {
    margin-top: -4.0625rem !important; }
  .mt-xxxl-n14 {
    margin-top: -4.375rem !important; }
  .mt-xxxl-n15 {
    margin-top: -4.6875rem !important; }
  .mt-xxxl-n16 {
    margin-top: -5rem !important; }
  .mt-xxxl-n17 {
    margin-top: -5.3125rem !important; }
  .mt-xxxl-n18 {
    margin-top: -5.625rem !important; }
  .mt-xxxl-n19 {
    margin-top: -5.9375rem !important; }
  .mt-xxxl-n20 {
    margin-top: -6.25rem !important; }
  .mt-xxxl-n21 {
    margin-top: -6.5625rem !important; }
  .mt-xxxl-n22 {
    margin-top: -6.875rem !important; }
  .mt-xxxl-n23 {
    margin-top: -7.1875rem !important; }
  .mt-xxxl-n24 {
    margin-top: -7.5rem !important; }
  .mt-xxxl-n25 {
    margin-top: -7.8125rem !important; }
  .mt-xxxl-n26 {
    margin-top: -8.125rem !important; }
  .mt-xxxl-n27 {
    margin-top: -8.4375rem !important; }
  .mt-xxxl-n28 {
    margin-top: -8.75rem !important; }
  .mt-xxxl-n29 {
    margin-top: -9.0625rem !important; }
  .mt-xxxl-n30 {
    margin-top: -9.375rem !important; }
  .mt-xxxl-n31 {
    margin-top: -9.6875rem !important; }
  .mt-xxxl-n32 {
    margin-top: -10rem !important; }
  .mt-xxxl-n33 {
    margin-top: -10.3125rem !important; }
  .mt-xxxl-n34 {
    margin-top: -10.625rem !important; }
  .mt-xxxl-n35 {
    margin-top: -10.9375rem !important; }
  .mt-xxxl-n36 {
    margin-top: -11.25rem !important; }
  .mt-xxxl-n37 {
    margin-top: -11.5625rem !important; }
  .mt-xxxl-n38 {
    margin-top: -11.875rem !important; }
  .mt-xxxl-n39 {
    margin-top: -12.1875rem !important; }
  .mt-xxxl-n40 {
    margin-top: -12.5rem !important; }
  .mt-xxxl-nbase {
    margin-top: -calc(20rem / 16) !important; }
  .me-xxxl-n1 {
    margin-right: -0.3125rem !important; }
  .me-xxxl-n2 {
    margin-right: -0.625rem !important; }
  .me-xxxl-n3 {
    margin-right: -0.9375rem !important; }
  .me-xxxl-n4 {
    margin-right: -1.25rem !important; }
  .me-xxxl-n5 {
    margin-right: -1.5625rem !important; }
  .me-xxxl-n6 {
    margin-right: -1.875rem !important; }
  .me-xxxl-n7 {
    margin-right: -2.1875rem !important; }
  .me-xxxl-n8 {
    margin-right: -2.5rem !important; }
  .me-xxxl-n9 {
    margin-right: -2.8125rem !important; }
  .me-xxxl-n10 {
    margin-right: -3.125rem !important; }
  .me-xxxl-n11 {
    margin-right: -3.4375rem !important; }
  .me-xxxl-n12 {
    margin-right: -3.75rem !important; }
  .me-xxxl-n13 {
    margin-right: -4.0625rem !important; }
  .me-xxxl-n14 {
    margin-right: -4.375rem !important; }
  .me-xxxl-n15 {
    margin-right: -4.6875rem !important; }
  .me-xxxl-n16 {
    margin-right: -5rem !important; }
  .me-xxxl-n17 {
    margin-right: -5.3125rem !important; }
  .me-xxxl-n18 {
    margin-right: -5.625rem !important; }
  .me-xxxl-n19 {
    margin-right: -5.9375rem !important; }
  .me-xxxl-n20 {
    margin-right: -6.25rem !important; }
  .me-xxxl-n21 {
    margin-right: -6.5625rem !important; }
  .me-xxxl-n22 {
    margin-right: -6.875rem !important; }
  .me-xxxl-n23 {
    margin-right: -7.1875rem !important; }
  .me-xxxl-n24 {
    margin-right: -7.5rem !important; }
  .me-xxxl-n25 {
    margin-right: -7.8125rem !important; }
  .me-xxxl-n26 {
    margin-right: -8.125rem !important; }
  .me-xxxl-n27 {
    margin-right: -8.4375rem !important; }
  .me-xxxl-n28 {
    margin-right: -8.75rem !important; }
  .me-xxxl-n29 {
    margin-right: -9.0625rem !important; }
  .me-xxxl-n30 {
    margin-right: -9.375rem !important; }
  .me-xxxl-n31 {
    margin-right: -9.6875rem !important; }
  .me-xxxl-n32 {
    margin-right: -10rem !important; }
  .me-xxxl-n33 {
    margin-right: -10.3125rem !important; }
  .me-xxxl-n34 {
    margin-right: -10.625rem !important; }
  .me-xxxl-n35 {
    margin-right: -10.9375rem !important; }
  .me-xxxl-n36 {
    margin-right: -11.25rem !important; }
  .me-xxxl-n37 {
    margin-right: -11.5625rem !important; }
  .me-xxxl-n38 {
    margin-right: -11.875rem !important; }
  .me-xxxl-n39 {
    margin-right: -12.1875rem !important; }
  .me-xxxl-n40 {
    margin-right: -12.5rem !important; }
  .me-xxxl-nbase {
    margin-right: -calc(20rem / 16) !important; }
  .mb-xxxl-n1 {
    margin-bottom: -0.3125rem !important; }
  .mb-xxxl-n2 {
    margin-bottom: -0.625rem !important; }
  .mb-xxxl-n3 {
    margin-bottom: -0.9375rem !important; }
  .mb-xxxl-n4 {
    margin-bottom: -1.25rem !important; }
  .mb-xxxl-n5 {
    margin-bottom: -1.5625rem !important; }
  .mb-xxxl-n6 {
    margin-bottom: -1.875rem !important; }
  .mb-xxxl-n7 {
    margin-bottom: -2.1875rem !important; }
  .mb-xxxl-n8 {
    margin-bottom: -2.5rem !important; }
  .mb-xxxl-n9 {
    margin-bottom: -2.8125rem !important; }
  .mb-xxxl-n10 {
    margin-bottom: -3.125rem !important; }
  .mb-xxxl-n11 {
    margin-bottom: -3.4375rem !important; }
  .mb-xxxl-n12 {
    margin-bottom: -3.75rem !important; }
  .mb-xxxl-n13 {
    margin-bottom: -4.0625rem !important; }
  .mb-xxxl-n14 {
    margin-bottom: -4.375rem !important; }
  .mb-xxxl-n15 {
    margin-bottom: -4.6875rem !important; }
  .mb-xxxl-n16 {
    margin-bottom: -5rem !important; }
  .mb-xxxl-n17 {
    margin-bottom: -5.3125rem !important; }
  .mb-xxxl-n18 {
    margin-bottom: -5.625rem !important; }
  .mb-xxxl-n19 {
    margin-bottom: -5.9375rem !important; }
  .mb-xxxl-n20 {
    margin-bottom: -6.25rem !important; }
  .mb-xxxl-n21 {
    margin-bottom: -6.5625rem !important; }
  .mb-xxxl-n22 {
    margin-bottom: -6.875rem !important; }
  .mb-xxxl-n23 {
    margin-bottom: -7.1875rem !important; }
  .mb-xxxl-n24 {
    margin-bottom: -7.5rem !important; }
  .mb-xxxl-n25 {
    margin-bottom: -7.8125rem !important; }
  .mb-xxxl-n26 {
    margin-bottom: -8.125rem !important; }
  .mb-xxxl-n27 {
    margin-bottom: -8.4375rem !important; }
  .mb-xxxl-n28 {
    margin-bottom: -8.75rem !important; }
  .mb-xxxl-n29 {
    margin-bottom: -9.0625rem !important; }
  .mb-xxxl-n30 {
    margin-bottom: -9.375rem !important; }
  .mb-xxxl-n31 {
    margin-bottom: -9.6875rem !important; }
  .mb-xxxl-n32 {
    margin-bottom: -10rem !important; }
  .mb-xxxl-n33 {
    margin-bottom: -10.3125rem !important; }
  .mb-xxxl-n34 {
    margin-bottom: -10.625rem !important; }
  .mb-xxxl-n35 {
    margin-bottom: -10.9375rem !important; }
  .mb-xxxl-n36 {
    margin-bottom: -11.25rem !important; }
  .mb-xxxl-n37 {
    margin-bottom: -11.5625rem !important; }
  .mb-xxxl-n38 {
    margin-bottom: -11.875rem !important; }
  .mb-xxxl-n39 {
    margin-bottom: -12.1875rem !important; }
  .mb-xxxl-n40 {
    margin-bottom: -12.5rem !important; }
  .mb-xxxl-nbase {
    margin-bottom: -calc(20rem / 16) !important; }
  .ms-xxxl-n1 {
    margin-left: -0.3125rem !important; }
  .ms-xxxl-n2 {
    margin-left: -0.625rem !important; }
  .ms-xxxl-n3 {
    margin-left: -0.9375rem !important; }
  .ms-xxxl-n4 {
    margin-left: -1.25rem !important; }
  .ms-xxxl-n5 {
    margin-left: -1.5625rem !important; }
  .ms-xxxl-n6 {
    margin-left: -1.875rem !important; }
  .ms-xxxl-n7 {
    margin-left: -2.1875rem !important; }
  .ms-xxxl-n8 {
    margin-left: -2.5rem !important; }
  .ms-xxxl-n9 {
    margin-left: -2.8125rem !important; }
  .ms-xxxl-n10 {
    margin-left: -3.125rem !important; }
  .ms-xxxl-n11 {
    margin-left: -3.4375rem !important; }
  .ms-xxxl-n12 {
    margin-left: -3.75rem !important; }
  .ms-xxxl-n13 {
    margin-left: -4.0625rem !important; }
  .ms-xxxl-n14 {
    margin-left: -4.375rem !important; }
  .ms-xxxl-n15 {
    margin-left: -4.6875rem !important; }
  .ms-xxxl-n16 {
    margin-left: -5rem !important; }
  .ms-xxxl-n17 {
    margin-left: -5.3125rem !important; }
  .ms-xxxl-n18 {
    margin-left: -5.625rem !important; }
  .ms-xxxl-n19 {
    margin-left: -5.9375rem !important; }
  .ms-xxxl-n20 {
    margin-left: -6.25rem !important; }
  .ms-xxxl-n21 {
    margin-left: -6.5625rem !important; }
  .ms-xxxl-n22 {
    margin-left: -6.875rem !important; }
  .ms-xxxl-n23 {
    margin-left: -7.1875rem !important; }
  .ms-xxxl-n24 {
    margin-left: -7.5rem !important; }
  .ms-xxxl-n25 {
    margin-left: -7.8125rem !important; }
  .ms-xxxl-n26 {
    margin-left: -8.125rem !important; }
  .ms-xxxl-n27 {
    margin-left: -8.4375rem !important; }
  .ms-xxxl-n28 {
    margin-left: -8.75rem !important; }
  .ms-xxxl-n29 {
    margin-left: -9.0625rem !important; }
  .ms-xxxl-n30 {
    margin-left: -9.375rem !important; }
  .ms-xxxl-n31 {
    margin-left: -9.6875rem !important; }
  .ms-xxxl-n32 {
    margin-left: -10rem !important; }
  .ms-xxxl-n33 {
    margin-left: -10.3125rem !important; }
  .ms-xxxl-n34 {
    margin-left: -10.625rem !important; }
  .ms-xxxl-n35 {
    margin-left: -10.9375rem !important; }
  .ms-xxxl-n36 {
    margin-left: -11.25rem !important; }
  .ms-xxxl-n37 {
    margin-left: -11.5625rem !important; }
  .ms-xxxl-n38 {
    margin-left: -11.875rem !important; }
  .ms-xxxl-n39 {
    margin-left: -12.1875rem !important; }
  .ms-xxxl-n40 {
    margin-left: -12.5rem !important; }
  .ms-xxxl-nbase {
    margin-left: -calc(20rem / 16) !important; }
  .p-xxxl-0 {
    padding: 0 !important; }
  .p-xxxl-1 {
    padding: 0.3125rem !important; }
  .p-xxxl-2 {
    padding: 0.625rem !important; }
  .p-xxxl-3 {
    padding: 0.9375rem !important; }
  .p-xxxl-4 {
    padding: 1.25rem !important; }
  .p-xxxl-5 {
    padding: 1.5625rem !important; }
  .p-xxxl-6 {
    padding: 1.875rem !important; }
  .p-xxxl-7 {
    padding: 2.1875rem !important; }
  .p-xxxl-8 {
    padding: 2.5rem !important; }
  .p-xxxl-9 {
    padding: 2.8125rem !important; }
  .p-xxxl-10 {
    padding: 3.125rem !important; }
  .p-xxxl-11 {
    padding: 3.4375rem !important; }
  .p-xxxl-12 {
    padding: 3.75rem !important; }
  .p-xxxl-13 {
    padding: 4.0625rem !important; }
  .p-xxxl-14 {
    padding: 4.375rem !important; }
  .p-xxxl-15 {
    padding: 4.6875rem !important; }
  .p-xxxl-16 {
    padding: 5rem !important; }
  .p-xxxl-17 {
    padding: 5.3125rem !important; }
  .p-xxxl-18 {
    padding: 5.625rem !important; }
  .p-xxxl-19 {
    padding: 5.9375rem !important; }
  .p-xxxl-20 {
    padding: 6.25rem !important; }
  .p-xxxl-21 {
    padding: 6.5625rem !important; }
  .p-xxxl-22 {
    padding: 6.875rem !important; }
  .p-xxxl-23 {
    padding: 7.1875rem !important; }
  .p-xxxl-24 {
    padding: 7.5rem !important; }
  .p-xxxl-25 {
    padding: 7.8125rem !important; }
  .p-xxxl-26 {
    padding: 8.125rem !important; }
  .p-xxxl-27 {
    padding: 8.4375rem !important; }
  .p-xxxl-28 {
    padding: 8.75rem !important; }
  .p-xxxl-29 {
    padding: 9.0625rem !important; }
  .p-xxxl-30 {
    padding: 9.375rem !important; }
  .p-xxxl-31 {
    padding: 9.6875rem !important; }
  .p-xxxl-32 {
    padding: 10rem !important; }
  .p-xxxl-33 {
    padding: 10.3125rem !important; }
  .p-xxxl-34 {
    padding: 10.625rem !important; }
  .p-xxxl-35 {
    padding: 10.9375rem !important; }
  .p-xxxl-36 {
    padding: 11.25rem !important; }
  .p-xxxl-37 {
    padding: 11.5625rem !important; }
  .p-xxxl-38 {
    padding: 11.875rem !important; }
  .p-xxxl-39 {
    padding: 12.1875rem !important; }
  .p-xxxl-40 {
    padding: 12.5rem !important; }
  .p-xxxl-base {
    padding: calc(20rem / 16) !important; }
  .px-xxxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxxl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important; }
  .px-xxxl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important; }
  .px-xxxl-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important; }
  .px-xxxl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important; }
  .px-xxxl-5 {
    padding-right: 1.5625rem !important;
    padding-left: 1.5625rem !important; }
  .px-xxxl-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important; }
  .px-xxxl-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important; }
  .px-xxxl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-xxxl-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important; }
  .px-xxxl-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important; }
  .px-xxxl-11 {
    padding-right: 3.4375rem !important;
    padding-left: 3.4375rem !important; }
  .px-xxxl-12 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important; }
  .px-xxxl-13 {
    padding-right: 4.0625rem !important;
    padding-left: 4.0625rem !important; }
  .px-xxxl-14 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important; }
  .px-xxxl-15 {
    padding-right: 4.6875rem !important;
    padding-left: 4.6875rem !important; }
  .px-xxxl-16 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-xxxl-17 {
    padding-right: 5.3125rem !important;
    padding-left: 5.3125rem !important; }
  .px-xxxl-18 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important; }
  .px-xxxl-19 {
    padding-right: 5.9375rem !important;
    padding-left: 5.9375rem !important; }
  .px-xxxl-20 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important; }
  .px-xxxl-21 {
    padding-right: 6.5625rem !important;
    padding-left: 6.5625rem !important; }
  .px-xxxl-22 {
    padding-right: 6.875rem !important;
    padding-left: 6.875rem !important; }
  .px-xxxl-23 {
    padding-right: 7.1875rem !important;
    padding-left: 7.1875rem !important; }
  .px-xxxl-24 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-xxxl-25 {
    padding-right: 7.8125rem !important;
    padding-left: 7.8125rem !important; }
  .px-xxxl-26 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important; }
  .px-xxxl-27 {
    padding-right: 8.4375rem !important;
    padding-left: 8.4375rem !important; }
  .px-xxxl-28 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important; }
  .px-xxxl-29 {
    padding-right: 9.0625rem !important;
    padding-left: 9.0625rem !important; }
  .px-xxxl-30 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important; }
  .px-xxxl-31 {
    padding-right: 9.6875rem !important;
    padding-left: 9.6875rem !important; }
  .px-xxxl-32 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-xxxl-33 {
    padding-right: 10.3125rem !important;
    padding-left: 10.3125rem !important; }
  .px-xxxl-34 {
    padding-right: 10.625rem !important;
    padding-left: 10.625rem !important; }
  .px-xxxl-35 {
    padding-right: 10.9375rem !important;
    padding-left: 10.9375rem !important; }
  .px-xxxl-36 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important; }
  .px-xxxl-37 {
    padding-right: 11.5625rem !important;
    padding-left: 11.5625rem !important; }
  .px-xxxl-38 {
    padding-right: 11.875rem !important;
    padding-left: 11.875rem !important; }
  .px-xxxl-39 {
    padding-right: 12.1875rem !important;
    padding-left: 12.1875rem !important; }
  .px-xxxl-40 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-xxxl-base {
    padding-right: calc(20rem / 16) !important;
    padding-left: calc(20rem / 16) !important; }
  .py-xxxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxxl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important; }
  .py-xxxl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important; }
  .py-xxxl-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important; }
  .py-xxxl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .py-xxxl-5 {
    padding-top: 1.5625rem !important;
    padding-bottom: 1.5625rem !important; }
  .py-xxxl-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important; }
  .py-xxxl-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important; }
  .py-xxxl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-xxxl-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important; }
  .py-xxxl-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important; }
  .py-xxxl-11 {
    padding-top: 3.4375rem !important;
    padding-bottom: 3.4375rem !important; }
  .py-xxxl-12 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important; }
  .py-xxxl-13 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important; }
  .py-xxxl-14 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important; }
  .py-xxxl-15 {
    padding-top: 4.6875rem !important;
    padding-bottom: 4.6875rem !important; }
  .py-xxxl-16 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-xxxl-17 {
    padding-top: 5.3125rem !important;
    padding-bottom: 5.3125rem !important; }
  .py-xxxl-18 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important; }
  .py-xxxl-19 {
    padding-top: 5.9375rem !important;
    padding-bottom: 5.9375rem !important; }
  .py-xxxl-20 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important; }
  .py-xxxl-21 {
    padding-top: 6.5625rem !important;
    padding-bottom: 6.5625rem !important; }
  .py-xxxl-22 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important; }
  .py-xxxl-23 {
    padding-top: 7.1875rem !important;
    padding-bottom: 7.1875rem !important; }
  .py-xxxl-24 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-xxxl-25 {
    padding-top: 7.8125rem !important;
    padding-bottom: 7.8125rem !important; }
  .py-xxxl-26 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important; }
  .py-xxxl-27 {
    padding-top: 8.4375rem !important;
    padding-bottom: 8.4375rem !important; }
  .py-xxxl-28 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important; }
  .py-xxxl-29 {
    padding-top: 9.0625rem !important;
    padding-bottom: 9.0625rem !important; }
  .py-xxxl-30 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important; }
  .py-xxxl-31 {
    padding-top: 9.6875rem !important;
    padding-bottom: 9.6875rem !important; }
  .py-xxxl-32 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-xxxl-33 {
    padding-top: 10.3125rem !important;
    padding-bottom: 10.3125rem !important; }
  .py-xxxl-34 {
    padding-top: 10.625rem !important;
    padding-bottom: 10.625rem !important; }
  .py-xxxl-35 {
    padding-top: 10.9375rem !important;
    padding-bottom: 10.9375rem !important; }
  .py-xxxl-36 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important; }
  .py-xxxl-37 {
    padding-top: 11.5625rem !important;
    padding-bottom: 11.5625rem !important; }
  .py-xxxl-38 {
    padding-top: 11.875rem !important;
    padding-bottom: 11.875rem !important; }
  .py-xxxl-39 {
    padding-top: 12.1875rem !important;
    padding-bottom: 12.1875rem !important; }
  .py-xxxl-40 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-xxxl-base {
    padding-top: calc(20rem / 16) !important;
    padding-bottom: calc(20rem / 16) !important; }
  .pt-xxxl-0 {
    padding-top: 0 !important; }
  .pt-xxxl-1 {
    padding-top: 0.3125rem !important; }
  .pt-xxxl-2 {
    padding-top: 0.625rem !important; }
  .pt-xxxl-3 {
    padding-top: 0.9375rem !important; }
  .pt-xxxl-4 {
    padding-top: 1.25rem !important; }
  .pt-xxxl-5 {
    padding-top: 1.5625rem !important; }
  .pt-xxxl-6 {
    padding-top: 1.875rem !important; }
  .pt-xxxl-7 {
    padding-top: 2.1875rem !important; }
  .pt-xxxl-8 {
    padding-top: 2.5rem !important; }
  .pt-xxxl-9 {
    padding-top: 2.8125rem !important; }
  .pt-xxxl-10 {
    padding-top: 3.125rem !important; }
  .pt-xxxl-11 {
    padding-top: 3.4375rem !important; }
  .pt-xxxl-12 {
    padding-top: 3.75rem !important; }
  .pt-xxxl-13 {
    padding-top: 4.0625rem !important; }
  .pt-xxxl-14 {
    padding-top: 4.375rem !important; }
  .pt-xxxl-15 {
    padding-top: 4.6875rem !important; }
  .pt-xxxl-16 {
    padding-top: 5rem !important; }
  .pt-xxxl-17 {
    padding-top: 5.3125rem !important; }
  .pt-xxxl-18 {
    padding-top: 5.625rem !important; }
  .pt-xxxl-19 {
    padding-top: 5.9375rem !important; }
  .pt-xxxl-20 {
    padding-top: 6.25rem !important; }
  .pt-xxxl-21 {
    padding-top: 6.5625rem !important; }
  .pt-xxxl-22 {
    padding-top: 6.875rem !important; }
  .pt-xxxl-23 {
    padding-top: 7.1875rem !important; }
  .pt-xxxl-24 {
    padding-top: 7.5rem !important; }
  .pt-xxxl-25 {
    padding-top: 7.8125rem !important; }
  .pt-xxxl-26 {
    padding-top: 8.125rem !important; }
  .pt-xxxl-27 {
    padding-top: 8.4375rem !important; }
  .pt-xxxl-28 {
    padding-top: 8.75rem !important; }
  .pt-xxxl-29 {
    padding-top: 9.0625rem !important; }
  .pt-xxxl-30 {
    padding-top: 9.375rem !important; }
  .pt-xxxl-31 {
    padding-top: 9.6875rem !important; }
  .pt-xxxl-32 {
    padding-top: 10rem !important; }
  .pt-xxxl-33 {
    padding-top: 10.3125rem !important; }
  .pt-xxxl-34 {
    padding-top: 10.625rem !important; }
  .pt-xxxl-35 {
    padding-top: 10.9375rem !important; }
  .pt-xxxl-36 {
    padding-top: 11.25rem !important; }
  .pt-xxxl-37 {
    padding-top: 11.5625rem !important; }
  .pt-xxxl-38 {
    padding-top: 11.875rem !important; }
  .pt-xxxl-39 {
    padding-top: 12.1875rem !important; }
  .pt-xxxl-40 {
    padding-top: 12.5rem !important; }
  .pt-xxxl-base {
    padding-top: calc(20rem / 16) !important; }
  .pe-xxxl-0 {
    padding-right: 0 !important; }
  .pe-xxxl-1 {
    padding-right: 0.3125rem !important; }
  .pe-xxxl-2 {
    padding-right: 0.625rem !important; }
  .pe-xxxl-3 {
    padding-right: 0.9375rem !important; }
  .pe-xxxl-4 {
    padding-right: 1.25rem !important; }
  .pe-xxxl-5 {
    padding-right: 1.5625rem !important; }
  .pe-xxxl-6 {
    padding-right: 1.875rem !important; }
  .pe-xxxl-7 {
    padding-right: 2.1875rem !important; }
  .pe-xxxl-8 {
    padding-right: 2.5rem !important; }
  .pe-xxxl-9 {
    padding-right: 2.8125rem !important; }
  .pe-xxxl-10 {
    padding-right: 3.125rem !important; }
  .pe-xxxl-11 {
    padding-right: 3.4375rem !important; }
  .pe-xxxl-12 {
    padding-right: 3.75rem !important; }
  .pe-xxxl-13 {
    padding-right: 4.0625rem !important; }
  .pe-xxxl-14 {
    padding-right: 4.375rem !important; }
  .pe-xxxl-15 {
    padding-right: 4.6875rem !important; }
  .pe-xxxl-16 {
    padding-right: 5rem !important; }
  .pe-xxxl-17 {
    padding-right: 5.3125rem !important; }
  .pe-xxxl-18 {
    padding-right: 5.625rem !important; }
  .pe-xxxl-19 {
    padding-right: 5.9375rem !important; }
  .pe-xxxl-20 {
    padding-right: 6.25rem !important; }
  .pe-xxxl-21 {
    padding-right: 6.5625rem !important; }
  .pe-xxxl-22 {
    padding-right: 6.875rem !important; }
  .pe-xxxl-23 {
    padding-right: 7.1875rem !important; }
  .pe-xxxl-24 {
    padding-right: 7.5rem !important; }
  .pe-xxxl-25 {
    padding-right: 7.8125rem !important; }
  .pe-xxxl-26 {
    padding-right: 8.125rem !important; }
  .pe-xxxl-27 {
    padding-right: 8.4375rem !important; }
  .pe-xxxl-28 {
    padding-right: 8.75rem !important; }
  .pe-xxxl-29 {
    padding-right: 9.0625rem !important; }
  .pe-xxxl-30 {
    padding-right: 9.375rem !important; }
  .pe-xxxl-31 {
    padding-right: 9.6875rem !important; }
  .pe-xxxl-32 {
    padding-right: 10rem !important; }
  .pe-xxxl-33 {
    padding-right: 10.3125rem !important; }
  .pe-xxxl-34 {
    padding-right: 10.625rem !important; }
  .pe-xxxl-35 {
    padding-right: 10.9375rem !important; }
  .pe-xxxl-36 {
    padding-right: 11.25rem !important; }
  .pe-xxxl-37 {
    padding-right: 11.5625rem !important; }
  .pe-xxxl-38 {
    padding-right: 11.875rem !important; }
  .pe-xxxl-39 {
    padding-right: 12.1875rem !important; }
  .pe-xxxl-40 {
    padding-right: 12.5rem !important; }
  .pe-xxxl-base {
    padding-right: calc(20rem / 16) !important; }
  .pb-xxxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxxl-1 {
    padding-bottom: 0.3125rem !important; }
  .pb-xxxl-2 {
    padding-bottom: 0.625rem !important; }
  .pb-xxxl-3 {
    padding-bottom: 0.9375rem !important; }
  .pb-xxxl-4 {
    padding-bottom: 1.25rem !important; }
  .pb-xxxl-5 {
    padding-bottom: 1.5625rem !important; }
  .pb-xxxl-6 {
    padding-bottom: 1.875rem !important; }
  .pb-xxxl-7 {
    padding-bottom: 2.1875rem !important; }
  .pb-xxxl-8 {
    padding-bottom: 2.5rem !important; }
  .pb-xxxl-9 {
    padding-bottom: 2.8125rem !important; }
  .pb-xxxl-10 {
    padding-bottom: 3.125rem !important; }
  .pb-xxxl-11 {
    padding-bottom: 3.4375rem !important; }
  .pb-xxxl-12 {
    padding-bottom: 3.75rem !important; }
  .pb-xxxl-13 {
    padding-bottom: 4.0625rem !important; }
  .pb-xxxl-14 {
    padding-bottom: 4.375rem !important; }
  .pb-xxxl-15 {
    padding-bottom: 4.6875rem !important; }
  .pb-xxxl-16 {
    padding-bottom: 5rem !important; }
  .pb-xxxl-17 {
    padding-bottom: 5.3125rem !important; }
  .pb-xxxl-18 {
    padding-bottom: 5.625rem !important; }
  .pb-xxxl-19 {
    padding-bottom: 5.9375rem !important; }
  .pb-xxxl-20 {
    padding-bottom: 6.25rem !important; }
  .pb-xxxl-21 {
    padding-bottom: 6.5625rem !important; }
  .pb-xxxl-22 {
    padding-bottom: 6.875rem !important; }
  .pb-xxxl-23 {
    padding-bottom: 7.1875rem !important; }
  .pb-xxxl-24 {
    padding-bottom: 7.5rem !important; }
  .pb-xxxl-25 {
    padding-bottom: 7.8125rem !important; }
  .pb-xxxl-26 {
    padding-bottom: 8.125rem !important; }
  .pb-xxxl-27 {
    padding-bottom: 8.4375rem !important; }
  .pb-xxxl-28 {
    padding-bottom: 8.75rem !important; }
  .pb-xxxl-29 {
    padding-bottom: 9.0625rem !important; }
  .pb-xxxl-30 {
    padding-bottom: 9.375rem !important; }
  .pb-xxxl-31 {
    padding-bottom: 9.6875rem !important; }
  .pb-xxxl-32 {
    padding-bottom: 10rem !important; }
  .pb-xxxl-33 {
    padding-bottom: 10.3125rem !important; }
  .pb-xxxl-34 {
    padding-bottom: 10.625rem !important; }
  .pb-xxxl-35 {
    padding-bottom: 10.9375rem !important; }
  .pb-xxxl-36 {
    padding-bottom: 11.25rem !important; }
  .pb-xxxl-37 {
    padding-bottom: 11.5625rem !important; }
  .pb-xxxl-38 {
    padding-bottom: 11.875rem !important; }
  .pb-xxxl-39 {
    padding-bottom: 12.1875rem !important; }
  .pb-xxxl-40 {
    padding-bottom: 12.5rem !important; }
  .pb-xxxl-base {
    padding-bottom: calc(20rem / 16) !important; }
  .ps-xxxl-0 {
    padding-left: 0 !important; }
  .ps-xxxl-1 {
    padding-left: 0.3125rem !important; }
  .ps-xxxl-2 {
    padding-left: 0.625rem !important; }
  .ps-xxxl-3 {
    padding-left: 0.9375rem !important; }
  .ps-xxxl-4 {
    padding-left: 1.25rem !important; }
  .ps-xxxl-5 {
    padding-left: 1.5625rem !important; }
  .ps-xxxl-6 {
    padding-left: 1.875rem !important; }
  .ps-xxxl-7 {
    padding-left: 2.1875rem !important; }
  .ps-xxxl-8 {
    padding-left: 2.5rem !important; }
  .ps-xxxl-9 {
    padding-left: 2.8125rem !important; }
  .ps-xxxl-10 {
    padding-left: 3.125rem !important; }
  .ps-xxxl-11 {
    padding-left: 3.4375rem !important; }
  .ps-xxxl-12 {
    padding-left: 3.75rem !important; }
  .ps-xxxl-13 {
    padding-left: 4.0625rem !important; }
  .ps-xxxl-14 {
    padding-left: 4.375rem !important; }
  .ps-xxxl-15 {
    padding-left: 4.6875rem !important; }
  .ps-xxxl-16 {
    padding-left: 5rem !important; }
  .ps-xxxl-17 {
    padding-left: 5.3125rem !important; }
  .ps-xxxl-18 {
    padding-left: 5.625rem !important; }
  .ps-xxxl-19 {
    padding-left: 5.9375rem !important; }
  .ps-xxxl-20 {
    padding-left: 6.25rem !important; }
  .ps-xxxl-21 {
    padding-left: 6.5625rem !important; }
  .ps-xxxl-22 {
    padding-left: 6.875rem !important; }
  .ps-xxxl-23 {
    padding-left: 7.1875rem !important; }
  .ps-xxxl-24 {
    padding-left: 7.5rem !important; }
  .ps-xxxl-25 {
    padding-left: 7.8125rem !important; }
  .ps-xxxl-26 {
    padding-left: 8.125rem !important; }
  .ps-xxxl-27 {
    padding-left: 8.4375rem !important; }
  .ps-xxxl-28 {
    padding-left: 8.75rem !important; }
  .ps-xxxl-29 {
    padding-left: 9.0625rem !important; }
  .ps-xxxl-30 {
    padding-left: 9.375rem !important; }
  .ps-xxxl-31 {
    padding-left: 9.6875rem !important; }
  .ps-xxxl-32 {
    padding-left: 10rem !important; }
  .ps-xxxl-33 {
    padding-left: 10.3125rem !important; }
  .ps-xxxl-34 {
    padding-left: 10.625rem !important; }
  .ps-xxxl-35 {
    padding-left: 10.9375rem !important; }
  .ps-xxxl-36 {
    padding-left: 11.25rem !important; }
  .ps-xxxl-37 {
    padding-left: 11.5625rem !important; }
  .ps-xxxl-38 {
    padding-left: 11.875rem !important; }
  .ps-xxxl-39 {
    padding-left: 12.1875rem !important; }
  .ps-xxxl-40 {
    padding-left: 12.5rem !important; }
  .ps-xxxl-base {
    padding-left: calc(20rem / 16) !important; }
  .gap-xxxl-0 {
    gap: 0 !important; }
  .gap-xxxl-1 {
    gap: 0.3125rem !important; }
  .gap-xxxl-2 {
    gap: 0.625rem !important; }
  .gap-xxxl-3 {
    gap: 0.9375rem !important; }
  .gap-xxxl-4 {
    gap: 1.25rem !important; }
  .gap-xxxl-5 {
    gap: 1.5625rem !important; }
  .gap-xxxl-6 {
    gap: 1.875rem !important; }
  .gap-xxxl-7 {
    gap: 2.1875rem !important; }
  .gap-xxxl-8 {
    gap: 2.5rem !important; }
  .gap-xxxl-9 {
    gap: 2.8125rem !important; }
  .gap-xxxl-10 {
    gap: 3.125rem !important; }
  .gap-xxxl-11 {
    gap: 3.4375rem !important; }
  .gap-xxxl-12 {
    gap: 3.75rem !important; }
  .gap-xxxl-13 {
    gap: 4.0625rem !important; }
  .gap-xxxl-14 {
    gap: 4.375rem !important; }
  .gap-xxxl-15 {
    gap: 4.6875rem !important; }
  .gap-xxxl-16 {
    gap: 5rem !important; }
  .gap-xxxl-17 {
    gap: 5.3125rem !important; }
  .gap-xxxl-18 {
    gap: 5.625rem !important; }
  .gap-xxxl-19 {
    gap: 5.9375rem !important; }
  .gap-xxxl-20 {
    gap: 6.25rem !important; }
  .gap-xxxl-21 {
    gap: 6.5625rem !important; }
  .gap-xxxl-22 {
    gap: 6.875rem !important; }
  .gap-xxxl-23 {
    gap: 7.1875rem !important; }
  .gap-xxxl-24 {
    gap: 7.5rem !important; }
  .gap-xxxl-25 {
    gap: 7.8125rem !important; }
  .gap-xxxl-26 {
    gap: 8.125rem !important; }
  .gap-xxxl-27 {
    gap: 8.4375rem !important; }
  .gap-xxxl-28 {
    gap: 8.75rem !important; }
  .gap-xxxl-29 {
    gap: 9.0625rem !important; }
  .gap-xxxl-30 {
    gap: 9.375rem !important; }
  .gap-xxxl-31 {
    gap: 9.6875rem !important; }
  .gap-xxxl-32 {
    gap: 10rem !important; }
  .gap-xxxl-33 {
    gap: 10.3125rem !important; }
  .gap-xxxl-34 {
    gap: 10.625rem !important; }
  .gap-xxxl-35 {
    gap: 10.9375rem !important; }
  .gap-xxxl-36 {
    gap: 11.25rem !important; }
  .gap-xxxl-37 {
    gap: 11.5625rem !important; }
  .gap-xxxl-38 {
    gap: 11.875rem !important; }
  .gap-xxxl-39 {
    gap: 12.1875rem !important; }
  .gap-xxxl-40 {
    gap: 12.5rem !important; }
  .gap-xxxl-base {
    gap: calc(20rem / 16) !important; }
  .text-xxxl-start {
    text-align: left !important; }
  .text-xxxl-end {
    text-align: right !important; }
  .text-xxxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .container--no-padding-xs .row {
    --bs-gutter-x: 0; } }

@media screen and (max-width: 991px) {
  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl,
  .container-xxxl,
  .container-md,
  .container-sm {
    --bs-gutter-x: 20px; }
  .row {
    --bs-gutter-x: 10px; } }

@media screen and (min-width: 992px) {
  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl,
  .container-xxxl,
  .container-lg,
  .container-xl,
  .container-xxl {
    --bs-gutter-x: 40px; } }

@font-face {
  font-family: 'lg';
  src: url("/maiersports_ng/static/fonts/lg.ttf?22t19m") format("truetype"), url("/maiersports_ng/static/fonts/lg.woff?22t19m") format("woff"), url("/maiersports_ng/static/fonts/lg.svg?22t19m#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

.lg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'lg' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  outline: none;
  border: none;
  background-color: transparent; }
  .lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    color: #FFF; }

.lg-actions .lg-next {
  right: 20px; }
  .lg-actions .lg-next:before {
    content: "\e095"; }

.lg-actions .lg-prev {
  left: 20px; }
  .lg-actions .lg-prev:after {
    content: "\e094"; }

@-webkit-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-webkit-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative; }

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative; }

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45); }
  .lg-toolbar .lg-icon {
    color: #999;
    cursor: pointer;
    float: right;
    font-size: 24px;
    height: 47px;
    line-height: 27px;
    padding: 10px 0;
    text-align: center;
    width: 50px;
    text-decoration: none !important;
    outline: medium none;
    background: none;
    border: none;
    box-shadow: none;
    -webkit-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    transition: color 0.2s linear; }
    .lg-toolbar .lg-icon:hover {
      color: #FFF; }
  .lg-toolbar .lg-close:after {
    content: "\e070"; }
  .lg-toolbar .lg-download:after {
    content: "\e0f2"; }

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080; }
  .lg-sub-html h4, .lg-sub-html .h4 {
    margin: 0;
    font-size: 13px;
    font-weight: bold; }
  .lg-sub-html p {
    font-size: 12px;
    margin: 5px 0 0; }

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle; }

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; }

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0); }

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0); }

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }
  .lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important; }

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0); }

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px; }

.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px; }
  @media (min-width: 1025px) {
    .lg-outer .lg-thumb-item {
      -webkit-transition: border-color 0.25s ease;
      -o-transition: border-color 0.25s ease;
      transition: border-color 0.25s ease; } }
  .lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
    border-color: #a90707; }
  .lg-outer .lg-thumb-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px; }

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0; }

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease; }

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px; }

.lg-outer .lg-toggle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
  outline: medium none;
  border: none; }
  .lg-outer .lg-toggle-thumb:after {
    content: "\e1ff"; }
  .lg-outer .lg-toggle-thumb:hover {
    color: #FFF; }

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px; }

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative; }
  .lg-outer .lg-video .lg-object {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }
  .lg-outer .lg-video .lg-video-play {
    width: 84px;
    height: 59px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -42px;
    margin-top: -30px;
    z-index: 1080;
    cursor: pointer; }

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("/maiersports_ng/static/img/vimeo-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("/maiersports_ng/static/img/vimeo-play.png") no-repeat scroll 0 -58px transparent; }

.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("/maiersports_ng/static/img/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8; }

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1; }

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("/maiersports_ng/static/img/youtube-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("/maiersports_ng/static/img/youtube-play.png") no-repeat scroll 0 -60px transparent; }

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0; }

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden; }

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none; }

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible; }

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s; }
  .lg-progress-bar .lg-progress {
    background-color: #a90707;
    height: 5px;
    width: 0; }
  .lg-progress-bar.lg-start .lg-progress {
    width: 100%; }
  .lg-show-autoplay .lg-progress-bar {
    opacity: 1; }

.lg-autoplay-button:after {
  content: "\e01d"; }
  .lg-show-autoplay .lg-autoplay-button:after {
    content: "\e01a"; }

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

#lg-zoom-in:after {
  content: "\e311"; }

#lg-actual-size {
  font-size: 20px; }
  #lg-actual-size:after {
    content: "\e033"; }

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none; }
  #lg-zoom-out:after {
    content: "\e312"; }
  .lg-zoomed #lg-zoom-out {
    opacity: 1;
    pointer-events: auto; }

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px; }
  .lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
    overflow: visible; }

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px; }
  .lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
    box-shadow: 0 0 0 2px white inset; }

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0); }
  .lg-outer .lg-pager-thumb-cont img {
    width: 100%;
    height: 100%; }

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px; }
  .lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
    box-shadow: 0 0 0 8px white inset; }

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0; }

.lg-fullscreen:after {
  content: "\e20c"; }
  .lg-fullscreen-on .lg-fullscreen:after {
    content: "\e20d"; }

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s; }

.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible; }

.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF; }

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s; }
  .lg-outer .lg-dropdown:after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    border: 8px solid transparent;
    border-bottom-color: #FFF;
    right: 16px;
    top: -16px; }
  .lg-outer .lg-dropdown > li:last-child {
    margin-bottom: 0px; }
  .lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
    color: #333; }
  .lg-outer .lg-dropdown a {
    color: #333;
    display: block;
    white-space: pre;
    padding: 4px 12px;
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 12px; }
    .lg-outer .lg-dropdown a:hover {
      background-color: rgba(0, 0, 0, 0.07); }
  .lg-outer .lg-dropdown .lg-dropdown-text {
    display: inline-block;
    line-height: 1;
    margin-top: -3px;
    vertical-align: middle; }
  .lg-outer .lg-dropdown .lg-icon {
    color: #333;
    display: inline-block;
    float: none;
    font-size: 20px;
    height: auto;
    line-height: 1;
    margin-right: 8px;
    padding: 0;
    vertical-align: middle;
    width: auto; }

.lg-outer #lg-share {
  position: relative; }
  .lg-outer #lg-share:after {
    content: "\e80d"; }

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998; }
  .lg-outer #lg-share-facebook .lg-icon:after {
    content: "\e904"; }

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced; }
  .lg-outer #lg-share-twitter .lg-icon:after {
    content: "\e907"; }

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39; }
  .lg-outer #lg-share-googleplus .lg-icon:after {
    content: "\e905"; }

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027; }
  .lg-outer #lg-share-pinterest .lg-icon:after {
    content: "\e906"; }

.lg-outer .lg-img-rotate {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  transition: transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s; }

.lg-rotate-left:after {
  content: "\e900"; }

.lg-rotate-right:after {
  content: "\e901"; }

.lg-icon.lg-flip-hor, .lg-icon.lg-flip-ver {
  font-size: 26px; }

.lg-flip-hor:after {
  content: "\e902"; }

.lg-flip-ver:after {
  content: "\e903"; }

.lg-group {
  *zoom: 1; }

.lg-group:before, .lg-group:after {
  display: table;
  content: "";
  line-height: 0; }

.lg-group:after {
  clear: both; }

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  outline: none;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-outer * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .lg-outer.lg-visible {
    opacity: 1; }
  .lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
    -webkit-transition-duration: inherit !important;
    transition-duration: inherit !important;
    -webkit-transition-timing-function: inherit !important;
    transition-timing-function: inherit !important; }
  .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
    opacity: 1; }
  .lg-outer.lg-grab img.lg-object {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer.lg-grabbing img.lg-object {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%; }
  .lg-outer .lg-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap; }
  .lg-outer .lg-item {
    background: url("/maiersports_ng/static/img/loading.gif") no-repeat scroll center center transparent;
    display: none !important; }
  .lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
    display: inline-block !important; }
  .lg-outer.lg-css .lg-current {
    display: inline-block !important; }
  .lg-outer .lg-item, .lg-outer .lg-img-wrap {
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%; }
    .lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
      content: "";
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px; }
  .lg-outer .lg-img-wrap {
    position: absolute;
    padding: 0 5px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
  .lg-outer .lg-item.lg-complete {
    background-image: none; }
  .lg-outer .lg-item.lg-current {
    z-index: 1060; }
  .lg-outer .lg-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important; }
  .lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
    opacity: 0;
    -webkit-transition: opacity 0.15s ease 0s;
    -o-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s; }
  .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
    opacity: 1; }
  .lg-outer .lg-empty-html {
    display: none; }
  .lg-outer.lg-hide-download #lg-download {
    display: none; }

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-backdrop.in {
    opacity: 1; }

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important; }

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-fade .lg-item {
  opacity: 0; }
  .lg-css3.lg-fade .lg-item.lg-current {
    opacity: 1; }
  .lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
    -webkit-transition: opacity 0.1s ease 0s;
    -moz-transition: opacity 0.1s ease 0s;
    -o-transition: opacity 0.1s ease 0s;
    transition: opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
    left: -100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
    left: 100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    left: 0;
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }
